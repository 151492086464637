import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AssessRequestsModule } from '../assess-requests/assess-requests.module';
import { SharedModule } from '../common/shared.module';
import { MasterWorklistComponent } from './components/master-worklist/master-worklist.component';
import { MyWorklistComponent } from './components/my-worklist/my-worklist.component';
import { WorklistRoutingModule } from './worklist-routing.module';
import { WorklistComponent } from './worklist.component';

@NgModule({
  declarations: [
    WorklistComponent,
    MyWorklistComponent,
    MasterWorklistComponent,

  ],
  imports: [
    CommonModule,
    WorklistRoutingModule,
    HttpClientModule,
    SharedModule,
    AssessRequestsModule,
    NgMultiSelectDropDownModule
  ]
})
export class WorklistModule { }
