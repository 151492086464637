import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { prdtRuleDetailsModel } from 'src/app/common/models/ProductRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { DataService } from 'src/app/common/services/data.service';
import { environment } from 'src/environments/environment';
import { RuleTypesApplicable } from '../../substance-based/rule-selection/rule-selection.component';

@Component({
  selector: 'app-product-rule-selection',
  templateUrl: './product-rule-selection.component.html',
  styleUrls: ['./product-rule-selection.component.scss']
})
export class ProductRuleSelectionComponent implements OnInit {
  @Output() ruleTypeEvent = new EventEmitter<any>();
  ruleTypesApplicable: RuleTypesApplicable[] = [];
  readOnlyValue = true;
  i = 0;
  isRuleAdded = false;
  isRuleChecked = false;
  isRuleUnChecked = false;
  loading = false;
  selectedRuleCategory = 'regulated';
  rulesAppliedList = [];
  ruleTypes = [];
  propertyRuleTypes = [];
  substanceBasedRuleTypes = [];
  substanceGroupRuleTypes = [];
  ruleBucket;
  preparationMethodToDisplay;
  intendedUseToDisplay;
  loadingMsg;
  constructor(
    private assessRequestRa: AssessRequestRaService,
    public matSnackBar: MatSnackBar,
    public dataSvcObject: DataService) { }

  ngOnInit() {
    this.preparationMethodToDisplay = recipeModel.preparationMethod;
    this.intendedUseToDisplay = recipeModel.intendedUse;
    this.getRuleApplicability();
    this.ruleBucket = localStorage.getItem('selectedRuleBucket');
  }
  onAddRule(ruleType) {
    if (ruleType.isRuleApplicable && ruleType.isAddRuleEnable) {
      this.ruleTypeEvent.emit(ruleType);
    }
  }

  onCheckRule(ruleType) {
    if (ruleType.isRuleApplicable) {
      const index = _.findIndex(prdtRuleDetailsModel.rulesListForProduct, (ruleValue) => {
        return ruleValue.ruleTypeID === ruleType.ruleTypeId;
      });
      if (index > -1) {
        this.matSnackBar.open('Rule Exists for the Rule Type. Rule Applicability cannot be changed.', 'close', {
          duration: 5000,
          panelClass: ['error']
        });
        return;
      }
    }
    if (ruleType.isRuleNotApplicable) {
      return;
    }
    ruleType.isAddRuleEnable = false;
    const checkedruletype = this.ruleTypesApplicable.find(x => x.ruleTypeDisplayName === ruleType.ruleTypeDisplayName);
    checkedruletype.isRuleApplicable = !checkedruletype.isRuleApplicable;
  }
  onUnCheckRule(ruleType) {
    if (ruleType.isRuleApplicable) {
      return;
    }
    ruleType.isAddRuleEnable = false;
    const uncheckedruletype = this.ruleTypesApplicable.find(x => x.ruleTypeDisplayName === ruleType.ruleTypeDisplayName);
    uncheckedruletype.isRuleNotApplicable = !uncheckedruletype.isRuleNotApplicable;
  }

  // save applicability data
  saveRuleApplicability() {
    this.ruleTypesApplicable.forEach(element => {
      element.marketId = prdtRuleDetailsModel.Market.marketId;
      element.pureCode = null;
      element.ruleCreationStatus = 'N';
      if (element.isRuleApplicable) {
        element.applicabilityStatus = 'Yes';
      } else
        if (element.isRuleNotApplicable) {
          element.applicabilityStatus = 'No';
        } else {
          element.applicabilityStatus = 'NA';
        }
    });
    const data = [];
    prdtRuleDetailsModel.selectedRpcsPdtBased.forEach(rpc => {
      this.ruleTypesApplicable.forEach(element => {
        const ruleappobject = Object.assign({}, element);
        ruleappobject.rpcid = Number(rpc.id);
        data.push(ruleappobject);
      });
    });
    this.loadingMsg = 'Saving Rule Types';
    this.loading = true;
    const apiurl = environment.APIFAsTRule + '/rules/ruleTypesMaps';
    this.assessRequestRa.postData(apiurl, data).subscribe((response) => {
      this.loading = false;
      if (response.message === 'Success') {
        this.matSnackBar.open('Saved Successfully', 'close', {
          duration: 3000,
          panelClass: ['success']
        });
        this.ruleTypesApplicable.forEach(element => {
          if (element.isRuleApplicable) {
            element.isAddRuleEnable = true;
          }
        });  // why the same panel added here
        const filteredTypes = _.filter(this.ruleTypesApplicable, (value) => value.applicabilityStatus === 'Yes' && (value.ruleClassification === 'Product Based Rules' || value.ruleClassification === 'Property Based Rules'));
        this.dataSvcObject.setProductRuleApplicability(filteredTypes);
      }
    }, (error) => {
      this.loading = false;
    });
  }


  // get applicability data
  getRuleApplicability() {
    this.i++;
    const applicabilityInputList = [];
    const marketId = prdtRuleDetailsModel.Market.marketId;
    prdtRuleDetailsModel.selectedRpcsPdtBased.forEach((value) => {
      const data = {
        // PureCode: purecode,
        MarketId: marketId,
        Rpcid: value.id
      };
      applicabilityInputList.push(data);
    });
    this.loadingMsg = 'Fetching data from FAST Rule-Engine';
    this.loading = true;
    const apiurl = environment.APIFAsTRule + '/rules/ruleTypesMapForMarkets';
    this.assessRequestRa.postData(apiurl, applicabilityInputList).subscribe((response) => {
      this.ruleTypesApplicable = [];
      this.propertyRuleTypes = [];
      this.substanceBasedRuleTypes = [];
      this.substanceGroupRuleTypes = [];
      if (response.length !== 0) {
        const id = Number(prdtRuleDetailsModel.selectedRpcsPdtBased[0].id);
        this.ruleTypesApplicable = response;
        this.ruleTypesApplicable.forEach(element => {
          if (element.ruleTypeId !== 17 && element.ruleTypeId !== 18 && element.ruleTypeId !== 42) {
            if (element.applicabilityStatus === 'Yes') {
              element.isRuleApplicable = true;
              element.isAddRuleEnable = true;
            } else if (element.applicabilityStatus === 'No') {
              element.isRuleNotApplicable = true;
            } else {
              element.isRuleNotApplicable = false;
              element.isRuleApplicable = false;
            }
            if (element.ruleClassification === 'Product Based Rules' || element.ruleClassification === 'Property Based Rules') {
              this.propertyRuleTypes.push(element);
            } else if (element.ruleClassification === 'Substance Based Rules') {
              this.substanceBasedRuleTypes.push(element);
            } else if (element.ruleClassification === 'Substance Group Rules') {
              if (element.ruleTypeId === 34 || element.ruleTypeId === 35 || element.ruleTypeId === 36) {
              } else {
                this.substanceGroupRuleTypes.push(element);
              }
            }
          }
        });
        const filtered = _.filter(this.ruleTypesApplicable, (value) => value.applicabilityStatus === 'Yes' && (value.ruleClassification === 'Product Based Rules' || value.ruleClassification === 'Property Based Rules'));
        this.dataSvcObject.setProductRuleApplicability(filtered);
        this.loading = false;
      } else if (response.length === 0) {
        this.loading = false;
        this.matSnackBar.open('Request Failed', 'close', {
          duration: 3000,
          panelClass: ['error']
        });
        this.dataSvcObject.setExpandablePanel(0);
      }
    }, (error) => {
      this.loading = false;
      this.dataSvcObject.setExpandablePanel(0);
    });
  }
}
