import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/common/services/data.service';

@Component({
  selector: 'app-create-product-rule',
  templateUrl: './create-product-rule.component.html',
  styleUrls: ['./create-product-rule.component.scss']
})
export class CreateProductRuleComponent implements OnInit {
  selectedProductRuleDetails;
  activeTabUR = 'rule-detail';
  loading = false;
  ruleDisplayName;
  constructor(private dataSvcObj: DataService) { }
/* istanbul ignore next */
  ngOnInit() {
    this.dataSvcObj.getruleToEdit().subscribe((value) => {
      if (value !== null) {
        this.ruleDisplayName = value.ruleTypeDisplayName;
      }
    });
    this.dataSvcObj.getSelectedProductRuleType.subscribe((value) => {
      if (value.length !== 0) {
        this.selectedProductRuleDetails = value;
        this.ruleDisplayName = this.selectedProductRuleDetails.ruleTypeDisplayName;
      }
    });
  }

}
