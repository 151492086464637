import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { pureRulesDetailsModel, ruleFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { sirDetailObj } from 'src/app/common/models/SirModel';
import { EXECUTE_RULE_TABLE_COLUMNS } from 'src/app/common/models/tableColumns';
import { DataService } from 'src/app/common/services/data.service';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ingredient-rule-template',
  templateUrl: './ingredient-rule-template.component.html',
  styleUrls: ['./ingredient-rule-template.component.scss']
})
export class IngredientRuleTemplateComponent implements OnInit, AfterViewInit {
  uomList: [];
  uomval = null;
  loadingMsg = 'loading';
  activeTabUR = 'ingredient-rule';
  ruleDisplayName;
  ruleAdded = []; selectedRuleTypeDetails = []; dataList = []; ruleTypeValues = []; subBasedRuleData = []; rpc = []; ruleSpecificAttributes = [];
  ruleTypeId;
  ruleTypeVal = '';
  readOnlyValue = true;
  propertySrc;
  propertyTree; propertyType; propertyName; minAmount;
  saveAndAddNewBtnClicked = false;
  loading = false; saveAndAddNewDialog = false;
  applicableRuleTypesToDisplay; rtRadioSelected; ruleDetails;
  ruleTypeRadioSelected = false;
  disablebtn = true;
  output;
  showValidation = false; servingSizeFlag = false; dosageFlag = false; dosageDisabled = false;
  servingSizeDisabled = false; parallelRuleWorkflow = false; allergenFlag = false; cancelClicked = false;
  ruleSource = ''; validityStartDate: any = ''; validityEndDate: any = '';
  yearAdopted: any = ''; ruleDescription = '';
  selectedIngRule: any;
  equalityCondition = { value: '>' };
  minEqualityCondition = [{ value: '>' }, { value: '>=' }];
  editRule = false; viewRule = false; ruleSaveConformationPopup = false; showRelatedRulesPopup = false; viewRelbtn = false;
  ruleVersion; ruleIdOnEdit;
  ruleComment: string;
  prepareRuleTableData = []; prepareRuleColumns = []; myWorklistTableColumns = []; selectedRowsRelated = [];
  showEmailConfirmationDialog = false;
  selectedRows;
  defaultValidityFromDate; defaultValidityEndDate; todayDate;
  marketForModal: any;
  commentsToUser: string;
  constructor(public dataServiceObj: DataService, private datePipe: DatePipe, private worklistservice: WorklistService, private assessReqRAService: AssessRequestRaService, private snackBar: MatSnackBar, private route: Router) { }
  ngAfterViewInit(): void {
    this.disableCheckbox();
  }
  disableCheckbox() {
    if (this.dataServiceObj.isfromSubstancesearch) {
      if (!this.dosageFlag) {
        const dosageclass = document.getElementsByClassName('dosageclass').item(0) as HTMLElement;
        if (dosageclass) {
          dosageclass.style.display = 'none';
        }
      }
      if (!this.servingSizeFlag) {
        const servinlass = document.getElementsByClassName('servinlass').item(0) as HTMLElement;
        if (servinlass) {
          servinlass.style.display = 'none';
        }
      }
      if (!this.allergenFlag) {
        const alergenclass = document.getElementsByClassName('alergenclass').item(0) as HTMLElement;
        if (alergenclass) {
          alergenclass.style.display = 'none';
        }
      }
    }
  }
  ngOnInit() {
    this.todayDate = new Date();
    this.defaultValidityFromDate = this.datePipe.transform(this.todayDate, 'longDate');
    this.defaultValidityEndDate = this.datePipe.transform(new Date('9999-12-31'), 'longDate');
    if (this.activeTabUR === 'ingredient-rule') {
      document.getElementById('description').style.display = 'none';
      document.getElementById('ruleValidity').style.display = 'none';
    }
    this.prepareRuleColumns = EXECUTE_RULE_TABLE_COLUMNS;
    this.dataServiceObj.getSirDetailForParallelRule().subscribe((result) => {
      if (result !== null) {
        this.parallelRuleWorkflow = true;
        Object.assign(sirDetailObj, result[0]);
        pureRulesDetailsModel.selectedCF = result[0].cfDetails[0];
      } else {
        this.parallelRuleWorkflow = false;
      }
    });
    this.dataServiceObj.getruleToEdit().subscribe((value) => {
      if (value !== null) {
        this.editRule = true;
        Object.assign(sirDetailObj, value.substanceList[0]);
        pureRulesDetailsModel.selectedCF = value.substanceList[0].cfDetails[0];
        this.ruleIdOnEdit = value.ruleId;
        const rpcVals = [];
        this.minAmount = value.minLimit;
        this.ruleVersion = value.ruleVersion;
        this.ruleTypeVal = value.propertyList[0].ingRuleType;
        this.equalityCondition = { value: value.equalityCondition };
        this.uomval = { uoMsymbol: value.uoM, uoMid: value.uoMID };
        this.marketForModal = recipeModel.marketDetails.marketName;
        pureRulesDetailsModel.allergenFlag = value.isAllergenApplicable === 'Y' ? true : false;
        this.allergenFlag = value.isAllergenApplicable === 'Y' ? true : false;
        if (value.isDosageApplicable === 'Y') {
          this.dosageFlag = true;
          this.servingSizeDisabled = true;
          pureRulesDetailsModel.dosageFlag = true;
        } else {
          this.dosageFlag = false;
          pureRulesDetailsModel.dosageFlag = false;
        }
        if (value.isServingSizeApplicable === 'Y') {
          this.servingSizeFlag = true;
          this.dosageDisabled = true;
          pureRulesDetailsModel.servingSizeFlag = true;
        } else {
          this.servingSizeFlag = false;
          pureRulesDetailsModel.servingSizeFlag = false;
        }
        value.rpcs.forEach((val) => {
          const data = {
            id: val.rpcId,
            text: val.rpcName,
            rpcFullText: val.rpcFullText
          };
          rpcVals.push(data);
        });
        pureRulesDetailsModel.selectedParentRpcs = rpcVals;
        pureRulesDetailsModel.selectedRpcs = rpcVals;
        recipeModel.preparationMethod = value.preparationMethod;
        recipeModel.intendedUse = value.intendedUse;
        this.ruleSource = value.ruleSource;
        if (ruleFlags.viewRule) {
          this.defaultValidityFromDate = value.validityStartDate ? (this.datePipe.transform(new Date(this.datePipe.transform(value.validityStartDate, 'yyyy-MM-dd')), 'longDate')) : '';
          this.defaultValidityEndDate = value.validityDate ? (this.datePipe.transform(new Date(this.datePipe.transform(value.validityDate, 'yyyy-MM-dd')), 'longDate')) : '';
        }
        this.yearAdopted = value.yearAdopted ? (new Date(value.yearAdopted)).toUTCString() : '';
        this.selectedIngRule = value.propertyList[0].ingRuleType;
        this.propertySrc = value.propertyList[0].propertySource;
        this.propertyTree = value.propertyList[0].propertyName;
        this.propertyType = value.propertyList[0].propertyClass;
        this.propertyName = value.propertyList[0].ingPropertyName;
        this.getWorklistColumns();
      } else {
        this.editRule = false;
      }
    });
    this.dataServiceObj.ruleType.subscribe((value) => {
      if (value.length !== 0) {
        this.selectedRuleTypeDetails[0] = value;
        this.ruleTypeId = this.selectedRuleTypeDetails[0].ruleTypeId;
        this.ruleDisplayName = this.selectedRuleTypeDetails[0].ruleTypeDisplayName;
        this.activeTabUR = 'ingredient-rule';
        if (!this.editRule && !this.viewRule) {
          this.ruleTypeVal = 'Minimum amount of acetic acid in vinegar';
          this.uomval = null;
          this.equalityCondition = { value: '>' };
          this.ruleSource = '';
          this.validityStartDate = '';
          this.validityEndDate = '';
          this.yearAdopted = '';
        }
        document.getElementById('ingredient-rule').style.display = 'block';
        document.getElementById('description').style.display = 'none';
        document.getElementById('ruleValidity').style.display = 'none';
        this.ruleDescription = this.selectedRuleTypeDetails[0].ruleTypeDescription ? this.selectedRuleTypeDetails[0].ruleTypeDescription : null;
        this.getPropertyTypeList();
        this.getUomList();
      }
    });
    if (ruleFlags.viewRule) {
      this.editRule = false;
      this.viewRule = true;
    } else if (ruleFlags.editRule) {
      this.editRule = true;
      this.viewRule = false;
    }
  }
  getUomList() {
    this.loading = true;
    const url = environment.APIFAsT + '/uoms';
    this.assessReqRAService.getData(url).subscribe((response) => {
      if (response !== null) {
        this.loading = false;
        this.uomList = response;
      }
    }, (error) => {
      this.loading = false;
    });
  }
  onTabChange(activeTab) {
    this.activeTabUR = activeTab;
    if (activeTab === 'ingredient-rule') {
      document.getElementById('ingredient-rule').style.display = 'block';
      document.getElementById('description').style.display = 'none';
      document.getElementById('ruleValidity').style.display = 'none';
    } else if (activeTab === 'description') {
      document.getElementById('ingredient-rule').style.display = 'none';
      document.getElementById('description').style.display = 'block';
      document.getElementById('ruleValidity').style.display = 'none';
    } else if (activeTab === 'ruleValidity') {
      document.getElementById('ingredient-rule').style.display = 'none';
      document.getElementById('description').style.display = 'none';
      document.getElementById('ruleValidity').style.display = 'block';
    }
    setTimeout(() => {
      this.disableCheckbox();
    }, 10);
  }
  valueChange(val: any) {
    this.minAmount = '';
    this.uomval = null;
    this.equalityCondition = { value: '>' };
    this.showValidation = false;
    this.ruleTypeVal = val.value.ingRuleType;
    this.selectedIngRule = val.value.ingRuleType;
    for (const value of this.dataList) {
      if (value.ingRuleType === this.ruleTypeVal) {
        this.propertyName = value.ingPropertyName;
        this.propertyTree = value.propertyName;
        this.propertyType = value.propertyClass;
        this.propertySrc = value.propertySource;
      }
    }
  }
  onDosageClick() {
    this.servingSizeDisabled = !this.servingSizeDisabled;
    pureRulesDetailsModel.dosageFlag = this.dosageFlag;
  }
  onServingSizeClick() {
    this.dosageDisabled = !this.dosageDisabled;
    pureRulesDetailsModel.servingSizeFlag = this.servingSizeFlag;
  }
  onAllergenClick() {
    pureRulesDetailsModel.allergenFlag = this.allergenFlag;
  }
  /* istanbul ignore next */
  getPropertyTypeList() {
    this.ruleTypeValues = [];
    const url = environment.APIFAsTRule + '/INGproperty';
    this.assessReqRAService.getData(url).subscribe((response) => {
      if (response !== null) {
        this.dataList = response;
        if (!this.editRule && !this.viewRule) {
          for (const value of this.dataList) {
            if (value.ingRuleType === 'Minimum amount of acetic acid in vinegar') {
              this.propertyName = value.ingPropertyName;
              this.propertyTree = value.propertyName;
              this.propertyType = value.propertyClass;
              this.propertySrc = value.propertySource;
            }
          }
          this.selectedIngRule = this.dataList[0];
        }
      }
    }, (error) => {
    });
  }
  /* istanbul ignore next */
  onCancel() {
    if (!this.editRule) {
      this.dataServiceObj.setExpandablePanel(2);
      this.minAmount = '';
      this.uomval = null;
      this.equalityCondition = { value: '>' };
    } else {
      this.minAmount = '';
      this.uomval = null;
      this.equalityCondition = { value: '>' };
      this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
        this.route.navigate(['/rule-management/view-rules']);
      });
    }
  }
  /* istanbul ignore next */
  onSaveandAddNew() {
    this.onSave();
  }
  /* istanbul ignore next */
  onSaveAndClose() {
    this.onSave();
  }
  /* istanbul ignore next */
  onSave() {
    this.loading = true;
    const rpcData = [];
    this.loadingMsg = 'Saving Rule';
    const saveRuleToDbUrl = environment.APIFAsTRule + '/rulesList';
    if (pureRulesDetailsModel.CascadeRpc === 'Y') {
      pureRulesDetailsModel.selectedParentRpcs.forEach((value) => {
        const data = {
          rpcFullText: value.rpcFullText,
          rpcName: value.text,
          rpcId: value.id
        };
        rpcData.push(data);
      });
    } else {
      pureRulesDetailsModel.selectedRpcs.forEach((value) => {
        const data = {
          rpcFullText: value.rpcFullText,
          rpcName: value.text,
          rpcId: value.id
        };
        rpcData.push(data);
      });
    }
    let pureSirDetails;
    if (localStorage.getItem('pureSirDetails')) {
      pureSirDetails = JSON.parse(localStorage.getItem('pureSirDetails'));
    }
    let substanceData = [];
    if (pureSirDetails && (pureRulesDetailsModel.SirId !== sirDetailObj.sirId)) {
      substanceData = [{
      PureCode: pureSirDetails.pureCode,
      PureDescription: pureSirDetails.pureDescription,
      SirId: pureSirDetails.sirId,
      SubstanceName: pureSirDetails.substanceName,
      InciName: pureSirDetails.inciName.toString(),
      Einecs: pureSirDetails.einecs.toString(),
      Cas: pureSirDetails.cas.toString(),
      parentCAS: pureSirDetails.parentCAS.toString(),
      Ins: pureSirDetails.ins.toString(),
      ENumber: pureSirDetails.eNumber.toString(),
      SubstanceFunction: { FunctionId: null, FunctionName: '' },
      UoM: '',
      CFDetails: [pureRulesDetailsModel.selectedCF],
      SubstanceMolWeight: pureSirDetails.substanceMolWeight,
      CombinationRatio: null
    }];
    } else {
      substanceData = [{
        PureCode: sirDetailObj.pureCode,
        PureDescription: sirDetailObj.pureDescription,
        SirId: sirDetailObj.sirId,
        SubstanceName: sirDetailObj.substanceName,
        InciName: sirDetailObj.inciName.toString(),
        Einecs: sirDetailObj.einecs.toString(),
        Cas: sirDetailObj.cas.toString(),
        parentCAS: sirDetailObj.parentCAS.toString(),
        Ins: sirDetailObj.ins.toString(),
        ENumber: sirDetailObj.eNumber.toString(),
        SubstanceFunction: { FunctionId: null, FunctionName: '' },
        UoM: '',
        CFDetails: [pureRulesDetailsModel.selectedCF],
        SubstanceMolWeight: sirDetailObj.substanceMolWeight,
        CombinationRatio: null
      }];
      localStorage.setItem('pureSirDetails', '');
    }

    const propertyList = [
      {
        PropertyId: '',
        PropertyClass: this.propertyType,
        PropertyName: this.propertyTree,
        INGPropertyName: this.propertyName,
        PropertyRecipeQuantity: null,
        PropertyTargetQuantity: null,
        PropertyMaxQuantity: '',
        PropertyMinQuantity: '',
        PropertyUoM: this.uomval.uoMsymbol,
        PropertyValueType: 'Max',
        ClaimsListFromRules: null,
        SpecialConditionListFromRules: null,
        UserInputClaim: null,
        UserInputSpecificCondition: null,
        PropertyRuleStatus: null,
        INGRuleType: this.ruleTypeVal,
        PropertySource: this.propertySrc
      }
    ];
    const savedData = [{
      Markets: [pureRulesDetailsModel.Market],
      RPCs: rpcData, /// object list
      RuleId: this.editRule ? this.ruleIdOnEdit : 0,
      RuleVersion: this.ruleVersion ? this.ruleVersion : 0,
      RuleTypeID: this.ruleTypeId,
      RuleType: this.ruleDisplayName,
      SubstanceList: substanceData,
      PropertyList: propertyList,
      CascadeMarket: 'N',
      CascadeRpc: 'Y', // made to Y by default as the flag is always selected
      UoMID: this.uomval.uoMid,
      UoM: this.uomval.uoMsymbol,
      TexualMinLimitText: '',
      TexualMaxLimitText: '',
      RuleDescription: this.ruleDisplayName,
      RuleSource: this.ruleSource ? this.ruleSource : '',
      RuleSourceType: '',
      AdditionalStatement: '',
      PositiveStatement: '',
      MaxLimit: '',
      MinLimit: this.minAmount,
      Claims: null,
      SpeficicConditions: null,
      FunctionList: [],
      PreparationMethod: 'As Sold',
      IntendedUse: 'Any',
      IsMandatorySubstance: null,
      IsAllergenApplicable: pureRulesDetailsModel.allergenFlag ? 'Y' : 'N',
      IsDosageApplicable: pureRulesDetailsModel.dosageFlag ? 'Y' : 'N',
      IsServingSizeApplicable: pureRulesDetailsModel.servingSizeFlag ? 'Y' : 'N',
      PhasedOutDate: null,
      WarningText: null,
      yearAdopted: this.yearAdopted ? this.datePipe.transform(this.yearAdopted, 'yyyy-MM-dd') : null,
      ValidityStartDate: this.validityStartDate ? this.datePipe.transform(this.validityStartDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityFromDate, 'yyyy-MM-dd'),
      ValidityDate: this.validityEndDate ? this.datePipe.transform(this.validityEndDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityEndDate, 'yyyy-MM-dd'),
      EqualityCondition: this.equalityCondition.value,
      Comments: this.ruleComment ? this.ruleComment : null,
      RuleFlow: this.parallelRuleWorkflow === true ? 'Parallel' : 'Request'
    }];
    if (!this.editRule) {
      this.assessReqRAService.postData(saveRuleToDbUrl, savedData).subscribe((response) => {
        this.loading = false;
        if (response[0].ruleId !== 0) {
          this.ruleAdded = response;
          this.getRuleDetailsFromDb();
          this.snackBar.open('Rule created successfully with Rule Id:' + response[0].ruleId, 'close', {
            duration: 5000,
            panelClass: ['success']
          });
          this.minAmount = '';
          this.uomval = null;
          this.equalityCondition = { value: '>' };
          if (this.saveAndAddNewBtnClicked === true) {
            setTimeout(() => {
              this.applicableRuleTypesToDisplay = pureRulesDetailsModel.applicableRuleTypeList;
              this.saveAndAddNewDialog = true;
            }, 3000);
          }
        } else {
          this.snackBar.open('Rule could not be created.', 'close', {
            duration: 5000,
            panelClass: ['error']
          });
          this.loading = false;
          this.minAmount = '';
          this.uomval = null;
          this.equalityCondition = { value: '>' };
        }
        setTimeout(() => this.dataServiceObj.setExpandablePanel(2), 3500);
      }
        , (error) => {
          this.loading = false;
          this.minAmount = '';
          this.uomval = null;
          this.equalityCondition = { value: '>' };
        }
      );
    } else {
      const saveRuleEdit = environment.APIFAsTRule + '/rules';
      this.assessReqRAService.putData(saveRuleEdit, savedData).subscribe((response) => {
        this.loading = false;
        if (response[0].ruleId !== 0) {
          this.ruleAdded = response;
          this.getRuleDetailsFromDb();
          this.snackBar.open('Rule updated successfully with Rule Id:' + response[0].ruleId + ' and Rule Version: ' + response[0].ruleVersion, 'close', {
            duration: 5000,
            panelClass: ['success']
          });
          this.minAmount = '';
          this.uomval = null;
          this.equalityCondition = { value: '>' };
          this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
            this.route.navigate(['/rule-management/view-rules']);
          });
        } else {
          this.snackBar.open('Rule could not be created.', 'close', {
            duration: 5000,
            panelClass: ['error']
          });
          this.loading = false;
          this.minAmount = '';
          this.uomval = null;
          this.equalityCondition = { value: '>' };
        }
      }
        , (error) => {
          this.loading = false;
          this.minAmount = '';
          this.uomval = null;
          this.equalityCondition = { value: '>' };
        }
      );

    }
  }
  getRuleDetailsFromDb() {
    this.rpc = [];
    pureRulesDetailsModel.selectedRpcs.forEach((value) => {
      const data = {
        rpcFullText: value.rpcFullText,
        rpcName: value.text,
        rpcId: value.id
      };
      this.rpc.push(data);
    });
    let pureSirDetails;
    if (localStorage.getItem('pureSirDetails')) {
      pureSirDetails = JSON.parse(localStorage.getItem('pureSirDetails'));
    }
    let fetchRuleBody;
    if (pureSirDetails && (pureRulesDetailsModel.SirId !== sirDetailObj.sirId)) {
      fetchRuleBody = {
        MarketList: [pureRulesDetailsModel.Market],
        RPCList: this.rpc,
        SIRIds: [sirDetailObj.sirId],
        ruleClassifications: ['Substance Based Rules']
      };
    } else {
      fetchRuleBody = {
        MarketList: [pureRulesDetailsModel.Market],
        RPCList: this.rpc,
        SIRIds: [sirDetailObj.sirId],
        ruleClassifications: ['Substance Based Rules']
      };
    }

    this.loading = true;
    this.loadingMsg = 'Fetching data from FAST Rule-Engine';
    const getruleapiurl = environment.APIFAsTRule + '/rules/rulesFilter';
    return new Promise<any>((resolve) => {
      this.assessReqRAService.postData(getruleapiurl, fetchRuleBody)
        .toPromise()
        .then(response => {
          this.loading = false;
          if (response !== null || response !== []) {
            resolve(response);
            this.subBasedRuleData = response;
            this.subBasedRuleData = _.filter(this.subBasedRuleData, (val) => val.ruleClassification === 'Substance Based Rules');
            for (const value of this.subBasedRuleData) {
              value.ruleVerionToDisplay = 'V' + value.ruleVersion;
              if (value.warningText === null) {
                value.warningText = value.warningStatement;
              }
              if (value.cfDetails !== null && value.cfDetails !== undefined) {
                const cfValue = value.cfDetails;
                value.cf = cfValue.cf;
                value.cfSubstanceExpressedAs = cfValue.component;
              }
            }
            this.dataServiceObj.setRulesListForPure(this.subBasedRuleData);
          }
        }, (error) => {
          this.loading = false;
        }).catch(error => {
          this.loading = false;
        });
    });
  }
  ruleTypeRadioButtonChanged(event) {
    this.ruleTypeRadioSelected = true;
    this.rtRadioSelected = event.target.value;
  }
  onContinue() {
    this.minAmount = '';
    this.uomval = null;
    this.equalityCondition = { value: '>' };
    this.saveAndAddNewDialog = false;
    this.saveAndAddNewBtnClicked = false;
    if (this.rtRadioSelected !== undefined) {
      const obj = _.filter(pureRulesDetailsModel.applicableRuleTypeList, (element) => element.ruleTypeDisplayName === this.rtRadioSelected);
      // tslint:disable-next-line: no-shadowed-variable
      const obj1 = obj.reduce((obj, newObj, i) => {
        newObj[i] = obj;
        return newObj;
      }, {});
      this.dataServiceObj.setSelectedRuleType(obj1);
      this.dataServiceObj.setExpandablePanel(3);
    }
  }
  checkNumeric(val: any) {
    const reg = new RegExp('^[0-9]+(\.[0-9]{1,10})?$');
    if (val.target.value) {
      this.output = reg.test(val.target.value);
      if (this.output) {
        this.showValidation = false;
        this.disablebtn = false;
      } else {
        this.showValidation = true;
      }
    } else {
      this.disablebtn = true;
      this.showValidation = false;
    }
  }
  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }
  /* istanbul ignore next */
  checkRulesAvail() {
    this.viewRelbtn = true;
    this.prepareRuleTableData = [];
    this.selectedRows = null;
    this.loadingMsg = 'Fetching data from FAsT Rule-Engine';
    this.loading = true;
    const apiurl = environment.APIFAsTRule + '/rules/queryrelatedrequests';
    const body = [];
    this.showRelatedRulesPopup = true;
    body.push({
      RuleId: this.ruleIdOnEdit,
      RuleVersion: this.ruleVersion
    });
    this.assessReqRAService.postData(apiurl, body).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.prepareRuleTableData = response.map(ingReq => {
          return {
            requestDisplayID: ingReq.requestDisplayID,
            cucCode: ingReq.cucCode,
            cucDescription: ingReq.cucDescription,
            recipeType: ingReq.recipeType,
            recipeStatus: ingReq.recipeStatus,
            marketId: ingReq.market.marketId,
            marketName: ingReq.market.marketName,
            division: (ingReq.tfc.tfcLevel === 5) ? ingReq.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName : ingReq.tfc.parentTFC.parentTFC.parentTFC.tfcName,
            rDFormulationCategory: (ingReq.tfc.tfcLevel === 5) ? ingReq.tfc.parentTFC.parentTFC.parentTFC.tfcName : ingReq.tfc.parentTFC.parentTFC.tfcName,
            formulationType: (ingReq.tfc.tfcLevel === 5) ? ingReq.tfc.parentTFC.parentTFC.tfcName : ingReq.tfc.parentTFC.tfcName,
            formulationClass: (ingReq.tfc.tfcLevel === 5) ? ingReq.tfc.parentTFC.tfcName : ingReq.tfc.tfcName,
            formulationSubClass: (ingReq.tfc.tfcLevel === 5) ? ingReq.tfc.tfcName : '',
            preparationMethod: ingReq.preparationMethod,
            intendedUse: ingReq.intendedUse,
            multicomponent: ingReq.isMultiComponent,
            requestStatus: ingReq.requestStatus,
            ra: ingReq.ra,
            allRa: ingReq.allRA,
            submittedDate: ingReq.submittedDate,
            submittedByUserId: ingReq.submittedByUserId,
            submittedByUserName: ingReq.submittedByUserName,
            comments: ingReq.comments,
            assignedDate: ingReq.assignedDate,
            inProgressRADate: ingReq.inProgressRADate,
            inProgressRDDate: ingReq.inProgressRNDDate,
            inProgressSIRDate: ingReq.inProgressSIRDate,
            rejectedDate: ingReq.rejectedDate,
            deletionDate: ingReq.deletionDate,
            completedDate: ingReq.completedDate,
            rdComment: ingReq.comments,
            raComment: ingReq.raComment,
            topTfcId: ingReq.tfcid,
            allRAIDs: ingReq.allRAIDs,
            raUserID: ingReq.raUserIDred,
            divisionid: (ingReq.tfc.tfcLevel === 5) ? ingReq.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId : ingReq.tfc.parentTFC.parentTFC.parentTFC.tfcId
          };
        });
      }
      const ele = document.getElementById('echeck') as HTMLInputElement;
      ele.checked = false;
      this.selectedRowsRelated = [];
    }, (error) => {
      this.loading = false;
    });
  }
  getWorklistColumns() {
    const apiurl = environment.APIFAsT + '/getworklistcolumns/relatedrequest';
    this.worklistservice.getWorklistApiData(apiurl).subscribe((response) => {
      this.myWorklistTableColumns = response;
    });
  }
  sendEmail() {
    this.showEmailConfirmationDialog = true;
    let reqId = '';
    let userDetails = '';
    const uDetail = [];
    const reDet = [];
    this.selectedRowsRelated.forEach(element => {
      reDet.push(element.requestDisplayID);
    });
    const distinctReqs = reDet.filter((n, i) => reDet.indexOf(n) === i);
    reqId = distinctReqs.join(' ');
    this.selectedRowsRelated.forEach(element => {
      uDetail.push(element.ra);
    });
    const distinctNames = uDetail.filter((n, i) => uDetail.indexOf(n) === i);
    userDetails = distinctNames.join(' ');
    this.commentsToUser = 'Regulatory rule/s for the request no. ' + reqId + ' will be updated/deleted by RA user ' + userDetails + ' .To get the most updated FAsT report, please submit a new request for the formulation. Ignore if the formulation is no longer valid.';
  }
  sendEmailMessage() {
    this.showEmailConfirmationDialog = false;
    this.showRelatedRulesPopup = false;
    const payLoad = {
      NotificationGroup: 'RDCOMPLETED',
      Subject: 'FAsT | Rules updated for these requests :',
      EmailBody: null,
      ToEmailList: '',
      FromEmail: null,
      CCEmailList: null,
      EmailType: 'html',
      UPD: this.ruleIdOnEdit.toString(),
      PureCodes: null,
      UserRequests: [],
      CUCDescription: null,
      Comments: this.commentsToUser
    };

    if (this.selectedRowsRelated.length > 0) {
      this.selectedRowsRelated.forEach(row => {
        const reqIds = this.selectedRowsRelated.filter(item => item.submittedByUserId === row.submittedByUserId).map(i => i.requestDisplayID);
        payLoad.UserRequests.push({
          userId: +row.submittedByUserId,
          requestIds: reqIds
        });
      });
    }

    if (payLoad.UserRequests.length > 0) {
      this.assessReqRAService.sendRulesEmailNotification(payLoad);
    }
  }
  onGroupSelect(checkValue) {
    if (checkValue) {
      this.selectedRowsRelated = this.prepareRuleTableData;
    } else {
      this.selectedRowsRelated = [];
    }
  }
}
