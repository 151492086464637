// Landing Page
export const LANDINGPAGE_HEADER = 'FAsT';
export const LANDINGPAGE_HEADER_EXPANDED = 'FORMULATION ASSESSMENT TOOL';
export const CARDITEMLIST = [
  { Item: 'Assessment Request', id: 'assess-requests', img: 'STANDARD-INSTRUCTION' },
  { Item: 'Reports Archive', id: 'reportsarchive', img: 'FACTORY-CONFIGRATION' },

];

// Header
export const TITLEHEADER_BOLD = 'FAsT-';
export const TITLEHEADER = 'Formulation Assessment Tool';
export const HEADERINFO = [
  { item: 'BANGALORE', img: 'Factory_Small_Gray' },
  { item: 'ENGLISH', img: 'Language_Gray' },
  { item: 'EURO', img: 'Curency_Gray' }
];


export const MENUITEMLIST = [
  { Item: 'Assessment Request', id: 'assess-requests', img: 'Standard-Instructions_Gray' },
  { Item: 'Reports Archive', id: 'reportsarchive', img: 'FactoryConfigration_Gray' },
  { Item: '', id: '1', img: 'noImg' }
];
export const MENUITEMLIST1 = [
  { Item: 'Assessment Request', id: 'assess-requests', img: 'Standard-Instructions_Gray' },
  { Item: 'Reports Archive', id: 'reportsarchive', img: 'FactoryConfigration_Gray' },
  { Item: '', id: '1', img: 'noImg' }
];


// factory Configurator page

export const INCIDENTTYPE = 'Incident Type';
export const INCIDENTUPLOAD = 'Incident Data Upload';
export const INCIDENTDATAUPLOAD = 'INCIDENT DATA UPLOAD';
export const COLUMNNAMES = ['SINo', 'FileName', 'UploadedDate', 'UploadedBy', 'IncidentType', 'Status', 'Dashboard'];
export const FILE_VALIDATION_MESSAGE = 'Please upload files with .xls or .xlsx format only.';
export const SELECT_INCIDENT_VALIDATION_MESSAGE = 'Please select Incident Type before uploading.';
export const API_ERROR_MESSAGE = 'Error in proccessing data. Please try again.';
export const FILE_UPLOAD_SUCCESS_MESSAGE = 'File is uploaded successfully.';
export const NO_RECORDS = 'No records found.';

// Configurator page
export const LOSSTREE_HEADER = 'LOSS TREE CONFIGURATOR';
export const DEFAULT_SEVERITY = 'select';
export const GRADES = [
  { text: 'AA' },
  { text: 'A' },
  { text: 'B' },
  { text: 'C' }
];
export const COLUMNS = [
  { id: 1, headerText: 'Level 0', contentAlignment: 'center', headerAlignment: 'center', width: 165, fixed: true },
  { id: 2, headerText: 'Level 1', headerAlignment: 'center', width: 80 },
  { id: 3, headerText: 'Level 2', headerAlignment: 'center', width: 80 },
  { id: 4, headerText: 'Level 3', headerAlignment: 'center', width: 80 },
  { id: 5, headerText: 'Level 4', headerAlignment: 'center', width: 80 },
  { id: 6, headerText: 'Level 5', headerAlignment: 'center', contentAlignment: 'center', width: 80 },
  { id: 7, headerText: 'Aggregation', headerAlignment: 'center', width: 180 },
  { id: 8, headerText: 'Severity Grade', headerAlignment: 'center', contentAlignment: 'center', width: 85 },
  { id: 9, headerText: 'Explanation/Examples', headerAlignment: 'center', contentAlignment: 'center', width: 150 },
  { id: 10, headerText: 'Actions', headerAlignment: 'center', contentAlignment: 'center', width: 80 }
];
export const SUCCESSMSG = ' Your changes have been saved successfully.';
export const ERRORMSG = 'Error in processing data. Reason: "';

// QA-matrix page (5 tabs)
export const QAHEADER = 'QA MATRIX CREATION';
export const FIRST_TABTITLE = 'Defect Mode / Severity';
export const SECOND_TABTITLE = 'Frequency';
export const THIRD_TABTITLE = 'Cost';
export const FOURTH_TABTITLE = 'Detectability';
export const FIFTH_TABTITLE_DPI = 'DPI Calculations &';
export const FIFTH_TABTITLE_PDCA = 'PDCA Status';
export const DEFECT_TYPE = 'DEFECT TYPE';

// defect-mode tab (datatable)
export const DEFECTMODECOLUMNSNAME = ['productionDate',
'productCode', 'formatAggregation', 'format', 'airswebId', 'description', 'defectAggregation', 'severityGrade'];
export const PRODUCTION = 'Production';

// Frequency tab (datatable)
export const FREQUENCYCOLUMNSNAME = ['productionDate', 'formatAggregation', 'blockedproduct',
'ProductionAmount', 'UoM', 'affectedProduct', 'frequencyScore'];
// Cost tab
export const COSTCOLUMNSNAME = ['productionDate', 'formatAggregation',
  'cost1piece', 'totaldefectcost', '%fromtotalcost', 'costscore'];
export const COSTSCORE = 'Cost Score';
export const FROMTOTALCOST = '% from Total Cost';
export const COST1PIECE = 'Cost of 1 Piece';
export const COST = 'Cost';
export const FREQUENCY = 'Frequency';
export const DETECTABILITYINDEX = 'Detectability Index';
export const SEVERITY = 'Severity';
export const EQUALTO = '=';


// Dashboard
export const DASHBOARD = 'DASHBOARD';

export const DASHBOARDVALUE = '--Select--';

export const FREQTABLEDATA = [
  { Criteria: 'Very High', From: '>6.68', To: '<100', Index: 500 },
  { Criteria: 'High', From: '>0.621', To: '<50', Index: 200 },
  { Criteria: 'Moderate', From: '>0.0233', To: '<25', Index: 100 },
  { Criteria: 'Low', From: '>=0', To: '<1', Index: 50 }
];

// API Related
// export const SUBSCRIPTIONKEY = '1353f865d012425880e82426fd0e0ba5';
export const APPLICATIONCONTENT = 'application/json; charset=utf-8';
export const ACCESSCONTROL = '*';
export const SETTINGS = 'settings';
export const LOSSTREE = 'settings/factories/';
export const FILEUPLOAD = 'incidents/files/';


