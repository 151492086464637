import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { AuthenticationGuard } from 'microsoft-adal-angular6';

import {
  MaxGroupRuleTemplateComponent,
} from './assess-request-ra/update-rules-ra/substance-group/create-group-rule/max-group-rule-template/max-group-rule-template.component';
import { SelectRecipeComponent } from './assess-requests/select-recipe/select-recipe.component';
import { ErrorPageComponent } from './common/components/error-page/error-page.component';
import { UnauthorizedComponent } from './common/components/unauthorized/unauthorized.component';
import { SelectRpcComponent } from './create-insert-rule/select-rpc/select-rpc.component';
import { FeaturesComponent } from './features/features.component';
import { HelpPageComponent } from './help-page/help-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { PlmReqComponent } from './plm-req/plm-req.component';
import { RelatedRequestsComponent } from './related-requests/related-requests.component';
import { StandaloneComponent } from './standalone/standalone.component';
import { EditRulesComponent } from './view-rules/edit-rules/edit-rules.component';
import { ViewRulesComponent } from './view-rules/view-rules.component';
import { SubstanceSearchComponent } from './substance-search/substance-search/substance-search.component';
import { SelectRecipePlmComponent } from './assess-requests/select-recipe-plm/select-recipe-plm.component';
const routes: Routes = [
  {
    path: 'assess-requests',
    loadChildren: () => import('./assess-requests/assess-requests.module').then(m => m.AssessRequestsModule),
  },
  {
    path: 'create-insert',
    loadChildren: () => import('./create-insert-rule/create-insert-rule.module').then(m => m.CreateInsertRuleModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'assess-request-ra',
    loadChildren: () => import('./assess-request-ra/assess-request-ra.module').then(m => m.AssessRequestRaModule),
  },
  {
    path: 'worklist',
    loadChildren: () => import('./worklist/worklist.module').then(m => m.WorklistModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'administration',
    loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'auth',
    component: MsalRedirectComponent
  },
  {
    path: 'rules/setting/substance',
    component: StandaloneComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'features',
    component: FeaturesComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'plm/:cucCode/:alt/:ver',
    component: PlmReqComponent
  },
  {
    path: '',
    pathMatch: 'full',
    component: HomePageComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'select',
    component: SelectRecipeComponent
  },
  {
    path: 'select-plm:/cucCode/:alt/:ver',
    component: SelectRecipePlmComponent
  },
  {
    path: 'substance-group',
    component: MaxGroupRuleTemplateComponent,
  },
  {
    path: 'help',
    component: HelpPageComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'rule-management/view-rules',
    component: ViewRulesComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'select-rpc',
        component: SelectRpcComponent
      },
      {
        path: 'edit-rules',
        component: EditRulesComponent
      }

    ]
  },
  {
    path: 'substance-search/:id',
    component: SubstanceSearchComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'view-rules/edit-rules',
    component: EditRulesComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'rule-management/related-requests',
    component: RelatedRequestsComponent,
    canActivate: [MsalGuard]
  },
  { path: 'fragrancemasterlist', loadChildren: () => import('./fragrance-master-list/fragrance-master-list.module').then(m => m.FragranceMasterListModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
