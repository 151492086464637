import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class PlmFormService {
  returnedValues = [];
  public validationPassed: string;
  // private plmform: FormGroup;
  // plmformAssess = {
  //     market: { marketId: [], marketName: [] }, division: '', formulationCategory: [], formulationType: [], formulationClass: [], formulationSubClass: [], fc: [], version: [], alternate: [], recipeType: [],
  //     description: [], name: [], quantity: [], uom: [], intendedUse: 'Any', prepMethod: [], comments: [], dosage: [], dosageUom: [],
  //     selectedMarkets: []
  //   };
  // get form() {
  //     return this.plmformAssess;
  // }
  constructor(private dataService: DataService) {
    this.validationPassed = 'In Progress';
    // this.plmform = this.fb.group({
    //     // title: ['', Validators.required],
    //     // salary: ['', Validators.required],
    //     // name: ['', Validators.required],
    //     // age: ['']

    //     market: ['', Validators.required],
    //     division: ['', Validators.required],
    //     formulationCategory: ['', Validators.required],
    //     formulationType: ['', Validators.required],
    //     formulationClass: ['', Validators.required],
    //     formulationSubClass: ['', Validators.required],
    //     fc: ['', Validators.required],
    //     version: ['', Validators.required],
    //     alternate: ['', Validators.required],
    //     recipeType: ['', Validators.required],
    //     description: ['', Validators.required],
    //     name: ['', Validators.required],
    //     quantity: ['', Validators.required],
    //     uom: ['', Validators.required],
    //     intendedUse: ['Any', Validators.required],
    //     prepMethod: ['', Validators.required],
    //     comments: [''],
    //     dosage: [''],
    //     dosageUom: [''],
    //     selectedMarkets: ['']
    // });
  }
  /* istanbul ignore next */
  validateForPrepareRule() {
    let applicableRuleTypeList = [];
    let rulesList = [];
    // get rule applicability for all pures
    // get all rules
    this.dataService.getApplicableRuleTypesObsv.subscribe((list) => {
      if (list.length !== 0) {
        applicableRuleTypeList = list;
        this.dataService.getRulesListForEachPure.subscribe((resultSet) => {
          if (resultSet.length !== 0) {
            rulesList = resultSet;
            applicableRuleTypeList.forEach((ruleApplicability) => {
              rulesList.forEach((rule) => {
                if ( ruleApplicability.Sirid === rule.SubstanceID && ruleApplicability.RuleTypeId === rule.RuleTypeId ) {
                  ruleApplicability.isSatisfied = true;
                }
              });
            });
            const filteredList = applicableRuleTypeList.filter((value) => value.isSatisfied !== true);
            if (filteredList.length > 0) {
              this.validationPassed = 'Failed';
            } else {
              this.validationPassed = 'Passed';
            }
          }
        });
      }
    });
  }
}
