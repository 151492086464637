import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { StepperComponent } from '../stepper/stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { PrimeNGModule } from '../primeng.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { CalendarModule } from 'primeng/calendar';
import { DisableIfUnauthorizedDirective } from './Authorization/unauthorizedDisable.directive';
import { HideIfUnauthorizedDirective } from './Authorization/unauthorizedHide.directive';
import { HideIfUnauthorizedByPermissionIdDirective } from './Authorization/hide-if-unauthorized-by-permission-id.directive';
import { DisableIfUnauthorizedByPermissionIdDirective } from './Authorization/unauthorizedDisableByPermissionId.directive';
import { ProductBasedComponent } from '../assess-request-ra/update-rules-ra/product-based/product-based.component';
import { ProductRuleSelectionComponent } from '../assess-request-ra/update-rules-ra/product-based/product-rule-selection/product-rule-selection.component';
import { CreateProductRuleComponent } from '../assess-request-ra/update-rules-ra/product-based/create-product-rule/create-product-rule.component';
import { CommonProductRuleTemplateComponent } from '../assess-request-ra/update-rules-ra/product-based/create-product-rule/product-rule-templates/common-product-rule-template/common-product-rule-template.component';
import { CreateRuleComponent } from '../assess-request-ra/update-rules-ra/substance-based/create-rule/create-rule.component';
import { SubstanceBasedComponent } from '../assess-request-ra/update-rules-ra/substance-based/substance-based.component';
import { SubstanceDataComponent } from '../assess-request-ra/update-rules-ra/substance-based/substance-data/substance-data.component';
import { AllowedFunctionTemplateComponent } from '../assess-request-ra/update-rules-ra/substance-based/create-rule/rule-templates/allowed-function-template/allowed-function-template.component';
import { CommonRuleTemplateComponent } from '../assess-request-ra/update-rules-ra/substance-based/create-rule/rule-templates/common-rule-template/common-rule-template.component';
import { MarketRpcSubBasedComponent } from '../assess-request-ra/update-rules-ra/substance-based/market-rpc-sub-based/market-rpc-sub-based.component';
import { RuleSelectionComponent } from '../assess-request-ra/update-rules-ra/substance-based/rule-selection/rule-selection.component';
import { IngredientRuleTemplateComponent } from '../assess-request-ra/update-rules-ra/substance-based/create-rule/rule-templates/ingredient-rule-template/ingredient-rule-template.component';
import { BannedListRuleTemplateComponent } from '../assess-request-ra/update-rules-ra/substance-group/create-group-rule/banned-list-rule-template/banned-list-rule-template.component';
import { ConflictingRuleTemplateComponent } from '../assess-request-ra/update-rules-ra/substance-group/create-group-rule/conflicting-rule-template/conflicting-rule-template.component';
import { CreateGroupRuleComponent } from '../assess-request-ra/update-rules-ra/substance-group/create-group-rule/create-group-rule.component';
import { MaxGroupRuleTemplateComponent } from '../assess-request-ra/update-rules-ra/substance-group/create-group-rule/max-group-rule-template/max-group-rule-template.component';
import { RegulatoryFunctionRuleTemplateComponent } from '../assess-request-ra/update-rules-ra/substance-group/create-group-rule/regulatory-function-rule-template/regulatory-function-rule-template.component';
import { RegulatoryWarningRuleTemplateComponent } from '../assess-request-ra/update-rules-ra/substance-group/create-group-rule/regulatory-warning-rule-template/regulatory-warning-rule-template.component';
import { GroupRuleSelectionComponent } from '../assess-request-ra/update-rules-ra/substance-group/group-rule-selection/group-rule-selection.component';
import { SubstanceGroupComponent } from '../assess-request-ra/update-rules-ra/substance-group/substance-group.component';
import { ExportExcelButtonComponent } from './components/export-excel-button/export-excel-button.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DownloadTemplatesComponent } from './components/download-templates/download-templates.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
@NgModule({
    declarations: [
        StepperComponent,
        HeaderComponent,
        DisableIfUnauthorizedDirective,
        HideIfUnauthorizedDirective,
        HideIfUnauthorizedByPermissionIdDirective,
        DisableIfUnauthorizedByPermissionIdDirective,
        SubstanceBasedComponent,
        SubstanceDataComponent,
        RuleSelectionComponent,
        MarketRpcSubBasedComponent,
        CreateRuleComponent,
        CommonRuleTemplateComponent,
        AllowedFunctionTemplateComponent,
        ProductBasedComponent,
        ProductRuleSelectionComponent,
        CreateProductRuleComponent,
        CommonProductRuleTemplateComponent,
        IngredientRuleTemplateComponent,

        SubstanceGroupComponent,
        CreateGroupRuleComponent,
        MaxGroupRuleTemplateComponent,
        GroupRuleSelectionComponent,
        BannedListRuleTemplateComponent,
        RegulatoryFunctionRuleTemplateComponent,
        RegulatoryWarningRuleTemplateComponent,
        ConflictingRuleTemplateComponent,
        ExportExcelButtonComponent,
        DownloadTemplatesComponent,
        UnauthorizedComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        CdkStepperModule,
        FormsModule,
        MaterialModule,
        PrimeNGModule,
        ReactiveFormsModule,
        MatRadioModule,
        CalendarModule,
        CKEditorModule
    ],
    exports: [
        StepperComponent,
        HeaderComponent,
        CdkStepperModule,
        FormsModule,
        MaterialModule,
        PrimeNGModule,
        CalendarModule,
        DisableIfUnauthorizedDirective,
        HideIfUnauthorizedDirective,
        HideIfUnauthorizedByPermissionIdDirective,
        DisableIfUnauthorizedByPermissionIdDirective,
        SubstanceBasedComponent,
        SubstanceDataComponent,
        RuleSelectionComponent,
        MarketRpcSubBasedComponent,
        CreateRuleComponent,
        CommonRuleTemplateComponent,
        AllowedFunctionTemplateComponent,
        ProductBasedComponent,
        ProductRuleSelectionComponent,
        CreateProductRuleComponent,
        CommonProductRuleTemplateComponent,
        IngredientRuleTemplateComponent,
        SubstanceGroupComponent,
        CreateGroupRuleComponent,
        MaxGroupRuleTemplateComponent,
        GroupRuleSelectionComponent,
        BannedListRuleTemplateComponent,
        RegulatoryFunctionRuleTemplateComponent,
        RegulatoryWarningRuleTemplateComponent,
        ConflictingRuleTemplateComponent,
        ExportExcelButtonComponent,
        DownloadTemplatesComponent
    ]
})

export class SharedModule { }
