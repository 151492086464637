import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pure-list-item',
  templateUrl: './pure-list-item.component.html',
  styleUrls: ['./pure-list-item.component.scss']
})
export class PureListItemComponent  {
  @Input() pureData: any;
}
