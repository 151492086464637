// export class SirDetails {
//     requestedPureCodeList: [];
//     pureCodeDetails: PureCode[];
//     selectedPureCode: any;
//     selectedPureDescription: any;
//     pureDescription: any;
//     sirId: any;
//     substanceName: any;
//     substanceMolWeight;
//     inciName: any;
//     selectedInciName;
//     einecs: any;
//     selectedEinecs;
//     synonyms: any;
//     selectedSynonym;
//     cfDetails: Array<CFDetails>;
//     selectedCF: any;
//     cas: any;
//     selectedCas: any;
//     ins: any;
//     selectedIns;
//     eNumber: any;
//     selectedENumber: any;
// }
export class SirDetails {
    pureCode: any;
    pureDescription: any;
    sirId: any;
    substanceName: any;
    substanceMolWeight;
    inciName: any;
    selectedInciName;
    einecs: any;
    selectedEinecs;
    synonyms: any;
    selectedSynonym;
    cfDetails: CFDetails[];
    selectedCF: any;
    cas: any;
    selectedCas: any;
    parentCAS: any;
    selectedParentCas: any;
    ins: any;
    selectedIns;
    eNumber: any;
    selectedENumber: any;
    checked: boolean;
    commentsToUser: any;
    hazardClassification: any;
}

export class CFDetails {
    component;
    cfDisplayText;
    expressedAs;
    cf;
    numberOfExpressedAs;
    componentMolWeight;
}

export class PureCode {
    pureCode: any;
    pureDescriotion: any;
}

// tslint:disable-next-line: no-var-keyword
export var sirDetailObj = new SirDetails ();
