import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-rules-list-item',
  templateUrl: './rules-list-item.component.html',
  styleUrls: ['./rules-list-item.component.scss']
})
export class RulesListItemComponent  {
  @Input() rulesData: any;
  @Input() functionInput: any;
  @Input() functionName: any;
  @Input() functionRule: any;
}
