export class GroupDataModel {
    GroupId;
    GroupType;
    GroupName;
    SubstanceData: SirDetails[];
}

export class SirDetails {
    sirId;
    pureCode;
    substanceName;
    cas;
    inciName;
    ins;
    cfDetails;
    checked;
}

// tslint:disable-next-line: no-var-keyword
export var groupDataModel = new GroupDataModel();
