import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { SELECT_RECIPE_COLUMNS } from 'src/app/common/models/tableColumns';
import { DataService } from 'src/app/common/services/data.service';
import { environment } from 'src/environments/environment';

import { AuthorizationDataService } from '../../common/Authorization/authorization-data.service';
import { AssessmentRequestService } from '../assess-requests.service';

@Component({
  selector: 'app-select-recipe',
  templateUrl: './select-recipe.component.html',
  styleUrls: ['./select-recipe.component.scss']
})
export class SelectRecipeComponent implements OnInit {

  @ViewChild('InputForm2') InputForm2: NgForm;
  @ViewChild('selectForm') selectForm: NgForm;
  @ViewChild('selectCucForm') selectCucForm: NgForm;
  activeTab = 'nav-search-with-recipe';
  formAssess;
  plmFormObj = {
    market: [],
    division: '', formulationCategory: [], formulationType: [], formulationClass: [], formulationSubClass: [],
    fc: [], version: [], alternate: [], recipeType: [],
    description: [], name: [], quantity: [], uom: [], intendedUse: 'Any',
    prepMethod: [], comments: '', dosage: '', dosageUom: '',
    selectedMarkets: [], tfcList: [], firstRequest: '', cucChanged: '',
  };
  CucSearchResponse: any;
  CUCforRecipeSearch: string;
  recipeTableFlag = false;
  onRadioClicked = false;
  todayDate;
  defaultMaxDate;
  defaultEndDate;
  defaultStartDate;
  defaultStartDateRcp;
  defaultMinDate;
  additionalfielderror;
  loading = true;
  filteredvalidityArea = [];
  validityarray = {
    validity: '',
    validityDescription: '',
    validityAreacode: ''
  };
  validityAreavals = [];
  validityAreaVal: any;
  cancelClicked = false;
  selectedPlmValue;
  @Output() formObj = new EventEmitter();
  loadingMsg = 'Loading..';
  recipeTypesValues = [{ id: '', value: 'Please select a Recipe Type' }, { id: 'Z_EXPERIMENTAL', value: 'Z_EXPERIMENTAL' }, { id: 'Z_GENERAL', value: 'Z_GENERAL' }, { id: 'Z_PLANT', value: 'Z_PLANT' }];
  selectRecipeColumns = SELECT_RECIPE_COLUMNS;
  constructor(
    private router: Router,
    public assessService: AssessmentRequestService,
    public matSnackBar: MatSnackBar,
    private dataTrans: DataService,
    private datePipe: DatePipe,
    private authService: AuthorizationDataService) { }

  ngOnInit() {
    this.todayDate = new Date();
    this.defaultMaxDate = new Date('2099-12-31');
    this.defaultMinDate = new Date('2000-01-01');
    this.defaultEndDate = this.datePipe.transform(new Date('2099-12-31'), 'longDate');
    this.defaultStartDate = this.datePipe.transform(this.todayDate, 'dd-MM-yyyy');
    this.defaultStartDateRcp = this.datePipe.transform(this.todayDate, 'dd-MM-yyyy');
    this.getvalidityArea();
    setTimeout(() => {
      this.loading = false;
    }, 500);
    this.authService.getFastRequestAPIs();
    this.formAssess = localStorage.getItem('FormObj') ? JSON.parse(localStorage.getItem('FormObj')) : this.plmFormObj;
    localStorage.setItem('FormObj', JSON.stringify(this.formAssess));
    if (this.formAssess.plmkeydate !== undefined) {
      this.defaultStartDateRcp = this.formAssess.plmkeydate;
    } else {
      this.defaultStartDateRcp = this.datePipe.transform(this.todayDate, 'dd-MM-yyyy');
      this.formAssess.plmkeydate = undefined;
    }
   }

   test(event, mc) {
    this.formAssess.plmkeydate = mc.inputFieldValue;
  }


  onNextClicked() {
    if (this.formAssess.plmkeydate === undefined || this.formAssess.plmkeydate === null) {
      this.formAssess.plmkeydate = this.datePipe.transform(this.todayDate, 'dd-MM-yyyy');
    }
    if (this.formAssess.plmvalidityarea === null) {
      this.validityarray.validityDescription = 'Default Validity Area';
      this.formAssess.plmvalidityarea = this.validityarray;
    }
    if (this.formAssess.plmvalidityarea === undefined) {
      this.formAssess.plmvalidityarea = '';
    }
    Object.assign({}, recipeModel);
    this.formAssess.selectedMarkets = null;
    recipeModel.formulationRequestDetails = undefined;
    recipeModel.functionList = undefined;
    if (this.formAssess.dosageForMarkets !== undefined) {
      this.formAssess.dosageForMarkets = null;
    }

    localStorage.setItem('FormObj', JSON.stringify(this.formAssess));
    this.dataTrans.setSelectedStep(1);
    this.router.navigate(['assess-requests/create-request']);
  }
  changerecipeDescription(recipeDescription) {
    recipeDescription = recipeDescription.length > 60 ? recipeDescription.substring(0, 60) + '...' : recipeDescription;
    return recipeDescription;
  }
  dropdownOnFocus(value) {
  }
  searchValidityArea(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (const value of this.validityAreavals) {
      this.validityAreaVal = value.validityDescription;
      if (this.validityAreaVal.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(this.validityAreaVal);
      }
    }
    this.filteredvalidityArea = filtered;
  }
  getvalidityArea() {
    const apiurl = environment.APIFAsT + '/getvalidityarea';
    this.assessService.getData(apiurl).subscribe((response1) => {
      this.validityAreavals = response1;
    }, (error) => {
    });
  }
  OnSearchWithCUC(cuc: string) {
    this.getvalidityArea();
    this.onRadioClicked = false;
    this.recipeTableFlag = true;
    this.loadingMsg = 'Fetching data from PLM';
    this.loading = true;
    const apiurl = environment.APIFAsT + '/recipelist/' + cuc;
    this.assessService.getData(apiurl).subscribe((response) => {
      this.loading = false;
      this.CucSearchResponse = response;
      for (const value of this.CucSearchResponse) {
        value.validFromDate = this.convertToDateFormat(value.validFromDate);
        value.validToDate = this.convertToDateFormat(value.validToDate);
        value.isDisable = true;
      }
      this.selectedPlmValue = response[0].cucCode;
    }, (error) => {
      this.loading = false;
    });

  }
  convertToDateFormat(date) {
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);

    const displayDate = day + '-' + month + '-' + year;
    return displayDate;
  }
  onCucChanged() {
    this.CucSearchResponse = [];
    this.onRadioClicked = false;
  }
  onValidityAreaChanged(event, rowValue) {
    this.formAssess.plmvalidityarea = rowValue.validityDescription;
    if (this.formAssess.plmvalidityarea === undefined) {
      this.formAssess.plmvalidityarea = 'Default Validity Area';
      this.additionalfielderror = false;
    } else {
      this.additionalfielderror = false;
    }
    /* istanbul ignore if */
    if (rowValue.validityDateVal === undefined) {
      this.formAssess.plmkeydate = this.datePipe.transform(this.todayDate, 'dd-MM-yyyy');
    }
  }
  onKeyDateChanged(event, rowValue) {
    this.formAssess.plmkeydate = this.datePipe.transform(rowValue.validityDateVal, 'dd-MM-yyyy');
    /* istanbul ignore if */
    if (this.formAssess.plmkeydate === undefined) {
      this.formAssess.plmkeydate = this.datePipe.transform(this.todayDate, 'dd-MM-yyyy');
      this.additionalfielderror = false;
      // this.additionalfielderror = true;
    } else {
      this.formAssess.plmkeydate = this.datePipe.transform(this.formAssess.plmkeydate, 'dd-MM-yyyy');
      this.additionalfielderror = false;
    }
  }

  getselectedkeydate(event) {
    this.formAssess.plmkeydate = this.datePipe.transform(this.formAssess.plmkeydate, 'dd-MM-yyyy');
    /* istanbul ignore if */
    if (this.formAssess.plmkeydate === undefined) {
      this.formAssess.plmkeydate = this.datePipe.transform(this.todayDate, 'dd-MM-yyyy');
      this.additionalfielderror = false;
      // this.additionalfielderror = true;
    } else {
      this.formAssess.plmkeydate = this.datePipe.transform(this.formAssess.plmkeydate, 'dd-MM-yyyy');
      this.additionalfielderror = false;
    }
  }
  onTabClick(activeTab) {
    this.activeTab = activeTab;
    localStorage.removeItem('FormObj');
  }
  onCucSelect(event, rowValue) {
    this.onRadioClicked = true;
    this.formAssess.fc = rowValue.cucCode;
    this.formAssess.alternate = rowValue.cucAlt;
    this.formAssess.version = rowValue.cucVer;
    this.formAssess.recipeType = rowValue.recipeType;
    this.formAssess.plmkeydate = this.datePipe.transform(rowValue.validityDateVal, 'dd-MM-yyyy');
    this.formAssess.plmvalidityarea = rowValue.validityDescription;
    if (this.formAssess.plmvalidityarea === undefined) {
      this.formAssess.plmvalidityarea = '';
      this.additionalfielderror = false;
    } else {
      this.additionalfielderror = false;
    }
    /* istanbul ignore if */
    if (rowValue.validityDateVal === undefined) {
      this.formAssess.plmkeydate = this.datePipe.transform(this.todayDate, 'dd-MM-yyyy');
    }
    for (const value of this.CucSearchResponse) {
      if (rowValue.cucAlt === value.cucAlt && rowValue.cucVer === value.cucVer) {
        value.isDisable = false;
      } else {
        value.isDisable = true;
      }
    }
  }
  onCancelClicked() {
    this.CucSearchResponse = [];
    this.onRadioClicked = false;
    localStorage.removeItem('FormObj');
    this.formAssess.fc = '';
    this.formAssess.recipeType = '';
    this.formAssess.version = '';
    this.formAssess.alternate = '';
    this.formAssess.plmkeydate = this.datePipe.transform(this.todayDate, 'dd-MM-yyyy');
    this.formAssess.plmvalidityarea = '';
    this.CUCforRecipeSearch = '';
    this.dataTrans.setSelectedStep(0);
    this.router.navigate(['assess-requests/select-recipe']);
  }
}
