export const RuletypesAttributeMapping = [
   // Max Rule Type
   {
      ruleTypeId: 21,
      ruleBucket: 'Group Based',
      ruleType: 'Substance Group - Max - group limit',
      attributeList: [{
         attributeName: 'Group Max',
         isRequired: true,
         attributeTemplate: 'SetLimit',
         isMandatory: true,
      },
      {
         attributeName: 'Function',
         isRequired: true,
         attributeTemplate: 'When',
         isMandatory: true,
      },
      {
         attributeName: 'Warning',
         isRequired: false,
         attributeTemplate: 'SetText',
         isMandatory: false,
      },
      {
         attributeName: 'Other Requirements & Restrictions',
         isRequired: false,
         attributeTemplate: 'SetText',
         isMandatory: false,
      },
         {
            attributeName : 'Conditional Requirements' ,
            isRequired : false,
            attributeTemplate: 'WhenText',
            isMandatory : false
         },
         {
            attributeName : 'Claims' ,
            isRequired : false,
            attributeTemplate: 'WhenText',
            isMandatory : false
         }
         // {
         //    attributeName : 'Source/Reference' ,
         //    isRequired : false,
         //    attributeTemplate: 'When'
         // },
         // {
         //    attributeName : 'Year Adopted' ,
         //    isRequired : false,
         //    attributeTemplate: 'When'
         // }
      ]
   },
   {
      ruleTypeId: 22,
      ruleBucket: 'Group Based',
      ruleType: 'Substance Group - Min - group limit',
      attributeList: [
         {
            attributeName: 'Group Min',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true
         },
         {
            attributeName: 'Function',
            isRequired: true,
            attributeTemplate: 'When',
            isMandatory: true
         },
         {
            attributeName: 'Warning',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
          {
             attributeName : 'Conditional Requirements' ,
             isRequired : false,
             attributeTemplate: 'WhenText',
             isMandatory : false
          },
          {
             attributeName : 'Claims' ,
             isRequired : false,
             attributeTemplate: 'WhenText',
             isMandatory : false
          }
         //  {
         //     attributeName : 'Source/Reference' ,
         //     isRequired : false,
         //     attributeTemplate: 'When',
         //     isMandatory : false,
         //  },
         //  {
         //     attributeName : 'Year Adopted' ,
         //     isRequired : false,
         //     attributeTemplate: 'When',
         //     isMandatory : false,
         //  }
      ]
   },
   {
      ruleTypeId: 23,
      ruleBucket: 'Group Based',
      ruleType: 'Substance Group - Range - group limit',
      attributeList: [
         {
            attributeName: 'Group Max',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true
         },
         {
            attributeName: 'Group Min',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true
         },
         {
            attributeName: 'Function',
            isRequired: true,
            attributeTemplate: 'When',
            isMandatory: true
         },
         {
            attributeName: 'Warning',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
          {
             attributeName : 'Conditional Requirements' ,
             isRequired : false,
             attributeTemplate: 'WhenText',
             isMandatory : false
          },
          {
             attributeName : 'Claims' ,
             isRequired : false,
             attributeTemplate: 'WhenText',
             isMandatory : false
          }
         //  {
         //     attributeName : 'Source/Reference' ,
         //     isRequired : false,
         //     attributeTemplate: 'When',
         //     isMandatory : false
         //  },
         //  {
         //     attributeName : 'Year Adopted' ,
         //     isRequired : false,
         //     attributeTemplate: 'When',
         //    isMandatory : false
         //  }
      ]
   },

   {
      ruleTypeId: 34,
      ruleBucket: 'Group Based',
      ruleType: 'Substance Group - Max - individual limit',
      attributeList: [{
         attributeName: 'Max',
         isRequired: true,
         attributeTemplate: 'SetLimit',
         isMandatory: true,
         isValid: true
      },
      {
         attributeName: 'Function',
         isRequired: false,
         attributeTemplate: 'When',
         isMandatory: false,
         isValid: true
      },
      {
         attributeName: 'Warning',
         isRequired: false,
         attributeTemplate: 'SetText',
         isMandatory: false,
         isValid: true
      },
      {
         attributeName: 'Other Requirements & Restrictions',
         isRequired: false,
         attributeTemplate: 'SetText',
         isMandatory: false,
         isValid: true
      },
         //  {
         //     attributeName : 'Specific Condition' ,
         //     isRequired : false,
         //     attributeTemplate: 'WhenText',
         //     isMandatory : false
         //  },
         //  {
         //     attributeName : 'Claims' ,
         //     isRequired : false,
         //     attributeTemplate: 'WhenText',
         //     isMandatory : false
         //  },
         //  {
         //     attributeName : 'Source/Reference' ,
         //     isRequired : false,
         //     attributeTemplate: 'When',
         //   isMandatory : false
         //  },
         //  {
         //     attributeName : 'Year Adopted' ,
         //     isRequired : false,
         //     attributeTemplate: 'When',
         //  isMandatory : false
         //  }
      ]
   },
   {
      ruleTypeId: 35,
      ruleBucket: 'Group Based',
      ruleType: 'Substance Group - Min - individual limit',
      attributeList: [
         {
            attributeName: 'Min',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true,
            isValid: true
         },
         {
            attributeName: 'Function',
            isRequired: false,
            attributeTemplate: 'When',
            isMandatory: false,
            isValid: true
         },
         {
            attributeName: 'Warning',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false,
            isValid: true
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false,
            isValid: true
         },
         //   {
         //      attributeName : 'Specific Condition' ,
         //      isRequired : false,
         //      attributeTemplate: 'WhenText',
         //      isMandatory : false
         //   },
         //   {
         //      attributeName : 'Claims' ,
         //      isRequired : false,
         //      attributeTemplate: 'WhenText',
         //      isMandatory : false
         //   },
         //   {
         //      attributeName : 'Source/Reference' ,
         //      isRequired : false,
         //      attributeTemplate: 'When'
         //   },
         //   {
         //      attributeName : 'Year Adopted' ,
         //      isRequired : false,
         //      attributeTemplate: 'When'
         //   }
      ]
   },
   {
      ruleTypeId: 36,
      ruleBucket: 'Group Based',
      ruleType: 'Substance Group - Range - individual limit',
      attributeList: [
         {
            attributeName: 'Max',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true,
            isValid: true
         },
         {
            attributeName: 'Min',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true,
            isValid: true
         },
         {
            attributeName: 'Function',
            isRequired: false,
            attributeTemplate: 'When',
            isMandatory: false,
            isValid: true
         },
         {
            attributeName: 'Warning',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false,
            isValid: true
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false,
            isValid: true
         },
         //   {
         //      attributeName : 'Specific Condition' ,
         //      isRequired : false,
         //      attributeTemplate: 'WhenText',
         //      isMandatory : false
         //   },
         //   {
         //      attributeName : 'Claims' ,
         //      isRequired : false,
         //      attributeTemplate: 'WhenText',
         //      isMandatory : false
         //   },
         //   {
         //      attributeName : 'Source/Reference' ,
         //      isRequired : false,
         //      attributeTemplate: 'When'
         //   },
         //   {
         //      attributeName : 'Year Adopted' ,
         //      isRequired : false,
         //      attributeTemplate: 'When'
         //   }
      ]
   },
   // Substance Based Max Rule Type
   {
      ruleTypeId: 1,
      ruleBucket: 'Substance Based',
      ruleType: 'Maximum limit',
      attributeList: [
         {
            attributeName: 'Max',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true // isRequired for checking the checkbox .. and IsMandatory is for required field validation
         },
         {
            attributeName: 'Warning',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Function',
            isRequired: true,
            attributeTemplate: 'When',
            isMandatory: false
         }
      ]
   },
   // Substance Based Min Rule Type
   {
      ruleTypeId: 2,
      ruleBucket: 'Substance Based',
      ruleType: 'Minimum limit',
      attributeList: [
         {
            attributeName: 'Min',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true // isRequired for checking the checkbox .. and IsMandatory is for required field validation
         },
         {
            attributeName: 'Warning',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Function',
            isRequired: true,
            attributeTemplate: 'When',
            isMandatory: false
         }
      ]
   },
   {
      ruleTypeId: 29,
      ruleBucket: 'Substance Based',
      ruleType: 'Hazard Classification',
      attributeList: [
         {
            attributeName: 'Warning',
            isRequired: true,
            attributeTemplate: 'SetText',
            isMandatory: true
         },
         {
            attributeName: 'Max',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: false // isRequired for checking the checkbox .. and IsMandatory is for required field validation
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         }
      ]
   },
   // Substance Based Range Rule Type
   {
      ruleTypeId: 3,
      ruleBucket: 'Substance Based',
      ruleType: 'Within Range',
      attributeList: [
         {
            attributeName: 'Max',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true // isRequired for checking the checkbox .. and IsMandatory is for required field validation
         },
         {
            attributeName: 'Min',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true
         },
         {
            attributeName: 'Warning',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Function',
            isRequired: true,
            attributeTemplate: 'When',
            isMandatory: false
         }
      ]
   },
   // Specific Condition Rule Type
   {
      ruleTypeId: 31,
      ruleBucket: 'Substance Based',
      ruleType: 'Conditional Requirements',
      attributeList: [
         {
            attributeName: 'Max',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: false // isRequired for checking the checkbox .. and IsMandatory is for required field validation
         },
         {
            attributeName: 'Min',
            isRequired: false,
            attributeTemplate: 'SetLimit',
            isMandatory: false
         },
         {
            attributeName: 'Warning',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Function',
            isRequired: false,
            attributeTemplate: 'When',
            isMandatory: false
         },
         {
            attributeName: 'Conditional Requirements',
            isRequired: true,
            attributeTemplate: 'WhenText',
            isMandatory: true
         },
         {
            attributeName: 'Claims',
            isRequired: false,
            attributeTemplate: 'WhenText',
            isMandatory: false
         }
      ]
   },
   // Claims Rule Type
   {
      ruleTypeId: 32,
      ruleBucket: 'Substance Based',
      ruleType: 'Claims',
      attributeList: [
         {
            attributeName: 'Max',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: false // isRequired for checking the checkbox .. and IsMandatory is for required field validation
         },
         {
            attributeName: 'Min',
            isRequired: false,
            attributeTemplate: 'SetLimit',
            isMandatory: false
         },
         {
            attributeName: 'Warning',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Function',
            isRequired: false,
            attributeTemplate: 'When',
            isMandatory: false
         },
         {
            attributeName: 'Conditional Requirements',
            isRequired: false,
            attributeTemplate: 'WhenText',
            isMandatory: false
         },
         {
            attributeName: 'Claims',
            isRequired: true,
            attributeTemplate: 'WhenText',
            isMandatory: true
         }
      ]
   },
   // Warning Rule Type
   {
      ruleTypeId: 15,
      ruleBucket: 'Substance Based',
      ruleType: 'Substance Warnings',
      attributeList: [
         {
            attributeName: 'Warning',
            isRequired: true,
            attributeTemplate: 'SetText',
            isMandatory: true
         },
         {
            attributeName: 'Function',
            isRequired: true,
            attributeTemplate: 'When',
            isMandatory: false // isRequired for checking the checkbox .. and IsMandatory is for required field validation
         }
      ]
   },
   // Other Requirements & Restrictions
   {
      ruleTypeId: 33,
      ruleBucket: 'Substance Based',
      ruleType: 'Other Requirements & Restrictions',
      attributeList: [
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: true,
            attributeTemplate: 'SetText',
            isMandatory: true
         },
         {
            attributeName: 'Function',
            isRequired: true,
            attributeTemplate: 'When',
            isMandatory: false
         }
      ]
   },
   // Phased Out Rule
   {
      ruleTypeId: 24,
      ruleBucket: 'Substance Based',
      ruleType: 'Phasing out',
      attributeList: [
         {
            attributeName: 'Phase out',
            isRequired: true,
            attributeTemplate: 'SetDate',
            isMandatory: true
         },
         {
            attributeName: 'Warning',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Function',
            isRequired: true,
            attributeTemplate: 'When',
            isMandatory: true
         }
      ]
   },


   // Conflicting Substance
   {
      ruleTypeId: 20,
      ruleBucket: 'Substance Based',
      ruleType: 'Conflicting Substance',
      attributeList: [
         {
            attributeName: 'Max',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true
         },
         {
            attributeName: 'Warning',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Function',
            isRequired: true,
            attributeTemplate: 'When',
            isMandatory: false
         },
         {
            attributeName: 'Conditional Requirements',
            isRequired: false,
            attributeTemplate: 'WhenText',
            isMandatory: false
         },
         {
            attributeName: 'Claims',
            isRequired: false,
            attributeTemplate: 'WhenText',
            isMandatory: false
         },
         {
            attributeName: 'Conflicting Substance',
            isRequired: true,
            attributeTemplate: 'WhenSelect',
            isMandatory: true
         }
      ]
   },
   // Adulterated Rule
   {
      ruleTypeId: 28,
      ruleBucket: 'Substance Based',
      ruleType: 'Adulterated',
      attributeList: [
         {
            attributeName: 'Warning',
            isRequired: true,
            attributeTemplate: 'SetText',
            isMandatory: true
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Function',
            isRequired: true,
            attributeTemplate: 'When',
            isMandatory: false
         }
      ]
   },
   // Prohibited Rule
   {
      ruleTypeId: 4,
      ruleBucket: 'Substance Based',
      ruleType: 'Prohibited',
      attributeList: [
         {
            attributeName: 'Max',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true
         },
         {
            attributeName: 'Warning',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Function',
            isRequired: true,
            attributeTemplate: 'When',
            isMandatory: true
         },
         {
            attributeName: 'Conditional Requirements',
            isRequired: false,
            attributeTemplate: 'WhenText',
            isMandatory: false
         },
         {
            attributeName: 'Claims',
            isRequired: false,
            attributeTemplate: 'WhenText',
            isMandatory: false
         }
      ]
   },
// Combination Substance
{
   ruleTypeId: 27,
   ruleBucket: 'Substance Based',
   ruleType: 'Combination Substance',
   attributeList: [
      {
         attributeName: 'Max',
         isRequired: false,
         attributeTemplate: 'SetLimit',
         isMandatory: false
      },
      {
         attributeName: 'Min',
         isRequired: true,
         attributeTemplate: 'SetLimit',
         isMandatory: true
      },
      {
         attributeName: 'Warning',
         isRequired: false,
         attributeTemplate: 'SetText',
         isMandatory: false
      },
      {
         attributeName: 'Other Requirements & Restrictions',
         isRequired: false,
         attributeTemplate: 'SetText',
         isMandatory: false
      },
      {
         attributeName: 'Function',
         isRequired: true,
         attributeTemplate: 'When',
         isMandatory: true
      },
      {
         attributeName: 'Conditional Requirements',
         isRequired: false,
         attributeTemplate: 'WhenText',
         isMandatory: false
      },
      {
         attributeName: 'Claims',
         isRequired: false,
         attributeTemplate: 'WhenText',
         isMandatory: false
      },
      {
         attributeName: 'Combination Substance',
         isRequired: true,
         attributeTemplate: 'CombinationSubSelect',
         isMandatory: true
      }
   ]
},
   // Product Based Product Standard
   {
      ruleTypeId: 41,
      ruleBucket: 'Product Based',
      ruleType: 'Product Standard',
      attributeList: [
         {
            attributeName: 'Max',
            isRequired: false,
            attributeTemplate: 'SetLimit',
            isMandatory: false
         },
         {
            attributeName: 'Min',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true
         },
         {
            attributeName: 'Function',
            isRequired: true,
            attributeTemplate: 'When',
            isMandatory: true
         },
         {
            attributeName: 'Conditional Requirements',
            isRequired: false,
            attributeTemplate: 'WhenText',
            isMandatory: false
         },
         {
            attributeName: 'Claims',
            isRequired: false,
            attributeTemplate: 'WhenText',
            isMandatory: false
         }
      ]
   },

   // Product Warning
   {
      ruleTypeId: 10,
      ruleBucket: 'Product Based',
      ruleType: 'Product Warnings',
      attributeList: [
         {
            attributeName: 'Warning',
            isRequired: true,
            attributeTemplate: 'SetText',
            isMandatory: true
         }
      ]
   },

   // CUC Property - Max
   {
      ruleTypeId: 7,
      ruleBucket: 'Product Based',
      ruleType: 'CUC Property - Max',
      attributeList: [
         {
            attributeName: 'Max',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true
         },
         {
            attributeName: 'Warning',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'CUC',
            isRequired: true,
            attributeTemplate: 'WhenPropertySelect',
            isMandatory: true
         },
         {
            attributeName: 'Conditional Requirements',
            isRequired: false,
            attributeTemplate: 'WhenText',
            isMandatory: false
         },
         {
            attributeName: 'Claims',
            isRequired: false,
            attributeTemplate: 'WhenText',
            isMandatory: false
         }
      ]
   },
   // CUC Property - Min
   {
      ruleTypeId: 8,
      ruleBucket: 'Product Based',
      ruleType: 'CUC Property - Min',
      attributeList: [
         {
            attributeName: 'Min',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true
         },
         {
            attributeName: 'Warning',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'CUC',
            isRequired: true,
            attributeTemplate: 'WhenPropertySelect',
            isMandatory: true
         },
         {
            attributeName: 'Conditional Requirements',
            isRequired: false,
            attributeTemplate: 'WhenText',
            isMandatory: false
         },
         {
            attributeName: 'Claims',
            isRequired: false,
            attributeTemplate: 'WhenText',
            isMandatory: false
         }
      ]
   },
   // CUC Property - Range
   {
      ruleTypeId: 9,
      ruleBucket: 'Product Based',
      ruleType: 'CUC Property - Within Range',
      attributeList: [
         {
            attributeName: 'Max',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true
         },
         {
            attributeName: 'Min',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true
         },
         {
            attributeName: 'Warning',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'CUC',
            isRequired: true,
            attributeTemplate: 'WhenPropertySelect',
            isMandatory: true
         },
         {
            attributeName: 'Conditional Requirements',
            isRequired: false,
            attributeTemplate: 'WhenText',
            isMandatory: false
         },
         {
            attributeName: 'Claims',
            isRequired: false,
            attributeTemplate: 'WhenText',
            isMandatory: false
         }
      ]
   },
   // Minimum Mandatory Substance
   {
      ruleTypeId: 11,
      ruleBucket: 'Product Based',
      ruleType: 'Minimum Mandatory Substance',
      attributeList: [
         {
            attributeName: 'Min',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true
         },
         {
            attributeName: 'Warning',
            isRequired: true,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Function',
            isRequired: false,
            attributeTemplate: 'When',
            isMandatory: false
         },
         {
            attributeName: 'Substance',
            isRequired: true,
            attributeTemplate: 'WhenSelect',
            isMandatory: true
         }
      ]
   },
   // Product Minimum Mandatory Substance
   {
      ruleTypeId: 45,
      ruleBucket: 'Product Based',
      ruleType: 'Product Minimum Mandatory Substance(s)',
      attributeList: [
         {
            attributeName: 'Min',
            isRequired: true,
            attributeTemplate: 'SetLimit',
            isMandatory: true
         },
         {
            attributeName: 'Warning',
            isRequired: true,
            attributeTemplate: 'SetText',
            isMandatory: false
         },
         {
            attributeName: 'Function',
            isRequired: false,
            attributeTemplate: 'When',
            isMandatory: false
         },
         {
            attributeName: 'Substance(s)',
            isRequired: true,
            attributeTemplate: 'WhenMultiSelect',
            isMandatory: true
         }
      ]
   },
   // Allowed Function
   {
      ruleTypeId: 14,
      ruleBucket: 'Substance Based',
      ruleType: 'Allowed Function',
      attributeList: [
         {
            attributeName: 'Warning',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         }
      ]
   },
   // Allowed Function
   {
      ruleTypeId: 44,
      ruleBucket: 'Group Based',
      ruleType: 'Regulatory Group - Function',
      attributeList: [
         {
            attributeName: 'Warning',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         }
      ]
   },
    // Regulatory Group Warning
    {
      ruleTypeId: 48,
      ruleBucket: 'Group Based',
      ruleType: 'Regulatory Group - Warning',
      attributeList: [
         {
            attributeName: 'Warning',
            isRequired: true,
            attributeTemplate: 'SetText',
            isMandatory: true
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         }
      ]
   },
   // CUC Property Warning
   {
      ruleTypeId: 49,
      ruleBucket: 'Product Based',
      ruleType: 'CUC Property - Warning',
      attributeList: [
         {
            attributeName: 'Warning',
            isRequired: true,
            attributeTemplate: 'SetText',
            isMandatory: true
         },
         {
            attributeName: 'CUC Property',
            isRequired: true,
            attributeTemplate: 'WhenPropertySelect',
            isMandatory: true
         },
         {
            attributeName: 'Other Requirements & Restrictions',
            isRequired: false,
            attributeTemplate: 'SetText',
            isMandatory: false
         }
         ,
         {
            attributeName : 'Claims' ,
            isRequired : false,
            attributeTemplate: 'WhenText',
            isMandatory : false
          }
      ]
   },

];
