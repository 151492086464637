import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssessRequestRaService } from '../assess-request-ra.service';

@Injectable({
  providedIn: 'root'
})
export class ReportSubmitService {

  constructor(private assessraService: AssessRequestRaService) { }
/* istanbul ignore next */
  exponentialToDecimal(value) {
    if ((/[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)/g).test(value)) {
      return Number(value).toFixed(20).replace(/\.?0+$/, '');
    } else {
      return value;
    }
  }
/* istanbul ignore next */
  getRPCList(rpcListAPI): Observable<any> {
    return this.assessraService.getData(rpcListAPI);
  }
  getTFCStatus(rpcTFCAPI): Observable<any> {
    return this.assessraService.getData(rpcTFCAPI);
  }
/* istanbul ignore next */
  getRPC(forTreeAPI): Observable<any> {
    return this.assessraService.getData(forTreeAPI);
  }
}
