export class PureRulesDetailsModel {
    Market: MarketDetails;
    PureCode;
    RpcDetails: RPCDetails[];
    RpcFullDetails;
    SirId;
    CascadeRpc;
    applicableRuleTypeList = [];
    selectedRpcs;
    selectedParentRpcs;
    selectedCF;
    selectedRuleType;
    allowedFunctions;
    ruleCategory;
    rulesListForSpecificPure;
    dosageFlag;
    servingSizeFlag;
    allergenFlag;
    selectedLeafRpcs = [];
    selectedMarketForRulesDisplay;
}

class MarketDetails {
    marketId;
    marketName;
}

export class RPCDetails {
    RpcId;
    RpcName;
}
export class PureSpecificRules {
    RuleId;
    PureCode;
    RuleTypeID;
    RuleType;
    SubstanceId;
}


// tslint:disable-next-line: no-var-keyword
export var pureRulesDetailsModel = new PureRulesDetailsModel();

export class RequestFlags {
    createRequest: boolean;
    viewRequest: boolean;
    EditRequest: boolean;
}
export let requestFlags = new RequestFlags();

export class RuleActionFlags {
    viewRule: boolean;
    editRule: boolean;
}
export let ruleFlags = new RuleActionFlags();

export class ParallelRule {
    rpcsForParallelRuleData = [];
    intendedUseForParallelRule;
    prepMethodForParallelRule;
    selectedMarket;
    selectedRPC;
    selectedrpc1;
    selectedrpc2;
    selectedrpc3;
    selectedrpc4;
    selectedrpc5;
    selectedrpc6;
}
export let parallelRuleObj = new ParallelRule();

