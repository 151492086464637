import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { PROPERTY_LIST_COLUMNS } from 'src/app/common/models/tableColumns';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-property-master',
  templateUrl: './property-master.component.html',
  styleUrls: ['./property-master.component.scss']
})
export class PropertyMasterComponent implements OnInit {
  propertyMasterListColumns;
  loading = false;
  editPropertyMaster = false;
  exportDialog = false;
  filteredRows: any = [];
  selectedRows: any = [];
  loadingMsg;
  userId: any;
  userDetails: any;
  parentPropertiesToDD = [];
  selectedParentProperty = [];
  childPropertiesListData = [];
  selectedPropertyId;
  selectedPropertyName;
  propertyEdit: any = {};
  selectedStatus;
  status = [];

  constructor(
    private apiService: AssessRequestRaService,
    public matSnackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.propertyMasterListColumns = PROPERTY_LIST_COLUMNS;
    this.userDetails = JSON.parse(localStorage.getItem('userRolesPermissions'));
    this.userId = this.userDetails.userId;
    this.status = [
      { name: 'Active', code: 'Y' },
      { name: 'In-Active', code: 'N'},
    ];
    this.addParentProperties();
  }
  addParentProperties() {
    this.loading = true;
    this.loadingMsg = 'Fetching CUC Properties';
    const apiurl = environment.APIFAsTRule + '/Properties/parentProperties/0';
    this.apiService.getData(apiurl).subscribe((response1) => {
      this.loading = false;
      this.parentPropertiesToDD = response1;
    }, (error) => {
      this.loading = false;
    });
  }
  getAllProperties(property) {
    this.loading = true;
    this.loadingMsg = 'Fetching Properties';
    const apiurl = environment.APIFAsTRule + '/Properties/childProperties/' + property.propertyId;
    this.apiService.getData(apiurl).subscribe((response) => {
      this.loading = false;
      this.childPropertiesListData = response;
      for (const value of this.childPropertiesListData) {
        if (value.isActive === 'Y') {
          value.isActive = 'Active';
        } else {
          value.isActive = 'In-Active';
        }
      }
    }, (error) => {
      this.loading = false;
    });
  }
  editPropertyData(propertyMasterList) {
    this.editPropertyMaster = true;
    this.propertyEdit.propertyId = propertyMasterList._selection.propertyId;
    this.propertyEdit.propertyName = propertyMasterList._selection.propertyName;
    propertyMasterList._selection.isActive = propertyMasterList._selection.isActive === 'Active' ? 'Active' : 'In-Active';
    this.selectedStatus = this.status.filter(f => f.name === propertyMasterList._selection.isActive)[0];
  }
  updateFunctionMasterData() {
    const data = {
      PropertyId: this.propertyEdit.propertyId,
      propertyName: this.propertyEdit.propertyName,
      IsActive: this.selectedStatus.code,
      ModifiedByUserId: this.userId,
    };
    const apiurl = environment.APIFAsT + '/updateProperty';
    this.apiService.postData(apiurl, data).subscribe((response) => {
      this.loading = false;
      this.editPropertyMaster = false;
      this.getAllProperties(this.selectedParentProperty);
      this.matSnackBar.open('Property details updated successfully ', 'close', {
        duration: 3000,
        panelClass: ['success']
      });
    }, (error) => {
      this.editPropertyMaster = false;
      this.getAllProperties(this.selectedParentProperty);
    });
  }
  onSelectType(event) {
    if (event) {
      this.selectedStatus = event;
    }
  }

  showExportDialog() {
    this.exportDialog = true;
  }

  onFilter(event) {
    this.filteredRows = event.filteredValue;
  }

  /* istanbul ignore next */
  onClearFilters(propertyMasterList: any) {
    propertyMasterList.reset();
    this.filteredRows = [];
    for (const value of this.propertyMasterListColumns) {
      if (value.field !== 'check') {
        const colname = value.field + '_val';
        if ((<HTMLInputElement> document.getElementById(colname)) && (<HTMLInputElement> document.getElementById(colname)).value) {
          (<HTMLInputElement> document.getElementById(colname)).value = '';
        }
      }
    }
  }
}
