import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import HtmlTableToJson from 'html-table-to-json';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { ExportPdfService } from 'src/app/common/services/export-pdf.service';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/common/services/data.service';
import { AssessRequestRaService } from '../../assess-request-ra.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report-action-icons',
  templateUrl: './report-action-icons.component.html',
  styleUrls: ['./report-action-icons.component.scss']
})
export class ReportActionIconsComponent implements OnInit {
  @Input() completeJSON = {
    requestStatus: '',
    requestDisplayID: 0,
    cucDescription: '',
    createdByUserId: 0,
    completedDate: ''
  };
  isHazardClassification = false;
  isComments = false;
  isRnDInputs = false;
  isCompleteConf = false;
  commentsPayLoad;
  isCompleted;
  loading = false;
  REPORT_COMMENTS = '';
  fragranceMessage = 'Note : More than one fragrance/s are present in the formula, an offline fragrance assessment by the fragrance expert is required.';
  contentText = '';
  linkArr = [];
  textbetweenTags = [];
  contentTextRC = '';
  linkArrRC = [];
  textbetweenTagsRC = [];
  divisionData = '';
  requestID: any;
  completeloading = false;
  reportData;
  requestIdData;
  actualRequestStatus = '';
  constructor(private accessRequestRaService: AssessRequestRaService, private matSnackBar: MatSnackBar, private exportPdfService: ExportPdfService, private workListService: WorklistService, private dataTrans: DataService, private route: Router) { }
  ngOnInit() {
    this.dataTrans.castUser.subscribe(res => {
      const splitUrl = this.route.url.split('/');
      if (splitUrl[2] === 'submit-report-plm') {
        this.actualRequestStatus = 'Completed';
      }
      this.requestIdData = res;
      this.divisionData = res.division;
      this.actualRequestStatus = res.requestStatus;
      if (res.division === undefined || res.division === null) {
        this.divisionData = res.tfc.tfcLevel === 5 ? res.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName : res.tfc.parentTFC.parentTFC.parentTFC.tfcName;
      } else {
        this.divisionData = res.division;
      }
    });
    this.dataTrans.requestReportData.subscribe(res => {
      this.reportData = res;
    });
    if (sessionStorage.getItem('IsPLmRequest') === 'yes') {
      this.isCompleted = sessionStorage.getItem('actualStatus').toString() === 'Completed' ? true : false;
      this.completeJSON.completedDate = sessionStorage.getItem('completeDate');
    } else {
      this.isCompleted =  this.actualRequestStatus === 'Completed' ? true : false;
    }
    recipeModel.requestDisplayID = recipeModel.requestDisplayID === undefined ? this.completeJSON.requestDisplayID : recipeModel.requestDisplayID;
    this.commentsPayLoad = {
      RequestStatusDisplayId: recipeModel.requestDisplayID,
      ReportComments: null
    };
    this.requestID = recipeModel.requestID;
    this.dataTrans.castUser.subscribe(res => {
      this.requestIdData = res;
    });
    this.dataTrans.requestReportData.subscribe(res => {
      this.reportData = res;
    });
  }

  toogleHazardClassification() {
    this.isHazardClassification = !this.isHazardClassification;
  }
  toggleRnDInputs() {
    this.isRnDInputs = !this.isRnDInputs;
  }
  toggleComplete() {
    this.isCompleteConf = !this.isCompleteConf;
  }
  /* istanbul ignore next */
  completeRequest() {
    this.isCompleteConf = false;
    this.completeloading = true;
    let completeAPIData;
    completeAPIData = this.reportData;
    completeAPIData.requestStatus = 'Completed';
    completeAPIData.requestStatusID = '170';
    completeAPIData.reportComments = '';
    completeAPIData.compBanned = this.completeJSON['compBanned'];
    completeAPIData.compFailed = this.completeJSON['compFailed'];
    completeAPIData.compWarning = this.completeJSON['compWarning'];
    completeAPIData.compInputMissing = this.completeJSON['compInputMissing'];
    completeAPIData.compositionRuleDoesnotExist = this.completeJSON['compositionRuleDoesnotExist'];
    completeAPIData.compRuleExecIssue = this.completeJSON['compRuleExecIssue'];
    completeAPIData.compNoIssue = this.completeJSON['compNoIssue'];
    completeAPIData.compNonRest = this.completeJSON['compNonRest'];
    completeAPIData.prodFailed = this.completeJSON['prodFailed'];
    completeAPIData.prodWarning = this.completeJSON['prodWarning'];
    completeAPIData.prodInputMissing = this.completeJSON['prodInputMissing'];
    completeAPIData.prodRuleNotExist = this.completeJSON['prodRuleNotExist'];
    completeAPIData.prodRuleExecIssue = this.completeJSON['prodRuleExecIssue'];
    completeAPIData.prodNoIssue = this.completeJSON['prodNoIssue'];
    completeAPIData.compositionStatus = this.completeJSON['compositionStatus'];
    completeAPIData.productStatus = this.completeJSON['productStatus'];
    completeAPIData.compositionTotalPures = this.completeJSON['compositionTotalPures'];
    completeAPIData.productTotalPures = this.completeJSON['productTotalPures'];
    completeAPIData.isRuleDoesnotExistToShow = false;
    const serviceCall = this.accessRequestRaService.completeReport(completeAPIData);
    serviceCall.subscribe((data) => {
      if (data.result === 'Success') {
        this.isCompleted = true;
        this.getCompletedDate();
        localStorage.setItem('isCompleted', 'true');
        this.workListService.setRequestStatus('Completed');
        this.matSnackBar.open(`Report completed successfully`, 'close', {
          duration: 5000,
          panelClass: ['success']
        });
        this.sendEmailNotification();
      }
    }, (error) => {
      this.completeloading = false;
      if (error.status !== 200) {
        console.log('error');
      }
    });
  }

  sendEmailNotification() {
    const payLoad = {
      NotificationGroup: 'COMPLETED',
      Subject: null,
      EmailBody: null,
      ToEmailList: this.completeJSON.createdByUserId,
      FromEmail: null,
      CCEmailList: null,
      EmailType: 'html',
      UPD: this.reportData.tfc.tfcLevel === 5 ? this.reportData.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId : this.reportData.tfc.parentTFC.parentTFC.parentTFC.tfcId,
      PureCodes: null,
      RequestID: this.completeJSON.requestDisplayID,
      CUCDescription: this.completeJSON.cucDescription,
      MarketId: this.reportData.marketDetails.marketId
    };

    const serviceCall = this.accessRequestRaService.sendEmailNotification(payLoad);
    serviceCall.subscribe((data) => {
      if (data.result === 'Success') {
        this.matSnackBar.open(`Email notification sent successfully`, 'close', {
          duration: 5000,
          panelClass: ['success']
        });
      }
    }, (error) => {
      this.completeloading = false;
      if (error.status !== 200) {
        console.log('error');
      }
    });
  }

  generatePdf() {
    this.loading = true;
    recipeModel.requestDisplayID = recipeModel.requestDisplayID === undefined ? this.completeJSON.requestDisplayID : recipeModel.requestDisplayID;
    this.exportPdfService.clearContent();
    this.buildPdf();
    setTimeout(() => {
      this.loading = false;
      this.exportPdfService.downloadPdf();
    }, 6000);
  }
  /* istanbul ignore next */
  move(arr, oldIndex, newIndex) {
    while (oldIndex < 0) {
      oldIndex += arr.length;
    }
    while (newIndex < 0) {
      newIndex += arr.length;
    }
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length;
      while ((k--) + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }

  formatCompositionTabContent(data) {
    const NoIssuesIdentified = this.move(data, 5, 3);
    const modifiedData = this.move(NoIssuesIdentified, 7, 4);
    return modifiedData;
  }
  // /* istanbul ignore next */
  formatComponentTabContent(data) {
    const NoIssuesIdentified = this.move(data, 4, 2);
    return NoIssuesIdentified;
  }
  /* istanbul ignore next */
  buildPdf() {
    const data = recipeModel.rpcRefinementData.formulationRequestDetails.filter(f => f.ingType === 'ZING_FRAGR');
    const tfcData = recipeModel.rpcRefinementData.tfc.tfcFullText.split('_')[0];
    if (data.length > 0) {
      this.fragranceMessage = data.map(s => s.specID).filter((n, i) => data.map(s => s.specID).indexOf(n) === i).length > 1 ? this.fragranceMessage : '';
    } else {
      this.fragranceMessage = '';
    }
    if (this.fragranceMessage === '') {
      if ((recipeModel.rpcRefinementData.fragranceRuleResults === null || recipeModel.rpcRefinementData.fragranceRuleResults === undefined || recipeModel.rpcRefinementData.fragranceRuleResults.length === 0) && (tfcData !== 'F&R')) {
        this.fragranceMessage = localStorage.getItem('FragranceMessage');
      } else {
        this.fragranceMessage = '';
      }
    }
    this.systemGenerateText();
    this.setPageTitle();
    this.exportPdfService.addContent({ text: 'Report', style: 'reportTitle' });
    this.exportPdfService.addContent({ text: ' Key data columns are displayed in this PDF version of the report for easy reference. For detailed report , kindly download the excel version. ', style: 'info' });
    this.setDate(recipeModel.rpcRefinementData);
    this.setProjectDetails(recipeModel.rpcRefinementData);
    this.setFormulationDetails(recipeModel.rpcRefinementData);
    this.setRpcRefinement(recipeModel.rpcRefinementData.rpcLeafNodes);
    this.setOverallStatus(recipeModel.rpcRefinementData);
    this.setReportComments();
    this.setKeyColor();
    if (recipeModel.rpcRefinementData.exportData.length > 0) {
      recipeModel.rpcRefinementData.exportData.forEach((component, index) => {
        if (component.multiComponentName != null) { this.setComponentDetails(component.multiComponentName, index); }
        this.setCommanData(component.compositionTabContent, ' Report on Composition ', 'pures', true);
        this.setCommanData(component.propertyTabContent, ' Report on Property ', 'properties', true);
        this.setCommanData(component.productTabContent, ' Report on Product ', 'products', true);
      });
    }
    this.setHazardClassification();
  }

  setKeyColor() {
    this.exportPdfService.addContent({ text: ' Status Key ', style: 'header' });
    const tableData = {
      style: 'table3',
      table: {
        body: [
          [{ text: 'Status icons', style: 'tableHeader' }, { text: 'Status Description', style: 'tableHeader' }],
          [{ svg: `${this.exportPdfService.getSvgIcon('banned')}` }, ` Banned `],
          [{ svg: `${this.exportPdfService.getSvgIcon('failed')}` }, ` Failed `],
          [{ svg: `${this.exportPdfService.getSvgIcon('warning')}` }, ` Warnings `],
          [{ svg: `${this.exportPdfService.getSvgIcon('plm')}` }, ` Input Missing/ Data Mismatch `],
          [{ svg: `${this.exportPdfService.getSvgIcon('no-rule-found')}` }, ` Rule does not exist `],
          [{ svg: `${this.exportPdfService.getSvgIcon('rule-execution-issues')}` }, ` Consult RA `],
          [{ svg: `${this.exportPdfService.getSvgIcon('passed')}` }, ` No Issues Identified `],
          [{ svg: `${this.exportPdfService.getSvgIcon('non-restricted')}` }, ` Non - Restricted `],
        ]
      },
    };

    this.exportPdfService.addContent(tableData);
  }

  systemGenerateText() {
    this.exportPdfService.addContent({ text: `This is a system generated compliance report for requested market. Please contact local RA for any queries/clarification.`, style: 'systemText', italics: true, margin: [0, 0, 0, 20] });
    this.exportPdfService.addContent({ text: this.fragranceMessage, style: 'systemText', italics: false, margin: [0, 0, 0, 20] });
  }

  setPageTitle() {
    const tableData = {
      table: {
        margin: [0, 0, 0, 40],
        body: [[{ text: ' Confidential Document ', style: 'pageHeader', border: [false, true, false, false], fillColor: '#042042' },
        { text: 'dummy test for space ', color: '#042042', border: [false, true, false, false], fillColor: '#042042' },
        { text: 'dummy test for space ', color: '#042042', border: [false, true, false, false], fillColor: '#042042' },
        { text: ' This is a downloaded report and may not be updated. To view the latest report, please visit ', style: 'defaultStyle2', border: [false, true, false, false], fillColor: '#042042', },
        { text: 'FAsT application', style: 'link', link: `${window.location.href}`, border: [false, true, false, false], fillColor: '#042042', },
        { text: 'dummy test for space ', color: '#042042', border: [false, true, false, false], fillColor: '#042042' },
        ]]
      }
    };
    this.exportPdfService.addContent(tableData);
  }

  setNodata() {
    this.exportPdfService.addContent({ text: `No data found`, style: 'defaultStyle', italics: true, margin: [20, 0, 0, 10] });
  }
  // /* istanbul ignore next */
  setComponentDetails(name, index) {
    this.exportPdfService.addContent({ text: ` Component ${index + 1} - ${name} `, style: 'component' });
  }
  setProjectDetails(f) {
    let manufacturecuntry = ' ';
    let manufacturesites = ' ';
    if (f.marketDetails.projDetail) {
      manufacturecuntry = f.marketDetails.projDetail.manufactureCountryDescription === null ? '' : f.marketDetails.projDetail.manufactureCountryDescription;
      manufacturesites = f.marketDetails.projDetail.manuSite === null || f.marketDetails.projDetail.manuSite.length === 0 || f.marketDetails.projDetail.manuSite[0].othersitedesc === undefined || f.marketDetails.projDetail.manuSite.map(m => m.othersitedesc) === undefined ? '' : f.marketDetails.projDetail.manuSite.map(m => m.othersitedesc).join(',');
      this.contentOptimize(f.marketDetails.projDetail.requestComments);
    }
    this.exportPdfService.addContent({ text: ' Project Details ', style: 'header' });
    const tableData = {
      style: 'table',
      table: {
        widths: ['auto', f.marketDetails.projDetail === null ? 250 : 'auto'],
        body: [
          ['Market', `  ${f.marketDetails === null ? '' : f.marketDetails.marketName} `],
          ['Project Name', `  ${f.marketDetails.projDetail === null || f.marketDetails.projDetail === undefined ? '' : f.marketDetails.projDetail.projectName} `],
          ['Project Type', ` ${f.marketDetails.projDetail === null || f.marketDetails.projDetail === undefined ? ' ' : f.marketDetails.projDetail.projectTypeDesc} `],
          ['Project Description', ` ${f.marketDetails.projDetail === null || f.marketDetails.projDetail === undefined ? ' ' : f.marketDetails.projDetail.projectDescription} `],
          ['Project Type', ` ${f.marketDetails.projDetail === null || f.marketDetails.projDetail === undefined ? ' ' : f.marketDetails.projDetail.projectTypeDesc} `],
          ['Brand', ` ${f.marketDetails.projDetail === null || f.marketDetails.projDetail === undefined ? ' ' : f.marketDetails.projDetail.brandDesc} `],
          ['Launch Timeline', ` ${f.marketDetails.projDetail === null || f.marketDetails.projDetail === undefined ? ' ' : f.marketDetails.projDetail.launchTimeLine} `],
          ['Manufacturing Country', ` ${f.marketDetails.projDetail === null || f.marketDetails.projDetail === undefined ? ' ' : manufacturecuntry} `],
          ['Manufacturing Sites ', ` ${f.marketDetails.projDetail === null ? ' ' : manufacturesites} `],
          ['Comments', [
            `${f.marketDetails.projDetail === null ? '' : this.contentText} `,
            {
              text: this.textbetweenTags[0] !== undefined ? this.handleSpecialCharactersForHyperlinks(this.textbetweenTags[0]) : '',
              link: this.linkArr[0],
              color: 'blue',
            },
            {
              text: this.textbetweenTags[1] !== undefined ? this.handleSpecialCharactersForHyperlinks(this.textbetweenTags[1]) : '',
              link: this.linkArr[1],
              color: 'blue',
            },
            {
              text: this.textbetweenTags[2] !== undefined ? this.handleSpecialCharactersForHyperlinks(this.textbetweenTags[2]) : '',
              link: this.linkArr[2],
              color: 'blue',
            },
            {
              text: this.textbetweenTags[3] !== undefined ? this.handleSpecialCharactersForHyperlinks(this.textbetweenTags[3]) : '',
              link: this.linkArr[3],
              color: 'blue',
            },
            {
              text: this.textbetweenTags[4] !== undefined ? this.handleSpecialCharactersForHyperlinks(this.textbetweenTags[4]) : '',
              link: this.linkArr[4],
              color: 'blue',
            },
            {
              text: this.textbetweenTags[5] !== undefined ? this.handleSpecialCharactersForHyperlinks(this.textbetweenTags[5]) : '',
              link: this.linkArr[5],
              color: 'blue',
            },
            {
              text: this.textbetweenTags[6] !== undefined ? this.handleSpecialCharactersForHyperlinks(this.textbetweenTags[6]) : '',
              link: this.linkArr[6],
              color: 'blue',
            },
          ],
          ]

        ]
      },
    };

    this.exportPdfService.addContent(tableData);
  }
  /* istanbul ignore next */
  setFormulationDetails(f) {
    const tfcStatusData = localStorage.getItem('tfcStatus');
    this.exportPdfService.addContent({ text: ' Formulation Details ', style: 'header' });
    const skeyDate = f.marketDetails.projDetail === null || f.marketDetails.projDetail === undefined ? '' : f.marketDetails.projDetail.keyDate !== null ? f.marketDetails.projDetail.keyDate.split('T')[0] : '';
    const svalidityArea = f.marketDetails.projDetail === null || f.marketDetails.projDetail === undefined ? '' : f.marketDetails.projDetail.validityArea != null ? f.marketDetails.projDetail.validityArea : '';
    const tableData = {
      style: 'table',
      table: {
        body: [
          [' Request ID ', ` ${f.requestDisplayID} `],
          [' CUC Code/ALT/VER ', ` ${f.cucCode}/${f.cucAlt}/${f.cucVer} `],
          [' Validity Area ', svalidityArea],
          [' Key Date', skeyDate],
          [' CUC Description ', ` ${f.cucDescription} `],
          [' TFC Path ', [{
            text: tfcStatusData === '0' ? f.tfc.tfcFullText + '\n The PLM TFC has been remapped/retired. Please create a new request with updated PLM TFC.' : f.tfc.tfcFullText,
            color: tfcStatusData === '0' ? 'red' : 'black',
          }
          ]],
          [' Market ', ` ${f.marketDetails.marketName} `],
          [' Intended Use ', ` ${f.intendedUse} `],
          [' Dosage ', ` ${f.dosage === null ? 'No data' : f.dosage} `],
          [' UoM ', ` ${f.dosageUnit === null || f.dosageUnit === undefined ? 'No data' : f.dosageUnit} `],
        ]
      },
    };

    this.exportPdfService.addContent(tableData);
  }

  setRpcRefinement(refinement) {
    this.exportPdfService.addContent({ text: ` RPC Refinement `, style: 'header' });
    if (refinement.length > 0) {
      const markets = refinement.map(r => r.market).filter((market, index, self) => self.indexOf(market) === index).filter(market => market !== null);
      const rpcList = [];
      markets.forEach(market => {
        rpcList.push({ marketName: market, rpcList: refinement.filter(marketItem => marketItem.market === market && marketItem.isChecked === 'checked').map(rpc => rpc.fullText === null ? 'No RPC found' : rpc.fullText) });
      });

      const tableData = {
        style: 'table',
        table: {
          body: [
            [{ text: 'Market', style: 'tableHeader' }, { text: 'Regulatory Product Classes', style: 'tableHeader' }]
          ]
        },
      };

      if (rpcList.length > 0) {
        rpcList.forEach(m => {
          if (m.rpcList.length > 0) {
            m.rpcList.forEach((rpc, index) => {
              if (index === 0) {
                tableData.table.body.push([{ rowSpan: m.rpcList.length, text: m.marketName }, rpc]);
              } else {
                tableData.table.body.push(['', rpc]);
              }
            });
          }
        });
        this.exportPdfService.addContent(tableData);
      } else {
        this.setNodata();
      }
    }
  }

  setOverallStatus(s) {
    this.exportPdfService.addContent({ text: ' Overall Status ', style: 'header' });
    this.exportPdfService.addContent({
      columns: [{ width: 'auto', text: ' Status of Report on Composition is ', style: 'defaultStyle' },
      { width: 'auto', text: ` ${this.exportPdfService.capitalize(s.compositionOverallStatus)} `, style: s.compositionOverallStatusColor, margin: [10, 0, 0, 0] },
      { width: 'auto', text: ` and Report on Product is `, style: 'defaultStyle', margin: [10, 0, 0, 0] },
      { width: 'auto', text: ` ${this.exportPdfService.capitalize(s.productOverallStatus)} `, style: s.productOverallStatusColor, margin: [10, 0, 0, 0] },
      { width: 'auto', text: ` . Request you to go through the report and make respective changes in recipe or contact local RA for more clarifications `, style: 'defaultStyle', margin: [5, 0, 0, 10] },
      ]
    });
  }

  setAllStatus(data) {
    const tableData = {
      style: 'table2',
      table: {
        body: [
          [{ text: 'Status', style: 'tableHeader' }, { text: 'Count of Rules', style: 'tableHeader' }]
        ]
      },
    };
    data.forEach(rpc => {
      tableData.table.body.push([{ text: ` ${rpc.tabHeader} `, style: 'defaultStyle' }, { text: ` ${rpc.count} `, style: 'defaultStyle' }]);
    });
    this.exportPdfService.addContent(tableData);
  }

  setCommanData(comp, title, section, isMultiComponent) {
    if (isMultiComponent && comp.length > 0) {
      this.exportPdfService.addContent({ text: `${title}`, style: 'header' });
      this.setAllStatus(comp);
      comp.forEach(rpc => {
        // each status
        this.exportPdfService.addContent({ text: ` ${rpc.tabHeader} - ${rpc.count} `, style: rpc.color, margin: [0, 10, 0, 10] });
        // all details for each status
        this.exportPdfService.addContent({ text: `${rpc.tabTitle} ${rpc.tabTitleText}`, style: 'defaultStyle', margin: [20, 0, 0, 0] });
        this.exportPdfService.addContent({ text: `${rpc.tabDescription}`, style: 'defaultStyle', margin: [20, 0, 0, 20] });

        if (rpc[section].length > 0) {
          if (section === 'pures') {
            this.setCompositionDetails(rpc.pures);
          } else if (section === 'properties') {
            this.setPropertyDetails(rpc.properties);
          } else if (section === 'products') {
            this.setProductDetails(rpc.products);
          }
        } else {
          this.setNodata();
        }
      });
    }
  }

  setCodeAndDescription(code, desc) {
    this.exportPdfService.addContent({ columns: [{ width: 'auto', text: `${code} - `, bold: true, style: 'defaultStyle', margin: [20, 10, 0, 10] }, { width: 'auto', text: `${desc} `, style: 'defaultStyle', margin: [5, 10, 0, 10] }] });
  }

  setIcon(item) {
    const icon = item.hasOwnProperty('icon') ? { svg: `${this.exportPdfService.getSvgIcon(item.icon)}` } : item.icon === null ? { text: '' } : { svg: `${this.exportPdfService.getSvgIcon(item.icon)}` };
    return icon;
  }

  setCompositionDetails(data) {
    data.forEach(list => {
      this.setCodeAndDescription(list.pureCode, list.pureDescription);
      const tableData = {
        style: 'table',
        table: {
          body: []
        },
      };
      if (list.pureDetails.length > 0) {
        // table header
        // tslint:disable-next-line:max-line-length
        tableData.table.body.push([{ text: 'Status', style: 'tableHeader' }, { text: 'PURE Qty', style: 'tableHeader' }, { text: 'PURE UoM', style: 'tableHeader' }, { text: 'Function', style: 'tableHeader' }, { text: 'Market', style: 'tableHeader' }, { text: 'RPC Path', style: 'tableHeader' }, { text: 'Rule RPC', style: 'tableHeader' }, { text: 'Rule Type', style: 'tableHeader' }, { text: 'Max', style: 'tableHeader' }, { text: 'Min', style: 'tableHeader' }, { text: 'Rule UoM', style: 'tableHeader' }, { text: 'Substance Name', style: 'tableHeader' }, { text: 'Warnings', style: 'tableHeader' }, { text: 'Conflicting Substance', style: 'tableHeader' }, { text: 'Combination Substance', style: 'tableHeader' }, { text: 'Rule ID', style: 'tableHeader' }, { text: 'Rule Version', style: 'tableHeader' }, { text: 'Other Restrictions and Requirements', style: 'tableHeader' }]);
        // table rows
        list.pureDetails.forEach(item => {
          let reptCompWarningText = (item.warningText === null || item.warningText === '' || item.warningText === undefined) ? '' : item.warningText;
          if (reptCompWarningText !== '') {
            reptCompWarningText = this.showHyperlinksInSentence(reptCompWarningText);
          }
          let reptPropOtherReqtText = (item.otherRestrictionsAndRequirements === null || item.otherRestrictionsAndRequirements === '' || item.otherRestrictionsAndRequirements === undefined) ? '' : item.otherRestrictionsAndRequirements;
          if (reptPropOtherReqtText !== '') {
            reptPropOtherReqtText = this.showHyperlinksInSentence(reptPropOtherReqtText);
          }
          tableData.table.body.push([this.setIcon(item), { text: item.recipeQuantity }, { text: item.recipeUoM }, { text: item.ruleFunctionName }, { text: item.marketNameForDisplay }, { text: item.rpcListForDisplay }, { text: item.ruleRPC }, { text: item.ruleTypeDisplayName }, { text: item.maxLimit }, { text: item.minLimit }, { text: item.ruleUoM }, { text: item.substanceName }, { text: reptCompWarningText }, { text: item.conflictingSubstance }, { text: item.combinationSubstance }, { text: item.ruleID },
          { text: (item.ruleVersion === null || item.ruleID === 0 || item.ruleID === '') ? item.ruleVersion : `V${item.ruleVersion}` }
            , { text: reptPropOtherReqtText }]);
        });
        this.exportPdfService.addContent(tableData);
      } else { this.setNodata(); }
    });
  }

  handleSpecialCharacters(text) {
    return text.replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, '').replace(/&amp;/g, '&');
  }
  handleSpecialCharactersComments(text) {
    return text.replace(/<p>|<\/p>/g, ' ').replace(/<.*?>/g, ' ').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, ' ').replace(/&amp;/g, '&');
  }
  setPropertyDetails(data) {
    data.forEach(list => {
      this.setCodeAndDescription(list.propertyClass, list.propertyName);
      const tableData = {
        style: 'table',
        table: {
          body: []
        },
      };
      if (list.propertyDetails.length > 0) {
        // table header
        // tslint:disable-next-line:max-line-length
        this.divisionData === 'F&R' ? tableData.table.body.push([{ text: 'Status', style: 'tableHeader' }, { text: 'Property Target', style: 'tableHeader' }, { text: 'Property Max', style: 'tableHeader' }, { text: 'Property Min', style: 'tableHeader' }, { text: 'Property n', style: 'tableHeader' }, { text: 'Property c', style: 'tableHeader' }, { text: 'Property m', style: 'tableHeader' }, { text: 'Property M', style: 'tableHeader' }, { text: 'Property UoM', style: 'tableHeader' }, { text: 'Property Method', style: 'tableHeader' }, { text: 'RPC Path', style: 'tableHeader' }, { text: 'Rule RPC', style: 'tableHeader' }, { text: 'Rule Type', style: 'tableHeader' }, { text: 'Max', style: 'tableHeader' }, { text: 'Min', style: 'tableHeader' }, { text: 'Rule UoM', style: 'tableHeader' }, { text: 'Warnings', style: 'tableHeader' }, { text: 'Rule ID', style: 'tableHeader' }, { text: 'Rule Version', style: 'tableHeader' }, { text: 'Other Restrictions and Requirements', style: 'tableHeader' }]) : tableData.table.body.push([{ text: 'Status', style: 'tableHeader' }, { text: 'Property Target', style: 'tableHeader' }, { text: 'Property Max', style: 'tableHeader' }, { text: 'Property Min', style: 'tableHeader' }, { text: 'Property UoM', style: 'tableHeader' }, { text: 'RPC Path', style: 'tableHeader' }, { text: 'Rule RPC', style: 'tableHeader' }, { text: 'Rule Type', style: 'tableHeader' }, { text: 'Max', style: 'tableHeader' }, { text: 'Min', style: 'tableHeader' }, { text: 'Rule UoM', style: 'tableHeader' }, { text: 'Warnings', style: 'tableHeader' }, { text: 'Rule ID', style: 'tableHeader' }, { text: 'Rule Version', style: 'tableHeader' }, { text: 'Other Restrictions and Requirements', style: 'tableHeader' }]);
        // table rows
        list.propertyDetails.forEach(item => {
          let reptPropWarningText = (item.warning === null || item.warning === '' || item.warning === undefined) ? '' : item.warning;
          if (reptPropWarningText !== '') {
            reptPropWarningText = this.showHyperlinksInSentence(reptPropWarningText);
          }
          let reptPropOtherReqtText = (item.otherRestrictionsAndRequirements === null || item.otherRestrictionsAndRequirements === '' || item.otherRestrictionsAndRequirements === undefined) ? '' : item.otherRestrictionsAndRequirements;
          if (reptPropOtherReqtText !== '') {
            reptPropOtherReqtText = this.showHyperlinksInSentence(reptPropOtherReqtText);
          }
          this.divisionData === 'F&R' ?
            tableData.table.body.push([this.setIcon(item), { text: item.propertyTargetQuantity }, { text: item.propertyMaxQuantity }, { text: item.propertyMinQuantity }, { text: item.propertynQuantity }, { text: item.propertycQuantity }, { text: item.propertymQuantity }, { text: item.propertyMQuantity }, { text: item.propertyUoM }, { text: item.propertyMethodQuantity }, { text: item.rpcList }, { text: item.ruleRPC }, { text: item.ruleTypeDisplayName }, { text: item.propertyMaxLimit }, { text: item.propertyMinLimit }, { text: item.ruleUoM }, { text: reptPropWarningText }, { text: item.ruleID },
            { text: item.ruleVersion === null ? item.ruleVersion : `V${item.ruleVersion}` }, { text: reptPropOtherReqtText }])
            : tableData.table.body.push([this.setIcon(item), { text: item.propertyTargetQuantity }, { text: item.propertyMaxQuantity }, { text: item.propertyMinQuantity }, { text: item.propertyUoM }, { text: item.rpcList }, { text: item.ruleRPC }, { text: item.ruleTypeDisplayName }, { text: item.propertyMaxLimit }, { text: item.propertyMinLimit }, { text: item.ruleUoM }, { text: reptPropWarningText }, { text: item.ruleID }, { text: item.ruleVersion === null ? item.ruleVersion : `V${item.ruleVersion}` }, { text: reptPropOtherReqtText }]);
        });
        this.exportPdfService.addContent(tableData);
      } else { this.exportPdfService.addContent({ text: `No issues found`, style: 'defaultStyle', italics: true, margin: [20, 0, 0, 10] }); }
    });
  }

  setProductDetails(data) {
    const tableData = {
      style: 'table',
      table: {
        body: []
      },
    };
    // table header
    // tslint:disable-next-line:max-line-length
    tableData.table.body.push([{ text: 'Status', style: 'tableHeader' }, { text: 'Market', style: 'tableHeader' }, { text: 'RPC Path', style: 'tableHeader' }, { text: 'Rule RPC', style: 'tableHeader' }, { text: 'Rule Type', style: 'tableHeader' }, { text: 'PURE Code Type', style: 'tableHeader' }, { text: 'PURE Description', style: 'tableHeader' }, { text: 'Function', style: 'tableHeader' }, { text: 'Max', style: 'tableHeader' }, { text: 'Min', style: 'tableHeader' }, { text: 'Rule UoM', style: 'tableHeader' }, { text: 'Warnings', style: 'tableHeader' }, { text: 'Rule ID', style: 'tableHeader' }, { text: 'Rule Version', style: 'tableHeader' }]);
    // table rows
    data.forEach(item => {
      let reptWarningText = (item.warningText === null || item.warningText === '' || item.warningText === undefined) ? '' : item.warningText;
      if (reptWarningText !== '') {
        reptWarningText = this.showHyperlinksInSentence(reptWarningText);
      }
      tableData.table.body.push([this.setIcon(item), { text: item.marketNameForDisplay }, { text: item.rpcListForDisplay }, { text: item.ruleRPC }, { text: item.ruleTypeDisplayName }, { text: item.pureCode }, { text: item.pureDescription }, { text: item.ruleFunctionName }, { text: item.maxLimit }, { text: item.minLimit }, { text: item.ruleUoM }, { text: reptWarningText }, { text: item.ruleID }, { text: item.ruleVersion === null ? item.ruleVersion : `V${item.ruleVersion}` }]);
    });
    this.exportPdfService.addContent({ text: ' ', style: 'defaultStyle', italics: true, margin: [0, 0, 0, 10] });
    this.exportPdfService.addContent(tableData);
  }

  setHazardClassification() {
    const apiUrl = `${environment.APIFAsT}/getCucLevelSpecData/${recipeModel.requestDisplayID}`;
    const serviceCall = this.accessRequestRaService.getHazardClassification(apiUrl);
    serviceCall.subscribe((data) => {
      this.exportPdfService.addContent({ text: ' Hazard Classification ', style: 'header' });
      if (data.length === 0) {
        this.exportPdfService.addContent({ text: 'No Hazard Classification found ', style: 'defaultStyle' });
      } else {
        const tableData = {
          style: 'table',
          table: {
            body: []
          },
        };
        tableData.table.body.push([{ text: 'Hazard Classification', style: 'tableHeader' }, { text: 'Hazard Category', style: 'tableHeader' }, { text: 'Route of Exposure', style: 'tableHeader' }, { text: 'H Statement', style: 'tableHeader' }]);

        data.forEach(item => {
          tableData.table.body.push([{ text: item.ghsClass }, { text: item.ghsCategory }, { text: item.ghsRouteOfExp }, { text: item.hStatement }]);
        });
        this.exportPdfService.addContent(tableData);
      }
    });
  }
  /* istanbul ignore next */
  setReportComments() {
    const payLoad = {
      RequestStatusDisplayId: recipeModel.requestDisplayID,
      ReportComments: null,
      crudType: 'G'
    };
    const serviceCall = this.accessRequestRaService.getAndPostComments(payLoad);
    this.REPORT_COMMENTS = localStorage.getItem('ReportComments');
    let comments = '';
    if (this.REPORT_COMMENTS != null) {
      comments = this.handleSpecialCharactersComments(this.REPORT_COMMENTS);
    }
    this.exportPdfService.addContent({ text: 'RA Assessment Summary ', style: 'header' });
    if (comments === null || comments === '') {
      this.exportPdfService.addContent({ text: 'No comments found ', style: 'defaultStyle' });
    } else {
      const newData = [];
      if (comments != null) {
        this.contentOptimizeReportComments(this.REPORT_COMMENTS);
        const splitData = comments.split('<figure >');

        splitData.forEach((item) => {
          const chunks = item.split('</figure>');
          chunks.forEach((chunkItem) => {
            newData.push(chunkItem);
          });
        });
      }
      newData.forEach((item) => {
        const isTable = item.match(/<table>(.*?)<\/table>/g);
        if (isTable === null) {
          this.exportPdfService.addContent({ text: ' ', style: 'defaultStyle' });
        } else {
          // generate table
          const table = HtmlTableToJson.parse(item, { values: true });
          const dataArray = [];
          dataArray.push(table.headers[0]);
          table.results[0].forEach(array => {
            dataArray.push(array);
          });
          if (dataArray.length > 1) {
            this.generateCommentsTable(dataArray);
          } else {
            this.exportPdfService.addContent('No comments found');
          }
        }
        this.generateReportCommentsLinks();
      });
    }

  }

  generateCommentsTable(table) {
    const tableData = {
      style: 'table2',
      table: {
        body: []
      },
    };
    table.forEach(item => {
      tableData.table.body.push(item);
    });
    this.exportPdfService.addContent(tableData);
  }
  /* istanbul ignore next */
  contentOptimize(content) {
    this.textbetweenTags = [];
    this.linkArr = [];
    if (content) {
      const container = document.createElement('div');
      container.innerHTML = content;
      this.contentText = container.innerText || container.textContent;
      const regex = /<a[^>]*href=["']([^"']*)["']/gm;
      const aregText = /<a(.*?)<\/a>/gm;
      let m;
      const totalAnchor = [1, 2, 3, 4, 5, 6, 7];
      for (const val of totalAnchor) {
        m = regex.exec(content);
        if (m == null) {
          break;
        }
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match, groupIndex) => {
          if (groupIndex === 1) {
            this.linkArr.push(match);
          }
        });
      }
      let getAncharText;
      for (const val of totalAnchor) {
        getAncharText = aregText.exec(content);
        if (getAncharText == null) {
          break;
        }
        if (getAncharText.index === aregText.lastIndex) {
          aregText.lastIndex++;
        }
        getAncharText.forEach((amatch, agroupIndex) => {
          if (agroupIndex === 1) {
            const aindex = amatch.indexOf('>');
            const matchLength = amatch.length;
            this.textbetweenTags.push(amatch.substring(aindex + 1, matchLength));
          }
        });
      }
    }
  }
  contentOptimizeReportComments(content) {
    this.textbetweenTagsRC = [];
    this.linkArrRC = [];
    if (content) {
      const container = document.createElement('div');
      container.innerHTML = content;
      this.contentTextRC = container.innerText || container.textContent;
      const regex = /<a[^>]*href=["']([^"']*)["']/gm;
      const aregText = /<a(.*?)<\/a>/gm;
      let m;
      const totalAnchor = [1, 2, 3, 4, 5, 6, 7];
      for (const val of totalAnchor) {
        m = regex.exec(content);
        if (m == null) {
          break;
        }
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match, groupIndex) => {
          if (groupIndex === 1) {
            this.linkArrRC.push(match);
          }
        });
      }
      let getAncharText;
      for (const val of totalAnchor) {
        getAncharText = aregText.exec(content);
        if (getAncharText == null) {
          break;
        }
        if (getAncharText.index === aregText.lastIndex) {
          aregText.lastIndex++;
        }
        getAncharText.forEach((amatch, agroupIndex) => {
          if (agroupIndex === 1) {
            const aindex = amatch.indexOf('>');
            const matchLength = amatch.length;
            this.textbetweenTagsRC.push(amatch.substring(aindex + 1, matchLength));
          }
        });
      }
    }
  }
  generateReportCommentsLinks() {
    const tableData = {
      style: 'table',
      table: {
        widths: ['auto', this.REPORT_COMMENTS === null ? 250 : 'auto'],
        body: []

      },
    };
    tableData.table.body.push(['RA Assessment Summary', [
      {
        text: this.REPORT_COMMENTS !== null ? this.handleSpecialCharactersComments(this.REPORT_COMMENTS) : ' ',
      }]]);

    if (this.linkArrRC[0] !== undefined) {
      if (this.linkArrRC[0].length > 0) {
        tableData.table.body.push(['RA Assessment Summary Link 1', [{
          text: this.textbetweenTagsRC[0] !== undefined ? this.handleSpecialCharactersForHyperlinks(this.textbetweenTagsRC[0]) : ' ',
          link: this.linkArrRC[0] !== undefined ? this.linkArrRC[0] : ' ',
          color: 'blue',
        }
        ]]);
      }

    }
    if (this.linkArrRC[1] !== undefined) {
      if (this.linkArrRC[1].length > 0) {
        tableData.table.body.push(['RA Assessment Summary Link 2', [{
          text: this.textbetweenTagsRC[1] !== undefined ? this.handleSpecialCharactersForHyperlinks(this.textbetweenTagsRC[1]) : ' ',
          link: this.linkArrRC[1] !== undefined ? this.linkArrRC[1] : ' ',
          color: 'blue',
        }
        ]]);
      }

    }
    if (this.linkArrRC[2] !== undefined) {
      if (this.linkArrRC[2].length > 0) {
        tableData.table.body.push(['RA Assessment Summary Link 3', [{
          text: this.textbetweenTagsRC[2] !== undefined ? this.handleSpecialCharactersForHyperlinks(this.textbetweenTagsRC[2]) : ' ',
          link: this.linkArrRC[2] !== undefined ? this.linkArrRC[2] : ' ',
          color: 'blue',
        }
        ]]);
      }
    }
    if (this.linkArrRC[3] !== undefined) {
      if (this.linkArrRC[3].length > 0) {
        tableData.table.body.push(['RA Assessment Summary Link 4', [{
          text: this.textbetweenTagsRC[3] !== undefined ? this.handleSpecialCharactersForHyperlinks(this.textbetweenTagsRC[3]) : ' ',
          link: this.linkArrRC[3] !== undefined ? this.linkArrRC[3] : ' ',
          color: 'blue',
        }
        ]]);
      }

    }
    if (this.linkArrRC[4] !== undefined) {
      if (this.linkArrRC[4].length > 0) {
        tableData.table.body.push(['RA Assessment Summary Link 5', [{
          text: this.textbetweenTagsRC[4] !== undefined ? this.handleSpecialCharactersForHyperlinks(this.textbetweenTagsRC[4]) : ' ',
          link: this.linkArrRC[4] !== undefined ? this.linkArrRC[4] : ' ',
          color: 'blue',
        }
        ]]);
      }
    }
    if (this.linkArrRC[5] !== undefined) {
      if (this.linkArrRC[5].length > 0) {
        tableData.table.body.push(['RA Assessment Summary Link 6', [{
          text: this.textbetweenTagsRC[5] !== undefined ? this.handleSpecialCharactersForHyperlinks(this.textbetweenTagsRC[5]) : ' ',
          link: this.linkArrRC[5] !== undefined ? this.linkArrRC[5] : ' ',
          color: 'blue',
        }
        ]]);
      }
    }
    if (this.linkArrRC[6] !== undefined) {
      if (this.linkArrRC[5].length > 0) {
        tableData.table.body.push(['RA Assessment Summary Link 7', [{
          text: this.textbetweenTagsRC[6] !== undefined ? this.handleSpecialCharactersForHyperlinks(this.textbetweenTagsRC[6]) : ' ',
          link: this.linkArrRC[6] !== undefined ? this.linkArrRC[6] : ' ',
          color: 'blue',
        }
        ]]);
      }
    }
    this.exportPdfService.addContent(tableData);
  }
  handleSpecialCharactersForHyperlinks(text) {
    if (text !== null && text !== '') {
      return text.replace(/&nbsp;.*?/gi, ' ').replace(/&amp;/g, '&').replace(/<strong>/gi, '').replace(/<\/strong>/gi, '').replace(/<i>/gi, '').replace(/<\/i>/gi, '');
    } else {
      return '';
    }
  }
/* istanbul ignore next */
  setDate(s) {
    this.requestID = s.requestID;
    const today = new Date();
    const minutes = today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes();
    this.exportPdfService.addContent({ text: ' Report Status ', style: 'header' });
    const tableData = {
      style: 'table',
      table: {
        body: [
          [{ text: 'Status' }, { text: `${this.isCompleted === true ? 'Completed' : 'In Progress'}` }],
          [{ text: 'Completed Date' }, { text: `${this.isCompleted === true ? `${this.completeJSON.completedDate}` : 'NA'}` }],
          [{ text: 'Report Generated Date' }, { text: `${today.getDate()}-${(today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1}-${today.getFullYear()} ${today.getHours()}:${minutes} `, }]

        ]
      },
    };
    this.exportPdfService.addContent(tableData);
  }
  /* istanbul ignore next */
  getCompletedDate() {
    const apiurl = environment.APIFAsT + '/getCompletedDetails/' + `${this.requestID}`;
    this.accessRequestRaService.getData(apiurl).subscribe((response) => {
      this.completeJSON['completedDate'] = response.result;
      this.completeloading = false;
    });
  }
  // /* istanbul ignore next */
  showHyperlinksInSentence(content) {
    const warningTextbetweenTagsRC = [];
    const warningLinkArrRC = [];
    const anchorTags = [];
    let withoutlinkdata = [];
    if (content) {
      const container = document.createElement('div');
      container.innerHTML = content;
      this.contentTextRC = container.innerText || container.textContent;
      const regex = /<a[^>]*href=["']([^"']*)["']/gm;
      const aregText = /<a(.*?)<\/a>/gm;
      let m;
      const totalAnchor = [1, 2, 3, 4, 5, 6, 7];
      for (const val of totalAnchor) {
        m = regex.exec(content);
        if (m == null) {
          break;
        }
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match, groupIndex) => {
          if (groupIndex === 1) {
            warningLinkArrRC.push(match);
          }
        });
      }
      let getAncharText;
      for (const val of totalAnchor) {
        getAncharText = aregText.exec(content);

        if (getAncharText == null) {
          break;
        } else {
          anchorTags.push(getAncharText[0]);
        }
        if (getAncharText.index === aregText.lastIndex) {
          aregText.lastIndex++;
        }
        getAncharText.forEach((amatch, agroupIndex) => {
          if (agroupIndex === 1) {
            const aindex = amatch.indexOf('>');
            const matchLength = amatch.length;
            warningTextbetweenTagsRC.push(amatch.substring(aindex + 1, matchLength));
          }
        });
      }
      if (anchorTags.length > 0) {
        let getnonanchortext = content;
        for (const val of anchorTags) {
          getnonanchortext = getnonanchortext.replace(val, '~!|{%#');
        }
        withoutlinkdata = getnonanchortext.split('~!|{%#');
      }
      if (warningLinkArrRC.length === 0) {
        return this.handleSpecialCharactersComments(content);
      } else {
        return [
          {
            text: this.handleSpecialCharactersComments(content.substring(0, content.indexOf(anchorTags[0]))),
          },
          {
            text: warningTextbetweenTagsRC[0] !== undefined ? this.handleSpecialCharactersForHyperlinks(warningTextbetweenTagsRC[0]) : ' ',
            link: warningLinkArrRC[0] !== undefined ? warningLinkArrRC[0] : ' ',
            color: warningLinkArrRC[0] !== undefined ? 'blue' : 'none',
          },
          {
            text: this.handleSpecialCharactersComments(withoutlinkdata[1]),
          }
          ,
          {
            text: warningTextbetweenTagsRC[1] !== undefined ? this.handleSpecialCharactersForHyperlinks(warningTextbetweenTagsRC[1]) : ' ',
            link: warningLinkArrRC[1] !== undefined ? warningLinkArrRC[1] : ' ',
            color: warningLinkArrRC[1] !== undefined ? 'blue' : 'none',
          },
          {
            text: anchorTags[1] !== undefined && withoutlinkdata[2] !== undefined ? this.handleSpecialCharactersComments(withoutlinkdata[2]) : '',
          },
          {
            text: warningTextbetweenTagsRC[2] !== undefined ? this.handleSpecialCharactersForHyperlinks(warningTextbetweenTagsRC[2]) : ' ',
            link: warningLinkArrRC[2] !== undefined ? warningLinkArrRC[2] : ' ',
            color: warningLinkArrRC[2] !== undefined ? 'blue' : 'none',
          },
          {
            text: anchorTags[2] !== undefined && withoutlinkdata[2] !== undefined ? this.handleSpecialCharactersComments(withoutlinkdata[3]) : '',
          },
          {
            text: warningTextbetweenTagsRC[3] !== undefined ? this.handleSpecialCharactersForHyperlinks(warningTextbetweenTagsRC[3]) : ' ',
            link: warningLinkArrRC[3] !== undefined ? warningLinkArrRC[3] : ' ',
            color: warningLinkArrRC[3] !== undefined ? 'blue' : 'none',
          },
          {
            text: anchorTags[3] !== undefined && withoutlinkdata[4] !== undefined ? this.handleSpecialCharactersComments(withoutlinkdata[4]) : '',
          },
          {
            text: warningTextbetweenTagsRC[4] !== undefined ? this.handleSpecialCharactersForHyperlinks(warningTextbetweenTagsRC[4]) : ' ',
            link: warningLinkArrRC[4] !== undefined ? warningLinkArrRC[4] : ' ',
            color: warningLinkArrRC[4] !== undefined ? 'blue' : 'none',
          },
          {
            text: anchorTags[4] !== undefined && withoutlinkdata[5] !== undefined ? this.handleSpecialCharactersComments(withoutlinkdata[5]) : '',
          },
          {
            text: warningTextbetweenTagsRC[5] !== undefined ? this.handleSpecialCharactersForHyperlinks(warningTextbetweenTagsRC[5]) : ' ',
            link: warningLinkArrRC[5] !== undefined ? warningLinkArrRC[5] : ' ',
            color: warningLinkArrRC[5] !== undefined ? 'blue' : 'none',
          },
          {
            text: anchorTags[5] !== undefined && withoutlinkdata[6] !== undefined ? this.handleSpecialCharactersComments(withoutlinkdata[6]) : '',
          },
          {
            text: warningTextbetweenTagsRC[6] !== undefined ? this.handleSpecialCharactersForHyperlinks(warningTextbetweenTagsRC[6]) : ' ',
            link: warningLinkArrRC[6] !== undefined ? warningLinkArrRC[6] : ' ',
            color: warningLinkArrRC[6] !== undefined ? 'blue' : 'none',
          },
          {
            text: anchorTags[6] !== undefined && withoutlinkdata[7] !== undefined ? this.handleSpecialCharactersComments(withoutlinkdata[7]) : '',
          }
        ];
      }
    }

  }
}
