import { Component, Input, OnInit } from '@angular/core';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { environment } from 'src/environments/environment';
import * as fs from 'file-saver';

@Component({
  selector: 'app-download-templates',
  templateUrl: './download-templates.component.html',
  styleUrls: ['./download-templates.component.scss']
})
export class DownloadTemplatesComponent implements OnInit {
  @Input() inputId;
  @Input() fileName;
  @Input() isIcon;
  @Input() buttonName;
  @Input() extension;
  loading = false;

  constructor(private reqSvc: AssessRequestRaService) { }

  ngOnInit() {
  }
/* istanbul ignore next */
  downloadFile() {
    this.loading = true;
    const fileURL = environment.APIFAsT + '/help/FAQ/' + this.inputId;
    this.reqSvc.downloadFile(fileURL).subscribe((response) => {
      this.loading = false;
      fs.saveAs(response, this.fileName + this.extension);
    }, (error) => {
      this.loading = false;
    });
  }

}
