import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatExpansionPanel } from '@angular/material/expansion';
import * as _ from 'lodash';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { SUBSTANCE_SEARCH_COLUMNS, SUBSTANCE_SEARCH_REGULATION_COLUMNS, SUBSTANCE_SEARCH_REGULATION_COLUMNS_EXCEL } from 'src/app/common/models/tableColumns';
import { DataService } from 'src/app/common/services/data.service';
import { environment } from 'src/environments/environment';
import { AutoComplete } from 'primeng';
@Component({
  selector: 'app-substance-search',
  templateUrl: './substance-search.component.html',
  styleUrls: ['./substance-search.component.scss']
})
export class SubstanceSearchComponent implements OnInit {
  @ViewChild('rowHoverDiv') rowHoverDiv: ElementRef;
  @ViewChild('ProjectForm') ProjectForm: NgForm;
  @ViewChild(MatExpansionPanel, { static: true }) multiExpansionpanel: MatExpansionPanel;
  @ViewChild('autoItems', { static: true }) public autoItems: AutoComplete;

  loading = false;
  star = 'fa fa-fw fa-search';
  loadingMsg = 'Fetching data from FAsT Rule-Engine';
  marketNameselected: any;
  marketToolTip: any;
  searchTableFlag = false;
  marketList: any = [];
  functionList: any = [];
  selectedMarkets = [];
  // filteredDataDivision = [];
  divisionData = [];
  groupsData = [];
  selectedDivision = {};
  selectedFunction = [];
  searchIdentifier = '';
  subtanceIdentifier = ['Pure Code', 'INCI Name', 'CAS #', 'SIR ID', 'Substance Name'];
  searchIdentifierValue = '';
  disablePureCode = false;
  checkSelectedVal = '';
  searchedSubstanceData = [];
  searchedSubstanceDataTemp = [];
  isEditGroup = false;
  isClearBtnDisabled = true;
  selectedGroup = [];
  substanceGroupData: {
    groupId: number;
    groupName;
    groupType;
    substanceData: any;
  };
  substanceorm = new FormGroup({});
  searchResultsGroup: any[];
  isSubstanceDataClicked = false;
  division = '';
  subIdentifier: string;
  selectedSubSearch: {
    cas;
    sirId;
    inciName;
    substanceName;
    pureCode;
  };
  isconfirmBtnDisabled = true;
  searchTableData: any[];
  detailTableData: any[];
  isClearbtnClicked = false;
  panelOpenState = false;
  divisionId: any;
  filteredRows: any = [];
  substanceSearchColumns = [];
  searchRegulationColumn = [];
  showSubstanceData = [];
  pointerNone = false;
  clickSubstance = false;
  loadingback = false;
  overlayText = '';
  hideOverlay = true;
  warningTextOpen: boolean;
  tablewarningComment: any;
  originalKeyparameter: any;
  componentNameTab;
  public steps: string[];
  public selectedStep;
  id: number;
  searchRegulationColumnExcel = [];
  isColumnsHasHyperLinks = 'No,Yes';
  searchTableDataExcel = [];
  sheetNameExcelDownload = '';
  popupHeadername = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: AssessRequestRaService,
    private dataServiceObj: DataService,
    public matSnackBar: MatSnackBar,
    public formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.isColumnsHasHyperLinks = 'No,Yes';
    this.substanceSearchColumns = SUBSTANCE_SEARCH_COLUMNS;
    this.searchRegulationColumn = SUBSTANCE_SEARCH_REGULATION_COLUMNS;
    this.searchRegulationColumnExcel = SUBSTANCE_SEARCH_REGULATION_COLUMNS_EXCEL;
    this.getMarketData();
    this.getFunctionData();
    this.getDivisonData();
    this.isConfirmEnable();
    this.isSearchEnable();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = +params.get('id');
      this.dataServiceObj.isfromSubstancesearch = false;
      if (this.id === 0) {
        this.dataServiceObj.fromViewtoSubstance = false;
        this.dataServiceObj.substanceFormdata = {
          selectedGroup: [],
          selectedMarkets: [],
          selectedFunction: [],
          searchIdentifier: '',
          division: '',
          selectedSubSearch: {},
          selectedDivision: {}
        };
        this.clearProjectFields();
      }
    });
    if (this.dataServiceObj.fromViewtoSubstance) {
      this.loadingback = true;
      this.searchIdentifier = this.dataServiceObj.substanceFormdata.searchIdentifier;
      this.valueSelected(this.dataServiceObj.substanceFormdata.selectedSubSearch);
      this.searchSubstance();
    }
  }
  onSearchIdentifierChange() {
    this.disablePureCode = false;
  }
  // tslint:disable-next-line: member-ordering
  step = 1;
  setStep(index: number) {
    this.step = index;
  }
  clickSearch() {
    this.searchResultsGroup = [];
    if (this.searchIdentifierValue === '') {
      this.selectedSubSearch = {
        cas: '',
        sirId: '',
        inciName: '',
        substanceName: '',
        pureCode: ''
      };
    }
  }
  onSearchSubstance() {
    this.dataServiceObj.fromViewtoSubstance = false;
    this.clickSubstance = false;
    this.searchSubstance();
  }

  searchSubstance() {
    let apiurl;
    this.searchResultsGroup = [];
    if (this.searchIdentifier === 'CAS #') {
      this.subIdentifier = 'CAS';
    } else if (this.searchIdentifier === 'Substance Name') {
      this.subIdentifier = 'BACKBONEIDENTITY';
    } else if (this.searchIdentifier === 'INCI Name') {
      this.subIdentifier = 'INCI';
    } else if (this.searchIdentifier === 'SIR ID') {
      this.subIdentifier = 'SIRID';
    } else if (this.searchIdentifier === 'Pure Code') {
      this.subIdentifier = 'PURECODE';
    }
    apiurl = environment.APIFAsTRule + '/rules/substances/' + this.subIdentifier + '/cfRequired/y';
    const data = [];
    data.push(this.searchIdentifierValue);
    this.loading = true;
    this.loadingMsg = 'Loading...';
    this.searchTableDataExcel = [];
    this.apiService.postData(apiurl, data).subscribe((response) => {
      if (response && response.length > 0) {
        if (this.dataServiceObj.fromViewtoSubstance) {
          this.onSubstancesearch();
        } else {
          this.searchResultsGroup = response;
          this.loading = false;
          this.disablePureCode = true;
          if (this.searchResultsGroup[0].sirId.includes('SIR ID Not found')) {
            this.pointerNone = true;
          } else {
            this.pointerNone = false;
          }
        }
        this.loading = false;
      }
    }, (error) => {
      this.searchResultsGroup = [];
      this.loading = false;
    });
  }

  getMarketData() {
    this.loading = true;
    this.loadingMsg = 'Fetching FAST Markets';
    const apiurl = environment.APIFAsT + '/markets';
    this.apiService.getData(apiurl).subscribe((response1) => {
      this.marketList = response1;
      if (this.dataServiceObj.fromViewtoSubstance) {
        this.selectedMarkets = this.dataServiceObj.substanceFormdata.selectedMarkets;
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  getDivisonData() {
    const apiurl = environment.APIFAsT + '/getsubstancesearchupds';
    this.apiService.getData(apiurl).subscribe((response1) => {
      this.loading = false;
      this.divisionData = response1;
      if (this.dataServiceObj.fromViewtoSubstance) {
        this.selectedDivision = this.dataServiceObj.substanceFormdata.selectedDivision;
        this.divisionSelected(event);
      }
    }, (error) => {
      this.loading = false;
    });
  }


  getFunctionData() {
    this.loading = true;
    this.loadingMsg = 'Fetching FAST functions';
    const apiurl = environment.APIFAsT + '/functionswithany';
    this.apiService.getData(apiurl).subscribe((response1) => {
      this.functionList = response1;
      if (this.dataServiceObj.fromViewtoSubstance) {
        this.selectedFunction = this.dataServiceObj.substanceFormdata.selectedFunction;
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }
  groupSelected(val) {

  }
  valueSelected(rowselected: any) {
    this.selectedSubSearch = rowselected;
    this.searchIdentifierValue = rowselected.sirId + '(' + rowselected.substanceName + ')';
    this.sheetNameExcelDownload = rowselected.substanceName + ' ' + 'Search Data';
    this.searchResultsGroup = [];
    this.disablePureCode = false;
    this.clickSubstance = true;
  }

  clearProjectFields() {
    this.searchIdentifier = '';
    this.searchIdentifierValue = '';
    this.selectedDivision = {};
    this.selectedGroup = [];
    this.selectedMarkets = [];
    this.selectedFunction = [];
    this.searchTableData = [];
    this.detailTableData = [];
    this.division = '';
    this.clickSubstance = true;
    this.isConfirmEnable();
    this.isClearEnable();
    this.isSubstanceDataClicked = false;
    this.selectedSubSearch = {
      cas: '',
      sirId: '',
      inciName: '',
      substanceName: '',
      pureCode: ''
    };
  }
  divisionSelected(event) {
    if (event !== null) {
      this.loading = true;
      this.loadingMsg = 'Loading ...';
      this.division = this.selectedDivision['updName'];
      this.divisionId = this.selectedDivision['updId'];
      if (this.division !== '') {
        this.getGroupData();
      }
    }
  }
  getGroupData() {
    const apiurl = environment.APIFAsTRule + '/rules/groups/upd/' + this.division;
    this.apiService.getData(apiurl).subscribe((response1) => {
      this.loading = false;
      this.groupsData = response1;
      if (this.dataServiceObj.fromViewtoSubstance) {
        this.selectedGroup = this.dataServiceObj.substanceFormdata.selectedGroup;
      }
    }, (error) => {
      this.loading = false;
    });
  }
  clearDivision() {

  }
  onMarketChange() {

  }
  isConfirmEnable() {
    if (this.selectedDivision !== null && Object.keys(this.selectedDivision).length > 0 && this.searchIdentifierValue !== '' && !this.pointerNone && this.clickSubstance && this.selectedSubSearch.sirId !== '') {
      return true;
    } else {
      return false;
    }
  }
  isClearEnable() {
    if (this.division !== '' || this.searchIdentifier !== '') {
      return true;
    } else {
      return false;
    }
  }

  isSearchEnable() {
    if (this.searchIdentifier !== '' && (this.searchIdentifierValue !== '' || this.searchIdentifierValue.length > 0)) {
      return true;
    } else {
      return false;
    }
  }
  redirectTo(data) {
    this.dataServiceObj.fromSubstanceData = data;
    this.dataServiceObj.isfromSubstancesearch = true;
    this.router.navigate(['rule-management/view-rules']);
  }
  onSubstancesearch() {
    this.router.navigate(['/substance-search/2']);
    this.searchRegulationColumnExcel = [];
    if (this.division === 'F&R') {
      this.substanceSearchColumns = this.substanceSearchColumns.filter(x => x.field !== 'hazardClassification');
      this.searchRegulationColumnExcel.push(SUBSTANCE_SEARCH_REGULATION_COLUMNS_EXCEL[0].filter(x => x.field !== 'hazardClassification'));
      this.searchRegulationColumnExcel.push(SUBSTANCE_SEARCH_REGULATION_COLUMNS_EXCEL[1]);
    } else {
      this.substanceSearchColumns = SUBSTANCE_SEARCH_COLUMNS;
      this.searchRegulationColumnExcel = SUBSTANCE_SEARCH_REGULATION_COLUMNS_EXCEL;
    }
    this.loading = true;
    this.loadingMsg = 'Loading';
    this.isSubstanceDataClicked = true;
    let SubstanceSearchval = [];
    const selectedgroupIds = this.selectedGroup.map(({ groupName }) => groupName);
    const selectedmarketIds = this.selectedMarkets.map(({ marketId }) => marketId);
    const selectedfunctionIds = this.selectedFunction.map(({ functionName }) => functionName);
    this.marketNameselected = this.selectedMarkets.map(({ marketName }) => marketName).join(',');
    if (this.marketNameselected.length > 0) {
      if (this.selectedMarkets.length === this.marketList.length) {
        this.marketNameselected = 'All';
        this.marketToolTip = 'All Markets Selected';
      }
    } else {
      this.marketNameselected = 'All';
      this.marketToolTip = 'Displaying Results for All Markets';
    }
    if (this.marketNameselected.length > 35) {
      this.marketToolTip = this.marketNameselected;
      this.marketNameselected = this.marketNameselected.substr(0, 34) + '...';
    } else {
      this.marketToolTip = this.marketNameselected;
    }

    if (this.searchIdentifier === 'CAS #') {
    //  SubstanceSearchval.push(this.selectedSubSearch.cas);
      SubstanceSearchval = this.selectedSubSearch.cas.split(',');
    } else if (this.searchIdentifier === 'Substance Name') {
      SubstanceSearchval.push(this.selectedSubSearch.substanceName);
    } else if (this.searchIdentifier === 'INCI Name') {
    //  SubstanceSearchval.push(this.selectedSubSearch.inciName);
      SubstanceSearchval = this.selectedSubSearch.inciName.split(',');
    } else if (this.searchIdentifier === 'SIR ID') {
      SubstanceSearchval.push(this.selectedSubSearch.sirId);
    } else if (this.searchIdentifier === 'Pure Code') {
      SubstanceSearchval = this.selectedSubSearch.pureCode.split(',');
    }
    this.dataServiceObj.substanceFormdata = {
      selectedGroup: this.selectedGroup,
      selectedMarkets: this.selectedMarkets,
      selectedFunction: this.selectedFunction,
      searchIdentifier: this.searchIdentifier,
      division: this.division,
      selectedSubSearch: this.selectedSubSearch,
      selectedDivision: this.selectedDivision
    };

    const data = {
      SubstanceSearch: SubstanceSearchval,
      MarketIds: selectedmarketIds,
      GroupNames: selectedgroupIds,
      Division: this.division,
      FunctionNames: selectedfunctionIds
    };
    this.searchTableData = [];
    this.searchTableDataExcel = [];
    const apiurl = environment.APIFAsTRule + '/rules/substancessummary/' + this.subIdentifier + '/cfRequired/y';
    this.apiService.postData(apiurl, data).subscribe((response) => {
      if (response && response.length > 0) {
        this.searchTableData = response;
        this.loading = false;
        this.loadingback = false;
        this.searchTableDataExcel.push(this.searchTableData);
        if (this.searchTableDataExcel.length === 2) {
          [this.searchTableDataExcel[0], this.searchTableDataExcel[1]] = [this.searchTableDataExcel[1], this.searchTableDataExcel[0]];
        }
      }
    }, (error) => {
      this.searchTableData = [];
      this.searchTableDataExcel = [];
      this.loading = false;
    });
    const detailata = {
      Market: this.selectedMarkets,
      UpdName: this.division,
      UpdId: this.divisionId,
      Sirid: this.selectedSubSearch.sirId,
      Groups: this.selectedGroup,
      Functions: this.selectedFunction
    };
    this.detailTableData = [];
    const apiurl1 = environment.APIFAsTRule + '/rules/queryrelatedrulesforsubstancebasedsearch';
    this.apiService.postData(apiurl1, detailata).subscribe((responseData) => {
      if (responseData && responseData.length > 0) {
        this.detailTableData = responseData;
        //  this.loading = false;
      }
      for (const val of this.detailTableData) {
        const rulevalueLinkRuleData = this.apiService.hyperLinksToSheet(val.keyParameter);
        val.rulevalueText1 = rulevalueLinkRuleData[0] !== undefined ? rulevalueLinkRuleData[0] : '';
        val.rulevalueText2 = rulevalueLinkRuleData[1] !== undefined ? rulevalueLinkRuleData[1] : '';
        val.rulevalueText3 = rulevalueLinkRuleData[2] !== undefined ? rulevalueLinkRuleData[2] : '';
        val.rulevalueText4 = rulevalueLinkRuleData[3] !== undefined ? rulevalueLinkRuleData[3] : '';
        val.rulevalueText5 = rulevalueLinkRuleData[4] !== undefined ? rulevalueLinkRuleData[4] : '';
        val.rulevalueText6 = rulevalueLinkRuleData[5] !== undefined ? rulevalueLinkRuleData[5] : '';
        val.rulevalueText7 = rulevalueLinkRuleData[6] !== undefined ? rulevalueLinkRuleData[6] : '';
        val.keyParameterExcel = this.apiService.handleSpecialCharacters(val.keyParameter);
        val.originalKeyparameter = val.keyParameter;
        val.keyParameterTextStripped = this.handleSpecialCharacters(val.originalKeyparameter).length > 40 ? this.handleSpecialCharacters(val.originalKeyparameter).substr(0, 40) + '...' : this.handleSpecialCharacters(val.originalKeyparameter);
        val.keyParameter = this.changeComment(val.keyParameter);
        val.commentstoDisplayExcel = this.apiService.handleSpecialCharacters(val.commentstoDisplay);
        val.originalcommentstoDisplay = val.commentstoDisplay;
        val.commentstoDisplayTextStripped = this.handleSpecialCharacters(val.originalcommentstoDisplay).length > 40 ? this.handleSpecialCharacters(val.originalcommentstoDisplay).substr(0, 40) + '...' : this.handleSpecialCharacters(val.originalcommentstoDisplay);
        val.commentstoDisplay = this.changeComment(val.commentstoDisplay);
      }
      this.searchTableDataExcel.push(this.detailTableData);
    }, (error) => {
      this.detailTableData = [];
      this.loading = false;
    });
  }

  onFilter(event) {
    if (event.filters != null) {
      (Object.keys(event.filters).length === 0 ? this.filteredRows = [] : this.filteredRows = event.filteredValue);
    }
  }
  hideFloatingIcons() {
    this.rowHoverDiv.nativeElement.style.display = 'none';
  }
  handleSpecialCharacters(text) {
    return text.replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, '').replace(/&amp;/g, '&');
  }

  showOverlay(htmlContent) {
    this.overlayText = htmlContent;
    this.hideOverlay = false;
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
  hideOverlayPanel() {
    this.hideOverlay = true;
  }
  openComment(data, sheadername) {
    this.warningTextOpen = true;
    this.popupHeadername = sheadername;
    const mydata = data.originalKeyparameter;
    this.tablewarningComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  closePopupW() {
    this.warningTextOpen = false;
  }
  changeComment(input) {
    if (input === null) {
      input = '';
    }
    const strValue = input;
    const ar = strValue.match(/<a.*?<\/a>/g);
    let commentwarning = '';
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentwarning = 'Click Here';
    } else {
      commentwarning = input;
    }
    return commentwarning;
  }
}
function compareData(a, b) {
  return a.groupId - b.groupId;
}


