import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';
import { AssessmentRequestService } from '../assess-requests/assess-requests.service';
import { SirDetails } from '../common/models/SirModel';
import { DataService } from '../common/services/data.service';

@Component({
  selector: 'app-standalone',
  templateUrl: './standalone.component.html',
  styleUrls: ['./standalone.component.scss']
})
export class StandaloneComponent implements OnInit {
  // @ViewChild('cdkStepper', { static: false }) cdkStepper;

  @ViewChild('verifyReqForm') verifyReqForm: NgForm;
  @ViewChild('updateRulesForm') updateRulesForm: NgForm;
  @ViewChild('executeRulesForm') executeRulesForm: NgForm;
  @ViewChild('submitReportForm') submitReportForm: NgForm;

  pureCodesList: any;
  componentNameTab = 'AssessRequestsRA';
  checkedPureCodes = [];
  switchedPureCodes = [];
  switchedSirDetails = [];
  sentPureCodes;
  loading = false;
  isDisabled = false;
  isCompleted = false;
  panelOpenState = true;
  completed = false;
  state: string;
  readOnlyValue = true;
  selectedIndex = 0;
  selectedPureDescription = 'Not Available';
  sirDetails = Array<SirDetails>();
  selectedSir; commentsToUser = ''; requestId;
  selectedSirName;
  selectedSirDetails = [];
  senderEmail;
  formValues = {
    pureCodes: [], selectedPureCode: '',
    pureDescription: '',
    sirId: '',
    substanceName: '',
    substanceMolWeight: '',
    inciName: [],
    selectedInciName: '',
    einecs: [],
    selectedEinecs: '',
    synonyms: [],
    selectedSynonym: '',
    cfDetails: [{
      cfDisplayText: '',
      expressedAs: '',
      cf: ''
    }],
    selectedCF: '',
    cas: [],
    selectedCas: '',
    parentCAS: [],
    selectedParentCas: [],
    ins: [],
    selectedIns: '',
    eNumber: [],
    selectedENumber: '',
  };

  notifyGPCDialog = false;
  environmentVariables = environment;
  messageParent = 'CAS no. fetched from Parent substance in SIR';
  /* istanbul ignore next */
  constructor(public assessService: AssessmentRequestService, public matSnackBar: MatSnackBar, private dataService: DataService) {
  }
  ngOnInit() {
    this.dataService.setSelectedStep(1);
  }
  /* istanbul ignore next */
  getSirDetails(pureCodes) {
    this.requestId = 2;
    const enteredPureCodes = _.split(this.pureCodesList, ',');
    this.checkedPureCodes = []; // emptying the checked list for disabling notify sir btn
    this.switchedPureCodes = [];
    this.switchedSirDetails = []; // emptying the switched list for disabling notify gpc btn
    const apiurl = environment.APIFAsT + '/sircf/' + pureCodes + '/request/' + this.requestId;
    this.loading = true;
    this.assessService.getData(apiurl).subscribe((response) => {
      this.loading = false;
      this.sirDetails = response;
      if (this.sirDetails !== [] || this.sirDetails !== null) {
        for (const val of this.sirDetails) {
          if (val.sirId === 'SIR ID Not found') {
            this.isDisabled = true;
          }
          if (val.cfDetails !== null) {
            if (val.cfDetails.length > 1) {
              val.selectedCF = val.cfDetails[0].cfDisplayText;
            }
          }
        }
      }
    }, (error) => {
      if (error.status !== 200) {
        this.matSnackBar.open('Request Failed', 'close', {
          duration: 3000,
          panelClass: ['error']
        });
        this.loading = false;
        // return false;
      }
    });
  }
  /* istanbul ignore next */
  notifySIRClicked(event) {
    this.sentPureCodes = this.checkedPureCodes.toString();
    if (this.checkedPureCodes.length !== 0) {
      const data = {
        NotificationGroup: 'SIR',
        Subject: null,
        EmailBody: null,
        ToEmailList: null,
        FromEmail: null,
        CCEmailList: null,
        EmailType: 'PlainText',
        UPD: 'BPC',
        PureCodes: this.sentPureCodes,
        RequestID: null
      };
      const apiurl = environment.APIFAsT + '/email';
      this.assessService.postData(apiurl, data).subscribe((response) => {
        // const apiurl = environment.APIFAsT + '/email/GPC/pures/' + this.checkedPureCodes + '/upd/BPC';
        // // this.loading = true;
        // this.assessService.getData(apiurl).subscribe((response) => {
        // console.log(response);
        if (response.message !== null) {
          this.matSnackBar.open('Email message sent to SIR team successfully', 'close', {
            duration: 3000,
            panelClass: ['success']
          });
        }
        // this.loading = false;
      }, (error) => {
      });
    } else {
      this.matSnackBar.open('Select the Pure Codes', 'close', {
        duration: 3900,
        panelClass: ['error']
      });
    }
  }
  /* istanbul ignore next */
  notifyGPCClicked(event, selectedSirDetails) {
    // this.commentsToUser = '';
    this.selectedSirDetails = selectedSirDetails;
    // this.selectedSirDetail = selectedSirDetail;
    this.notifyGPCDialog = true;
    // this.selectedSir = selectedSirDetail.sirId;
    // this.selectedSirName = selectedSirDetail.substanceName;
  }
  /* istanbul ignore next */
  sendMailtoGPC() {
    let emailData = '';
    this.sentPureCodes = this.switchedPureCodes.toString();
    this.selectedSirDetails.forEach(element => {
      emailData = emailData + '\r\nPure Code:' + ' ' + element.pureCode + '\r\n[SIR ID :' + ' ' + element.sirId + ']' +
        '\r\nBackbone Identity :' + ' ' + element.substanceName + '. ' + '\r\n' + 'Comments : ' + ' ' + element.commentsToUser + '\r\n';
    });
    const data = {
      NotificationGroup: 'GPC',
      Subject: null,
      EmailBody: emailData,
      ToEmailList: null,
      FromEmail: null,
      CCEmailList: null,
      EmailType: 'PlainText',
      UPD: 'BPC',
      PureCodes: this.sentPureCodes,
      RequestID: null
    };
    const apiurl = environment.APIFAsT + '/email';
    this.assessService.postData(apiurl, data).subscribe((response) => {
      // this.notifyGPCDialog = false;
      if (response.message !== null) {
        this.matSnackBar.open('Email message sent to GPC team successfully', 'close', {
          duration: 5000,
          panelClass: ['success']
        });
      }
    }, (error) => {
    });
  }
  /* istanbul ignore next */
  onChangeCfDetails() {
    console.log('onchangecfdetails');
  }
  /* istanbul ignore next */
  onPureCodeChanged(value) {
    console.log(value, 'select event value');
  }
  /* istanbul ignore next */
  onCheck(event, checkedPureCode) {
    if (event.checked === true) {
      this.checkedPureCodes.push(checkedPureCode);
    } else if (event.checked === false) {
      const index = this.checkedPureCodes.findIndex(d => d === checkedPureCode);
      this.checkedPureCodes.splice(index, 1);
    }
  }
  /* istanbul ignore next */
  handleSwitch(event, sirvalue) {
    // this.sirDetails.forEach(x => {
    //   if (x.sirId === sirvalue.sirId) {
    //     console.log(x.checked, 'checked value');
    //     console.log(x.sirId, 'SIRiD value');
    //     this.switchedPureCodes.push(x.sirId);
    //   }
    // });
    if (event.checked === true) {
      this.switchedSirDetails.push(sirvalue);
      this.switchedPureCodes.push(sirvalue.pureCode);
    } else if (event.checked === false) {
      const index = this.switchedPureCodes.findIndex(d => d === sirvalue.pureCode);
      this.switchedPureCodes.splice(index, 1);
      this.switchedSirDetails.splice(index, 1);
    }
  }
}
