import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ingredient-property-values',
  templateUrl: './ingredient-property-values.component.html',
  styleUrls: ['./ingredient-property-values.component.scss']
})
export class IngredientPropertyValuesComponent {
  @Input() data: any;
}
