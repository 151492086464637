import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { HAZARD_CLASSIFICATION } from 'src/app/common/models/PureFnModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hazard-classification',
  templateUrl: './hazard-classification.component.html',
  styleUrls: ['./hazard-classification.component.scss']
})
export class HazardClassificationComponent implements OnInit {
  displayColumns = HAZARD_CLASSIFICATION;
  tableData = [];
  loading = true;
  constructor(private accessRequestRaService: AssessRequestRaService, public route: ActivatedRoute) { }

  ngOnInit() {
    const apiUrl = `${environment.APIFAsT}/getCucLevelSpecData/${recipeModel.requestDisplayID}`;

    this.accessRequestRaService.getHazardClassification(apiUrl).subscribe((data) => {
      this.loading = false;
      this.tableData = data;
    }, (error) => {
      if (error.status !== 200) {
        this.loading = false;
      }
    });
  }

}
