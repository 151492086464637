import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { VIEW_PREPARE_RULE_TABLE_COLUMNS, VIEW_PREPARE_RULE_TABLE_COLUMNS_EXCEL } from 'src/app/common/models/tableColumns';
import { DataService } from 'src/app/common/services/data.service';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from 'src/environments/environment';

import { AssessRequestRaService } from '../../assess-request-ra.service';

@Component({
  selector: 'app-view-prepare-rule',
  templateUrl: './view-prepare-rule.component.html',
  styleUrls: ['./view-prepare-rule.component.scss']
})
export class ViewPrepareRuleComponent implements OnInit {
  overlayText = '';
  hideOverlay = true;
  viewPrepareRuleColumns = [];
  viewPrepareRuleColumnsExcel = [];
  loading = false;
  loadingMessage = '';
  marketName;
  tffName;
  assessmentModel;
  ruleExecutionResult;
  viewPrepareRuleTableData = [];
  substanceRuleList;
  groupRuleList = [];
  productWarningRules;
  propertyRuleList;
  viewPrepareRulePageFlag = true;
  reportFlag = false;
  goToWorklistConfirmation = false;
  warningTextOpen = false;
  tablewarningComment = '';
  commentRD = '';
  orrTextOpen = false;
  tableORRComment = '';
  isColumnsHasHyperLinks = 'Yes';
  constructor(
    public assessraService: AssessRequestRaService,
    public matSnackBar: MatSnackBar,
    private dataService: DataService,
    private route: Router,
    private worklistService: WorklistService
  ) { }

  ngOnInit() {
    this.isColumnsHasHyperLinks = 'Yes';
    this.getViewPrepareRuleTableData();
  }
  getViewPrepareRuleTableData() {
    this.assessmentModel = recipeModel;
    this.viewPrepareRuleColumns = VIEW_PREPARE_RULE_TABLE_COLUMNS;
    this.viewPrepareRuleColumnsExcel = VIEW_PREPARE_RULE_TABLE_COLUMNS_EXCEL;
    this.marketName = recipeModel.marketDetails.marketName;
    this.tffName = recipeModel.tfcFullText;
    this.loading = true;
    const apiurl = environment.APIFAsTRule + '/rules/assessmentRules';
    this.assessraService.postData(apiurl, this.assessmentModel).subscribe((response) => {
      this.loading = false;
      if (response !== null) {
        this.ruleExecutionResult = response;
        if (this.ruleExecutionResult.isMultiComponent === 'Y' && this.viewPrepareRuleColumns[0].header !== 'COMPONENT') {
          const obj = {
            field: 'multiComponentName',
            header: 'COMPONENT',
            width: '120px',
          };
          this.viewPrepareRuleColumns.splice(0, 0, obj);
          this.viewPrepareRuleColumnsExcel.splice(0, 0, obj);
        }
        if (this.ruleExecutionResult.isMultiComponent === 'N' && this.viewPrepareRuleColumns[0].header === 'COMPONENT') {
          this.viewPrepareRuleColumns.shift();
          this.viewPrepareRuleColumnsExcel.shift();
        }

        if (this.ruleExecutionResult.otherRuleResults !== null) {
          this.matSnackBar.dismiss();
          if (this.ruleExecutionResult.otherRuleResults[0].ruleSource === 'Rule-Engine' &&
            this.ruleExecutionResult.otherRuleResults[0].ruleExecutionStatus !== 'Success') {
            this.matSnackBar.open(this.ruleExecutionResult.otherRuleResults[0].ruleExecutionStatus + ' for this request', 'close', {
              duration: 1900,
              panelClass: ['error']
            });
            this.matSnackBar.dismiss();
          }
        }
        this.substanceRuleList = this.ruleExecutionResult.rulesResults;
        this.substanceRuleList.forEach(data => {
          data.ruleVerionToDisplay = data.ruleVersion === null ? '' : 'V' + data.ruleVersion;
          data.isAllergen = data.isAllergen === 'Y' ? 'Y' : '';
          data.allowedFuncFlag = data.ruleTypeID === 14 ? 'Y' : 'N';
          if (data.ruleTypeID === 30) {
            data.propertySource = data.propertyName;
            data.propertyName = '';
          }
          data.uoM = data.ruleUoM;
          if (data.ruleCFDetails !== null) {
            data.cfSubstanceExpressedAs = data.ruleCFDetails.component;
            data.cf = data.ruleCFDetails.cf !== 0 ? data.ruleCFDetails.cf : '';
          }
          data.warningText = (data.warningText !== null) ? data.warningText : ((data.warningStatement !== null) ? data.warningStatement : '');
          const warningLinkRuleData = this.assessraService.hyperLinksToSheet(data.warningText);
          data.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
          data.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
          data.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
          data.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
          data.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
          data.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
          data.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
          data.originalWarningText = data.warningText;
          data.warningText = this.changeComment(data.warningText);
          data.warningTextStripped = this.handleSpecialCharacters(data.originalWarningText);
          data.otherRestrictionsAndRequirements = (data.otherRestrictionsAndRequirements !== null) ? data.otherRestrictionsAndRequirements : '';
          const orrLinkRuleData = this.assessraService.hyperLinksToSheet(data.otherRestrictionsAndRequirements);
          data.ORR1 = orrLinkRuleData[0] !== undefined ? orrLinkRuleData[0] : '';
          data.ORR2 = orrLinkRuleData[1] !== undefined ? orrLinkRuleData[1] : '';
          data.ORR3 = orrLinkRuleData[2] !== undefined ? orrLinkRuleData[2] : '';
          data.ORR4 = orrLinkRuleData[3] !== undefined ? orrLinkRuleData[3] : '';
          data.ORR5 = orrLinkRuleData[4] !== undefined ? orrLinkRuleData[4] : '';
          data.ORR6 = orrLinkRuleData[5] !== undefined ? orrLinkRuleData[5] : '';
          data.ORR7 = orrLinkRuleData[6] !== undefined ? orrLinkRuleData[6] : '';
          data.originalotherRestrictionsAndReq = data.otherRestrictionsAndRequirements;
          data.otherRestrictionsAndRequirements = this.changeComment(data.otherRestrictionsAndRequirements);
          data.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(data.originalotherRestrictionsAndReq);
        });

        this.groupRuleList = this.ruleExecutionResult.groupRuleResults;
        this.groupRuleList.forEach(data => {
          data.ruleVerionToDisplay = data.ruleVersion === null ? '' : 'V' + data.ruleVersion;
          data.isAllergen = data.isAllergen === 'Y' ? 'Y' : '';
          data.allowedFuncFlag = data.ruleTypeID === 14 ? 'Y' : 'N';
          data.warningText = (data.warningText !== null && data.warningText !== undefined) ? data.warningText : ((data.warningStatement !== null && data.warningStatement !== undefined) ? data.warningStatement : '');
          data.originalWarningText = data.warningText;
          data.warningTextStripped = this.handleSpecialCharacters(data.originalWarningText);
          const warningLinkRuleData = this.assessraService.hyperLinksToSheet(data.warningText);
          data.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
          data.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
          data.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
          data.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
          data.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
          data.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
          data.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
          data.warningText = this.changeComment(data.warningText);
          data.otherRestrictionsAndRequirements = (data.otherRestrictionsAndRequirements !== null) ? data.otherRestrictionsAndRequirements : '';
          data.originalotherRestrictionsAndReq = data.otherRestrictionsAndRequirements;
          data.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(data.originalotherRestrictionsAndReq);
          const orrLinkRuleData = this.assessraService.hyperLinksToSheet(data.otherRestrictionsAndRequirements);
          data.ORR1 = orrLinkRuleData[0] !== undefined ? orrLinkRuleData[0] : '';
          data.ORR2 = orrLinkRuleData[1] !== undefined ? orrLinkRuleData[1] : '';
          data.ORR3 = orrLinkRuleData[2] !== undefined ? orrLinkRuleData[2] : '';
          data.ORR4 = orrLinkRuleData[3] !== undefined ? orrLinkRuleData[3] : '';
          data.ORR5 = orrLinkRuleData[4] !== undefined ? orrLinkRuleData[4] : '';
          data.ORR6 = orrLinkRuleData[5] !== undefined ? orrLinkRuleData[5] : '';
          data.ORR7 = orrLinkRuleData[6] !== undefined ? orrLinkRuleData[6] : '';
          data.otherRestrictionsAndRequirements = this.changeComment(data.otherRestrictionsAndRequirements);
          data.substanceGroup = data.groupName;
          data.uoM = data.ruleUoM;
          data.rpcListForDisplay = data.rpcList;
          if (data.ruleCFDetails !== null) {
          }
          if (data.ruleTypeDisplayName === 'Conflicting Substance Group') {

            let substanceConflicts = '';
            data.conflictingGroup = data.conflictingGroupName;
            if (data.conflictingSubGroupSubstances !== null && data.conflictingSubGroupSubstances.length > 0) {
              data.conflictingSubGroupSubstances.forEach(conflicSub => {
                substanceConflicts = substanceConflicts + '' + conflicSub.pureCode + ' [ ' + conflicSub.sirId + ' ] ';
              });

            }
            data.conflictingSubstance = substanceConflicts;
          }

        });

        this.propertyRuleList = this.ruleExecutionResult.productPropertiesResults;
        this.propertyRuleList.forEach(data => {
          data.ruleVerionToDisplay = data.ruleVersion === null ? '' : 'V' + data.ruleVersion;
          data.isAllergen = data.isAllergen === 'Y' ? 'Y' : '';
          data.allowedFuncFlag = data.ruleTypeID === 14 ? 'Y' : 'N';
          data.warningText = (data.warning !== null) ? data.warning : data.warningStatement;
          data.minLimit = data.propertyMinLimit;
          data.maxLimit = data.propertyMaxLimit;
          data.uoM = data.ruleUoM;
          data.property = data.propertyClass;
          data.subProperty = data.propertyName;
          data.rpcListForDisplay = data.rpcList;
          if (data.ruleTypeID === 7 || data.ruleTypeID === 8 || data.ruleTypeID === 9 || data.ruleTypeID === 49) {
            data.propertySource = 'CUC';
          }
          data.warningText = (data.warning !== null) ? data.warning : ((data.warningStatement !== null) ? data.warningStatement : '');
          data.originalWarningText = data.warningText;
          data.warningTextStripped = this.handleSpecialCharacters(data.originalWarningText);
          const warningLinkRuleData = this.assessraService.hyperLinksToSheet(data.warningText);
          data.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
          data.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
          data.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
          data.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
          data.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
          data.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
          data.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
          data.warningText = this.changeComment(data.warningText);
          data.otherRestrictionsAndRequirements = (data.otherRestrictionsAndRequirements !== null) ? data.otherRestrictionsAndRequirements : '';
          data.originalotherRestrictionsAndReq = data.otherRestrictionsAndRequirements;
          data.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(data.otherRestrictionsAndRequirements);
          const orrLinkRuleData = this.assessraService.hyperLinksToSheet(data.otherRestrictionsAndRequirements);
          data.ORR1 = orrLinkRuleData[0] !== undefined ? orrLinkRuleData[0] : '';
          data.ORR2 = orrLinkRuleData[1] !== undefined ? orrLinkRuleData[1] : '';
          data.ORR3 = orrLinkRuleData[2] !== undefined ? orrLinkRuleData[2] : '';
          data.ORR4 = orrLinkRuleData[3] !== undefined ? orrLinkRuleData[3] : '';
          data.ORR5 = orrLinkRuleData[4] !== undefined ? orrLinkRuleData[4] : '';
          data.ORR6 = orrLinkRuleData[5] !== undefined ? orrLinkRuleData[5] : '';
          data.ORR7 = orrLinkRuleData[6] !== undefined ? orrLinkRuleData[6] : '';
          data.otherRestrictionsAndRequirements = this.changeComment(data.otherRestrictionsAndRequirements);

        });
        this.productWarningRules = this.ruleExecutionResult.productWarnings;
        this.productWarningRules.forEach(prodWarnRule => {
          prodWarnRule.ruleVerionToDisplay = prodWarnRule.ruleVersion === null ? '' : 'V' + prodWarnRule.ruleVersion;
          prodWarnRule.ruleTypeDisplayName = 'Product Warnings';
          prodWarnRule.rpcListForDisplay = prodWarnRule.rpcList;
          prodWarnRule.warningText = (prodWarnRule.warning !== null) ? prodWarnRule.warning : ((prodWarnRule.warningStatement !== null) ? prodWarnRule.warningStatement : '');
          prodWarnRule.originalWarningText = prodWarnRule.warningText;
          prodWarnRule.warningTextStripped = this.handleSpecialCharacters(prodWarnRule.warningText);
          const warningLinkRuleData = this.assessraService.hyperLinksToSheet(prodWarnRule.warningText);
          prodWarnRule.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
          prodWarnRule.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
          prodWarnRule.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
          prodWarnRule.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
          prodWarnRule.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
          prodWarnRule.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
          prodWarnRule.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
          prodWarnRule.warningText = this.changeComment(prodWarnRule.warningText);
          prodWarnRule.otherRestrictionsAndRequirements = (prodWarnRule.otherRestrictionsAndRequirements !== null) ? prodWarnRule.otherRestrictionsAndRequirements : '';
          prodWarnRule.originalotherRestrictionsAndReq = prodWarnRule.otherRestrictionsAndRequirements;
          prodWarnRule.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(prodWarnRule.otherRestrictionsAndRequirements);
          const orrLinkRuleData = this.assessraService.hyperLinksToSheet(prodWarnRule.otherRestrictionsAndRequirements);
          prodWarnRule.ORR1 = orrLinkRuleData[0] !== undefined ? orrLinkRuleData[0] : '';
          prodWarnRule.ORR2 = orrLinkRuleData[1] !== undefined ? orrLinkRuleData[1] : '';
          prodWarnRule.ORR3 = orrLinkRuleData[2] !== undefined ? orrLinkRuleData[2] : '';
          prodWarnRule.ORR4 = orrLinkRuleData[3] !== undefined ? orrLinkRuleData[3] : '';
          prodWarnRule.ORR5 = orrLinkRuleData[4] !== undefined ? orrLinkRuleData[4] : '';
          prodWarnRule.ORR6 = orrLinkRuleData[5] !== undefined ? orrLinkRuleData[5] : '';
          prodWarnRule.ORR7 = orrLinkRuleData[6] !== undefined ? orrLinkRuleData[6] : '';
          prodWarnRule.otherRestrictionsAndRequirements = this.changeComment(prodWarnRule.otherRestrictionsAndRequirements);
         });
        this.propertyRuleList = this.propertyRuleList.concat(this.productWarningRules);
        this.viewPrepareRuleTableData = this.substanceRuleList.concat(this.groupRuleList, this.propertyRuleList);
        } else {
      }
    }, (error) => {
    this.loading = false;
    });
  }
  handleSpecialCharacters(text) {
    return text.replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, '').replace(/&amp;/g, '&');
  }
  onExecute() {
    this.viewPrepareRulePageFlag = false;
    const requestStatus = 'In Progress – RA';
    const statusUpdateApiUrl = environment.APIFAsT + '/assessment/request/' + recipeModel.requestDisplayID + '/status/' + requestStatus + '/comment/SystemGenerated';
    this.loading = true;
    this.loadingMessage = 'Saving Assessment Request Status';
    this.assessraService.putData(statusUpdateApiUrl, null).subscribe((statusresponse) => {
      if (statusresponse.result !== null) {
        this.loading = false;
        const val: any = {
          requestID: recipeModel.requestDisplayID,
          requestStatus: requestStatus
        };
        this.worklistService.setRecipeData(val);
        this.dataService.setSelectedStep(3);
        this.route.navigate(['assess-request-ra/submit-report']);
      }
    }, (error) => {
      this.loading = false;
    });
  }
  goToWorklistBtnClick() {
    this.route.navigate(['/worklist/master-worklist']);
  }
  showOverlay(htmlContent) {
    this.overlayText = htmlContent;
    this.hideOverlay = false;
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
  hideOverlayPanel() {
    this.hideOverlay = true;
  }
  openComment(data) {
    this.warningTextOpen = true;
    const mydata = data.originalWarningText;
    this.tablewarningComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  openCommentORR(data) {
    this.orrTextOpen = true;
    const mydata = data.originalotherRestrictionsAndReq;
    this.tableORRComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  closePopupORR() {
    this.orrTextOpen = false;
  }
  closePopup() {
    this.warningTextOpen = false;
  }
  changeComment(input) {
    const strValue = input;
    const ar = strValue.match(/<a.*?<\/a>/g);
    let commentwarning = '';
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentwarning = 'Click Here';
    } else {
      commentwarning = input;
    }
    return commentwarning;
  }
}
