import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariableUpdateService {
  loading = true;
  dataModel: any;
  constructor(
    public matSnackBar: MatSnackBar,
    private assessRequestRa: AssessRequestRaService
  ) { }
  /* istanbul ignore next */
  updateSIRIds(sIRList: any) {
    if (recipeModel !== null && sIRList !== null) {
      this.dataModel = recipeModel;
      for (let i = 0; i < this.dataModel.formulationRequestDetails.length; i++) {
        sIRList.forEach(element => {
          if (element.pureCode === this.dataModel.formulationRequestDetails[i].pureCode) {
            this.dataModel.formulationRequestDetails[i].substanceID = element.sirId;
            this.dataModel.formulationRequestDetails[i].substanceName = element.substanceName;
            this.dataModel.formulationRequestDetails[i].synonyms = element.synonyms;
            this.dataModel.formulationRequestDetails[i].casNumber = element.cas !== null ? element.cas.join(',') : '';
            this.dataModel.formulationRequestDetails[i].parentCASNumber = element.parentCAS !== null ? element.parentCAS.join(',') : '';
            this.dataModel.formulationRequestDetails[i].insNumber = element.ins !== null ? element.ins.join(',') : '';
            this.dataModel.formulationRequestDetails[i].inciNumber = element.inciName ? element.inciName.join(',') : '';
            this.dataModel.formulationRequestDetails[i].einecsNumber = element.einecs ? element.einecs.join(',') : '';
            this.dataModel.formulationRequestDetails[i].eNumber = element.eNumber ? element.eNumber.join(',') : '';
            this.dataModel.formulationRequestDetails[i].cfDetails = element.cfDetails;
            this.dataModel.formulationRequestDetails[i].userSelectedCFDetails = element.cfDetails !== null ? element.cfDetails[0] : null;
          }
        });
      }
      Object.assign(recipeModel, this.dataModel);
      this.updateAssesmentModel();
    }
  }

  /* istanbul ignore next */
  updateAssesmentModel() {
    this.loading = true;
    const apiurl = environment.APIFAsT + '/assessmentrequestmarket';
    this.assessRequestRa.putData(apiurl, recipeModel).subscribe((response) => {
      this.loading = false;

    }, (error) => {
      this.loading = false;
      if (error.status === 200) {
        this.matSnackBar.open('Successfully updated SIR values ', 'close', {
          duration: 3000,
          panelClass: ['success']
        });
      } else {
        this.matSnackBar.open('SIR Updation Failed', 'close', {
          duration: 3000,
          panelClass: ['error']
        });
      }
    });

  }
}
