import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as _ from 'lodash';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { pureRulesDetailsModel, ruleFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { RuletypesAttributeMapping } from 'src/app/common/models/RuletypesAttributeMapping';
import { sirDetailObj } from 'src/app/common/models/SirModel';
import { DataService } from 'src/app/common/services/data.service';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-common-rule-template',
  templateUrl: './common-rule-template.component.html',
  styleUrls: ['./common-rule-template.component.scss']
})
export class CommonRuleTemplateComponent implements OnInit, AfterViewInit {
  @ViewChild('subBasedCommonForm') subBasedCommonForm: NgForm;
  activeTabUR = 'rule-detail';
  name = 'Angular ';
  editor = ClassicEditor;
  ckeditordata = '';
  ankerCount: any;
  readOnlyValue = true;
  shownoresult = false;
  maxEqualityCondition = [{ value: '<' }, { value: '<=' }];
  minEqualityCondition = [{ value: '>' }, { value: '>=' }];
  equalityCondition = [{ value: 'isEqual' }];
  ruleTypeId;
  ruleDisplayName;
  disableAnyForAllUsers = false;
  rtRadioSelected;
  applicableRuleTypesToDisplay;
  ruleAdded = []; allRulesforRT = []; allFns = []; allowedFns = []; uomList = []; rpc = []; subBasedRuleData = []; rulesListForSubstance = []; ruleSpecificAttributes = []; selectedRuleDetails = []; selectedRuleTypeDetails = [];
  selectedFunction; selectedUoM; selectedEqualityCondition;
  ruleDetails;
  attBtnClicked = false; loading = false;
  loadingMsg;
  ruleTypeRadioSelected = false; saveAndAddNewDialog = false; saveAndAddNewBtnClicked = false;
  defaultDateForPhasedOut = new Date(2099, 11, 31);
  phasedOutDate: any;
  pureSirDetail;
  filteredConflictingSubstances: any[];
  filteredCombRules: any[];
  selectedSubstanceName = '';
  disablePureCode = false;
  search = 'fa fa-fw fa-search';
  uomVal = 0;
  valConfRule;
  disablebtn = true;
  selectedSubstance;
  selectedSubstanceCR;
  showSetVar = false; showWhenVar = false; showCombVar = false; rangeValidationFailed = false;
  setIndex;
  whenIndex;
  combIndex;
  pureSubstanceNameDetail;
  selectedSubstanceNameCR = null;
  disablePureCodeCR = false;
  shownoresultCR = false;
  ratioCR = false;
  disableCRFields = true;
  disableCRFieldsRatio = true;
  ratioSubstanceSirSubstance = null;
  ratioSubstanceSir = null;
  selectedConflictingSubstance;
  output;
  patternRatioCheckFirst = false;
  patternRatioCheckSecond = false;
  hazardClassDropdown = [];
  hazardClassValue;
  hazardCategoryDropdown;
  hazardCategoryValue;
  routeofExposureDropdown;
  routeofExposureValue;
  hStatementDropdown;
  hStatementValue;
  cutOffConcentrationDropdown;
  cutOffConcentrationValue;
  servingSizeFlag = false;
  dosageFlag = false;
  dosageDisabled = false;
  servingSizeDisabled = false;
  allergenFlag = false;
  cancelClicked = false;
  ruleSource = '';
  validityStartDate: any = '';
  validityEndDate: any = '';
  yearAdopted: any = '';
  ruleDescription = '';
  uomValidation = false;
  showDuplicateMessage = false;
  showDuplicateMessageCR = false;
  parallelRuleWorkflow = false;
  editRule = false;
  viewRule = false;
  ruleIdOnEdit;
  ruleVersion;
  currentRoute: string;
  ruleSaveConformationPopup = false;
  ruleComment: string;
  myworkListData = [];
  showRelatedRulesPopup = false;
  myWorklistTableColumns = [];
  viewRelbtn = false;
  marketForModal = '';
  selectedRows = [];
  commentsToUser = '';
  showEmailConfirmationDialog = false;
  defaultValidityFromDate;
  defaultValidityEndDate;
  todayDate;
  config = {
    toolbar: [
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      // '|',
      // 'insertTable',
      'link'
    ],
    image: {
      toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side']
    },
    link: {
      addTargetToExternalLinks: true,
      decorators: [
        {
          mode: '',
          label: '',
          attributes: {
            target: '_blank',
          }
        }
      ]
    }
  };
  constructor(public dataServiceObj: DataService, private worklistservice: WorklistService, private datePipe: DatePipe, private assessReqRAService: AssessRequestRaService, private snackBar: MatSnackBar, private route: Router) { }
  ngAfterViewInit(): void {
    if (this.currentRoute === '/rule-management/view-rules/edit-rules') {
      this.hazardClassValue = (this.selectedRuleTypeDetails[0].hazardClass === null) ? { value: '' } : { value: this.selectedRuleTypeDetails[0].hazardClass };
      this.hazardCategoryValue = (this.selectedRuleTypeDetails[0].hazardCategory === null) ? { value: '' } : { value: this.selectedRuleTypeDetails[0].hazardCategory };
      this.routeofExposureValue = (this.selectedRuleTypeDetails[0].routeOfExposure === null) ? { value: '' } : { value: this.selectedRuleTypeDetails[0].routeOfExposure };
      this.hStatementValue = (this.selectedRuleTypeDetails[0].hStatement === null) ? { value: '' } : { value: this.selectedRuleTypeDetails[0].hStatement };
      this.cutOffConcentrationValue = (this.selectedRuleTypeDetails[0].cutOffConcentration === null) ? { value: '' } : { value: this.selectedRuleTypeDetails[0].cutOffConcentration };
      this.getHazardDropdownValues();
      this.getHazardCategories();
      this.getRouteOfExposures();
      this.getHStatement();
      this.getConcentration();
    }
    this.disableCheckbox();
  }

  disableCheckbox() {
    if (this.dataServiceObj.isfromSubstancesearch) {
      if (!this.dosageFlag) {
        const dosageclass = document.getElementsByClassName('dosageclass').item(0) as HTMLElement;
        dosageclass.style.display = 'none';
      }
      if (!this.servingSizeFlag) {
        const servinlass = document.getElementsByClassName('servinlass').item(0) as HTMLElement;
        servinlass.style.display = 'none';
      }
      if (!this.allergenFlag) {
        const alergenclass = document.getElementsByClassName('alergenclass').item(0) as HTMLElement;
        alergenclass.style.display = 'none';
      }
    }
  }

  /* istanbul ignore next */
  ngOnInit() {
    this.todayDate = new Date();
    this.defaultValidityFromDate = this.datePipe.transform(this.todayDate, 'longDate');
    this.defaultValidityEndDate = this.datePipe.transform(new Date('9999-12-31'), 'longDate');
    this.editRule = false;
    this.viewRule = false;
    if (this.activeTabUR === 'rule-detail') {
      document.getElementById('description').style.display = 'none';
      document.getElementById('ruleValidity').style.display = 'none';
    }
    this.dataServiceObj.getruleToEdit().subscribe((value) => {
      if (value !== null) {
        this.editRule = true;
        Object.assign(sirDetailObj, value.substanceList[0]);
        pureRulesDetailsModel.selectedCF = value.substanceList[0].cfDetails[0];
        this.ruleIdOnEdit = value.ruleId;
        this.ruleVersion = value.ruleVersion;
        const rpcVals = [];
        this.marketForModal = recipeModel.marketDetails.marketName;
        value.rpcs.forEach((val) => {
          const data = {
            id: val.rpcId,
            text: val.rpcName,
            rpcFullText: val.rpcFullText
          };
          rpcVals.push(data);
        });
        pureRulesDetailsModel.selectedParentRpcs = rpcVals;
        pureRulesDetailsModel.selectedRpcs = rpcVals;
        recipeModel.preparationMethod = value.preparationMethod;
        recipeModel.intendedUse = value.intendedUse;
      } else {
        this.editRule = false;
      }
    });

    if (ruleFlags.viewRule) {
      this.editRule = false;
      this.viewRule = true;
    } else if (ruleFlags.editRule) {
      this.editRule = true;
      this.viewRule = false;
    }
    if (!this.editRule) {
      this.dataServiceObj.getSirDetailForParallelRule().subscribe((result) => {
        if (result !== null) {
          this.parallelRuleWorkflow = true;
          Object.assign(sirDetailObj, result[0]);
          sirDetailObj.cfDetails.forEach(element => {
            if (element.cfDisplayText === result[0].selectedCF) {
              pureRulesDetailsModel.selectedCF = element;
            }
          });
        } else {
          this.parallelRuleWorkflow = false;
        }
      });
    }

    let pureSirDetails;
    if (localStorage.getItem('pureSirDetails')) {
      pureSirDetails = JSON.parse(localStorage.getItem('pureSirDetails'));
    }
    if (pureSirDetails && (pureRulesDetailsModel.SirId !== sirDetailObj.sirId)) {
    this.pureSirDetail = pureRulesDetailsModel.SirId;
    this.pureSubstanceNameDetail = pureRulesDetailsModel.SirId + ' (' + pureSirDetails.substanceName + ')';
    } else {
      this.pureSirDetail = sirDetailObj.sirId;
      this.pureSubstanceNameDetail = sirDetailObj.sirId + ' (' + sirDetailObj.substanceName + ')';
    }
    this.dataServiceObj.ruleType.subscribe((value) => {
      if (value.length !== 0) {
        this.clearValues();
        this.selectedRuleTypeDetails[0] = value;
        this.ruleTypeId = this.selectedRuleTypeDetails[0].ruleTypeId;
        this.ruleDisplayName = this.selectedRuleTypeDetails[0].ruleTypeDisplayName;
        this.activeTabUR = 'rule-detail';
        if (this.ruleTypeId === 20) {
          this.disableAnyForAllUsers = true;
        }
        document.getElementById('rule-detail').style.display = 'block';
        document.getElementById('description').style.display = 'none';
        document.getElementById('ruleValidity').style.display = 'none';
        this.ruleDescription = this.selectedRuleTypeDetails[0].ruleTypeDescription ? this.selectedRuleTypeDetails[0].ruleTypeDescription : null;
        if (!this.editRule && !this.viewRule) {
          this.ruleSource = '';
          this.validityStartDate = '';
          this.validityEndDate = '';
          this.yearAdopted = '';
        }
        if (this.ruleTypeId !== 14) {
          this.getUomList();
          this.getFnList();
          this.getRuleSpecificAttributeList(this.ruleTypeId);
        }
        if (this.ruleTypeId === 24) {
          this.defaultDateForPhasedOut = new Date(2099, 11 , 31 , 0 , 0 , 0);
          this.phasedOutDate = this.datePipe.transform(this.defaultDateForPhasedOut, 'yyyy-MM-dd');
        }
        if (this.ruleTypeId === 29) {
          this.getHazardDropdownValues();
          this.hazardValidation();
        }
        if (this.ruleTypeId === 4 || this.ruleTypeId === 20) {
          this.maxEqualityCondition = [{ value: '=' }];
        } else {
          this.maxEqualityCondition = [{ value: '<' }, { value: '<=' }];
          this.minEqualityCondition = [{ value: '>' }, { value: '>=' }];
          this.equalityCondition = [{ value: 'isEqual' }];
        }
      }
      if (this.editRule === true || this.viewRule === true) {
        this.getEditRuleDetails(value);
        this.getWorklistColumns();
      }
    });
  }
  // ngAfterViewInit() {
  //   if (this.currentRoute === '/rule-management/view-rules/edit-rules') {
  //     this.hazardClassValue = (this.selectedRuleTypeDetails[0].hazardClass === null) ? { value: '' } : { value: this.selectedRuleTypeDetails[0].hazardClass };
  //     this.hazardCategoryValue = (this.selectedRuleTypeDetails[0].hazardCategory === null) ? { value: '' } : { value: this.selectedRuleTypeDetails[0].hazardCategory };
  //     this.routeofExposureValue = (this.selectedRuleTypeDetails[0].routeOfExposure === null) ? { value: '' } : { value: this.selectedRuleTypeDetails[0].routeOfExposure };
  //     this.hStatementValue = (this.selectedRuleTypeDetails[0].hStatement === null) ? { value: '' } : { value: this.selectedRuleTypeDetails[0].hStatement };
  //     this.cutOffConcentrationValue = (this.selectedRuleTypeDetails[0].cutOffConcentration === null) ? { value: '' } : { value: this.selectedRuleTypeDetails[0].cutOffConcentration };
  //     this.getHazardDropdownValues();
  //     this.getHazardCategories();
  //     this.getRouteOfExposures();
  //     this.getHStatement();
  //     this.getConcentration();
  //   }
  // }
  getHazardDropdownValues() {
    this.currentRoute = this.route.url;
    this.hazardValidation();
    let url;
    if (this.currentRoute === '/rule-management/view-rules/edit-rules' || this.currentRoute === '/create-insert/create-parallel-rule') {
      url = environment.APIFAsT + '/GetPlHazardClasses_WithoutReqId/' + recipeModel.marketDetails.marketName + '/' + sirDetailObj.pureCode;
    } else {
      url = environment.APIFAsT + '/GetPlHazardClasses/' + recipeModel.requestDisplayID + '/' + sirDetailObj.pureCode;
    }
    this.loading = true;
    this.loadingMsg = 'Getting Hazard Classes';
    this.assessReqRAService.getData(url).subscribe((response) => {
      this.loading = false;
      if (response !== null) {
        this.hazardClassDropdown = response.map(item => {
          return {
            value: item
          };
        });
        if (this.hazardClassDropdown.length === 1) {
          this.hazardClassValue = this.hazardClassDropdown[0];
          this.getHazardCategories();
        }
      }
    }, (error) => {
      this.loading = false;
      if (error.error.message === undefined) {
        this.snackBar.open('System error occured. Please contact administrator.', 'close', {
          duration: 4100,
          panelClass: ['error']
        });
      } else {
        this.snackBar.open(error.error.message, 'close', {
          duration: 3600,
          panelClass: ['error']
        });
      }
    });
  }
  getHazardCategories() {
    this.hazardValidation();
    if (this.hazardClassValue !== null) {
      let GetPlHazardCategoriesurl;
      if (this.currentRoute === '/rule-management/view-rules/edit-rules' || this.currentRoute === '/create-insert/create-parallel-rule') {
        GetPlHazardCategoriesurl = environment.APIFAsT + '/GetPlHazardCategories_WithoutReqId/' + recipeModel.marketDetails.marketName + '/' + sirDetailObj.pureCode + '/' + encodeURIComponent(encodeURIComponent(this.hazardClassValue.value));
      } else {
        GetPlHazardCategoriesurl = environment.APIFAsT + '/GetPlHazardCategories/' + recipeModel.requestDisplayID + '/' + sirDetailObj.pureCode + '/' + encodeURIComponent(encodeURIComponent(this.hazardClassValue.value));
      }
      this.loading = true;
      this.loadingMsg = 'Getting Hazard Categories';
      this.assessReqRAService.getData(GetPlHazardCategoriesurl).subscribe((response) => {
        this.loading = false;
        this.loadingMsg = 'Getting Hazard Classes';
        if (response !== null) {
          this.hazardCategoryDropdown = response.map(item => {
            return {
              value: item
            };
          });
          if (this.hazardCategoryDropdown.length === 1) {
            this.hazardCategoryValue = this.hazardCategoryDropdown[0];
            this.getRouteOfExposures();
          }
        }
      }, (error) => {
        this.loading = false;
        if (error.error.message === undefined) {
          this.snackBar.open('System error occured. Please contact administrator.', 'close', {
            duration: 4100,
            panelClass: ['error']
          });
        } else {
          this.snackBar.open(error.error.message, 'close', {
            duration: 3600,
            panelClass: ['error']
          });
        }
      });
    } else {
      this.hazardCategoryValue = null;
      this.routeofExposureValue = null;
      this.hStatementValue = null;
      this.cutOffConcentrationValue = null;
      this.hazardCategoryDropdown = null;
      this.routeofExposureDropdown = null;
      this.hStatementDropdown = null;
      this.cutOffConcentrationDropdown = null;
    }
  }
  getRouteOfExposures() {
    this.hazardValidation();
    if (this.hazardClassValue !== null && this.hazardCategoryValue !== null) {
      let GetPlHazardRouteOfExpurl;
      if (this.currentRoute === '/rule-management/view-rules/edit-rules' || this.currentRoute === '/create-insert/create-parallel-rule') {
        GetPlHazardRouteOfExpurl = environment.APIFAsT + '/GetPlHazardRouteOfExp_WithoutReqId/' + recipeModel.marketDetails.marketName + '/' + sirDetailObj.pureCode + '/' + encodeURIComponent(encodeURIComponent(this.hazardClassValue.value)) + '/' + encodeURIComponent(encodeURIComponent(this.hazardCategoryValue.value));
      } else {
        GetPlHazardRouteOfExpurl = environment.APIFAsT + '/GetPlHazardRouteOfExp/' + recipeModel.requestDisplayID + '/' + sirDetailObj.pureCode + '/' + encodeURIComponent(encodeURIComponent(this.hazardClassValue.value)) + '/' + encodeURIComponent(encodeURIComponent(this.hazardCategoryValue.value));
      }
      this.loading = true;
      this.loadingMsg = 'Getting Route Of Exposure';
      this.assessReqRAService.getData(GetPlHazardRouteOfExpurl).subscribe((response) => {
        this.loading = false;
        if (response !== null) {
          this.routeofExposureDropdown = response.map(item => {
            return {
              value: item
            };
          });
          if (this.routeofExposureDropdown.length === 1) {
            this.routeofExposureValue = this.routeofExposureDropdown[0];
            this.getHStatement();
          }
        }
      }, (error) => {
        this.loading = false;
        if (error.error.message === undefined) {
          this.snackBar.open('System error occured. Please contact administrator.', 'close', {
            duration: 4100,
            panelClass: ['error']
          });
        } else {
          this.snackBar.open(error.error.message, 'close', {
            duration: 3600,
            panelClass: ['error']
          });
        }
      });
    } else {
      this.routeofExposureValue = null;
      this.hStatementValue = null;
      this.cutOffConcentrationValue = null;
      this.routeofExposureDropdown = null;
      this.hStatementDropdown = null;
      this.cutOffConcentrationDropdown = null;
    }
  }
  getHStatement() {
    this.hazardValidation();
    if (this.hazardClassValue !== null && this.hazardCategoryValue !== null && this.routeofExposureValue !== null) {
      let GetPlHazardHStatementurl;
      if (this.currentRoute === '/rule-management/view-rules/edit-rules' || this.currentRoute === '/create-insert/create-parallel-rule') {
        GetPlHazardHStatementurl = environment.APIFAsT + '/GetPlHazardHStatement_WithoutReqId/' + recipeModel.marketDetails.marketName + '/' + sirDetailObj.pureCode + '/' + encodeURIComponent(encodeURIComponent(this.hazardClassValue.value)) + '/' + encodeURIComponent(encodeURIComponent(this.hazardCategoryValue.value)) + '/' + encodeURIComponent(encodeURIComponent(this.routeofExposureValue.value));
      } else {
        GetPlHazardHStatementurl = environment.APIFAsT + '/GetPlHazardHStatement/' + recipeModel.requestDisplayID + '/' + sirDetailObj.pureCode + '/' + encodeURIComponent(encodeURIComponent(this.hazardClassValue.value)) + '/' + encodeURIComponent(encodeURIComponent(this.hazardCategoryValue.value)) + '/' + encodeURIComponent(encodeURIComponent(this.routeofExposureValue.value));
      }
      this.loading = true;
      this.loadingMsg = 'Getting H Statement';
      this.assessReqRAService.getData(GetPlHazardHStatementurl).subscribe((response) => {
        this.loading = false;
        if (response !== null) {
          this.hStatementDropdown = response.map(item => {
            return {
              value: item
            };
          });
          if (this.hStatementDropdown.length === 1) {
            this.hStatementValue = this.hStatementDropdown[0];
            this.getConcentration();
          }
        }
      }, (error) => {
        this.loading = false;
        if (error.error.message === undefined) {
          this.snackBar.open('System error occured. Please contact administrator.', 'close', {
            duration: 4100,
            panelClass: ['error']
          });
        } else {
          this.snackBar.open(error.error.message, 'close', {
            duration: 3600,
            panelClass: ['error']
          });
        }
      });
    } else {
      this.hStatementValue = null;
      this.cutOffConcentrationValue = null;
      this.hStatementDropdown = null;
      this.cutOffConcentrationDropdown = null;
    }
  }
  /* istanbul ignore next */
  getConcentration() {
    this.hazardValidation();
    if (this.hazardClassValue !== null && this.hazardCategoryValue !== null && this.routeofExposureValue !== null && this.hStatementValue !== null) {
      let GetPlHazardConcentrationurl;
      if (this.currentRoute === '/rule-management/view-rules/edit-rules' || this.currentRoute === '/create-insert/create-parallel-rule') {
        GetPlHazardConcentrationurl = environment.APIFAsT + '/GetPlHazardConcentration_WithoutReqId/' + recipeModel.marketDetails.marketName + '/' + sirDetailObj.pureCode + '/' + encodeURIComponent(encodeURIComponent(this.hazardClassValue.value)) + '/' + encodeURIComponent(encodeURIComponent(this.hazardCategoryValue.value)) + '/' + encodeURIComponent(encodeURIComponent(this.routeofExposureValue.value)) + '/' + encodeURIComponent(encodeURIComponent(this.hStatementValue.value));
      } else {
        GetPlHazardConcentrationurl = environment.APIFAsT + '/GetPlHazardConcentration/' + recipeModel.requestDisplayID + '/' + sirDetailObj.pureCode + '/' + encodeURIComponent(encodeURIComponent(this.hazardClassValue.value)) + '/' + encodeURIComponent(encodeURIComponent(this.hazardCategoryValue.value)) + '/' + encodeURIComponent(encodeURIComponent(this.routeofExposureValue.value)) + '/' + encodeURIComponent(encodeURIComponent(this.hStatementValue.value));
      }
      this.loading = true;
      this.loadingMsg = 'Getting Cut Off / Concentration';
      this.assessReqRAService.getData(GetPlHazardConcentrationurl).subscribe((response) => {
        this.loading = false;
        if (response !== null) {
          this.cutOffConcentrationDropdown = response.map(item => {
            return {
              value: item
            };
          });
          if (this.cutOffConcentrationDropdown.length === 1) {
            this.cutOffConcentrationValue = this.cutOffConcentrationDropdown[0];
          }
        }
      }, (error) => {
        this.loading = false;
        if (error.error.message === undefined) {
          this.snackBar.open('System error occured. Please contact administrator.', 'close', {
            duration: 4100,
            panelClass: ['error']
          });
        } else {
          this.snackBar.open(error.error.message, 'close', {
            duration: 3600,
            panelClass: ['error']
          });
        }
      });
    } else {
      this.cutOffConcentrationValue = null;
      this.cutOffConcentrationDropdown = null;
    }
  }
  // /* istanbul ignore next */
  hazardValidation() {
    if (this.hazardClassValue !== null && this.hazardCategoryValue !== null && this.routeofExposureValue !== null && this.hStatementValue !== null && this.cutOffConcentrationValue !== null) {
      this.disablebtn = false;
    } else {
      this.disablebtn = true;
    }
  }
  onRatioClick(event: any) {
    if (this.ratioCR) {
      this.disableCRFields = false;
      this.disableCRFieldsRatio = false;
    } else {
      this.disableCRFields = true;
      this.disableCRFieldsRatio = true;
      this.ratioSubstanceSirSubstance = null;
      this.ratioSubstanceSir = null;
    }
  }
  checkval(event) {
    this.disablebtn = true;
    setTimeout(() => {
      this.showDuplicateMessage = false;
      this.showDuplicateMessageCR = false;
      if (this.ruleTypeId === 20 && event.target.value !== this.selectedSubstance) {
        this.disablebtn = true;
        this.shownoresult = false;
      } else if (this.ruleTypeId === 27 && event.target.value !== this.selectedSubstanceCR) {
        this.disablebtn = true;
        this.shownoresultCR = false;
      }
    }, 1000);
  }
  valueSelected(val: any) {
    if (this.ruleTypeId === 20) {
      // 'val' param gets you the entire object from API
      this.selectedConflictingSubstance = val;
      this.selectedSubstanceName = val.sirId + ' (' + val.substanceName + ')';
      this.selectedSubstance = val.sirId + ' (' + val.substanceName + ')';
      this.disablePureCode = false;
      if (this.selectedSubstanceName === this.pureSubstanceNameDetail) {
        this.disablebtn = true;
        this.showDuplicateMessage = true;
      } else {
        this.disablebtn = false;
        this.showDuplicateMessage = false;
      }
    } else if (this.ruleTypeId === 27) {
      this.selectedConflictingSubstance = val;
      this.selectedSubstanceNameCR = val.sirId + ' (' + val.substanceName + ')';
      this.selectedSubstanceCR = val.sirId + ' (' + val.substanceName + ')';
      this.disablePureCodeCR = false;
      if (this.selectedSubstanceNameCR === this.pureSubstanceNameDetail) {
        this.disablebtn = true;
        this.showDuplicateMessageCR = true;

      } else {
        this.disablebtn = false;
        this.showDuplicateMessageCR = false;
      }
    }
  }
  filterSubstanceName(event) {
    this.shownoresult = false;
    this.valConfRule = event.target.value;
  }
  filterSubstances() {
    this.shownoresult = false;
    this.shownoresultCR = false;
    this.filteredConflictingSubstances = [];
    this.filteredCombRules = [];
    if (this.valConfRule) {
      this.loading = true;
      this.loadingMsg = 'Loading Substance Details';
      this.assessReqRAService.getConflictingDataSearchResults(this.valConfRule).subscribe((response: any[]) => {
        if (response.length > 0) {
          this.loading = false;
          if (this.ruleTypeId === 20) {
            this.filteredConflictingSubstances = response;
            this.disablePureCode = true;
          } else {
            this.filteredCombRules = response;
            this.disablePureCodeCR = true;
          }
        } else {
          this.loading = false;
          if (this.ruleTypeId === 20) {
            this.disablePureCode = false;
            this.shownoresult = true;
          } else {
            this.disablePureCodeCR = false;
            this.shownoresultCR = true;
          }
        }
      }, (error) => {
        this.loading = false;
      });
    }
  }
  onTabChange(activeTab) {
    this.activeTabUR = activeTab;
    if (activeTab === 'rule-detail') {
      document.getElementById('rule-detail').style.display = 'block';
      document.getElementById('description').style.display = 'none';
      document.getElementById('ruleValidity').style.display = 'none';
    } else if (activeTab === 'description') {
      document.getElementById('rule-detail').style.display = 'none';
      document.getElementById('description').style.display = 'block';
      document.getElementById('ruleValidity').style.display = 'none';
    } else if (activeTab === 'ruleValidity') {
      document.getElementById('rule-detail').style.display = 'none';
      document.getElementById('description').style.display = 'none';
      document.getElementById('ruleValidity').style.display = 'block';
    }
    setTimeout(() => {
      this.disableCheckbox();
    }, 10);
  }
  getUomList() {
    const url = environment.APIFAsT + '/uoms';
    this.assessReqRAService.getData(url).subscribe((response) => {
      if (response !== null) {
        this.uomList = response;
      }
    }, (error) => {
    });
  }
  getFnList() {
    let allowedFns = [];
    this.rpc = [];
    pureRulesDetailsModel.selectedRpcs.forEach((value) => {
      const data = {
        rpcFullText: value.rpcFullText,
        rpcName: value.text,
        rpcId: value.id
      };
      this.rpc.push(data);
    });
    const fetchRuleBody = {
      MarketList: [pureRulesDetailsModel.Market],
      RPCList: this.rpc,
      SIRIds: [sirDetailObj.sirId],
      GetAllFunctionsIfNoAllowedFunctions: 'Y',
    };
    const allowedFnApiUrl = environment.APIFAsT + '/allowedfunctions';
    this.assessReqRAService.postData(allowedFnApiUrl, fetchRuleBody).subscribe((response) => {
      if (response !== null) {
        allowedFns = response;
        if (allowedFns.length !== 0) {
          this.allFns = allowedFns;
          this.ruleSpecificAttributes.filter(x => x.attributeName === 'Function')[0].valuelist = this.allFns;
        } else if (allowedFns.length === 0) {
          this.snackBar.open('Allowed Functions need to be selected', 'close', {
            duration: 4000,
            panelClass: ['error']
          });
          this.dataServiceObj.setExpandablePanel(2);
        }
      }
    }, (error) => {
    });
  }
  /* istanbul ignore next */
  getRuleSpecificAttributeList(ruleTypeId) {
    const ruledetails = RuletypesAttributeMapping.filter(obj => obj.ruleBucket === 'Substance Based' && obj.ruleTypeId === ruleTypeId)[0];
    this.ruleDetails = Object.assign({}, ruledetails);
    const rsListAttr = this.ruleDetails.attributeList;
    this.ruleSpecificAttributes = rsListAttr.map(x => Object.assign({}, x));
    for (let i = 0; i < this.ruleSpecificAttributes.length; i++) {
      if (this.ruleSpecificAttributes[i].attributeTemplate === 'SetLimit' || this.ruleSpecificAttributes[i].attributeTemplate === 'SetText' || this.ruleSpecificAttributes[i].attributeTemplate === 'SetDate') {
        this.showSetVar = true;
        this.setIndex = i;
        break;
      } else {
        this.showSetVar = false;
      }
    }
    for (let i = 0; i < this.ruleSpecificAttributes.length; i++) {
      if (this.ruleSpecificAttributes[i].attributeTemplate === 'CombinationSubSelect') {
        this.showCombVar = true;
        this.combIndex = i;
        break;
      } else {
        this.showCombVar = false;
      }
    }
    for (let i = 0; i < this.ruleSpecificAttributes.length; i++) {
      if (this.ruleSpecificAttributes[i].attributeTemplate === 'When' || this.ruleSpecificAttributes[i].attributeTemplate === 'WhenText' || this.ruleSpecificAttributes[i].attributeTemplate === 'WhenSelect') {
        this.showWhenVar = true;
        this.whenIndex = i;
        break;
      } else {
        this.showWhenVar = false;
      }
    }
    this.ruleSpecificAttributes.filter(x => x.attributeTemplate === 'When').forEach(attr => {
      if (attr.attributeName === 'Function') {
        attr.valuelist = this.allFns;
      }
    });
  }
  removeRTAttribute(value, index: number) {
    value.attributeValue = null;
    value.Uom = [];
    this.ruleSpecificAttributes[index].attributeValue = null;
    this.ruleSpecificAttributes[index].Uom = [];
    this.ruleSpecificAttributes.filter(x => x.attributeName === value.attributeName)[0].isRequired = false;
    for (let i = 0; i < this.ruleSpecificAttributes.length; i++) {
      if ((this.ruleSpecificAttributes[i].attributeTemplate === 'SetLimit' && this.ruleSpecificAttributes[i].isRequired) ||
        (this.ruleSpecificAttributes[i].attributeTemplate === 'SetText' && this.ruleSpecificAttributes[i].isRequired) ||
        (this.ruleSpecificAttributes[i].attributeTemplate === 'SetDate' && this.ruleSpecificAttributes[i].isRequired)) {
        this.showSetVar = true;
        this.setIndex = i;
        break;
      } else {
        this.showSetVar = false;
      }
    }
    for (let i = 0; i < this.ruleSpecificAttributes.length; i++) {
      if ((this.ruleSpecificAttributes[i].attributeTemplate === 'When' && this.ruleSpecificAttributes[i].isRequired) ||
        (this.ruleSpecificAttributes[i].attributeTemplate === 'WhenText' && this.ruleSpecificAttributes[i].isRequired) ||
        (this.ruleSpecificAttributes[i].attributeTemplate === 'WhenSelect' && this.ruleSpecificAttributes[i].isRequired)) {
        this.showWhenVar = true;
        this.whenIndex = i;
        break;
      } else {
        this.showWhenVar = false;
      }
    }
  }
  removeRTAttributeHide(value, index: number) {
    value.attributeValue = null;
    value.Uom = [];
    this.ruleSpecificAttributes[index].attributeValue = null;
    this.ruleSpecificAttributes[index].Uom = [];
    if (value.isRequired === false) {
      this.ruleSpecificAttributes.filter(x => x.attributeName === value.attributeName)[0].isRequired = false;
    }
    for (let i = 0; i < this.ruleSpecificAttributes.length; i++) {
      if ((this.ruleSpecificAttributes[i].attributeTemplate === 'SetLimit' && this.ruleSpecificAttributes[i].isRequired) ||
        (this.ruleSpecificAttributes[i].attributeTemplate === 'SetText' && this.ruleSpecificAttributes[i].isRequired) || (this.ruleSpecificAttributes[i].attributeTemplate === 'SetDate' && this.ruleSpecificAttributes[i].isRequired)) {
        this.showSetVar = true;
        this.setIndex = i;
        break;
      } else {
        this.showSetVar = false;
      }
    }
    for (let i = 0; i < this.ruleSpecificAttributes.length; i++) {
      if ((this.ruleSpecificAttributes[i].attributeTemplate === 'When' && this.ruleSpecificAttributes[i].isRequired) ||
        (this.ruleSpecificAttributes[i].attributeTemplate === 'WhenText' && this.ruleSpecificAttributes[i].isRequired) ||
        (this.ruleSpecificAttributes[i].attributeTemplate === 'WhenSelect' && this.ruleSpecificAttributes[i].isRequired)) {
        this.showWhenVar = true;
        this.whenIndex = i;
        break;
      } else {
        this.showWhenVar = false;
      }
    }
  }
  /* istanbul ignore next */
  onSaveAndClose() {
    this.onSave();
    if (!this.editRule && !this.viewRule) {
      setTimeout(() => this.dataServiceObj.setExpandablePanel(2), 5500);
    }
  }

  onSaveandAddNew() {
    this.onSave();
  }
  onDosageClick() {
    this.servingSizeDisabled = !this.servingSizeDisabled;
    pureRulesDetailsModel.dosageFlag = this.dosageFlag;
  }
  onServingSizeClick() {
    this.dosageDisabled = !this.dosageDisabled;
    pureRulesDetailsModel.servingSizeFlag = this.servingSizeFlag;
  }
  onAllergenClick() {
    pureRulesDetailsModel.allergenFlag = this.allergenFlag;
  }
  /* istanbul ignore next */
  onSave() {
    let saveRuleToDbUrl;
    let maxValue; let uomValue; let maxEqualityValue; let minEqualityValue; let equalityCondition; let minValue;
    let fnValue = { functionId: '', functionName: '' };
    let fnId; let fnName; let uoMSymbol; let uoMId;
    let warningText; let otherText; let spConditionText; let claimsText;
    let ruleType;
    const rpcData = [];
    ruleType = this.ruleDetails.ruleType;
    this.ruleSpecificAttributes.forEach((ruleattr) => {
      if (ruleattr.attributeName === 'Max') {
        maxValue = ruleattr.attributeValue;
        uomValue = ruleattr.Uom;
        if (uomValue !== undefined && uomValue !== null && Object.keys(uomValue).length > 0) {
          uoMId = uomValue.uoMid;
          uoMSymbol = uomValue.uoMsymbol;
        }
        maxEqualityValue = ruleattr.equalityCondition === undefined && maxValue ? { value: '<' } : ruleattr.equalityCondition;
        if (maxEqualityValue && ruleattr.isRequired) {
          equalityCondition = maxEqualityValue.value !== undefined ? maxEqualityValue.value : '';
        }
      }
      if (ruleattr.attributeName === 'Min') {
        minValue = ruleattr.attributeValue;
        uomValue = ruleattr.Uom;
        if (uomValue !== undefined && uomValue !== null && Object.keys(uomValue).length > 0) {
          uoMId = uomValue.uoMid;
          uoMSymbol = uomValue.uoMsymbol;
        }
        minEqualityValue = ruleattr.equalityCondition === undefined && minValue ? { value: '>' } : ruleattr.equalityCondition;
        if (minEqualityValue && ruleattr.isRequired) {
          equalityCondition = minEqualityValue.value !== undefined ? minEqualityValue.value : '';
        }
      }
      if (ruleattr.attributeName === 'Function') {
        fnValue = ruleattr.attributeValue;
        if (fnValue !== undefined && fnValue !== null && fnValue.functionName !== 'Any') {
          fnId = fnValue.functionId;
          fnName = fnValue.functionName;
        }
      }
      if (ruleattr.attributeName === 'Warning') {
        warningText = ruleattr.attributeValue;
      }
      if (ruleattr.attributeName === 'Other Requirements & Restrictions') {
        otherText = ruleattr.attributeValue;
      }
      if (ruleattr.attributeName === 'Conditional Requirements') {
        spConditionText = ruleattr.attributeValue;
      }
      if (ruleattr.attributeName === 'Claims') {
        claimsText = ruleattr.attributeValue;
      }
      if (ruleattr.attributeName === 'Hazard at Substance Level') {
        otherText = ruleattr.attributeValue;
      }
    });
    let pureSirDetails;
    if (localStorage.getItem('pureSirDetails')) {
      pureSirDetails = JSON.parse(localStorage.getItem('pureSirDetails'));
    }
    let substanceData = [];
    if (pureSirDetails && (pureRulesDetailsModel.SirId !== sirDetailObj.sirId)) {
      substanceData = [{
        PureCode: pureSirDetails.pureCode,
        PureDescription: pureSirDetails.pureDescription,
        SirId: pureSirDetails.sirId,
        SubstanceName: pureSirDetails.substanceName,
        InciName: pureSirDetails.inciName.toString(),
        Einecs: pureSirDetails.einecs.toString(),
        Cas: pureSirDetails.cas.toString(),
        Ins: pureSirDetails.ins.toString(),
        ENumber: pureSirDetails.eNumber.toString(),
        SubstanceFunction: fnId && fnName ? { FunctionId: fnId ? fnId : null, FunctionName: fnName ? fnName : '' } : null,
        UoM: uoMSymbol ? uoMSymbol : '',
        CFDetails: [pureSirDetails.cfDetails[0]],
        SubstanceMolWeight: pureSirDetails.substanceMolWeight,
        CombinationRatio: (this.ruleTypeId === 27 && this.ratioSubstanceSirSubstance) ? this.ratioSubstanceSirSubstance : null
      }];

    } else {
      substanceData = [{
        PureCode: sirDetailObj.pureCode,
        PureDescription: sirDetailObj.pureDescription,
        SirId: sirDetailObj.sirId,
        SubstanceName: sirDetailObj.substanceName,
        InciName: sirDetailObj.inciName.toString(),
        Einecs: sirDetailObj.einecs.toString(),
        Cas: sirDetailObj.cas.toString(),
        Ins: sirDetailObj.ins.toString(),
        ENumber: sirDetailObj.eNumber.toString(),
        SubstanceFunction: fnId && fnName ? { FunctionId: fnId ? fnId : null, FunctionName: fnName ? fnName : '' } : null,
        UoM: uoMSymbol ? uoMSymbol : '',
        CFDetails: [pureRulesDetailsModel.selectedCF],
        SubstanceMolWeight: sirDetailObj.substanceMolWeight,
        CombinationRatio: (this.ruleTypeId === 27 && this.ratioSubstanceSirSubstance) ? this.ratioSubstanceSirSubstance : null
      }];
      localStorage.setItem('pureSirDetails', '');
    }
    if (this.ruleTypeId === 20) {
      saveRuleToDbUrl = environment.APIFAsTRule + '/conflictingSubstancesRule';
    } else if (this.ruleTypeId !== 20) {
      saveRuleToDbUrl = environment.APIFAsTRule + '/rulesList';
    }
    if (this.ruleTypeId === 3 || (minValue !== undefined && minValue !== null && maxValue !== undefined && maxValue !== null)) {  // for Range Rule
      equalityCondition = minEqualityValue.value + ';' + maxEqualityValue.value;
    }
    if (minValue === undefined && maxValue === undefined) {
      equalityCondition = '';
    }
    if (this.ruleTypeId === 20) {
      maxValue = this.uomVal;
      equalityCondition = '=';
      this.selectedConflictingSubstance.SubstanceFunction = { FunctionId: fnId ? fnId : null, FunctionName: fnName ? fnName : '' };
      substanceData.push(this.selectedConflictingSubstance);
    }
    if (this.ruleTypeId === 27) {
      this.selectedConflictingSubstance.SubstanceFunction = { FunctionId: fnId ? fnId : null, FunctionName: fnName ? fnName : '' };
      this.selectedConflictingSubstance.CombinationRatio = this.ratioSubstanceSir;
      substanceData.push(this.selectedConflictingSubstance);
    }
    if (this.ruleTypeId === 4) {
      maxValue = this.uomVal;
      equalityCondition = '=';
    }
    if (pureRulesDetailsModel.CascadeRpc === 'Y') {
      pureRulesDetailsModel.selectedParentRpcs.forEach((value) => {
        const data = {
          rpcFullText: value.rpcFullText,
          rpcName: value.text,
          rpcId: value.id
        };
        rpcData.push(data);
      });
    } else {
      pureRulesDetailsModel.selectedRpcs.forEach((value) => {
        const data = {
          rpcFullText: value.rpcFullText,
          rpcName: value.text,
          rpcId: value.id
        };
        rpcData.push(data);
      });
    }
    const savedData = [{
      Markets: [pureRulesDetailsModel.Market],
      RPCs: rpcData, /// object list
      RuleId: this.ruleIdOnEdit ? this.ruleIdOnEdit : 0,
      RuleVersion: this.ruleVersion ? this.ruleVersion : 0,
      RuleTypeID: this.ruleTypeId,
      RuleType: ruleType,
      SubstanceList: substanceData,
      CascadeMarket: 'N',
      CascadeRpc: 'Y', // made to Y by default as the flag is always selected
      UoMID: uoMId ? uoMId : 2,
      UoM: uoMSymbol ? uoMSymbol : 'N/A',
      TexualMinLimitText: '',
      TexualMaxLimitText: '',
      RuleDescription: ruleType,
      RuleSource: this.ruleSource ? this.ruleSource : '',
      RuleSourceType: 'Regulatory',
      AdditionalStatement: otherText ? otherText : '', // other requirements and restrictions
      PositiveStatement: '',
      MaxLimit: maxValue === 0 || maxValue ? maxValue : '',
      MinLimit: minValue === 0 || minValue ? minValue : '',
      EqualityCondition: equalityCondition ? equalityCondition : '',
      Claims: claimsText ? [{ ClaimText: claimsText ? claimsText : '' }] : null,
      SpeficicConditions: spConditionText ? [{ SpecificConditionText: spConditionText ? spConditionText : '' }] : null,
      FunctionList: null, // used only for allowed function array of objects
      NegativeStatement: (this.ruleTypeId !== 15 && this.ruleTypeId !== 33 && this.ruleTypeId !== 28) ? warningText : '',
      PreparationMethod: recipeModel.preparationMethod,
      IntendedUse: recipeModel.intendedUse,
      IsMandatorySubstance: null, // Y for substance mandatory rule
      IsAllergenApplicable: pureRulesDetailsModel.allergenFlag ? 'Y' : 'N',
      IsDosageApplicable: pureRulesDetailsModel.dosageFlag ? 'Y' : 'N',
      IsServingSizeApplicable: pureRulesDetailsModel.servingSizeFlag ? 'Y' : 'N',
      PhasedOutDate: this.phasedOutDate !== '' ? this.phasedOutDate : null,
      WarningText: (this.ruleTypeId === 15 || this.ruleTypeId === 24 || this.ruleTypeId === 33 || this.ruleTypeId === 28) ? warningText : null, // used for warning rule, adulterated rule , phased out rule, other requirements and restrictions, Product Warnings
      HazardClass: '',
      HazardCategory: '',
      RouteOfExposure: '',
      HStatement: '',
      CutOffConcentration: '',
      yearAdopted: this.yearAdopted ? this.datePipe.transform(this.yearAdopted, 'yyyy-MM-dd') : null,
      ValidityStartDate: this.validityStartDate ? this.datePipe.transform(this.validityStartDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityFromDate, 'yyyy-MM-dd'),
      ValidityDate: this.validityEndDate ? this.datePipe.transform(this.validityEndDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityEndDate, 'yyyy-MM-dd'),
      Comments: this.ruleComment ? this.ruleComment : null,
      RuleFlow: this.parallelRuleWorkflow === true ? 'Parallel' : 'Request'
    }];
    if (this.ruleTypeId === 29) {
      savedData[0].HazardClass = this.hazardClassValue && this.hazardClassValue.value ? this.hazardClassValue.value : '';
      savedData[0].HazardCategory = this.hazardCategoryValue && this.hazardCategoryValue.value ? this.hazardCategoryValue.value : '';
      savedData[0].RouteOfExposure = this.routeofExposureValue && this.routeofExposureValue.value ? this.routeofExposureValue.value : '';
      savedData[0].HStatement = this.hStatementValue && this.hStatementValue.value ? this.hStatementValue.value : '';
      savedData[0].CutOffConcentration = this.cutOffConcentrationValue && this.cutOffConcentrationValue.value ? this.cutOffConcentrationValue.value : '';
    }
    this.loading = true;
    this.loadingMsg = 'Saving Rule';
    if (!this.editRule) {
      this.assessReqRAService.postData(saveRuleToDbUrl, savedData).subscribe((response) => {
        this.loading = false;
        this.clearValues();
        if (response[0].ruleId !== 0) {
          this.ruleAdded = response;
          this.getRuleDetailsFromDb();
          this.snackBar.open('Rule created successfully with Rule Id:' + response[0].ruleId, 'close', {
            duration: 5000,
            panelClass: ['success']
          });
          if (this.saveAndAddNewBtnClicked === true) {
            setTimeout(() => {
              this.applicableRuleTypesToDisplay = pureRulesDetailsModel.applicableRuleTypeList;
              this.saveAndAddNewDialog = true;
            }, 3000);
          }
        } else {
          this.snackBar.open('Rule could not be created.', 'close', {
            duration: 5000,
            panelClass: ['error']
          });
        }
      }
        , (error) => {
          this.loading = false;
        }
      );
    } else {
      saveRuleToDbUrl = environment.APIFAsTRule + '/rules';
      this.assessReqRAService.putData(saveRuleToDbUrl, savedData).subscribe((response) => {
        this.loading = false;
        this.clearValues();
        if (response[0].ruleId !== 0) {
          this.ruleAdded = response;
          this.snackBar.open('Rule updated successfully with Rule Id:' + response[0].ruleId + ' and Rule Version: ' + response[0].ruleVersion, 'close', {
            duration: 5000,
            panelClass: ['success']
          });
          this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
            this.route.navigate(['/rule-management/view-rules']);
          });
        } else {
          this.snackBar.open('Rule could not be created.', 'close', {
            duration: 5000,
            panelClass: ['error']
          });
        }
      }
        , (error) => {
          this.loading = false;
        }
      );
    }

  }
  getRuleDetailsFromDb() {
    this.rpc = [];
    pureRulesDetailsModel.selectedRpcs.forEach((value) => {
      const data = {
        rpcFullText: value.rpcFullText,
        rpcName: value.text,
        rpcId: value.id
      };
      this.rpc.push(data);
    });
    let pureSirDetails;
    if (localStorage.getItem('pureSirDetails')) {
      pureSirDetails = JSON.parse(localStorage.getItem('pureSirDetails'));
    }
    let  fetchRuleBody;
    if (pureSirDetails && (pureRulesDetailsModel.SirId !== sirDetailObj.sirId)) {
       fetchRuleBody = {
        MarketList: [pureRulesDetailsModel.Market],
        RPCList: this.rpc,
        SIRIds: [pureSirDetails.sirId],
        ruleClassifications: ['Substance Based Rules']
      };
    } else {
     fetchRuleBody = {
      MarketList: [pureRulesDetailsModel.Market],
      RPCList: this.rpc,
      SIRIds: [sirDetailObj.sirId],
      ruleClassifications: ['Substance Based Rules']
    };
  }
    this.loading = true;
    this.loadingMsg = 'Fetching data from FAST Rule-Engine';
    const getruleapiurl = environment.APIFAsTRule + '/rules/rulesFilter';
    return new Promise<any>((resolve) => {
      this.assessReqRAService.postData(getruleapiurl, fetchRuleBody)
        .toPromise()
        .then(response => {
          this.loading = false;
          if (response !== null || response !== []) {
            resolve(response);
            this.subBasedRuleData = response;
            this.subBasedRuleData = _.filter(this.subBasedRuleData, (val) => val.ruleClassification === 'Substance Based Rules');
            for (const value of this.subBasedRuleData) {
              value.ruleVerionToDisplay = 'V' + value.ruleVersion;
              value.warningText = (value.warningText === null || value.warningText === '') ? (value.warningStatement === null || value.warningStatement === '') ? '' : value.warningStatement : value.warningText;
              if (value.warningText !== '') {
                value.originalWarningText = value.warningText;
                const warningLinkRuleData = this.assessReqRAService.hyperLinksToSheet(value.warningText);
                value.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
                value.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
                value.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
                value.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
                value.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
                value.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
                value.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
                value.warningText = this.changeComment(value);
                value.warningTextStripped = this.handleSpecialCharacters(value.warningText);
              } else {
                value.warningTextStripped = '';
              }
              value.otherRestrictionsAndRequirements = (value.otherRestrictionsAndRequirements === null || value.otherRestrictionsAndRequirements === '') ? '' : value.otherRestrictionsAndRequirements;
              const orrLinkRuleData = this.assessReqRAService.hyperLinksToSheet(value.otherRestrictionsAndRequirements);
              value.ORR1 = orrLinkRuleData[0] !== undefined ? orrLinkRuleData[0] : '';
              value.ORR2 = orrLinkRuleData[1] !== undefined ? orrLinkRuleData[1] : '';
              value.ORR3 = orrLinkRuleData[2] !== undefined ? orrLinkRuleData[2] : '';
              value.ORR4 = orrLinkRuleData[3] !== undefined ? orrLinkRuleData[3] : '';
              value.ORR5 = orrLinkRuleData[4] !== undefined ? orrLinkRuleData[4] : '';
              value.ORR6 = orrLinkRuleData[5] !== undefined ? orrLinkRuleData[5] : '';
              value.ORR7 = orrLinkRuleData[6] !== undefined ? orrLinkRuleData[6] : '';
              if (value.otherRestrictionsAndRequirements !== '') {
                value.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(value.otherRestrictionsAndRequirements);
              } else {
                value.otherRestrictionsAndRequirementsStripped = '';
              }
              if (value.cfDetails !== null && value.cfDetails !== undefined) {
                const cfValue = value.cfDetails;
                value.cf = cfValue.cf;
                value.cfSubstanceExpressedAs = cfValue.component;
              }
            }
            this.dataServiceObj.setRulesListForPure(this.subBasedRuleData);
          }
        }).catch(error => {
          this.loading = false;
        });
    });
  }
  handleSpecialCharacters(text) {
    return text.replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, '').replace(/&amp;/g, '&');
  }
  /* istanbul ignore next */
  onCancel() {
    if (!this.editRule) {
      this.clearValues();
      this.dataServiceObj.setExpandablePanel(2);
    } else {
      this.clearValues();
      this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
        this.route.navigate(['/rule-management/view-rules']);
      });
    }
  }
  onContinue() {
    this.clearValues();
    this.saveAndAddNewDialog = false;
    this.saveAndAddNewBtnClicked = false;
    if (this.rtRadioSelected !== undefined) {
      const obj = _.filter(pureRulesDetailsModel.applicableRuleTypeList, (element) => element.ruleTypeDisplayName === this.rtRadioSelected);
      // tslint:disable-next-line: no-shadowed-variable
      const obj1 = obj.reduce((obj, newObj, i) => {
        newObj[i] = obj;
        return newObj;
      }, {});
      this.dataServiceObj.setSelectedRuleType(obj1);
      this.dataServiceObj.setExpandablePanel(3);
    }
  }
  onAddAttributeBtnClicked() {
    this.attBtnClicked = !this.attBtnClicked;
  }
  ruleTypeRadioButtonChanged(event) {
    this.ruleTypeRadioSelected = true;
    this.rtRadioSelected = event.target.value;
  }
  dateSelect(event) {
    const phasedDate = new Date(event).toDateString();
    this.phasedOutDate = this.datePipe.transform(phasedDate, 'yyyy-MM-dd');
  }
  clearValues() {
    this.ruleDetails = [];
    this.selectedSubstanceName = '';
    this.ratioCR = false;
    this.ratioSubstanceSir = null;
    this.ratioSubstanceSirSubstance = null;
    this.selectedSubstanceNameCR = null;
    this.disableCRFields = true;
    this.disableCRFieldsRatio = true;
    this.disablebtn = true;
    this.patternRatioCheckFirst = false;
    this.patternRatioCheckSecond = false;
    this.hazardClassValue = null;
    this.hazardCategoryValue = null;
    this.routeofExposureValue = null;
    this.hStatementValue = null;
    this.cutOffConcentrationValue = null;
    this.saveAndAddNewDialog = false;
    this.servingSizeFlag = false;
    this.dosageFlag = false;
    this.allergenFlag = false;
    this.dosageDisabled = false;
    this.servingSizeDisabled = false;
    this.uomValidation = false;
    this.phasedOutDate = '';
    pureRulesDetailsModel.dosageFlag = null;
    pureRulesDetailsModel.servingSizeFlag = null;
    pureRulesDetailsModel.allergenFlag = null;
  }
  checkRatioFirst(val: any) {
    const reg = new RegExp('^[0-9]+(\.[0-9]{1,10})?$');
    if (val.target.value) {
      this.output = reg.test(val.target.value);
      if (this.output) {
        this.patternRatioCheckFirst = false;
        //  this.disablebtn = false;
      } else {
        this.patternRatioCheckFirst = true;
      }
    } else {
      //   this.disablebtn = true;
      this.patternRatioCheckFirst = false;
    }
  }
  checkRatioSecond(val: any) {
    const reg = new RegExp('^[0-9]+(\.[0-9]{1,10})?$');
    if (val.target.value) {
      this.output = reg.test(val.target.value);
      if (this.output) {
        this.patternRatioCheckSecond = false;
        //  this.disablebtn = false;
      } else {
        this.patternRatioCheckSecond = true;
      }
    } else {
      //  this.disablebtn = true;
      this.patternRatioCheckSecond = false;
    }
  }
  checkRangeValidation() {
    let minValue; let maxValue;
    if (this.ruleTypeId === 3) {
      this.ruleSpecificAttributes.forEach((ruleattr) => {
        if (ruleattr.attributeName === 'Max') {
          maxValue = ruleattr.attributeValue;
        }
        if (ruleattr.attributeName === 'Min') {
          minValue = ruleattr.attributeValue;
        }
      });
      if (Number(minValue) > Number(maxValue)) {
        this.rangeValidationFailed = true;
      } else {
        this.rangeValidationFailed = false;
      }
    }
  }
  checkUomValue(event) {
    const value = event.target.value;
    if (value.length > 0) {
      this.uomValidation = true;
    } else {
      this.uomValidation = false;
    }
  }
  onClickedOutside() {
    this.attBtnClicked = false;
  }
  onModelChanged() {
    alert('Model Changed');
  }
  getEditRuleDetails(ruleToEdit) {
    let firstValue; let secondValue;
    pureRulesDetailsModel.allergenFlag = ruleToEdit.isAllergenApplicable === 'Y' ? true : false;
    this.allergenFlag = ruleToEdit.isAllergenApplicable === 'Y' ? true : false;
    if (this.viewRule) {
      this.defaultValidityFromDate = ruleToEdit.validityStartDate ? (this.datePipe.transform(new Date(this.datePipe.transform(ruleToEdit.validityStartDate, 'yyyy-MM-dd')), 'longDate')) : '';
      this.defaultValidityEndDate = ruleToEdit.validityDate ? (this.datePipe.transform(new Date(this.datePipe.transform(ruleToEdit.validityDate, 'yyyy-MM-dd')), 'longDate')) : '';
    }
    if (this.editRule) {
      this.defaultValidityEndDate = ruleToEdit.validityDate ? (this.datePipe.transform(new Date(this.datePipe.transform(ruleToEdit.validityDate, 'yyyy-MM-dd')), 'longDate')) : '';
    }
    this.yearAdopted = ruleToEdit.yearAdopted ? (new Date(ruleToEdit.yearAdopted)).toUTCString() : '';
    this.ruleSource = ruleToEdit.ruleSource;
    if (ruleToEdit.isDosageApplicable === 'Y') {
      this.dosageFlag = true;
      this.servingSizeDisabled = true;
      pureRulesDetailsModel.dosageFlag = true;
    } else {
      this.dosageFlag = false;
      pureRulesDetailsModel.dosageFlag = false;
    }
    if (ruleToEdit.isServingSizeApplicable === 'Y') {
      this.servingSizeFlag = true;
      this.dosageDisabled = true;
      pureRulesDetailsModel.servingSizeFlag = true;
    } else {
      this.servingSizeFlag = false;
      pureRulesDetailsModel.servingSizeFlag = false;
    }
    if (ruleToEdit.ruleTypeID === 3) {
      firstValue = ruleToEdit.equalityCondition.split(';')[0];
      secondValue = ruleToEdit.equalityCondition.split(';')[1];
    }
    this.ruleSpecificAttributes.forEach((ruleattr) => {
      if (ruleattr.attributeName === 'Max' && (ruleToEdit.maxLimit || ruleToEdit.maxLimit === 0)) {
        ruleattr.isRequired = true;
        ruleattr.attributeValue = ruleToEdit.maxLimit;
        ruleattr.Uom = { uoMsymbol: ruleToEdit.uoM, uoMid: ruleToEdit.uoMID };
        if (ruleToEdit.ruleTypeID === 3) {
          ruleattr.equalityCondition = { value: secondValue };
        } else if (ruleToEdit.ruleTypeID !== 3 && (ruleToEdit.maxLimit || ruleToEdit.maxLimit === 0) && (ruleToEdit.minLimit || ruleToEdit.minLimit === 0)) {
          ruleattr.equalityCondition = { value: ruleToEdit.equalityCondition.split(';')[1] };
        } else {
          ruleattr.equalityCondition = { value: ruleToEdit.equalityCondition };
        }
      }
      if (ruleattr.attributeName === 'Function' && ruleToEdit.substanceList && ruleToEdit.substanceList.length > 0) {
        ruleattr.isRequired = true;
        const functionVal = ruleToEdit.substanceList[0].substanceFunction;
        ruleattr.attributeValue = { functionName: functionVal ? functionVal.functionName : 'Any', functionId: functionVal ? functionVal.functionId : 'Any' };
      }
      if (ruleattr.attributeName === 'Warning' && ruleToEdit.negativeStatement) {
        ruleattr.isRequired = true;
        ruleattr.attributeValue = ruleToEdit.negativeStatement;
      }
      if (ruleattr.attributeName === 'Warning' && (ruleToEdit.ruleTypeID === 15 || ruleToEdit.ruleTypeID === 28)) {
        ruleattr.isRequired = true;
        ruleattr.attributeValue = ruleToEdit.warningText;
      }
      if (ruleattr.attributeName === 'Other Requirements & Restrictions' && ruleToEdit.additionalStatement) {
        ruleattr.isRequired = true;
        ruleattr.attributeValue = ruleToEdit.additionalStatement;
      }
      if (ruleattr.attributeName === 'Min' && (ruleToEdit.minLimit || ruleToEdit.minLimit === 0)) {
        ruleattr.isRequired = true;
        ruleattr.attributeValue = ruleToEdit.minLimit;
        ruleattr.Uom = { uoMsymbol: ruleToEdit.uoM, uoMid: ruleToEdit.uoMID };
        if (ruleToEdit.ruleTypeID === 3) {
          ruleattr.equalityCondition = { value: firstValue };
        } else if (ruleToEdit.ruleTypeID !== 3 && (ruleToEdit.maxLimit || ruleToEdit.maxLimit === 0) && (ruleToEdit.minLimit || ruleToEdit.minLimit === 0)) {
          ruleattr.equalityCondition = { value: ruleToEdit.equalityCondition.split(';')[0] };
        } else {
          ruleattr.equalityCondition = { value: ruleToEdit.equalityCondition };
        }
      }
      if (ruleattr.attributeName === 'Conditional Requirements' && ruleToEdit.speficicConditions && ruleToEdit.speficicConditions.length > 0) {
        ruleattr.isRequired = true;
        ruleattr.attributeValue = ruleToEdit.speficicConditions[0].specificConditionText;
      }
      if (ruleattr.attributeName === 'Claims' && ruleToEdit.claims && ruleToEdit.claims.length > 0) {
        ruleattr.isRequired = true;
        ruleattr.attributeValue = ruleToEdit.claims[0].claimText;
      }
      if (ruleattr.attributeName === 'Combination Substance' && ruleToEdit.ruleTypeID === 27 && ruleToEdit.substanceList && ruleToEdit.substanceList.length > 0) {
        const val = ruleToEdit.substanceList[1];
        if (ruleToEdit.substanceList.length > 0 && ruleToEdit.substanceList[0].combinationRatio && ruleToEdit.substanceList[1].combinationRatio) {
          this.ratioCR = true;
          this.disableCRFields = false;
          this.disableCRFieldsRatio = false;
        } else {
          this.ratioCR = false;
          this.disableCRFields = true;
          this.disableCRFieldsRatio = true;
        }
        this.disablebtn = false;
        this.patternRatioCheckFirst = false;
        this.patternRatioCheckSecond = false;
        this.ratioSubstanceSirSubstance = ruleToEdit.substanceList[0].combinationRatio;
        this.ratioSubstanceSir = ruleToEdit.substanceList[1].combinationRatio;
        this.selectedConflictingSubstance = val;
        this.selectedSubstanceNameCR = val.sirId + ' (' + val.substanceName + ')';
        this.selectedSubstanceCR = val.sirId + ' (' + val.substanceName + ')';
        this.disablePureCodeCR = false;
      }
      if (ruleattr.attributeName === 'Conflicting Substance' && ruleToEdit.ruleTypeID === 20 && ruleToEdit.substanceList && ruleToEdit.substanceList.length > 0) {
        const val = ruleToEdit.substanceList[1];
        this.selectedConflictingSubstance = val;
        this.selectedSubstanceName = val.sirId + ' (' + val.substanceName + ')';
        this.selectedSubstance = val.sirId + ' (' + val.substanceName + ')';
        this.disablePureCode = false;
        this.disablebtn = false;
      }
      if (ruleattr.attributeName === 'Phase out') {
        ruleattr.isRequired = true;
        this.phasedOutDate = ruleToEdit.phasedOutDate;
      }
    });
  }
  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }
  /* istanbul ignore next */
  checkRulesAvail() {
    this.viewRelbtn = true;
    this.myworkListData = [];
    this.selectedRows = [];
    this.loadingMsg = 'Fetching data from FAsT Rule-Engine';
    this.loading = true;
    const apiurl = environment.APIFAsTRule + '/rules/queryrelatedrequests';
    const body = [];
    this.showRelatedRulesPopup = true;
    body.push({
      RuleId: this.ruleIdOnEdit,
      RuleVersion: this.ruleVersion
    });
    this.assessReqRAService.postData(apiurl, body).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.myworkListData = response.map(reqElem => {
          return {
            requestDisplayID: reqElem.requestDisplayID,
            cucCode: reqElem.cucCode,
            cucDescription: reqElem.cucDescription,
            recipeType: reqElem.recipeType,
            recipeStatus: reqElem.recipeStatus,
            marketId: reqElem.market.marketId,
            marketName: reqElem.market.marketName,
            division: (reqElem.tfc.tfcLevel === 5) ? reqElem.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName : reqElem.tfc.parentTFC.parentTFC.parentTFC.tfcName,
            rDFormulationCategory: (reqElem.tfc.tfcLevel === 5) ? reqElem.tfc.parentTFC.parentTFC.parentTFC.tfcName : reqElem.tfc.parentTFC.parentTFC.tfcName,
            formulationType: (reqElem.tfc.tfcLevel === 5) ? reqElem.tfc.parentTFC.parentTFC.tfcName : reqElem.tfc.parentTFC.tfcName,
            formulationClass: (reqElem.tfc.tfcLevel === 5) ? reqElem.tfc.parentTFC.tfcName : reqElem.tfc.tfcName,
            formulationSubClass: (reqElem.tfc.tfcLevel === 5) ? reqElem.tfc.tfcName : '',
            preparationMethod: reqElem.preparationMethod,
            intendedUse: reqElem.intendedUse,
            multicomponent: reqElem.isMultiComponent,
            requestStatus: reqElem.requestStatus,
            ra: reqElem.ra,
            allRa: reqElem.allRA,
            submittedDate: reqElem.submittedDate,
            submittedByUserId: reqElem.submittedByUserId,
            submittedByUserName: reqElem.submittedByUserName,
            comments: reqElem.comments,
            assignedDate: reqElem.assignedDate,
            inProgressRADate: reqElem.inProgressRADate,
            inProgressRDDate: reqElem.inProgressRNDDate,
            inProgressSIRDate: reqElem.inProgressSIRDate,
            rejectedDate: reqElem.rejectedDate,
            deletionDate: reqElem.deletionDate,
            completedDate: reqElem.completedDate,
            rdComment: reqElem.comments,
            raComment: reqElem.raComment,
            topTfcId: reqElem.tfcid,
            allRAIDs: reqElem.allRAIDs,
            raUserID: reqElem.raUserIDred,
            divisionid: (reqElem.tfc.tfcLevel === 5) ? reqElem.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId : reqElem.tfc.parentTFC.parentTFC.parentTFC.tfcId
          };
        });
      }
      const ele = document.getElementById('echeck') as HTMLInputElement;
      ele.checked = false;
      this.selectedRows = [];
    }, (error) => {
      this.loading = false;
    });
  }
  getWorklistColumns() {
    const apiurl = environment.APIFAsT + '/getworklistcolumns/relatedrequest';
    this.worklistservice.getWorklistApiData(apiurl).subscribe((response) => {
      this.myWorklistTableColumns = response;
    });
  }
  sendEmail() {
    this.showEmailConfirmationDialog = true;
    let reqId = '';
    let userDetails = '';
    const uDetail = [];
    const reDet = [];
    this.selectedRows.forEach(element => {
      reDet.push(element.requestDisplayID);
    });
    const distinctReqs = reDet.filter((n, i) => reDet.indexOf(n) === i);
    reqId = distinctReqs.join(' ');
    this.selectedRows.forEach(element => {
      uDetail.push(element.ra);
    });
    const distinctNames = uDetail.filter((n, i) => uDetail.indexOf(n) === i);
    userDetails = distinctNames.join(' ');
    this.commentsToUser = 'Regulatory rule/s for the request no. ' + reqId + ' will be updated/deleted by RA user ' + userDetails + ' .To get the most updated FAsT report, please submit a new request for the formulation. Ignore if the formulation is no longer valid.';
  }
  sendEmailMessage() {
    this.showEmailConfirmationDialog = false;
    this.showRelatedRulesPopup = false;

    const payLoad = {
      NotificationGroup: 'RDCOMPLETED',
      Subject: 'FAsT | Rules updated for these requests :',
      EmailBody: null,
      ToEmailList: '',
      FromEmail: null,
      CCEmailList: null,
      EmailType: 'html',
      UPD: this.ruleIdOnEdit.toString(),
      PureCodes: null,
      UserRequests: [],
      CUCDescription: null,
      Comments: this.commentsToUser
    };

    if (this.selectedRows.length > 0) {
      this.selectedRows.forEach(row => {
        const reqIds = this.selectedRows.filter(item => item.submittedByUserId === row.submittedByUserId).map(i => i.requestDisplayID);
        payLoad.UserRequests.push({
          userId: +row.submittedByUserId,
          requestIds: reqIds
        });
      });
    }

    if (payLoad.UserRequests.length > 0) {
      this.assessReqRAService.sendRulesEmailNotification(payLoad);
    }
  }
  onGroupSelect(checkValue) {
    if (checkValue) {
      this.selectedRows = this.myworkListData;
    } else {
      this.selectedRows = [];
    }
  }
  /* istanbul ignore next */
  maxlenghtCheck(data, count, atributeName) {
    if (data !== null && data !== undefined) {
      data = data.replace(/<strong>/gi, '');
      data = data.replace(/<\/strong>/gi, '');
      data = data.replace(/<i>/gi, '');
      data = data.replace(/<\/i>/gi, '');
      const dataWithoutHtmlCount = this.handleSpecialCharacters(data).length + 1;
      const datawithhtmlCount = data.length;
      this.ruleSpecificAttributes.forEach((ruleattr) => {
        if (ruleattr.attributeName === atributeName) {
          ruleattr.ankerCount = (data.match(/<a/g) || []).length;
        }
        this.checkmax7();
      });
      const diff = datawithhtmlCount - dataWithoutHtmlCount;
      if (count <= dataWithoutHtmlCount) {
        this.ruleSpecificAttributes.forEach((ruleattr) => {
          if (ruleattr.attributeName === atributeName) {
            ruleattr.attributeValue = ruleattr.attributeValue.substring(0, count + diff);
          }
        });
        return false;
      }
    }
  }
  checkmax7() {
    for (let index = 0; index < this.ruleSpecificAttributes.length; index++) {
      if (this.ruleSpecificAttributes[index].ankerCount > 7) {
        this.ankerCount = this.ruleSpecificAttributes[index].ankerCount;
        break;
      } else {
        this.ankerCount = this.ruleSpecificAttributes[index].ankerCount;
      }
    }
  }

  changeComment(input) {
    const strValue = input.warningText;
    const ar = strValue.match(/<a.*?<\/a>/g);
    let commentwarning = '';
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentwarning = 'Click Here';
    } else {
      commentwarning = input.warningText;
    }
    return commentwarning;
  }
}
