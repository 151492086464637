import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  authorizemsg = 'You are not authorized to view the FAsT application.';
  isAuthenticated;
  authorizdemsg: string;
  UNALink: string;
  loading = true;
  constructor(private httpClient: HttpClient) {
  }

  ngOnInit() {
    this.loading = true;
    this.getisErrRedrtProd();
  }
  getisErrRedrtProd() {
    this.httpClient.get(environment.APIFAsT + '/appinfo/iserrredrtprod').subscribe(result => {
      localStorage.setItem('isErrRedrtProd', JSON.stringify(result['isErrRedrtProd']).replace('"', '').replace('"', ''));
      this.getFastUserActivateUrl();
    });
  }
  getFastUserActivateUrl() {
    this.httpClient.get(environment.APIFAsT + '/appinfo/fastuseractivateurl').subscribe(result => {
      localStorage.setItem('FastUserActivateUrl', JSON.stringify(result['FastUserActivateUrl']).replace('"', '').replace('"', ''));
      const roleData = JSON.parse(localStorage.getItem('userRolesPermissionspml'));
      const fromplm = localStorage.getItem('fromplm');
      const isaccessPlm = localStorage.getItem('isaccessPlm');
      this.isAuthenticated = localStorage.getItem('isErrRedrtProd');
      if (this.isAuthenticated !== null && this.isAuthenticated !== undefined) {
        this.isAuthenticated = this.isAuthenticated.toLocaleLowerCase();
      }
      if (fromplm === '0' && roleData.fastStatus === null && isaccessPlm === '0') {
        this.authorizdemsg = 'You are not authorized to raise request in FAsT application. Please raise request for Requester role using';
      } else if (fromplm === '1' && roleData.fastStatus.toLocaleLowerCase() === 'active' && isaccessPlm === '1') {
        this.authorizdemsg = 'You are not authorized to raise request in FAsT application. Please raise request for Requester role using';
      } else if (fromplm === '1' && (roleData.fastStatus.toLocaleLowerCase() === 'norole' || roleData.fastStatus.toLocaleLowerCase() === 'Disabled')) {
        this.authorizdemsg = 'You are not authorized to view FAsT application. Please raise request for account using';
      } else if (this.isAuthenticated === 'false' || this.isAuthenticated === 'False') {
        this.authorizdemsg = 'Please contact ARA Admin (' + localStorage.getItem('FastAdminMail') + ') if you are facing access issue.';
      } else {
        this.authorizdemsg = 'Your account is deactivated. To reactivate your account use ';
      }
      this.UNALink = localStorage.getItem('FastUserActivateUrl');
      this.loading = false;
    });
  }
}
