import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnChanges {
  @Input() isCompleted;
  @Input() maxLength = 0;
  @Input() numberOfLinks = 0;
  @Output() commentsPayload: EventEmitter<any> = new EventEmitter();

  payLoad;
  loading = true;
  public editorData = null;
  isEditableAllowed;
  ankerCount: any;
  public Editor = ClassicEditor;
  editorCommentsCount: any;
  config = {
    toolbar: [
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'link',
    ],
    image: {
      toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side']
    },
    link : {
      addTargetToExternalLinks: true,
      decorators: [
        {
          mode: '',
          label: '',
          attributes: {
            target: '_blank',
          }
        }
      ]
    }
  };
  constructor(private accessRequestRaService: AssessRequestRaService) { }

  ngOnChanges() {
    this.isEditableAllowed = this.isCompleted;
    this.payLoad = {
      RequestStatusDisplayId: recipeModel.requestDisplayID,
      ReportComments: this.editorData,
      crudType: 'G'
    };
    this.getComments(this.payLoad);
  }

  onChange(event: ChangeEvent): void {
    this.editorData = event.editor.getData();
    this.payLoad.ReportComments = this.editorData.replace(/class=\"table\"/g, '').replace(/"/g, '\'');
    this.payLoad.crudType = 'I';
    this.maxlenghtCheck(this.editorData, this.maxLength);
    this.commentsPayload.emit(this.payLoad);
  }

  getComments(payLoad) {
    this.accessRequestRaService.getAndPostComments(payLoad).subscribe((data) => {
      this.loading = false;
      this.editorData = data.result.replace(/class=\"table\"/g, '').replace(/"/g, '\'');
    }, () => {
      this.loading = false;
    });
  }
  //  /* istanbul ignore next */
   maxlenghtCheck(data, count) {
    data = data.replace(/<strong>/gi, '');
    data = data.replace(/<\/strong>/gi, '');
    data = data.replace(/<i>/gi, '');
    data = data.replace(/<\/i>/gi, '');
    this.ankerCount = (data.match(/<a/g) || []).length;
    localStorage.setItem('ankerCount', JSON.stringify ( this.ankerCount ));
    localStorage.setItem('editorCount', JSON.stringify ( 0));
    const dataWithoutHtmlCount = data.replace(/<(.|\n)*?>/g, '').length + 1;
    const datawithhtmlCount = data.length;
    const diff = datawithhtmlCount - dataWithoutHtmlCount;
    this.editorCommentsCount = dataWithoutHtmlCount;
    if (count <= dataWithoutHtmlCount) {
      localStorage.setItem('editorCount', JSON.stringify ( dataWithoutHtmlCount ));
      return false;
    } else {
      localStorage.setItem('editorCount', JSON.stringify ( dataWithoutHtmlCount ));
      return true;
    }
  }
}
