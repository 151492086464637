import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';

import { AssessRequestRaService } from '../assess-request-ra/assess-request-ra.service';
import { EXECUTE_RULE_TABLE_COLUMNS } from '../common/models/tableColumns';
import { WorklistService } from '../worklist/worklist.service';

@Component({
  selector: 'app-related-requests',
  templateUrl: './related-requests.component.html',
  styleUrls: ['./related-requests.component.scss']
})
export class RelatedRequestsComponent implements OnInit {
  overlayText = '';
  hideOverlay = true;
  marketToDD = [];
  marketList = [];
  selectedMarkets = null;
  loading = false;
  loadingMsg = '';
  ruleVersion = '';
  ruleId;
  prepareRuleTableData = [];
  prepareRuleColumns = [];
  showRelatedRules = false;
  // selectVersionForm = FormGroup;
  // @ViewChildren('selectVersionForm') selectVersionForm: NgForm;
   @ViewChild('selectVersionForm', null) selectVersionForm: FormGroup;

  allFns = [];
  allFnsDD = [];
  valConfRule = '';
  shownoresult = false;
  star = 'fa fa-fw fa-search';
  disablePureCode = false;
  filteredCombRules = [];
  idSection = true;
  dateSection = false;
  productParentProperties = [];
  productSubProperties = [];
  productParentPropertiesDD = [];
  productSubPropertiesDD = [];
  selectedSubstanceName = '';
  propertyClass = '';
  propertyName = '';
  function = '';
  rangeDates = '';
  substanceObj;
  selectedRow;
  showRelatedRequests = false;
  viewRelatedReqTableColumns = [];
  myworkListData = [];
  selectedRows: any = [];
  showEmailConfirmationDialog = false;
  propValSelected = false;
  commentsToUser = null;
  propertyClassSelected = false;
  isCommentDialogOpen = false;
  tableComment: any;
  constructor(
    private apiService: AssessRequestRaService,
    private datePipe: DatePipe,
    private worklistservice: WorklistService) { }

  ngOnInit() {
    this.prepareRuleColumns = EXECUTE_RULE_TABLE_COLUMNS;
    this.getAllFns();
    this.getMarketData();
    this.getCucProperties();
    this.getWorklistColumns();
  }
  getMarketData() {
    this.loading = true;
    this.loadingMsg = 'Fetching FAST Markets';
    const apiurl = environment.APIFAsT + '/allmarkets';
    this.apiService.getData(apiurl).subscribe((response1) => {
      this.marketList = response1;
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  getAllRules(formData) {
    this.showRelatedRequests = false;
    this.showRelatedRules = false;
    this.loadingMsg = 'Fetching data from FAsT Rule-Engine';
    this.loading = true;
    this.selectedRow = null;
    const apiurl = environment.APIFAsTRule + '/rules/queryrelatedrules';
    let body;
    if (this.idSection) {
      body = {
        marketId: this.selectedMarkets.marketId,
        marketName: this.selectedMarkets.marketName,
        RuleId: formData.form.value.ruleId,
        RuleVersion: ''
      };
    } else {
      body = {
        marketId: this.selectedMarkets.marketId,
        marketName: this.selectedMarkets.marketName,
        RuleId: '',
        RuleVersion: '',
        RuleEditStartDate: this.datePipe.transform(formData.form.value.rangeDates[0], 'yyyy-MM-dd'),
        RuleEditEndDate: this.datePipe.transform(formData.form.value.rangeDates[1], 'yyyy-MM-dd'),
        PureCode: this.substanceObj ? this.substanceObj.pureCode : '',
        Sirid: this.substanceObj ? this.substanceObj.sirId : '',
        PropertyId: formData.form.value.propertyClass ? formData.form.value.propertyName.propertyId : '',
        PropertyClass: formData.form.value.propertyClass ? formData.form.value.propertyClass.propertyClass : formData.form.value.propertyClass,
        PropertyName: formData.form.value.propertyName ? formData.form.value.propertyName.propertyName : '',
        GroupID: '',
        GroupName: formData.form.value.groupNam,
        FunctionId: formData.form.value.function ? formData.form.value.function.functionId : '',
        FunctionName: formData.form.value.function ? formData.form.value.function.functionName : ''

      };
    }
    this.apiService.postData(apiurl, body).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.showRelatedRules = true;
        if (response.length > 0) {
          this.prepareRuleTableData = this.apiService.modifyObjectForTableMapping(response);
        } else {
          this.prepareRuleTableData = response;
        }
        for (const val of this.prepareRuleTableData) {
          val.warningText = (val.warningText !== null) ? val.warningText : ((val.warningStatement !== null) ? val.warningStatement : '');
          val.warningTextStripped = this.handleSpecialCharacters(val.warningText);
          val.otherRestrictionsAndRequirements = (val.otherRestrictionsAndRequirements !== null) ? val.otherRestrictionsAndRequirements : '';
          val.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(val.otherRestrictionsAndRequirements);
        }
      }
    }, (error) => {
      this.loading = false;
    });
  }
  handleSpecialCharacters(text) {
    return text.replace(/<.*?>/g, ' ').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, '').replace(/&amp;/g, '&');
  }
  onMarketChange() {
    this.showRelatedRules = false;
    this.showRelatedRequests = false;
    this.selectVersionForm.reset();
  }
  getAllFns() {
    const functionApiUrl = environment.APIFAsT + '/functionsListForAny';
    this.apiService.getData(functionApiUrl).subscribe((response) => {
      this.allFns = response;
    });
  }
  filterSubstanceName(event) {
    if (event.target.value) {
      this.shownoresult = false;
      this.valConfRule = event.target.value;
    } else {
      this.shownoresult = false;
      this.substanceObj = null;
      this.selectedSubstanceName = null;
      this.valConfRule = null;
    }
  }
  filterSubstances() {
    this.shownoresult = false;
    if (this.valConfRule) {
      this.loading = true;
      this.loadingMsg = 'Loading Substance Details';
      this.apiService.getConflictingDataSearchResults(this.valConfRule).subscribe((response: any[]) => {
        if (response.length > 0) {
          this.loading = false;
          this.filteredCombRules = response;
          this.disablePureCode = true;
        } else {
          this.loading = false;
          this.disablePureCode = false;
          this.shownoresult = true;
          setTimeout(() => {
            this.shownoresult = false;
          }, 2500);
        }
      }, (error) => {
        this.loading = false;
      });
    }
  }
  valueSelected(val: any) {
    this.substanceObj = val;
    // 'val' param gets you the entire object from API
    const value = val.sirId + ' (' + val.substanceName + ')';
    this.disablePureCode = false;
    this.selectedSubstanceName = value;
  }

  getCucProperties() {
    /** Getting CUC Properties from Request */
    this.apiService.getCucParentProperties().subscribe((response: any[]) => {
      if (response.length !== 0 && response !== null) {
        this.productParentProperties = response;
      } else {
        this.productParentProperties = [];
      }
    }, (error) => {
    });
  }
  selectedPropertyClass(value) {
    this.selectVersionForm.controls['propertyName'].reset();
    this.propertyClassSelected = true;
    this.productSubProperties = [];
    this.propValSelected = true;
    this.loading = true;
    this.loadingMsg = 'Fetching data from FAST Rule-Engine';
    this.apiService.getCucChildProperties(value.propertyId).subscribe((response: any[]) => {
      this.loading = false;
      if (response.length !== 0 && response !== null) {
        this.productSubProperties = response;
      } else {
        this.productSubProperties = [];
      }
    }, (error) => {
      this.loading = false;
    });
  }
  clearSubProperty(value) {
    if (value) {
      this.propValSelected = false;
      this.propertyName = '';
      this.propertyClass = '';
      this.propertyClassSelected = false;
      this.selectVersionForm.controls['propertyName'].reset();
    } else {
      this.propertyName = '';
    }

  }
  searchCUCFunction(event) {
    const filteredRpc = [];
    const query = event.query;
    for (const value of this.productParentProperties) {
      if (value.propertyClass.toLowerCase().includes(query.toLowerCase())) {
        filteredRpc.push(value);
      }
    }
    this.productParentPropertiesDD = filteredRpc;
  }
  searchCUCFunctionChild(event) {
    const filteredRpc = [];
    const query = event.query;
    for (const value of this.productSubProperties) {
      if (value.propertyName.toLowerCase().includes(query.toLowerCase())) {
        filteredRpc.push(value);
      }
    }
    this.productSubPropertiesDD = filteredRpc;
  }
  searchFns(event) {
    const filteredRpc = [];
    const query = event.query;
    for (const value of this.allFns) {
      if (value.functionName.toLowerCase().includes(query.toLowerCase())) {
        filteredRpc.push(value);
      }
    }
    this.allFnsDD = filteredRpc;
  }
  searchMarkets(event) {
    const filteredRpc = [];
    const query = event.query;
    for (const value of this.marketList) {
      if (value.marketName.toLowerCase().includes(query.toLowerCase())) {
        filteredRpc.push(value);
      }
    }
    this.marketToDD = filteredRpc;
  }
  valueRadioSelected(value, event) {
    this.selectVersionForm.reset();
    this.showRelatedRules = false;
    this.selectedRow = null;
    this.showRelatedRequests = false;
    if (value === 'id') {
      this.idSection = true;
      this.dateSection = false;
    } else {
      this.dateSection = true;
      this.idSection = false;
    }
  }
  onGroupSelect(rowData) {
    this.selectedRow = rowData;
  }
  getSelectedRequests() {
    this.selectedRows = [];
    this.loadingMsg = 'Fetching data from FAsT Rule-Engine';
    this.loading = true;
    const apiurl = environment.APIFAsTRule + '/rules/queryrelatedrequests';
    const body = [];
    body.push({
      RuleId: this.selectedRow.ruleId,
      RuleVersion: this.selectedRow.ruleVersion
    });
    this.apiService.postData(apiurl, body).subscribe((response) => {
      if (response.length > 0) {
        this.loading = false;
        this.myworkListData = response.map(relReqData => {
          return {
            requestDisplayID: relReqData.requestDisplayID,
            cucCode: relReqData.cucCode,
            cucDescription: relReqData.cucDescription,
            recipeType: relReqData.recipeType,
            recipeStatus: relReqData.recipeStatus,
            marketId: relReqData.market.marketId,
            marketName: relReqData.market.marketName,
            division: (relReqData.tfc.tfcLevel === 5) ? relReqData.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName : relReqData.tfc.parentTFC.parentTFC.parentTFC.tfcName,
            rDFormulationCategory: (relReqData.tfc.tfcLevel === 5) ? relReqData.tfc.parentTFC.parentTFC.parentTFC.tfcName : relReqData.tfc.parentTFC.parentTFC.tfcName,
            formulationType: (relReqData.tfc.tfcLevel === 5) ? relReqData.tfc.parentTFC.parentTFC.tfcName : relReqData.tfc.parentTFC.tfcName,
            formulationClass: (relReqData.tfc.tfcLevel === 5) ? relReqData.tfc.parentTFC.tfcName : relReqData.tfc.tfcName,
            formulationSubClass: (relReqData.tfc.tfcLevel === 5) ? relReqData.tfc.tfcName : '',
            preparationMethod: relReqData.preparationMethod,
            intendedUse: relReqData.intendedUse,
            multicomponent: relReqData.isMultiComponent,
            requestStatus: relReqData.requestStatus,
            ra: relReqData.ra,
            allRa: relReqData.allRA,
            submittedDate: relReqData.submittedDate,
            submittedByUserId: relReqData.submittedByUserId,
            submittedByUserName: relReqData.submittedByUserName,
            comments: relReqData.comments,
            assignedDate: relReqData.assignedDate,
            inProgressRADate: relReqData.inProgressRADate,
            inProgressRDDate: relReqData.inProgressRNDDate,
            inProgressSIRDate: relReqData.inProgressSIRDate,
            rejectedDate: relReqData.rejectedDate,
            deletionDate: relReqData.deletionDate,
            completedDate: relReqData.completedDate,
            rdComment: relReqData.comments,
            raComment: relReqData.raComment,
            topTfcId: relReqData.tfcid,
            allRAIDs: relReqData.allRAIDs,
            raUserID: relReqData.raUserIDred,
            divisionid: (relReqData.tfc.tfcLevel === 5) ? relReqData.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId : relReqData.tfc.parentTFC.parentTFC.parentTFC.tfcId
          };
        });
        this.showRelatedRules = false;
        this.showRelatedRequests = true;
      } else {
        this.showRelatedRules = false;
        this.showRelatedRequests = true;
        this.loading = false;
        this.myworkListData = [];
      }
    }, (error) => {
      this.loading = false;
    });



  }
  goBack() {
    this.showRelatedRules = true;
    this.showRelatedRequests = false;
    this.selectedRow = null;
  }
  getWorklistColumns() {
    const apiurl = environment.APIFAsT + '/getworklistcolumns/relatedrequest';
    this.worklistservice.getWorklistApiData(apiurl).subscribe((response) => {
      this.viewRelatedReqTableColumns = response;
      if (this.viewRelatedReqTableColumns[0].fieldName === 'check') {
        this.viewRelatedReqTableColumns.shift();
      }
    });
  }
  selectRow(checkValue) {
    if (checkValue) {
      this.selectedRows = this.myworkListData;
    } else {
      this.selectedRows = [];
    }
  }
  sendEmail() {
    this.showEmailConfirmationDialog = false;
    const payLoad = {
      NotificationGroup: 'RDCOMPLETED',
      Subject: 'FAsT | Rules updated for these requests :',
      EmailBody: null,
      ToEmailList: '',
      FromEmail: null,
      CCEmailList: null,
      EmailType: 'html',
      UPD: this.selectedRow.ruleId.toString(),
      PureCodes: null,
      UserRequests: [],
      CUCDescription: null,
      Comments: this.commentsToUser === '' ? null : this.commentsToUser
    };

    if (this.selectedRows.length > 0) {
      this.selectedRows.forEach(row => {
        const reqIds = this.selectedRows.filter(item => item.submittedByUserId === row.submittedByUserId).map(i => i.requestDisplayID);
        payLoad.UserRequests.push({
          userId: +row.submittedByUserId,
          requestIds: reqIds
        });
      });
    }

    if (payLoad.UserRequests.length > 0) {
      this.apiService.sendRulesEmailNotification(payLoad);
    }
  }
  showCommentMsg() {
    this.commentsToUser = null;
  }

  closeAllBtn() {
    this.selectedMarkets = [];
    this.selectVersionForm.reset();
    this.prepareRuleTableData = [];
    this.showRelatedRequests = false;
    this.showEmailConfirmationDialog = false;
    this.showRelatedRules = false;
    this.ngOnInit();
    this.valueRadioSelected('id', event);
  }
  showOverlay(htmlContent) {
    this.overlayText = htmlContent;
    this.hideOverlay = false;
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
  hideOverlayPanel() {
    this.hideOverlay = true;
  }
  /* istanbul ignore next */
  changeComment(comment) {
    let commentRD = '';
    const strValue = comment;
    const ar = strValue.match(/<a.*?<\/a>/g);
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentRD = 'Click Here';
    } else {
      const hComment = this.handleSpecialCharacters(comment);
      commentRD = hComment.length > 25 ? hComment.substring(0, 25) + '...' : hComment;
    }
    return commentRD;
  }
  /* istanbul ignore next */
  openComment(data) {
    this.isCommentDialogOpen = true;
    this.tableComment = data.toString().replac(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  /* istanbul ignore next */
  closePopup() {
    this.isCommentDialogOpen = false;
  }
}
