import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as $ from 'jquery';
import 'jstree';
import * as _ from 'lodash';
import { prdtRuleDetailsModel } from 'src/app/common/models/ProductRuleDetailsModel';
import { parallelRuleObj, ruleFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { PRODUCT_RULE_TABLE_COLUMNS, PRODUCT_RULE_TABLE_COLUMNS_EXCEL } from 'src/app/common/models/tableColumns';
import { DataService } from 'src/app/common/services/data.service';
import { environment } from 'src/environments/environment';
import { AssessRequestRaService } from '../../assess-request-ra.service';

@Component({
  selector: 'app-product-based',
  templateUrl: './product-based.component.html',
  styleUrls: ['./product-based.component.scss']
})
export class ProductBasedComponent implements OnInit {
  @ViewChild('onRuleHoverDiv') onRuleHoverDiv;
  @Output() sendRulesEvent = new EventEmitter<any>();
  @Input() isFileNameAmendDate = 'No';
  overlayText = '';
  hideOverlay = true; cascadeRPC = true; loading = false;
  unileverMarketObj = {
    clusterId: null,
    clusterName: null,
    marketFullText: null,
    marketId: '',
    marketName: 'Unilever',
    parentMarket: ''
  };
  rpcData; rpcFullData; displayedTFCPath; selectedRuleBucket; selectedMarket; selectedPureSirDetail; displayRuleType;
  selectedRpcList = []; marketsToSelect = []; selectedRuleDetails = []; ruleColumns = []; ruleColumnsExcel = []; prdtRuleDisplayData = []; rpc = []; sirIdList = [];
  step = 0;
  deletePopup = false; parallelRuleWorkflow = false;
  dataSelectedDelete; componentGlobal;
  parentRpcParallelRule;
  rpcToSelect = []; parallelRuleRPCs = [];
  selectedRPC = '';
  editRuleFlow = false;
  viewRuleFlow = false;
  rpcsForEditRule = [];
  rpcsForEditRulestatus = [];
  deletePropRuleComment: string;
  warningTextOpen: boolean;
  tablewarningComment: any;
  commentRD = '';
  orrTextOpen: boolean;
  tableORRComment: any;
  isColumnsHasHyperLinks: 'Yes';
  constructor(
    private assessRequestRa: AssessRequestRaService,
    public matSnackBar: MatSnackBar,
    public dataServiceObj: DataService) { }
  /* istanbul ignore next */
  ngOnInit() {
    this.editRuleFlow = false;
    this.viewRuleFlow = false;
    prdtRuleDetailsModel.CascadeRpc = 'Y';
    this.isColumnsHasHyperLinks = 'Yes';
    this.ruleColumns = PRODUCT_RULE_TABLE_COLUMNS;
    this.ruleColumnsExcel = PRODUCT_RULE_TABLE_COLUMNS_EXCEL;
    this.selectedRuleBucket = localStorage.getItem('selectedRuleBucket');
    this.dataServiceObj.getComponentNameSvc.subscribe((val) => {
      this.componentGlobal = val;
    });
    this.dataServiceObj.getruleToEdit().subscribe((value) => {
      if (value !== null) {
        this.step = 2;
        this.editRuleFlow = true;
        this.selectedMarket = value.markets[0].marketName;
        recipeModel.marketDetails = value.markets[0];
        if (value.markets) {
          this.selectedMarket = value.markets[0].marketName;
          recipeModel.marketDetails = value.markets[0];
        }
        this.parentRpcParallelRule = value.rpcid;
        for (const val of value.rpcs) {
          this.rpcsForEditRule.push(val.rpcFullText);
          this.rpcsForEditRulestatus.push(val.mrpcStatus);
        }
      } else {
        this.step = 0;
        this.editRuleFlow = false;
      }
    });
    this.dataServiceObj.getParallelRuleFormValues().subscribe((value) => {
      if (value !== null && this.componentGlobal === 'CreateInsertRule') {
        this.parallelRuleWorkflow = true;
        this.selectedMarket = value.selectedMarket.marketName;
        recipeModel.marketDetails = value.selectedMarket;
        prdtRuleDetailsModel.Market = value.selectedMarket;
      } else {
        this.parallelRuleWorkflow = false;
        this.selectedMarket = recipeModel.marketDetails.marketName;
        prdtRuleDetailsModel.Market = recipeModel.marketDetails;
      }
    });
    if (prdtRuleDetailsModel.Market === undefined) {
      prdtRuleDetailsModel.Market = recipeModel.marketDetails;
    }
    this.displayedTFCPath = recipeModel.tfcFullText;
    if (this.parallelRuleWorkflow) {
      this.marketsToSelect.push(recipeModel.marketDetails);
      recipeModel.intendedUse = parallelRuleObj.intendedUseForParallelRule;
      recipeModel.preparationMethod = parallelRuleObj.prepMethodForParallelRule.value;
      this.getRPCForParallelRule();
    } else if (this.editRuleFlow || this.viewRuleFlow) {
      this.marketsToSelect.push(recipeModel.marketDetails);
    } else {
      this.getFallbackMarkets();
    }
    this.dataServiceObj.setRulesListForProduct([]);
    this.dataServiceObj.getRulesListForPdt.subscribe((resultSet) => { //  on adding rules update the rule display table
      if (resultSet.length !== 0) {
        this.prdtRuleDisplayData = resultSet;
        for (const val of this.prdtRuleDisplayData) {
          val.warningText = (val.warningText !== null) ? val.warningText : ((val.warningStatement !== null) ? val.warningStatement : '');
          val.warningTextStripped = this.handleSpecialCharacters((val.warningText !== null && val.warningText !== 'Click Here') ? val.warningText : ((val.warningStatement !== null) ? val.warningStatement : ''));
          val.originalWarningText = val.warningText;
          const warningLinkRuleData = this.assessRequestRa.hyperLinksToSheet((val.warningText !== null && val.warningText !== 'Click Here') ? val.warningText : ((val.warningStatement !== null) ? val.warningStatement : ''));
          val.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
          val.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
          val.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
          val.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
          val.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
          val.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
          val.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
          val.warningTextClickHere = this.changeComment(val.warningText);
          val.otherRestrictionsAndRequirements = (val.otherRestrictionsAndRequirements !== null) ? val.otherRestrictionsAndRequirements : '';
        //  val.originalotherRestrictionsAndRequirements = val.otherRestrictionsAndRequirements;
          const orrLinkRuleData = this.assessRequestRa.hyperLinksToSheet(val.otherRestrictionsAndRequirements);
          val.ORR1 = orrLinkRuleData[0] !== undefined ? orrLinkRuleData[0] : '';
          val.ORR2 = orrLinkRuleData[1] !== undefined ? orrLinkRuleData[1] : '';
          val.ORR3 = orrLinkRuleData[2] !== undefined ? orrLinkRuleData[2] : '';
          val.ORR4 = orrLinkRuleData[3] !== undefined ? orrLinkRuleData[3] : '';
          val.ORR5 = orrLinkRuleData[4] !== undefined ? orrLinkRuleData[4] : '';
          val.ORR6 = orrLinkRuleData[5] !== undefined ? orrLinkRuleData[5] : '';
          val.ORR7 = orrLinkRuleData[6] !== undefined ? orrLinkRuleData[6] : '';
          val.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(val.originalotherRestrictionsAndRequirements);
          val.otherRestrictionsAndRequirementsClickHere = this.changeComment(val.otherRestrictionsAndRequirements);
        }
        prdtRuleDetailsModel.rulesListForProduct = resultSet;
      }
    });
    this.dataServiceObj.expandedPanel.subscribe((result) => { // setting the expanded panel
      if (result !== '') {
        const step = result;
        this.setStep(Number(step));
      }
    });
    if (ruleFlags.viewRule) {
      this.editRuleFlow = false;
      this.viewRuleFlow = true;
    } else if (ruleFlags.editRule) {
      this.editRuleFlow = true;
      this.viewRuleFlow = false;
    }

    if (!this.editRuleFlow) {
      if (this.ruleColumns[this.ruleColumns.length - 1].field === 'delete') {
        this.ruleColumns.pop();
      }
    } else {
      if (this.ruleColumns[this.ruleColumns.length - 1].field === 'yearAdopted') {
        const obj = { field: 'delete', header: '', width: '40px' };
        this.ruleColumns.push(obj);
      }
    }
  }

  handleSpecialCharacters(text) {
    return text.replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, '').replace(/&amp;/g, '&');
  }
  /* istanbul ignore next */
  onMarketChange(filterVal: any) {
    $('.tree-div').jstree('destroy').empty();
    this.getRPCDataBasedOnMarket(filterVal.value.marketName);
    this.selectedRPC = '';
    prdtRuleDetailsModel.selectedRpcsPdtBased = [];
    prdtRuleDetailsModel.Market = this.marketsToSelect.filter(x => x.marketName === filterVal.value.marketName)[0];
    prdtRuleDetailsModel.RpcDetails = this.rpc;
  }
  setStep(index: number) {
    this.step = index;
    if (this.step === 1) {
      if (this.checkValidationPrdtBased(0) === true) {
        this.getRuleDetailsFromDb();
      }
    }
  }
  /* istanbul ignore next */
  getRPCForParallelRule() {
    prdtRuleDetailsModel.selectedRpcsPdtBased = [];
    prdtRuleDetailsModel.selectedParentRpcsPdtBased = [];
    this.createRpcTreeForParallelRule(parallelRuleObj.rpcsForParallelRuleData);
    const length = parallelRuleObj.rpcsForParallelRuleData.length;
    prdtRuleDetailsModel.selectedRpcsPdtBased.push(parallelRuleObj.rpcsForParallelRuleData[length - 1]);
    prdtRuleDetailsModel.selectedParentRpcsPdtBased.push(parallelRuleObj.rpcsForParallelRuleData[length - 1]);
  }
  /* istanbul ignore next */
  getSelectedParentRpcs() {
    let filteredData = []; let tempValue; const TopParentRpc = [];
    prdtRuleDetailsModel.selectedRpcsPdtBased.forEach((value) => {
      tempValue = value;
      while (true) {
        filteredData = _.filter(prdtRuleDetailsModel.selectedRpcsPdtBased, (rpcVal) => {
          return rpcVal.id === tempValue.parent;
        });
        if (filteredData && filteredData.length > 0) {
          tempValue = filteredData[0];
        } else {
          const index = _.findIndex(TopParentRpc, (val) => {
            return val.id === tempValue.id;
          });
          if (index === -1) {
            TopParentRpc.push(tempValue);
          }
          break;
        }
      }
    });
    prdtRuleDetailsModel.selectedParentRpcsPdtBased = TopParentRpc;
  }
  nextStep() {
    let varrpcitem = 0;
    varrpcitem = prdtRuleDetailsModel.selectedRpcsPdtBased.length;
    if (varrpcitem > 1) {
      prdtRuleDetailsModel.selectedParentRpcsPdtBased = [];
      this.loading = false;
      this.matSnackBar.open('Invalid Input Error: More than one child RPC level under same parent is selected. Please select one child RPC level to save rule applicability.', 'close', {
        duration: 3000,
        panelClass: ['error']
      });
    } else {
      if (this.step === 0) {
        if (this.checkValidationPrdtBased(0) === true) {
          this.getRuleDetailsFromDb();
        }
      }
      this.step++;
      prdtRuleDetailsModel.RpcDetails = this.selectedRpcList;
    }
  }
  selectedProductRuleType(value) {
    this.selectedRuleDetails = value;
    this.displayRuleType = value.ruleTypeDisplayName;
    this.dataServiceObj.setSelectedProductRuleType(this.selectedRuleDetails);
    this.step = 2;
    this.setStep(this.step);
  }
  /* istanbul ignore next */
  createRpcTree(rpcdata) {
    const component = this;
    // tslint:disable-next-line: no-shadowed-variable
    $('.tree-div').on('changed.jstree', (e, rpcdata) => {
      // tslint:disable-next-line: no-var-keyword
      var i; var j; const r = []; let selectedRpcObject; this.selectedRpcList = [];
      for (i = 0, j = rpcdata.selected.length; i < j; i++) {
        r.push(rpcdata.instance.get_node(rpcdata.selected[i]).text);
        selectedRpcObject = rpcdata.instance.get_node(rpcdata.selected[i]).original;
        const index = _.findIndex(this.selectedRpcList, (value) => value.id === selectedRpcObject.id);
        if (index === -1) {
          this.selectedRpcList.push(selectedRpcObject);
        }
      }
      prdtRuleDetailsModel.selectedRpcsPdtBased = this.selectedRpcList;
      this.dataServiceObj.setSelectedRpcValues(prdtRuleDetailsModel.selectedRpcsPdtBased);
      this.getSelectedParentRpcs();
    }).jstree({
      plugins: ['changed', 'checkbox'],
      checkbox: {
        three_state: false,
        cascade: 'indeterminate' // up, down, indeterminate values
      },
      core: {
        check_callback: true,
        data: rpcdata,
        themes: {
          icons: false
        }
      },
      types: {
        default: {
          icon: 'src/assets/images/tree.svg'
        }
      },
      icon: 'glyphicon glyphicon-file file-node',

      // tslint:disable-next-line: space-before-function-paren // tslint:disable-next-line: no-shadowed-variable
    }).bind('loaded.jstree', function (event, rpcdata) {
      $(this).jstree('open_all');
    });
    $('.tree-div').jstree(true).redraw(true);
  }
  /* istanbul ignore next */
  createRpcTreeForParallelRule(rpcData) {
    $('.tree-div').on('changed.jstree', (evt, data) => {
      $('.tree-div').jstree(true).set_icon(data.node, 'https://jstree.com/tree-icon.png');
    });

    $('.tree-div').on('changed.jstree', (e, unileverRpcDataChanged) => {
    }).jstree({
      plugins: ['changed'],
      checkbox: {
        three_state: false

      },
      core: {
        check_callback: true,
        data: rpcData,
        themes: {
          icons: false
        }
      },
      types: {
        default: {
          icon: 'src/assets/images/tree.svg'
        }
      },
      icon: 'glyphicon glyphicon-file file-node',
      // tslint:disable-next-line: space-before-function-paren // tslint:disable-next-line: no-shadowed-variable
    }).bind('loaded.jstree', function (event, unileverRpcData123) {
      $(this).jstree('open_all');
      $('#' + rpcData[rpcData.length - 1].id + ' >a').css('color', '#1F36C7');
    });
    $('.tree-div').jstree(true).redraw(true);

  }
  onCascadeClick() {
    const cascadeRPCFlag = this.cascadeRPC ? 'Y' : 'N';
    prdtRuleDetailsModel.CascadeRpc = cascadeRPCFlag ? cascadeRPCFlag : null;
  }

  getRuleDetailsFromDb() {
    this.rpc = [];
    if (prdtRuleDetailsModel.selectedRpcsPdtBased !== undefined && prdtRuleDetailsModel.selectedRpcsPdtBased.length !== 0) {
      if (prdtRuleDetailsModel.selectedRpcsPdtBased[0].text !== 'No Regulatory Product Class is Mapped') {
        prdtRuleDetailsModel.selectedRpcsPdtBased.forEach((rpcValue) => {
          this.rpc.push({
            rpcId: rpcValue.id,
            rpcName: rpcValue.text,
            rpcFullText: rpcValue.rpcFullText,
          });
        });
      }
    }
    const fetchRuleBody = {
      MarketList: [prdtRuleDetailsModel.Market],
      RPCList: this.rpc,
      ruleClassifications: ['Property Based Rules', 'Product Based Rules']
    };
    const getruleapiurl = environment.APIFAsTRule + '/rules/rulesFilter';
    this.assessRequestRa.postData(getruleapiurl, fetchRuleBody).subscribe((response) => {
      if (response !== null || response !== []) {
        this.prdtRuleDisplayData = response;
        this.prdtRuleDisplayData = _.filter(this.prdtRuleDisplayData, (val) =>
          val.ruleClassification === 'Product Based Rules' || val.ruleClassification === 'Property Based Rules');
        for (const val of this.prdtRuleDisplayData) {
          val.ruleVerionToDisplay = 'V' + val.ruleVersion;
          if (val.ruleTypeID === 7 || val.ruleTypeID === 8 || val.ruleTypeID === 9 || val.ruleTypeID === 49) {
            val.propertySource = 'CUC';
          }
          val.warningText = (val.warningText !== null) ? val.warningText : ((val.warningStatement !== null) ? val.warningStatement : '');
          val.warningTextStripped = this.handleSpecialCharacters(val.warningText);
          const warningLinkRuleData =  this.assessRequestRa.hyperLinksToSheet(val.warningText);
          val.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
          val.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
          val.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
          val.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
          val.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
          val.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
          val.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
          val.originalWarningText = val.warningText;
          val.warningTextClickHere = this.changeComment(val.warningText);
          val.otherRestrictionsAndRequirements = (val.otherRestrictionsAndRequirements !== null) ? val.otherRestrictionsAndRequirements : '';
          const orrLinkRuleData = this.assessRequestRa.hyperLinksToSheet(val.otherRestrictionsAndRequirements);
          val.ORR1 = orrLinkRuleData[0] !== undefined ? orrLinkRuleData[0] : '';
          val.ORR2 = orrLinkRuleData[1] !== undefined ? orrLinkRuleData[1] : '';
          val.ORR3 = orrLinkRuleData[2] !== undefined ? orrLinkRuleData[2] : '';
          val.ORR4 = orrLinkRuleData[3] !== undefined ? orrLinkRuleData[3] : '';
          val.ORR5 = orrLinkRuleData[4] !== undefined ? orrLinkRuleData[4] : '';
          val.ORR6 = orrLinkRuleData[5] !== undefined ? orrLinkRuleData[5] : '';
          val.ORR7 = orrLinkRuleData[6] !== undefined ? orrLinkRuleData[6] : '';
          val.originalotherRestrictionsAndRequirements = val.otherRestrictionsAndRequirements;
          val.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(val.otherRestrictionsAndRequirements);
          val.otherRestrictionsAndRequirementsClickHere = this.changeComment(val.otherRestrictionsAndRequirements);
          if (val.cfDetails !== null && val.cfDetails !== undefined) {
            const cfValue = val.cfDetails;
            val.cf = cfValue.cf;
            val.cfSubstanceExpressedAs = cfValue.component;
          }
        }
        this.dataServiceObj.setRulesListForProduct(this.prdtRuleDisplayData);
        this.sendRulesEvent.emit(this.prdtRuleDisplayData);
      }
    }, (error) => {
      // this.loading = false;
    });
  }
  getFallbackMarkets() {
    const apiurl = environment.APIFAsT + '/markets/' + recipeModel.marketDetails.marketName + '/assessmentOrder';
    this.assessRequestRa.getData(apiurl).subscribe((data) => {
      this.loading = false;
      if (data !== null) {
        const fallBackMarkets = data;
        for (const value of fallBackMarkets) {
          this.marketsToSelect.push(value);
        }
      } else {
        this.marketsToSelect.push(recipeModel.marketDetails);
      }
      this.getRPCDataBasedOnMarket(this.selectedMarket);
    }, (error) => {
      this.loading = false;
    });
  }
  checkValidationPrdtBased(step) {
    if (step === 0) {
      if ((prdtRuleDetailsModel.Market !== undefined) && (prdtRuleDetailsModel.selectedRpcsPdtBased !== undefined && prdtRuleDetailsModel.selectedRpcsPdtBased.length !== 0)) {
        if (prdtRuleDetailsModel.selectedRpcsPdtBased[0].text !== 'No Regulatory Product Class is Mapped') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    if (step === 1) {
      if ((prdtRuleDetailsModel.applicableRuleTypeList !== undefined && prdtRuleDetailsModel.applicableRuleTypeList.length !== 0) && (this.checkValidationPrdtBased(0) === true)) {
        return true;
      } else {
        return false;
      }
    }
  }
  deleteRule(val) {
    this.deletePopup = true;
    this.dataSelectedDelete = val;
  }
  confirmDelete() {
    const urlForDelete = environment.APIFAsTRule + '/DeleteRules';
    const data = [
      {
        RuleTypeID: this.dataSelectedDelete.ruleTypeID,
        RuleType: this.dataSelectedDelete.ruleType,
        RuleId: this.dataSelectedDelete.ruleId,
        RuleVersion: this.dataSelectedDelete.ruleVersion,
        Comments: this.deletePropRuleComment
      }];
    const fetchRuleBody = {
      MarketList: [prdtRuleDetailsModel.Market],
      RPCList: this.rpc,
      ruleClassifications: ['Property Based Rules', 'Product Based Rules']
    };
    this.loading = true;
    this.assessRequestRa.putData(urlForDelete, data).subscribe((responseData) => {
      if (responseData !== null) {
        this.deletePropRuleComment = null;
        this.matSnackBar.open('Rule is deleted successfully ', 'close', {
          duration: 3000,
          panelClass: ['success']
        });
        const getruleapiurl = environment.APIFAsTRule + '/rules/rulesFilter';
        this.assessRequestRa.postData(getruleapiurl, fetchRuleBody).subscribe((response) => {
          if (response.length > 0) {
            this.prdtRuleDisplayData = response;
            this.prdtRuleDisplayData = _.filter(this.prdtRuleDisplayData, (val) =>
              val.ruleClassification === 'Product Based Rules' || val.ruleClassification === 'Property Based Rules');
            for (const val of this.prdtRuleDisplayData) {
              val.ruleVerionToDisplay = 'V' + val.ruleVersion;
              if (val.ruleTypeID === 7 || val.ruleTypeID === 8 || val.ruleTypeID === 9 || val.ruleTypeID === 49) {
                val.propertySource = 'CUC';
              }
              val.warningText = (val.warningText !== null ? val.warningText : ((val.warningStatement !== null ? val.warningStatement : '')));
              val.warningTextStripped = this.handleSpecialCharacters((val.warningText !== null) ? val.warningText : ((val.warningStatement !== null) ? val.warningStatement : ''));
              const warningLinkRuleData = this.assessRequestRa.hyperLinksToSheet(val.warningText);
              val.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
              val.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
              val.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
              val.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
              val.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
              val.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
              val.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
              val.originalWarningText = val.warningText;
              val.warningTextClickHere = this.changeComment(val.warningText);
              val.otherRestrictionsAndRequirements = (val.otherRestrictionsAndRequirements !== null) ? val.otherRestrictionsAndRequirements : '';
              const orrLinkRuleData = this.assessRequestRa.hyperLinksToSheet(val.otherRestrictionsAndRequirements);
              val.ORR1 = orrLinkRuleData[0] !== undefined ? orrLinkRuleData[0] : '';
              val.ORR2 = orrLinkRuleData[1] !== undefined ? orrLinkRuleData[1] : '';
              val.ORR3 = orrLinkRuleData[2] !== undefined ? orrLinkRuleData[2] : '';
              val.ORR4 = orrLinkRuleData[3] !== undefined ? orrLinkRuleData[3] : '';
              val.ORR5 = orrLinkRuleData[4] !== undefined ? orrLinkRuleData[4] : '';
              val.ORR6 = orrLinkRuleData[5] !== undefined ? orrLinkRuleData[5] : '';
              val.ORR7 = orrLinkRuleData[6] !== undefined ? orrLinkRuleData[6] : '';
              val.originalotherRestrictionsAndRequirements = val.otherRestrictionsAndRequirements;
              val.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(val.originalotherRestrictionsAndRequirements);
              val.otherRestrictionsAndRequirementsClickHere = this.changeComment(val.otherRestrictionsAndRequirements);
              if (val.cfDetails !== null && val.cfDetails !== undefined) {
                const cfValue = val.cfDetails;
                val.cf = cfValue.cfDisplayText;
                val.cfSubstanceExpressedAs = cfValue.expressedAs;
              }
            }
            this.dataServiceObj.setRulesListForProduct(this.prdtRuleDisplayData);
            prdtRuleDetailsModel.rulesListForProduct = this.prdtRuleDisplayData;
            this.sendRulesEvent.emit(this.prdtRuleDisplayData);
            this.loading = false;
          } else {
            this.prdtRuleDisplayData = response;
            for (const val of this.prdtRuleDisplayData) {
              val.warningText = (val.warningText !== null) ? val.warningText : ((val.warningStatement !== null) ? val.warningStatement : '');
              val.warningTextStripped = this.handleSpecialCharacters((val.warningText !== null) ? val.warningText : ((val.warningStatement !== null) ? val.warningStatement : ''));
              const warningLinkRuleData = this.assessRequestRa.hyperLinksToSheet(val.warningText);
              val.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
              val.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
              val.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
              val.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
              val.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
              val.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
              val.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
              val.originalWarningText = val.warningText;
              val.warningTextClickHere = this.changeComment(val.warningText);
              val.otherRestrictionsAndRequirements = (val.otherRestrictionsAndRequirements !== null) ? val.otherRestrictionsAndRequirements : '';
              const orrLinkRuleData = this.assessRequestRa.hyperLinksToSheet(val.otherRestrictionsAndRequirements);
              val.ORR1 = orrLinkRuleData[0] !== undefined ? orrLinkRuleData[0] : '';
              val.ORR2 = orrLinkRuleData[1] !== undefined ? orrLinkRuleData[1] : '';
              val.ORR3 = orrLinkRuleData[2] !== undefined ? orrLinkRuleData[2] : '';
              val.ORR4 = orrLinkRuleData[3] !== undefined ? orrLinkRuleData[3] : '';
              val.ORR5 = orrLinkRuleData[4] !== undefined ? orrLinkRuleData[4] : '';
              val.ORR6 = orrLinkRuleData[5] !== undefined ? orrLinkRuleData[5] : '';
              val.ORR7 = orrLinkRuleData[6] !== undefined ? orrLinkRuleData[6] : '';
              val.originalotherRestrictionsAndRequirements = val.otherRestrictionsAndRequirements;
              val.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(val.originalotherRestrictionsAndRequirements);
              val.otherRestrictionsAndRequirementsClickHere = this.changeComment(val.otherRestrictionsAndRequirements);
            }
            this.dataServiceObj.setRulesListForProduct(response);
            this.sendRulesEvent.emit(response);
            prdtRuleDetailsModel.rulesListForProduct = response;
            this.loading = false;
          }
        }, (error) => {
          this.loading = false;
        });
      }
    }, (error) => {
      this.matSnackBar.open('Rule deletion Failed', 'close', {
        duration: 3000,
        panelClass: ['error']
      });
      this.loading = false;
    });

  }
  /* istanbul ignore next */
  getRPCDataBasedOnMarket(marketSelected) {
    prdtRuleDetailsModel.CascadeRpc = 'Y';
    this.selectedRPC = '';
    this.rpcData = [];
    this.loading = true;
    const result = this.marketsToSelect.filter(x => x.marketName === marketSelected)[0];
    const url = environment.APIFAsT + '/marketid/' + result.marketId + '/tfcid/' + recipeModel.tfc.tfcId + '/rpclist';
    this.assessRequestRa.getData(url).subscribe((response) => {
      this.loading = false;
      if (response.length > 0) {
        this.rpcToSelect = response;
        this.onRPCChangedLoad(response[0]);
      } else {
        this.rpcToSelect = [];
        this.selectedRPC = '';
        prdtRuleDetailsModel.selectedRpcsPdtBased = [];
        this.selectedRpcList = [];
        this.getTreeDataBasedOnMarket(response);
        this.matSnackBar.open('No RPCs are found for market ' + marketSelected, 'close', {
          duration: 3000,
          panelClass: ['success']
        });
      }
    }, (error) => {
      this.loading = false;
    });
  }
  /* istanbul ignore next */
  onRPCChanged(selectedRPC) {
    this.getTreeDataBasedOnMarket(selectedRPC.value);
  }
  /* istanbul ignore next */
  onRPCChangedLoad(selectedRPCOnLoad) {
    this.getTreeDataBasedOnMarket(selectedRPCOnLoad);
  }
  /* istanbul ignore next */
  getTreeDataBasedOnMarket(rpcVal) {
    this.loading = true;
    this.selectedRpcList = [];
    prdtRuleDetailsModel.selectedRpcsPdtBased = [];
    $('.tree-div').jstree('destroy').empty();
    const encodedTfcName = encodeURIComponent(encodeURIComponent(encodeURIComponent(recipeModel.tfc.tfcFullText)));
    let url;
    if (rpcVal.rpcId !== undefined) {
      url = environment.APIFAsT + '/markets/' + prdtRuleDetailsModel.Market.marketName + '/tfcname/' + encodedTfcName + '/rpcs/fortree/rpc/' + rpcVal.rpcId;
    } else {
      url = environment.APIFAsT + '/markets/' + prdtRuleDetailsModel.Market.marketName + '/tfcname/' + encodedTfcName + '/rpcs/fortree/rpc/0';
    }
    this.assessRequestRa.getData(url).subscribe((data) => {
      this.loading = false;
      this.rpcData = data;
      this.dataServiceObj.setSelectedRpcValues(this.rpcData);
      if (this.rpcData.length > 0) {
        for (const val of this.rpcData) {
          if (val.text === 'No Regulatory Product Class is Mapped') {
            this.createRpcTree(this.rpcData);
            prdtRuleDetailsModel.selectedRpcsPdtBased = [];
            this.selectedRpcList = [];
          }
        }
        if (rpcVal.rpcId !== undefined) {
          const urlData = environment.APIFAsT + '/markets/' + prdtRuleDetailsModel.Market.marketName + '/tfcname/' + encodedTfcName + '/rpcs/formodel/rpc/' + rpcVal.rpcId;
          this.assessRequestRa.getData(urlData).subscribe((response) => {
            if (response !== null) {
              prdtRuleDetailsModel.RpcFullDetails = [];
              this.rpcFullData = response;
              prdtRuleDetailsModel.RpcFullDetails = this.rpcFullData;
              if (this.rpcFullData.length === 0) {
                prdtRuleDetailsModel.RpcFullDetails = [];
              }
            } else {
              this.rpcFullData = [];
            }
            prdtRuleDetailsModel.RpcFullDetails.forEach((rpcDetailedValue) => {
              const index = _.findIndex(this.rpcData, (value) => Number(value.id) === rpcDetailedValue.parentRpcId);
              if (index !== -1) {
                this.rpcData[index].rpcFullText = rpcDetailedValue.parentRpcSearchValue;
              }
            });
            this.getSelectedParentRpcs();
            this.createRpcTree(this.rpcData);
            this.loading = false;
          });
        }
      } else {
        prdtRuleDetailsModel.selectedRpcsPdtBased = [];
        this.selectedRpcList = [];
      }
    }, (error) => {
      this.loading = false;
    });
  }
  showOverlay(htmlContent) {
    this.overlayText = htmlContent;
    this.hideOverlay = false;
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
  hideOverlayPanel() {
    this.hideOverlay = true;
  }
  /* istanbul ignore next */
  changeComment(input) {
    const strValue = input;
    const ar = strValue.match(/<a.*?<\/a>/g);
    let commentwarning = '';
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentwarning = 'Click Here';
    } else {
      commentwarning = input;
    }
    return commentwarning;
  }
  /* istanbul ignore next */
  openComment(data) {
    this.warningTextOpen = true;
    const mydata = data.warningStatement !== '' ? data.warningStatement : data.warningText;
    this.tablewarningComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  closePopup() {
    this.warningTextOpen = false;
  }
  /* istanbul ignore next */
  openCommentORR(data) {
    this.orrTextOpen = true;
    const mydata = data.originalotherRestrictionsAndRequirements;
    this.tableORRComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }

  closePopupORR() {
    this.orrTextOpen = false;
  }
}
