import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AssessRequestsComponent } from '../assess-requests/assess-requests.component';
import { SharedModule } from '../common/shared.module';
import { AssessRequestsRoutingModule } from './assess-requests-routing.module';
import { SelectRecipeComponent } from './select-recipe/select-recipe.component';
import { CreateRequestComponent } from './create-request/create-request.component';
import { SubmitRequestComponent } from './submit-request/submit-request.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SelectRecipePlmComponent } from './select-recipe-plm/select-recipe-plm.component';

@NgModule({
  imports: [
    CommonModule,
    AssessRequestsRoutingModule,
    SharedModule,
    CKEditorModule,
  ],
  declarations: [
    AssessRequestsComponent,
    SelectRecipeComponent,
    CreateRequestComponent,
    SubmitRequestComponent,
    SelectRecipePlmComponent,
  ],
})
export class AssessRequestsModule { }
