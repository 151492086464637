import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import { AuthorizationDataService } from './authorization-data.service';
// import { AuthPermission } from './authorization.types';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[disableIfUnauthorizedByPermissionId]'
})
export class DisableIfUnauthorizedByPermissionIdDirective implements OnInit {
    @Input('disableIfUnauthorizedByPermissionId') permissionId: string; // Required permissions passed in
    // Use like below:
    // <div [disableIfUnauthorized]="'createRule,viewRule'"> <!-- a property set or passed into the component -->

    constructor(private el: ElementRef, private authorizationService: AuthorizationService, private auth: AuthorizationDataService) { }

    ngOnInit() {
        this.auth.getRolesDataByPermissionId().subscribe((val) => {
            if (val) {
                if (!this.authorizationService.hasPermissionLoadByPermissionId(this.permissionId, val)) {
                    this.el.nativeElement.disabled = true;
                    this.el.nativeElement.style.pointerEvents = 'none';
                    this.el.nativeElement.style.textDecoration = 'none';
                }
            }
        });
    }
}
