import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationDataService {
  private rolesData$: BehaviorSubject<any> = new BehaviorSubject(null);
  private rolesDataByPermissionsId$: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(private httpClient: HttpClient) { }
  /* istanbul ignore next */
  getPermissions() {
    return this.httpClient.get(environment.APIFAsT + '/permissions');
  }
  /* istanbul ignore next */
  getValueFromAPIs() {
    this.httpClient.get(environment.APIFAsT + '/appinfo/asisingredientcomponentname').subscribe(result => {
      localStorage.setItem('AsIsIngredientComponentname', JSON.stringify(result['AsIsIngredientComponentname']));
    });
  }
  /* istanbul ignore next */
  getFastRequestAPIs() {
    const data = {
      ActivityID: 205,
      ActivitySummary: 'Create Request Page Visited'
    };
    this.httpClient.post(environment.APIFAsT + '/saveactivitylog', data, { responseType: 'text' }).subscribe(result => {
    });
  }
  /* istanbul ignore next */
  setRolesData(val: any) {
    this.rolesData$.next(val);
  }
  /* istanbul ignore next */
  getRolesData(): Observable<any> {
    return this.rolesData$.asObservable();
  }
  /* istanbul ignore next */
  setRolesDataByPermissionId(val: any) {
    this.rolesDataByPermissionsId$.next(val);
  }
  /* istanbul ignore next */
  getRolesDataByPermissionId() {
    return this.rolesDataByPermissionsId$.asObservable();
  }
  /* istanbul ignore next */
  getFeatureFragranceFlag() {
    this.httpClient.get(environment.APIFAsT + '/appinfo/featureflagfragrance').subscribe(result => {
      localStorage.setItem('FeatureFlagFragrance', JSON.stringify(result['FeatureFlagFragrance']).toLocaleLowerCase().replace('"', '').replace('"', ''));
    });
  }
  /* istanbul ignore next */
  getFragranceMessage() {
    this.httpClient.get(environment.APIFAsT + '/appinfo/fragrancemessage').subscribe(result => {
      localStorage.setItem('FragranceMessage', JSON.stringify(result['FragranceMessage']).replace('"', '').replace('"', ''));
    });
  }
  /* istanbul ignore next */
  getFastUserActivateUrl() {
    this.httpClient.get(environment.APIFAsT + '/appinfo/fastuseractivateurl').subscribe(result => {
      localStorage.setItem('FastUserActivateUrl', JSON.stringify(result['FastUserActivateUrl']).replace('"', '').replace('"', ''));
    });
  }
  /* istanbul ignore next */
  getFastAdminMail() {
    this.httpClient.get(environment.APIFAsT + '/appinfo/fastadminmail').subscribe(result => {
      localStorage.setItem('FastAdminMail', JSON.stringify(result['FastAdminMail']).replace('"', '').replace('"', ''));
    });
  }
  /* istanbul ignore next */
  getisErrRedrtProd() {
    this.httpClient.get(environment.APIFAsT + '/appinfo/iserrredrtprod').subscribe(result => {
      localStorage.setItem('isErrRedrtProd', JSON.stringify(result['isErrRedrtProd']).replace('"', '').replace('"', ''));
    });
  }
}
