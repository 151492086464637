import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { APPLICATIONCONTENT, ACCESSCONTROL } from '../../constants/labels';
// import { environment } from '../../../../environments/environment';
import { timeout, map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) {
  }

  timeoutMsec = 300000;

  public post<T>(path: string, body: any | null): Observable<T> {
    return this.http.post<T>(path, body, httpOptions).pipe(timeout(this.timeoutMsec));
  }
  public get<T>(path: string): Observable<T> {
    return this.http.get<T>(path, httpOptions).pipe(timeout(this.timeoutMsec));
  }

  public getwithcode<T>(path: string, securitycode: string): Observable<T> {
    const httpOptionsGet = {
      headers: new HttpHeaders({
        'Content-Type': APPLICATIONCONTENT,
        'access-control-allow-origin': ACCESSCONTROL,
      }),
      params: { code: securitycode }
    };
    return this.http.get<T>(path, httpOptionsGet).pipe(timeout(this.timeoutMsec));
  }

  public postwithcode<T>(path: string, body: any | null, code: string): Observable<T> {
    const httpOptionsGet = {
      headers: new HttpHeaders({
        'Content-Type': APPLICATIONCONTENT,
        'access-control-allow-origin': ACCESSCONTROL,
      }),
      params: { Code: code }
    };
    return this.http.post<T>(path, body, httpOptionsGet).pipe(timeout(this.timeoutMsec));
  }

  public delete<T>(path): Observable<T> {
    return this.http.delete<T>(path).pipe(timeout(this.timeoutMsec));
  }

  public put<T>(path: string, body: any | null): Observable<T> {
    return this.http.put<T>(path, body, httpOptions);
  }
  public inputXML(file: File, path: string): Observable<any> {
    const httpOptionsGet = {
      headers: new HttpHeaders({
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'access-control-allow-origin': ACCESSCONTROL,
      }),
    };
    const formData: FormData = new FormData();
    formData.append('files', file);
    return this.http.post(path, formData).pipe(timeout(this.timeoutMsec));
  }
  public getFileData(path: string): Observable<Blob> {
    return this.http.get(path, { responseType: 'blob' }).pipe(map(
      (res) => {
        return new Blob([res], { type: 'application/pdf' });
      }));
  }
}
