import 'jstree';

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { SirDetails } from 'src/app/common/models/GroupDataModel';
import { SIR_SHOW_MORE_COLUMNS } from 'src/app/common/models/PureFnModel';
import { parallelRuleObj, ruleFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { sirDetailObj } from 'src/app/common/models/SirModel';
import { DataService } from 'src/app/common/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-select-rpc',
  templateUrl: './select-rpc.component.html',
  styleUrls: ['./select-rpc.component.scss']
})
export class SelectRpcComponent implements OnInit {
  step = 0;
  loadingMessage;
  star = 'fa fa-fw fa-search';
  sentPureCodes;
  checkedFlag;
  loading = false;
  marketsToSelect = [];
  expandedView = false;
  isExpanded = false;
  notifyGPCDialog = false;
  searchIdentifier;
  subtanceIdentifier = ['Pure Code', 'INCI Name', 'CAS #', 'SIR ID', 'Substance Name'];
  searchIdentifierValue = null;
  selectedMarket = null;
  radioValSelected = false;
  radioVal;
  searchedSubstanceData = [];
  sirDetails = Array<SirDetails>();
  sirObjSelectedIndex = null;
  checkedPureCodes = [];
  switchedPureCodes = [];
  switchedSirDetails = [];
  selectedSirDetails = [];
  expandedPureCode;
  divisionData = [];
  rpc1Data = [];
  rpc2Data = [];
  rpc3Data = [];
  rpc4Data = [];
  rpc5Data = [];
  rpc6Data = [];
  selectedrpc1 = '';
  selectedrpc2 = '';
  selectedrpc3 = '';
  selectedrpc4 = '';
  selectedrpc5 = '';
  selectedrpc6 = '';
  selectedPrepMethod;
  intendedUse = 'Any';
  prepartionMethodValues = [{ id: 1, value: 'As Sold' }, { id: 2, value: 'As Prepared' }];
  selectedDivision = '';
  disbleNextBtn = false;
  filteredDataDivision = [];
  filteredDataMarket = [];
  filteredRpc1Data = [];
  filteredRpc2Data = [];
  filteredRpc3Data = [];
  filteredRpc4Data = [];
  filteredRpc5Data = [];
  filteredRpc6Data = [];
  validationFailed = false;
  searchResultsGroup = [];
  disablePureCode = false;
  hazardClassificationDetail = '';
  selectedmarket;
  prefMsg = 'Create Market RPC/TFC-Market RPC mapping';
  rpcLevels;
  rpcPathResults;
  disableRpcs = false;
  selectedRpcPath = '';
  onConfirmClicked = false;
  lastRpcSelected = null;
  showMoreData = [];
  showMoreDialog = false;
  showMoreColumns = [];
  divisionValSelected = false;
  userDetails;
  isSuperAdminOrGlobalRA = false;
  selectedRpcNode;
  reModifyTreeBtnFlag = false;
  showPuremsg = false;
  messageParent = 'CAS no. fetched from Parent substance in SIR';
  hazardByPureLevel = [];
  hazardByPureLevelSearch = [];
  constructor(
    private matSnackBar: MatSnackBar,
    private assessRequestRa: AssessRequestRaService,
    private route: Router,
    private dataSvc: DataService) { }
  profileForm = new FormGroup({
    selectedMarket: new FormControl(''),
    selectedrpc1: new FormControl(''),
    selectedrpc2: new FormControl(''),
    selectedrpc3: new FormControl(''),
    selectedrpc4: new FormControl(''),
    selectedrpc5: new FormControl(''),
    selectedrpc6: new FormControl(''),
  });
  ngOnInit() {
    ruleFlags.viewRule = false;
    ruleFlags.editRule = false;
    this.showMoreColumns = SIR_SHOW_MORE_COLUMNS;
    if (parallelRuleObj.selectedMarket) {
      this.selectedMarket = parallelRuleObj.selectedMarket;
      this.profileForm.value.selectedMarket = this.selectedMarket;
      this.getRPCData(this.selectedMarket);
    }
    if (parallelRuleObj.prepMethodForParallelRule) {
      this.selectedPrepMethod = parallelRuleObj.prepMethodForParallelRule;
    } else {
      this.selectedPrepMethod = this.prepartionMethodValues[0];
    }
    if (parallelRuleObj.selectedRPC) {
      this.selectedRpcPath = parallelRuleObj.selectedRPC;
      this.rpcPathResults = this.selectedRpcPath;
      this.onRPCPathSelected(this.selectedRpcPath);
    } else if (parallelRuleObj.selectedrpc1) {
      this.retainRPCSelectedValue();
    }
    this.dataSvc.setruleToEdit(null);
    this.dataSvc.setSelectedStep(0);
    this.getUserBasedMarkets();
    this.getDivisonData();

    const ruleData = JSON.parse(localStorage.getItem('ruleData'));
    if (ruleData !== null) {
      this.selectedMarket = {
        marketId: ruleData.marketId,
        marketName: ruleData.marketName
      };
      const selectedRpcPath = {
        rpcId: ruleData.rpcId,
        rpcSearchValue: ruleData.rpcPath
      };
      this.onRPCPathSelected(selectedRpcPath);
      localStorage.setItem('ruleData', null);
    }
  }
  setStep(index: number) {
    this.step = index;
    if (this.step === 1) {
      // this.checkValidation(0);
    }
  }
  nextStep() {
    if (this.step === 0) {
      this.getDivisonData();
      parallelRuleObj.selectedMarket = this.selectedMarket;
      this.dataSvc.setParallelRuleFormValues(this.profileForm.value);
      const getRpTreeUrl = environment.APIFAsT + '/market/' + this.selectedMarket.marketName + '/rpcid/' + this.lastRpcSelected.rpcId + '/parentrpctree';
      this.assessRequestRa.getData(getRpTreeUrl).subscribe((responseData) => {
        if (responseData !== null) {
          this.loading = false;
          this.dataSvc.setLastSelectedNodeTreeData(responseData);
        }
      }, (error) => {
        this.loading = false;
      });
      this.step++;
    } else if (this.step === 1) {
      //  this.divisionValSelected = false;
      this.sirDetails = [];
      this.showPuremsg = false;
      this.switchedPureCodes = [];
      this.switchedSirDetails = [];
      this.searchIdentifier = null;
      this.expandedView = false;
      this.searchIdentifierValue = null;
      parallelRuleObj.intendedUseForParallelRule = this.intendedUse;
      parallelRuleObj.prepMethodForParallelRule = this.selectedPrepMethod;
      this.sirDetails = [];
      this.switchedPureCodes = [];
      this.switchedSirDetails = [];
      this.searchIdentifier = null;
      this.searchIdentifierValue = null;
      if (this.radioVal === 2 || this.radioVal === 3) {
        if (this.radioVal === 2) {
          this.dataSvc.setRuleTypeVal('Substance Group Rules');
        } else if (this.radioVal === 3) {
          this.dataSvc.setRuleTypeVal('Product Based Rules');
        }
        this.dataSvc.setComponentName('CreateInsertRule');
        this.dataSvc.setSelectedStep(1);
        this.route.navigate(['/create-insert/create-parallel-rule']);
      } else {
        this.step++;
      }
    } else if (this.step === 2) {
      const obj = [];
      obj.push(this.sirDetails[this.sirObjSelectedIndex]);
      this.dataSvc.setSirDetailForParallelRule(obj);
      this.dataSvc.setRuleTypeVal('Substance Based Rules');
      this.dataSvc.setComponentName('CreateInsertRule');
      Object.assign(sirDetailObj, this.sirDetails[this.sirObjSelectedIndex]);
      this.dataSvc.setSelectedStep(1);
      this.route.navigate(['/create-insert/create-parallel-rule']);
    }
  }
  prevStep() {
    this.step--;
  }
  radioSelected(val: any) {
    this.radioValSelected = true;
    this.radioVal = val;
    if (val === 1) {
      this.divisionValSelected = false;
      this.selectedDivision = '';
      localStorage.setItem('selectedRuleBucket', 'Substance-Based');
    } else if (val === 2) {
      this.selectedDivision = '';
      this.divisionValSelected = false;
      localStorage.setItem('selectedRuleBucket', 'Substance-Group');
    } else if (val === 3) {
      localStorage.setItem('selectedRuleBucket', 'Product-Based');
      this.divisionValSelected = true;
      recipeModel.division = null;
    }
  }
  navigate() {
    console.log(this.radioVal);
  }

  searchSubstance() {
    this.sirObjSelectedIndex = null;
    let apiurl;
    this.sirDetails = [];
    this.checkedPureCodes = [];
    this.switchedPureCodes = [];
    this.expandedView = false;
    let val = this.searchIdentifier.replace(/ +/g, '').toUpperCase();
    if (this.searchIdentifier === 'CAS #') {
      val = 'CAS';
    } else if (this.searchIdentifier === 'Substance Name') {
      val = 'BACKBONEIDENTITY';
    } else if (this.searchIdentifier === 'INCI Name') {
      val = 'INCI';
    }
    this.getHazardClassification();
    apiurl = environment.APIFAsTRule + '/rules/substances/' + val + '/cfRequired/y';
    const data = [];
    data.push(this.searchIdentifierValue);
    this.loadingMessage = 'Loading..';
    this.loading = true;
    this.assessRequestRa.postData(apiurl, data).subscribe((response) => {
      if (response && response.length > 0) {
        response[0].hazardClassification = this.hazardClassificationDetail[0];
        this.searchResultsGroup = response;
        this.disablePureCode = true;
        this.loading = false;
      } else {
        this.disablePureCode = false;
        this.loading = false;
        this.matSnackBar.open('Could not find a matching substance, Please provide the correct search value', 'close', {
          duration: 6000,
          panelClass: ['error']
        });
      }
    }, (error) => {
      this.loading = false;
      return false;
    });
  }

  getHazardClassification() {
    this.hazardByPureLevelSearch = [];
    const searchIdentifierValue = (this.searchIdentifierValue === null) ? '' : this.searchIdentifierValue;
    const getHazardClassification = environment.APIFAsT + '/GetHazardClassification/' + this.selectedMarket.marketName + '/' + searchIdentifierValue.toUpperCase();
    this.assessRequestRa.getData(getHazardClassification).subscribe((result) => {
      this.hazardClassificationDetail = result;
      const hazpureData = {
        pureCode : searchIdentifierValue.toUpperCase(),
        hazardClassificationDetail : this.hazardClassificationDetail !== undefined ? this.hazardClassificationDetail[0] : null
      };
      this.hazardByPureLevelSearch.push(hazpureData);
    });
  }

  onSearchIdentifierChange() {
    this.searchIdentifierValue = null;
    this.searchResultsGroup = [];
    this.disablePureCode = false;
    this.sirDetails = [];
    this.sirObjSelectedIndex = null;
    this.showPuremsg = false;
  }
  handleSwitch(event, sirvalue) {
    sirvalue.issueCategoryList = [
      { name: 'PURE CODE', value: 'PURE CODE' },
      { name: 'CAS#', value: 'CAS#' },
      { name: 'ParentCAS#', value: 'ParentCAS#' },
      { name: 'E#', value: 'E#' },
      { name: 'INS#', value: 'INS#' },
      { name: 'EINECS#', value: 'EINECS#' },
      { name: 'INCI', value: 'INCI' },
      { name: 'Synonym', value: 'Synonym' },
      { name: 'CF details', value: 'CF details' },
    ];
    sirvalue.selectedIssueCategory = [];
    this.switchedSirDetails = [];
    if (event.checked === true) {
      this.switchedSirDetails.push(sirvalue);
      this.switchedPureCodes.push(sirvalue.pureCode);
    } else if (event.checked === false) {
      const index = this.switchedPureCodes.findIndex(d => d === sirvalue.pureCode);
      this.switchedPureCodes.splice(index, 1);
      this.switchedSirDetails.splice(index, 1);
    }
  }
  onCheck(event, checkedPureCode) {
    if (event.checked === true) {
      this.checkedPureCodes.push(checkedPureCode);
    } else if (event.checked === false) {
      const index = this.checkedPureCodes.findIndex(d => d === checkedPureCode);
      this.checkedPureCodes.splice(index, 1);
    }
  }
  notifyGPCClicked(event, selectedSirDetails) {
    this.selectedSirDetails = selectedSirDetails;
    for (const value of this.selectedSirDetails) {
      value.commentsToUser = '';
      value.selectedIssueCategory = [];
    }
    this.notifyGPCDialog = true;
  }
  notifySIRClicked(event) {
    this.sentPureCodes = this.checkedPureCodes.toString();
    if (this.checkedPureCodes.length !== 0) {
      const data = {
        NotificationGroup: 'SIR',
        Subject: null,
        EmailBody: null,
        ToEmailList: null,
        FromEmail: null,
        CCEmailList: null,
        EmailType: 'PlainText',
        UPD: recipeModel.division,
        PureCodes: this.sentPureCodes,
        RequestID: null
      };
      const apiurl = environment.APIFAsT + '/email';
      this.assessRequestRa.postData(apiurl, data).subscribe((response) => {
        if (response.message !== null) {
          this.loading = false;
          this.matSnackBar.open('Email message sent to SIR team successfully', 'close', {
            duration: 3000,
            panelClass: ['success']
          });
        }
      }, (error) => {
        this.loading = false;
      });
    } else {
      this.matSnackBar.open('Select the Pure Codes', 'close', {
        duration: 3900,
        panelClass: ['error']
      });
    }
  }
  sendMailtoGPC() {
    let emailData = '';
    this.sentPureCodes = this.switchedPureCodes.toString();
    this.selectedSirDetails.forEach(element => {
      emailData = emailData + '\r\nPure Code:' + ' ' + element.pureCode + '\r\n[SIR ID :' + ' ' + element.sirId + ']' +
        '\r\nBackbone Identity :' + ' ' + element.selectedIssueCategory.map((s) => s.value).toString() + '. ' + '\r\n' + 'Comments : ' + ' ' + element.commentsToUser + '\r\n'
        + 'Pure Description : ' + ' ' + element.pureDescription + '\r\n';
    });
    const data = {
      NotificationGroup: 'GPC',
      Subject: null,
      EmailBody: emailData,
      ToEmailList: null,
      FromEmail: null,
      CCEmailList: null,
      EmailType: 'html',
      UPD: recipeModel.division,
      PureCodes: this.sentPureCodes,
      RequestID: null
    };
    this.switchedSirDetails.forEach(e => {
      e.selectedIssueCategory = [];
      e.commentsToUser = '';
    });
    const apiurl = environment.APIFAsT + '/email';
    this.assessRequestRa.postData(apiurl, data).subscribe((response) => {
      if (response.message !== null) {
        this.loading = false;
        this.matSnackBar.open('Email message sent to GPC team successfully', 'close', {
          duration: 5000,
          panelClass: ['success']
        });
      }
    }, (error) => {
      return false;
    });
  }
  getUserBasedMarkets() {
    this.userDetails = JSON.parse(localStorage.getItem('userRolesPermissions'));
    this.userDetails.roles.forEach(c => {
      if (c.roleId === 150 || c.roleId === 140 || (c.roleId === 130 && c.market === null)) {
        this.isSuperAdminOrGlobalRA = true;
      }
    });
    if (!this.isSuperAdminOrGlobalRA) {
      this.userDetails.roles.forEach(t => {
        if (t.roleId === 130 || t.roleId === 170) {
          this.marketsToSelect.push(t.market);
        }
      });
      this.marketsToSelect.sort((a, b) => (a.marketName > b.marketName) ? 1 : -1);
      this.marketsToSelect = _.uniqBy(this.marketsToSelect, v => [v.marketId, v.marketName].join());
    } else {
      this.getMarketData();
    }
  }
  getMarketData() {
    this.loading = true;
    this.loadingMessage = 'Loading..';
    const apiurl = environment.APIFAsT + '/allmarkets';
    this.assessRequestRa.getData(apiurl).subscribe((response1) => {
      this.loading = false;
      this.marketsToSelect = response1;
    }, (error) => {
      this.loading = false;
    });
  }
  searchMarket(event) {
    if (this.profileForm.value.selectedMarket && this.profileForm.value.selectedMarket.marketId === undefined) {
      this.validationFailed = true;
      this.check();
    } else if ((this.profileForm.value.selectedMarket && this.profileForm.value.selectedMarket.marketId !== undefined)
      && (this.profileForm.value.selectedrpc1 && this.profileForm.value.selectedrpc1.rpcDescription !== undefined)) {
      this.validationFailed = false;
    }
    const filteredSearchMarket = [];
    const query = event.query;
    for (const value of this.marketsToSelect) {
      if (value.marketName.toLowerCase().includes(query.toLowerCase())) {
        filteredSearchMarket.push(value);
      }
    }
    this.filteredDataMarket = filteredSearchMarket;
  }
  searchDivision(event) {
    this.divisionValSelected = false;
    const filteredSearchDivision = [];
    const query = event.query;
    for (const value of this.divisionData) {
      if (value.updName.toLowerCase().includes(query.toLowerCase())) {
        filteredSearchDivision.push(value);
      }
    }
    this.filteredDataDivision = filteredSearchDivision;
  }

  updateMarket(selected) {
    this.marketSelected(selected);
    if ((this.profileForm.value.selectedMarket && this.profileForm.value.selectedMarket.marketId !== undefined)
      && (this.profileForm.value.selectedrpc1 && this.profileForm.value.selectedrpc1.rpcDescription !== undefined)) {
      this.validationFailed = false;
    }
    this.check();
    setTimeout(() => {
      this.getRPCData(this.selectedMarket);
    }, 500);
  }
  updateMarket1(event, market) {
    this.selectedMarket = market.marketName;
    this.profileForm.value.selectedMarket = market;
  }
  marketSelected(val) {
    this.rpc1Data = [];
    this.rpc2Data = [];
    this.rpc3Data = [];
    this.rpc4Data = [];
    this.rpc5Data = [];
    this.rpc6Data = [];
    this.selectedrpc1 = '';
    this.selectedrpc2 = '';
    this.selectedrpc3 = '';
    this.selectedrpc4 = '';
    this.selectedrpc5 = '';
    this.selectedrpc6 = '';
    this.profileForm.value.selectedrpc1 = '';
    this.profileForm.value.selectedrpc2 = '';
    this.profileForm.value.selectedrpc3 = '';
    this.profileForm.value.selectedrpc4 = '';
    this.profileForm.value.selectedrpc5 = '';
    this.profileForm.value.selectedrpc6 = '';
    parallelRuleObj.rpcsForParallelRuleData = [];
    $('#treeDiv').jstree('destroy').empty();
    this.onConfirmClicked = false;
  }
  getDivisonData() {
    const apiurl = environment.APIFAsT + '/upds';
    this.assessRequestRa.getData(apiurl).subscribe((response1) => {
      this.loading = false;
      this.divisionData = response1;
    }, (error) => {
      this.loading = false;
    });
  }
  retainRPCSelectedValue() {
    this.selectedrpc1 = parallelRuleObj.selectedrpc1;
    this.rpc1Changed(this.selectedrpc1);
    if (this.selectedrpc1) {
      this.selectedrpc2 = parallelRuleObj.selectedrpc2;
      this.rpc2Changed(this.selectedrpc2);
      if (this.selectedrpc2) {
        this.selectedrpc3 = parallelRuleObj.selectedrpc3;
        this.rpc3Changed(this.selectedrpc3);
        if (this.selectedrpc3) {
          this.selectedrpc4 = parallelRuleObj.selectedrpc4;
          this.rpc4Changed(this.selectedrpc4);
          if (this.selectedrpc4) {
            this.selectedrpc5 = parallelRuleObj.selectedrpc5;
            this.rpc5Changed(this.selectedrpc5);
            if (this.selectedrpc5) {
              this.selectedrpc6 = parallelRuleObj.selectedrpc6;
            }
          }
        }
      }
    }
  }
  getRPCData(value) {
    if (value) {
      const marketValue = value.marketId;
      this.loading = true;
      this.loadingMessage = 'Loading..';
      const url = environment.APIFAsT + '/marketid/' + marketValue + '/tfcid/0' + '/rpclist';
      this.assessRequestRa.getData(url).subscribe((rpcdata) => {
        this.loading = false;
        this.rpc1Data = rpcdata;
      }, (error) => {
        this.rpc1Data = [];
        this.loading = false;
      });
    }
  }

  searchRpc1(event) {
    this.selectedmarket = this.profileForm.value.selectedMarket.marketName;
    if (this.profileForm.value.selectedrpc1 && this.profileForm.value.selectedrpc1.rpcDescription === undefined) {
      this.validationFailed = true;
      this.checkRpc1(1, '');
    } else if ((this.profileForm.value.selectedMarket && this.profileForm.value.selectedMarket.marketId !== undefined)
      && (this.profileForm.value.selectedrpc1 && this.profileForm.value.selectedrpc1.rpcDescription !== undefined)) {
      this.validationFailed = false;
    }
    const filteredRpc = [];
    const query = event.query;
    for (const value of this.rpc1Data) {
      if (value.rpcDescription.toLowerCase().includes(query.toLowerCase())) {
        filteredRpc.push(value);
      }
    }
    this.filteredRpc1Data = filteredRpc;
  }

  searchRpc2(event) {
    if (this.profileForm.value.selectedrpc2 && this.profileForm.value.selectedrpc2.rpcDescription === undefined) {
      this.checkRpc1(2, '');
    }
    const filteredRpc = [];
    const query = event.query;
    for (const value of this.rpc2Data) {
      if (value.rpcDescription.toLowerCase().includes(query.toLowerCase())) {
        filteredRpc.push(value);
      }
    }
    this.filteredRpc2Data = filteredRpc;
  }

  searchRpc3(event) {
    if (this.profileForm.value.selectedrpc3 && this.profileForm.value.selectedrpc3.rpcDescription === undefined) {
      this.checkRpc1(3, '');
    }
    const filteredRpc = [];
    const query = event.query;
    for (const value of this.rpc3Data) {
      if (value.rpcDescription.toLowerCase().includes(query.toLowerCase())) {
        filteredRpc.push(value);
      }
    }
    this.filteredRpc3Data = filteredRpc;
  }

  searchRpc4(event) {
    if (this.profileForm.value.selectedrpc4 && this.profileForm.value.selectedrpc4.rpcDescription === undefined) {
      this.checkRpc1(4, '');
    }
    const filteredRpc = [];
    const query = event.query;
    for (const value of this.rpc4Data) {
      if (value.rpcDescription.toLowerCase().includes(query.toLowerCase())) {
        filteredRpc.push(value);
      }
    }
    this.filteredRpc4Data = filteredRpc;
  }

  searchRpc5(event) {
    if (this.profileForm.value.selectedrpc5 && this.profileForm.value.selectedrpc5.rpcDescription === undefined) {
      this.checkRpc1(5, '');
    }
    const filteredRpc = [];
    const query = event.query;
    for (const value of this.rpc5Data) {
      if (value.rpcDescription.toLowerCase().includes(query.toLowerCase())) {
        filteredRpc.push(value);
      }
    }
    this.filteredRpc5Data = filteredRpc;
  }

  searchRpc6(event) {
    const filteredRpc = [];
    const query = event.query;
    for (const value of this.rpc6Data) {
      if (value.rpcDescription.toLowerCase().includes(query.toLowerCase())) {
        filteredRpc.push(value);
      }
    }
    this.filteredRpc6Data = filteredRpc;
  }

  rpc1Changed(value) {
    if ((this.profileForm.value.selectedMarket && this.profileForm.value.selectedMarket.marketId !== undefined)
      && (this.profileForm.value.selectedrpc1 && this.profileForm.value.selectedrpc1.rpcDescription !== undefined)) {
      this.validationFailed = false;
    }
    this.rpc2Data = [];
    this.rpc3Data = [];
    this.rpc4Data = [];
    this.rpc5Data = [];
    this.rpc6Data = [];
    this.selectedrpc2 = '';
    this.selectedrpc3 = '';
    this.selectedrpc4 = '';
    this.selectedrpc5 = '';
    this.selectedrpc6 = '';
    this.profileForm.value.selectedrpc2 = '';
    this.profileForm.value.selectedrpc3 = '';
    this.profileForm.value.selectedrpc4 = '';
    this.profileForm.value.selectedrpc5 = '';
    this.profileForm.value.selectedrpc6 = '';
    parallelRuleObj.rpcsForParallelRuleData = [];
    parallelRuleObj.selectedrpc1 = this.profileForm.value.selectedrpc1 ? this.profileForm.value.selectedrpc1 : this.selectedrpc1;
    $('#treeDiv').jstree('destroy').empty();
    this.loading = true;
    const rpcId = value.rpcId;
    if (rpcId) {
      const url = environment.APIFAsT + '/marketid/' + this.profileForm.value.selectedMarket.marketId + '/rpcid/' + rpcId + '/immediteChildren';
      this.assessRequestRa.getData(url).subscribe((rpcdata) => {
        this.loading = false;
        this.rpc2Data = rpcdata;
      }, (error) => {
        this.loading = false;
      });
    }
  }
  rpc2Changed(value) {
    this.rpc3Data = [];
    this.rpc4Data = [];
    this.rpc5Data = [];
    this.rpc6Data = [];
    this.selectedrpc3 = '';
    this.selectedrpc4 = '';
    this.selectedrpc5 = '';
    this.selectedrpc6 = '';
    this.profileForm.value.selectedrpc3 = '';
    this.profileForm.value.selectedrpc4 = '';
    this.profileForm.value.selectedrpc5 = '';
    this.profileForm.value.selectedrpc6 = '';
    parallelRuleObj.selectedrpc2 = this.profileForm.value.selectedrpc2 ? this.profileForm.value.selectedrpc2 : this.selectedrpc2;
    const rpcId = value.rpcId;
    if (rpcId) {
      this.loading = true;
      const url = environment.APIFAsT + '/marketid/' + this.profileForm.value.selectedMarket.marketId + '/rpcid/' + rpcId + '/immediteChildren';
      this.assessRequestRa.getData(url).subscribe((rpcdata) => {
        this.loading = false;
        this.rpc3Data = rpcdata;
      }, (error) => {
        this.loading = false;
      });
    }
  }
  check() {
    this.rpc1Data = [];
    this.rpc2Data = [];
    this.rpc3Data = [];
    this.rpc4Data = [];
    this.rpc5Data = [];
    this.rpc6Data = [];
    this.selectedrpc1 = '';
    this.selectedrpc2 = '';
    this.selectedrpc3 = '';
    this.selectedrpc4 = '';
    this.selectedrpc5 = '';
    this.selectedrpc6 = '';
    this.profileForm.value.selectedrpc1 = '';
    this.profileForm.value.selectedrpc2 = '';
    this.profileForm.value.selectedrpc3 = '';
    this.profileForm.value.selectedrpc4 = '';
    this.profileForm.value.selectedrpc5 = '';
    this.profileForm.value.selectedrpc6 = '';
    this.selectedRpcPath = '';
    parallelRuleObj.rpcsForParallelRuleData = [];
    $('#treeDiv').jstree('destroy').empty();
    this.onConfirmClicked = false;
  }
  checkRpc1(index, event) {
    if (event === '') {
      if (index === 1) {
        this.rpc2Data = [];
        this.rpc3Data = [];
        this.rpc4Data = [];
        this.rpc5Data = [];
        this.rpc6Data = [];
        this.selectedrpc2 = '';
        this.selectedrpc3 = '';
        this.selectedrpc4 = '';
        this.selectedrpc5 = '';
        this.selectedrpc6 = '';
        this.profileForm.value.selectedrpc2 = '';
        this.profileForm.value.selectedrpc3 = '';
        this.profileForm.value.selectedrpc4 = '';
        this.profileForm.value.selectedrpc5 = '';
        this.profileForm.value.selectedrpc6 = '';
      } else if (index === 2) {
        this.rpc3Data = [];
        this.rpc4Data = [];
        this.rpc5Data = [];
        this.rpc6Data = [];
        this.selectedrpc3 = '';
        this.selectedrpc4 = '';
        this.selectedrpc5 = '';
        this.selectedrpc6 = '';
        this.profileForm.value.selectedrpc3 = '';
        this.profileForm.value.selectedrpc4 = '';
        this.profileForm.value.selectedrpc5 = '';
        this.profileForm.value.selectedrpc6 = '';
      } else if (index === 3) {
        this.rpc4Data = [];
        this.rpc5Data = [];
        this.rpc6Data = [];
        this.selectedrpc4 = '';
        this.selectedrpc5 = '';
        this.selectedrpc6 = '';
        this.profileForm.value.selectedrpc4 = '';
        this.profileForm.value.selectedrpc5 = '';
        this.profileForm.value.selectedrpc6 = '';
      } else if (index === 4) {
        this.rpc5Data = [];
        this.rpc6Data = [];
        this.selectedrpc5 = '';
        this.selectedrpc6 = '';
        this.profileForm.value.selectedrpc5 = '';
        this.profileForm.value.selectedrpc6 = '';
      } else if (index === 5) {
        this.rpc6Data = [];
        this.selectedrpc6 = '';
        this.profileForm.value.selectedrpc6 = '';
      }
    }
    parallelRuleObj.rpcsForParallelRuleData = [];
    $('#treeDiv').jstree('destroy').empty();
    this.onConfirmClicked = false;
  }
  Clear() {
    this.rpc1Data = [];
    this.divisionData = [];
    this.rpc2Data = [];
    this.rpc3Data = [];
    this.rpc4Data = [];
    this.rpc5Data = [];
    this.rpc6Data = [];
    this.profileForm.value.selectedrpc1 = '';
    this.profileForm.value.selectedrpc2 = '';
    this.profileForm.value.selectedrpc3 = '';
    this.profileForm.value.selectedrpc4 = '';
    this.profileForm.value.selectedrpc5 = '';
    this.profileForm.value.selectedrpc6 = '';
  }
  divisionSelected(val) {
    this.divisionValSelected = true;
    recipeModel.division = val.updName;
  }

  rpc3Changed(value) {
    this.rpc4Data = [];
    this.rpc5Data = [];
    this.rpc6Data = [];
    this.selectedrpc4 = '';
    this.selectedrpc5 = '';
    this.selectedrpc6 = '';
    this.profileForm.value.selectedrpc4 = '';
    this.profileForm.value.selectedrpc5 = '';
    this.profileForm.value.selectedrpc6 = '';
    parallelRuleObj.selectedrpc3 = this.selectedrpc3;
    const rpcId = value.rpcId;
    if (rpcId) {
      this.loading = true;
      const url = environment.APIFAsT + '/marketid/' + this.profileForm.value.selectedMarket.marketId + '/rpcid/' + rpcId + '/immediteChildren';
      this.assessRequestRa.getData(url).subscribe((rpcdata) => {
        this.loading = false;
        this.rpc4Data = rpcdata;
      }, (error) => {
        this.loading = false;
      });
    }
  }

  rpc4Changed(value) {
    this.rpc5Data = [];
    this.rpc6Data = [];
    this.profileForm.value.selectedrpc5 = '';
    this.profileForm.value.selectedrpc6 = '';
    this.selectedrpc5 = '';
    this.selectedrpc6 = '';
    parallelRuleObj.selectedrpc4 = this.selectedrpc4;
    const rpcId = value.rpcId;
    if (rpcId) {
      this.loading = true;
      const url = environment.APIFAsT + '/marketid/' + this.profileForm.value.selectedMarket.marketId + '/rpcid/' + rpcId + '/immediteChildren';
      this.assessRequestRa.getData(url).subscribe((rpcdata) => {
        this.loading = false;
        this.rpc5Data = rpcdata;
      }, (error) => {
        this.loading = false;
      });
    }
  }

  rpc5Changed(value) {
    this.rpc6Data = [];
    this.selectedrpc6 = '';
    this.profileForm.value.selectedrpc6 = '';
    parallelRuleObj.selectedrpc5 = this.selectedrpc5;
    const rpcId = value.rpcId;
    if (rpcId) {
      this.loading = true;
      const url = environment.APIFAsT + '/marketid/' + this.profileForm.value.selectedMarket.marketId + '/rpcid/' + rpcId + '/immediteChildren';
      this.assessRequestRa.getData(url).subscribe((rpcdata) => {
        this.loading = false;
        this.rpc6Data = rpcdata;
      }, (error) => {
        this.loading = false;
      });
    }
  }
  stringToArrayConversion(sirData) {
    this.sirDetails = [];
    const objLen = (sirData[0].pureCode.split(','));
    let isPurelevelSearch = false;
    objLen.forEach(element => {
      sirData.forEach((value) => {
        let hazardClassificationData = value.hazardClassification;
        if (this.searchIdentifier.toLowerCase() !== 'pure code') {
          isPurelevelSearch = false;
          hazardClassificationData = this.hazardByPureLevel.filter(p => p.pureCode === element).map(h => h.hazardClassificationDetail)[0];
        } else if (this.searchIdentifier.toLowerCase() === 'pure code')  {
          isPurelevelSearch = true;
          hazardClassificationData = this.hazardByPureLevelSearch.filter(p => p.pureCode === element).map(h => h.hazardClassificationDetail)[0];
        }
        const sirObj = {
          cfDetails: value.cfDetails,
          selectedCF: value.cfDetails[0].cfDisplayText,
          combinationRatio: value.combinationRatio,
          pureCode: element,
          pureDescription: value.pureDescription,
          sirId: value.sirId,
          substanceFunction: value.substanceFunction,
          substanceMolWeight: value.substanceMolWeight,
          substanceName: value.substanceName,
          hazardClassification: hazardClassificationData,
          inciName: (value.inciName === '' || value.inciname === null) ? [] : value.inciName.split(','),
          cas: (value.cas === '' || value.cas === null) ? [] : value.cas.split(','),
          parentCAS: (value.parentCAS === '' || value.parentCAS === null) ? [] : value.parentCAS.split(','),
          einecs: (value.einecs === '' || value.einecs === null) ? [] : value.einecs.split(','),
          eNumber: (value.eNumber === '' || value.eNumber === null) ? [] : value.eNumber.split(','),
          ins: (value.ins === '' || value.ins === null) ? [] : value.ins.split(','),
          synonyms: [],
          uoM: value.uoM,
          checked: false
        };
        if (!isPurelevelSearch) {
          this.sirDetails.push(sirObj);
        } else if (isPurelevelSearch && this.hazardByPureLevelSearch.filter(p => p.pureCode === element).length > 0) {
          this.sirDetails.push(sirObj);
        }
      });
    });
  }
  goToPrefClicked() {
    const url = environment.PREFADMIN;
    window.open(url, '_blank');
  }
  checkval(event) {
    if (event.target.value === '') {
      this.searchResultsGroup = [];
      this.disablePureCode = false;
      this.searchIdentifierValue = null;
      this.sirDetails = [];
      this.sirObjSelectedIndex = null;
      this.showPuremsg = false;
    }
  }
  /* istanbul ignore next */
  valueSelected(rowselected: any) {
    this.searchResultsGroup = [];
    this.disablePureCode = false;
    const data = [];
    data.push(rowselected);
    if (rowselected.sirId !== 'SIR ID Not found' && rowselected.pureCode !== 'PURE CODE Not found') {
      if (this.searchIdentifier.toLowerCase() !== 'pure code') {
        this.getHazardForNonPurecode(data);
      } else {
        this.stringToArrayConversion(data);
        this.disbleNextBtn = false;
      }
    } else {
      this.disbleNextBtn = true;
      this.sirDetails = data;
    }
    if (rowselected.pureCode === 'No PURE CODE') {
      this.showPuremsg = true;
      this.sirDetails.forEach(e => {
        if (rowselected.sirId === e.sirId) {
          e.checked = true;
          this.handleSwitch({ checked: true }, e);
        }
      });
    } else {
      this.showPuremsg = false;
    }
  }
  searchRPCPath(event) {
    this.loading = true;
    const data = [this.selectedMarket];
    const getRpcPathUrl = environment.APIFAsT + '/searchtext/' + event.query + '/rpcpaths';
    this.assessRequestRa.postData(getRpcPathUrl, data).subscribe((responseData) => {
      if (responseData !== null) {
        this.loading = false;
        this.rpcPathResults = responseData;
      }
    }, (error) => {
      this.loading = false;
    });

  }
  onRPCPathSelected(selectedRpcPath) {
    this.loading = true;
    this.onRPCPathUnselected();
    parallelRuleObj.selectedRPC = selectedRpcPath;
    this.selectedRpcPath = selectedRpcPath;
    const getRpcPathUrl = environment.APIFAsT + '/rpcid/' + selectedRpcPath.rpcId + '/parents';
    this.assessRequestRa.getData(getRpcPathUrl).subscribe((responseData) => {
      if (responseData !== null) {
        this.disableRpcs = true;
        this.loading = false;
        this.rpcLevels = responseData;
        this.selectedrpc1 = this.rpcLevels[0];
        this.selectedrpc2 = this.rpcLevels[1];
        this.selectedrpc3 = this.rpcLevels[2];
        this.selectedrpc4 = this.rpcLevels[3];
        this.selectedrpc5 = this.rpcLevels[4];
        this.selectedrpc6 = this.rpcLevels[5];
        this.profileForm.value.selectedrpc1 = this.rpcLevels[0];
        this.profileForm.value.selectedrpc2 = this.rpcLevels[1];
        this.profileForm.value.selectedrpc3 = this.rpcLevels[2];
        this.profileForm.value.selectedrpc4 = this.rpcLevels[3];
        this.profileForm.value.selectedrpc5 = this.rpcLevels[4];
        this.profileForm.value.selectedrpc6 = this.rpcLevels[5];

        parallelRuleObj.selectedrpc1 = this.rpcLevels[0];
        parallelRuleObj.selectedrpc2 = this.rpcLevels[1];
        parallelRuleObj.selectedrpc3 = this.rpcLevels[2];
        parallelRuleObj.selectedrpc4 = this.rpcLevels[3];
        parallelRuleObj.selectedrpc5 = this.rpcLevels[4];
        parallelRuleObj.selectedrpc6 = this.rpcLevels[5];
        if (this.selectedrpc1) {
          this.validationFailed = false;
        }
        this.selectedRpcNode = '';
        if (this.reModifyTreeBtnFlag) {
          this.fetchRpcTree();
        }
      }
    }, (error) => {
      this.loading = false;
    });

  }
  onRPCPathUnselected() {
    parallelRuleObj.selectedRPC = null;
    this.validationFailed = false;
    this.disableRpcs = false;
    this.selectedrpc1 = '';
    this.selectedrpc2 = '';
    this.selectedrpc3 = '';
    this.selectedrpc4 = '';
    this.selectedrpc5 = '';
    this.selectedrpc6 = '';

    this.profileForm.value.selectedrpc1 = '';
    this.profileForm.value.selectedrpc2 = '';
    this.profileForm.value.selectedrpc3 = '';
    this.profileForm.value.selectedrpc4 = '';
    this.profileForm.value.selectedrpc5 = '';
    this.profileForm.value.selectedrpc6 = '';
    parallelRuleObj.rpcsForParallelRuleData = [];
    $('#treeDiv').jstree('destroy').empty();
    this.onConfirmClicked = false;
  }
  fetchRpcTree() {
    this.lastRpcSelected = null;
    $('#treeDiv').jstree('destroy').empty();
    if (this.selectedrpc6 !== '' && this.selectedrpc6 !== undefined) {
      this.lastRpcSelected = this.selectedrpc6;
    } else if (this.selectedrpc5 !== '' && this.selectedrpc5 !== undefined) {
      this.lastRpcSelected = this.selectedrpc5;
    } else if (this.selectedrpc4 !== '' && this.selectedrpc4 !== undefined) {
      this.lastRpcSelected = this.selectedrpc4;
    } else if (this.selectedrpc3 !== '' && this.selectedrpc3 !== undefined) {
      this.lastRpcSelected = this.selectedrpc3;
    } else if (this.selectedrpc2 !== '' && this.selectedrpc2 !== undefined) {
      this.lastRpcSelected = this.selectedrpc2;
    } else if (this.selectedrpc1 !== '' && this.selectedrpc1 !== undefined) {
      this.lastRpcSelected = this.selectedrpc1;
    }
    this.loading = true;
    const getRpTreeUrl = environment.APIFAsT + '/market/' + this.selectedMarket.marketName + '/rpcid/' + this.lastRpcSelected.rpcId + '/rpctree';
    if (this.reModifyTreeBtnFlag) {
      this.reModifyTreeBtnFlag = false;
    }
    this.assessRequestRa.getData(getRpTreeUrl).subscribe((responseData) => {
      if (responseData !== null) {
        this.loading = false;
        const resp = responseData;
        this.createRpcTree(resp);
      }
    }, (error) => {
      this.loading = false;
    });
  }
  /* istanbul ignore next */
  createRpcTree(rpcdata) {
    this.selectedRpcNode = '';
    $('#treeDiv').on('changed.jstree', (evt, data) => {
      $('#treeDiv').jstree(true).set_icon(data.node, 'https://jstree.com/tree-icon.png');
    });

    // tslint:disable-next-line: no-shadowed-variable
    $('#treeDiv').on('changed.jstree', (e, rpcdata) => {
      this.selectedRpcNode = rpcdata;
    }).jstree({
      plugins: ['changed', 'wholerow'],
      checkbox: {
        three_state: false

      },
      core: {
        check_callback: true,
        data: rpcdata,
        themes: {
          icons: false
        }
      },
      types: {
        default: {
          icon: 'src/assets/images/tree.svg'
        }
      },
      icon: 'glyphicon glyphicon-file file-node',

      // tslint:disable-next-line: no-shadowed-variable  // tslint:disable-next-line: space-before-function-paren
    }).bind('loaded.jstree', function (event, rpcdata) {
      $(this).jstree('open_all');
    });

    //  $('#search-tree').jstree(true).refresh();
    $('#treeDiv').jstree(true).redraw(true);
  }
  /* istanbul ignore next */
  sirObjSelectedIndexChange() {
    const obj = [];
    obj.push(this.sirDetails[this.sirObjSelectedIndex]);
    this.dataSvc.setSirDetailForParallelRule(obj);
  }
  /* istanbul ignore next */
  displayShowMoreDialog(pureCode) {
    this.showMoreDialog = true;
    let apiurl;
    apiurl = environment.APIFAsT + '/getPureLevelSpecDetails_WithoutReqId/' + this.selectedMarket.marketName + '/' + pureCode;
    this.loadingMessage = 'Getting Pure Level Specification';
    this.loading = true;
    this.showMoreData = [];
    this.assessRequestRa.getData(apiurl).subscribe((response) => {
      this.loading = false;
      this.showMoreData = response;
    });
  }
  onReModifyClicked() {
    this.reModifyTreeBtnFlag = true;
    this.onRPCPathUnselected();
    this.selectedRpcPath = this.selectedRpcNode.node.original.rpcFullText;
    const val = {
      rpcId: this.selectedRpcNode.node.original.id,
      rpcFullText: this.selectedRpcNode.node.original.rpcFullText,
      rpcSearchValue: this.selectedRpcNode.node.original.rpcFullText,
      rpcDescription: this.selectedRpcNode.node.original.text

    };
    this.onRPCPathSelected(val);
  }
  /* istanbul ignore next */
  getHazardForNonPurecode(sirData) {
    this.hazardByPureLevel = [];
    if (this.searchIdentifier.toLowerCase() !== 'pure code') {
      const objLen = (sirData[0].pureCode.split(','));
      const pureCount = objLen.length;
      let increment = 0;
      objLen.forEach(element => {
          const getHazardClassification = environment.APIFAsT + '/GetHazardClassification/' + this.selectedMarket.marketName + '/' + element.toUpperCase();
          this.assessRequestRa.getData(getHazardClassification).subscribe((result) => {
            this.hazardClassificationDetail = result;
            const hazpureData = {
              pureCode : element,
              hazardClassificationDetail : this.hazardClassificationDetail.length > 0 ? this.hazardClassificationDetail[0] : null
            };
            this.hazardByPureLevel.push(hazpureData);
            increment = increment + 1;
            if (pureCount === increment) {
              this.stringToArrayConversion(sirData);
              this.disbleNextBtn = false;
            }
          });
      });
    }
  }
}
