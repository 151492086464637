import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as fs from 'file-saver';
import { environment } from 'src/environments/environment';

import { AssessRequestRaService } from '../assess-request-ra/assess-request-ra.service';
import { HELPTABLE_COLUMNS } from '../common/models/tableColumns';

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss']
})
export class HelpPageComponent implements OnInit {

  constructor(private reqSvc: AssessRequestRaService) { }
  @ViewChild('helptableDiv') rowHoverDiv: ElementRef;
  @ViewChild('helptableDivRD') rowHoverDivRD: ElementRef;
  activeTabUR = 'fastra';
  helpTableListColumns = [];
  helpRAListData = [];
  helpRDListData = [];
  selectedRowData: any = [];
  loading = false;
  extension = '.pdf';
  rowData = [];
  step = 0;
  ngOnInit() {
    this.loading = true;
    this.helpTableListColumns = HELPTABLE_COLUMNS;
    const functionApiUrl = environment.APIFAsT + '/help/allFAQs';
    this.reqSvc.getData(functionApiUrl).subscribe((response) => {
      this.loading = false;
      for (const value of response) {
        if (value.questionCategory === 'FAsT - RnD') {
          this.helpRDListData.push(value);
        } else if (value.questionCategory === 'FAsT - RA') {
          this.helpRAListData.push(value);
        }
      }
    }, (error) => {
      this.loading = false;
    });
  }
  onTabChange(activeTab) {
    this.hideFloatingIcons();
    this.activeTabUR = activeTab;
  }
  /* istanbul ignore next */
  showFloatingIcons(event, rowData) {
    this.selectedRowData = rowData;
    this.rowData = rowData;
    event.target.dataTypeElem = 'row';
    setTimeout(() => {
      const htmlRowElm = document.getElementById('helptableDiv');
      const adj = (window.screen.width === window.outerWidth) ? 0 : -15;
      htmlRowElm.style.display = 'block';
      htmlRowElm.style.left = String(event.pageX + 15) + 'px';
      if (event.pageY > 400) {
        htmlRowElm.style.top = (event.pageY - 180) + 'px';
      } else {
        htmlRowElm.style.top = (event.pageY - 10) + 'px';
      }
    }, 100);
  }
  downloadlinkFile(rowData) {
    this.selectedRowData = rowData;
    this.rowData = rowData;
    this.downloadFile();
  }
  showFloatingIconsRD(event, rowData) {
    this.selectedRowData = rowData;
    this.rowData = rowData;
    event.target.dataTypeElem = 'row';
    setTimeout(() => {
      const htmlDivElm = document.getElementById('helptableDivRD');
      const adj = (window.screen.width === window.outerWidth) ? 0 : -15;
      htmlDivElm.style.display = 'block';
      htmlDivElm.style.left = String(event.pageX + 15) + 'px';
      if (event.pageY > 400) {
        htmlDivElm.style.top = (event.pageY - 180) + 'px';
      } else {
        htmlDivElm.style.top = (event.pageY - 10) + 'px';
      }
    }, 100);
  }
  hideFloatingIcons() {
    this.rowHoverDiv.nativeElement.style.display = 'none';
    this.rowHoverDivRD.nativeElement.style.display = 'none';
  }
  downloadFile() {
    this.loading = true;
    const fileURL = environment.APIFAsT + '/help/FAQ/' + this.selectedRowData.faqId;
    this.reqSvc.downloadFile(fileURL).subscribe((response) => {
      this.loading = false;
      if ( this.selectedRowData.storageFileName.indexOf('.') !== -1) {
        this.extension = this.selectedRowData.storageFileName.substring(this.selectedRowData.storageFileName.indexOf('.'));
      }
      fs.saveAs(response, this.selectedRowData.questionDesc + this.extension);
    }, (error) => {
      this.loading = false;
    });
  }
  mousehoverpop(event) {
    event.stopPropagation();
  }
  setStep(index: number) {
    this.step = index;
  }
}
