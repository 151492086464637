import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/common/services/data.service';

@Component({
  selector: 'app-create-group-rule',
  templateUrl: './create-group-rule.component.html',
  styleUrls: ['./create-group-rule.component.scss']
})
export class CreateGroupRuleComponent implements OnInit {
  selectedRuleTypeDetails = [];
  ruleDisplayName; ruleTypeDisplayName; ruleTypeIdVal;
  selectedRuleDetails: any;
  constructor(public dataSvcObj: DataService) { }

  ngOnInit() {
    // on change of group ruletype
    this.dataSvcObj.selectedGroupRuleType.subscribe((response: any) => {
      this.selectedRuleDetails = response;
      this.ruleTypeDisplayName = this.selectedRuleDetails.ruleTypeDisplayName;
      this.ruleTypeIdVal = this.selectedRuleDetails.ruleTypeId;
    });
  }
}
