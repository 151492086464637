import { Injectable } from '@angular/core';
import { AuthorizationDataService } from './authorization-data.service';
import { UserRolesAndPermissions, Role, Permission } from './permissionsModel';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthorizationService {

    permissonsString: string;
    permissonIdsString: string;
    permissions: Array<string> = []; // Store the actions for which this user has permission
    permissionsIds: Array<string> = [];
    rolesString: string;
    roles: Array<string> = [];
    userRolesPermissions: UserRolesAndPermissions;
    userTypes: Array<string> = [];
    userTypesString: string;

    constructor(private authorizationDataService: AuthorizationDataService, private router: Router) {

        this.rolesString = localStorage.getItem('roles');
        if (this.rolesString) {
            this.roles = this.rolesString.split(',');
        }
        this.permissonsString = localStorage.getItem('permissons');
        this.permissonIdsString = localStorage.getItem('permissonsIds');
        if (this.permissonsString) {
            this.permissions = this.permissonsString.split(',');
            this.permissionsIds = this.permissonIdsString.split(',');
        }

        if ((!this.permissionsIds && !this.permissions) || !this.roles) {
            this.initializePermissions();
        }
    }
/* istanbul ignore next */
    getUserType() {
        if (this.rolesString) {
            const ra = this.rolesString.indexOf('RA') > -1 ? 'RA' : 'NA';
            const rnd = this.rolesString.indexOf('R&D') > -1 ? 'R&D' : 'NA';
            const admin = this.rolesString.toLocaleLowerCase().indexOf('admin') > -1 ? 'Admin' : 'NA';
            this.userTypesString = '';

            if (ra === 'RA') {
                this.userTypes.push(ra);
                this.userTypesString += ra;
            }

            if (rnd === 'R&D') {
                this.userTypes.push(rnd);
                this.userTypesString += ';' + rnd;
            }

            if (admin === 'Admin') {
                this.userTypes.push(admin);
                this.userTypesString += ';' + admin;
            }

            localStorage.setItem('userTypes', this.userTypesString);
            return this.userTypes;
        } else {
            return [];
        }
    }
    /* istanbul ignore next */
    hasPermission(currentPermissions: string) {
        if (this.permissions) {
            if (currentPermissions) {
                // tslint:disable-next-line: prefer-const
                let permissionList = currentPermissions.split(',');
                if (this.permissions && this.permissions.find(permission => {
                    return permissionList.indexOf(permission) > -1;
                })) {
                    return true;
                }
                return false;

            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    /* istanbul ignore next */
    hasPermissionIds(currentPermissions: string) {
        if (this.permissionsIds) {
            if (currentPermissions) {
                // tslint:disable-next-line: prefer-const
                let permissionList = currentPermissions.split(',');
                if (this.permissionsIds && this.permissionsIds.find(permission => {
                    return permissionList.indexOf(permission) > -1;
                })) {
                    return true;
                }
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    /* istanbul ignore next */
    hasPermissionLoad(currentPermissions: string, allPermissions: any[]) {
        if (allPermissions) {
            if (currentPermissions) {
                // tslint:disable-next-line: prefer-const
                let permissionList = currentPermissions.split(',');
                if (allPermissions && allPermissions.find(permission => {
                    return permissionList.indexOf(permission) > -1;
                })) {
                    return true;
                }
                return false;

            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    // This method is called once and a list of permissions is stored in the permissions property
    /* istanbul ignore next */
    initializePermissions() {
        // Call API to retrieve the list of actions this user is permitted to perform. (Details not provided here.)
        // In this case, the method returns a Promise, but it could have been implemented as an Observable
        // 12, 120, 130, 140, 160
        return this.authorizationDataService.getPermissions()
            .subscribe((permissionsData: any) => {
                const splitUrl = this.router.url.split('/');
                const plmRules = [150, 110, 170];
                let isaccessPlm = '1';
                permissionsData.roles.forEach(c => {
                    if (plmRules.includes(c.roleId)) {
                        isaccessPlm = '0';
                        return;
                    }
                });
                if ( permissionsData.fastStatus === null) {
                    localStorage.setItem('isaccessPlm', '0');
                    localStorage.setItem('fromplm', '0');
                    localStorage.setItem('userRolesPermissionspml', JSON.stringify(permissionsData));
                    this.router.navigate(['/unauthorized']);
                } else if ((splitUrl[2] === 'select-recipe-plm') && permissionsData.fastStatus !== null && permissionsData.fastStatus.toLocaleLowerCase() === 'active' && isaccessPlm === '1') {
                    localStorage.setItem('isaccessPlm', '1');
                    localStorage.setItem('fromplm', '1');
                    localStorage.setItem('userRolesPermissionspml', JSON.stringify(permissionsData));
                    this.router.navigate(['/unauthorized']);
                } else if ((splitUrl[2] === 'select-recipe-plm' || splitUrl[2] === 'verify-request-plm' || splitUrl[2] === 'submit-report-plm') && permissionsData.fastStatus !== null && (permissionsData.fastStatus.toLocaleLowerCase() === 'disabled' || permissionsData.fastStatus.toLocaleLowerCase() === 'norole')) {
                    localStorage.setItem('isaccessPlm', '0');
                    localStorage.setItem('fromplm', '1');
                    localStorage.setItem('userRolesPermissionspml', JSON.stringify(permissionsData));
                    this.router.navigate(['/unauthorized']);
                } else if (permissionsData.fastStatus !== null && (permissionsData.fastStatus.toLocaleLowerCase() === 'active' || permissionsData.fastStatus.toLocaleLowerCase() === 'activenew' || permissionsData.fastStatus.toLocaleLowerCase() === 'norole')) {
                    this.userRolesPermissions = permissionsData as UserRolesAndPermissions;
                    localStorage.setItem('userRolesPermissions', JSON.stringify(this.userRolesPermissions));
                    this.extractRlesPermissions(this.userRolesPermissions);
                    this.extractRlesPermissionsByPermissionId(this.userRolesPermissions);
                } else {
                    localStorage.setItem('isaccessPlm', '0');
                    localStorage.setItem('fromplm', '0');
                    this.router.navigate(['/unauthorized']);
                }
            }, (error) => {
                this.router.navigate(['/unauthorized']);
            });

    }
    /* istanbul ignore next */
    extractRlesPermissions(apiPermissionsData: UserRolesAndPermissions) {
        if (apiPermissionsData) {
            if (apiPermissionsData.roles) {
                this.roles.splice(0, this.roles.length);
            }
            this.permissions.splice(0, this.permissions.length);

            _.forEach(apiPermissionsData.roles, (role: Role) => {
                this.roles.push(role.roleName);

                _.forEach(role.permissions, (permision: Permission) => {
                    this.permissions.push(permision.permissionDesc);
                });
            });

            this.rolesString = this.roles.join(',');
            localStorage.setItem('roles', this.rolesString);
            this.permissonsString = this.permissions.join(',');
            localStorage.setItem('permissons', this.permissonsString);
            this.authorizationDataService.setRolesData(this.permissions);
        }
    }
    /* istanbul ignore next */
    extractRlesPermissionsByPermissionId(apiPermissionsData: UserRolesAndPermissions) {
        if (apiPermissionsData) {
            if (apiPermissionsData.roles) {
                this.roles.splice(0, this.roles.length);
            }
            this.permissionsIds.splice(0, this.permissionsIds.length);

            _.forEach(apiPermissionsData.roles, (role: Role) => {
                this.roles.push(role.roleName);

                _.forEach(role.permissions, (permision: Permission) => {
                    this.permissionsIds.push(permision.permissionId.toString());
                });
            });

            this.rolesString = this.roles.join(',');
            localStorage.setItem('roles', this.rolesString);
            this.permissonIdsString = this.permissionsIds.join(',');
            localStorage.setItem('permissonsIds', this.permissonIdsString);
            this.authorizationDataService.setRolesDataByPermissionId(this.permissionsIds);
        }
    }
    /* istanbul ignore next */
    hasPermissionLoadByPermissionId(currentPermissions: string, allPermissions: any[]) {
        if (allPermissions) {
            if (currentPermissions) {
                // tslint:disable-next-line: prefer-const
                let permissionList = currentPermissions.split(',');
                if (allPermissions && allPermissions.find(permission => {
                    return permissionList.indexOf(permission) > -1;
                })) {
                    return true;
                }
                return false;

            } else {
                return true;
            }
        } else {
            return false;
        }
    }
}
