import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { PROPERTY_COLUMNS_VIEW, PROPERTY_COLUMNS, DOSAGE_DETAILS_COLUMNS, SERVING_DETAILS_COLUMNS } from 'src/app/common/models/PureFnModel';
import { requestFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import {
  COMPOSITION_COLUMNS_EDIT,
  COMPOSITION_COLUMNS_EDIT_NO,
  DOSAGE_COMPONENT,
  SUMMARY_MULTI_COMPONENT,
  FRAGRANCE_COLUMNS, FRAGRANCE_REGIONAL_COLUMNS2, FRAGRANCE_REGIONAL_COLUMNS3, FRAGRANCE_EXCEL_COLUMNS, PROJECT_COLUMNS
} from 'src/app/common/models/tableColumns';
import { DataService } from 'src/app/common/services/data.service';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from 'src/environments/environment';
import { NgForm } from '@angular/forms';
import { AssessRequestsComponent } from '../assess-requests.component';
import { AssessmentRequestService } from '../assess-requests.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { I } from '@angular/cdk/keycodes';
@Component({
  selector: 'app-submit-request',
  templateUrl: './submit-request.component.html',
  styleUrls: ['./submit-request.component.scss']
})
export class SubmitRequestComponent implements OnInit {
  @ViewChild('ProjectForm') ProjectForm: NgForm;
  assessRequestObj: AssessRequestsComponent;
  name = 'Angular ';
  editor = ClassicEditor;
  isEditProject = false;
  isSaveaddBtclick = true;
  isNextBtnEnabled = false;
  isSaveBtnClicked = true;
  isCommentDialogOpen = false;
  selectedStep;
  displayMarkets;
  formAssess;
  requestId;
  loading = false;
  confirmSubmitDialog = false;
  updatePopUpFlag = false;
  projtypeothoptionflg = false;
  brandothoptionflg = false;
  mansiteothoptionflg = false;
  cancelClicked = false;
  GoToWorklistConfirmation = false;
  isPopupyesClicked = false;
  star = 'fa fa-fw fa-search';
  keydatetodisplay = '';
  validityareatodisplay = '';
  readOnlyValue = true;
  cols = [];
  fragranceDetailsColumns = [];
  tempManuSite = [];
  fragranceComplianceLevel2Column = [];
  fragranceComplianceLevel3Column = [];
  compositionDetailsColumns = [];
  compositionData = [];
  productData = [];
  regionalComplianceData = [];
  regionalComplianceLevel2Data = [];
  regionalComplianceLevel3Data = [];
  ReqId = '';
  MarketIds = '';
  updId;
  functionListVals = [];
  filteredFunctionListVals = [];
  functionVal: any;
  responsedataUOM = [];
  requestResponse;
  linkchkflg;
  createRequestFlag;
  viewRequestFlag;
  editbtnclickedFlag;
  editRequestFlag;
  selectedUom;
  loadingMessage;
  multicomponentValue;
  dosageDetailsColumns = [];
  dosageDetailsData = [];
  multicomponentDatacolumns = [];
  multicomponentData = [];
  selectedMultiComponents = [];
  editMultiComponents = [];
  sortedCompositionData = [];
  hideDosageServicePanel = false;
  flagmancountryselected = true;
  isDosage: boolean;
  showRequestSubmittedDialog = false;
  displayReqIds: any;
  calculatedRecipeQty;
  infraData = [];
  regionalData2 = [];
  regionalData3 = [];
  fragranceMultipleArrayExcelRowsData = [];
  fragranceMultipleArrayExcelColumns = [];
  projectDetailsColumnssub = [];
  isManufacturesiteDialogOpen = false;
  showFragranceMenu: boolean;
  isFileNameAmendDate: any;
  fragranceMessage = '';
  ankerCount: any;
  ProjDetail: any = {
    RequestId: '',
    Market: null,
    ProjectName: '',
    ProjectDescription: '',
    ProjectType: [],
    Brand: [],
    OtherBrandDescription: '',
    OtherProjectDescription: '',
    otherManufacturingSiteDescription: '',
    LaunchTimeLine: Date,
    ManufactureSite: [],
    ManufactureCountry: {},
    comments: ''
  };
  userId: any;
  ProjDetailList = [];
  date: any;
  brandType = [];
  manufatureCoutryData = [];
  manufatureSiteData = [];
  projectType = [];
  editMarkteId: any;
  isDialogOpen = false;
  isprojectTypeDialogOpen = false;
  responsedataMarket;
  editflg = false;
  todayDate;
  ProjDetailList1: any = [];
  tableComment: any;
  config = {
    toolbar: [
      'link'
    ],
    placeholder: 'Please enter Comment',
    link: {
      addTargetToExternalLinks: true,
      decorators: [
        {
          mode: '',
          label: '',
          attributes: {
            target: '_blank',
          }
        }
      ]
    }
  };
  servingDetailsColumns = [];
  sheetTitle = '';
  constructor(
    public assessService: AssessmentRequestService,
    private dataTrans: DataService,
    public matSnackBar: MatSnackBar,
    private routersvc: Router,
    public worklistservice: WorklistService,
    public route: ActivatedRoute,
    private datePipe: DatePipe
  ) { }
  /* istanbul ignore next */
  ngOnInit() {
    this.editbtnclickedFlag = false;
    this.userId = JSON.parse(localStorage.getItem('userRolesPermissions')).userId;
    this.getfragranceFlag();
    this.linkchkflg = true;
    this.projtypeothoptionflg = false;
    this.brandothoptionflg = false;
    this.mansiteothoptionflg = false;
    this.todayDate = new Date();
    this.projectDetailsColumnssub = JSON.parse(JSON.stringify(this.removeObjectWithfield(PROJECT_COLUMNS, 'Actions')));
    this.createRequestFlag = requestFlags.createRequest;
    this.viewRequestFlag = requestFlags.viewRequest;
    this.editRequestFlag = requestFlags.EditRequest;
    this.formAssess = JSON.parse(localStorage.getItem('FormObj'));
    this.infraData = this.formAssess.ifraComplianceModelList;
    this.regionalData2 = this.formAssess.regionalComplianceLevel2ModelList;
    this.regionalData3 = this.formAssess.regionalComplianceLevel3ModelList;
    this.fragranceMultipleArrayExcelRowsData.push(this.infraData);
    this.fragranceMultipleArrayExcelRowsData.push(this.regionalData2);
    this.fragranceMultipleArrayExcelRowsData.push(this.regionalData3);
    this.showFragranceMessage(this.infraData, this.regionalData2, this.regionalData3);
    this.getMarketData();
    this.getBrandData();
    this.getProjectTypeData();
    this.getManufatureCountry();
    // added below code for appending date or request number to Download excel file
    this.isFileNameAmendDate = 'No';
    this.isFileNameAmendDate = this.createRequestFlag === true ? 'Yes' : 'No';
    this.keydatetodisplay = this.formAssess.plmkeydate;
    if (this.formAssess.plmvalidityarea.validityDescription === undefined) {
      this.validityareatodisplay = 'Default Validity Area';
    } else {
      this.validityareatodisplay = this.formAssess.plmvalidityarea.validityDescription;
    }
    /* istanbul ignore if */
    if (this.createRequestFlag === false) {
      this.worklistservice.getRecipeData().subscribe((val: any) => {
        /* istanbul ignore if */
        if (val !== null) {
          recipeModel.requestDisplayID = val.requestID;
        }
      });
    }
    // ends
    /* istanbul ignore if */
    if (this.formAssess.division === 'BPC') {
      this.hideDosageServicePanel = true;
    } else if (this.formAssess.division === 'F&R') {
      this.hideDosageServicePanel = false;
      this.sheetTitle = 'Serving Details';
      this.isDosage = false;
    } else if (this.formAssess.division === 'HC') {
      this.hideDosageServicePanel = false;
      this.sheetTitle = 'Dosage Details';
      this.isDosage = true;
    }
    /* istanbul ignore if */
    if (this.editRequestFlag) {
      this.getUOMData();
      this.getfunctions();
    }
    this.formAssess.division === 'F&R' ? this.cols = PROPERTY_COLUMNS_VIEW : this.cols = PROPERTY_COLUMNS;
    this.formAssess.division === 'HC' ? this.servingDetailsColumns = DOSAGE_DETAILS_COLUMNS : this.servingDetailsColumns = SERVING_DETAILS_COLUMNS;
    this.compositionDetailsColumns = COMPOSITION_COLUMNS_EDIT;
    this.fragranceDetailsColumns = FRAGRANCE_COLUMNS;
    this.fragranceComplianceLevel2Column = FRAGRANCE_REGIONAL_COLUMNS2;
    this.fragranceComplianceLevel3Column = FRAGRANCE_REGIONAL_COLUMNS3;
    this.fragranceMultipleArrayExcelColumns = FRAGRANCE_EXCEL_COLUMNS;
    this.dosageDetailsData = this.formAssess.dosageForMarkets;
    this.ProjDetailList = this.formAssess.projectdetailData;
    /* istanbul ignore next */
    this.multicomponentDatacolumns = SUMMARY_MULTI_COMPONENT;
    this.multicomponentData = this.formAssess.multiComponent;
    this.multicomponentValue = (this.formAssess.isMultiComponent === 'Y') ? 'Yes' : 'No';
    /* istanbul ignore if */
    if (this.formAssess.requestStatus === 'SentBack') {
      const obj = {
        field: 'raComment',
        header: 'RA Comment',
        width: '130px',
      };
      const objRD = {
        field: 'originatorComment',
        header: 'R&D Comment',
        width: '130px',
        editable: true,
      };
      if (this.compositionDetailsColumns[this.compositionDetailsColumns.length - 1].header === 'R&D Comment' && this.compositionDetailsColumns[this.compositionDetailsColumns.length - 2].header !== 'RA Comment') {
        this.compositionDetailsColumns.pop();
      }
      if (this.compositionDetailsColumns[this.compositionDetailsColumns.length - 2].header !== 'RA Comment' && this.compositionDetailsColumns[this.compositionDetailsColumns.length - 1].header !== 'R&D Comment') {
        this.compositionDetailsColumns.push(obj);
        this.compositionDetailsColumns.push(objRD);
      }
    }
    this.dataTrans.compositiondatapreview.subscribe((values) => {
      this.compositionData = values;
      for (const value of this.compositionData) {
        if (value.substanceFunctionInput === null) {
          value.substanceFunctionInput = 'Any';
        }
      }
      this.calculatedRecipeQty = _.sumBy(this.compositionData, 'recipeQuantity');
      this.calculatedRecipeQty = _.round(this.calculatedRecipeQty, 12);
    });
    this.dataTrans.propertydatapreview.subscribe((values: any) => {
      this.productData = values;
      for (let i = 0; i < values.length; i++) {
        if (values[i].propertyMaxQuantity !== null) {
          this.productData[i].propertyMaxQuantity = Number(values[i].propertyMaxQuantity.replace(/,/g, ''));
        }
        if (values[i].propertyMinQuantity !== null) {
          this.productData[i].propertyMinQuantity = Number(values[i].propertyMinQuantity.replace(/,/g, ''));
        }
        if (values[i].propertyTargetQuantity !== null) {
          this.productData[i].propertyTargetQuantity = Number(values[i].propertyTargetQuantity.replace(/,/g, ''));
        }
      }
    });
    if (this.formAssess.isMultiComponent === 'Y') {
      this.compositionDetailsColumns = COMPOSITION_COLUMNS_EDIT;
    } else {
      this.compositionDetailsColumns = COMPOSITION_COLUMNS_EDIT_NO;
    }
    if (!this.createRequestFlag) {
      this.SetOriginalRecipeQty(this.formAssess.isMultiComponent, false);
    }
    /* istanbul ignore if */
    if (this.formAssess.compositionData !== undefined) {
      this.compositionData = this.formAssess.compositionData;
      for (const value of this.compositionData) {
        if (value.substanceFunctionInput === null) {
          value.substanceFunctionInput = 'Any';
        }
      }
      this.calculatedRecipeQty = _.sumBy(this.compositionData, 'recipeQuantity');
      this.calculatedRecipeQty = _.round(this.calculatedRecipeQty, 12);
    } else {
      this.dataTrans.multiComponentdatapreview.subscribe((values) => {
        this.editMultiComponents = values;
      });
      if (this.formAssess.isMultiComponent === 'Y') {
        this.sortCompositionData();
      }
    }
  }
  removeObjectWithfield(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.field === id);
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
    return arr;
  }
  // tslint:disable-next-line: member-ordering
  step = 1;
  setStep(index: number) {
    this.step = index;
  }
  sortCompositionData() {
    this.sortedCompositionData = [];
    this.selectedMultiComponents = [];
    this.editMultiComponents.forEach(item => {
      this.compositionData.forEach(composition => {
        if (item.multiComponentIngredientName === composition.specID && item.multiComponentName === composition.multiComponentName) {
          composition.componentSet = item.multiComponentName;
        }
      });
    });
    this.editMultiComponents.forEach(item => {
      const filterCom = this.selectedMultiComponents.filter(com => com.component === item.multiComponentName);
      if (filterCom.length === 0) {
        this.selectedMultiComponents.push({
          specID: item.multiComponentIngredientName,
          component: item.multiComponentName
        });
      }
    });

    this.selectedMultiComponents.forEach(component => {
      this.sortedCompositionData.push({
        show: true,
        composition: true,
        specID: component.component,
        specDescription: '',
        specQuantity: '',
        specUoM: '',
        ingredientPath: '',
        ingDescription: '',
        ingQuantity: '',
        pureCode: '',
        pureDescription: '',
        recipeQuantityToDisplay: '',
        recipeUoM: '',
        substanceFunctionInput: '',
        rowValue: 0
      });
      this.compositionData.forEach(composition => {
        if (component.component === composition.componentSet) {
          this.sortedCompositionData.push(composition);
        }
      });
    });
    this.compositionData = this.sortedCompositionData;
    for (const value of this.compositionData) {
      if (value.substanceFunctionInput === null) {
        value.substanceFunctionInput = 'Any';
      }
    }
  }
  /* istanbul ignore next */
  submitDialog() {
    this.confirmSubmitDialog = true;
  }
  onUpdateBtnClick() {
    this.updatePopUpFlag = true;
  }
  /* istanbul ignore next */
  onPreviousClicked() {
    this.dataTrans.setSelectedStep(1);
    localStorage.setItem('selectedStepprev', '1');
    this.routersvc.navigate(['assess-requests/create-request']);
  }
  onSubmit() {
    this.loadingMessage = 'Submitting Request Details';
    recipeModel.markets = this.formAssess.market;
    recipeModel.dosage = this.formAssess.dosage;
    recipeModel.dosageUnit = this.formAssess.dosageUom;
    recipeModel.servingSize = this.formAssess.servingSize;
    recipeModel.requestComments = this.formAssess.comments;
    recipeModel.intendedUse = this.formAssess.intendedUse;
    recipeModel.plmvalidityarea = this.validityareatodisplay;
    recipeModel.keyDate = this.formAssess.plmkeydate;
    recipeModel.regionalComplianceLevel2ModelList = this.formAssess.regionalComplianceLevel2ModelList;
    recipeModel.regionalComplianceLevel3ModelList = this.formAssess.regionalComplianceLevel3ModelList;
    const data = recipeModel;
    data.dosageForMarkets = this.formAssess.dosageForMarkets;
    data.isMultiComponent = this.formAssess.isMultiComponent === undefined ? 'N' : this.formAssess.isMultiComponent;
    data.multiComponent = this.formAssess.isMultiComponent === 'Y' ? this.formAssess.multiComponent : null;
    this.SetOriginalRecipeQty(data.isMultiComponent, this.createRequestFlag);
    data.formulationRequestDetails = this.formAssess.formulationRequestDetails;
    data.markets = this.formAssess.projectdetailData;
    /* istanbul ignore if */
    if (data.multiComponent !== null) {
      data.formulationRequestDetails.forEach((item, index) => {
        data.multiComponent.forEach(component => {
          if (item.specID === component.multiComponentIngredientName && item.rowValue === component.multiRowValue) {
            item['multiComponentID'] = component.multiComponentId;
            item['multiComponentName'] = component.multiComponentName;
          }
        });
      });
    }
    data.formulationRequestDetails.forEach((item, index) => {
      item.rndInputFunction = item.substanceFunctionInput;
      if (isNaN(item.specID)) {
        data.formulationRequestDetails.splice(index, 1);
      }
    });

    this.loading = true;
    let responseResult = '';
    const apiurl = environment.APIFAsT + '/executionrequest';
    this.assessService.postData(apiurl, data).subscribe((response) => {
      this.loading = false;
      this.requestId = response;
      /* istanbul ignore if */
      // CG- adding code
      if (response.message.indexOf('~') !== -1) {
        responseResult = response.message.split('~')[0].trim();
        this.ReqId = response.message.split('~')[1].trim();
        for (const v of recipeModel.markets) {
          this.MarketIds = this.MarketIds + ',' + v.marketId;
        }
        this.updId = recipeModel.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId;
        this.sendResponseEmail('REQUESTRAISED', this.ReqId, this.MarketIds.substring(1, this.MarketIds.length), this.updId, '', '', this.formAssess.description);
      } else {
        responseResult = response.message;
      }
      // Ends CG-code
      if (response.message.indexOf('[REQ') !== -1) {
        const reqId = response.message.split('[');
        for (const value of reqId) {
          if (value.startsWith('REQ')) {
            if (value.trim().endsWith('] For')) {
              const newIds = value.trim().replace('] For', '');
              this.displayReqIds = this.displayReqIds ? this.displayReqIds + ',' + newIds : newIds;
            } else if (value.endsWith(']')) {
              const newIds = value.trim().replace(']', '');
              this.displayReqIds = this.displayReqIds ? this.displayReqIds + ',' + newIds : newIds;
            }
          }
        }
      }

      this.showRequestSubmittedDialog = true;
    }, (error) => {
      this.loading = false;
    });
  }
  /* istanbul ignore next */
  onCloseDialog() {
    this.routersvc.navigate(['/worklist/master-worklist']);
    localStorage.removeItem('FormObj');
  }
  /* istanbul ignore next */
  onUpdate() {
    this.loadingMessage = 'Updating Request Details';
    const apiurl = environment.APIFAsT + '/executionrequest/' + this.formAssess.requestDisplayID;
    this.loading = true;
    this.assessService.getData(apiurl).subscribe((response) => {
      if (response !== null) {
        this.requestResponse = response;
        if (this.requestResponse !== null) {
          this.SetOriginalRecipeQty(this.formAssess.isMultiComponent, this.editRequestFlag);
          this.requestResponse.formulationRequestDetails = this.compositionData;
          this.requestResponse.productPropertiesInputs = this.productData;
          this.requestResponse.formulationRequestDetails.forEach((item, index) => {
            item.rndInputFunction = item.substanceFunctionInput;
            if (isNaN(item.specID)) {
              this.requestResponse.formulationRequestDetails.splice(index, 1);
            }
          });
          this.requestResponse.dosage = this.formAssess.dosage;
          this.requestResponse.servingSize = this.formAssess.servingSize;
          this.requestResponse.dosageUnit = (this.selectedUom) ? this.selectedUom.uoMsymbol : '';
          this.requestResponse.requestComments = this.formAssess.comments;
          // CG - added
          if (this.formAssess.dosageForMarkets !== null) {
            this.formAssess.dosageForMarkets[0].dosage = this.formAssess.dosage;
            this.formAssess.dosageForMarkets[0].servingSize = this.formAssess.servingSize;
            this.formAssess.dosageForMarkets[0].dosageUnit = (this.selectedUom) ? this.selectedUom.uoMsymbol : '';
            this.requestResponse.dosageForMarkets = this.formAssess.dosageForMarkets;
          }
          if (this.ProjDetailList !== null) {
            this.requestResponse.marketDetails = this.ProjDetailList[0];
          }
          // CG- ends
          this.loading = true;
          const url = environment.APIFAsT + '/assessmentrequestmarket';
          this.assessService.putData(url, this.requestResponse).subscribe((response12) => {
            const updid = this.formAssess.tfcList.parentTFC.parentTFC.parentTFC.parentTFC.tfcId;
            if (this.formAssess.requestStatus === 'Submitted' || this.formAssess.requestStatus === 'Re-Submitted') {
              let rauserids = this.formAssess.raUserId;
              if (this.formAssess.raUserId === null || this.formAssess.raUserId === 0) {
                rauserids = 'All';
              }
              this.sendResponseEmail('UPDATEREQUEST', this.formAssess.requestDisplayID, this.formAssess.market.marketId, updid, rauserids, this.formAssess.createdByUserId, this.formAssess.description);
            }
            if (this.formAssess.requestStatus === 'SentBack') {
              const requestStatus = '105';
              const statusUpdateApiUrl = environment.APIFAsT + '/assessment/request/' + this.formAssess.requestDisplayID + '/status/' + requestStatus + '/comment/SystemGenerated';
              this.assessService.putData(statusUpdateApiUrl, null).subscribe((statusresponse) => {
                if (statusresponse.message !== null) {
                  this.sendResponseEmail('UPDATEREQUEST', this.formAssess.requestDisplayID, this.formAssess.market.marketId, updid, this.formAssess.raUserId, this.formAssess.createdByUserId, this.formAssess.description);
                  this.goToWorklistBtnClick();
                  this.loading = false;
                }
              }, (error) => {
                this.loading = false;
              });
            } else {
              this.goToWorklistBtnClick();
              this.loading = false;
            }
          }, (error) => {
            this.loading = false;
          });
        }
      }
    }, (error) => {
      this.loading = false;
    });

  }
  // CG - Send response email to respective RA/R&D users.
  /* istanbul ignore next */
  sendResponseEmail(notificationGroup, displayRequestId, marketId, updid, toemaillist, ccemaillist, cucDescription) {
    const data = {
      NotificationGroup: notificationGroup,
      Subject: null,
      EmailBody: null,
      ToEmailList: toemaillist,
      FromEmail: null,
      CCEmailList: ccemaillist,
      EmailType: 'html',
      UPD: updid,
      PureCodes: null,
      RequestID: displayRequestId,
      MarketId: marketId,
      CUCDescription: cucDescription
    };
    const apiurl = environment.APIFAsT + '/responseemail';
    this.assessService.postData(apiurl, data).subscribe((response) => {
      this.loading = false;
      if (response.result === 'Success') {
        console.log(response.result);
      }
    }, (error) => {
      this.loading = false;
    });
  }
  test(event, mc) {
    this.ProjDetail.LaunchTimeLine = mc.inputFieldValue;
  }
  /* istanbul ignore next */
  onEdit() {
    requestFlags.EditRequest = true;
    this.dataTrans.setSelectedStep(1);
    this.editProjectDeails(this.formAssess.projectdetailData[0]);
    this.isEditProject = true;
  }
  /* istanbul ignore next */
  onCancelClicked() {
    localStorage.removeItem('FormObj');
    Object.assign({}, this.formAssess);
    this.dataTrans.setSelectedStep(0);
    this.routersvc.navigate(['assess-requests/select-recipe']);
  }
  /* istanbul ignore next */
  goToWorklistBtnClick() {
    localStorage.removeItem('FormObj');
    this.routersvc.navigate(['/worklist/master-worklist']);
  }
  getfunctions() {
    const apiurl = environment.APIFAsT + '/functions';
    this.assessService.getData(apiurl).subscribe((response) => {
      this.functionListVals = response;
      const fnValue = { functionName: 'Any' };
      this.functionListVals.push(fnValue);
    }, (error) => {
    });
  }
  /* istanbul ignore next */
  getUOMData() {
    const apiurl = environment.APIFAsT + '/uoms';
    this.assessService.getData(apiurl).subscribe((response1) => {
      this.responsedataUOM = response1;
      if (this.formAssess.dosageUom === '' || this.formAssess.dosageUom === null) {
        this.selectedUom = null;
      } else {
        this.responsedataUOM.forEach(x => {
          if (x.uoMsymbol === this.formAssess.dosageUom) {
            this.selectedUom = x;
          }

        });
      }

    }, (error) => {
    });
  }
  searchFnList(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (const value of this.functionListVals) {
      this.functionVal = value.functionName;
      if (this.functionVal.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(this.functionVal);
      }
    }
    this.filteredFunctionListVals = filtered;
  }
  addComments(val, index) {
    setTimeout(() => {
      if (val) {
        this.compositionData[index].originatorComment = val.target.value;
      }
    }, 500);
  }
  addProdComments(val, index) {
    setTimeout(() => {
      if (val) {
        this.productData[index].originatorComment = val.target.value;
      }
    }, 500);
  }
  /* istanbul ignore next */
  SetOriginalRecipeQty(multicomponentype, requestmode) {
    if (multicomponentype === 'N' && requestmode === true) {
      for (const value of this.compositionData) {
        value.originalRecipeQuantity = value.recipeQuantity;
      }
    } else if (multicomponentype === 'Y') {
      for (const val of this.compositionData) {
        const tempOriginalRecpQty = val.originalRecipeQuantity;
        val.originalRecipeQuantity = val.recipeQuantity;
        val.recipeQuantity = tempOriginalRecpQty;
        val.recipeQuantityToDisplay = tempOriginalRecpQty;
      }
    }
    this.formAssess.formulationRequestDetails = this.compositionData;
  }
  getfragranceFlag() {
    const apiurl = environment.APIFAsT + '/appinfo/featureflagfragrance';
    this.assessService.getData(apiurl).subscribe((res) => {
      if (res.FeatureFlagFragrance) {
        this.showFragranceMenu = res.FeatureFlagFragrance.toLocaleLowerCase().replace('"', '').replace('"', '') === 'true' ? true : false;
      }
    });
  }

  showFragranceMessage(infraData, regionalData2, regionalData3) {
    if (infraData !== undefined && infraData != null && regionalData2 !== undefined && regionalData3 !== undefined) {
      if (infraData.length === 0 && regionalData2.length === 0 && regionalData3.length === 0) {
        this.fragranceMessage = localStorage.getItem('FragranceMessage');
      } else {
        this.fragranceMessage = '';
      }
    }
  }
  getMarketData() {
    const apiurl = environment.APIFAsT + '/markets';
    this.assessService.getData(apiurl).subscribe((response1) => {
      this.responsedataMarket = response1;
    }, (error) => {
    });
  }
  getBrandData() {
    const apiurl = environment.APIFAsT + '/getbrand/division/' + this.formAssess.division;
    this.assessService.getData(apiurl).subscribe((response) => {
      this.brandType = response;
    });
  }
  getProjectTypeData() {
    const apiurl = environment.APIFAsT + '/getprojecttypes/division/' + this.formAssess.division;
    this.assessService.getData(apiurl).subscribe((res) => {
      this.projectType = res;
    });
  }
  /* istanbul ignore next */
  addprojectRequest(data) {
    const strsitecode = [];
    const strsitedesc = [];
    const today = new Date();
    data.ManufactureSite.forEach(e => {
      strsitecode.push(e.plmplantCode);
      if (e.plmplantCode === '99') {
        strsitedesc.push(data.otherManufacturingSiteDescription);
      } else {
        strsitedesc.push(e.plmplantDesc);
      }
    });
    data.ManufactureSite.forEach((value, index) => {
      if (value.plmplantCode === '99') {
        data.ManufactureSite[index].othersitedesc = data.otherManufacturingSiteDescription;
      } else {
        data.ManufactureSite[index].othersitedesc = value.plmplantDesc;
      }
    });
    const req = {
      marketId: data.Market.marketId,
      marketName: data.Market.marketName,
      clusterId: null,
      clusterName: null,
      marketFullText: null,
      parentMarket: null,
      marketLevel: null,
      isCluster: false,
      projDetail: {
        RequestId: data.Market.marketId,
        marketId: data.Market.marketId,
        projectName: data.ProjectName,
        projectDescription: data.ProjectDescription,
        projectTypeCd: data.ProjectType.projTypeCd,
        projectTypeDesc: data.ProjectType.projTypeCd !== '99' ? data.ProjectType.projTypeDesc : data.OtherProjectDescription,
        brandCd: data.Brand !== undefined ? data.Brand.brandCd : '',
        brandDesc: data.Brand.brandCd !== '99' ? data.Brand.brandDesc : data.OtherBrandDescription,
        launchTimeLine: data.LaunchTimeLine !== null && data.LaunchTimeLine !== undefined && data.LaunchTimeLine !== '' ? this.convertToYYYMMDD(data.LaunchTimeLine) : '',
        manufactureSiteCd: strsitecode.join(','),
        manufactureSiteDesc: strsitedesc.join(','),
        manufactureCountryDescription: data.ManufactureCountry !== undefined ? data.ManufactureCountry.countryDesc : '',
        manufactureCountryCode: data.ManufactureCountry !== undefined ? data.ManufactureCountry.countryCode : '',
        CreatedDate: today,
        ModifiedDate: today,
        CreatedBy: this.userId,
        ModifiedBy: this.userId,
        manuSite: data.ManufactureSite,
        requestComments: data.comments,
        otherManufacturingSiteDescription: data.otherManufacturingSiteDescription
      }
    };
    return req;
  }

  saveProjectDetails(type) {
    this.flagmancountryselected = true;
    if (this.ProjDetailList.length > 5) {
      this.matSnackBar.open('Only upto 5 markets we cad add in table', 'close', {
        duration: 1000,
        panelClass: ['error']
      });
      return true;
    }
    if (this.ProjDetail.Market) {
      this.ProjDetail.RequestId = this.ProjDetail.Market.marketId;
      // edit details
      this.ProjDetailList = this.removeObjectWithId(this.ProjDetailList, this.editMarkteId);
      this.ProjDetailList.push(JSON.parse(JSON.stringify(this.addprojectRequest(this.ProjDetail))));
      this.editMarkteId = '';
      this.matSnackBar.open('Project Details Updated Successfully', 'close', {
        duration: 1000,
        panelClass: ['success']
      });
    }
    this.isNextBtnEnabled = false;
    this.isSaveBtnClicked = false;
    this.projtypeothoptionflg = false;
    this.brandothoptionflg = false;
    this.mansiteothoptionflg = false;
    this.formAssess.projectdetailData = this.ProjDetailList;
    localStorage.setItem('FormObj', JSON.stringify(this.formAssess));
    this.editflg = false;
    this.ProjDetail = {
      RequestId: '',
      Market: null,
      ProjectName: '',
      ProjectDescription: '',
      ProjectType: [],
      Brand: [],
      OtherBrandDescription: '',
      OtherProjectDescription: '',
      otherManufacturingSiteDescription: '',
      LaunchTimeLine: '',
      ManufactureSite: [],
      ManufactureCountry: {},
      comments: ''
    };
    this.isEditProject = false;
    this.editbtnclickedFlag = false;
  }
  editProjectDeails(row) {
    let othesitedetail = '';
    row.projDetail.manuSite.forEach((e, i) => {
      if (e.plmplantCode === '99') {
        this.mansiteothoptionflg = true;
        row.projDetail.manuSite[i].othersitedesc = 'Other';
        othesitedetail = row.projDetail.manufactureSiteDesc.split(',')[i];
      }
    });
    this.editflg = true;
    const setdetail = {
      RequestId: '',
      Market: this.responsedataMarket.find(x => x.marketId === row.marketId),
      ProjectName: row.projDetail.projectName,
      ProjectDescription: row.projDetail.projectDescription,
      OtherProjectDescription: row.projDetail.projectTypeCd === '99' ? row.projDetail.projectTypeDesc : '',
      OtherBrandDescription: row.projDetail.brandCd === '99' ? row.projDetail.brandDesc : '',
      ProjectType: row.projDetail.projectTypeCd ? this.projectType.find(x => x.projTypeCd === row.projDetail.projectTypeCd) : [],
      Brand: row.projDetail.brandCd ? this.brandType.find(x => x.brandCd === row.projDetail.brandCd) : [],
      LaunchTimeLine: row.projDetail.launchTimeLine !== '' ? new Date(row.projDetail.launchTimeLine) : '',
      ManufactureCountry: row.projDetail.manufactureCountryCode ? this.manufatureCoutryData.find(x => x.countryCode === row.projDetail.manufactureCountryCode) : [],
      comments: row.projDetail.requestComments,
      ManufactureSite: row.projDetail.manuSite,
      otherManufacturingSiteDescription: othesitedetail
    };
    if (row.projDetail.projectTypeCd === '99') {
      this.projtypeothoptionflg = true;
    } else {
      this.projtypeothoptionflg = false;
    }
    if (row.projDetail.brandCd === '99') {
      this.brandothoptionflg = true;
    } else {
      this.brandothoptionflg = false;
    }
    this.editMarkteId = row.marketId;
    this.ProjDetail = JSON.parse(JSON.stringify(setdetail));
    this.ProjDetail.LaunchTimeLine = row.projDetail.launchTimeLine !== '' ? new Date(row.projDetail.launchTimeLine) : '';
    if (row.projDetail.manufactureCountryCode) {
      this.getManufatureSite();
    }
    this.isNextBtnEnabled = true;
    this.isSaveBtnClicked = false;
    this.isSaveaddBtclick = true;
    this.editbtnclickedFlag = true;
  }

  onDropdownClick(event) {
    if (this.ProjDetail.Brand.brandCd === '99') {
      this.isDialogOpen = true;
      this.brandothoptionflg = true;
    } else {
      this.brandothoptionflg = false;
    }
  }
  onYesClick() {
    this.isDialogOpen = false;
    this.isprojectTypeDialogOpen = false;
    this.isManufacturesiteDialogOpen = false;
    this.isPopupyesClicked = true;
  }
  onProjTypeDropdownClick(event) {
    if (this.ProjDetail.ProjectType.projTypeCd === '99') {
      this.isprojectTypeDialogOpen = true;
      this.projtypeothoptionflg = true;
    } else {
      this.projtypeothoptionflg = false;
    }
  }
  /* istanbul ignore next */
  onManufacturesiteChange(event) {
    if (event.itemValue.plmplantCode === '99') {
      if (event.value.some(e => e.plmplantCode === '99')) {
        this.isManufacturesiteDialogOpen = true;
        this.mansiteothoptionflg = true;
      } else {
        this.mansiteothoptionflg = false;
      }
    }
  }
  onBrandNoClick() {
    this.isDialogOpen = false;
    if (this.ProjDetail.OtherBrandDescription === '') {
      this.ProjDetail.Brand = '';
      this.brandothoptionflg = false;
    }
  }
  onBrandYesClick() {
    this.isDialogOpen = false;
    if (this.ProjDetail.OtherBrandDescription === '') {
      this.ProjDetail.OtherBrandDescription = '';
      this.ProjDetail.Brand = '';
      this.brandothoptionflg = false;
    }
  }
  onProjTypeNoClick() {
    this.isprojectTypeDialogOpen = false;
    if (this.ProjDetail.OtherProjectDescription === '') {
      this.ProjDetail.ProjectType = '';
      this.projtypeothoptionflg = false;
    }
  }
  onProjTypeYesClick() {
    this.isprojectTypeDialogOpen = false;
    if (this.ProjDetail.OtherProjectDescription === '') {
      this.ProjDetail.OtherProjectDescription = '';
      this.ProjDetail.ProjectType = '';
      this.projtypeothoptionflg = false;
    }
  }
  onManSiteNoClick() {
    this.isManufacturesiteDialogOpen = false;
    this.resetManuSite();
  }
  resetManuSite() {
    if (this.ProjDetail.otherManufacturingSiteDescription === '') {
      this.tempManuSite = [];
      this.ProjDetail.otherManufacturingSiteDescription = '';
      this.ProjDetail.ManufactureSite.forEach(element => {
        if (element.plmplantCode === '99') {
          const objWithIdIndex = this.ProjDetail.ManufactureSite.findIndex((obj) => obj.plmplantCode === '99');
          this.ProjDetail.ManufactureSite.splice(objWithIdIndex, 1);
          this.mansiteothoptionflg = false;
        } else {
          this.tempManuSite.push({ id: 0, plmplantCode: element.plmplantCode, plmplantDesc: element.plmplantDesc, countryCode: element.countryCode, othersitedesc: element.othersitedesc });
        }
      });
      this.ProjDetail.ManufactureSite = [];
      this.ProjDetail.ManufactureSite = this.tempManuSite;
      this.mansiteothoptionflg = false;
    }
  }
  onManuSiteYesClick() {
    this.isManufacturesiteDialogOpen = false;
    if (this.ProjDetail.otherManufacturingSiteDescription === '') {
      this.resetManuSite();
    }
  }
  saveEnable() {
    const flag = false;
    if (this.isSaveBtnClicked) {
      return true;
    }
    return flag;
  }

  saveAddEnable() {
    const flag = false;
    if (this.isSaveaddBtclick) {
      return true;
    }
    return flag;
  }
  removeObjectWithId(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.marketId === id);
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
    return arr;
  }
  // /* istanbul ignore next */
  deleteProjectTypedata(row) {
    this.editMarkteId = row.marketId;
    this.ProjDetailList = this.removeObjectWithId(this.ProjDetailList, this.editMarkteId);
    this.editMarkteId = '';
    this.matSnackBar.open('project details deleted successfully', 'close', {
      duration: 1000,
      panelClass: ['error']
    });
    this.isNextBtnEnabled = false;
    this.isSaveBtnClicked = false;
    this.isSaveaddBtclick = true;
    this.editMarkteId = '';
  }
  getManufatureCountry() {
    const apiurl = environment.APIFAsT + '/getManufacturingCountry';
    this.assessService.getData(apiurl).subscribe((respData) => {
      this.manufatureCoutryData = respData;
    });
  }
  onManufactureDropdownClick() {
    this.getManufatureSite();
    this.ProjDetail.ManufactureSite = [];
  }
  getManufatureSite() {
    if (this.ProjDetail.ManufactureCountry.countryCode !== undefined || this.ProjDetail.ManufactureCountry.countryCode !== null) {
      this.flagmancountryselected = false;
    } else {
      this.flagmancountryselected = true;
    }
    const apiurl = environment.APIFAsT + '/getmanufacturingsites/countrycode/' + this.ProjDetail.ManufactureCountry.countryCode;
    this.assessService.getData(apiurl).subscribe((resData) => {
      this.manufatureSiteData = resData;
    });
  }
  onmarketChange(event) {
    this.isSaveBtnClicked = false;
    this.isSaveaddBtclick = false;
  }
  setlaunchTime(event, mc) {
    this.ProjDetail.launchTimeLine = mc.inputFieldValue;
  }
  openComment(data) {
    this.isCommentDialogOpen = true;
    this.tableComment = data.replace('<a', '<a target="_blank"');
  }
  onManufacturesiteOthClick() {
    this.isPopupyesClicked = false;
    this.isManufacturesiteDialogOpen = true;
  }
  changeComment(comment) {
    let commentRD = '';
    const strValue = comment;
    const ar = strValue.match(/<a.*?<\/a>/g);
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentRD = 'Click Here';
    } else {
      const hComment = this.handleSpecialCharacters(comment);
      commentRD = hComment.length > 25 ? hComment.substring(0, 25) + '...' : hComment;
    }
    return commentRD;
  }
  maxlenghtCheck(data, count) {
    data = data.replace(/<strong>/gi, '');
    data = data.replace(/<\/strong>/gi, '');
    data = data.replace(/<i>/gi, '');
    data = data.replace(/<\/i>/gi, '');
    this.ankerCount = (data.match(/<a/g) || []).length;
    const dataWithoutHtmlCount = this.handleCommentsSpecialCharacters(data).length;
    const datawithhtmlCount = data.length;
    const diff = datawithhtmlCount - dataWithoutHtmlCount;
    if (count <= dataWithoutHtmlCount) {
      this.ProjDetail.comments = this.ProjDetail.comments.substring(0, count + diff);
      return false;
    }
    if (this.ankerCount > 7) {
      this.linkchkflg = false;
    } else {
      this.linkchkflg = true;
    }
  }

  changedateFormat(launchdate) {
    const d = new Date(launchdate);
    if (!isNaN(d.getTime())) {
      return launchdate;
    }
    const [day, month, year] = launchdate.split('/');
    const dateObj = new Date(+year, +month - 1, +day);
    return dateObj;
  }
  closePopup() {
    this.isCommentDialogOpen = false;
  }
  /* istanbul ignore next */
  convertToYYYMMDD(str) {
    const date = new Date(str);
    const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }
  handleSpecialCharacters(text) {
    return text.replace(/<.*?>/g, ' ').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, ' ').replace(/&amp;/g, '&');
  }
  handleCommentsSpecialCharacters(text) {
    return text.replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, ' ').replace(/&amp;/g, '&');
  }
}

