import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as $ from 'jquery';
import 'jstree';
import * as _ from 'lodash';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { parallelRuleObj, pureRulesDetailsModel } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { sirDetailObj } from 'src/app/common/models/SirModel';
import { DataService } from 'src/app/common/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-market-rpc-sub-based',
  templateUrl: './market-rpc-sub-based.component.html',
  styleUrls: ['./market-rpc-sub-based.component.scss']
})
/* istanbul ignore next */
export class MarketRpcSubBasedComponent implements OnInit {
  cascadeRPC = true;
  loading = false;
  unileverMarketObj = {
    clusterId: null,
    clusterName: null,
    marketFullText: null,
    marketId: '',
    marketName: 'Unilever',
    parentMarket: ''
  };
  rpc = [];  selectedRpcList = [];  marketsToSelect = []; selectedRuleDetails = []; componentsToSelect = []; valuesForComponent = [];
  rpcData; rpcFullData; displayedTFCPath; selectedRuleBucket;
  step = 0; selectedMarket; selectedPureSirDetail; pureSirDetail;  parentRpcParallelRule;
  selectedComponent = '';  selectedRPC = '';
  hideMapRpc = true; readOnlyValue = true;
  @Input() refreshGrid: boolean;
  componentGlobal;
  parallelRuleWorkflow = false; editRuleFlow = false;
  rpcsForEditRulestatus = [];
  rpcdataForTree = []; rpcsForEditRule = []; rpcToSelect = [];
  constructor(
    private dataServiceObj: DataService, private assessRequestRa: AssessRequestRaService, private snackBar: MatSnackBar) { }

  /* istanbul ignore next */
  ngOnInit() {
    this.editRuleFlow = false;
    pureRulesDetailsModel.CascadeRpc = 'Y';
    this.pureSirDetail = sirDetailObj;
    this.dataServiceObj.getComponentNameSvc.subscribe((val) => {
      this.componentGlobal = val;
    });
    this.dataServiceObj.getruleToEdit().subscribe((value) => {
      if (value !== null) {
        this.editRuleFlow = true;
        if (value.markets) {
          this.selectedMarket = value.markets[0].marketName;
          recipeModel.marketDetails = value.markets[0];
        }
        this.parentRpcParallelRule = value.rpcid;
        for (const val of value.rpcs) {
          this.rpcsForEditRule.push(val.rpcFullText);
          this.rpcsForEditRulestatus.push(val.mrpcStatus);
        }
      } else {
        this.editRuleFlow = false;
        this.selectedMarket = recipeModel.marketDetails.marketName;
        pureRulesDetailsModel.Market = recipeModel.marketDetails;
      }
    });

    this.dataServiceObj.getParallelRuleFormValues().subscribe((value) => {
      if (value !== null && this.componentGlobal === 'CreateInsertRule') {
        this.parallelRuleWorkflow = true;
        this.selectedMarket = value.selectedMarket.marketName;
        this.parentRpcParallelRule = value.selectedrpc1.rpcId;
        recipeModel.marketDetails = value.selectedMarket;
        pureRulesDetailsModel.Market = recipeModel.marketDetails;
      } else {
        this.parallelRuleWorkflow = false;
        this.selectedMarket = recipeModel.marketDetails.marketName;
        pureRulesDetailsModel.Market = recipeModel.marketDetails;
      }
    });

    if (pureRulesDetailsModel.Market === undefined) {
      pureRulesDetailsModel.Market = recipeModel.marketDetails;
    }
    this.displayedTFCPath = recipeModel.tfcFullText;
    if (this.parallelRuleWorkflow) {
      this.marketsToSelect.push(recipeModel.marketDetails);
      recipeModel.intendedUse = parallelRuleObj.intendedUseForParallelRule;
      recipeModel.preparationMethod = parallelRuleObj.prepMethodForParallelRule.value;
      this.getRPCForParallelRule();
    } else if (this.editRuleFlow) {
      this.marketsToSelect.push(recipeModel.marketDetails);
      pureRulesDetailsModel.CascadeRpc = 'Y';
    } else {
      this.getFallbackMarkets();
    }
    if (recipeModel.isMultiComponent === 'Y') {
      this.getComponentNames();
    }
    this.dataServiceObj.getRefreshGridVal().subscribe((val) => {
      if (val === true) {
        this.hideMapRpc = false;
        this.mappedRPCToComponent();
      }
    });
  }
  /* istanbul ignore next */
  createRpcTreeForParallelRule(rpcData) {
    $('.tree-div').on('changed.jstree', (evt, data) => {
      $('.tree-div').jstree(true).set_icon(data.node, 'https://jstree.com/tree-icon.png');
    });

    $('.tree-div').on('changed.jstree', (e, unileverRpcDataChanged) => {
    }).jstree({
      plugins: ['changed'],
      checkbox: {
        three_state: false,

      },
      core: {
        check_callback: true,
        data: rpcData,
        themes: {
          icons: false
        }
      },
      types: {
        default: {
          icon: 'src/assets/images/tree.svg'
        }
      },
      icon: 'glyphicon glyphicon-file file-node',
      // tslint:disable-next-line: space-before-function-paren // tslint:disable-next-line: no-shadowed-variable
    }).bind('loaded.jstree', function (event, unileverRpcData123) {
      $(this).jstree('open_all');
      $('#' + rpcData[rpcData.length - 1].id + ' >a').css('color', '#1F36C7');
    });
    // $('.tree-div').jstree('check_node', rpcData[rpcData.length - 1].id);
    $('.tree-div').jstree(true).redraw(true);

  }
  /* istanbul ignore next */
  getRPCForParallelRule() {
    pureRulesDetailsModel.CascadeRpc = 'Y';
    pureRulesDetailsModel.selectedRpcs = [];
    pureRulesDetailsModel.selectedParentRpcs = [];
    this.createRpcTreeForParallelRule(parallelRuleObj.rpcsForParallelRuleData);
    const length = parallelRuleObj.rpcsForParallelRuleData.length;
    pureRulesDetailsModel.selectedRpcs.push(parallelRuleObj.rpcsForParallelRuleData[length - 1]);
    pureRulesDetailsModel.selectedParentRpcs.push(parallelRuleObj.rpcsForParallelRuleData[length - 1]);
  }

  /* istanbul ignore next */
  onMarketChange(filterVal: any) {
    $('.tree-div').jstree('destroy').empty();
    this.getRPCDataBasedOnMarket(filterVal.value.marketName);
    pureRulesDetailsModel.Market = this.marketsToSelect.filter(x => x.marketName === filterVal.value.marketName)[0];
    pureRulesDetailsModel.RpcDetails = this.rpc;
    this.selectedComponent = '';
    this.selectedRPC = '';
    this.valuesForComponent = [];
    this.hideMapRpc = true;
    this.dataServiceObj.setComponentObs(null);
  }

  /* istanbul ignore next */
  createRpcTree(rpcdata) {
    const component = this;
    // tslint:disable-next-line: no-shadowed-variable
    $('.tree-div').on('changed.jstree', (e, rpcdata) => {
      // $('.tree-div').jstree(true).refresh();
      // tslint:disable-next-line: no-var-keyword
      var i; var j; const r = []; let selectedRpcObject; this.selectedRpcList = [];
      for (i = 0, j = rpcdata.selected.length; i < j; i++) {
        r.push(rpcdata.instance.get_node(rpcdata.selected[i]).text);
        selectedRpcObject = rpcdata.instance.get_node(rpcdata.selected[i]).original;
        const index = _.findIndex(this.selectedRpcList, (value) => value.id === selectedRpcObject.id);
        if (index === -1) {
          this.selectedRpcList.push(selectedRpcObject);
        }
      }
      pureRulesDetailsModel.selectedRpcs = this.selectedRpcList;
      this.dataServiceObj.setSelectedRpcValues(pureRulesDetailsModel.selectedRpcs);
      this.getSelectedParentRpcs();
      this.getSelectedLeafRpcs();
    }).jstree({
      plugins: ['changed', 'checkbox'],
      checkbox: {
        three_state: false,
        cascade: 'indeterminate' // up, down, indeterminate values
      },
      core: {
        check_callback: true,
        data: rpcdata,
        themes: {
          icons: false
        }
      },
      types: {
        default: {
          icon: 'src/assets/images/tree.svg'
        }
      },
      icon: 'glyphicon glyphicon-file file-node',

      // tslint:disable-next-line: space-before-function-paren // tslint:disable-next-line: no-shadowed-variable
    }).bind('loaded.jstree', function (event, rpcdata) {
      $(this).jstree('open_all');
    });

    //  $('#search-tree').jstree(true).refresh();
    $('.tree-div').jstree(true).redraw(true);
  }
  /* istanbul ignore next */
  getSelectedParentRpcs() {
    let filteredData = []; let tempValue; const TopParentRpc = [];
    pureRulesDetailsModel.selectedRpcs.forEach((value) => {
      tempValue = value;
      while (true) {
        filteredData = _.filter(pureRulesDetailsModel.selectedRpcs, (rpcVal) => {
          return rpcVal.id === tempValue.parent;
        });
        if (filteredData && filteredData.length > 0) {
          tempValue = filteredData[0];
        } else {
          const index = _.findIndex(TopParentRpc, (val) => {
            return val.id === tempValue.id;
          });
          if (index === -1) {
            TopParentRpc.push(tempValue);
          }
          break;
        }
      }
    });
    pureRulesDetailsModel.selectedParentRpcs = TopParentRpc;
  }
  onCascadeClick() {
    const cascadeRPCFlag = this.cascadeRPC ? 'Y' : 'N';
    pureRulesDetailsModel.CascadeRpc = cascadeRPCFlag;
  }
  getFallbackMarkets() {
    const apiurl = environment.APIFAsT + '/markets/' + recipeModel.marketDetails.marketName + '/assessmentOrder';
    this.assessRequestRa.getData(apiurl).subscribe((data) => {
      this.loading = false;
      if (data !== null) {
        const fallBackMarkets = data;
        for (const value of fallBackMarkets) {
          this.marketsToSelect.push(value);
        }
      } else {
        this.marketsToSelect.push(recipeModel.marketDetails);
      }
      this.getRPCDataBasedOnMarket(this.selectedMarket);
    }, (error) => {
      this.loading = false;
    });
  }
  getComponentNames() {
    let obj: any;
    const data: any = recipeModel.formulationRequestDetails;
    const newSetData = [];
    for (const value of data) {
      if (this.pureSirDetail.pureCode === value.pureCode) {
        obj = {
          multiComponentID: value.multiComponentID,
          multiComponentName: value.multiComponentName
        };
        newSetData.push(obj);
      }
    }
    this.componentsToSelect = Array.from(new Set(newSetData.map(s => s.multiComponentID)))
      .map(id => {
        return {
          multiComponentID: id,
          multiComponentName: newSetData.find(s => s.multiComponentID === id).multiComponentName
        };
      });
  }
  onComponentChange(val: any) {
    this.selectedComponent = val;
    const data = this.componentsToSelect.filter(x => x.multiComponentName === this.selectedComponent)[0];
    this.dataServiceObj.setSelectedMultiComponent(data);
    if (this.selectedComponent.toLowerCase() === localStorage.getItem('AsIsIngredientComponentname').toLowerCase()) {
      this.hideMapRpc = true;
      this.dataServiceObj.setComponentObs(null);
    } else {
      this.hideMapRpc = false;
      this.mappedRPCToComponent();
    }
  }
  mappedRPCToComponent() {
    this.loading = true;
    const data = this.componentsToSelect.filter(x => x.multiComponentName === this.selectedComponent)[0];
    const URL = environment.APIFAsTRule + '/multicomponentMaps/markets/' + pureRulesDetailsModel.Market.marketId + '/multiComponents/' + data.multiComponentID;
    this.assessRequestRa.getData(URL).subscribe((response) => {
      this.valuesForComponent = [];
      if (response !== null && response.length > 0) {
        this.loading = false;
        this.dataServiceObj.setComponentObs(response);
        for (const value of response) {
          this.valuesForComponent.push(value.rpcName);
        }
      } else {
        this.loading = false;
        this.valuesForComponent = [];
        this.dataServiceObj.setComponentObs(response);
      }
    }, (error) => {
      this.loading = false;
    });
  }
  getSelectedLeafRpcs() {
    pureRulesDetailsModel.selectedLeafRpcs = [];
    pureRulesDetailsModel.selectedRpcs.forEach((rpcValue) => {
      const childPresent = this.hasChildNodes(rpcValue);
      if (!childPresent) {
        pureRulesDetailsModel.selectedLeafRpcs.push(rpcValue);
      }
    });
  }
  hasChildNodes(rpc) {
    let hasChild = false;
    this.rpcData.forEach((rpcValue) => {
      if (rpcValue.parent === rpc.id) {
        hasChild = true;
      }
    });
    return hasChild;
  }
  getRPCDataBasedOnMarket(marketSelected) {
    this.selectedRPC = '';
    this.rpcdataForTree = [];
    pureRulesDetailsModel.CascadeRpc = 'Y';
    this.loading = true;
    const result = this.marketsToSelect.filter(x => x.marketName === marketSelected)[0];
    const url = environment.APIFAsT + '/marketid/' + result.marketId + '/tfcid/' + recipeModel.tfc.tfcId + '/rpclist';
    this.assessRequestRa.getData(url).subscribe((response) => {
      this.loading = false;
      if (response.length > 0) {
        this.rpcToSelect = response;
        this.onRPCChangedLoad(response[0]);
      } else {
        this.rpcToSelect = [];
        this.selectedRPC = '';
        pureRulesDetailsModel.selectedRpcs = [];
        this.selectedRpcList = [];
        this.getTreeDataBasedOnMarket(response);
        this.snackBar.open('No RPCs are found for market ' + marketSelected, 'close', {
          duration: 3000,
          panelClass: ['success']
        });
      }
    }, (error) => {
      this.loading = false;
    });
  }
  /* istanbul ignore next */
  onRPCChanged(selectedRPC) {
    this.getTreeDataBasedOnMarket(selectedRPC.value);
  }
  /* istanbul ignore next */
  onRPCChangedLoad(selectedRPCOnLoad) {
    this.getTreeDataBasedOnMarket(selectedRPCOnLoad);
  }
  /* istanbul ignore next */
  getTreeDataBasedOnMarket(rpcVal) {
    this.loading = true;
    pureRulesDetailsModel.selectedRpcs = [];
    $('.tree-div').jstree('destroy').empty();
    const encodedTfcName = encodeURIComponent(encodeURIComponent(encodeURIComponent(recipeModel.tfc.tfcFullText)));
    let url;
    if (rpcVal.rpcId !== undefined) {
      url = environment.APIFAsT + '/markets/' + pureRulesDetailsModel.Market.marketName + '/tfcname/' + encodedTfcName + '/rpcs/fortree/rpc/' + rpcVal.rpcId;
    } else {
      url = environment.APIFAsT + '/markets/' + pureRulesDetailsModel.Market.marketName + '/tfcname/' + encodedTfcName + '/rpcs/fortree/rpc/0';
    }
    this.assessRequestRa.getData(url).subscribe((data) => {
      this.loading = false;
      this.rpcdataForTree = data;
      this.rpcData = data;
      if (this.rpcdataForTree.length > 0) {
        for (const value of this.rpcdataForTree) {
          if (value.text === 'No Regulatory Product Class is Mapped') {
            this.createRpcTree(this.rpcData);
            pureRulesDetailsModel.selectedRpcs = [];
            this.selectedRpcList = [];
          }
        }
        if (rpcVal.rpcId !== undefined) {
          const urlData = environment.APIFAsT + '/markets/' + pureRulesDetailsModel.Market.marketName + '/tfcname/' + encodedTfcName + '/rpcs/formodel/rpc/' + rpcVal.rpcId;
          this.assessRequestRa.getData(urlData).subscribe((response) => {
            if (response !== null) {
              pureRulesDetailsModel.RpcFullDetails = [];
              this.rpcFullData = response;
              pureRulesDetailsModel.RpcFullDetails = this.rpcFullData;
              if (this.rpcFullData.length === 0) {
                pureRulesDetailsModel.RpcFullDetails = [];
              } else {
                pureRulesDetailsModel.RpcFullDetails.forEach((rpcDetailedValue) => {
                  const index = _.findIndex(this.rpcdataForTree, (value) => Number(value.id) === rpcDetailedValue.parentRpcId);
                  if (index !== -1) {
                    this.rpcData[index].rpcFullText = rpcDetailedValue.parentRpcSearchValue;
                  }
                });
                this.getSelectedParentRpcs();
                this.createRpcTree(this.rpcData);
                this.getSelectedLeafRpcs();
                this.loading = false;
                this.dataServiceObj.setSelectedRpcValues(this.rpcData);
              }
            } else {
              this.rpcFullData = [];
            }
          });
        }
      } else {
        pureRulesDetailsModel.selectedRpcs = [];
        this.selectedRpcList = [];
      }
    }, (error) => {
      this.loading = false;
    });
  }
}
