import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TabViewModule } from 'primeng/tabview';
import { AssessRequestRaComponent } from '../assess-request-ra/assess-request-ra.component';
import { SharedModule } from '../common/shared.module';
import { AssessRequestRaRoutingModule } from './assess-request-ra-routing.module';
import { ExecuteRulesRaComponent } from './execute-rules-ra/execute-rules-ra.component';
import { PrepareRuleComponent } from './execute-rules-ra/prepare-rule/prepare-rule.component';
import { ViewPrepareRuleComponent } from './execute-rules-ra/view-prepare-rule/view-prepare-rule.component';
import { SubmitReportRaComponent } from './submit-report-ra/submit-report-ra.component';
import { UpdateRulesRaComponent } from './update-rules-ra/update-rules-ra.component';
import { VerifyRequestRaComponent } from './verify-request-ra/verify-request-ra.component';
import { ReportComponent } from './execute-rules-ra/report/report.component';
import { CommentsComponent } from './submit-report-ra/report-action-icons/comments/comments.component';
import { HazardClassificationComponent } from './submit-report-ra/report-action-icons/hazard-classification/hazard-classification.component';
import { ReportActionIconsComponent } from './submit-report-ra/report-action-icons/report-action-icons.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ChartModule } from 'primeng/chart';
import { IngredientPropertyValuesComponent } from './submit-report-ra/report-action-icons/rnd-inputs/ingredient-property-values/ingredient-property-values.component';
import { PureListItemComponent } from './submit-report-ra/report-action-icons/rnd-inputs/pure-list-item/pure-list-item.component';
import { RndInputsComponent } from './submit-report-ra/report-action-icons/rnd-inputs/rnd-inputs.component';
import { RulesListItemComponent } from './submit-report-ra/report-action-icons/rnd-inputs/rules-list-item/rules-list-item.component';
import { RulesListComponent } from './submit-report-ra/report-action-icons/rnd-inputs/rules-list/rules-list.component';
import { VerifyRequestPlmComponent } from './verify-request-plm/verify-request-plm.component';
import { SubmitReportPlmComponent } from './submit-report-ra/submit-report-plm/submit-report-plm.component';
@NgModule({
  declarations: [
    AssessRequestRaComponent,
    VerifyRequestRaComponent,
    UpdateRulesRaComponent,
    ExecuteRulesRaComponent,
    SubmitReportRaComponent,
    PrepareRuleComponent,
    PrepareRuleComponent,
    ViewPrepareRuleComponent,
    ReportComponent,
    ReportActionIconsComponent,
    HazardClassificationComponent,
    CommentsComponent,
    RndInputsComponent,
    RulesListItemComponent,
    RulesListComponent,
    PureListItemComponent,
    IngredientPropertyValuesComponent,
    VerifyRequestPlmComponent,
    SubmitReportPlmComponent
  ],
  imports: [
    CommonModule,
    AssessRequestRaRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    TabViewModule,
    CKEditorModule,
    ChartModule
  ]
})
export class AssessRequestRaModule { }
