import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { environment } from 'src/environments/environment';
import { groupDataModel } from '../models/GroupDataModel';
import { prdtRuleDetailsModel } from '../models/ProductRuleDetailsModel';
import { pureRulesDetailsModel } from '../models/PureRuleDetailsModel';
import { RecipeDetailsModel, recipeModel } from '../models/recipeDetailsModel';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  requestIdData = new BehaviorSubject<any>(null);
  reportData = new BehaviorSubject<any>(null);
  public previewdata = new BehaviorSubject('no data');
  public previewresultdata = new BehaviorSubject('no data');
  public previewformassessdata = new BehaviorSubject('no data');
  public previewcompositiondata = new BehaviorSubject(['no data']);
  public previewMultiComponentData = new BehaviorSubject(['no data']);
  public previewpropertydata = new BehaviorSubject(['no data']);
  public previewfragrancedata = new BehaviorSubject(['no data']);
  public previewprojectdata = new BehaviorSubject(['no data']);
  public globaldata = new BehaviorSubject('no data');
  public selectedStepSvc = new BehaviorSubject(0);
  public getComponentNameSvc = new BehaviorSubject('');
  public updatedRecipeData = new BehaviorSubject(new RecipeDetailsModel());
  public selectedSubRuleType = new BehaviorSubject([]);
  public selectedGroupRuleType = new BehaviorSubject([]);
  public selectedProductRuleType = new BehaviorSubject([]);
  public panelToExpand = new BehaviorSubject('');
  public rulesListForPure = new BehaviorSubject([]);
  public rulesListForGroup = new BehaviorSubject([]);
  public rulesListForProduct = new BehaviorSubject([]);
  public selectedRpcValues = new BehaviorSubject([]);
  public selectedPureForRuleSetting = new BehaviorSubject([]);
  public selectedeGroupForRuleSetting = new BehaviorSubject([]);
  public applicableRuleTypesObv = new BehaviorSubject([]);
  public pdtApplicableRuleTypeObsv = new BehaviorSubject([]);
  public selectedRuleCategoryForSubBased = new BehaviorSubject('');
  private componentObs$: BehaviorSubject<any> = new BehaviorSubject(null);
  private multiComponentObs$: BehaviorSubject<any> = new BehaviorSubject(null);
  private refreshGridVal$: BehaviorSubject<any> = new BehaviorSubject(null);
  private loadRuleType$: BehaviorSubject<any> = new BehaviorSubject(null);
  private loadSirDetail$: BehaviorSubject<any> = new BehaviorSubject(null);
  private formValues$: BehaviorSubject<any> = new BehaviorSubject(null);
  private ruleDetailsToEdit: BehaviorSubject<any> = new BehaviorSubject(null);
  private ruleDetailsViewToEdit: BehaviorSubject<any> = new BehaviorSubject(null);
  private confGroupDataToEdit: BehaviorSubject<any> = new BehaviorSubject(null);
  private treeDataCreation: BehaviorSubject<any> = new BehaviorSubject(null);
  datapreview = this.previewdata.asObservable();
  resultdatapreview = this.previewresultdata.asObservable();
  formAssessdatapreview = this.previewformassessdata.asObservable();
  compositiondatapreview = this.previewcompositiondata.asObservable();
  multiComponentdatapreview = this.previewMultiComponentData.asObservable();
  propertydatapreview = this.previewpropertydata.asObservable();
  fragrancedatapreview = this.previewfragrancedata.asObservable();
  projectdatapreview = this.previewprojectdata.asObservable();
  dataglobal = this.globaldata.asObservable();
  selectedStepValue = this.selectedStepSvc.asObservable();
  getCompName = this.getComponentNameSvc.asObservable();
  datarecipe = this.updatedRecipeData.asObservable();
  ruleType = this.selectedSubRuleType.asObservable();
  getSelectedGroupRuleType = this.selectedGroupRuleType.asObservable();
  getSelectedProductRuleType = this.selectedProductRuleType.asObservable();
  expandedPanel = this.panelToExpand.asObservable();
  getRulesListForEachPure = this.rulesListForPure.asObservable();
  getRulesListForGroup = this.rulesListForGroup.asObservable();
  getRulesListForPdt = this.rulesListForProduct.asObservable();
  getSelectedRpcs = this.selectedRpcValues.asObservable();
  getSelectedPureValue = this.selectedPureForRuleSetting.asObservable();
  getApplicableRuleTypesObsv = this.applicableRuleTypesObv.asObservable();
  getGroupData = this.selectedeGroupForRuleSetting.asObservable();
  getSelectedRuleCategoryForSubBased = this.selectedRuleCategoryForSubBased.asObservable();
  getPdtApplicableRuleTyes = this.pdtApplicableRuleTypeObsv.asObservable();
  castUser = this.requestIdData.asObservable();
  requestReportData = this.reportData.asObservable();

  public pureCode;
  public pureDesc;
  public search;
  public fromSubstanceData;
  public isfromSubstancesearch;
  public fromViewtoSubstance = false;
  public substanceFormdata = {
    selectedGroup: [],
    selectedMarkets: [],
    selectedFunction: [],
    searchIdentifier: '',
    division: '',
    selectedSubSearch: {},
    selectedDivision: {}
  };
  constructor(
    private assessRequestRa: AssessRequestRaService) {
  }
  updateRequestId(reqId) {
    this.requestIdData.next(reqId);
  }
  getRequestData(data) {
    this.reportData.next(data);
  }
  /* istanbul ignore next */
  setpreviewdata(data) {
    return this.previewdata.next(data);
  }
  /* istanbul ignore next */
  setformAssessdata(data) {
    return this.previewformassessdata.next(data);
  }
  setCompositionData(data) {
    return this.previewcompositiondata.next(data);
  }
  setMultiComponentData(data) {
    return this.previewMultiComponentData.next(data);
  }
  setPropertyData(data) {
    return this.previewpropertydata.next(data);
  }
  setFragranceData(data) {
    return this.previewfragrancedata.next(data);
  }
  setProjectData(data) {
    return this.previewprojectdata.next(data);
  }
  setpreviewresultdata(data) {
    return this.previewresultdata.next(data);
  }
  setglobaldata(data) {
    return this.globaldata.next(data);
  }
  setSearch() {
    this.search = true;
  }
  setSelectedStep(data) {
    return this.selectedStepSvc.next(data);
  }
  setComponentName(data) {
    return this.getComponentNameSvc.next(data);
  }
  setRecipeData(data) {
    return this.updatedRecipeData.next(data);
  }
  setSelectedRuleType(data) {
    return this.selectedSubRuleType.next(data);
  }
  setSelectedGroupRuleType(data) {
    return this.selectedGroupRuleType.next(data);
  }
  setSelectedProductRuleType(data) {
    return this.selectedProductRuleType.next(data);
  }
  setExpandablePanel(data) {
    return this.panelToExpand.next(data);
  }
  setRulesListForPure(data) {
    return this.rulesListForPure.next(data);
  }
  setRulesListForGroup(data) {
    return this.rulesListForGroup.next(data);
  }
  setRulesListForProduct(data) {
    return this.rulesListForProduct.next(data);
  }
  setSelectedRpcValues(data) {
    return this.selectedRpcValues.next(data);
  }
  setSelectedPureValue(data) {
    pureRulesDetailsModel.PureCode = data;
    return this.selectedPureForRuleSetting.next(data);
  }
  setApplicableRuleTypesObsv(data) {
    // pureRulesDetailsModel.applicableRuleTypeList = [];
    pureRulesDetailsModel.applicableRuleTypeList = data;
    return this.applicableRuleTypesObv.next(data);
  }
  setProductRuleApplicability(data) {
    // prdtRuleDetailsModel.applicableRuleTypeList = [];
    prdtRuleDetailsModel.applicableRuleTypeList = data;
    return this.pdtApplicableRuleTypeObsv.next(data);
  }
  setSelectedGroup(data) {
    Object.assign(groupDataModel, data);
    return this.selectedeGroupForRuleSetting.next(data);
  }
  setRuleCategory(data) {
    return this.selectedRuleCategoryForSubBased.next(data);
  }
  public getRpcTree(marketName) {
    const encodedTfcName = encodeURIComponent(encodeURIComponent(encodeURIComponent(recipeModel.tfcFullText)));
    const apiurl = environment.APIFAsT + '/markets/' + marketName + '/tfcname/' + encodedTfcName + '/rpcs/fortree';
    return this.assessRequestRa.getData(apiurl);
    // .subscribe((response) => {
    //   return response;
    // });
  }
  public getRpcModel(marketName) {
    const encodedTfcName = encodeURIComponent(encodeURIComponent(encodeURIComponent(recipeModel.tfcFullText)));
    const apiurl = environment.APIFAsT + '/markets/' + marketName + '/tfcname/' + encodedTfcName + '/rpcs/formodel';
    return this.assessRequestRa.getData(apiurl);
    // .subscribe((response) => {
    //   return response;
    // });
  }

  getComponentObs(): Observable<any> {
    return this.componentObs$.asObservable();
  }
  setComponentObs(comp: any) {
    this.componentObs$.next(comp);
  }
  getSelectedMultiComponent(): Observable<any> {
    return this.multiComponentObs$.asObservable();
  }
  setSelectedMultiComponent(comp) {
    this.multiComponentObs$.next(comp);
  }
  getRefreshGridVal(): Observable<any> {
    return this.refreshGridVal$.asObservable();
  }
  setRefreshGridVal(comp) {
    this.refreshGridVal$.next(comp);
  }
  getRuleTypeVal(): Observable<any> {
    return this.loadRuleType$.asObservable();
  }
  setRuleTypeVal(comp) {
    this.loadRuleType$.next(comp);
  }
  setSirDetailForParallelRule(sirDetail) {
    this.loadSirDetail$.next(sirDetail);
  }
  getSirDetailForParallelRule(): Observable<any> {
    return this.loadSirDetail$.asObservable();
  }
  setParallelRuleFormValues(formValues) {
    this.formValues$.next(formValues);
  }
  getParallelRuleFormValues(): Observable<any> {
    return this.formValues$.asObservable();
  }
  setruleToEdit(ruleDetails) {
    this.ruleDetailsToEdit.next(ruleDetails);
  }
  getruleToEdit() {
    return this.ruleDetailsToEdit.asObservable();
  }
  setViewruleToEdit(ruleDetails) {
    this.ruleDetailsViewToEdit.next(ruleDetails);
  }
  getViewruleToEdit() {
    return this.ruleDetailsViewToEdit.asObservable();
  }
  setSelectedConflictingGroupData(confGroupData) {
    this.confGroupDataToEdit.next(confGroupData);
  }
  getSelectedConflictingGroupData() {
    return this.confGroupDataToEdit.asObservable();
  }
  setLastSelectedNodeTreeData(value) {
    this.treeDataCreation.next(value);
  }
  getLastSelectedNodeTreeData(): Observable<any> {
    return this.treeDataCreation.asObservable();
  }
}
