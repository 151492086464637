import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { MsalService} from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { AuthorizationDataService } from '../common/Authorization/authorization-data.service';
import { requestFlags } from '../common/models/PureRuleDetailsModel';
import { DataService } from '../common/services/data.service';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  LoggedInUserName;
  apiResponse;
  role;
  loading = false;
  openMenuBar = false;
  givenName;
  adminDD = false;
  worklistDD = false;
  ruleManaementDD = false;
  fragranceDD = false;
  showFragranceMenu = false;
  firstLetter;
  constructor(
    // private adalSvc: MsAdalAngular6Service,
    private msalSvc: MsalService,
    private router: Router,
    private dataService: DataService,
    private authService: AuthorizationDataService,
    private httpClient: HttpClient
  ) { }

  getuserprofile() {
    const token = localStorage.getItem('Token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    this.httpClient.get('https://graph.microsoft.com/v1.0/me', { headers: headers }).subscribe(resp => {
      this.firstLetter = JSON.stringify(resp['givenName']).charAt(1) + JSON.stringify(resp['surname']).charAt(1);
      this.givenName = JSON.stringify(resp['givenName']).substring(1, JSON.stringify(resp['givenName']).length - 1) + ' ' + JSON.stringify(resp['surname']).substring(1, JSON.stringify(resp['surname']).length - 1);
      this.LoggedInUserName = this.msalSvc.instance.getAllAccounts()[0].username;
      this.loading = false;
      const roleData = localStorage.getItem('roles');
      const data = roleData.split(',');
      const listRole = [];
      for (const value of data) {
        if (value.toLowerCase().startsWith('fast')) {
          listRole.push(value);
        }
      }
      this.role = listRole.join(',');
      localStorage.setItem('firstLetter', this.firstLetter);
    });
  }
  ngOnInit() {
    this.loading = true;
    this.getuserprofile();
    const token = localStorage.getItem('Token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    this.httpClient.get(environment.APIFAsT + '/appinfo/featureflagfragrance').subscribe(result => {
      const fragranceFlag = JSON.stringify(result['FeatureFlagFragrance']).toLocaleLowerCase().replace('"', '').replace('"', '');
      this.showFragranceMenu = fragranceFlag === 'false' ? false : true;
     });
    this.authService.getRolesData().subscribe((val) => {
      if (val) {
        if (val === 'Error') {
          this.router.navigate(['/error']);
        } else {
          this.loading = false;
          // if (this.msalSvc.hasOwnProperty('instance') && this.msalSvc.instance.hasOwnProperty('getAllAccounts()')) {
          if (!(this.msalSvc.instance.getAllAccounts()[0].username).endsWith('@unilever.com')) {
            this.router.navigate(['/error']);
            setTimeout(() => {
              this.msalSvc.logout();
              localStorage.clear();
            }, 5000);
          } else {
            this.getuserprofile();
            const roleData = localStorage.getItem('roles');
            const data = roleData.split(',');
            const listRole = [];
            for (const value of data) {
              if (value.toLowerCase().startsWith('fast')) {
                listRole.push(value);
              }
            }
            this.role = listRole.join(',');
            if (this.firstLetter === null || this.firstLetter === '') {
              this.firstLetter = localStorage.getItem('firstLetter');
            }
          }
          // }
        }
      }
    });
  }
  toggleMenu() {
    this.openMenuBar = true;
  }
  navigate(val) {
    this.showFragranceMenu = localStorage.getItem('FeatureFlagFragrance') === 'true' ? true : false;
    localStorage.removeItem('marketDetailsEdit');
    if (val === 'Create Request') {
      this.loading = true;
      requestFlags.viewRequest = false;
      requestFlags.EditRequest = false;
      requestFlags.createRequest = true;
      localStorage.removeItem('FormObj');
      this.dataService.setComponentName('AssessRequestsRD');
      setTimeout(() => {
        this.loading = false;
        this.dataService.setSelectedStep(0);
      }, 1000);
      this.router.navigate(['/assess-requests/select-recipe']);
    } else if (val === 'Create Rules') {
      this.dataService.setComponentName('CreateInsertRule');
      setTimeout(() => {
        this.dataService.setSelectedStep(0);
      }, 1000);
      this.router.navigate(['/create-insert/select-rpc']);
    } else if (val === 'Master') {
      this.router.navigate(['/worklist/master-worklist']);
    } else if (val === 'My') {
      this.router.navigate(['/worklist/my-worklist']);
    } else if (val === 'Function') {
      this.router.navigate(['/administration/function-master']);
    } else if (val === 'Component') {
      this.router.navigate(['/administration/component-master']);
    } else if (val === 'Property') {
      this.router.navigate(['/administration/property-master']);
    } else if (val === 'TFC') {
      this.router.navigate(['/fragrancemasterlist/tfc-ifra-mapping']);
    } else if (val === 'Regional') {
      this.router.navigate(['/fragrancemasterlist/regional-complaince-level2']);
    } else if (val === 'PLMFAsTMarket') {
      this.router.navigate(['/fragrancemasterlist/plm-fast-market']);
    } else if (val === 'Help') {
      this.router.navigate(['/help']);
    } else if (val === 'View-Rules') {
      this.dataService.setComponentName('EditRule');
      this.router.navigate(['/rule-management/view-rules']);
    } else if (val === 'Related-Requests') {
      this.router.navigate(['/rule-management/related-requests']);
    } else if (val === 'Regulatory-group-master') {
      this.router.navigate(['/administration/regulatory-group-master']);
    } else if (val === 'substance-search') {
      this.router.navigate(['/substance-search/0']);
    }
  }
  closeMenu() {
    this.openMenuBar = false;
    this.worklistDD = false;
    this.adminDD = false;
    this.fragranceDD = false;
  }
  navigateParent(val) {
    if (val === 'Worklist') {
      this.worklistDD = !this.worklistDD;
      this.adminDD = false;
      this.ruleManaementDD = false;
      this.fragranceDD = false;
    } else if (val === 'Admin') {
      this.adminDD = !this.adminDD;
      this.worklistDD = false;
      this.ruleManaementDD = false;
      this.fragranceDD = false;
    } else if (val === 'rule-Management') {
      this.adminDD = false;
      this.worklistDD = false;
      this.fragranceDD = false;
      this.ruleManaementDD = !this.ruleManaementDD;
    } else if (val === 'Fragrance') {
      this.adminDD = false;
      this.worklistDD = false;
      this.ruleManaementDD = false;
      this.fragranceDD = !this.fragranceDD;
    }

  }
}
