import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdministrationRoutingModule } from './administration-routing.module';
import { AdministrationComponent } from './administration.component';
import { FunctionMasterComponent } from './function-master/function-master.component';
import { ComponentMasterComponent } from './component-master/component-master.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../common/shared.module';
import { AssessRequestsModule } from '../assess-requests/assess-requests.module';
import { RegulatoryGroupMasterComponent } from './regulatory-group-master/regulatory-group-master.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PropertyMasterComponent } from './property-master/property-master.component';

@NgModule({
  declarations: [
    AdministrationComponent,
    FunctionMasterComponent,
    ComponentMasterComponent,
    RegulatoryGroupMasterComponent,
    PropertyMasterComponent,

  ],
  imports: [
    CommonModule,
    AdministrationRoutingModule,
    HttpClientModule,
    SharedModule,
    AssessRequestsModule,
    ReactiveFormsModule
  ]
})
export class AdministrationModule { }
