export class RecipeDetailsModel {
    firstRequest;
    keyDate;
    plmvalidityarea;
    cucCode;
    cucAlt;
    cucVer;
    cucDescription;
    dosageUnit;
    fc: [];
    version: [];
    alternate: [];
    recipeType: [];
    description: [];
    marketDetails: MarketDetails;
    markets: MarketDetails [];
    division: '';
    formulationCategory: [];
    formulationType: [];
    formulationClass: [];
    formulationSubClass: any[];
    quantity: [];
    uom: [];
    intendedUse: 'Any';
    preparationMethod: [];
    comments: [];
    dosage: [];
    dosageUom: [];
    servingSize: [];
    selectedMarkets: [];
    formulationRequestDetails: FormulationRequestDetails[];
    productPropertiesInputs: ProductPropertiesInputs[];
    requestComments;
    requestDisplayID;
    requestID;
    tfc: TFC;
    pureCodeList;
    tfcFullText;
    functionList;
    selectedRpcs;
    requestStatus;
    reportComments;
    requestStatusID;
    recipeStatus;
    rpcList;
    allRAIDs;
    raUserID;
    ra;
    isMultiComponent;
    multiComponent;
    dosageForMarkets;
    rpcRefinementData;
    isViewReportClicked;
    ifraComplianceModelList;
    regionalComplianceLevel2ModelList;
    regionalComplianceLevel3ModelList;
    productOverallStatus;
}

export class TFC {
    tfcFullText;
    tfcId;
    tfcLevel;
    tfcName;
    parentTFC: TFC;

}
export class MarketDetails {
    marketId;
    marketName;
    clusterId: '';
    clusterName: '';
    marketFullText: '';
    parentMarket;
}

export class FormulationRequestDetails {
    casNumber;
    parentCASNumber;
    claimsListFromRules;
    convFactor;
    eNumber;
    einecsNumber;
    functionLstFromRules: [];
    inciNumber;
    ingDescription;
    ingID;
    ingQuantity;
    ingUoM;
    ingredientPath;
    insNumber;
    pureCode;
    pureDescription;
    pureQuantityPerItem;
    pureSerial;
    recipeQuantity;
    recipeQuantityToDisplay;
    recipeUoM;
    ruleExecutionStatus;
    specDescription;
    specID;
    specQuantity;
    specSerial;
    specUoM;
    specialConditionListFromRules;
    statusOrder;
    substanceFunctionInput;
    substanceFunctionInputChanged;
    rndInputFunction;
    substanceID;
    substanceName;
    substanceRuleStatus;
    synonyms;
    userInputClaim;
    userInputClaimChanged;
    userInputIngredientType;
    userInputSpecificCondition;
    userInputSpecificConditionChanged;
    userSelectedCFDetails: CFDetails;
    cfDetails: CFDetails[];
    multiComponentID;
    multiComponentName;
    rowValue;
}

export class ProductPropertiesInputs {
    claimsListFromRules;
    propertyClass;
    propertyId;
    propertyName;
    propertyRecipeQuantity;
    propertyRuleStatus;
    propertyUoM;
    propertyValueType;
    rpcList;
    specialConditionListFromRules;
    userInputClaim;
    userInputSpecificCondition;
}
export class TFCDetails {
    parentTFC;
    tfcFullText;
    tfcId;
    tfcLevel;
    tfcName;
}
export class CFDetails {
    cf;
    cfDisplayText;
    component;
    componentMolWeight;
    expressedAs;
    numberOfExpressedAs;
}
export class FunctionList {
    functionName: '';
    functionId: '';
}
// export class UomDetails {
//     isActive;
//     uoMid;
//     uoMname;
//     uoMsymbol;
// }

// export class FunctionDetails {
//     functionId;
//     functionName;
// }

// tslint:disable-next-line: no-var-keyword
export var recipeModel = new RecipeDetailsModel();

// // tslint:disable-next-line: no-var-keyword
// export var uomList = new UomDetails();

// // tslint:disable-next-line: no-var-keyword
// export var functionList = new FunctionDetails();


