import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { environment } from './../environments/environment';
import { AuthorizationDataService } from './common/Authorization/authorization-data.service';
import { AuthorizationService } from './common/Authorization/authorization.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ara-fast-poc-webapp';
  loading = true;
  isAuthenticated = false;
  activeUser: string | undefined = 'unknown user';
  private unsubscribe = new Subject<void>();
  constructor(
    private router: Router,
    // private adalSvc: MsAdalAngular6Service,
    private authorizationDataService: AuthorizationDataService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient) {
    const OAUTH_TOKEN_ENDPOINT_URL = environment.OAUTH_TOKEN_ENDPOINT;
  }
  async logIn() {
    await this.msalService.instance.loginRedirect({
      scopes: ['user.read', 'openid', 'profile'],
      redirectUri: window.location.origin,
    });
  }
  get authenticated(): boolean {
    return this.msalService.instance.getActiveAccount() ? true : false;
  }
  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)
        , takeUntil(this.unsubscribe))
      .subscribe(async () => {
        if (!this.authenticated) {
          await this.logIn();
        } else {
          // if (this.msalService.instance.hasOwnProperty('getAllAccounts()')) {
            if (this.msalService.instance.getAllAccounts()[0].username.length > 0) {
              // || this.adalSvc.userInfo.profile.roles === undefined
              if (!((this.msalService.instance.getAllAccounts()[0].username).endsWith('@unilever.com') || (this.msalService.instance.getAllAccounts()[0].username).endsWith('@benjerry.com'))) {
                this.router.navigate(['/error']);
                this.loading = false;
                setTimeout(() => {
                  this.msalService.logout();
                  localStorage.clear();
                }, 5000);
              } else {
                this.loading = false;
                const token = localStorage.getItem('Token');
                const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
                this.http.get('https://graph.microsoft.com/v1.0/me', { headers: headers }).subscribe(resp => {
                  localStorage.setItem('factory', '');
                  localStorage.setItem('UserEmailAddress', this.msalService.instance.getAllAccounts()[0].username);
                  localStorage.setItem('UserName', this.msalService.instance.getAllAccounts()[0].name);
                  localStorage.setItem('GivenName', JSON.stringify(resp['givenName']).substring(1, JSON.stringify(resp['givenName']).length - 1));
                  localStorage.setItem('FamilyName', JSON.stringify(resp['surname']).substring(1, JSON.stringify(resp['surname']).length - 1));
                  localStorage.setItem('url', window.location.href);
                  this.loading = false;
                });
                sessionStorage.removeItem('roles');
                sessionStorage.removeItem('permissons');
                sessionStorage.removeItem('userRolesPermissions');
                this.loading = false;
                localStorage.setItem('Token', token);
                const authorizationService = new AuthorizationService(this.authorizationDataService, this.router);
                authorizationService.initializePermissions();
                this.authorizationDataService.getValueFromAPIs();
                this.authorizationDataService.getFeatureFragranceFlag();
                this.authorizationDataService.getFragranceMessage();
                this.authorizationDataService.getFastAdminMail();
                this.authorizationDataService.getisErrRedrtProd();
                if (localStorage.getItem('FormObj')) {
                  localStorage.removeItem('FormObj');
                }
              }
            }
          // }
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntil(this.unsubscribe)
      )
      .subscribe((result: EventMessage) => {
        const authResult = result.payload as AuthenticationResult;
        localStorage.setItem('Token', authResult.accessToken);
        localStorage.setItem('FunctionToken', authResult.idToken);
        this.msalService.instance.setActiveAccount(authResult.account);
        if (this.msalService.instance.getAllAccounts()[0].username.length > 0) {
          // || this.adalSvc.userInfo.profile.roles === undefined
          if (!((this.msalService.instance.getAllAccounts()[0].username).endsWith('@unilever.com') || (this.msalService.instance.getAllAccounts()[0].username).endsWith('@benjerry.com'))) {
            this.router.navigate(['/error']);
            this.loading = false;
            setTimeout(() => {
              this.msalService.logout();
              localStorage.clear();
            }, 5000);
          } else {
            this.loading = false;
            const token = localStorage.getItem('Token');
            const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
            this.http.get('https://graph.microsoft.com/v1.0/me', { headers: headers }).subscribe(resp => {
              localStorage.setItem('factory', '');
              localStorage.setItem('UserEmailAddress', this.msalService.instance.getAllAccounts()[0].username);
              localStorage.setItem('UserName', this.msalService.instance.getAllAccounts()[0].name);
              localStorage.setItem('GivenName', JSON.stringify(resp['givenName']).substring(1, JSON.stringify(resp['givenName']).length - 1));
              localStorage.setItem('FamilyName', JSON.stringify(resp['surname']).substring(1, JSON.stringify(resp['surname']).length - 1));
              localStorage.setItem('url', window.location.href);
              this.loading = false;
            });
            sessionStorage.removeItem('roles');
            sessionStorage.removeItem('permissons');
            sessionStorage.removeItem('userRolesPermissions');
            this.loading = false;
            localStorage.setItem('Token', token);
            const authorizationService = new AuthorizationService(this.authorizationDataService, this.router);
            authorizationService.initializePermissions();
            this.authorizationDataService.getValueFromAPIs();
            this.authorizationDataService.getFeatureFragranceFlag();
            this.authorizationDataService.getFragranceMessage();
            this.authorizationDataService.getFastAdminMail();
            this.authorizationDataService.getisErrRedrtProd();
            if (localStorage.getItem('FormObj')) {
              localStorage.removeItem('FormObj');
            }
          }
        }
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }
  setAuthenticationStatus(): void {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (activeAccount.username.length > 0) {
      activeAccount = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(activeAccount);
      this.isAuthenticated = true;
      this.activeUser = activeAccount.username;
    }
  }
}
