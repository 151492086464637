export const environment = {
  production: false,
  APIFAsT: 'https://bnlwe-af-d-57294-unilevercom-funapp-01.azurewebsites.net/api/ara/fast/v1',
  APIFAsTRule: 'https://ara-fast-dev-ruleengine.unilever.com/api/ara/fast/ruleEngine/v1',
  tenant: 'f66fae02-5d36-495b-bfe0-78a6ff9f8e6e',
  clientId: '0cfe1f98-00f0-4087-9eb1-bc077b311332',
  endpoints: 'https://ara-fast-dev.unilever.com',
  // endpoints: 'http://localhost:4200',
  OAUTH_TOKEN_ENDPOINT: 'https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e/oauth2/v2.0/token',
  SIRURL: 'https://sir-qa.unilever.com',
  PREFADMIN: 'https://ara-admin-dev.unilever.com/home',
  appInsights: {
      instrumentationKey: '1f3aedb6-291a-436b-a033-02b713be798c'
  },
  MSALFAsTAPIConfig: 'https://bnlwe-af-d-57294-unilevercom-funapp-01.azurewebsites.net',
  MSALFAsTRuleEngineConfig: 'https://ara-fast-dev-ruleengine.unilever.com',
  ImpersonationConfig: 'https://Unilever.onmicrosoft.com/8e44cffa-2ab8-4863-83af-7709da1123ef/user_impersonation'
};
