import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AssessRequestRaComponent } from './assess-request-ra.component';
import { VerifyRequestRaComponent } from './verify-request-ra/verify-request-ra.component';
import { UpdateRulesRaComponent } from './update-rules-ra/update-rules-ra.component';
import { ExecuteRulesRaComponent } from './execute-rules-ra/execute-rules-ra.component';
import { SubmitReportRaComponent } from './submit-report-ra/submit-report-ra.component';
import { VerifyRequestPlmComponent } from './verify-request-plm/verify-request-plm.component';
import { SubmitReportPlmComponent } from './submit-report-ra/submit-report-plm/submit-report-plm.component';
const routes: Routes = [
    {
        path: '',
        component: AssessRequestRaComponent,
        children: [
            {
                path: 'verify-request/:requestId',
                component: VerifyRequestRaComponent
            },
            {
                path: 'update-rules',
                component: UpdateRulesRaComponent
            },
            {
                path: 'execute-rules',
                component: ExecuteRulesRaComponent
            },
            {
                path: 'submit-report',
                component: SubmitReportRaComponent
            },
            {
                path: 'verify-request-plm/:requestId',
                component: VerifyRequestPlmComponent,
            },
            {
                path: 'submit-report-plm/:requestId/:division',
                component: SubmitReportPlmComponent
            },
        ]
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AssessRequestRaRoutingModule { }
