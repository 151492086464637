import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MasterWorklistComponent } from './components/master-worklist/master-worklist.component';
import { MyWorklistComponent } from './components/my-worklist/my-worklist.component';
import { WorklistComponent } from './worklist.component';

const routes: Routes = [
  {
    path: '',
    component: WorklistComponent,
    children: [
      {
        path: 'master-worklist',
        component: MasterWorklistComponent
      },
      {
        path: 'my-worklist',
        component: MyWorklistComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorklistRoutingModule { }
