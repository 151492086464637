import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import * as _ from 'lodash';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { prdtRuleDetailsModel } from 'src/app/common/models/ProductRuleDetailsModel';
import { ruleFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { RuletypesAttributeMapping } from 'src/app/common/models/RuletypesAttributeMapping';
import { DataService } from 'src/app/common/services/data.service';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-common-product-rule-template',
  templateUrl: './common-product-rule-template.component.html',
  styleUrls: ['./common-product-rule-template.component.scss']
})
export class CommonProductRuleTemplateComponent implements OnInit {
  @ViewChild('productBasedRuleForm') productBasedRuleForm: NgForm;
  name = 'Angular ';
  editor = ClassicEditor;
  data: any = '';
  activeTabUR = 'rule-detail';
  readOnlyValue = true;
  ankerCount: any;
  maxEqualityCondition = [{ value: '<' }, { value: '<=' }];
  minEqualityCondition = [{ value: '>' }, { value: '>=' }];
  equalityCondition = [{ value: 'isEqual' }];
  ruleTypeId;
  ruleDisplayName;
  rtRadioSelected;
  applicableRuleTypesToDisplay;
  ruleAdded = []; allRulesforRT = []; allFns = []; allowedFns = []; uomList = []; rpc = []; rulesListForSubstance = []; ruleSpecificAttributes = [];  filteredSubstances: any = [];
  selectedRuleDetails = []; selectedRuleTypeDetails = []; pdtRulesList = []; productProperties = []; productParentProperties = []; productSubProperties = []; searchingInputs = []; selectedRowSubstance = [];
  selectedFunction; selectedUoM; selectedEqualityCondition;
  ruleDetails;
  attBtnClicked = false; loading = false; ruleTypeRadioSelected = false; saveAndAddNewDialog = false;
  selectedSubstanceName = '';
  pureCodeDD = false;
  pureCodeDDIndex: number;
  shownoresult = false;
  valConfRule;
  checkSelectedVal = '';
  disablebtn = false;
  loadingMsg;
  showSetVar = false;
  showWhenVar = false;
  setIndex;
  whenIndex;
  saveAndAddNewBtnClicked = false;
  rangeValidationFailed = false;
  cancelClicked = false;
  uomValidation = false;
  ruleSource = '';
  validityStartDate: any = '';
  validityEndDate: any = '';
  yearAdopted: any = '';
  ruleDescription = '';
  star = 'fa fa-fw fa-search';
  productParentPropertiesDD = []; productSubPropertiesDD = [];  prepareRuleTableData = []; myWorklistTableColumns = []; selectedRowsRelated = []; selectedSubsList = [];
  validationFailed = false; editRule = false; viewRule = false;
  ruleIdOnEdit; ruleVersion;
  disabledFieldEditRule = false; ruleSaveConformationPopup = false;
  ruleComment: string;
  showRelatedRulesPopup = false; viewRelbtn = false;
  marketForModal = '';
  showEmailConfirmationDialog = false;
  selectedRows;
  commentsToUser = '';
  defaultValidityFromDate;
  defaultValidityEndDate;
  todayDate;
  subsList = [Array[1]];
  setAllMandatoryFlag = false; productMinSubValidationFlag = false; productWarningSubValidationFlag = true; parallelRuleWorkflow = false;
  config = {
    toolbar: [
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      'link'
      // '|',
    ],
    image: {
      toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side']
    },
    link : {
      addTargetToExternalLinks: true,
      decorators: [
        {
          mode: '',
          label: '',
          attributes: {
            target: '_blank',
          }
        }
      ]
    }
  };
  constructor(
    public dataServiceObj: DataService, private assessReqRAService: AssessRequestRaService, private snackBar: MatSnackBar,
    private route: Router, private datePipe: DatePipe, private worklistservice: WorklistService) { }
  /* istanbul ignore next */
  ngOnInit() {
    this.todayDate = new Date();
    this.defaultValidityFromDate = this.datePipe.transform(this.todayDate, 'longDate');
    this.defaultValidityEndDate = this.datePipe.transform(new Date('9999-12-31'), 'longDate');
    this.subsList = [Array[1]];
    this.selectedSubsList = [];
    this.selectedRowSubstance = [];
    this.searchingInputs = [];
    this.setAllMandatoryFlag = false;
    if (this.activeTabUR === 'rule-detail') {
      document.getElementById('description').style.display = 'none';
      document.getElementById('ruleValidity').style.display = 'none';
    }
    this.dataServiceObj.getruleToEdit().subscribe((value) => {
      if (value !== null) {
        this.editRule = true;
        this.ruleIdOnEdit = value.ruleId;
        prdtRuleDetailsModel.selectedParentRpcsPdtBased = value.rpcs;
        prdtRuleDetailsModel.selectedRpcsPdtBased = value.rpcs;
        this.ruleSource = value.ruleSource;
        this.validityStartDate = '';
        this.validityEndDate = '';
        this.yearAdopted = '';
        this.ruleVersion = value.ruleVersion;
        this.marketForModal = recipeModel.marketDetails.marketName;
        this.getWorklistColumns();
      } else {
        this.editRule = false;
      }
    });
    if (ruleFlags.viewRule) {
      this.editRule = false;
      this.viewRule = true;
    } else if (ruleFlags.editRule) {
      this.editRule = true;
      this.viewRule = false;
    }
    this.dataServiceObj.getSelectedProductRuleType.subscribe((value) => {
      if (value.length !== 0) {
        this.subsList = [Array[1]];
        this.selectedSubsList = [];
        this.selectedRowSubstance = [];
        this.selectedRuleTypeDetails[0] = value;
        this.ruleTypeId = this.selectedRuleTypeDetails[0].ruleTypeId;
        this.ruleDisplayName = this.selectedRuleTypeDetails[0].ruleTypeDisplayName;
        this.productSubProperties = [];
        this.productSubPropertiesDD = [];
        this.activeTabUR = 'rule-detail';
        document.getElementById('rule-detail').style.display = 'block';
        document.getElementById('description').style.display = 'none';
        document.getElementById('ruleValidity').style.display = 'none';
        this.ruleDescription = this.selectedRuleTypeDetails[0].ruleTypeDescription ? this.selectedRuleTypeDetails[0].ruleTypeDescription : null;
        if (!this.editRule && !this.viewRule) {
          this.ruleSource = '';
          this.validityStartDate = '';
          this.validityEndDate = '';
          this.yearAdopted = '';
        }
        if (this.ruleTypeId !== 14) {
          this.getUomList();
          this.getFnList();
          this.getCucProperties();
          this.getRuleSpecificAttributeList(this.ruleTypeId);
        }
        if (this.ruleTypeId === 11 && this.ruleTypeId === 45 && !this.editRule && !this.viewRule) {
          this.disablebtn = true;
          this.selectedSubstanceName = null;
          this.filteredSubstances = [];
          this.searchingInputs = [];
          this.valConfRule = '';
        }
      }
      if (this.editRule === true || this.viewRule === true) {
        this.getEditRuleDetails(value);
        this.disablebtn = false;
      }
    });

  }
  /* istanbul ignore next */
  getEditRuleDetails(ruleToEdit) {
    recipeModel.preparationMethod = ruleToEdit.preparationMethod;
    recipeModel.intendedUse = ruleToEdit.intendedUse;
    this.ruleSource = ruleToEdit.ruleSource;
    if (this.viewRule) {
      this.defaultValidityFromDate = ruleToEdit.validityStartDate ? (this.datePipe.transform(new Date(this.datePipe.transform(ruleToEdit.validityStartDate, 'yyyy-MM-dd')), 'longDate')) : '';
      this.defaultValidityEndDate = ruleToEdit.validityDate ? (this.datePipe.transform(new Date(this.datePipe.transform(ruleToEdit.validityDate, 'yyyy-MM-dd')), 'longDate')) : '';
    }
    if (this.editRule) {
      this.defaultValidityEndDate = ruleToEdit.validityDate ? (this.datePipe.transform(new Date(this.datePipe.transform(ruleToEdit.validityDate, 'yyyy-MM-dd')), 'longDate')) : '';
    }
    this.yearAdopted = ruleToEdit.yearAdopted ? (new Date(ruleToEdit.yearAdopted)).toUTCString() : '';
    let firstValue; let secondValue;
    this.ruleSpecificAttributes.forEach((ruleattr) => {
      if (ruleToEdit.ruleTypeID === 7 || ruleToEdit.ruleTypeID === 8 || ruleToEdit.ruleTypeID === 9 || ruleToEdit.ruleTypeID === 11) {
        this.disabledFieldEditRule = true;
      } else {
        this.disabledFieldEditRule = false;
      }
      if (ruleToEdit.ruleTypeID === 9) {
        firstValue = ruleToEdit.equalityCondition.split(';')[0];
        secondValue = ruleToEdit.equalityCondition.split(';')[1];
      }
      if (ruleattr.attributeName === 'Max' && (ruleToEdit.maxLimit || ruleToEdit.maxLimit === 0)) {
        ruleattr.isRequired = true;
        ruleattr.attributeValue = ruleToEdit.maxLimit;
        ruleattr.Uom = { uoMsymbol: ruleToEdit.uoM, uoMid: ruleToEdit.uoMID };
        if (ruleToEdit.ruleTypeID === 9) {
          ruleattr.equalityCondition = { value: secondValue };
        } else if (ruleToEdit.ruleTypeID !== 9 && (ruleToEdit.maxLimit || ruleToEdit.maxLimit === 0) && (ruleToEdit.minLimit || ruleToEdit.minLimit === 0)) {
          ruleattr.equalityCondition = { value: ruleToEdit.equalityCondition.split(';')[1] };
        } else {
          ruleattr.equalityCondition = { value: ruleToEdit.equalityCondition };
        }
      }
      if (ruleattr.attributeName === 'Min' && (ruleToEdit.minLimit || ruleToEdit.minLimit === 0)) {
        ruleattr.isRequired = true;
        ruleattr.attributeValue = ruleToEdit.minLimit;
        ruleattr.Uom = { uoMsymbol: ruleToEdit.uoM, uoMid: ruleToEdit.uoMID };
        if (ruleToEdit.ruleTypeID === 9) {
          ruleattr.equalityCondition = { value: firstValue };
        } else if (ruleToEdit.ruleTypeID !== 9 && (ruleToEdit.maxLimit || ruleToEdit.maxLimit === 0) && (ruleToEdit.minLimit || ruleToEdit.minLimit === 0)) {
          ruleattr.equalityCondition = { value: ruleToEdit.equalityCondition.split(';')[0] };
        } else {
          ruleattr.equalityCondition = { value: ruleToEdit.equalityCondition };
        }
      }

      if (ruleattr.attributeName === 'Function' && ruleToEdit.functionList && ruleToEdit.functionList.length > 0 && ruleToEdit.ruleTypeID !== 11) {
        ruleattr.isRequired = true;
        let functionVal = ruleToEdit.functionList[0];
        if (ruleToEdit.functionList[0].functionId === null && ruleToEdit.functionList[0].functionName === null) {
          functionVal = null;
        }
        ruleattr.attributeValue = { functionName: functionVal ? functionVal.functionName : 'Any', functionId: functionVal ? functionVal.functionId : 'Any' };
      }
      if (ruleattr.attributeName === 'Function' && (ruleToEdit.ruleTypeID === 11) && ruleToEdit.substanceList && ruleToEdit.substanceList.length > 0) {
        ruleattr.isRequired = true;
        const functionVal = ruleToEdit.substanceList[0].substanceFunction;
        ruleattr.attributeValue = { functionName: functionVal ? functionVal.functionName : 'Any', functionId: functionVal ? functionVal.functionId : 'Any' };
      }

      if (ruleattr.attributeName === 'Warning' && ruleToEdit.negativeStatement) {
        ruleattr.isRequired = true;
        ruleattr.attributeValue = ruleToEdit.negativeStatement;
      }
      if (ruleattr.attributeName === 'Warning' && ruleToEdit.warningText && (ruleToEdit.ruleTypeID === 10 || ruleToEdit.ruleTypeID === 49 )) {
        ruleattr.isRequired = true;
        ruleattr.attributeValue = ruleToEdit.warningText;
      }
      if (ruleattr.attributeName === 'Other Requirements & Restrictions' && ruleToEdit.additionalStatement) {
        ruleattr.isRequired = true;
        ruleattr.attributeValue = ruleToEdit.additionalStatement;

      }
      if (ruleattr.attributeName === 'Conditional Requirements' && ruleToEdit.speficicConditions.length > 0) {
        ruleattr.isRequired = true;
        ruleattr.attributeValue = ruleToEdit.speficicConditions[0].specificConditionText;
      }
      if (ruleattr.attributeName === 'Claims' && ruleToEdit.claims.length > 0) {
        ruleattr.isRequired = true;
        ruleattr.attributeValue = ruleToEdit.claims[0].claimText;
      }
      if (ruleattr.attributeName === 'CUC') {
        ruleattr.parentPropertyAttributeValue = { propertyClass: ruleToEdit.propertyList[0].propertyClass, propertyId: ruleToEdit.propertyList[0].parentPropertyId };
        ruleattr.subPropertyAttributeValue = { propertyName: ruleToEdit.propertyList[0].propertyName, propertyId: ruleToEdit.propertyList[0].propertyId };
        this.getSubPropertiesMethodInvoked(ruleattr.parentPropertyAttributeValue);
      }
      if (ruleattr.attributeName === 'CUC Property') {
        ruleattr.parentPropertyAttributeValue = { propertyClass: ruleToEdit.propertyList[0].propertyClass, propertyId: ruleToEdit.propertyList[0].parentPropertyId };
        ruleattr.subPropertyAttributeValue = { propertyName: ruleToEdit.propertyList[0].propertyName, propertyId: ruleToEdit.propertyList[0].propertyId };
        this.getSubPropertiesMethodInvoked(ruleattr.parentPropertyAttributeValue);
      }
      if (ruleattr.attributeName === 'Substance' && ruleToEdit.substanceList.length > 0) {
        if (this.ruleTypeId === 11) {
          this.disablebtn = false;
          this.selectedRowSubstance = ruleToEdit.substanceList[0];
        }
        ruleattr.isRequired = true;
        this.selectedSubstanceName = ruleToEdit.substanceList[0].sirId + ' (' + ruleToEdit.substanceList[0].substanceName + ')';
      }
      if (ruleattr.attributeName === 'Substance(s)' && ruleToEdit.substanceList.length > 0) {
        if (this.ruleTypeId === 45) {
          this.setAllMandatoryFlag = ruleToEdit.allSubstancesMandatory === 'Y' ? true : false;
          this.disablebtn = false;
          this.subsList = [];
          this.selectedRowSubstance = ruleToEdit.substanceList;
          ruleToEdit.substanceList.forEach(element => {
            this.subsList.push(this.subsList.length);
            this.selectedSubsList.push(element.sirId + ' (' + element.substanceName + ')');
            this.searchingInputs.push(element.sirId + ' (' + element.substanceName + ')');
          });
        }
        ruleattr.isRequired = true;
      }
    });
  }
  onTabChange(activeTab) {
    this.activeTabUR = activeTab;
    if (activeTab === 'rule-detail') {
      document.getElementById('rule-detail').style.display = 'block';
      document.getElementById('description').style.display = 'none';
      document.getElementById('ruleValidity').style.display = 'none';
    } else if (activeTab === 'description') {
      document.getElementById('rule-detail').style.display = 'none';
      document.getElementById('description').style.display = 'block';
      document.getElementById('ruleValidity').style.display = 'none';
    } else if (activeTab === 'ruleValidity') {
      document.getElementById('rule-detail').style.display = 'none';
      document.getElementById('description').style.display = 'none';
      document.getElementById('ruleValidity').style.display = 'block';
    }
  }
  getCucProperties() {
    /** Getting CUC Properties from Request */
    this.assessReqRAService.getCucParentProperties().subscribe((response: any[]) => {
      if (response.length !== 0 && response !== null) {
        this.productParentProperties = response;
      } else {
        this.productParentProperties = [];
      }
    }, (error) => {
    });
  }
  /* istanbul ignore next */
  getSubProperties(selectedValue, ruleobj, index) {
    /** Getting CUC Sub Properties from Request */
    if (ruleobj.subPropertyAttributeValue) {
      this.ruleSpecificAttributes[index].subPropertyAttributeValue = null;
    }
    this.getSubPropertiesMethodInvoked(selectedValue);
  }
  /* istanbul ignore next */
  getSubPropertiesMethodInvoked(selectedValue) {
    this.productSubProperties = [];
    this.productSubPropertiesDD = [];
    this.loading = true;
    this.loadingMsg = 'Fetching data from FAST Rule-Engine';
    this.assessReqRAService.getCucChildProperties(selectedValue.propertyId).subscribe((response: any[]) => {
      this.loading = false;
      if (response.length !== 0 && response !== null) {
        this.productSubProperties = response;
      } else {
        this.productSubProperties = [];
      }
    }, (error) => {
      this.loading = false;
    });
  }
  getUomList() {
    const url = environment.APIFAsT + '/uoms';
    this.assessReqRAService.getData(url).subscribe((response) => {
      if (response !== null) {
        this.uomList = response;
      }
    }, (error) => {
    });
  }
  getFnList() {
    const functionApiUrl = environment.APIFAsT + '/functions';
    this.assessReqRAService.getData(functionApiUrl).subscribe((response) => {
      this.allFns = response;
    }, (error) => {
    });
  }
  /* istanbul ignore next */
  checkRangeValidation(ruleAttributeObj, ruleSpecificAttributes) {
    let minValue; let maxValue;
    if (this.ruleTypeId === 9) {
      this.ruleSpecificAttributes.forEach((ruleattr) => {
        if (ruleattr.attributeName === 'Max') {
          maxValue = ruleattr.attributeValue;
        }
        if (ruleattr.attributeName === 'Min') {
          minValue = ruleattr.attributeValue;
        }
      });
      if (Number(minValue) > Number(maxValue)) {
        this.rangeValidationFailed = true;
      } else {
        this.rangeValidationFailed = false;
      }
    }
  }
  /* istanbul ignore next */
  getRuleSpecificAttributeList(ruleTypeId) {
    const ruledetails = RuletypesAttributeMapping.filter(obj => obj.ruleBucket === 'Product Based' && obj.ruleTypeId === ruleTypeId)[0];
    this.ruleDetails = Object.assign({}, ruledetails);
    const rsListAttr = this.ruleDetails.attributeList;
    this.ruleSpecificAttributes = rsListAttr.map(x => Object.assign({}, x));
    for (let i = 0; i < this.ruleSpecificAttributes.length; i++) {
      if ((this.ruleSpecificAttributes[i].attributeTemplate === 'SetLimit' && this.ruleSpecificAttributes[i].isRequired)
        || (this.ruleSpecificAttributes[i].attributeTemplate === 'SetText' && this.ruleSpecificAttributes[i].isRequired)
        || (this.ruleSpecificAttributes[i].attributeTemplate === 'SetDate' && this.ruleSpecificAttributes[i].isRequired)) {
        this.showSetVar = true;
        this.setIndex = i;
        break;
      } else {
        this.showSetVar = false;
      }
    }
    for (let i = 0; i < this.ruleSpecificAttributes.length; i++) {
      if ((this.ruleSpecificAttributes[i].attributeTemplate === 'When' && this.ruleSpecificAttributes[i].isRequired)
        || (this.ruleSpecificAttributes[i].attributeTemplate === 'WhenText' && this.ruleSpecificAttributes[i].isRequired)
        || (this.ruleSpecificAttributes[i].attributeTemplate === 'WhenSelect' && this.ruleSpecificAttributes[i].isRequired)
        || (this.ruleSpecificAttributes[i].attributeTemplate === 'WhenMultiSelect' && this.ruleSpecificAttributes[i].isRequired)
        || (this.ruleSpecificAttributes[i].attributeTemplate === 'WhenPropertySelect' && this.ruleSpecificAttributes[i].isRequired)) {
        this.showWhenVar = true;
        this.whenIndex = i;
        break;
      } else {
        this.showWhenVar = false;
      }
    }
  }
  /* istanbul ignore next */
  removeRTAttribute(value, index: number) {
    value.attributeValue = null;
    value.Uom = [];
    this.ruleSpecificAttributes[index].attributeValue = null;
    this.ruleSpecificAttributes[index].Uom = [];
    this.ruleSpecificAttributes.filter(x => x.attributeName === value.attributeName)[0].isRequired = false;
    this.removeSetWhenLabel();
  }
  /* istanbul ignore next */
  removeRTAttributeHide(value, index: number) {
    value.attributeValue = null;
    value.Uom = [];
    this.ruleSpecificAttributes[index].attributeValue = null;
    this.ruleSpecificAttributes[index].Uom = [];
    if (value.isRequired === false) {
      this.ruleSpecificAttributes.filter(x => x.attributeName === value.attributeName)[0].isRequired = false;
    }
    this.removeSetWhenLabel();
  }
  addSubstanceAttribute() {
    this.subsList.push(this.subsList.length);
  }
  /* istanbul ignore next */
  removeSubstanceAttribute(value, index: number, j: number) {
    if (j !== 0) {
      this.subsList.splice(this.subsList.indexOf(index), 1);
      this.selectedRowSubstance.splice(index, 1);
      this.selectedSubsList.splice(index, 1);
      this.searchingInputs.splice(index, 1);
    } else {
      this.selectedSubsList[j] = '';
      this.selectedRowSubstance[j] = '';
      this.selectedSubsList[j] = '';
      this.searchingInputs[j] = '';
    }
  }
  /* istanbul ignore next */
  onSaveAndClose() {
    this.saveAndAddNewDialog = false;
    this.saveAndAddNewBtnClicked = false;
    this.productSubProperties = [];
    this.onSave();
    if (!this.editRule) {
      this.setAllMandatoryFlag = false;
      setTimeout(() => this.dataServiceObj.setExpandablePanel(1), 3500);
    }
  }
  /* istanbul ignore next */
  onSaveandAddNew() {
    this.onSave();
  }
  /* istanbul ignore next */
  onSave() {
    // tslint:disable-next-line: prefer-const
    let maxValue; let uomValue; let maxEqualityValue; let minValue;
    let selectedSubstance; let substanceData; let minEqualityValue; let equalityCondition;
    let propertyNameObj = { propertyId: '', propertyName: '', propertyClass: '', propertyValueType: '' };
    let propertyClassObj = { propertyId: '', propertyName: '', propertyClass: '', propertyValueType: '' };
    let fnValue = { functionId: '', functionName: '' };
    let fnId; let fnName; let uoMSymbol; let uoMId;
    let warningText; let otherText; let spConditionText; let claimsText;
    let ruleType;
    const rpcData = [];
    ruleType = this.ruleDetails.ruleType;
    this.ruleSpecificAttributes.forEach((ruleattr) => {

      if (ruleattr.attributeName === 'Max') {
        maxValue = ruleattr.attributeValue;
        uomValue = ruleattr.Uom;
        if (uomValue !== undefined && uomValue !== null) {
          uoMId = uomValue.uoMid;
          uoMSymbol = uomValue.uoMsymbol;
        }
        maxEqualityValue = ruleattr.equalityCondition === undefined && maxValue ? { value: '<' } : ruleattr.equalityCondition;
        if (maxEqualityValue) {
          equalityCondition = maxEqualityValue.value;
        }
      }
      if (ruleattr.attributeName === 'Min') {
        minValue = ruleattr.attributeValue;
        uomValue = ruleattr.Uom;
        if (uomValue !== undefined && uomValue !== null) {
          uoMId = uomValue.uoMid;
          uoMSymbol = uomValue.uoMsymbol;
        }
        minEqualityValue = ruleattr.equalityCondition === undefined && minValue ? { value: '>' } : ruleattr.equalityCondition;
        if (minEqualityValue) {
          equalityCondition = minEqualityValue.value;
        }
      }
      if (ruleattr.attributeName === 'Function') {
        fnValue = ruleattr.attributeValue;
        if (fnValue !== undefined && fnValue !== null && fnValue.functionName !== 'Any') {
          fnId = fnValue.functionId;
          fnName = fnValue.functionName;
        }
      }
      if (ruleattr.attributeName === 'Warning') {
        warningText = ruleattr.attributeValue;
      }
      if (ruleattr.attributeName === 'Other Requirements & Restrictions') {
        otherText = ruleattr.attributeValue;
      }
      if (ruleattr.attributeName === 'Conditional Requirements') {
        spConditionText = ruleattr.attributeValue;
      }
      if (ruleattr.attributeName === 'Claims') {
        claimsText = ruleattr.attributeValue;
      }
      if (ruleattr.attributeName === 'CUC') {
        propertyClassObj = ruleattr.parentPropertyAttributeValue;
        propertyNameObj = ruleattr.subPropertyAttributeValue;
      }
      if (ruleattr.attributeName === 'CUC Property') {
        propertyClassObj = ruleattr.parentPropertyAttributeValue;
        propertyNameObj = ruleattr.subPropertyAttributeValue;
      }
    });
    if (this.ruleTypeId === 9 || (minValue !== undefined && maxValue !== undefined)) {  // for Range Rule
      equalityCondition = minEqualityValue.value + ';' + maxEqualityValue.value;
    }
    if (minValue === undefined && maxValue === undefined) {
      equalityCondition = '';
    }
    if (prdtRuleDetailsModel.CascadeRpc === 'Y') {
      prdtRuleDetailsModel.selectedParentRpcsPdtBased.forEach((value) => {
        const data = {
          rpcFullText: value.rpcFullText,
          rpcName: value.text,
          rpcId: value.id
        };
        rpcData.push(data);
      });
    } else {
      prdtRuleDetailsModel.selectedRpcsPdtBased.forEach((value) => {
        const data = {
          rpcFullText: value.rpcFullText,
          rpcName: value.text,
          rpcId: value.id
        };
        rpcData.push(data);
      });
    }
    const productPropertyData = [{
      PropertyId: propertyNameObj.propertyId ? propertyNameObj.propertyId : null,
      PropertyClass: propertyClassObj.propertyClass ? propertyClassObj.propertyClass : null,
      PropertyName: propertyNameObj.propertyName ? propertyNameObj.propertyName : null,
      PropertyRecipeQuantity: null,
      PropertyTargetQuantity: null,
      PropertyMaxQuantity: maxValue ? maxValue : null,
      PropertyMinQuantity: minValue ? minValue : null,
      PropertyUoM: uoMSymbol ? uoMSymbol : '',
      PropertyValueType: propertyNameObj.propertyValueType,
      ClaimsListFromRules: null,
      SpecialConditionListFromRules: null,
      UserInputClaim: null,
      UserInputSpecificCondition: null,
      PropertyRuleStatus: null,
    }];
    if (this.selectedRowSubstance.length !== 0 && this.ruleTypeId === 11) {
      selectedSubstance = this.selectedRowSubstance;
      substanceData = [{
        PureCode: selectedSubstance.pureCode,
        PureDescription: selectedSubstance.pureDescription,
        SirId: selectedSubstance.sirId,
        SubstanceName: selectedSubstance.substanceName,
        InciName: selectedSubstance.inciName.toString(),
        Einecs: selectedSubstance.einecs.toString(),
        Cas: selectedSubstance.cas.toString(),
        parentCAS: selectedSubstance.parentCAS.toString(),
        Ins: selectedSubstance.ins.toString(),
        ENumber: selectedSubstance.eNumber.toString(),
        SubstanceFunction: fnId && fnName ? { FunctionId: fnId ? fnId : null, FunctionName: fnName ? fnName : '' } : null,
        CFDetails: selectedSubstance.cfDetails
      }];
    }
    if (this.selectedRowSubstance.length !== 0 && this.ruleTypeId === 45) {
      substanceData = this.selectedRowSubstance.map(element => {
        return {
          PureCode: element.pureCode,
          PureDescription: element.pureDescription,
          SirId: element.sirId,
          SubstanceName: element.substanceName,
          InciName: element.inciName.toString(),
          Einecs: element.einecs.toString(),
          Cas: element.cas.toString(),
          parentCAS: element.parentCAS.toString(),
          Ins: element.ins.toString(),
          ENumber: element.eNumber.toString(),
          SubstanceFunction: fnId && fnName ? { FunctionId: fnId ? fnId : null, FunctionName: fnName ? fnName : '' } : null,
          CFDetails: element.cfDetails
        };
      });
    }
    let saveRuleToDbUrl;

    const savedData = [{
      Markets: [prdtRuleDetailsModel.Market],
      RPCs: rpcData, /// object list
      RuleId: this.ruleIdOnEdit ? this.ruleIdOnEdit : 0,
      RuleTypeID: this.ruleTypeId,
      RuleVersion: this.ruleVersion ? this.ruleVersion : 0,
      RuleType: ruleType,
      SubstanceList: this.ruleTypeId === 11 || this.ruleTypeId === 45 ? substanceData : null,
      AllSubstancesMandatory: this.ruleTypeId === 45 && this.setAllMandatoryFlag === true ? 'Y' : 'N',
      PropertyList: this.ruleTypeId !== 10 && this.ruleTypeId !== 41 && this.ruleTypeId !== 11 && this.ruleTypeId !== 45 ? productPropertyData : null,
      CascadeMarket: 'N',
      CascadeRpc: 'Y',
      UoMID: uoMId ? uoMId : 2,
      UoM: uoMSymbol ? uoMSymbol : 'N/A',
      TexualMinLimitText: '',
      TexualMaxLimitText: '',
      RuleDescription: ruleType,
      RuleSource: this.ruleSource ? this.ruleSource : '',
      RuleSourceType: '',
      AdditionalStatement: otherText ? otherText : '', // other requirements and restrictions
      PositiveStatement: '',
      MaxLimit: (maxValue || maxValue === 0) ? maxValue : '',
      MinLimit: (minValue || minValue === 0)  ? minValue : '',
      EqualityCondition: equalityCondition,
      Claims: claimsText ? [{ ClaimText: claimsText ? claimsText : '' }] : null,
      SpeficicConditions: spConditionText ? [{ SpecificConditionText: spConditionText ? spConditionText : '' }] : null,
      FunctionList: fnId && fnName ? [{ FunctionId: fnId ? fnId : null, FunctionName: fnName ? fnName : '' }] : [], // used only for allowed function array of objects
      NegativeStatement: (this.ruleTypeId !== 10) ? warningText : '',
      PreparationMethod: recipeModel.preparationMethod,
      IntendedUse: recipeModel.intendedUse,
      IsMandatorySubstance: null, // Y for substance mandatory rule
      PhasedOutDate: null,
      WarningText: (this.ruleTypeId === 10) ? warningText : null, // used for warning rule, adulterated rule , phased out rule, other requirements and restrictions, Product Warnings, CUC Property Warning
      yearAdopted: this.yearAdopted ? this.datePipe.transform(this.yearAdopted, 'yyyy-MM-dd') : null,
      ValidityStartDate: this.validityStartDate ? this.datePipe.transform(this.validityStartDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityFromDate, 'yyyy-MM-dd'),
      ValidityDate: this.validityEndDate ? this.datePipe.transform(this.validityEndDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityEndDate, 'yyyy-MM-dd'),
      Comments: this.ruleComment ? this.ruleComment : null,
      RuleFlow: this.parallelRuleWorkflow === true ? 'Parallel' : 'Request'
    }];
    this.loading = true;
    this.loadingMsg = 'Saving Rule...';
    if (!this.editRule) {
      saveRuleToDbUrl = environment.APIFAsTRule + '/rulesList';
      this.assessReqRAService.postData(saveRuleToDbUrl, savedData).subscribe((response) => {
        this.loading = false;
        if (response[0].ruleId !== 0) {
          this.ruleAdded = response;
          this.getRuleDetailsFromDb();
          this.snackBar.open('Rule created successfully with Rule Id:' + response[0].ruleId, 'close', {
            duration: 5600,
            panelClass: ['success']
          });
          this.selectedSubstanceName = '';
          if (this.saveAndAddNewBtnClicked === true) {
            setTimeout(() => {
              this.applicableRuleTypesToDisplay = prdtRuleDetailsModel.applicableRuleTypeList;
              this.saveAndAddNewDialog = true;
            }, 3000);
            this.selectedSubstanceName = '';
          }
        }
      }, (error) => {
        this.loading = false;
        this.selectedSubstanceName = '';
      });
    } else {
      saveRuleToDbUrl = environment.APIFAsTRule + '/rules';
      this.assessReqRAService.putData(saveRuleToDbUrl, savedData).subscribe((response) => {
        this.loading = false;
        this.clearValues();
        if (response[0].ruleId !== 0) {
          this.ruleAdded = response;
          this.snackBar.open('Rule updated successfully with Rule Id:' + response[0].ruleId + ' and Rule Version: ' + response[0].ruleVersion, 'close', {
            duration: 5000,
            panelClass: ['success']
          });
          this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
            this.route.navigate(['/rule-management/view-rules']);
          });
        } else {
          this.snackBar.open('Rule could not be created.', 'close', {
            duration: 5000,
            panelClass: ['error']
          });
        }
      }
        , (error) => {
          this.loading = false;
        }
      );
    }
  }
  /* istanbul ignore next */
  ruleTypeRadioButtonChanged(event) {
    this.ruleTypeRadioSelected = true;
    this.rtRadioSelected = event.target.value;
  }
  /* istanbul ignore next */
  getRuleDetailsFromDb() {
    this.rpc = [];
    prdtRuleDetailsModel.selectedRpcsPdtBased.forEach((value) => {
      const data = {
        rpcFullText: value.rpcFullText,
        rpcName: value.text,
        rpcId: value.id
      };
      this.rpc.push(data);
    });
    const fetchRuleBody = {
      MarketList: [prdtRuleDetailsModel.Market],
      RPCList: this.rpc,
      ruleClassifications: ['Property Based Rules', 'Product Based Rules']
    };
    this.loading = true;
    this.loadingMsg = 'Fetching data from FAST Rule-Engine';
    const getruleapiurl = environment.APIFAsTRule + '/rules/rulesFilter';
    this.assessReqRAService.postData(getruleapiurl, fetchRuleBody).subscribe((response) => {
      this.loading = false;
      if (response !== null || response !== []) {
        this.pdtRulesList = response;
        this.pdtRulesList = _.filter(this.pdtRulesList, (val) =>
          val.ruleClassification === 'Product Based Rules' || val.ruleClassification === 'Property Based Rules');
        for (const value of this.pdtRulesList) {
          value.ruleVerionToDisplay = 'V' + value.ruleVersion;
          if (value.ruleTypeID === 7 || value.ruleTypeID === 8 || value.ruleTypeID === 9) {
            value.propertySource = 'CUC';
          }
          if (value.ruleTypeID === 49) {
            value.propertySource = 'CUC Property';
          }
          if (value.warningText === null) {
            value.originalWarningText = value.warningStatement;
            const warningLinkRuleData = this.assessReqRAService.hyperLinksToSheet(value.warningText);
            value.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
            value.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
            value.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
            value.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
            value.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
            value.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
            value.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
            value.warningText = this.changeComment(value.warningStatement);
            // value.warningText = value.warningStatement;
          }
          if (value.cfDetails !== null && value.cfDetails !== undefined) {
            const cfValue = value.cfDetails;
            value.cf = cfValue.cfDisplayText;
            value.cfSubstanceExpressedAs = cfValue.expressedAs;
          }
        }
        this.dataServiceObj.setRulesListForProduct(this.pdtRulesList);
      }
    }, (error) => {
      this.loading = false;
    });

  }
  /* istanbul ignore next */
  onCancel() {
    if (!this.editRule) {
      this.clearValues();
      this.dataServiceObj.setExpandablePanel(1);
    } else {
      this.clearValues();
      this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
        this.route.navigate(['/rule-management/view-rules']);
      });
    }
  }
  /* istanbul ignore next */
  onContinue() {
    this.saveAndAddNewDialog = false;
    this.saveAndAddNewBtnClicked = false;
    this.productSubProperties = [];
    if (this.rtRadioSelected !== undefined) {
      const obj = _.filter(prdtRuleDetailsModel.applicableRuleTypeList, (element) => element.ruleTypeDisplayName === this.rtRadioSelected);
      // tslint:disable-next-line: no-shadowed-variable
      const obj1 = obj.reduce((obj, newObj, i) => {
        newObj[i] = obj;
        return newObj;
      }, {});
      this.dataServiceObj.setSelectedProductRuleType(obj1);
      this.dataServiceObj.setExpandablePanel(2);
    }
  }
  onAddAttributeBtnClicked() {
    this.attBtnClicked = !this.attBtnClicked;
  }
  /* istanbul ignore next */
  valueSelected(val: any, index: any) {
    if (this.ruleTypeId === 11) {
      this.valConfRule = val.sirId + ' (' + val.substanceName + ')';
      // 'val' param gets you the entire object from API
      this.selectedRowSubstance = val;
      this.selectedSubstanceName = val.sirId + ' (' + val.substanceName + ')';
      this.checkSelectedVal = val.sirId + ' (' + val.substanceName + ')';
      this.pureCodeDD = false;
      this.disablebtn = false;
    } else if (this.ruleTypeId === 45) {
      this.valConfRule = val.sirId + ' (' + val.substanceName + ')';
      this.searchingInputs[index] = this.valConfRule + ' ' + val.pureCode;
      // 'val' param gets you the entire object from API
      this.selectedRowSubstance[index] = val;
      this.selectedSubsList[index] = val.sirId + ' (' + val.substanceName + ')';
      this.checkSelectedVal = val.sirId + ' (' + val.substanceName + ')';
      this.pureCodeDD = false;
      this.disablebtn = false;
      if (this.selectedSubsList.length !== this.selectedRowSubstance.length) {
        this.productMinSubValidationFlag = true;
        this.productWarningSubValidationFlag = true;
      } else {
        this.productMinSubValidationFlag = false;
        if (this.ruleTypeId === 49) {
          this.ruleSpecificAttributes.forEach((ruleattr) => {
            this.validateWarning(ruleattr.Warning);
          });
        }
      }
    }
  }
  /* istanbul ignore next */
  filterSubstanceName(event) {
    this.shownoresult = false;
    this.valConfRule = event.target.value;
    this.productMinSubValidationFlag = true;
    this.productWarningSubValidationFlag = true;
  }
  /* istanbul ignore next */
  filterSubstances() {
    this.shownoresult = false;
    this.filteredSubstances = [];
    if (this.valConfRule) {
      if (!this.searchingInputs.some(el => el.includes(this.valConfRule))) {
        this.loading = true;
        this.loadingMsg = 'Fetching data from FAST Rule-Engine';
        this.assessReqRAService.getConflictingDataSearchResults(this.valConfRule).subscribe((response: any[]) => {
          if (response.length > 0) {
            this.loading = false;
            this.filteredSubstances = response;
            this.pureCodeDD = true;
          } else {
            this.loading = false;
            this.pureCodeDD = false;
            this.shownoresult = true;
          }
        }, (error) => {
          this.loading = false;
        });
      } else {
        this.snackBar.open('Substance already Added', 'close', {
          duration: 4000,
          panelClass: ['error']
        });
      }
    }
  }
  checkval(event) {
    if (this.selectedSubsList.length !== this.selectedRowSubstance.length) {
      this.productMinSubValidationFlag = true;
      this.productWarningSubValidationFlag = true;
    } else if (this.subsList) {
      this.subsList.forEach(sub => {
        if (sub === undefined) {
          this.productMinSubValidationFlag = true;
          this.productWarningSubValidationFlag = true;
        }
      });
    }
    setTimeout(() => {
      if (event.target.value !== this.checkSelectedVal) {
        this.disablebtn = true;
        this.shownoresult = false;
        this.pureCodeDD = false;
        this.filteredSubstances = [];
      }
    }, 1000);
  }
  /* istanbul ignore next */
  clearValues() {
    this.ruleDetails = [];
    this.valConfRule = '';
    this.selectedSubstanceName = '';
    this.selectedRowSubstance = null;
    this.disablebtn = true;
    this.saveAndAddNewDialog = false;
    this.saveAndAddNewBtnClicked = false;
    this.productSubProperties = [];
    this.pureCodeDD = false;
    this.filteredSubstances = [];
    this.checkSelectedVal = '';
    this.setAllMandatoryFlag = false;
    this.searchingInputs = [];
  }
  /* istanbul ignore next */
  removeSetWhenLabel() {
    for (let i = 0; i < this.ruleSpecificAttributes.length; i++) {
      if ((this.ruleSpecificAttributes[i].attributeTemplate === 'SetLimit' && this.ruleSpecificAttributes[i].isRequired) ||
        (this.ruleSpecificAttributes[i].attributeTemplate === 'SetText' && this.ruleSpecificAttributes[i].isRequired) || (this.ruleSpecificAttributes[i].attributeTemplate === 'SetDate' && this.ruleSpecificAttributes[i].isRequired)) {
        this.showSetVar = true;
        this.setIndex = i;
        break;
      } else {
        this.showSetVar = false;
      }
    }
    for (let i = 0; i < this.ruleSpecificAttributes.length; i++) {
      if ((this.ruleSpecificAttributes[i].attributeTemplate === 'When' && this.ruleSpecificAttributes[i].isRequired) ||
        (this.ruleSpecificAttributes[i].attributeTemplate === 'WhenText' && this.ruleSpecificAttributes[i].isRequired) ||
        (this.ruleSpecificAttributes[i].attributeTemplate === 'WhenSelect' && this.ruleSpecificAttributes[i].isRequired) ||
        (this.ruleSpecificAttributes[i].attributeTemplate === 'WhenMultiSelect' && this.ruleSpecificAttributes[i].isRequired) ||
        (this.ruleSpecificAttributes[i].attributeTemplate === 'WhenPropertySelect' && this.ruleSpecificAttributes[i].isRequired)) {
        this.showWhenVar = true;
        this.whenIndex = i;
        break;
      } else {
        this.showWhenVar = false;
      }
    }
  }
  /* istanbul ignore next */
  checkUomValue(event) {
    const value = event.target.value;
    if (value.length > 0) {
      this.uomValidation = true;
    } else {
      this.uomValidation = false;
    }
  }
  checkWarningText(event) {
    if (this.productBasedRuleForm.value.attributeText_Warning === undefined || this.productBasedRuleForm.value.attributeText_Warning.length <= 0 ) {
      this.validationFailed = true;
    }
  }
  /* istanbul ignore next */
  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }
  /* istanbul ignore next */
  searchCUCFunction(event) {
    if (this.productBasedRuleForm.value.AttributeFnValue_ && this.productBasedRuleForm.value.AttributeFnValue_.propertyId === undefined) {
      this.validationFailed = true;
      this.productSubProperties = [];
      this.productSubPropertiesDD = [];
    }
    const filteredRpc = [];
    const query = event.query;
    for (const value of this.productParentProperties) {
      if (value.propertyClass.toLowerCase().includes(query.toLowerCase())) {
        filteredRpc.push(value);
      }
    }
    this.productParentPropertiesDD = filteredRpc;
  }
  /* istanbul ignore next */
  searchCUCSubProperty(event) {
    if (this.productBasedRuleForm.value.AttributeFnValue_CUC_SubProperty && this.productBasedRuleForm.value.AttributeFnValue_CUC_SubProperty.propertyId === undefined) {
      this.validationFailed = true;
    }
    const filteredRpc = [];
    const query = event.query;
    for (const value of this.productSubProperties) {
      if (value.propertyName.toLowerCase().includes(query.toLowerCase())) {
        filteredRpc.push(value);
      }
    }
    this.productSubPropertiesDD = filteredRpc;
    this.productMinSubValidationFlag = false;
    if (this.ruleTypeId === 49) {
      this.ruleSpecificAttributes.forEach((ruleattr) => {
        this.validateWarning(ruleattr.attributeValue);
      });
    }
  }
  /* istanbul ignore next */
  clearSubProperty() {
    this.productSubProperties = [];
    this.productSubPropertiesDD = [];
  }
  selectedFuntionValue() {
    this.validationFailed = false;
  }
  onClickedOutside() {
    this.attBtnClicked = false;
  }
  /* istanbul ignore next */
  checkRulesAvail() {
    this.viewRelbtn = true;
    this.prepareRuleTableData = [];
    this.selectedRows = null;
    this.loadingMsg = 'Fetching data from FAsT Rule-Engine';
    this.loading = true;
    const apiurl = environment.APIFAsTRule + '/rules/queryrelatedrequests';
    const body = [];
    this.showRelatedRulesPopup = true;
    body.push({
      RuleId: this.ruleIdOnEdit,
      RuleVersion: this.ruleVersion
    });
    this.assessReqRAService.postData(apiurl, body).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.prepareRuleTableData = response.map(prodReq => {
          return {
            requestDisplayID: prodReq.requestDisplayID,
            cucCode: prodReq.cucCode,
            cucDescription: prodReq.cucDescription,
            recipeType: prodReq.recipeType,
            recipeStatus: prodReq.recipeStatus,
            marketId: prodReq.market.marketId,
            marketName: prodReq.market.marketName,
            division: (prodReq.tfc.tfcLevel === 5) ? prodReq.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName : prodReq.tfc.parentTFC.parentTFC.parentTFC.tfcName,
            rDFormulationCategory: (prodReq.tfc.tfcLevel === 5) ? prodReq.tfc.parentTFC.parentTFC.parentTFC.tfcName : prodReq.tfc.parentTFC.parentTFC.tfcName,
            formulationType: (prodReq.tfc.tfcLevel === 5) ? prodReq.tfc.parentTFC.parentTFC.tfcName : prodReq.tfc.parentTFC.tfcName,
            formulationClass: (prodReq.tfc.tfcLevel === 5) ? prodReq.tfc.parentTFC.tfcName : prodReq.tfc.tfcName,
            formulationSubClass: (prodReq.tfc.tfcLevel === 5) ? prodReq.tfc.tfcName : '',
            preparationMethod: prodReq.preparationMethod,
            intendedUse: prodReq.intendedUse,
            multicomponent: prodReq.isMultiComponent,
            requestStatus: prodReq.requestStatus,
            ra: prodReq.ra,
            allRa: prodReq.allRA,
            submittedDate: prodReq.submittedDate,
            submittedByUserId: prodReq.submittedByUserId,
            submittedByUserName: prodReq.submittedByUserName,
            comments: prodReq.comments,
            assignedDate: prodReq.assignedDate,
            inProgressRADate: prodReq.inProgressRADate,
            inProgressRDDate: prodReq.inProgressRNDDate,
            inProgressSIRDate: prodReq.inProgressSIRDate,
            rejectedDate: prodReq.rejectedDate,
            deletionDate: prodReq.deletionDate,
            completedDate: prodReq.completedDate,
            rdComment: prodReq.comments,
            raComment: prodReq.raComment,
            topTfcId: prodReq.tfcid,
            allRAIDs: prodReq.allRAIDs,
            raUserID: prodReq.raUserIDred,
            divisionid: (prodReq.tfc.tfcLevel === 5) ? prodReq.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId : prodReq.tfc.parentTFC.parentTFC.parentTFC.tfcId
          };
        });
      }
      const ele = document.getElementById('echeck') as HTMLInputElement;
      ele.checked = false;
      this.selectedRowsRelated = [];
    }, (error) => {
      this.loading = false;
    });
  }
  getWorklistColumns() {
    const apiurl = environment.APIFAsT + '/getworklistcolumns/relatedrequest';
    this.worklistservice.getWorklistApiData(apiurl).subscribe((response) => {
      this.myWorklistTableColumns = response;
    });
  }
  /* istanbul ignore next */
  sendEmail() {
    this.showEmailConfirmationDialog = true;
    let reqId = '';
    let userDetails = '';
    const uDetail = [];
    const reDet = [];
    this.selectedRowsRelated.forEach(element => {
      reDet.push(element.requestDisplayID);
    });
    const distinctReqs = reDet.filter((n, i) => reDet.indexOf(n) === i);
    reqId = distinctReqs.join(' ');
    this.selectedRowsRelated.forEach(element => {
      uDetail.push(element.ra);
    });
    const distinctNames = uDetail.filter((n, i) => uDetail.indexOf(n) === i);
    userDetails = distinctNames.join(' ');
    this.commentsToUser = 'Regulatory rule/s for the request no. ' + reqId + ' will be updated/deleted by RA user ' + userDetails + ' .To get the most updated FAsT report, please submit a new request for the formulation. Ignore if the formulation is no longer valid.';
  }
  /* istanbul ignore next */
  sendEmailMessage() {
    this.showEmailConfirmationDialog = false;
    this.showRelatedRulesPopup = false;
    const payLoad = {
      NotificationGroup: 'RDCOMPLETED',
      Subject: 'FAsT | Rules updated for these requests :',
      EmailBody: null,
      ToEmailList: '',
      FromEmail: null,
      CCEmailList: null,
      EmailType: 'html',
      UPD: this.ruleIdOnEdit.toString(),
      PureCodes: null,
      UserRequests: [],
      CUCDescription: null,
      Comments: this.commentsToUser
    };

    if (this.selectedRowsRelated.length > 0) {
      this.selectedRowsRelated.forEach(row => {
        const reqIds = this.selectedRowsRelated.filter(item => item.submittedByUserId === row.submittedByUserId).map(i => i.requestDisplayID);
        payLoad.UserRequests.push({
          userId: +row.submittedByUserId,
          requestIds: reqIds
        });
      });
    }

    if (payLoad.UserRequests.length > 0) {
      this.assessReqRAService.sendRulesEmailNotification(payLoad);
    }
  }
  onGroupSelect(checkValue) {
    if (checkValue) {
      this.selectedRowsRelated = this.prepareRuleTableData;
    } else {
      this.selectedRowsRelated = [];
    }
  }
  /* istanbul ignore next */
  maxlenghtCheck(data, count, atributeName) {
    if (data !== null && data !== undefined) {
      const dataWithoutHtmlCount = data.replace(/<(.|\n)*?>/g, '').length + 1;
      const datawithhtmlCount = data.length;
      this.validateWarning(data);
      this.ruleSpecificAttributes.forEach((ruleattr) => {
        if (ruleattr.attributeName === atributeName) {
          ruleattr.ankerCount = (data.match(/<a/g) || []).length;
        }
        this.checkmax7();
      });
      const diff = datawithhtmlCount - dataWithoutHtmlCount;
      if (count <= dataWithoutHtmlCount) {
        this.ruleSpecificAttributes.forEach((ruleattr) => {
          if (ruleattr.attributeName === atributeName) {
            ruleattr.attributeValue = ruleattr.attributeValue.substring(0, count + diff);
          }
        });
        return false;
      }
    }
  }
  checkmax7() {
    for (let index = 0; index < this.ruleSpecificAttributes.length; index++) {
        if (this.ruleSpecificAttributes[index].ankerCount > 7) {
            this.ankerCount = this.ruleSpecificAttributes[index].ankerCount;
            break;
        } else {
            this.ankerCount = this.ruleSpecificAttributes[index].ankerCount;
        }
    }
  }

  /* istanbul ignore next */
  validateWarning(data) {
    const datawithhtmlCount = data.length;
    if (datawithhtmlCount > 0) {
      this.productWarningSubValidationFlag = false;
    } else {
      this.productWarningSubValidationFlag = true;
    }
    if (this.ankerCount > 7) {
      this.productWarningSubValidationFlag = true;
    }
  }
  /* istanbul ignore next */
  changeComment(input) {
    const strValue = input;
    const ar = strValue.match(/<a.*?<\/a>/g);
    let commentwarning = '';
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentwarning = 'Click Here';
    } else {
      commentwarning = input;
    }
    return commentwarning;
  }
}
