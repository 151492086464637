import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { AuthorizationDataService } from './authorization-data.service';
import { AuthorizationService } from './authorization.service';

@Directive({
  selector: '[appHideIfUnauthorizedByPermissionId]'
})
export class HideIfUnauthorizedByPermissionIdDirective implements OnInit {
  @Input('appHideIfUnauthorizedByPermissionId') permissionId: string;

  constructor(private el: ElementRef, private authorizationService: AuthorizationService, private auth: AuthorizationDataService) { }
 /* istanbul ignore next */
  ngOnInit() {
    this.auth.getRolesDataByPermissionId().subscribe((val) => {
      if (val) {
          if (!this.authorizationService.hasPermissionLoadByPermissionId(this.permissionId, val)) {
              this.el.nativeElement.style.display = 'none';
          }
      }
  });
  }

}
