import { Injectable, Output } from '@angular/core';

@Injectable()
export class QuillSettingsService {

  @Output() public quillmodules = {
    toolbar: [
      ['bold', 'italic', 'underline'],        // toggled buttons
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],          // outdent/indent
      [{ align: [] }],
      ['clean'],                                         // remove formatting button
      ['link']                         // link
    ]
  };

  @Output() public quillformats = [
    'bold', 'italic', 'underline',
    'list', 'indent', 'align', 'link'
  ];

  constructor() { }

}
