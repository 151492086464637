import { Component, OnInit } from '@angular/core';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';

@Component({
  selector: 'app-rnd-inputs',
  templateUrl: './rnd-inputs.component.html',
  styleUrls: ['./rnd-inputs.component.scss']
})
export class RndInputsComponent implements OnInit {

  data;
  tabName;
  currentLink;
  comments;

  tabsList = [{ title: 'Mandatory Functions', isActive: true, isVisible: true },
  { title: 'Claims', isActive: false, isVisible: true },
  { title: 'Conditional Requirements', isActive: false, isVisible: true },
  { title: 'Ingredient Property Values', isActive: false, isVisible: true },
  { title: 'Comments', isActive: false, isVisible: true }];

  constructor(private assessRequestRaService: AssessRequestRaService) { }

  ngOnInit() {
    this.comments = this.assessRequestRaService.COMMENTS;
    this.handleOnMenuChange(this.tabsList[0], 0);
    this.data = recipeModel;
  }

  handleOnMenuChange(tab: any, i: number) {
    this.tabsList.forEach((t: any) => {
      t.isActive = false;
    });
    this.tabName = tab.title;
    this.currentLink = i;
    tab.isActive = true;
  }

}
