import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AssessRequestsComponent } from './assess-requests.component';
import { CreateRequestComponent } from './create-request/create-request.component';
import { SelectRecipeComponent } from './select-recipe/select-recipe.component';
import { SubmitRequestComponent } from './submit-request/submit-request.component';
import { SelectRecipePlmComponent } from './select-recipe-plm/select-recipe-plm.component';

const routes: Routes = [
    {
        path: '',
        component: AssessRequestsComponent,
        children: [
            {
                path: 'select-recipe',
                component: SelectRecipeComponent
            },
            {
                path: 'create-request',
                component: CreateRequestComponent
            },
            {
                path: 'submit-request',
                component: SubmitRequestComponent
            },
            {
                path: 'select-recipe-plm/:cucCode',
                component: SelectRecipePlmComponent
            },
            {
                path: 'select-recipe-plm/:cucCode/:alt',
                component: SelectRecipePlmComponent
            },
            {
                path: 'select-recipe-plm/:cucCode/:alt/:ver',
                component: SelectRecipePlmComponent
            },
            {
                path: 'select-recipe-plm/:cucCode/:alt/:ver/:RecipeType',
                component: SelectRecipePlmComponent
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AssessRequestsRoutingModule { }
