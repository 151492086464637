import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CdkStepper } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Table } from 'primeng/table';

import { IngredientData } from '../common/models/ingredientdata';
import { requestFlags } from '../common/models/PureRuleDetailsModel';
import { DataService } from '../common/services/data.service';
import { PlmFormService } from '../common/services/plm-form-service';
import { AssessmentRequestService } from './assess-requests.service';

@Component({
  selector: 'app-assess-requests',
  templateUrl: './assess-requests.component.html',
  styleUrls: ['./assess-requests.component.scss'],
  providers: [{ provide: CdkStepper, useValue: undefined }, PlmFormService]
})
/* istanbul ignore next */
export class AssessRequestsComponent implements OnInit {
  @ViewChild('stepper1') stepper1;
  @ViewChild('FCForm') FCForm: NgForm;
  @ViewChild('CucForm') CucForm: NgForm;
  @ViewChild('InputForm') InputForm: NgForm;
  @ViewChild('InputForm2') InputForm2: NgForm;
  @ViewChild('dt') dt: Table;
  @ViewChild('dt1') dt1: Table;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('autoInput', { read: MatAutocompleteTrigger }) matAutocompleteTrigger: MatAutocompleteTrigger;
  form1valid;
  form2valid;
  selectedMarkets = [];
  star = 'fa fa-fw fa-search';
  componentNameTab = 'AssessRequestsRD';
  formulationCode = '';
  columnLength = 800;
  selectedTab = 'ingredient';
  public result = null;
  public result1 = null;
  showFiller = false;
  fetchingdata = false;
  readOnlyValue = true;
  noRpc = false;
  onSearch = false;
  onReset = false;
  showProdWarn = false;
  onAssessClicked = false;
  isLoggedIn = false;
  isDialogRequired = false;
  loading = false;
  loadingMsg = 'Please wait User Authentication is in progress';
  firstRequest = 'Y';
  ingredientData = [];
  reqData = [];
  productData = [];
  productDataResults = [];
  totalPureQty: any;
  filteredData: any = [];
  pureValues: any = [];
  responsedataFormulationList: any = [];
  rpcList = [{
    RPCId: '',
    RPCName: '',
    RPCFullText: '',
    MRPCId: '',
    tmrmId: ''
  }];

  tfcList: any = [];
  functionVal: any;
  marketVal: any;
  responsedataMarket;
  responsedataFormulationAssessment;
  tfc1Id: any;
  tfc2Id: any;
  tfc3Id: any;
  tfc4Id: any;
  tfc5Id: any;
  keyDate: any;
  tmrmId: any;
  rowData: IngredientData[];
  userName;
  name;
  role;
  ingData = [];
  rulesResultData = [];
  components: any;
  cols: any[] = [];
  compositionDetailsColumns = [];
  functionListVals = [];
  filteredFunctionListVals = [];
  filteredIngredientTypes = [];
  filteredMarketListVals = [];
  substanceToBeDisplayed = [];
  displayMarket: any = '';
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectable = true;
  removable = true;
  marketChips = [];
  autocompleteTagsOptionActivated = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  public steps: string[];
  public selectedStep = 0;
  constructor(
    public formBuilder: FormBuilder,
    public assessService: AssessmentRequestService,
    private routersvc: Router,
    private route: ActivatedRoute,
    private msalSvc: MsalService,
    public dialogbox: MatDialog,
    public matSnackBar: MatSnackBar,
    private dataTrans: DataService) {
    this.components = {};

  }
  /* istanbul ignore next */
  ngOnInit() {
    this.dataTrans.setComponentName('AssessRequestsRD');
    setTimeout(() => this.stepper1 ?
      console.log('selectedStep-assess-requests', this.stepper1.selectedStep) : console.log('selectedStep-assess-requests not defined'), 1000);
    if (this.msalSvc.instance.getAllAccounts().length === 0) {
      this.loading = true;
      this.loadingMsg = 'Please wait User Authentication is in progress';
      setTimeout(() => {
        location.reload();
        this.loading = false;
      }, 5000);
    }
    if (this.msalSvc.instance.getAllAccounts()[0].username.length <= 0) {
      this.isLoggedIn = false;
    } else {
      this.isLoggedIn = true;
      this.name = this.msalSvc.instance.getAllAccounts()[0].name;
      this.userName = this.msalSvc.instance.getAllAccounts()[0].username;
    }
    // }
    // if (this.adalSvc.userInfo.profile.roles && this.adalSvc.userInfo.profile.roles.length > 0) {
    //   this.role = '(' + this.adalSvc.userInfo.profile.roles[0] + ')';
    // }
    if (localStorage.getItem('cucCode') !== '' || localStorage.getItem('cucCode') !== undefined) {
      localStorage.setItem('cucCode', '');
    }
    this.dataTrans.selectedStepValue.subscribe((value) => {
      console.log('selectedIndex', value);
    });

    if (this.routersvc.url.endsWith('/select-recipe') && (requestFlags.createRequest === undefined)) {
      requestFlags.viewRequest = false;
      requestFlags.EditRequest = false;
      requestFlags.createRequest = true;
    }
    if (requestFlags.viewRequest || requestFlags.EditRequest) {
      this.steps = this.route.snapshot.routeConfig.children.map(child => child.path);
      const step = this.steps[2];
      this.routersvc.navigate([step], { relativeTo: this.route });

    } else if (requestFlags.createRequest) {
      this.steps = this.route.snapshot.routeConfig.children.map(child => child.path);
      const step = this.steps[0];
      this.routersvc.navigate([step], { relativeTo: this.route });
    } else {
        this.steps = this.route.snapshot.routeConfig.children.map(child => child.path);
        const step = this.steps[6];
        requestFlags.createRequest = true;
      //  this.routersvc.navigate([step], { relativeTo: this.route });
    }

  }
  /* istanbul ignore next */
  selectionChanged(event: any) {
    this.selectedStep = event.selectedIndex;
    this.routersvc.navigate([this.steps[this.selectedStep]], { relativeTo: this.route });
  }
  onMarketChange(option) {
    this.onSearch = true;
    if (this.marketChips.length < 5) {
      this.marketChips.push(option);
    }
  }
  /* istanbul ignore next */
  changeTab(event?: any) {
    if (event.value === 'product-std') {
      this.selectedTab = 'product-std';
    } else if (event.value === 'ingredient') {
      this.selectedTab = 'ingredient';
    }
  }
  /* istanbul ignore next */
  displayFn(option) {
    if (option && option.marketId.length !== 0) {
      return option.marketName;
    } else {
      return '';
    }
  }
  onMarketSelect(event: any) {
    this.selectedMarkets.push(event);
  }
  onMarketSelectAll(event: any) {
    this.selectedMarkets = event;
  }
  onMarketDeSelect(event: any) {
    const index = this.selectedMarkets.indexOf(event);
    this.selectedMarkets.splice(index, 1);
  }

  onEnter(evt: any) {
    // this.onMarketChange();
  }


  optionActivated(event) {
    if (event.option) {
      this.autocompleteTagsOptionActivated = true;
    }
  }
  /* istanbul ignore next */
  optionSelected(event: MatAutocompleteSelectedEvent, value): void {
    this.autocompleteTagsOptionActivated = false;
    if (this.marketChips.length < 5) {
      this.marketChips.push(event.option.value);
    }
    if (value) {
      value = '';
    }
  }

}
