import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { AuthorizationDataService } from './authorization-data.service';
import { AuthorizationService } from './authorization.service';
// import { AuthPermission } from './authorization.types';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[hideIfUnauthorized]'
})
export class HideIfUnauthorizedDirective implements OnInit {
    @Input('hideIfUnauthorized') permissions: string; // Required permissions passed in
    // Use like below:
    // <div [hideIfUnauthorized]="'AraFast_DeleteRequest'"> <!-- a property set or passed into the component -->

    constructor(private el: ElementRef, private authorizationService: AuthorizationService, private auth: AuthorizationDataService) { }

    ngOnInit() {
        this.auth.getRolesData().subscribe((val) => {
            if (val) {
                if (!this.authorizationService.hasPermissionLoad(this.permissions, val)) {
                    this.el.nativeElement.style.display = 'none';
                }
            }
        });
    }
}
