import { DatePipe } from '@angular/common';
import { Component, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { groupRulesDetailsModel } from 'src/app/common/models/GroupRulesDetailsModel';
import { pureRulesDetailsModel, ruleFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { DataService } from 'src/app/common/services/data.service';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-banned-list-rule-template',
  templateUrl: './banned-list-rule-template.component.html',
  styleUrls: ['./banned-list-rule-template.component.scss']
})
export class BannedListRuleTemplateComponent implements OnInit, AfterViewInit {
  @ViewChild('groupMaxRuleForm') groupMaxRuleForm: NgForm;
  activeTabGR = 'rule-detail'; activeTab = 'banned-list';
  ruleTypeId: any; groupDetails: any; groupDetailsInitial: any;
  selectedRuleDetails = []; allFns = []; allowedFns = []; subGroupDetails = []; ruleLevels = []; rulesListForGroup = [];
  targetProducts = []; sourceProducts = []; selectedRowsRelated = []; myWorklistTableColumns = []; prepareRuleTableData = []; loading = false;
  selectedRuleLevelArray = []; selectedRuleTypeDetails = []; allRulesforRT = []; uomList = []; rpc = [];
  isAllCfSelected: boolean; saveAndAddNewBtnClicked: boolean; saveAndCloseClicked: boolean;
  parallelRuleWorkflow = false; cancelClicked = false; showEmailConfirmationDialog = false; ruleLevelClicked = false; readOnlyValue = true;
  ruleSource = ''; commentsToUser = ''; marketForModal = ''; selectedRuleLevel = '';
  validityStartDate: any = ''; validityEndDate: any = ''; yearAdopted: any = ''; ruleDescription = '';
  showRelatedRulesPopup = false; viewRelbtn = false; ruleSaveConformationPopup = false; editRuleFlow = false; viewRuleFlow = false;
  equalityCondition = [{ value: 'IsEqual' }, { value: 'IsGreater' }, { value: 'IsLesser' }];
  selectedFunction; selectedUoM; selectedEqualityCondition;
  substanceIndex;
  saveAndAddNewDialog;
  ruleAdded;
  applicableGroupRuleTypesToDisplay;
  ruleTypeRadioSelected;
  rtRadioSelected;
  ruleType;
  editRuleData;
  ruleIdOnEdit;
  ruleVersion;
  ruleComment: string; loadingMsg: string; ruleDisplayName: string;
  selectedRows;
  constructor(
    private assessReqRAdataService: AssessRequestRaService,
    public snackBarGroup: MatSnackBar,
    public formBuilder: FormBuilder,
    public dataSvcObj: DataService,
    private datePipe: DatePipe,
    private worklistservice: WorklistService,
    private route: Router) { }
  defaultValidityFromDate;
  defaultValidityEndDate;
  todayDate;


  ngAfterViewInit(): void {
    this.disableCheckbox();
  }
  disableCheckbox() {
    if (this.dataSvcObj.isfromSubstancesearch) {
      const picklistSource = document.getElementsByClassName('ui-picklist-source-wrapper').item(0) as HTMLElement;
      picklistSource.style.display = 'none';
      const picklistButton = document.getElementsByClassName('ui-picklist-buttons').item(0) as HTMLElement;
      picklistButton.style.display = 'none';
      const picklistTarget = document.getElementsByClassName('ui-picklist-target-wrapper').item(0) as HTMLElement;
      picklistTarget.style.cssFloat = 'left';
    }
  }
  /* istanbul ignore next */
  ngOnInit() {
    this.todayDate = new Date();
    this.defaultValidityFromDate = this.datePipe.transform(this.todayDate, 'longDate');
    this.defaultValidityEndDate = this.datePipe.transform(new Date('9999-12-31'), 'longDate');
    this.dataSvcObj.getruleToEdit().subscribe((value) => {
      if (value !== null) {
        this.editRuleFlow = true;
        this.viewRuleFlow = false;
        this.viewRelbtn = false;
        this.marketForModal = recipeModel.marketDetails.marketName;
        this.editRuleData = value;
        this.ruleSource = value.ruleSource;
        if (ruleFlags.viewRule) {
          this.defaultValidityFromDate = value.validityStartDate ? (this.datePipe.transform(new Date(this.datePipe.transform(value.validityStartDate, 'yyyy-MM-dd')), 'longDate')) : '';
          this.defaultValidityEndDate = value.validityDate ? (this.datePipe.transform(new Date(this.datePipe.transform(value.validityDate, 'yyyy-MM-dd')), 'longDate')) : '';
        }
        this.yearAdopted = value.yearAdopted ? (new Date(value.yearAdopted)).toUTCString() : '';
        this.ruleIdOnEdit = value.ruleId;
        this.ruleVersion = value.ruleVersion;
        pureRulesDetailsModel.Market = value.markets[0];
        const rpcVals = [];
        value.rpcs.forEach((val) => {
          const data = {
            id: val.rpcId,
            text: val.rpcName,
            rpcFullText: val.rpcFullText
          };
          rpcVals.push(data);
        });
        pureRulesDetailsModel.selectedParentRpcs = rpcVals;
        pureRulesDetailsModel.selectedRpcs = rpcVals;
        recipeModel.preparationMethod = value.preparationMethod;
        recipeModel.intendedUse = value.intendedUse;
        this.getWorklistColumns();
      }
    });
    if (this.activeTabGR === 'rule-detail') {
      document.getElementById('description').style.display = 'none';
    }
    // get group data on group selection change
    this.dataSvcObj.getGroupData.subscribe((groupData: any) => {
      if (groupData) {
        this.groupDetailsInitial = groupData;
        if (this.editRuleFlow || this.viewRuleFlow) {
          this.sourceProducts = [];
          this.targetProducts = [];
          this.groupDetails = Object.assign({}, this.groupDetailsInitial);
          const data = groupData.substanceData;
          for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < this.editRuleData.substanceSubgroupsToSirList.length; j++) {
              if (data[i].sirId === this.editRuleData.substanceSubgroupsToSirList[j].substanceId) {
                this.targetProducts.push(data[i]);
              }
            }
          }
          this.sourceProducts = _.xor(data, this.targetProducts);
        } else {
          this.refreshComponant();
          this.groupDetails = Object.assign({}, this.groupDetailsInitial);
        }
      }
    });
    // ruletype selection change
    this.dataSvcObj.getSelectedGroupRuleType.subscribe((value) => {
      if (value.length !== 0) {
        this.refreshComponant();
        this.selectedRuleTypeDetails[0] = value;
        this.ruleTypeId = this.selectedRuleTypeDetails[0].ruleTypeId;
        this.ruleDisplayName = this.selectedRuleTypeDetails[0].ruleTypeDisplayName;
        this.ruleType = this.selectedRuleTypeDetails[0].ruleType;
        this.activeTabGR = 'rule-detail';
        document.getElementById('rule-detail').style.display = 'block';
        document.getElementById('description').style.display = 'none';
        this.ruleDescription = this.selectedRuleTypeDetails[0].ruleTypeDescription ? this.selectedRuleTypeDetails[0].ruleTypeDescription : null;
        if (!this.editRuleFlow && !this.viewRuleFlow) {
          this.ruleSource = '';
          this.validityStartDate = '';
          this.validityEndDate = '';
          this.yearAdopted = '';
          this.groupDetails = this.groupDetailsInitial;
        }
      }
    });

    if (ruleFlags.viewRule) {
      this.editRuleFlow = false;
      this.viewRuleFlow = true;
    } else if (ruleFlags.editRule) {
      this.editRuleFlow = true;
      this.viewRuleFlow = false;
    }
  }
  onTabChange(activeTab) {
    this.activeTabGR = activeTab;
    if (activeTab === 'rule-detail') {
      document.getElementById('rule-detail').style.display = 'block';
      document.getElementById('description').style.display = 'none';
      document.getElementById('ruleValidity').style.display = 'none';
    } else if (activeTab === 'description') {
      document.getElementById('rule-detail').style.display = 'none';
      document.getElementById('description').style.display = 'block';
      document.getElementById('ruleValidity').style.display = 'none';
    } else if (activeTab === 'ruleValidity') {
      document.getElementById('rule-detail').style.display = 'none';
      document.getElementById('description').style.display = 'none';
      document.getElementById('ruleValidity').style.display = 'block';
    }
    setTimeout(() => {
      this.disableCheckbox();
    }, 10);
  }
  /* istanbul ignore next */
  onSaveAndClose() {
    this.saveAndCloseClicked = true;
    this.onSave();
  }
  /* istanbul ignore next */
  onSaveandAddNew() {
    this.saveAndAddNewBtnClicked = true;
    this.onSave();
  }
  // save positive list/ anned group rule
  /* istanbul ignore next */
  onSave() {
    let ruleTypeId;
    let ruleType;
    const rpcData = [];
    const subgroupsubstancelist = [];
    const mktList = [{ marketId: pureRulesDetailsModel.Market.marketId, marketName: pureRulesDetailsModel.Market.marketName }];
    ruleTypeId = this.ruleTypeId;
    ruleType = this.ruleDisplayName;

    if (groupRulesDetailsModel.CascadeRpc === 'Y') {
      pureRulesDetailsModel.selectedParentRpcs.forEach((value) => {
        const data = {
          rpcFullText: value.rpcFullText,
          rpcName: value.text,
          rpcId: value.id
        };
        rpcData.push(data);
      });
    } else {
      pureRulesDetailsModel.selectedRpcs.forEach((value) => {
        const data = {
          rpcFullText: value.rpcFullText,
          rpcName: value.text,
          rpcId: value.id
        };
        rpcData.push(data);
      });
    }
    if (!this.editRuleFlow && !this.viewRuleFlow) {
      this.subGroupDetails.forEach(subgroup => {
        subgroupsubstancelist.push({
          SubstanceId: subgroup.sirId,
        });
      });
    } else {
      this.targetProducts.forEach(subgroup => {
        subgroupsubstancelist.push({
          SubstanceId: subgroup.sirId,
        });
      });
    }
    const saveRuleToDbUrl = environment.APIFAsTRule + '/rulesList';
    const savedData = [{
      RuleId: this.ruleIdOnEdit ? this.ruleIdOnEdit : 0,
      RuleVersion: this.ruleVersion ? this.ruleVersion : 0,
      RPCs: rpcData,
      MarketID: Number(pureRulesDetailsModel.Market.marketId),
      Market: pureRulesDetailsModel.Market.marketName,
      Markets: mktList,
      RuleTypeID: ruleTypeId,
      RuleType: ruleType,
      CascadeMarket: 'N',
      CascadeRpc: 'Y',
      UoMID: 0,
      UoM: '',
      TexualMinLimitText: '',
      TexualMaxLimitText: '',
      RuleDescription: '',
      RuleSource: this.ruleSource ? this.ruleSource : '',
      RuleSourceType: '',
      AdditionalStatement: '', // other requirements and restrictions
      PositiveStatement: '',
      MaxLimit: '',
      MinLimit: '',
      RuleClaim: '',
      RuleSpecificCondions: '',
      GroupFunction: '',
      PreparationMethod: recipeModel.preparationMethod,
      IntendedUse: recipeModel.intendedUse,
      FunctionList: null, // used only for allowed function array of objects
      NegativeStatement: '',
      IsMandatorySubstance: null, // Y for substance mandatory rule
      WarningText: null, // used for warning rule, adulterated rule , phased out rule, other requirements and restrictions, Product Warnings
      SubstanceGroup: this.groupDetails,
      SubstanceSubgroupsToSirList: subgroupsubstancelist,
      yearAdopted: this.yearAdopted ? this.datePipe.transform(this.yearAdopted, 'yyyy-MM-dd') : null,
      ValidityStartDate: this.validityStartDate ? this.datePipe.transform(this.validityStartDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityFromDate, 'yyyy-MM-dd'),
      ValidityDate: this.validityEndDate ? this.datePipe.transform(this.validityEndDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityEndDate, 'yyyy-MM-dd'),
      Comments: this.ruleComment ? this.ruleComment : null,
      RuleFlow: this.parallelRuleWorkflow === true ? 'Parallel' : 'Request'
    }];
    this.loadingMsg = 'Saving Rule';
    this.loading = true;
    if (!this.editRuleFlow) {
      this.assessReqRAdataService.postData(saveRuleToDbUrl, savedData).subscribe((response) => {
        this.loading = false;
        if (response[0].ruleId !== 0) {
          this.ruleAdded = response;
          this.getRuleDetailsFromDb();
          this.snackBarGroup.open('Group Rule added successfully with Rule Id:' + response[0].ruleId, 'close', {
            duration: 5000,
            panelClass: ['success']
          });
          if (this.saveAndAddNewBtnClicked) {
            setTimeout(() => {
              this.dataSvcObj.getApplicableRuleTypesObsv.subscribe((res) => {
                if (res.length !== 0) {
                  pureRulesDetailsModel.applicableRuleTypeList = res;
                  this.applicableGroupRuleTypesToDisplay = res;
                }
              });
              this.applicableGroupRuleTypesToDisplay = pureRulesDetailsModel.applicableRuleTypeList;
              this.saveAndAddNewDialog = true;
              this.saveAndAddNewBtnClicked = false;
            }, 3000);
          } else
            if (this.saveAndCloseClicked) {
              this.dataSvcObj.setExpandablePanel(2);
              this.saveAndCloseClicked = false;
            }
        } else {
          this.snackBarGroup.open('Rule could not be created.', 'close', {
            duration: 5000,
            panelClass: ['error']
          });
        }
      }, (error) => {
        this.loading = false;
      });
    } else {
      const url = environment.APIFAsTRule + '/rules';
      this.assessReqRAdataService.putData(url, savedData).subscribe((response) => {
        this.loading = false;
        if (response[0].ruleId !== 0) {
          this.ruleAdded = response;
          this.getRuleDetailsFromDb();
          this.snackBarGroup.open('Rule updated successfully with Rule Id:' + response[0].ruleId + ' and Rule Version: ' + response[0].ruleVersion, 'close', {
            duration: 5000,
            panelClass: ['success']
          });
          this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
            this.route.navigate(['/rule-management/view-rules']);
          });
        } else {
          this.snackBarGroup.open('Rule could not be updated.', 'close', {
            duration: 5000,
            panelClass: ['error']
          });
        }
      }, (error) => {
        this.loading = false;
      });
    }
  }
  // get rules from DB
  getRuleDetailsFromDb() {
    this.rpc = [];
    if (pureRulesDetailsModel.selectedRpcs !== undefined) {
      if (pureRulesDetailsModel.selectedRpcs[0].text !== 'No Regulatory Product Class is Mapped') {
        pureRulesDetailsModel.selectedRpcs.forEach((rpcValue) => {
          this.rpc.push({
            rpcId: rpcValue.id,
            rpcName: rpcValue.text,
            rpcFullText: rpcValue.rpcFullText,
          });
        });
      }
    }
    const fetchRuleBody = {
      MarketList: [pureRulesDetailsModel.Market],
      RPCList: this.rpc,
      ruleClassifications: ['Substance Group Rules']
    };

    this.loading = true;
    const getruleapiurl = environment.APIFAsTRule + '/rules/rulesFilter';
    return new Promise<any>((resolve) => {
      this.assessReqRAdataService.postData(getruleapiurl, fetchRuleBody)
        .toPromise()
        .then(response => {
          this.loading = false;
          if (response !== null || response !== []) {
            resolve(response);
            const result = response.filter(x => x.ruleClassification === 'Substance Group Rules');
            for (const value of result) {
              value.ruleVerionToDisplay = 'V' + value.ruleVersion;
              if (value.cfDetails !== null && value.cfDetails !== undefined) {
                const cfValue = value.cfDetails;
                value.cf = cfValue.cf;
                value.cfSubstanceExpressedAs = cfValue.component;
              }
              value.warningText = (value.warningText !== null) ? value.warningText : ((value.warningStatement !== null) ? value.warningStatement : '');
              if (value.warningText !== null) {
                value.warningTextStripped = this.handleSpecialCharacters(value.warningText);
              } else {
                value.warningTextStripped = ' ';
              }
              value.otherRestrictionsAndRequirements = (value.otherRestrictionsAndRequirements !== null) ? value.otherRestrictionsAndRequirements : '';
              if (value.otherRestrictionsAndRequirements !== null) {
                value.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(value.otherRestrictionsAndRequirements);
              } else {
                value.otherRestrictionsAndRequirementsStripped = ' ';
              }
            }
            this.dataSvcObj.setRulesListForGroup(result);
            groupRulesDetailsModel.rulesListForGroups = result;
            this.rulesListForGroup.push(response.filter((value) => value.groupID === this.groupDetails.groupId));
          }
        }).catch(error => {
          this.loading = false;
        });
    });
  }
  handleSpecialCharacters(text) {
    return text.replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, '').replace(/&amp;/g, '&');
  }
  onCancel() {
    if (!this.editRuleFlow) {
      this.subGroupDetails.forEach((element) => {
        element.ContributionFactor = '';
        this.groupDetails.substanceData.push(element);
      });
      this.subGroupDetails = [];
      this.activeTab = 'positive-list';
      this.dataSvcObj.setExpandablePanel(2);
    } else {
      this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
        this.route.navigate(['/rule-management/view-rules']);
      });
    }

  }
  ruleTypeRadioButtonChanged(event) {
    this.ruleTypeRadioSelected = true;
    this.rtRadioSelected = event.target.value;
  }
  onContinue() {
    if (this.rtRadioSelected !== undefined) {
      const obj = _.filter(pureRulesDetailsModel.applicableRuleTypeList, (element) => element.ruleTypeDisplayName === this.rtRadioSelected);
      // tslint:disable-next-line: no-shadowed-variable
      const obj1 = obj.reduce((obj, newObj, i) => {
        newObj[i] = obj;
        return newObj;
      }, {});
      this.dataSvcObj.setSelectedGroupRuleType(obj1);
      this.dataSvcObj.setExpandablePanel(3);
    }
  }
  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }

  refreshComponant() {
    this.subGroupDetails.forEach((element) => {
      this.groupDetails.substanceData.push(element);
    });
    this.subGroupDetails = [];
    this.activeTab = 'positive-list';
  }
  /* istanbul ignore next */
  checkRulesAvail() {
    this.viewRelbtn = true;
    this.prepareRuleTableData = [];
    this.selectedRows = null;
    this.loadingMsg = 'Fetching data from FAsT Rule-Engine';
    this.loading = true;
    const apiurl = environment.APIFAsTRule + '/rules/queryrelatedrequests';
    const body = [];
    this.showRelatedRulesPopup = true;
    body.push({
      RuleId: this.ruleIdOnEdit,
      RuleVersion: this.ruleVersion
    });
    this.assessReqRAdataService.postData(apiurl, body).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.prepareRuleTableData = response.map(banedReq => {
          return {
            requestDisplayID: banedReq.requestDisplayID,
            cucCode: banedReq.cucCode,
            cucDescription: banedReq.cucDescription,
            recipeType: banedReq.recipeType,
            recipeStatus: banedReq.recipeStatus,
            marketId: banedReq.market.marketId,
            marketName: banedReq.market.marketName,
            division: (banedReq.tfc.tfcLevel === 5) ? banedReq.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName : banedReq.tfc.parentTFC.parentTFC.parentTFC.tfcName,
            rDFormulationCategory: (banedReq.tfc.tfcLevel === 5) ? banedReq.tfc.parentTFC.parentTFC.parentTFC.tfcName : banedReq.tfc.parentTFC.parentTFC.tfcName,
            formulationType: (banedReq.tfc.tfcLevel === 5) ? banedReq.tfc.parentTFC.parentTFC.tfcName : banedReq.tfc.parentTFC.tfcName,
            formulationClass: (banedReq.tfc.tfcLevel === 5) ? banedReq.tfc.parentTFC.tfcName : banedReq.tfc.tfcName,
            formulationSubClass: (banedReq.tfc.tfcLevel === 5) ? banedReq.tfc.tfcName : '',
            preparationMethod: banedReq.preparationMethod,
            intendedUse: banedReq.intendedUse,
            multicomponent: banedReq.isMultiComponent,
            requestStatus: banedReq.requestStatus,
            ra: banedReq.ra,
            allRa: banedReq.allRA,
            submittedDate: banedReq.submittedDate,
            submittedByUserId: banedReq.submittedByUserId,
            submittedByUserName: banedReq.submittedByUserName,
            comments: banedReq.comments,
            assignedDate: banedReq.assignedDate,
            inProgressRADate: banedReq.inProgressRADate,
            inProgressRDDate: banedReq.inProgressRNDDate,
            inProgressSIRDate: banedReq.inProgressSIRDate,
            rejectedDate: banedReq.rejectedDate,
            deletionDate: banedReq.deletionDate,
            completedDate: banedReq.completedDate,
            rdComment: banedReq.comments,
            raComment: banedReq.raComment,
            topTfcId: banedReq.tfcid,
            allRAIDs: banedReq.allRAIDs,
            raUserID: banedReq.raUserIDred,
            divisionid: (banedReq.tfc.tfcLevel === 5) ? banedReq.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId : banedReq.tfc.parentTFC.parentTFC.parentTFC.tfcId
          };
        });
      }
      const ele = document.getElementById('echeck') as HTMLInputElement;
      ele.checked = false;
      this.selectedRowsRelated = [];
    }, (error) => {
      this.loading = false;
    });
  }
  getWorklistColumns() {
    const apiurl = environment.APIFAsT + '/getworklistcolumns/relatedrequest';
    this.worklistservice.getWorklistApiData(apiurl).subscribe((response) => {
      this.myWorklistTableColumns = response;
    });
  }
  sendEmail() {
    this.showEmailConfirmationDialog = true;
    let reqId = '';
    let userDetails = '';
    const uDetail = [];
    const reDet = [];
    this.selectedRowsRelated.forEach(element => {
      reDet.push(element.requestDisplayID);
    });
    const distinctReqs = reDet.filter((n, i) => reDet.indexOf(n) === i);
    reqId = distinctReqs.join(' ');
    this.selectedRowsRelated.forEach(element => {
      uDetail.push(element.ra);
    });
    const distinctNames = uDetail.filter((n, i) => uDetail.indexOf(n) === i);
    userDetails = distinctNames.join(' ');
    this.commentsToUser = 'Regulatory rule/s for the request no. ' + reqId + ' will be updated/deleted by RA user ' + userDetails + ' .To get the most updated FAsT report, please submit a new request for the formulation. Ignore if the formulation is no longer valid.';
  }
  sendEmailMessage() {
    const payLoad = {
      NotificationGroup: 'RDCOMPLETED',
      Subject: 'FAsT | Rules updated for these requests :',
      EmailBody: null,
      ToEmailList: '',
      FromEmail: null,
      CCEmailList: null,
      EmailType: 'html',
      UPD: this.ruleIdOnEdit.toString(),
      PureCodes: null,
      UserRequests: [],
      CUCDescription: null,
      Comments: this.commentsToUser
    };

    if (this.selectedRowsRelated.length > 0) {
      this.selectedRowsRelated.forEach(row => {
        const reqIds = this.selectedRowsRelated.filter(item => item.submittedByUserId === row.submittedByUserId).map(i => i.requestDisplayID);
        payLoad.UserRequests.push({
          userId: +row.submittedByUserId,
          requestIds: reqIds
        });
      });
    }

    if (payLoad.UserRequests.length > 0) {
      this.assessReqRAdataService.sendRulesEmailNotification(payLoad);
    }
    this.showEmailConfirmationDialog = false;
    this.showRelatedRulesPopup = false;
  }
  onGroupSelect(checkValue) {
    if (checkValue) {
      this.selectedRowsRelated = this.prepareRuleTableData;
    } else {
      this.selectedRowsRelated = [];
    }
  }
}
