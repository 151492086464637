import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as $ from 'jquery';
import 'jstree';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { groupDataModel } from 'src/app/common/models/GroupDataModel';
import { groupRulesDetailsModel } from 'src/app/common/models/GroupRulesDetailsModel';
import { parallelRuleObj, pureRulesDetailsModel, ruleFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { sirDetailObj } from 'src/app/common/models/SirModel';
import {
  GROUPTABLE_COLUMNS, GROUP_RULE_COLUMNS,
  GROUP_RULE_COLUMNS_EXCEL, SUBSTANCE_COLUMNS,
  VIEW_PRODUCT_SUBSTANCE_RULE_COLUMNS
} from 'src/app/common/models/tableColumns';
import { DataService } from 'src/app/common/services/data.service';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from 'src/environments/environment';
import { AssessRequestRaService } from '../../assess-request-ra.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-substance-group',
  templateUrl: './substance-group.component.html',
  styleUrls: ['./substance-group.component.scss']
})

export class SubstanceGroupComponent implements OnInit {
  @Output() sendRulesEvent = new EventEmitter<any>();
  @Input() isFileNameAmendDate = 'No';
  overlayText = ''; checkSelectedVal = '';
  hideOverlay = true;
  loading = false;
  loadingMessage = 'Loading'; deleteGrpRuleComment: string;
  step = 0;
  asIsIngredient;
  marketsToSelect = []; componentsToSelect = []; notifyGPCDialog = false; groupRuleColumns = []; groupRuleColumnsExcel = []; groupColumns = [];
  viewSubstanceRuleColumns = VIEW_PRODUCT_SUBSTANCE_RULE_COLUMNS;
  selectedRpc; selectedPureSirDetail; displayRuleType; ruleDisplayData;
  groupData = []; searchedSubstanceData = []; searchedSubstanceDataTemp = [];
  addSubstanceDialogue = false; addItemBtnDisableFlag = true;
  createGroupTab; substanceColumns; groupToCreateData; groupName;
  subtanceIdentifier = ['Pure Code', 'INCI Name', 'CAS #', 'SIR ID', 'Substance Name'];
  selectedIdentifier;
  groupForm: FormGroup;
  tfcPath; searchIdentifierValue; searchIdentifier; substanceData; selectedRuleDetails;
  substanceGroupData: {
    groupId;
    groupName;
    groupType;
    substanceData: any;
  };
  isGroupSelected: boolean;
  sirIdsforSubstanceDetails: string[] = [];
  division;
  cascadeRPC = true;
  unileverMarketObj = {
    clusterId: null,
    clusterName: null,
    marketFullText: null,
    marketId: '',
    marketName: 'Unilever',
    parentMarket: ''
  };
  rpcDetails;
  rpcFullData;
  displayedTFCPath;
  selectedRuleBucket;
  selectedRpcList = []; rpc = []; createdGroup = []; checkedPureCodes = []; switchedPureCodes = []; switchedSirDetails = [];
  selectedMarket; componentGlobal; parentRpcParallelRule;
  selectedComponent = ''; selectedRPC = '';
  selectedSirDetailsforGPC; checkedFlag; isSubstanceSelected; isAllSubstanceChecked; isRpcSelected; sentPureCodes;
  switchedSirDetailsEdit = []; switchedPureCodesEdit = []; selectedSirDetails = []; valuesForComponent = []; rpcsForEditRule = [];
  deletePopup = false; hideMapRpc = true; readOnlyValue = true; disableLoader = false; isNotifyGpc = false; notifyGPCDialogEdit = false;
  dataSelectedDelete; pureSirDetail; groupToEditData;
  isMultiComponentAvail = false; onload = true; parallelRuleWorkflow = false; isEditGroup = false; editNotifyGPC = false;
  dataForRPCMApping = []; rpcToSelect = []; selectedRowData: any = []; selectedSirDetailsEdit = []; searchResultsGroup = [];
  radioRowIndex = null;
  disablePureCode = false; editRuleFlow = false; viewRuleFlow = false;
  warningTextOpen: boolean;
  tablewarningComment: any;
  commentRD = '';
  orrTextOpen: boolean;
  tableORRComment: any;
  isColumnsHasHyperLinks = 'Yes';
  constructor(
    private assessRequestRa: AssessRequestRaService, public dataServiceObj: DataService, public matSnackBar: MatSnackBar,
    public formBuilder: FormBuilder, private worklistService: WorklistService
  ) { }
  /* istanbul ignore next */
  ngOnInit() {
    this.asIsIngredient = localStorage.getItem('AsIsIngredientComponentname').toLowerCase();
    this.editRuleFlow = false;
    this.viewRuleFlow = false;
    this.pureSirDetail = sirDetailObj;
    this.createGroupTab = false;
    this.groupRuleColumns = GROUP_RULE_COLUMNS;
    this.groupRuleColumnsExcel = GROUP_RULE_COLUMNS_EXCEL;
    this.groupColumns = GROUPTABLE_COLUMNS;
    this.substanceColumns = SUBSTANCE_COLUMNS;
    this.isSubstanceSelected = false;
    this.dataServiceObj.getComponentNameSvc.subscribe((val) => {
      this.componentGlobal = val;
    });
    this.dataServiceObj.getruleToEdit().subscribe((value) => {
      if (value !== null) {
        this.editRuleFlow = true;
        if (value.markets) {
          this.selectedMarket = value.markets[0].marketName;
          recipeModel.marketDetails = value.markets[0];
        }
        this.parentRpcParallelRule = value.rpcid;
        for (const val of value.rpcs) {
          this.rpcsForEditRule.push(val.rpcFullText);
        }
      } else {
        this.editRuleFlow = false;
      }
    });
    if (ruleFlags.viewRule) {
      this.editRuleFlow = false;
      this.viewRuleFlow = true;
    } else if (ruleFlags.editRule) {
      this.editRuleFlow = true;
      this.viewRuleFlow = false;
    }
    this.dataServiceObj.getParallelRuleFormValues().subscribe((value) => {
      if (value !== null && this.componentGlobal === 'CreateInsertRule') {
        this.parallelRuleWorkflow = true;
        this.selectedMarket = value.selectedMarket.marketName;
        recipeModel.marketDetails = value.selectedMarket;
        this.division = recipeModel.division;
        pureRulesDetailsModel.Market = recipeModel.marketDetails;
      } else {
        this.parallelRuleWorkflow = false;
        this.selectedMarket = recipeModel.marketDetails.marketName;
        pureRulesDetailsModel.Market = recipeModel.marketDetails;
      }
    });
    if (this.parallelRuleWorkflow) {
      this.marketsToSelect.push(recipeModel.marketDetails);
      recipeModel.intendedUse = parallelRuleObj.intendedUseForParallelRule;
      recipeModel.preparationMethod = parallelRuleObj.prepMethodForParallelRule.value;
      this.getRPCForParallelRule();
    } else if (this.editRuleFlow) {
      this.marketsToSelect.push(recipeModel.marketDetails);
      pureRulesDetailsModel.CascadeRpc = 'Y';
    } else {
      if (recipeModel) {
        this.selectedMarket = recipeModel.marketDetails.marketName;
        this.tfcPath = recipeModel.tfcFullText;
        this.division = recipeModel.division;
      }
      this.getFallbackMarkets();
      if (recipeModel.isMultiComponent === 'Y') {
        this.getComponentNames();
        this.isMultiComponentAvail = true;
      } else {
        this.isMultiComponentAvail = false;
      }
    }
    // getting groups
    if (!this.editRuleFlow && !this.viewRuleFlow) {
      if (!this.groupData || this.groupData.length === 0) {
        this.loading = true;
        this.getGroups();
      }
      this.createGroupFormStructure();
    }
    this.setRuleBucket('Substance-Group');
    this.dataServiceObj.expandedPanel.subscribe((result) => { // setting the expanded panel
      if (result !== '') {
        const step = result;
        this.setStep(Number(step));
      }
    });
    // get group rules
    this.dataServiceObj.getRulesListForGroup.subscribe((response) => {
      if (response) {
        this.ruleDisplayData = response;
        groupRulesDetailsModel.rulesListForGroups = response;
      }
    });
    groupRulesDetailsModel.CascadeRpc = 'Y';
    if (!this.editRuleFlow || !this.viewRuleFlow) {
      if (this.groupRuleColumns[this.groupRuleColumns.length - 1].field === 'delete') {
        this.groupRuleColumns.pop();
      }
    } else {
      if (this.groupRuleColumns[this.groupRuleColumns.length - 1].field === 'modifiedDate') {
        const obj = { field: 'delete', header: '', width: '40px' };
        this.groupRuleColumns.push(obj);
      }
    }
  }
  /* istanbul ignore next */
  getRPCForParallelRule() {
    pureRulesDetailsModel.selectedRpcs = [];
    pureRulesDetailsModel.selectedParentRpcs = [];
    this.createRpcTreeForParallelRule(parallelRuleObj.rpcsForParallelRuleData);
    const length = parallelRuleObj.rpcsForParallelRuleData.length;
    pureRulesDetailsModel.selectedRpcs.push(parallelRuleObj.rpcsForParallelRuleData[length - 1]);
    pureRulesDetailsModel.selectedParentRpcs.push(parallelRuleObj.rpcsForParallelRuleData[length - 1]);
  }
  /* istanbul ignore next */
  createRpcTreeForParallelRule(rpcData) {
    $('.tree-div-group').on('changed.jstree', (evt, data) => {
      $('.tree-div-group').jstree(true).set_icon(data.node, 'https://jstree.com/tree-icon.png');
    });

    $('.tree-div-group').on('changed.jstree', (e, unileverRpcDataChanged) => {
    }).jstree({
      plugins: ['changed'],
      checkbox: {
        three_state: false

      },
      core: {
        check_callback: true,
        data: rpcData,
        themes: {
          icons: false
        }
      },
      types: {
        default: {
          icon: 'src/assets/images/tree.svg'
        }
      },
      icon: 'glyphicon glyphicon-file file-node',
      // tslint:disable-next-line: space-before-function-paren // tslint:disable-next-line: no-shadowed-variable
    }).bind('loaded.jstree', function (event, unileverRpcData123) {
      $(this).jstree('open_all');
      $('#' + rpcData[rpcData.length - 1].id + ' >a').css('color', '#1F36C7');
    });
    $('.tree-div-group').jstree(true).redraw(true);

  }
  setRuleBucket(ruleBucketValue) {
    localStorage.setItem('selectedRuleBucket', ruleBucketValue);
  }
  // To initialize group form structure
  createGroupFormStructure() {
    this.groupForm = this.formBuilder.group({
      GroupName: [''],
      GroupType: 'Regulatory Group',
      UPDFullText: this.division,
      SubstanceIds: [],
    });
  }
  setStep(index: number) {
    if (this.checkValidation(index)) {
      this.step = index;
    }
    // To get group rules after market and RPC selections
    if (this.step === 1) {
      this.getGroupRulesFromDb();
      this.isGroupSelected = false;
    }
  }
  nextStep() {
    let varrpcitem = 0;
    varrpcitem = pureRulesDetailsModel.selectedRpcs.length;
    if (varrpcitem > 1) {
      this.loading = false;
      this.matSnackBar.open('Invalid Input Error: More than one child RPC level under same parent is selected. Please select one child RPC level to save rule applicability.', 'close', {
        duration: 3000,
        panelClass: ['error']
      });
    } else {
      if (this.checkValidation(this.step)) {
        this.step++;
      }
      if (this.step === 1) {
        this.getGroupRulesFromDb();
      }
    }
  }
  selectedRuleType(value) {
    this.selectedRuleDetails = value;
    this.displayRuleType = value.ruleTypeDisplayName;
    this.dataServiceObj.setSelectedGroupRuleType(this.selectedRuleDetails);
    this.step = 3;
    this.setStep(this.step);
  }
  createGroup() {
    this.createGroupTab = true;
    this.addSubstanceDialogue = false;
    this.isEditGroup = false;
    this.createdGroup = [];
    this.switchedSirDetails = [];
    this.switchedPureCodes = [];
    this.switchedPureCodesEdit = [];
    this.switchedSirDetailsEdit = [];
  }
  addSubstanceMethod() {
    this.searchIdentifier = null;
    this.searchIdentifierValue = null;
    this.searchResultsGroup = [];
    this.disablePureCode = false;
    this.selectedRowData = [];
    this.searchedSubstanceData = [];
    this.searchedSubstanceDataTemp = [];
    this.switchedPureCodes = [];
    this.switchedPureCodes = [];
    this.addSubstanceDialogue = true;
    this.isAllSubstanceChecked = false;
    this.isSubstanceSelected = false;
    this.switchedSirDetails = [];
  }
  /* istanbul ignore next */
  showGroup() {
    this.groupForm.controls.GroupName.setValue('');
    this.addSubstanceDialogue = false;
    this.createGroupTab = false;
    this.groupData = [];
    this.getGroups();
    this.searchIdentifier = null;
    this.searchIdentifierValue = null;
    this.searchResultsGroup = [];
    this.disablePureCode = false;
    this.radioRowIndex = null;
    this.selectedRowData = [];
    this.createdGroup = [];
  }
  addSubstance() {
    // this.addSubstanceDialogue = true;
  }
  // get groups data for selected division
  /* istanbul ignore next */
  getGroups() {
    this.loading = true;
    this.loadingMessage = 'Fetching data from SIR';
    this.isGroupSelected = false;
    this.radioRowIndex = null;
    const apiurl = environment.APIFAsTRule + '/rules/substancegroups/upd/' + this.division;
    this.assessRequestRa.getData(apiurl).subscribe((response) => {
      if (response.length > 0) {
        this.loading = true;
        this.disableLoader = false;
        response.forEach(group => {
          group.substanceIds.forEach(sub => {
            const temp = [];
            group.substances.forEach(element => {
              if (element.sirId === sub) {
                element.cfDetails.forEach(cf => {
                  temp.push(cf.cfDisplayText);
                });
                this.groupData.push({
                  groupId: group.groupId,
                  groupName: group.groupName,
                  groupType: group.groupType,
                  substanceId: sub,
                  pureCode: element.pureCode,
                  pureDescription: element.pureDescription,
                  substanceName: element.substanceName,
                  cas: element.cas,
                  parentCAS: element.parentCAS,
                  cfDetails: element.cfDetails,
                  cfData: temp.toString(),
                  ins: element.ins,
                  inciName: element.inciName,
                  einecs: element.einecs,
                });
              }
            });
          });
        });
        this.loading = false;
        this.disableLoader = true;
      } else {
        this.loading = false;
        this.disableLoader = true;
      }
    }, (error) => {
      this.loading = false;
      if (error.status === 200) {
        this.matSnackBar.open('Group data recieved', 'close', {
          duration: 3000,
          panelClass: ['success']
        });
      } else {
        this.matSnackBar.open('Request Failed', 'close', {
          duration: 3000,
          panelClass: ['error']
        });
        return false;
      }
    });
  }
  // To craete group
  /* istanbul ignore next */
  saveGroupDetails() {
    let apiurl;
    let data;
    const selectedSubstances = this.searchedSubstanceData.filter(x => x.isSubstanceChecked && !x.sirId.includes('SIR ID Not found'));
    if (selectedSubstances.length === 0) {
      return;
    }
    this.loading = true;
    // if - Create group, Else - Edit group
    if (!this.isEditGroup && this.createdGroup.length === 0) {
      this.loadingMessage = 'Saving Group';
      apiurl = environment.APIFAsTRule + '/rules/substancegroup';
      this.groupToCreateData = this.groupForm.value;
      this.groupToCreateData.SubstanceIds = [];
      selectedSubstances.forEach(element => {
        if (!element.sirId.includes('SIR ID Not found')) {
          this.groupToCreateData.SubstanceIds.push(element.sirId);
        }
      });
      data = this.groupToCreateData;
      this.assessRequestRa.postData(apiurl, data).subscribe((response) => {
        this.matSnackBar.open('Group created successfully', 'close', {
          duration: 3000,
          panelClass: ['success']
        });
        this.substanceGroupData = {
          groupId: response.groupId,
          groupName: response.groupName,
          groupType: response.groupType,
          substanceData: selectedSubstances
        };
        this.createdGroup = selectedSubstances;
        this.loading = false;
        this.isEditGroup = true;
        this.loadingMessage = 'Loading..';
        this.substanceData = this.searchedSubstanceData;
        this.addSubstanceDialogue = false;
        this.searchedSubstanceData = [];
        this.searchedSubstanceDataTemp = [];
        this.searchIdentifierValue = '';
        this.searchResultsGroup = [];
        this.disablePureCode = false;
        this.searchIdentifier = '';
      }, (error) => {
        this.loading = false;
        this.loadingMessage = 'Loading..';
        this.substanceData = this.searchedSubstanceData;
        this.addSubstanceDialogue = false;
        this.searchedSubstanceData = [];
        this.searchedSubstanceDataTemp = [];
        this.searchIdentifierValue = '';
        this.searchResultsGroup = [];
        this.disablePureCode = false;
        this.searchIdentifier = '';
        this.switchedSirDetails = [];
        this.switchedPureCodes = [];
        this.switchedPureCodesEdit = [];
        this.switchedSirDetailsEdit = [];
        return false;
      });
    } else {
      this.loadingMessage = 'Updating Group';
      apiurl = environment.APIFAsTRule + '/rules/editsubstancegroup';
      this.groupToEditData = Object.assign({}, this.substanceGroupData);
      this.groupToEditData.SubstanceIds = [];
      selectedSubstances.forEach(element => {
        if (!element.sirId.includes('SIR ID Not found')) {
          this.groupToEditData.SubstanceIds.push(element.sirId);
        }
      });
      data = this.groupToEditData;
      this.assessRequestRa.putData(apiurl, data).subscribe((response) => {
        this.matSnackBar.open('Group updated successfully', 'close', {
          duration: 3000,
          panelClass: ['success']
        });
        this.createdGroup.push.apply(this.createdGroup, selectedSubstances);
        this.loading = false;
        this.loadingMessage = 'Loading..';
        this.substanceData = this.searchedSubstanceData;
        this.addSubstanceDialogue = false;
        this.searchedSubstanceData = [];
        this.searchedSubstanceDataTemp = [];
        this.searchIdentifierValue = '';
        this.searchResultsGroup = [];
        this.disablePureCode = false;
        this.searchIdentifier = '';
      }, (error) => {
        this.loading = false;
        this.loadingMessage = 'Loading..';
        this.substanceData = this.searchedSubstanceData;
        this.addSubstanceDialogue = false;
        this.searchedSubstanceData = [];
        this.searchedSubstanceDataTemp = [];
        this.searchIdentifierValue = '';
        this.searchResultsGroup = [];
        this.disablePureCode = false;
        this.searchIdentifier = '';
        this.switchedPureCodes = [];
        this.switchedPureCodesEdit = [];
        this.switchedSirDetailsEdit = [];
        return false;
      });
    }
  }
  // to search the substance on the basis of pure code
  /* istanbul ignore next */
  searchSubstance() {
    let apiurl;
    this.searchResultsGroup = [];
    let val = '';
    if (this.searchIdentifier === 'CAS #') {
      val = 'CAS';
    } else if (this.searchIdentifier === 'Substance Name') {
      val = 'BACKBONEIDENTITY';
    } else if (this.searchIdentifier === 'INCI Name') {
      val = 'INCI';
    } else if (this.searchIdentifier === 'SIR ID') {
      val = 'SIRID';
    } else if (this.searchIdentifier === 'Pure Code') {
      val = 'PURECODE';
    }
    apiurl = environment.APIFAsTRule + '/rules/substances/' + val + '/cfRequired/y';
    const data = [];
    data.push(this.searchIdentifierValue);
    this.loadingMessage = 'Loading..';
    this.loading = true;
    this.assessRequestRa.postData(apiurl, data).subscribe((response) => {
      if (response && response.length > 0) {
        this.searchResultsGroup = response;
        this.loading = false;
        this.disablePureCode = true;
      }
    }, (error) => {
      this.searchResultsGroup = [];
      this.loading = false;
    });
  }
  onSearchIdentifierChange() {
    this.searchIdentifierValue = null;
    this.searchResultsGroup = [];
    this.disablePureCode = false;
  }
  // save/edit group validation check flag - enable if substances are selected
  /* istanbul ignore next */
  onSubstanceSelect() {
    let sirIDExists = false;
    for (const val of this.searchedSubstanceData) {
      if (!val.sirId.includes('SIR ID Not found')) {
        sirIDExists = true;
        break;
      } else {
        sirIDExists = false;
      }
    }
    const selectedSubs = this.searchedSubstanceData.filter(x => x.isSubstanceChecked);
    if (selectedSubs.length > 0 && sirIDExists) {
      this.isSubstanceSelected = true;
    } else {
      this.isSubstanceSelected = false;
    }
  }
  onAllSubstanceSelect() {
    this.searchedSubstanceData.forEach(x => x.isSubstanceChecked = this.isAllSubstanceChecked);
    this.onSubstanceSelect();
  }
  // assign groupdatamodel on group selection
  /* istanbul ignore next */
  onGroupSelect(event, rowValue, index) {
    this.radioRowIndex = index;
    this.isGroupSelected = true;
    this.substanceGroupData = {
      groupId: rowValue.groupId,
      groupName: rowValue.groupName,
      groupType: rowValue.groupType,
      substanceData: []
    };
    this.groupData.forEach(groupsub => {
      if (groupsub.groupId === rowValue.groupId && groupsub.substanceName) {
        const subData = {
          sirId: groupsub.substanceId,
          purecode: groupsub.pureCode,
          pureDescription: groupsub.pureDescription,
          substanceName: this.changeToTitleCase(groupsub.substanceName),
          cas: groupsub.cas,
          parentCAS: groupsub.parentCAS,
          inciName: groupsub.inciName,
          ins: groupsub.ins,
          cfDetails: groupsub.cfDetails,
          cfdata: groupsub.cfData,
          einecs: groupsub.einecs,
          synonyms: groupsub.substanceName
        };
        this.substanceGroupData.substanceData.push(subData);
      }
    });
    Object.assign(groupDataModel, this.substanceGroupData);
    this.dataServiceObj.setSelectedGroup(this.substanceGroupData);
  }
  changeToTitleCase(word: string) {
    if (!word) {
      return word;
    }
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
  // change RPC on market change
  onMarketChange(filterVal: any) {
    $('.tree-div-group').jstree('destroy').empty();
    this.onload = false;
    this.getrpcDetailsBasedOnMarket(filterVal.value.marketName);
    this.selectedRPC = '';
    pureRulesDetailsModel.Market = this.marketsToSelect.filter(x => x.marketName === filterVal.value.marketName)[0];
    groupRulesDetailsModel.Market = this.marketsToSelect.filter(x => x.marketName === filterVal.value.marketName)[0];
    pureRulesDetailsModel.selectedRpcs = [];
    groupRulesDetailsModel.selectedRpcs = [];
  }
  /* istanbul ignore next */
  getRPCs(marketName) {
    groupRulesDetailsModel.CascadeRpc = 'Y';
    this.rpcDetails = [];
    forkJoin([this.dataServiceObj.getRpcTree(marketName), this.dataServiceObj.getRpcModel(marketName)]).subscribe((response) => {
      if (response[0] !== null) {
        this.selectedRpcList = [];
        this.rpcDetails = response[0];
        this.dataServiceObj.setSelectedRpcValues(this.rpcDetails);
        pureRulesDetailsModel.selectedRpcs = this.rpcDetails;
        groupRulesDetailsModel.selectedRpcs = this.rpcDetails;
        if (this.rpcDetails) {
          for (const val of this.rpcDetails) {
            if (val.text === 'No Regulatory Product Class is Mapped') {
              pureRulesDetailsModel.selectedRpcs = [];
              groupRulesDetailsModel.selectedRpcs = [];
              this.selectedRpcList = [];
            }
          }
        }
      } else {
        this.rpcDetails = [];
      }
      if (response[1] !== null) {
        pureRulesDetailsModel.RpcFullDetails = [];
        groupRulesDetailsModel.RpcFullDetails = [];

        this.rpcFullData = response[1];
        pureRulesDetailsModel.RpcFullDetails = this.rpcFullData;
        groupRulesDetailsModel.RpcFullDetails = this.rpcFullData;
        if (this.rpcFullData.length === 0) {
          pureRulesDetailsModel.RpcFullDetails = [];
          groupRulesDetailsModel.RpcFullDetails = [];
        }
      } else {
        this.rpcFullData = [];
      }
      pureRulesDetailsModel.RpcFullDetails.forEach((rpcDetailedValue) => {
        const index = _.findIndex(pureRulesDetailsModel.selectedRpcs, (value) => Number(value.id) === rpcDetailedValue.parentRpcId);
        if (index !== -1) {
          this.rpcDetails[index].rpcFullText = rpcDetailedValue.parentRpcSearchValue;
        }
      });
      groupRulesDetailsModel.RpcFullDetails.forEach((rpcDetailedValue) => {
        const index = _.findIndex(groupRulesDetailsModel.selectedRpcs, (value) => Number(value.id) === rpcDetailedValue.parentRpcId);
        if (index !== -1) {
          this.rpcDetails[index].rpcFullText = rpcDetailedValue.parentRpcSearchValue;
        }
      });
      pureRulesDetailsModel.selectedRpcs = this.rpcDetails;
      groupRulesDetailsModel.selectedRpcs = this.rpcDetails;
      this.getSelectedParentRpcsForGroups();
      this.getSelectedLeafRpcs();
      this.createRpcTree(this.rpcDetails);
    }, (error) => {
      this.loading = false;
    });
  }
  createRpcTree(rpcDetails) {
    const component = this;
    // tslint:disable-next-line: no-shadowed-variable
    $('.tree-div-group').on('changed.jstree', (e, rpcDetails) => {
      // tslint:disable-next-line: no-var-keyword
      var i; var j; const r = []; let selectedRpcObject; this.selectedRpcList = [];
      for (i = 0, j = rpcDetails.selected.length; i < j; i++) {
        r.push(rpcDetails.instance.get_node(rpcDetails.selected[i]).text);
        selectedRpcObject = rpcDetails.instance.get_node(rpcDetails.selected[i]).original;
        const index = _.findIndex(this.selectedRpcList, (value) => value.id === selectedRpcObject.id);
        if (index === -1) {
          this.selectedRpcList.push(selectedRpcObject);
        }
      }
      pureRulesDetailsModel.selectedRpcs = this.selectedRpcList;
      groupRulesDetailsModel.selectedRpcs = this.selectedRpcList;
      this.dataServiceObj.setSelectedRpcValues(pureRulesDetailsModel.selectedRpcs);
      this.dataServiceObj.setSelectedRpcValues(groupRulesDetailsModel.selectedRpcs);
      this.getSelectedParentRpcsForGroups();
      this.getSelectedLeafRpcs();
      if (this.selectedRpcList.length > 0) {
        this.isRpcSelected = true;
      }
    }).jstree({
      plugins: ['changed', 'checkbox'],
      checkbox: {
        three_state: false,
        cascade: 'indeterminate' // up, down, indeterminate values
      },
      core: {
        check_callback: true,
        data: rpcDetails,
        themes: {
          icons: false
        }
      },
      types: {
        default: {
          icon: 'src/assets/images/tree.svg'
        }
      },
      icon: 'glyphicon glyphicon-file file-node',

      // tslint:disable-next-line: space-before-function-paren // tslint:disable-next-line: no-shadowed-variable
    }).bind('loaded.jstree', function (event, rpcDetails) {
      $(this).jstree('open_all');
    });

    $('.tree-div-group').jstree(true).redraw(true);
  }
  getSelectedParentRpcsForGroups() {
    let filteredData = [];
    let tempValue;
    const TopParentRpc = [];
    pureRulesDetailsModel.selectedRpcs.forEach((value) => {
      tempValue = value;
      while (true) {
        filteredData = _.filter(pureRulesDetailsModel.selectedRpcs, (rpcVal) => {
          return rpcVal.id === tempValue.parent;
        });
        if (filteredData && filteredData.length > 0) {
          tempValue = filteredData[0];
        } else {
          const index = _.findIndex(TopParentRpc, (val) => {
            return val.id === tempValue.id;
          });
          if (index === -1) {
            TopParentRpc.push(tempValue);
          }
          break;
        }
      }
    });
    pureRulesDetailsModel.selectedParentRpcs = TopParentRpc;
  }
  onCascadeClick() {
    const cascadeRPCFlag = this.cascadeRPC ? 'Y' : 'N';
    groupRulesDetailsModel.CascadeRpc = cascadeRPCFlag;
  }
  /* istanbul ignore next */
  getFallbackMarkets() {
    const apiurl = environment.APIFAsT + '/markets/' + recipeModel.marketDetails.marketName + '/assessmentOrder';
    this.assessRequestRa.getData(apiurl).subscribe((data) => {
      if (data !== null) {
        const fallBackMarkets = data;
        for (const val of fallBackMarkets) {
          this.marketsToSelect.push(val);
        }
      } else {
        this.marketsToSelect.push(recipeModel.marketDetails);
      }
      this.getrpcDetailsBasedOnMarket(this.selectedMarket);
    }, (error) => {
      this.loading = false;
    });
  }
  // get group rules from DB
  getGroupRulesFromDb() {
    this.rpc = [];
    this.loading = true;
    if (pureRulesDetailsModel.selectedRpcs) {
      if (pureRulesDetailsModel.selectedRpcs[0].text !== 'No Regulatory Product Class is Mapped') {
        pureRulesDetailsModel.selectedRpcs.forEach((rpcValue) => {
          this.rpc.push({
            rpcId: rpcValue.id,
            rpcName: rpcValue.text,
            rpcFullText: rpcValue.rpcFullText,
          });
        });
      }
    }

    if (groupRulesDetailsModel.selectedRpcs) {
      if (groupRulesDetailsModel.selectedRpcs[0].text !== 'No Regulatory Product Class is Mapped') {
        groupRulesDetailsModel.selectedRpcs.forEach((rpcValue) => {
          this.rpc.push({
            rpcId: rpcValue.id,
            rpcName: rpcValue.text,
            rpcFullText: rpcValue.rpcFullText,
          });
        });
      }
    }
    const fetchRuleBody = {
      MarketList: [pureRulesDetailsModel.Market],
      RPCList: this.rpc,
      ruleClassifications: ['Substance Group Rules']
    };
    const getruleapiurl = environment.APIFAsTRule + '/rules/rulesFilter';
    this.assessRequestRa.postData(getruleapiurl, fetchRuleBody).subscribe((response) => {
      if (response !== null || response !== []) {
        this.loading = false;
        const result = response.filter(x => x.ruleClassification === 'Substance Group Rules');
        this.ruleDisplayData = result;
        for (const value of this.ruleDisplayData) {
          value.ruleVerionToDisplay = 'V' + value.ruleVersion;
          if (value.cfDetails !== null && value.cfDetails !== undefined) {
            const cfValue = value.cfDetails;
            value.cf = cfValue.cf !== 0 ? cfValue.cf : '';
            value.cfSubstanceExpressedAs = cfValue.component;
          }
          value.warningText = (value.warningText !== null) ? value.warningText : ((value.warningStatement !== null) ? value.warningStatement : '');
          value.originalWarningText = value.warningText;
          const warningLinkRuleData = this.assessRequestRa.hyperLinksToSheet(value.warningText);
          value.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
          value.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
          value.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
          value.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
          value.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
          value.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
          value.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
          value.warningText = this.changeComment(value.warningText);
          // value.warningTextStripped = this.handleSpecialCharacters(value.warningText);
          value.otherRestrictionsAndRequirements = (value.otherRestrictionsAndRequirements !== null) ? value.otherRestrictionsAndRequirements : '';
          const orrLinkRuleData = this.assessRequestRa.hyperLinksToSheet(value.otherRestrictionsAndRequirements);
          value.ORR1 = orrLinkRuleData[0] !== undefined ? orrLinkRuleData[0] : '';
          value.ORR2 = orrLinkRuleData[1] !== undefined ? orrLinkRuleData[1] : '';
          value.ORR3 = orrLinkRuleData[2] !== undefined ? orrLinkRuleData[2] : '';
          value.ORR4 = orrLinkRuleData[3] !== undefined ? orrLinkRuleData[3] : '';
          value.ORR5 = orrLinkRuleData[4] !== undefined ? orrLinkRuleData[4] : '';
          value.ORR6 = orrLinkRuleData[5] !== undefined ? orrLinkRuleData[5] : '';
          value.ORR7 = orrLinkRuleData[6] !== undefined ? orrLinkRuleData[6] : '';
          value.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(value.otherRestrictionsAndRequirements);
          value.originalOtherRestrictionsAndRequirements = value.otherRestrictionsAndRequirements;
          value.otherRestrictionsAndRequirements = this.changeComment(value.otherRestrictionsAndRequirements);
        }
        groupRulesDetailsModel.rulesListForGroups = this.ruleDisplayData;
        this.sendRulesEvent.emit(this.ruleDisplayData);
      }
    }, (error) => {
      this.loading = false;
    });
  }

  handleSpecialCharacters(text) {
    return text.replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, '').replace(/&amp;/g, '&');
  }
  // To check validation for accordian steps
  checkValidation(step) {
    if (step === 0) {
      if ((pureRulesDetailsModel.Market !== undefined) && (pureRulesDetailsModel.selectedRpcs !== undefined && pureRulesDetailsModel.selectedRpcs.length !== 0)) {
        if (pureRulesDetailsModel.selectedRpcs[0].text !== 'No Regulatory Product Class is Mapped') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    if (step === 1) {
      // rule selection  validation
      if (this.radioRowIndex !== null) {
        this.isGroupSelected = true;
      } else {
        this.isGroupSelected = false;
      }
      if (Object.keys(groupDataModel).length !== 0 && this.checkValidation(0) && this.radioRowIndex !== null && !this.createGroupTab) {
        return true;
      } else {
        return false;
      }
    }
    if (step === 2) {
      if (pureRulesDetailsModel.applicableRuleTypeList !== undefined
        && pureRulesDetailsModel.applicableRuleTypeList.length !== 0
        && this.checkValidation(0)) {
        return true;
      } else {
        return false;
      }
    }
    if (step === 3) {
      this.dataServiceObj.getSelectedGroupRuleType.subscribe((response) => {
        const selectedgroupruletype = response;
        if (selectedgroupruletype.length && this.checkValidation(0) && this.checkValidation(1) && this.checkValidation(2)) {
          return true;
        }
      });
      return false;
    }

  }
  /* istanbul ignore next */
  handleSwitchEdit(event, sirvalue) {
    this.editNotifyGPC = true;
    sirvalue.issueCategoryList = [
      { name: 'CAS#', value: 'CAS#' },
      { name: 'Parent CAS#', value: 'Parent CAS#' },
      { name: 'E#', value: 'E#' },
      { name: 'INS#', value: 'INS#' },
      { name: 'EINECS#', value: 'EINECS#' },
      { name: 'INCI', value: 'INCI' },
      { name: 'Synonym', value: 'Synonym' },
      { name: 'CF details', value: 'CF details' },
    ];
    sirvalue.selectedIssueCategory = [];
    if (event.checked === true) {
      this.switchedSirDetailsEdit.push(sirvalue);
      this.switchedPureCodesEdit.push(sirvalue.purecode);
    } else if (event.checked === false) {
      const index = this.switchedPureCodesEdit.findIndex(d => d === sirvalue.purecode);
      this.switchedPureCodesEdit.splice(index, 1);
      this.switchedSirDetailsEdit.splice(index, 1);
    }
  }
  handleSwitch(event, sirvalue) {
    sirvalue.issueCategoryList = [
      { name: 'CAS#', value: 'CAS#' },
      { name: 'Parent CAS#', value: 'Parent CAS#' },
      { name: 'E#', value: 'E#' },
      { name: 'INS#', value: 'INS#' },
      { name: 'EINECS#', value: 'EINECS#' },
      { name: 'INCI', value: 'INCI' },
      { name: 'Synonym', value: 'Synonym' },
      { name: 'CF details', value: 'CF details' },
    ];
    sirvalue.selectedIssueCategory = [];
    if (event.checked === true) {
      this.isNotifyGpc = false;
      this.switchedSirDetails.push(sirvalue);
      this.switchedPureCodes.push(sirvalue.purecode);
      this.switchedPureCodes.push(sirvalue.pureDescription);
    } else if (event.checked === false) {
      this.isNotifyGpc = true;
      const index = this.switchedPureCodes.findIndex(d => d === sirvalue.purecode);
      this.switchedPureCodes.splice(index, 1);
      this.switchedSirDetails.splice(index, 1);
    }
    this.switchedPureCodes = this.switchedPureCodes.filter((element) => {
      return element !== undefined;
    });
  }
  onCheck(event, checkedPureCode) {
    if (event.checked === true) {
      this.checkedPureCodes.push(checkedPureCode);
    } else if (event.checked === false) {
      const index = this.checkedPureCodes.findIndex(d => d === checkedPureCode);
      this.checkedPureCodes.splice(index, 1);
    }
  }
  notifyGPCClicked(event, selectedSirDetails) {
    for (const value of selectedSirDetails) {
      value.selectedIssueCategory = [];
      value.commentsToUser = '';
    }
    selectedSirDetails = this.removeDuplicates(selectedSirDetails, 'sirId');
    this.selectedSirDetails = selectedSirDetails;
    this.notifyGPCDialog = true;
  }
  /* istanbul ignore next */
  notifyGPCClickedEdit(event, selectedSirDetails) {
    for (const value of this.selectedSirDetails) {
      value.selectedIssueCategory = [];
      value.commentsToUser = '';
    }
    this.selectedSirDetails = this.removeDuplicates(selectedSirDetails, 'sirId');
    this.selectedSirDetailsEdit = selectedSirDetails;
    this.notifyGPCDialogEdit = true;
  }
  /* istanbul ignore next */
  removeDuplicates(array, ruleID) {
    const lookup = {};
    array.forEach(element => {
      lookup[element[ruleID]] = element;
    });
    return Object.keys(lookup).map(prop => lookup[prop]);
  }
  notifySIRClicked(event) {
    this.sentPureCodes = this.checkedPureCodes.toString();
    if (this.checkedPureCodes.length !== 0) {
      const data = {
        NotificationGroup: 'SIR',
        Subject: null,
        EmailBody: null,
        ToEmailList: null,
        FromEmail: null,
        CCEmailList: null,
        EmailType: 'PlainText',
        UPD: recipeModel.division,
        PureCodes: this.sentPureCodes,
        RequestID: recipeModel.requestDisplayID
      };
      const apiurl = environment.APIFAsT + '/email';
      this.assessRequestRa.postData(apiurl, data).subscribe((response) => {
        if (response.message !== null) {
          const requestStatus = 'In Progress – SIR';
          const statusUpdateApiUrl = environment.APIFAsT + '/assessment/request/' + recipeModel.requestDisplayID + '/status/' + requestStatus + '/comment/SystemGenerated';
          this.loading = true;
          this.loadingMessage = 'Request Status Update';
          this.assessRequestRa.putData(statusUpdateApiUrl, null).subscribe((statusresponse) => {
            if (statusresponse.message !== null) {
              this.checkedFlag = false;
              this.loading = false;
              const val: any = {
                requestID: recipeModel.requestDisplayID,
                requestStatus: requestStatus
              };
              this.worklistService.setRecipeData(val);
              this.matSnackBar.open('Email message sent to SIR team successfully', 'close', {
                duration: 3000,
                panelClass: ['success']
              });
            }
          }, (error) => {
            this.loading = false;
          });
        }
      }, (error) => {
      });
    } else {
      this.matSnackBar.open('Select the Pure Codes', 'close', {
        duration: 3900,
        panelClass: ['error']
      });
    }
  }
  /* istanbul ignore next */
  sendMailtoGPC(dataDetails, pureCodesDetails) {
    let emailData = '';
    this.sentPureCodes = pureCodesDetails.toString();
    dataDetails.forEach(element => {
      emailData = emailData + '\r\nPure Code:' + ' ' + element.purecode + '\r\n[SIR ID :' + ' ' + element.sirId + ']' +
        '\r\nBackbone Identity :' + ' ' + element.selectedIssueCategory.map((s) => s.value).toString() + '\r\n' + 'Comments : ' + ' ' + element.commentsToUser + '\r\n'
        + 'Pure Description : ' + ' ' + element.pureDescription + '\r\n';
    });
    const data = {
      NotificationGroup: 'GPC',
      Subject: null,
      EmailBody: emailData,
      ToEmailList: null,
      FromEmail: null,
      CCEmailList: null,
      EmailType: 'html',
      UPD: recipeModel.division,
      PureCodes: this.sentPureCodes,
      RequestID: recipeModel.requestDisplayID
    };
    const apiurl = environment.APIFAsT + '/email';
    this.assessRequestRa.postData(apiurl, data).subscribe((response) => {
      if (response.message !== null && recipeModel.requestDisplayID !== undefined) {
        const requestStatus = 'In Progress – SIR';
        const statusUpdateApiUrl = environment.APIFAsT + '/assessment/request/' + recipeModel.requestDisplayID + '/status/' + requestStatus + '/comment/SystemGenerated';
        this.loading = true;
        this.loadingMessage = 'Request Status Update';
        this.assessRequestRa.putData(statusUpdateApiUrl, null).subscribe((statusresponse) => {
          if (statusresponse.message !== null) {
            this.loading = false;
            this.checkedFlag = false;
            const val: any = {
              requestID: recipeModel.requestDisplayID,
              requestStatus: requestStatus
            };
            this.worklistService.setRecipeData(val);
            this.matSnackBar.open('Email message sent to GPC team successfully', 'close', {
              duration: 5000,
              panelClass: ['success']
            });
          }
        }, (error) => {
          this.loading = false;
        });
      } else {
        this.matSnackBar.open('Email message sent to GPC team successfully', 'close', {
          duration: 5000,
          panelClass: ['success']
        });
      }
    }, (error) => {
    });
  }
  /* istanbul ignore next */
  deleteRule(val) {
    this.deletePopup = true;
    this.dataSelectedDelete = val;
  }
  /* istanbul ignore next */
  confirmDelete() {
    const urlForDelete = environment.APIFAsTRule + '/DeleteRules';
    const data = [
      {
        RuleTypeID: this.dataSelectedDelete.ruleTypeID,
        RuleType: this.dataSelectedDelete.ruleType,
        RuleId: this.dataSelectedDelete.ruleId,
        RuleVersion: this.dataSelectedDelete.ruleVersion,
        Comments: this.deleteGrpRuleComment
      }];
    const fetchRuleBody = {
      MarketList: [pureRulesDetailsModel.Market],
      RPCList: this.rpc,
      ruleClassifications: ['Substance Group Rules']
    };
    this.loading = true;
    this.loadingMessage = 'Deleting..';
    this.assessRequestRa.putData(urlForDelete, data).subscribe((responseData) => {
      if (responseData !== null) {
        this.deleteGrpRuleComment = null;
        this.matSnackBar.open('Rule is deleted successfully ', 'close', {
          duration: 3000,
          panelClass: ['success']
        });
        const getruleapiurl = environment.APIFAsTRule + '/rules/rulesFilter';
        this.loadingMessage = 'Fetching Rules List..';
        this.assessRequestRa.postData(getruleapiurl, fetchRuleBody).subscribe((response) => {
          if (response.length > 0) {
            this.loading = false;
            const result = response.filter(x => x.ruleClassification === 'Substance Group Rules');
            this.ruleDisplayData = result;
            for (const value of this.ruleDisplayData) {
              value.ruleVerionToDisplay = 'V' + value.ruleVersion;
              if (value.cfDetails !== null && value.cfDetails !== undefined) {
                const cfValue = value.cfDetails;
                value.cf = cfValue.cf !== 0 ? cfValue.cf : '';
                value.cfSubstanceExpressedAs = cfValue.component;
              }
            }
            groupRulesDetailsModel.rulesListForGroups = this.ruleDisplayData;
            this.sendRulesEvent.emit(this.ruleDisplayData);
          } else {
            this.ruleDisplayData = response;
            groupRulesDetailsModel.rulesListForGroups = response;
            this.sendRulesEvent.emit(response);
            this.loading = false;
          }
        }, (error) => {
          this.loading = false;
        });
      }
    }, (error) => {
      this.matSnackBar.open('Rule deletion Failed', 'close', {
        duration: 3000,
        panelClass: ['error']
      });
      this.loading = false;
    });

  }
  /* istanbul ignore next */
  getComponentNames() {
    const data: any = recipeModel.formulationRequestDetails;
    this.componentsToSelect = Array.from(new Set(data.map(s => s.multiComponentID)))
      .map(id => {
        return {
          multiComponentID: id,
          multiComponentName: data.find(s => s.multiComponentID === id).multiComponentName
        };
      });
  }
  /* istanbul ignore next */
  onComponentChange(val: any) {
    // this.selectedComponent = val.toLowerCase();
    this.selectedComponent = val;
    if (this.selectedComponent === localStorage.getItem('AsIsIngredientComponentname')) {
      this.hideMapRpc = true;
      this.dataForRPCMApping = [];
    } else {
      this.hideMapRpc = false;
      this.mappedRPCToComponent();
    }
  }
  /* istanbul ignore next */
  mappedRPCToComponent() {
    this.loading = true;
    const data = this.componentsToSelect.filter(x => x.multiComponentName === this.selectedComponent)[0];
    const URL = environment.APIFAsTRule + '/multicomponentMaps/markets/' + recipeModel.marketDetails.marketId + '/multiComponents/' + data.multiComponentID;
    this.assessRequestRa.getData(URL).subscribe((response) => {
      this.valuesForComponent = [];
      if (response !== null && response.length > 0) {
        this.loading = false;
        this.dataForRPCMApping = response;
        for (const val of response) {
          this.valuesForComponent.push(val.rpcName);
        }
      } else {
        this.loading = false;
        this.valuesForComponent = [];
        this.dataForRPCMApping = [];
      }
    }, (error) => {
      this.loading = false;
    });
  }
  /* istanbul ignore next */
  mapRPCTOComponent() {
    this.loading = true;
    const URL = environment.APIFAsTRule + '/multicomponentMaps';
    const responseData = [];
    const data = this.componentsToSelect.filter(x => x.multiComponentName === this.selectedComponent)[0];
    const dataVal = pureRulesDetailsModel.selectedLeafRpcs;
    if (dataVal.length > 0) {
      for (const val of dataVal) {
        const obj = {
          marketId: recipeModel.marketDetails.marketId,
          marketName: recipeModel.marketDetails.marketName,
          multiComponentId: data.multiComponentID,
          multiComponentName: data.multiComponentName,
          rpcName: val.text,
          rpcid: val.id
        };
        responseData.push(obj);
      }
      this.assessRequestRa.postData(URL, responseData).subscribe((response) => {
        if (response !== null) {
          this.loading = false;
          this.mappedRPCToComponent();
          this.matSnackBar.open('RPC mapping is done successfully', 'close', {
            duration: 3000,
            panelClass: ['success']
          });
        }
      }, (error) => {
        this.loading = false;
        this.matSnackBar.open('RPC Mapping already exists', 'close', {
          duration: 3000,
          panelClass: ['error']
        });
      });
    } else {
      this.loading = false;
      this.matSnackBar.open('No leaf node is selected', 'close', {
        duration: 3000,
        panelClass: ['success']
      });
    }
  }
  /* istanbul ignore next */
  getSelectedLeafRpcs() {
    pureRulesDetailsModel.selectedLeafRpcs = [];
    pureRulesDetailsModel.selectedRpcs.forEach((rpcValue) => {
      const childPresent = this.hasChildNodes(rpcValue);
      if (!childPresent) {
        pureRulesDetailsModel.selectedLeafRpcs.push(rpcValue);
      }
    });
  }
  /* istanbul ignore next */
  hasChildNodes(rpc) {
    let hasChild = false;
    this.rpcDetails.forEach((rpcValue) => {
      if (rpcValue.parent === rpc.id) {
        hasChild = true;
      }
    });
    return hasChild;
  }
  /* istanbul ignore next */
  getrpcDetailsBasedOnMarket(marketSelected) {
    this.selectedRPC = '';
    this.rpcDetails = [];
    this.loading = true;
    groupRulesDetailsModel.CascadeRpc = 'Y';
    const result = this.marketsToSelect.filter(x => x.marketName === marketSelected)[0];
    const url = environment.APIFAsT + '/marketid/' + result.marketId + '/tfcid/' + recipeModel.tfc.tfcId + '/rpclist';
    this.assessRequestRa.getData(url).subscribe((response) => {
      if (!this.onload) {
        this.loading = false;
      }
      if (response.length > 0) {
        this.rpcToSelect = response;
        this.onRPCChangedLoad(response[0]);
      } else {
        this.rpcToSelect = [];
        pureRulesDetailsModel.selectedRpcs = [];
        groupRulesDetailsModel.selectedRpcs = [];
        this.selectedRPC = '';
        this.getTreeDataBasedOnMarket(response);
        this.matSnackBar.open('No RPCs are found for market ' + marketSelected, 'close', {
          duration: 3000,
          panelClass: ['success']
        });
      }
    }, (error) => {
      this.loading = false;
    });
  }
  /* istanbul ignore next */
  onRPCChanged(selectedRPC) {
    this.onload = false;
    this.getTreeDataBasedOnMarket(selectedRPC.value);
  }
  /* istanbul ignore next */
  onRPCChangedLoad(selectedRPCOnLoad) {
    this.getTreeDataBasedOnMarket(selectedRPCOnLoad);
  }
  /* istanbul ignore next */
  getTreeDataBasedOnMarket(rpcVal) {
    this.loading = true;
    this.selectedRpcList = [];
    pureRulesDetailsModel.selectedRpcs = [];
    groupRulesDetailsModel.selectedRpcs = [];
    $('.tree-div-group').jstree('destroy').empty();
    const encodedTfcName = encodeURIComponent(encodeURIComponent(encodeURIComponent(recipeModel.tfc.tfcFullText)));
    let url;
    if (rpcVal.rpcId !== undefined) {
      url = environment.APIFAsT + '/markets/' + pureRulesDetailsModel.Market.marketName + '/tfcname/' + encodedTfcName + '/rpcs/fortree/rpc/' + rpcVal.rpcId;
    } else {
      url = environment.APIFAsT + '/markets/' + pureRulesDetailsModel.Market.marketName + '/tfcname/' + encodedTfcName + '/rpcs/fortree/rpc/0';
    }
    this.assessRequestRa.getData(url).subscribe((data) => {
      if (!this.onload) {
        this.loading = false;
      }
      this.rpcDetails = data;
      this.dataServiceObj.setSelectedRpcValues(this.rpcDetails);
      if (this.rpcDetails.length > 0) {
        for (const value of this.rpcDetails) {
          if (value.text === 'No Regulatory Product Class is Mapped') {
            pureRulesDetailsModel.selectedRpcs = [];
            groupRulesDetailsModel.selectedRpcs = [];
            this.selectedRpcList = [];
            this.createRpcTree(this.rpcDetails);
          }
        }
        if (rpcVal.rpcId !== undefined) {
          const urlData = environment.APIFAsT + '/markets/' + pureRulesDetailsModel.Market.marketName + '/tfcname/' + encodedTfcName + '/rpcs/formodel/rpc/' + rpcVal.rpcId;
          this.assessRequestRa.getData(urlData).subscribe((response) => {
            if (response !== null) {
              setTimeout(() => {
                if (this.disableLoader) {
                  this.loading = false;
                }
              }, 1000);
              pureRulesDetailsModel.RpcFullDetails = [];
              groupRulesDetailsModel.RpcFullDetails = [];
              this.rpcFullData = response;
              pureRulesDetailsModel.RpcFullDetails = this.rpcFullData;
              groupRulesDetailsModel.RpcFullDetails = this.rpcFullData;
              if (this.rpcFullData.length === 0) {
                pureRulesDetailsModel.RpcFullDetails = [];
                groupRulesDetailsModel.RpcFullDetails = [];
              } else {

                pureRulesDetailsModel.RpcFullDetails.forEach((rpcDetailedValue) => {
                  const index = _.findIndex(this.rpcDetails, (value) => Number(value.id) === rpcDetailedValue.parentRpcId);
                  if (index !== -1) {
                    this.rpcDetails[index].rpcFullText = rpcDetailedValue.parentRpcSearchValue;
                  }
                });
                groupRulesDetailsModel.RpcFullDetails.forEach((rpcDetailedValue) => {
                  const index = _.findIndex(this.rpcDetails, (value) => Number(value.id) === rpcDetailedValue.parentRpcId);
                  if (index !== -1) {
                    this.rpcDetails[index].rpcFullText = rpcDetailedValue.parentRpcSearchValue;
                  }
                });
                this.getSelectedParentRpcsForGroups();
                this.getSelectedLeafRpcs();
                this.createRpcTree(this.rpcDetails);
              }
            } else {
              setTimeout(() => {
                if (this.disableLoader) {
                  this.loading = false;
                }
              }, 1000);
              this.rpcFullData = [];
              pureRulesDetailsModel.selectedRpcs = [];
              groupRulesDetailsModel.selectedRpcs = [];
            }
          });
        }
      } else {
        setTimeout(() => {
          if (this.disableLoader) {
            this.loading = false;
          }
        }, 1000);
        pureRulesDetailsModel.selectedRpcs = [];
        groupRulesDetailsModel.selectedRpcs = [];
        this.selectedRpcList = [];
      }
    }, (error) => {
      this.loading = false;
    });
  }
  // on edit group button click
  /* istanbul ignore next */
  editGroup() {
    this.switchedPureCodesEdit = [];
    this.switchedSirDetailsEdit = [];
    this.createGroupTab = true;
    this.addSubstanceDialogue = false;
    this.isEditGroup = true;
    this.groupForm = this.formBuilder.group({
      GroupName: this.substanceGroupData.groupName,
      GroupType: 'Regulatory Group',
      UPDFullText: this.division,
      SubstanceIds: [],
    });
    this.createdGroup = this.substanceGroupData.substanceData;
    const ele = document.getElementById('echeck') as HTMLInputElement;
    ele.checked = false;
  }
  /* istanbul ignore next */
  rowselected(data) {
    this.onGroupSelect(data, data.data, data.index);
  }
  /* istanbul ignore next */
  onRowUnselect(event) {
    this.radioRowIndex = null;
  }
  addItem() {
    this.searchedSubstanceData = this.searchedSubstanceData.concat(this.searchedSubstanceDataTemp);
    this.searchedSubstanceDataTemp = [];
    this.addItemBtnDisableFlag = true;
    this.disablePureCode = false;
  }
  /* istanbul ignore next */
  valueSelected(rowselected: any, event) {
    if (event.currentTarget.checked) {
      console.log(event, 'event');
      let purecode;
      let sirId;
      let cas;
      let parentCAS;
      let cfdata;
      let einecs;
      let synonyms;
      let ins;
      let inciName;
      let sirIdNotFound;
      let existingRecord;
      this.checkSelectedVal = rowselected.sirId + ' (' + rowselected.substanceName + ')';
      if (rowselected) {
        const found = this.searchedSubstanceData.some(item => item.sirId === rowselected.sirId);
        if (found) {
          this.matSnackBar.open('Substance already added', 'close', {
            duration: 6000,
            panelClass: ['error']
          });
          this.loading = false;
          return false;
        }
        this.loading = false;
        const sirvalues = rowselected;
        purecode = sirvalues.pureCode;
        sirId = sirvalues.sirId;
        if (!sirId || sirId === '' || sirId.includes('SIR ID Not found')) {
          sirIdNotFound = true;
        } else {
          sirIdNotFound = false;
        }
        cas = sirvalues.cas;
        parentCAS = sirvalues.parentCAS;
        einecs = sirvalues.einecs;
        synonyms = sirvalues.substanceName;
        if (sirvalues.cfDetails !== null) {
          cfdata = sirvalues.cfDetails[0].cfDisplayText;
        }
        ins = sirvalues.ins;
        inciName = sirvalues.inciName;
        existingRecord = this.searchedSubstanceDataTemp.filter(x => x.sirId === sirId);
        if (this.isEditGroup === true) {
          existingRecord = this.substanceGroupData.substanceData.filter(x => x.sirId === sirId);
        }
        if (existingRecord && existingRecord.length > 0) {
          this.matSnackBar.open('Substance already added.', 'close', {
            duration: 6000,
            panelClass: ['error']
          });
          this.loading = false;
          return false;
        }
        this.searchedSubstanceDataTemp.push({
          purecode,
          sirId,
          cas,
          parentCAS,
          einecs,
          synonyms,
          cfdata,
          ins,
          inciName,
          isSubstanceChecked: false,
          sirIdNotFound
        });
        this.loading = false;
      } else if (this.searchIdentifier === 'Pure Code') {
        this.loading = false;
        existingRecord = this.searchedSubstanceDataTemp.filter(x => x.purecode === this.searchIdentifierValue);
        if (existingRecord && existingRecord.length > 0) {
          this.matSnackBar.open('Duplicate Record', 'close', {
            duration: 6000,
            panelClass: ['error']
          });
          return false;
        }
        purecode = this.searchIdentifierValue;
        const foundPure = this.searchedSubstanceData.some(item => item.purecode === rowselected.purecode);
        if (!foundPure) {
          this.searchedSubstanceDataTemp.push({
            purecode,
            isSubstanceChecked: false,
            sirIdNotFound: true
          });
        }
      } else {
        this.loading = false;
        this.matSnackBar.open('Could not find a matching substance, Please provide the correct search value', 'close', {
          duration: 6000,
          panelClass: ['error']
        });
      }
    } else {
      this.searchedSubstanceDataTemp = this.searchedSubstanceDataTemp.filter((item) => item.sirId !== rowselected.sirId);
    }
    if (this.searchedSubstanceDataTemp.length > 0) {
      this.addItemBtnDisableFlag = false;
    } else {
      this.addItemBtnDisableFlag = true;
    }
  }
  /* istanbul ignore next */
  checkval(event) {
    if (event.target.value === '') {
      this.searchResultsGroup = [];
      this.disablePureCode = false;
    }
    setTimeout(() => {
      if (event.target.value !== this.checkSelectedVal) {
        this.searchResultsGroup = [];
        this.disablePureCode = false;
      }
    }, 1000);
  }

  showOverlay(htmlContent) {
    this.overlayText = htmlContent;
    this.hideOverlay = false;
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
  hideOverlayPanel() {
    this.hideOverlay = true;
  }
  /* istanbul ignore next */
  openComment(data) {
    this.warningTextOpen = true;
    const mydata = data.originalWarningText;
    this.tablewarningComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  /* istanbul ignore next */
  openCommentORR(data) {
    this.orrTextOpen = true;
    const mydata = data.originalOtherRestrictionsAndRequirements;
    this.tableORRComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  closePopup() {
    this.warningTextOpen = false;
  }
  closePopupORR() {
    this.orrTextOpen = false;
  }
  /* istanbul ignore next */
  changeComment(input) {
    const strValue = input;
    const ar = strValue.match(/<a.*?<\/a>/g);
    let commentwarning = '';
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentwarning = 'Click Here';
    } else {
      commentwarning = input;
    }
    return commentwarning;
  }
   /* istanbul ignore next */
   onClearFilters(dtGroupTable) {
    dtGroupTable.reset();
    for (const value of this.groupColumns) {
      if (value.field !== 'check') {
        const colname = value.field + '_val';
        if ((<HTMLInputElement> document.getElementById(colname)) && (<HTMLInputElement> document.getElementById(colname)).value) {
          (<HTMLInputElement> document.getElementById(colname)).value = '';
        }
      }
    }
  }
}
