
import { CdkStepper } from '@angular/cdk/stepper';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { recipeModel } from '../common/models/recipeDetailsModel';
import { DataService } from '../common/services/data.service';
import { WorklistService } from '../worklist/worklist.service';
import { AssessRequestRaService } from './assess-request-ra.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-assess-request-ra',
  templateUrl: './assess-request-ra.component.html',
  styleUrls: ['./assess-request-ra.component.scss'],
  providers: [{ provide: CdkStepper }]
})

export class AssessRequestRaComponent implements OnInit {
  activeTab = 'nav-formulation-details';
  star = 'fa fa-fw fa-search';
  componentNameTab = 'AssessRequestsRA';
  loading = false;
  loadingMsg = 'Please wait User Authentication is in progress';
  public steps: string[];
  public selectedStep = 0;
  /* istanbul ignore next */
  constructor(
    public formBuilder: FormBuilder,
    public assessRaService: AssessRequestRaService,
    private routersvc: Router,
    private route: ActivatedRoute,
    public dialogbox: MatDialog,
    public matSnackBar: MatSnackBar,
    private worklistservice: WorklistService,
    private msalSvc: MsalService,
    private dataSvc: DataService) {

  }
  requestid;
  step;
  /* istanbul ignore next */
  ngOnInit() {
    this.dataSvc.setComponentName(this.componentNameTab);
    this.steps = this.route.snapshot.routeConfig.children.map(child => child.path);
    this.worklistservice.getProfileObs().subscribe(profile => this.requestid = profile);
    // tslint:disable-next-line: prefer-const
    let urlId = this.routersvc.url.split('/');
    if (this.requestid === null) {
      this.requestid = urlId[urlId.length - 1];
    }
    if (this.msalSvc.instance.getAllAccounts().length === 0) {
      this.loading = true;
      this.loadingMsg = 'Please wait User Authentication is in progress';
      setTimeout(() => {
        location.reload();
        this.loading = false;
        this.loadingMsg = '';
      }, 7000);
      setTimeout(() => {
      //  location.reload();
      //  this.loading = false;
        this.loadingMsg = '';
      }, 1500);
    }
    const isViewReportClicked = localStorage.getItem('isViewReportClicked');
    const requestStatus = localStorage.getItem('requestStatus');

    if (urlId[2] === 'submit-report-plm') {
      this.selectedStep = 3;
      // const compltedreport = JSON.parse(localStorage.getItem('CompletedReport'));
      const division = urlId[4];
      this.step = 'submit-report-plm/' + urlId[3] + '/' + division;
      //  this.step = 'submit-report-plm/' + compltedreport.requestDisplayID;
      this.componentNameTab = 'SubmitReportPLM';
      this.dataSvc.setComponentName(this.componentNameTab);
    } else if (urlId[2] === 'verify-request-plm' && this.steps[4] === 'verify-request-plm/:requestId' && recipeModel.requestStatus !== 'In Progress – R&D') {
      this.step = 'verify-request-plm/' + this.requestid;
      this.dataSvc.setComponentName(this.componentNameTab);
    } else if (this.steps[0] === 'verify-request/:requestId' && recipeModel.requestStatus !== 'In Progress – R&D' && isViewReportClicked === 'false') {
      this.step = 'verify-request/' + this.requestid;
    } else if (this.steps[3] === 'submit-report' && requestStatus === 'Completed' && isViewReportClicked === 'true') {
      this.selectedStep = 3;
      this.step = 'submit-report';
      this.componentNameTab = 'SubmitReportRA';
      this.dataSvc.setComponentName(this.componentNameTab);
    } else if (recipeModel.requestStatus === 'In Progress – R&D') {
      this.selectedStep = 2;
      this.step = 'execute-rules';
    } else {
      this.step = 'verify-request/' + this.requestid;
    }
    this.steps = this.steps.slice(0, 4);
    this.routersvc.navigate([this.step], { relativeTo: this.route });
  }
  /* istanbul ignore next */
  selectionChanged(event: any) {
    this.selectedStep = event.selectedIndex;
    this.worklistservice.getProfileObs().subscribe(profile => this.requestid = profile);
    if (event.selectedIndex === 0) {
      this.step = 'verify-request/' + this.requestid;
      this.routersvc.navigate([this.step], { relativeTo: this.route });
    } else {
      this.routersvc.navigate([this.steps[this.selectedStep]], { relativeTo: this.route });
    }
  }

}
