export const SCROLLABLE_COLUMNS = [
    { field: 'specDescription', header: 'Ingredient Description', width: '180px' },
    { field: 'specQuantity', header: 'Quantity', width: '110px' },
    { field: 'specUoM', header: 'UoM', width: '100px' },
    { field: 'pureCode', header: 'PURE Code', width: '150px' },
    { field: 'pureDescription', header: 'PURE Description', width: '140px' },
    { field: 'recipeQuantityToDisplay', header: 'PURE Quantity', width: '150px' },
    { field: 'recipeUoM', header: 'UoM', width: '110px' },
    { field: 'substanceFunctionInput', header: 'Function', width: '110px' },
    { field: 'substanceRuleStatus', header: 'Status', width: '80px' }
];
export const COLUMNS = [
    { field: 'specID', header: 'Ingredient' },
    { field: 'specDescription', header: 'Ingredient Description' },
    { field: 'specQuantity', header: 'Quantity' },
    { field: 'specUoM', header: 'UoM' },
    { field: 'pureCode', header: 'PURE Code' },
    { field: 'pureDescription', header: 'PURE Description' },
    { field: 'recipeQuantityToDisplay', header: 'Quantity' },
    { field: 'recipeUoM', header: 'UoM' },
    { field: 'substanceFunctionInput', header: 'Function', width: '100px' },
    { field: 'substanceRuleStatus', header: 'Status' }
];
export const PRODUCT_COLUMNS = [
    { field: 'propertyClass', header: 'Property', width: '100px' },
    { field: 'propertyName', header: 'Sub Property', width: '110px' },
    { field: 'propertyRecipeQuantity', header: 'Quantity', width: '100px' },
    { field: 'propertyUoM', header: 'UoM', width: '100px' },
    { field: 'ruleTypeDisplayName', header: 'Rule Type', width: '130px' },
    { field: 'propertyMinLimit', header: 'Min. Value', width: '100px' },
    { field: 'propertyMaxLimit', header: 'Max. Value', width: '100px' },
    { field: 'propertyTextualMinLimits', header: 'Textual Min', width: '120px' },
    { field: 'propertyTextualMaxLimits', header: 'Textual Max', width: '120px' },
    { field: 'additionalStatement', header: 'Additional Statement', width: '170px' },
    { field: 'standardPositiveStatement', header: 'Positive Statement', width: '150px' },
    { field: 'standardNegativeStatement', header: 'Negative Statement', width: '155px' },
    { field: 'warning', header: 'Warning', width: '100px' },
    { field: 'ruleSource', header: 'Source', width: '100px' },
    { field: 'ruleExecutionStatus', header: 'Status', width: '90px' }
];
export const DETAILED_REPORT_COLUMNS = [
    { field: 'pureCode', header: 'PURE Code', width: '100px' },
    { field: 'pureDescription', header: 'PURE Description', width: '140px' },
    { field: 'substanceName', header: 'Substance Name', width: '150px' },
    { field: 'casNumber', header: 'CAS', width: '100px' },
    { field: 'parentCASNumber', header: 'Parent CAS', width: '100px' },
    { field: 'inciNumber', header: 'INCI', width: '110px' },
    { field: 'insNumber', header: 'INS', width: '100px' },
    { field: 'substanceID', header: 'SIR ID', width: '120px' },
    { field: 'marketNameForDisplay', header: 'Market', width: '100px' },
    { field: 'substanceFunctionInput', header: 'User Input Function', width: '180px' },
    { field: 'rpcListForDisplay', header: 'RPC Levels', width: '200px' },
    { field: 'ruleFunctionName', header: 'Rule Function', width: '130px' },
    { field: 'recipeQuantity', header: 'PURE Quantity', width: '130px' },
    { field: 'recipeUoM', header: 'UoM', width: '100px' },
    { field: 'ruleTypeDisplayName', header: 'Rule Type', width: '100px' },
    { field: 'minLimit', header: 'Min. Value', width: '100px' },
    { field: 'maxLimit', header: 'Max. Value', width: '100px' },
    { field: 'textualMinLimits', header: 'Textual Min. Limit', width: '145px' },
    { field: 'textualMaxLimits', header: 'Textual Max. Limit', width: '150px' },
    { field: 'standardPositiveStatement', header: 'Positive Statement', width: '150px' },
    { field: 'standardNegativeStatement', header: 'Negative Statement', width: '155px' },
    { field: 'warningText', header: 'Warning', width: '150px' },
    { field: 'additionalStatement', header: 'Additional Statement', width: '170px' },
    { field: 'userInputClaim', header: 'Claims', width: '100px' },
    { field: 'userInputSpecificCondition', header: 'Conditional Requirements', width: '155px' },
    { field: 'ruleClaimName', header: 'Rule Claim', width: '100px' },
    { field: 'ruleSpecificConditionName', header: 'Rule Specific Condition', width: '180px' },
    { field: 'commentForDisplay', header: 'Comments', width: '155px' },
    { field: 'isAggregated', header: 'Is Aggregated', width: '130px' },
    { field: 'ruleExecutionStatus', header: 'Status', width: '90px' } // 29
];
export const FROZEN_COLUMNS = [
    { field: 'specID', header: 'Ingredient', width: '140px' }
];

export const PROPERTY_COLUMNS = [
    { field: 'propertyClass', header: 'Property', width: '130px'},
    { field: 'propertyName', header: 'Sub Property', width: '130px' },
    { field: 'propertyTypicalQuantity', header: 'Typical', width: '90px' },
    { field: 'propertyMinQuantity', header: 'Min', width: '90px' },
    { field: 'propertyMaxQuantity', header: 'Max', width: '90px' },
    { field: 'propertyTargetQuantity', header: 'Target', width: '90px' },
    { field: 'propertyUoM', header: 'UoM', width: '90px' },
    { field: 'originatorComment', header: 'R&D Comment', width: '90px' }

];
export const PROPERTY_COLUMNS_VIEW = [
    { field: 'propertyClass', header: 'Property', width: '120px', padding: '10px', margin: '2%'},
    { field: 'propertyName', header: 'Sub Property', width: '120px' },
    { field: 'propertyTypicalQuantity', header: 'Typical', width: '80px' },
    { field: 'propertyMinQuantity', header: 'Min', width: '80px' },
    { field: 'propertyMaxQuantity', header: 'Max', width: '80px' },
    { field: 'propertyTargetQuantity', header: 'Target', width: '80px' },
    // { field: 'propertyUoM', header: 'UoM', width: '90px' },
    // { field: 'raComment', header: 'RA Comment', width: '90px' },
    // { field: 'originatorComment', header: 'R&D Comment', width: '90px' }
    { field: 'propertynQuantity', header: 'n', width: '40px' ,  statement : 'Number of sample units chosen separately and independently' },
    { field: 'propertycQuantity', header: 'c', width: '40px',  statement : 'Maximum allowable number of results between m and M' },
    { field: 'propertymQuantity', header: 'm', width: '40px', statement : 'Level of the test organism which is acceptable and attainable in food' },
    { field: 'propertyMQuantity', header: 'M', width: '40px', statement : 'The upper acceptable concentration of a test organism' },
    { field: 'propertyUoM', header: 'UoM', width: '70px', statement : 'Unit of Measurement'},
    { field: 'propertyMethodQuantity', header: 'Method', width: '80px' , statement : 'Test Method used' },
    { field: 'originatorComment', header: 'R&D Comment', width: '90px' }
];
export const USER_INPUT_COLUMNS = [
    { field: 'pureCode', header: 'PURE Code', width: '100px' },
    { field: 'substanceID', header: 'SIR ID', width: '120px' },
    { field: 'substanceFunctionInput', header: 'User Input Function', width: '180px' },
    { field: 'userInputClaim', header: 'Claims', width: '100px' },
    { field: 'userInputSpecificCondition', header: 'Conditional Requirements', width: '155px' }
];
export const REPORT_PURE_DETAILS = [
    { field: 'ruleExecutionStatus', header: 'Status', width: '60px', filter: 'N' },
    { field: 'commentForDisplay', header: 'Comments', width: '120px', filter: 'Y' },
    { field: 'warningText', header: 'Warnings', width: '100px', filter: 'Y' },
    { field: 'warningicon', header: '', width: '14px', filter: 'N' },
    { field: 'convertedRecipeQty', header: 'Calculated Pure Quantity', width: '100px', filter: 'Y' },
    { field: 'finalUoM', header: 'Calculated Pure UoM', width: '100px', filter: 'Y' },
    { field: 'rndInputFunction', header: 'Request Function', width: '150px', filter: 'Y' },
    { field: 'substanceFunctionInput', header: 'Rule Evaluated Function', width: '150px', filter: 'Y', statement: 'R&D selected function during the additional input step, It may be the same as Request input or may be overridden for rule execution' },
    { field: 'marketNameForDisplay', header: 'Market', width: '120px', filter: 'Y' },
    { field: 'ruleRPC', header: 'Rule RPC', width: '100px', filter: 'Y' },
    { field: 'rpcListForDisplay', header: 'RPC List', width: '100px', filter: 'Y' },
    { field: 'ruleTypeDisplayName', header: 'Rule Type', width: '120px', filter: 'Y' },
    { field: 'maxLimit', header: 'Max', width: '100px', filter: 'Y' },
    { field: 'minLimit', header: 'Min', width: '120px', filter: 'Y' },
    { field: 'ruleUoM', header: 'Rule UOM', width: '120px', filter: 'Y' },
    { field: 'ruleFunctionName', header: 'Rule Function', width: '100px', filter: 'Y' },
    { field: 'recipeQuantity', header: 'PLM Pure Qty', width: '100px', filter: 'Y' },
    { field: 'recipeUoM', header: 'PLM Pure UOM', width: '100px', filter: 'Y' },
    { field: 'isExempted', header: 'Exempted Y/N', width: '100px', filter: 'Y' },
    { field: 'ruleID', header: 'Rule ID', width: '100px', filter: 'Y' },
    { field: 'ruleVersion', header: 'Rule Version', width: '100px', filter: 'Y' },
    { field: 'action', header: 'Action', width: '100px', filter: 'N' },
];

export const DETAILS_POPUP = [
    { field: 'ruleExecutionStatus', header: 'Status', width: '100px' },
    { field: 'commentForDisplay', header: 'Comments', width: '100px' },
    { field: 'warningText', header: 'Warnings', width: '100px' },
    { field: 'warningicon', header: '', width: '12px', filter: 'N' },
    { field: 'groupApplicability', header: 'Group Rule Applicability', width: '100px' },
    { field: 'pureCode', header: 'PURE Code', width: '100px' },
    { field: 'pureDescription', header: 'PURE Description', width: '100px' },
    { field: 'convertedRecipeQty', header: 'Calculated Pure Quantity', width: '100px' },
    { field: 'finalUoM', header: 'Calculated Pure UoM', width: '100px' },
    { field: 'recipeQuantity', header: 'PLM PURE Quantity', width: '100px' },
    { field: 'recipeUoM', header: 'PLM Unit of Measure', width: '80px' },
    { field: 'inciNumber', header: 'INCI', width: '100px' },
    { field: 'cfSubstanceExpressedAs', header: 'CF Substance Expressed as', width: '100px' },
    { field: 'cf', header: 'CF', width: '100px' },
    { field: 'isAllergen', header: 'Allergen (Y/N)', width: '100px' },
    { field: 'hazardClass', header: 'Hazard Classification', width: '100px' },
    { field: 'marketNameForDisplay', header: 'Market', width: '100px' },
    { field: 'rpcListForDisplay', header: 'RPC Path', width: '100px' },
    { field: 'ruleRPC', header: 'Rule RPC', width: '100px' },
    { field: 'ruleTypeDisplayName', header: 'Rule Type', width: '150px' },
    { field: 'allowedFuncFlag', header: 'Allowed Function (Y/N)', width: '100px' },
    { field: 'rndInputFunction', header: 'Request Function', width: '150px' },
    { field: 'substanceFunctionInput', header: 'Rule Evaluated Function', width: '150px', statement: 'R&D selected function during the additional input step, It may be the same as Request input or may be overridden for rule execution' },
    { field: 'equalityCondition', header: 'Equality Condition', width: '100px' },
    { field: 'minLimit', header: 'Minimum value', width: '80px' },
    { field: 'maxLimit', header: 'Maximum value', width: '80px' },
    { field: 'ruleUoM', header: 'Rule UOM', width: '120px' },
    { field: 'groupName', header: 'Substance Group', width: '80px' },
    { field: 'isExempted', header: 'Exempted Y/N', width: '100px' },
    { field: 'conflictingSubstance', header: 'Conflicting Substance', width: '100px' },
    { field: 'combinationSubstance', header: 'Combination Substance', width: '100px' },
    { field: 'combinationRatioToDisplay', header: 'Combination Ratio', width: '100px' },
    { field: 'ruleSpecificConditionName', header: 'Conditional Requirements', width: '100px' },
    { field: 'ruleClaimName', header: 'Claims', width: '100px' },
    { field: 'phaseOutDate', header: 'Phasing out Date', width: '80px' },
    { field: 'otherRestrictionsAndRequirements', header: 'Other Requirements and Restrictions', width: '150px' },
    { field: 'otherRestrictionsAndRequirementsicon', header: '', width: '12px', filter: 'N' },
    { field: 'propertySource', header: 'Property Source', width: '80px' },
    { field: 'propertyClass', header: 'Property', width: '100px' },
    { field: 'propertyName', header: 'Sub property', width: '100px' },
    { field: 'ingPropertyType', header: 'ING Rule Type', width: '100px' },
    { field: 'ingPropertyName', header: 'ING Property Name', width: '100px' },
    { field: 'ruleSource', header: 'Rule Source', width: '100px' },
    { field: 'validityDate', header: 'Validity', width: '100px' },
    { field: 'substanceID', header: 'SIR ID', width: '100px' },
    { field: 'casNumber', header: 'CAS #', width: '100px' },
    { field: 'parentCASNumber', header: 'Parent CAS #', width: '100px' },
    { field: 'einecsNumber', header: 'EINECS#', width: '100px' },
    { field: 'eNumber', header: 'E #', width: '100px' },
    { field: 'insNumber', header: 'INS #', width: '100px' },
    { field: 'multiComponentName', header: 'Component type', width: '80px' },
    { field: 'preparationMethod', header: 'Preparation Method	', width: '100px' },
    { field: 'intendedUse', header: 'Intended Use', width: '100px' },
    { field: 'groupID', header: 'Group ID', width: '100px' },
    { field: 'groupName', header: 'Group Name', width: '100px' },
    { field: 'ruleID', header: 'Rule ID', width: '100px' },
    { field: 'ruleVersion', header: 'Rule Version', width: '100px' },
    { field: 'acidityAsAceticAcid', header: 'Acetic Acid', width: '100px' },
    { field: 'aceticAcidUoM', header: 'Acetic Acid UoM', width: '100px' },
    { field: 'brixOfLemon', header: 'Brix of Lemon', width: '100px' },
    { field: 'brixOfLemonUoM', header: 'Brix of Lemon UoM', width: '100px' },
    { field: 'warningText1', header: 'Warnings Link 1', width: 25, show: 'N' },
    { field: 'warningText2', header: 'Warnings Link 2', width: 25, show: 'N' },
    { field: 'warningText3', header: 'Warnings Link 3', width: 25, show: 'N' },
    { field: 'warningText4', header: 'Warnings Link 4', width: 25, show: 'N' },
    { field: 'warningText5', header: 'Warnings Link 5', width: 25, show: 'N' },
    { field: 'warningText6', header: 'Warnings Link 6', width: 25, show: 'N' },
    { field: 'warningText7', header: 'Warnings Link 7', width: 25, show: 'N' },
    { field: 'ORR1', header: 'Other Requirements and Restrictions Link 1', width: 45, show: 'N'  },
    { field: 'ORR2', header: 'Other Requirements and Restrictions Link 2', width: 45, show: 'N'  },
    { field: 'ORR3', header: 'Other Requirements and Restrictions Link 3', width: 45, show: 'N'  },
    { field: 'ORR4', header: 'Other Requirements and Restrictions Link 4', width: 45, show: 'N'  },
    { field: 'ORR5', header: 'Other Requirements and Restrictions Link 5', width: 45, show: 'N'  },
    { field: 'ORR6', header: 'Other Requirements and Restrictions Link 6', width: 45, show: 'N'  },
    { field: 'ORR7', header: 'Other Requirements and Restrictions Link 7', width: 45, show: 'N'  },
];

export const DETAILS_INGREDIENT_POPUP = [
    { field: 'ruleExecutionStatus', header: 'Status', width: '100px' },
    { field: 'amendmentNumber', header: 'Amendment Number', width: '135px' },
    { field: 'commentForDisplay', header: 'Comments', width: '100px' },
    { field: 'specId', header: 'Specification', width: '100px' },
    { field: 'ingredientDescription', header: 'Ingredient Description', width: '140px' },
    { field: 'groupApplicability', header: 'Group Rule Applicability', width: '150px' },
    { field: 'pureCode', header: 'PURE Code', width: '100px' },
    { field: 'pureDescription', header: 'PURE Description', width: '120px' },
    { field: 'convertedRecipeQty', header: 'Calculated Pure Quantity', width: '160px' },
    { field: 'finalUoM', header: 'Calculated Pure UoM', width: '150px' },
    { field: 'recipeQuantity', header: 'PLM PURE Quantity', width: '130px' },
    { field: 'recipeUoM', header: 'PLM Unit of Measure', width: '130px' },
    { field: 'marketNameForDisplay', header: 'Market', width: '100px' },
    { field: 'rpcListForDisplay', header: 'RPC Path', width: '100px' },
    { field: 'ruleRPC', header: 'Rule RPC', width: '100px' },
    { field: 'ruleTypeDisplayName', header: 'Rule Type', width: '150px' },
    { field: 'minLimit', header: 'Minimum value', width: '120px' },
    { field: 'maxLimit', header: 'Maximum value', width: '120px' },
    { field: 'ruleUoM', header: 'Rule UOM', width: '120px' },
    { field: 'substanceID', header: 'SIR ID', width: '100px' },
];

export const VIEW_DETAILED_REPORT = [
    { field: 'specID', header: 'Specification', width: '100px' },
    { field: 'specDescription', header: 'Specification Description', width: '100px' },
    { field: 'specQuantity', header: 'Qty Per Str', width: '100px' },
    { field: 'ingredientPath', header: 'Ingredient Path', width: '100px' },
    { field: 'ingDescription', header: 'Ingredient Description', width: '100px' },
    { field: 'pureCode', header: 'PURE CODE', width: '100px' },
    { field: 'pureDescription', header: 'PURE Description', width: '100px' },
    { field: 'recipeQuantity', header: 'Qty Per PO%', width: '100px' },
    { field: 'rndInputFunction', header: 'Request Function', width: '150px' },
    { field: 'substanceFunctionInput', header: 'Rule Evaluated Function', width: '100px', statement: 'R&D selected function during the additional input step, It may be the same as Request input or may be overridden for rule execution' },
    { field: 'ordering', header: 'Status', width: '100px' },
];

export const PROPERTY_DETAIL_COLUMNS = [
    { field: 'ruleExecutionStatus', header: 'Status', width: '100px' },
    { field: 'commentForDisplay', header: 'Comments', width: '100px' },
    { field: 'ruleID', header: 'Rule ID', width: '100px' },
    { field: 'ruleVersion', header: 'Rule Version', width: '100px' },
    { field: 'marketNameForDisplay', header: 'Market', width: '100px' },
    { field: 'rpcList', header: 'RPC Path', width: '100px' },
    { field: 'ruleRPC', header: 'Rule RPC', width: '100px' },
    { field: 'ruleTypeDisplayName', header: 'Rule Type', width: '100px' },
    { field: 'propertySource', header: 'Property Source', width: '100px' },
    { field: 'propertyClass', header: 'Property', width: '100px' },
    { field: 'propertyName', header: 'Sub Property', width: '100px' },
    { field: 'propertyMinLimit', header: 'Min', width: '100px' },
    { field: 'equalityCondition', header: 'Equality Condition', width: '100px' },
    { field: 'propertyMaxLimit', header: 'Max', width: '100px' },
    { field: 'ruleUoM', header: 'Unit of Measure', width: '100px' },
    { field: 'ruleClaimName', header: 'Claims', width: '100px' },
    { field: 'ruleSpecificConditionName', header: 'Conditional Requirements', width: '200px' },
    { field: 'warning', header: 'Warning', width: '100px' },
    { field: 'warningicon', header: '', width: '14px', filter: 'N' },
    { field: 'otherRestrictionsAndRequirements', header: 'Other Requirements and Restrictions', width: '150px' },
    { field: 'otherRestrictionsAndRequirementsicon', header: '', width: '12px', filter: 'N' },
    { field: 'componentType', header: 'Component type', width: '100px' },
    { field: 'preparationMethod', header: 'Prepatation Method', width: '100px' },
    { field: 'intendedUse', header: 'Intended Use', width: '100px' },
    { field: 'propertyTypicalQuantity', header: 'Property Typical', width: '150px' },
    { field: 'propertyMinQuantity', header: 'Property Min', width: '100px' },
    { field: 'propertyMaxQuantity', header: 'Property Max', width: '100px' },
    { field: 'propertyTargetQuantity', header: 'Property Target', width: '150px' },
    { field: 'propertyUoM', header: 'Property UoM', width: '100px' },
    { field: 'ruleCreatedByUserDisplayName', header: 'Created By', width: '100px' },
    { field: 'ruleCreatedDate', header: 'Created Date', width: '100px' },
    { field: 'ruleModifiedByUserDisplayName', header: 'Modified By', width: '100px' },
    { field: 'ruleModifiedDate', header: 'Modified Date', width: '100px' },
    { field: 'ruleSourceType', header: 'Source', width: '100px' },
    { field: 'validityDate', header: 'Validity', width: '100px' },
];
export const PROPERTY_DETAIL_COLUMNS_TAB_VIEW = [
    { field: 'ruleExecutionStatus', header: 'Status', width: '100px' },
    { field: 'commentForDisplay', header: 'Comments', width: '100px' },
    { field: 'ruleID', header: 'Rule ID', width: '100px' },
    { field: 'ruleVersion', header: 'Rule Version', width: '100px' },
    { field: 'marketNameForDisplay', header: 'Market', width: '100px' },
    { field: 'rpcList', header: 'RPC Path', width: '100px' },
    { field: 'ruleRPC', header: 'Rule RPC', width: '100px' },
    { field: 'ruleTypeDisplayName', header: 'Rule Type', width: '100px' },
    { field: 'propertySource', header: 'Property Source', width: '100px' },
    { field: 'propertyClass', header: 'Property', width: '100px' },
    { field: 'propertyName', header: 'Sub Property', width: '100px' },
    { field: 'propertyMinLimit', header: 'Min', width: '100px' },
    { field: 'equalityCondition', header: 'Equality Condition', width: '100px' },
    { field: 'propertyMaxLimit', header: 'Max', width: '100px' },
    { field: 'ruleUoM', header: 'Unit of Measure', width: '100px' },
    { field: 'ruleClaimName', header: 'Claims', width: '100px' },
    { field: 'ruleSpecificConditionName', header: 'Conditional Requirements', width: '200px' },
    { field: 'warning', header: 'Warning', width: '130px' },
    { field: 'warningicon', header: '', width: '14px', filter: 'N' },
    { field: 'otherRestrictionsAndRequirements', header: 'Other Requirements and Restrictions', width: '150px' },
    { field: 'otherRestrictionsAndRequirementsicon', header: '', width: '12px', filter: 'N' },
    { field: 'componentType', header: 'Component type', width: '100px' },
    { field: 'preparationMethod', header: 'Prepatation Method', width: '100px' },
    { field: 'intendedUse', header: 'Intended Use', width: '100px' },
    { field: 'propertyTypicalQuantity', header: 'Property Typical', width: '150px' },
    { field: 'propertyMinQuantity', header: 'Property Min', width: '100px' },
    { field: 'propertyMaxQuantity', header: 'Property Max', width: '100px' },
    { field: 'propertyTargetQuantity', header: 'Property Target', width: '150px' },
    { field: 'propertynQuantity', header: 'Property n', width: '90px', statement : 'Number of sample units chosen separately and independently' },
    { field: 'propertycQuantity', header: 'Property c', width: '90px', statement : 'Maximum allowable number of results between m and M' },
    { field: 'propertymQuantity', header: 'Property m', width: '90px', statement : 'Level of the test organism which is acceptable and attainable in food' },
    { field: 'propertyMQuantity', header: 'Property M', width: '90px' , statement : 'The upper acceptable concentration of a test organism'},
    { field: 'propertyUoM', header: 'Property UoM', width: '100px' },
    { field: 'propertyMethodQuantity', header: 'Property Method', width: '90px' , statement : 'Test Method used' },
    { field: 'ruleCreatedByUserDisplayName', header: 'Created By', width: '100px' },
    { field: 'ruleCreatedDate', header: 'Created Date', width: '100px' },
    { field: 'ruleModifiedByUserDisplayName', header: 'Modified By', width: '100px' },
    { field: 'ruleModifiedDate', header: 'Modified Date', width: '100px' },
    { field: 'ruleSourceType', header: 'Source', width: '100px' },
    { field: 'validityDate', header: 'Validity', width: '100px' },
];
export const PROPERTY_DETAIL_COLUMNS_VIEW = [
    { field: 'ruleExecutionStatus', header: 'Status', width: '100px' },
    { field: 'commentForDisplay', header: 'Comments', width: '100px' },
    { field: 'ruleID', header: 'Rule ID', width: '100px' },
    { field: 'ruleVersion', header: 'Rule Version', width: '100px' },
    { field: 'marketNameForDisplay', header: 'Market', width: '100px' },
    { field: 'rpcList', header: 'RPC Path', width: '100px' },
    { field: 'ruleRPC', header: 'Rule RPC', width: '100px' },
    { field: 'ruleTypeDisplayName', header: 'Rule Type', width: '100px' },
    { field: 'propertySource', header: 'Property Source', width: '100px' },
    { field: 'propertyClass', header: 'Property', width: '100px' },
    { field: 'propertyName', header: 'Sub Property', width: '100px' },
    { field: 'propertyMinLimit', header: 'Min', width: '100px' },
    { field: 'equalityCondition', header: 'Equality Condition', width: '100px' },
    { field: 'propertyMaxLimit', header: 'Max', width: '100px' },
    { field: 'ruleUoM', header: 'Unit of Measure', width: '80px'},
    { field: 'ruleClaimName', header: 'Claims', width: '100px' },
    { field: 'ruleSpecificConditionName', header: 'Conditional Requirements', width: '200px' },
    { field: 'warning', header: 'Warning', width: '100px' },
    { field: 'warningicon', header: '', width: '14px', filter: 'N' },
    { field: 'otherRestrictionsAndRequirements', header: 'Other Requirements and Restrictions', width: '150px' },
    { field: 'otherRestrictionsAndRequirementsicon', header: '', width: '12px', filter: 'N' },
    { field: 'componentType', header: 'Component type', width: '100px' },
    { field: 'preparationMethod', header: 'Prepatation Method', width: '100px' },
    { field: 'intendedUse', header: 'Intended Use', width: '100px' },
    { field: 'propertyTypicalQuantity', header: 'Property Typical', width: '150px' },
    { field: 'propertyMinQuantity', header: 'Property Min', width: '100px' },
    { field: 'propertyMaxQuantity', header: 'Property Max', width: '100px' },
    { field: 'propertyTargetQuantity', header: 'Property Target', width: '150px' },
    { field: 'propertynQuantity', header: 'Property n', width: '90px', statement : 'Number of sample units chosen separately and independently' },
    { field: 'propertycQuantity', header: 'Property c', width: '90px', statement : 'Maximum allowable number of results between m and M' },
    { field: 'propertymQuantity', header: 'Property m', width: '90px', statement : 'Level of the test organism which is acceptable and attainable in food' },
    { field: 'propertyMQuantity', header: 'Property M', width: '90px' , statement : 'The upper acceptable concentration of a test organism'},
    { field: 'propertyUoM', header: 'Property UoM', width: '100px' },
    { field: 'propertyMethodQuantity', header: 'Property Method', width: '90px' , statement : 'Test Method used' },
    { field: 'ruleCreatedByUserDisplayName', header: 'Created By', width: '100px' },
    { field: 'ruleCreatedDate', header: 'Created Date', width: '100px' },
    { field: 'ruleModifiedByUserDisplayName', header: 'Modified By', width: '100px' },
    { field: 'ruleModifiedDate', header: 'Modified Date', width: '100px' },
    { field: 'ruleSourceType', header: 'Source', width: '100px' },
    { field: 'validityDate', header: 'Validity', width: '100px' },
];

export const PRODUCT_DETAILS_COLUMNS = [
    { field: 'ruleExecutionStatus', header: 'Status', width: '100px' },
    { field: 'commentForDisplay', header: 'Comments', width: '100px' },
    { field: 'ruleID', header: 'Rule ID', width: '100px' },
    { field: 'ruleVersion', header: 'Rule Version', width: '100px' },
    { field: 'convertedRecipeQty', header: 'Calculated Pure Quantity', width: '100px' },
    { field: 'finalUoM', header: 'Calculated Pure UoM', width: '100px' },
    { field: 'recipeQuantity', header: 'PLM Pure Qty', width: '100px' },
    { field: 'recipeUoM', header: 'PLM Pure UOM', width: '100px' },
    { field: 'marketNameForDisplay', header: 'Market', width: '100px' },
    { field: 'rpcListForDisplay', header: 'RPC Path', width: '100px' },
    { field: 'ruleRPC', header: 'Rule RPC', width: '100px' },
    { field: 'ruleTypeDisplayName', header: 'Rule Type', width: '100px' },
    { field: 'pureCode', header: 'PURE Code', width: '100px' },
    { field: 'pureDescription', header: 'PURE Description', width: '100px' },
    { field: 'inciNumber', header: 'INCI', width: '100px' },
    { field: 'ruleFunctionName', header: 'Function', width: '100px' },
    { field: 'minLimit', header: 'Minimum value', width: '80px' },
    { field: 'equalityCondition', header: 'Equality Condition', width: '100px' },
    { field: 'maxLimit', header: 'Maximum value', width: '80px' },
    { field: 'ruleUoM', header: 'Unit of Measure', width: '80px' },
    { field: 'ruleClaimName', header: 'Claims', width: '80px' },
    { field: 'ruleSpecificConditionName', header: 'Conditional Requirements', width: '100px' },
    { field: 'warningText', header: 'Warnings', width: '100px' },
    { field: 'warningicon', header: '', width: '12px', filter: 'N' },
    { field: 'otherRestrictionsAndRequirements', header: 'Other Requirements and Restrictions', width: '150px' },
    { field: 'otherRestrictionsAndRequirementsicon', header: '', width: '12px', filter: 'N' },
    { field: 'substanceID', header: 'SIR ID', width: '100px' },
    { field: 'casNumber', header: 'CAS #', width: '100px' },
    { field: 'parentCASNumber', header: 'Parent CAS #', width: '100px' },
    { field: 'einecsNumber', header: 'EINECS#', width: '100px' },
    { field: 'eNumber', header: 'E #', width: '100px' },
    { field: 'insNumber', header: 'INS #', width: '100px' },
    { field: 'componentName', header: 'Component type	', width: '80px' },
    { field: 'preparationMethod', header: 'Preparation Method	', width: '100px' },
    { field: 'intendedUse', header: 'Intended Use', width: '100px' },
    { field: 'ruleCreatedByUserDisplayName', header: 'Created By', width: '100px' },
    { field: 'ruleCreatedDate', header: 'Created Date', width: '100px' },
    { field: 'ruleModifiedByUserDisplayName', header: 'Modified By', width: '100px' },
    { field: 'ruleModifiedDate', header: 'Modified Date', width: '100px' },
    { field: 'ruleSource', header: 'Source', width: '100px' },
    { field: 'validityDate', header: 'Validity', width: '100px' },
    { field: 'yearAdopted', header: 'Year Adopted', width: '100px' },
    { field: 'action', header: 'Action', width: '100px', filter: 'N' },
];
export const PRODUCT_DETAILS_COLUMNS_VIEW = [
    { field: 'ruleExecutionStatus', header: 'Status', width: '100px' },
    { field: 'commentForDisplay', header: 'Comments', width: '100px' },
    { field: 'ruleID', header: 'Rule ID', width: '100px' },
    { field: 'ruleVersion', header: 'Rule Version', width: '100px' },
    { field: 'convertedRecipeQty', header: 'Calculated Pure Quantity', width: '100px' },
    { field: 'finalUoM', header: 'Calculated Pure UoM', width: '100px' },
    { field: 'recipeQuantity', header: 'PLM Pure Qty', width: '100px' },
    { field: 'recipeUoM', header: 'PLM Pure UOM', width: '100px' },
    { field: 'marketNameForDisplay', header: 'Market', width: '100px' },
    { field: 'rpcListForDisplay', header: 'RPC Path', width: '100px' },
    { field: 'ruleRPC', header: 'Rule RPC', width: '100px' },
    { field: 'ruleTypeDisplayName', header: 'Rule Type', width: '100px' },
    { field: 'pureCode', header: 'PURE Code', width: '100px' },
    { field: 'pureDescription', header: 'PURE Description', width: '100px' },
    { field: 'inciNumber', header: 'INCI', width: '100px' },
    { field: 'ruleFunctionName', header: 'Function', width: '100px' },
    { field: 'minLimit', header: 'Minimum value', width: '80px' },
    { field: 'equalityCondition', header: 'Equality Condition', width: '100px' },
    { field: 'maxLimit', header: 'Maximum value', width: '80px' },
    { field: 'propertynQuantity', header: 'Property n', width: '90px',  statement : 'Number of sample units chosen separately and independently' },
    { field: 'propertycQuantity', header: 'Property c', width: '90px' , statement : 'Maximum allowable number of results between m and M' },
    { field: 'propertymQuantity', header: 'Property m', width: '90px', statement : 'Level of the test organism which is acceptable and attainable in food' },
    { field: 'propertyMQuantity', header: 'Property M', width: '90px' , statement : 'The upper acceptable concentration of a test organism'},
    { field: 'propertyUoM', header: 'Property UOM', width: '80px' , statement : 'Unit of Measure'},
    { field: 'propertyMethodQuantity', header: 'Method', width: '90px' , statement : 'Test Method used'},
    { field: 'ruleClaimName', header: 'Claims', width: '80px' },
    { field: 'ruleSpecificConditionName', header: 'Conditional Requirements', width: '100px' },
    { field: 'warningText', header: 'Warnings', width: '100px' },
    { field: 'warningicon', header: '', width: '12px', filter: 'N' },
    { field: 'otherRestrictionsAndRequirements', header: 'Other Requirements and Restrictions', width: '150px' },
    { field: 'otherRestrictionsAndRequirementsicon', header: '', width: '12px', filter: 'N' },
    { field: 'substanceID', header: 'SIR ID', width: '100px' },
    { field: 'casNumber', header: 'CAS #', width: '100px' },
    { field: 'parentCASNumber', header: 'Parent CAS #', width: '100px' },
    { field: 'einecsNumber', header: 'EINECS#', width: '100px' },
    { field: 'eNumber', header: 'E #', width: '100px' },
    { field: 'insNumber', header: 'INS #', width: '100px' },
    { field: 'componentName', header: 'Component type	', width: '80px' },
    { field: 'preparationMethod', header: 'Preparation Method	', width: '100px' },
    { field: 'intendedUse', header: 'Intended Use', width: '100px' },
    { field: 'ruleCreatedByUserDisplayName', header: 'Created By', width: '100px' },
    { field: 'ruleCreatedDate', header: 'Created Date', width: '100px' },
    { field: 'ruleModifiedByUserDisplayName', header: 'Modified By', width: '100px' },
    { field: 'ruleModifiedDate', header: 'Modified Date', width: '100px' },
    { field: 'ruleSource', header: 'Source', width: '100px' },
    { field: 'validityDate', header: 'Validity', width: '100px' },
    { field: 'yearAdopted', header: 'Year Adopted', width: '100px' },
    { field: 'action', header: 'Action', width: '100px', filter: 'N' },
];

export const PRODUCT_VIEW_DETAILED_REPORT_COLUMNS = [
    { field: 'status', header: 'Status', width: '100px' },
    { field: 'commentForDisplay', header: 'Comments', width: '100px' },
    { field: 'ruleID', header: 'Rule ID', width: '100px' },
    { field: 'ruleVersion', header: 'Rule Version', width: '100px' },
    { field: 'market', header: 'Market', width: '100px' },
    { field: 'rpcPath', header: 'RPC Path', width: '100px' },
    { field: 'ruleRPC', header: 'Rule RPC', width: '100px' },
    { field: 'ruleType', header: 'Rule Type', width: '100px' },
    { field: 'propertySource', header: 'Property Source', width: '100px' },
    { field: 'property', header: 'Property', width: '100px' },
    { field: 'subProperty', header: 'Sub Property', width: '100px' },
    { field: 'pureCode', header: 'PURE Code', width: '100px' },
    { field: 'pureDescription', header: 'PURE Description', width: '100px' },
    { field: 'inci', header: 'INCI', width: '100px' },
    { field: 'function', header: 'Function', width: '100px' },
    { field: 'minValue', header: 'Minimum value', width: '80px' },
    { field: 'equalityCondition', header: 'Equality Condition', width: '100px' },
    { field: 'maxValue', header: 'Maximum value', width: '80px' },
    { field: 'uom', header: 'Unit of Measure', width: '80px' },
    { field: 'claims', header: 'Claims', width: '80px' },
    { field: 'ruleSpecificConditionName', header: 'Conditional Requirements', width: '100px' },
    { field: 'warningText', header: 'Warnings', width: '100px' },
    { field: 'warningicon', header: '', width: '12px', filter: 'N' },
    { field: 'otherRestrictionsAndRequirements', header: 'Other Requirements and Restrictions', width: '150px' },
    { field: 'otherRestrictionsAndRequirementsicon', header: '', width: '12px', filter: 'N' },
    { field: 'substanceID', header: 'SIR ID', width: '100px' },
    { field: 'casNumber', header: 'CAS #', width: '100px' },
    { field: 'parentCASNumber', header: 'Parent CAS #', width: '100px' },
    { field: 'einecsNumber', header: 'EINECS#', width: '100px' },
    { field: 'eNumber', header: 'E #', width: '100px' },
    { field: 'insNumber', header: 'INS #', width: '100px' },
    { field: 'componentName', header: 'Component type	', width: '80px' },
    { field: 'preparationMethod', header: 'Preparation Method	', width: '100px' },
    { field: 'intendedUse', header: 'Intended Use', width: '100px' },
    { field: 'ruleCreatedByUserDisplayName', header: 'Created By', width: '100px' },
    { field: 'ruleCreatedDate', header: 'Created Date', width: '100px' },
    { field: 'ruleModifiedByUserDisplayName', header: 'Modified By', width: '100px' },
    { field: 'ruleModifiedDate', header: 'Modified Date', width: '100px' },
    { field: 'source', header: 'Source', width: '100px' },
    { field: 'validityDate', header: 'Validity', width: '100px' },
    { field: 'warningText1', header: 'Warnings Link 1', width: 25, show: 'N' },
    { field: 'warningText2', header: 'Warnings Link 2', width: 25, show: 'N' },
    { field: 'warningText3', header: 'Warnings Link 3', width: 25, show: 'N' },
    { field: 'warningText4', header: 'Warnings Link 4', width: 25, show: 'N' },
    { field: 'warningText5', header: 'Warnings Link 5', width: 25, show: 'N' },
    { field: 'warningText6', header: 'Warnings Link 6', width: 25, show: 'N' },
    { field: 'warningText7', header: 'Warnings Link 7', width: 25, show: 'N' },
    { field: 'ORR1', header: 'Other Requirements and Restrictions Link 1', width: 45, show: 'N'  },
    { field: 'ORR2', header: 'Other Requirements and Restrictions Link 2', width: 45, show: 'N'  },
    { field: 'ORR3', header: 'Other Requirements and Restrictions Link 3', width: 45, show: 'N'  },
    { field: 'ORR4', header: 'Other Requirements and Restrictions Link 4', width: 45, show: 'N'  },
    { field: 'ORR5', header: 'Other Requirements and Restrictions Link 5', width: 45, show: 'N'  },
    { field: 'ORR6', header: 'Other Requirements and Restrictions Link 6', width: 45, show: 'N'  },
    { field: 'ORR7', header: 'Other Requirements and Restrictions Link 7', width: 45, show: 'N'  },
];
export const PRODUCT_VIEW_DETAILED_REPORT_COLUMNS_VIEW = [
    { field: 'status', header: 'Status', width: '100px' },
    { field: 'commentForDisplay', header: 'Comments', width: '100px' },
    { field: 'ruleID', header: 'Rule ID', width: '100px' },
    { field: 'ruleVersion', header: 'Rule Version', width: '100px' },
    { field: 'market', header: 'Market', width: '100px' },
    { field: 'rpcPath', header: 'RPC Path', width: '100px' },
    { field: 'ruleRPC', header: 'Rule RPC', width: '100px' },
    { field: 'ruleType', header: 'Rule Type', width: '100px' },
    { field: 'propertySource', header: 'Property Source', width: '100px' },
    { field: 'property', header: 'Property', width: '100px' },
    { field: 'subProperty', header: 'Sub Property', width: '100px' },
    { field: 'pureCode', header: 'PURE Code', width: '100px' },
    { field: 'pureDescription', header: 'PURE Description', width: '100px' },
    { field: 'inci', header: 'INCI', width: '100px' },
    { field: 'function', header: 'Function', width: '100px' },
    { field: 'minValue', header: 'Minimum value', width: '80px' },
    { field: 'equalityCondition', header: 'Equality Condition', width: '100px' },
    { field: 'maxValue', header: 'Maximum value', width: '80px' },
    { field: 'uom', header: 'Unit of Measure', width: '80px' },
    { field: 'claims', header: 'Claims', width: '80px' },
    { field: 'ruleSpecificConditionName', header: 'Conditional Requirements', width: '100px' },
    { field: 'warningText', header: 'Warnings', width: '100px' },
    { field: 'warningicon', header: '', width: '12px', filter: 'N' },
    { field: 'otherRestrictionsAndRequirements', header: 'Other Requirements and Restrictions', width: '150px' },
    { field: 'otherRestrictionsAndRequirementsicon', header: '', width: '12px', filter: 'N' },
    { field: 'substanceID', header: 'SIR ID', width: '100px' },
    { field: 'casNumber', header: 'CAS #', width: '100px' },
    { field: 'parentCASNumber', header: 'Parent CAS #', width: '100px' },
    { field: 'einecsNumber', header: 'EINECS#', width: '100px' },
    { field: 'eNumber', header: 'E #', width: '100px' },
    { field: 'insNumber', header: 'INS #', width: '100px' },
    { field: 'componentName', header: 'Component type	', width: '80px' },
    { field: 'preparationMethod', header: 'Preparation Method	', width: '100px' },
    { field: 'intendedUse', header: 'Intended Use', width: '100px' },
    { field: 'ruleCreatedByUserDisplayName', header: 'Created By', width: '100px' },
    { field: 'ruleCreatedDate', header: 'Created Date', width: '100px' },
    { field: 'ruleModifiedByUserDisplayName', header: 'Modified By', width: '100px' },
    { field: 'ruleModifiedDate', header: 'Modified Date', width: '100px' },
    { field: 'source', header: 'Source', width: '100px' },
    { field: 'validityDate', header: 'Validity', width: '100px' },
];
export const PRODUCT_VIEW_DETAILED_REPORT_COLUMNS_VIEW_EXCEL = [
    { field: 'status', header: 'Status', width: '100px' },
    { field: 'commentForDisplay', header: 'Comments', width: '100px' },
    { field: 'ruleID', header: 'Rule ID', width: '100px' },
    { field: 'ruleVersion', header: 'Rule Version', width: '100px' },
    { field: 'market', header: 'Market', width: '100px' },
    { field: 'rpcPath', header: 'RPC Path', width: '100px' },
    { field: 'ruleRPC', header: 'Rule RPC', width: '100px' },
    { field: 'ruleType', header: 'Rule Type', width: '100px' },
    { field: 'propertySource', header: 'Property Source', width: '100px' },
    { field: 'property', header: 'Property', width: '100px' },
    { field: 'subProperty', header: 'Sub Property', width: '100px' },
    { field: 'pureCode', header: 'PURE Code', width: '100px' },
    { field: 'pureDescription', header: 'PURE Description', width: '100px' },
    { field: 'inci', header: 'INCI', width: '100px' },
    { field: 'function', header: 'Function', width: '100px' },
    { field: 'minValue', header: 'Minimum value', width: '80px' },
    { field: 'equalityCondition', header: 'Equality Condition', width: '100px' },
    { field: 'maxValue', header: 'Maximum value', width: '80px' },
    { field: 'uom', header: 'Unit of Measure', width: '80px' },
    { field: 'claims', header: 'Claims', width: '80px' },
    { field: 'ruleSpecificConditionName', header: 'Conditional Requirements', width: '100px' },
    { field: 'warningText', header: 'Warnings', width: '100px' },
    { field: 'otherRestrictionsAndRequirements', header: 'Other Requirements and Restrictions', width: '150px' },
    { field: 'substanceID', header: 'SIR ID', width: '100px' },
    { field: 'casNumber', header: 'CAS #', width: '100px' },
    { field: 'parentCASNumber', header: 'Parent CAS #', width: '100px' },
    { field: 'einecsNumber', header: 'EINECS#', width: '100px' },
    { field: 'eNumber', header: 'E #', width: '100px' },
    { field: 'insNumber', header: 'INS #', width: '100px' },
    { field: 'componentName', header: 'Component type	', width: '80px' },
    { field: 'preparationMethod', header: 'Preparation Method	', width: '100px' },
    { field: 'intendedUse', header: 'Intended Use', width: '100px' },
    // { field: 'propertyTypicalQuantity', header: 'Property Typical', width: '150px' },
    // { field: 'propertyMinQuantity', header: 'Property Min', width: '100px' },
    // { field: 'propertyMaxQuantity', header: 'Property Max', width: '100px' },
    // { field: 'propertyTargetQuantity', header: 'Property Target', width: '150px' },
    // { field: 'propertynQuantity', header: 'Property n', width: '90px' },
    // { field: 'propertycQuantity', header: 'Property c', width: '90px' },
    // { field: 'propertymQuantity', header: 'Property m', width: '90px' },
    // { field: 'propertyMQuantity', header: 'Property M', width: '90px' },
    // { field: 'propertyUoM', header: 'Property UoM', width: '100px' },
    // { field: 'propertyMethodQuantity', header: 'Property Method', width: '90px' },
    { field: 'ruleCreatedByUserDisplayName', header: 'Created By', width: '100px' },
    { field: 'ruleCreatedDate', header: 'Created Date', width: '100px' },
    { field: 'ruleModifiedByUserDisplayName', header: 'Modified By', width: '100px' },
    { field: 'ruleModifiedDate', header: 'Modified Date', width: '100px' },
    { field: 'source', header: 'Source', width: '100px' },
    { field: 'validityDate', header: 'Validity', width: '100px' },
];
export const SIR_SHOW_MORE_COLUMNS = [
    { field: 'hazardClass', header: 'Hazard Class', width: '129px' },
    { field: 'hazardCategory', header: 'Hazard Category', width: '130px' },
    { field: 'hStatement', header: 'H Statement', width: '120px' },
    { field: 'usage', header: 'Usage', width: '120px' },
    { field: 'concentrationLimit', header: 'Cut-off/Concentration limit', width: '150px' },
    { field: 'routeOfExp', header: 'Route Of Exposure', width: '155px' },
    { field: 'effectOn', header: 'Effect On', width: '155px' },
];


export const HAZARD_CLASSIFICATION = [
    { field: 'ghsClass', header: 'Hazard Classification', width: '120px' },
    { field: 'ghsCategory', header: 'Hazard Category', width: '100px' },
    { field: 'ghsRouteOfExp', header: 'Route of Exposure', width: '100px' },
    { field: 'effectOn', header: 'Effect On', width: '80px' },
    { field: 'hStatement', header: 'H Statement', width: '80px' },
    { field: 'targetOrgan', header: 'Target Organ', width: '80px' },
    { field: 'justification', header: 'Justification', width: '80px' },
    { field: 'ghsClassShort', header: 'GHS Class Short', width: '100px' },
    { field: 'ghsCategoryShort', header: 'GHS Category Short', width: '100px' },
    { field: 'usage', header: 'Usage', width: '80px' },
];

// export const SERVING_DETAILS_COLUMNS = [
//     { field: 'marketName', header: 'Market', width: '130px'},
//     { field: 'dosage', header: 'Dosage', width: '130px' },
//     { field: 'servingSize', header: 'Serving', width: '90px' },
//     { field: 'dosageUnit', header: 'UoM', width: '90px' },
// ];
export const SERVING_DETAILS_COLUMNS = [
    { field: 'marketName', header: 'Market', width: '130px'},
    { field: 'servingSize', header: 'Serving', width: '90px' },
    { field: 'dosageUnit', header: 'UoM', width: '90px' },
];
export const DOSAGE_DETAILS_COLUMNS = [
    { field: 'marketName', header: 'Market', width: '130px'},
    { field: 'dosage', header: 'Dosage', width: '130px' },
    { field: 'dosageUnit', header: 'UoM', width: '90px' },
];
