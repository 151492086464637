import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as _ from 'lodash';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { groupRulesDetailsModel } from 'src/app/common/models/GroupRulesDetailsModel';
import { pureRulesDetailsModel, ruleFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { RuletypesAttributeMapping } from 'src/app/common/models/RuletypesAttributeMapping';
import { DataService } from 'src/app/common/services/data.service';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-max-group-rule-template',
    templateUrl: './max-group-rule-template.component.html',
    styleUrls: ['./max-group-rule-template.component.scss']
})
export class MaxGroupRuleTemplateComponent implements OnInit, AfterViewInit {
    @ViewChild('groupMaxRuleForm') groupMaxRuleForm: NgForm;
    @ViewChild('groupCombinationRuleForm') groupCombinationRuleForm: NgForm;
    name = 'Max Group Ruke. ';
    editor = ClassicEditor;
    data: any = ''; selectedRuleLevel = '';
    activeTabGR = 'rule-detail'; activeTab = 'substance-list';
    ruleTypeId; ruleDisplayName; ruleDisplayNameForIndividual; ruleDisplayIdForIndividual;
    selectedRuleDetails = []; allFns = []; allowedFns = [];
    loading = false; ruleLevelClicked = false; combinationRuleChecked = false; exemptedRuleChecked = false;
    groupDetails; groupDetailsInitial;
    subGroupDetails = []; ruleLevels = []; limitTypes = []; selectedRuleLevelArray = [];
    maxEqualityCondition = [{ value: '<' }, { value: '<=' }]; minEqualityCondition = [{ value: '>' }, { value: '>=' }];
    equalityCondition = [{ value: 'isEqual' }];
    readOnlyValue = true; attBtnClicked = false;
    ankerCount: any;
    selectedRuleTypeDetails = []; allRulesforRT = []; uomList = []; ruleSpecificAttributes = [];
    selectedFunction; selectedUoM; selectedEqualityCondition; ruleDetails; substanceIndex;
    indAtrBtnClicked; saveAndAddNewDialog; ruleAdded;
    applicableGroupRuleTypesToDisplay; ruleTypeRadioSelected; rtRadioSelected;
    isAllCfSelected: boolean; ruleComment: string;
    rpc = []; rulesListForGroup = []; combRuleSpecificAttributes = [];
    selectCombinationLimitClicked: boolean; selectCombinationTab = false;
    selectedLimitType; combSubGroupDetails; commentsToUser = ''; marketForModal = '';
    combAttBtnClicked = false; isGroupLimitSet = false; isSaveButtonEnable = false;
    saveAndAddNewBtnClicked = false; saveAndCloseClicked = false; rangeValidationFailedGroup = false;
    rangeValidationFailedInd = false; rangeValidationFailedComb = false; cancelClicked = false;
    ruleSource = ''; validityStartDate: any = ''; validityEndDate: any = ''; yearAdopted: any; ruleDescription = '';
    uomGroupRuleValidation; uomIndRuleValidation; uomCombRuleValidation; combinationTabOpened;
    limitTabOpened = false; parallelRuleWorkflow = false; isGroupDosageDisabled = true; isGroupServingSizeDisabled = true;
    groupDosageFlag = false; groupServingSizeFlag = false; editRuleFlow = false; viewRuleFlow = false;
    editRuleData; ruleIdOnEdit; ruleVersion;
    targetProducts = []; sourceProducts = []; switchedSirDetails = []; selectedSirDetails = []; switchedPureCodes = [];
    notifyGPCDialog = false; ruleSaveConformationPopup = false; showRelatedRulesPopup = false; viewRelbtn = false; showEmailConfirmationDialog = false;
    sentPureCodes; loadingMsg; selectedRows;
    prepareRuleTableData = []; myWorklistTableColumns = []; selectedRowsRelated = [];
    combRuleTypeId = null; combRuleVersion = null; combinationRuleType = null;
    defaultValidityFromDate; defaultValidityEndDate; todayDate;
    warningTextOpen: boolean;
    tablewarningComment: any;
    orrTextOpen: boolean;
    tableORRComment: any;
    config = {
        toolbar: [
            'bold',
            'italic',
            'underline',
            '|',
            'bulletedList',
            'numberedList',
            // '|',
            'Link'
        ],
        image: {
            toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side']
        },
        link: {
            addTargetToExternalLinks: true,
            decorators: [
                {
                    mode: '',
                    label: '',
                    attributes: {
                        target: '_blank',
                    }
                }
            ]
        }
    };
    constructor(
        private assessReqRAdataService: AssessRequestRaService, public snackBarGroup: MatSnackBar, public formBuilder: FormBuilder,
        private worklistservice: WorklistService, private datePipe: DatePipe, public dataSvcObj: DataService, private route: Router) { }

    ngAfterViewInit(): void {
        this.disableCheckbox();
        setTimeout(() => {
            this.disableCheckbox();
        }, 3005);
    }
    disableCheckbox() {
        if (this.dataSvcObj.isfromSubstancesearch) {
            const picklistSource = document.getElementsByClassName('ui-picklist-source-wrapper').item(0) as HTMLElement;
            picklistSource.style.display = 'none';
            const picklistButton = document.getElementsByClassName('ui-picklist-buttons').item(0) as HTMLElement;
            picklistButton.style.display = 'none';
            const picklistTarget = document.getElementsByClassName('ui-picklist-target-wrapper').item(0) as HTMLElement;
            picklistTarget.style.cssFloat = 'left';
            const dosageclass = document.getElementsByClassName('dosageclass').item(0) as HTMLElement;
            if (!this.groupDosageFlag) {
                dosageclass.style.visibility = 'hidden';
            } else {
                dosageclass.style.visibility = 'initial';
            }
            const servinlass = document.getElementsByClassName('servinlass').item(0) as HTMLElement;
            if (!this.groupServingSizeFlag) {
                servinlass.style.display = 'none';
            } else {
                servinlass.style.display = 'initial';
            }
        }
    }
    /* istanbul ignore next */
    ngOnInit() {
        this.todayDate = new Date();
        this.defaultValidityFromDate = this.datePipe.transform(this.todayDate, 'longDate');
        this.defaultValidityEndDate = this.datePipe.transform(new Date('9999-12-31'), 'longDate');
        this.editRuleFlow = false;
        this.viewRuleFlow = false;
        this.dataSvcObj.getruleToEdit().subscribe((value) => {
            if (value !== null) {
                this.editRuleFlow = true;
                this.viewRelbtn = false;
                this.marketForModal = recipeModel.marketDetails.marketName;
                this.editRuleData = value;
                this.ruleSource = value.ruleSource;
                if (ruleFlags.viewRule) {
                    this.defaultValidityFromDate = value.validityStartDate ? (this.datePipe.transform(new Date(this.datePipe.transform(value.validityStartDate, 'yyyy-MM-dd')), 'longDate')) : '';
                    this.defaultValidityEndDate = value.validityDate ? (this.datePipe.transform(new Date(this.datePipe.transform(value.validityDate, 'yyyy-MM-dd')), 'longDate')) : '';
                }
                this.yearAdopted = value.yearAdopted ? (new Date(value.yearAdopted)).toUTCString() : '';
                this.ruleIdOnEdit = value.ruleId;
                this.ruleVersion = value.ruleVersion;
                this.ruleTypeId = value.ruleTypeID;
                pureRulesDetailsModel.Market = value.markets[0];
                const rpcVals = [];
                value.rpcs.forEach((val) => {
                    const data = {
                        id: val.rpcId,
                        text: val.rpcName,
                        rpcFullText: val.rpcFullText
                    };
                    rpcVals.push(data);
                });
                pureRulesDetailsModel.selectedParentRpcs = rpcVals;
                pureRulesDetailsModel.selectedRpcs = rpcVals;
                recipeModel.preparationMethod = value.preparationMethod;
                recipeModel.intendedUse = value.intendedUse;
                this.getWorklistColumns();
            } else {
                this.editRuleFlow = false;
            }
        });
        if (this.activeTabGR === 'rule-detail') {
            document.getElementById('description').style.display = 'none';
        }
        // set group data on group selection change
        this.dataSvcObj.getGroupData.subscribe((groupData: any) => {
            if (groupData) {
                this.groupDetailsInitial = groupData;
                if (this.editRuleFlow || this.viewRuleFlow) {
                    this.groupDetails = Object.assign({}, this.groupDetailsInitial);
                    const data = groupData.substanceData;
                    this.sourceProducts = [];
                    this.targetProducts = [];
                    for (let i = 0; i < data.length; i++) {
                        for (let j = 0; j < this.editRuleData.substanceSubgroupsToSirList.length; j++) {
                            if (data[i].sirId === this.editRuleData.substanceSubgroupsToSirList[j].substanceId) {
                                const val = data[i].cfDetails.filter(x => x.cf === this.editRuleData.substanceSubgroupsToSirList[j].contributionFactor)[0];
                                data[i].ContributionFactor = val.cfDisplayText;
                                data[i].selectedCF = val;
                                data[i].formattedCF = 'CF  ' + data[i].selectedCF.cf;
                                data[i].selectedExemptionValue = this.editRuleData.substanceSubgroupsToSirList[j].isExempted === 'Y' ? 'true' : 'false';
                                if (this.editRuleData.substanceSubgroupsToSirList[j].isExempted === 'Y') {
                                    this.exemptedRuleChecked = true;
                                }
                                this.targetProducts.push(data[i]);
                            }

                        }
                    }
                    if (this.editRuleData.combinationSubstanceSubgroupsToSirList && this.editRuleData.combinationSubstanceSubgroupsToSirList.length > 0) {
                        this.combinationRuleChecked = true;
                        this.combRuleTypeId = this.editRuleData.combinationRuleId;
                        this.combRuleVersion = this.editRuleData.combinationRuleVersion;
                        if (this.ruleTypeId === 21) {
                            this.combinationRuleType = 'Combination Rules- group limit - max';
                        } else if (this.ruleTypeId === 22) {
                            this.combinationRuleType = 'Combination Rules- group limit - min';
                        } else if (this.ruleTypeId === 23) {
                            this.combinationRuleType = 'Combination Rules- group limit - range';
                        }
                        this.combinationTabOpened = true;
                        this.attBtnClicked = false;
                        for (let i = 0; i < this.targetProducts.length; i++) {
                            for (let j = 0; j < this.editRuleData.combinationSubstanceSubgroupsToSirList.length; j++) {
                                if (this.targetProducts[i].sirId === this.editRuleData.combinationSubstanceSubgroupsToSirList[j].substanceId) {
                                    this.targetProducts[i].combinationChecked = true;
                                }
                            }
                        }
                    }
                    this.isAllCfSelected = true;
                    this.isGroupLimitSet = true;
                    this.sourceProducts = _.xor(data, this.targetProducts);
                    this.subGroupDetails = this.targetProducts;
                } else {
                    this.refreshComponant();
                    this.groupDetailsInitial = groupData;
                    this.groupDetails = Object.assign({}, this.groupDetailsInitial);
                }
            }
        });
        // get selected rule type change
        this.dataSvcObj.getSelectedGroupRuleType.subscribe((value) => {
            if (value.length !== 0) {
                this.limitTypes = [{ name: 'Set combination limit', isChecked: false }];
                this.ruleLevels = [{ name: 'Individual Level', isChecked: false }, { name: 'Group Level', isChecked: false }];
                this.selectCombinationTab = true;
                this.selectedRuleTypeDetails[0] = value;
                this.limitTabOpened = false;
                this.ruleTypeId = this.selectedRuleTypeDetails[0].ruleTypeId;
                this.ruleDisplayName = this.selectedRuleTypeDetails[0].ruleTypeDisplayName;
                this.ruleDisplayNameForIndividual = this.ruleDisplayName.replace('- group', '- individual');
                if (this.ruleTypeId !== 38 || this.ruleTypeId !== 37 || this.ruleTypeId !== 39) {
                    this.ruleDisplayIdForIndividual = (this.ruleTypeId === 21) ? 34 : (this.ruleTypeId === 22) ? 35 : 36;
                }
                this.activeTabGR = 'rule-detail';
                this.activeTab = 'substance-list';
                if (!this.editRuleFlow && !this.viewRuleFlow) {
                    this.refreshComponant();
                    this.ruleSource = '';
                    this.validityStartDate = '';
                    this.validityEndDate = '';
                    this.yearAdopted = '';
                    this.exemptedRuleChecked = false;
                    this.combinationRuleChecked = false;
                    this.combRuleSpecificAttributes = [];
                }
                document.getElementById('rule-detail').style.display = 'block';
                document.getElementById('description').style.display = 'none';
                this.ruleDescription = this.selectedRuleTypeDetails[0].ruleTypeDescription ? this.selectedRuleTypeDetails[0].ruleTypeDescription : null;

                this.getGroupDetail();
                this.getUomList();
                this.getFnList();
                this.getRuleSpecificAttributeList();
                this.combgroupAttributeDetails();
                this.groupDetails = this.groupDetailsInitial;
                this.selectedRuleLevelArray = [];
                this.selectedRuleLevel = '';
                this.switchedPureCodes = [];
                this.switchedSirDetails = [];
                this.selectedSirDetails = [];
            }
        });
        if (this.editRuleFlow || this.viewRuleFlow) {
            setTimeout(() => {
                this.getEditGroupRuleDetails(this.editRuleData);
            }, 3000);
        }
        if (ruleFlags.viewRule) {
            this.editRuleFlow = false;
            this.viewRuleFlow = true;
        } else if (ruleFlags.editRule) {
            this.editRuleFlow = true;
            this.viewRuleFlow = false;
        }
    }

    getGroupDetail() {
        this.dataSvcObj.getGroupData.subscribe((groupData) => {
            this.groupDetailsInitial = groupData;
            this.groupDetails = Object.assign({}, this.groupDetailsInitial);
        });
    }
    onTabChange(activeTab) {
        this.activeTabGR = activeTab;
        if (activeTab === 'rule-detail') {
            document.getElementById('rule-detail').style.display = 'block';
            document.getElementById('description').style.display = 'none';
            document.getElementById('ruleValidity').style.display = 'none';
        } else if (activeTab === 'description') {
            document.getElementById('rule-detail').style.display = 'none';
            document.getElementById('description').style.display = 'block';
            document.getElementById('ruleValidity').style.display = 'none';
        } else if (activeTab === 'ruleValidity') {
            document.getElementById('rule-detail').style.display = 'none';
            document.getElementById('description').style.display = 'none';
            document.getElementById('ruleValidity').style.display = 'block';
        }
        setTimeout(() => {
            this.disableCheckbox();
        }, 10);
    }
    /* istanbul ignore next */
    onInnerTabChange(activeTab) {
        this.attBtnClicked = false;
        if (!this.limitTabOpened) {
            this.subgroupAttributeDetails();
            this.limitTabOpened = true;
        }
        if (activeTab === 'set-limit' && !this.editRuleFlow && !this.viewRuleFlow) {
            this.subGroupDetails.forEach((subgroup) => {
                subgroup.selectedCF = subgroup.cfDetails.filter(x => x.cfDisplayText === subgroup.ContributionFactor)[0];
                if (subgroup.selectedCF) {
                    subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                } else {
                    subgroup.ContributionFactor = subgroup.cfDetails[0].cfDisplayText;
                    subgroup.selectedCF = subgroup.cfDetails[0];
                    subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                }
                if (!subgroup.ruleDeatilsIndividual) {
                    const invList = RuletypesAttributeMapping.filter(obj => obj.ruleBucket === 'Group Based' && obj.ruleTypeId === this.ruleDisplayIdForIndividual)[0];
                    subgroup.ruleDeatilsIndividual = Object.assign({}, invList);
                    const invListAttr = subgroup.ruleDeatilsIndividual.attributeList;
                    subgroup.ruleSpecificAttributesForIndividual = invListAttr.map(x => Object.assign({}, x));
                    subgroup.ruleSpecificAttributesForIndividual.filter(x => x.attributeTemplate === 'When').forEach(attr => {
                        if (attr.attributeName === 'Function') {
                            attr.valuelist = this.allFns;
                        }
                    });
                }
            });

            this.isAllCfSelected = true;
            this.isGroupLimitSet = true;
        }
        if (activeTab === 'combination-rule') {
            this.combinationTabOpened = true;
        }
        if (activeTab === 'contribution-factor' && (this.editRuleFlow || this.viewRuleFlow)) {
            this.targetProducts.forEach((subgroup) => {
                if (!subgroup.ContributionFactor) {
                    if (subgroup.cfDetails && subgroup.cfDetails.length > 0) {
                        subgroup.ContributionFactor = subgroup.cfDetails[0].cfDisplayText;
                    }
                    subgroup.selectedCF = subgroup.cfDetails.filter(x => x.cfDisplayText === subgroup.ContributionFactor)[0];
                    if (subgroup.selectedCF) {
                        subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                    }
                }
                if (!subgroup.ruleDeatilsIndividual) {
                    const invList = RuletypesAttributeMapping.filter(obj => obj.ruleBucket === 'Group Based' && obj.ruleTypeId === this.ruleDisplayIdForIndividual)[0];
                    subgroup.ruleDeatilsIndividual = Object.assign({}, invList);
                    const invListAttr = subgroup.ruleDeatilsIndividual.attributeList;
                    subgroup.ruleSpecificAttributesForIndividual = invListAttr.map(x => Object.assign({}, x));
                    subgroup.ruleSpecificAttributesForIndividual.filter(x => x.attributeTemplate === 'When').forEach(attr => {
                        if (attr.attributeName === 'Function') {
                            attr.valuelist = this.allFns;
                        }
                    });
                }
                this.subGroupDetails = this.targetProducts;
            });
            this.isAllCfSelected = true;
        }
        if (activeTab === 'contribution-factor' && !this.editRuleFlow && !this.viewRuleFlow) {

            this.subGroupDetails.forEach((subgroup) => {
                subgroup.selectedCF = subgroup.cfDetails.filter(x => x.cfDisplayText === subgroup.ContributionFactor)[0];
                if (subgroup.selectedCF) {
                    subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                } else {
                    subgroup.ContributionFactor = subgroup.cfDetails[0].cfDisplayText;
                    subgroup.selectedCF = subgroup.cfDetails[0];
                    subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                }
            });
            this.isAllCfSelected = true;
        }
        if (activeTab === 'set-limit' && (this.editRuleFlow || this.viewRuleFlow)) {
            this.targetProducts.forEach(subgroup => {
                subgroup.selectedCF = subgroup.cfDetails.filter(x => x.cfDisplayText === subgroup.ContributionFactor)[0];
                if (subgroup.selectedCF) {
                    subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                } else {
                    subgroup.selectedCF = subgroup.cfDetails[0];
                    subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                }
                if (!subgroup.ruleDeatilsIndividual) {
                    const invList = RuletypesAttributeMapping.filter(obj => obj.ruleBucket === 'Group Based' && obj.ruleTypeId === this.ruleDisplayIdForIndividual)[0];
                    subgroup.ruleDeatilsIndividual = Object.assign({}, invList);
                    const invListAttr = subgroup.ruleDeatilsIndividual.attributeList;
                    subgroup.ruleSpecificAttributesForIndividual = invListAttr.map(x => Object.assign({}, x));
                    subgroup.ruleSpecificAttributesForIndividual.filter(x => x.attributeTemplate === 'When').forEach(attr => {
                        if (attr.attributeName === 'Function') {
                            attr.valuelist = this.allFns;
                        }
                    });
                }
            });
            this.subGroupDetails = JSON.parse(JSON.stringify(this.targetProducts));
            //  this.subGroupDetails = this.targetProducts;
        }
        this.activeTab = activeTab;
    }
    // remove group ruletype attribute
    removeRTAttribute(value, index: number) {
        value.attributeValue = null;
        value.Uom = [];
        this.ruleSpecificAttributes.filter(x => x.attributeName === value.attributeName)[0].isRequired = false;
    }
    // remove individual ruletype attribute
    removeIndRTAttribute(subGrp, value, index: number) {
        value.attributeValue = null;
        value.Uom = [];
        subGrp.ruleSpecificAttributesForIndividual.filter(x => x.attributeName === value.attributeName)[0].isRequired = false;
    }
    // remove combination ruletype attribute
    removeCombRTAttribute(value, index: number) {
        value.combUomValue = [];
        value.combAttributeValue = null;
        this.combRuleSpecificAttributes.filter(x => x.attributeName === value.attributeName)[0].isRequired = false;
    }
    // clear group rule attribute data on row delete
    removeRTAttributeHide(value, index: number) {
        value.attributeValue = null;
        value.combAttributeValue = null;
        value.Uom = [];
        value.combUomValue = [];
    }
    // clear individual rule attribute data on row delete
    removeRTIndAttributeHide(value, index: number) {
        value.attributeValue = null;
        value.Uom = [];
    }
    // clear combination rule attribute data on row delete
    removeCombRTAttributeHide(value, index: number) {
        value.combAttributeValue = null;
        value.combUomValue = [];
    }
    // get uom list
    getUomList() {
        const url = environment.APIFAsT + '/uoms';
        this.assessReqRAdataService.getData(url).subscribe((response) => {
            if (response !== null) {
                this.uomList = response;
            }
        });
    }
    // get function list
    getFnList() {
        const functionApiUrl = environment.APIFAsT + '/functions';
        this.assessReqRAdataService.getData(functionApiUrl).subscribe((response) => {
            this.allFns = response;
            const attr = this.ruleSpecificAttributes.filter(x => x.attributeName === 'Function')[0];
            if (attr) {
                attr.valuelist = this.allFns;
            }
            this.combRuleSpecificAttributes = this.ruleSpecificAttributes.map(x => Object.assign({}, x));
            this.combRuleSpecificAttributes.filter(x => x.attributeTemplate === 'When').forEach(combAttr => {
                if (combAttr.attributeName === 'Function') {
                    combAttr.valuelist = this.allFns;
                }
            });
        });
    }
    onSaveAndClose() {
        this.saveAndCloseClicked = true;
        this.onSave();
    }
    onSaveandAddNew() {
        this.saveAndAddNewBtnClicked = true;
        this.onSave();
    }
    // save rule
    /* istanbul ignore next */
    onSave() {
        let maxValue;
        let subGroupMaxValue;
        let minValue;
        let subGroupMinValue;
        let uomValue;
        let subGroupUomValue;
        let fnValue;
        let subGroupFnValue;
        let warningText;
        let subGroupWarningText;
        let otherText;
        let subGroupOtherText;
        let ruleTypeId;
        let ruleType;
        let equalityCondition;
        let subGroupEqualityCondition;
        let maxEqualityGroupValue; let minEqualityGroupValue;
        let maxEqualitySubGroupValue; let minEqualitySubGroupValue;
        let maxEqualityCombGroupValue; let minEqualityCombGroupValue;
        let spConditionText; let claimsText;
        const rpcData = [];
        let savedData = [];
        const subgroupsubstancelist = [];
        const mktList = [{ marketId: pureRulesDetailsModel.Market.marketId, marketName: pureRulesDetailsModel.Market.marketName }];
        ruleTypeId = this.ruleTypeId;
        ruleType = this.ruleDetails.ruleType;
        if (groupRulesDetailsModel.CascadeRpc === 'Y') {
            pureRulesDetailsModel.selectedParentRpcs.forEach((value) => {
                const data = {
                    rpcFullText: value.rpcFullText,
                    rpcName: value.text,
                    rpcId: value.id
                };
                rpcData.push(data);
            });
        } else {
            pureRulesDetailsModel.selectedRpcs.forEach((value) => {
                const data = {
                    rpcFullText: value.rpcFullText,
                    rpcName: value.text,
                    rpcId: value.id
                };
                rpcData.push(data);
            });
        }
        this.ruleSpecificAttributes.forEach((ruleattr) => {
            if (ruleattr.attributeName === 'Group Max') {
                maxValue = ruleattr.attributeValue;
                uomValue = ruleattr.Uom;
                maxEqualityGroupValue = ruleattr.equalityCondition === undefined && maxValue ? { value: '<' } : ruleattr.equalityCondition;
                if (maxEqualityGroupValue) {
                    equalityCondition = maxEqualityGroupValue.value !== undefined ? maxEqualityGroupValue.value : '';
                }
            }
            if (ruleattr.attributeName === 'Group Min') {
                minValue = ruleattr.attributeValue;
                uomValue = ruleattr.Uom;
                equalityCondition = ruleattr.equalityCondition;
                minEqualityGroupValue = ruleattr.equalityCondition === undefined && minValue ? { value: '>' } : ruleattr.equalityCondition;
                if (minEqualityGroupValue) {
                    equalityCondition = minEqualityGroupValue.value !== undefined ? minEqualityGroupValue.value : '';
                }
            }
            if (ruleattr.attributeName === 'Function') {
                fnValue = ruleattr.attributeValue;
            }
            if (ruleattr.attributeName === 'Warning') {
                warningText = ruleattr.attributeValue;
            }
            if (ruleattr.attributeName === 'Other Requirements & Restrictions') {
                otherText = ruleattr.attributeValue;
            }
            if (ruleattr.attributeName === 'Conditional Requirements') {
                spConditionText = ruleattr.attributeValue;
            }
            if (ruleattr.attributeName === 'Claims') {
                claimsText = ruleattr.attributeValue;
            }
        });
        if (this.ruleTypeId === 23 && (minValue !== undefined && maxValue !== undefined)) {  // for Range Rule
            equalityCondition = minEqualityGroupValue.value + ';' + maxEqualityGroupValue.value;
        }
        if (minValue === undefined && maxValue === undefined) {
            equalityCondition = '';
        }
        if (this.selectedRuleLevel.indexOf('Individual') > -1) {
            this.subGroupDetails.forEach(subgroup => {
                subgroup.ruleSpecificAttributesForIndividual.forEach((subgroupattr) => {
                    if (subgroupattr.attributeName === 'Max') {
                        subGroupMaxValue = subgroupattr.attributeValue;
                        subGroupUomValue = subgroupattr.Uom;
                        maxEqualitySubGroupValue = subgroupattr.equalityCondition === undefined && subGroupMaxValue ? { value: '<' } : subgroupattr.equalityCondition;
                        if (maxEqualitySubGroupValue) {
                            subGroupEqualityCondition = maxEqualitySubGroupValue.value !== undefined ? maxEqualitySubGroupValue.value : '';
                        }
                    }
                    if (subgroupattr.attributeName === 'Min') {
                        subGroupMinValue = subgroupattr.attributeValue;
                        subGroupUomValue = subgroupattr.Uom;
                        minEqualitySubGroupValue = subgroupattr.equalityCondition === undefined && subGroupMinValue ? { value: '>' } : subgroupattr.equalityCondition;
                        if (minEqualitySubGroupValue) {
                            subGroupEqualityCondition = minEqualitySubGroupValue.value !== undefined ? minEqualitySubGroupValue.value : '';
                        }
                    }
                    if (subgroupattr.attributeName === 'Function') {
                        subGroupFnValue = subgroupattr.attributeValue;
                    }
                    if (subgroupattr.attributeName === 'Warning') {
                        subGroupWarningText = subgroupattr.attributeValue;
                    }
                    if (subgroupattr.attributeName === 'Other Requirements & Restrictions') {
                        subGroupOtherText = subgroupattr.attributeValue;
                    }
                });
                if (this.ruleTypeId === 23 && (subGroupMinValue && subGroupMaxValue)) {  // for Range Rule
                    subGroupEqualityCondition = minEqualitySubGroupValue.value + ';' + maxEqualitySubGroupValue.value;
                }
                if (subGroupMinValue === undefined && subGroupMaxValue === undefined) {
                    subGroupEqualityCondition = '';
                }
                subgroupsubstancelist.push({
                    SubstanceId: subgroup.sirId,
                    ComponentName: subgroup.selectedCF.component,
                    ContributionFactor: subgroup.selectedCF.cf,
                    NumberOfExpressedAs: subgroup.selectedCF.numberOfExpressedAs,
                    SubComponentMolWeight: subgroup.selectedCF.componentMolWeight,
                    MaxLimit: subGroupMaxValue,
                    MinLimit: subGroupMinValue,
                    UoMID: subGroupUomValue ? subGroupUomValue.uoMid ? subGroupUomValue.uoMid : 2 : 2,
                    UoM: subGroupUomValue ? subGroupUomValue.uoMname ? subGroupUomValue.uoMname : 'N/A' : 'N/A',
                    // tslint:disable-next-line: triple-equals
                    IsExempted: subgroup.selectedExemptionValue == 'true' && this.exemptedRuleChecked ? 'Y' : 'N',
                    Function: subGroupFnValue ? subGroupFnValue : null,
                    NegativeStatement: subGroupWarningText ? subGroupWarningText : '',
                    AdditionalStatement: subGroupOtherText ? subGroupOtherText : '',
                    EqualityCondition: subGroupEqualityCondition,
                });
            });
        } else {
            this.subGroupDetails.forEach(subgroup => {
                subgroupsubstancelist.push({
                    SubstanceId: subgroup.sirId,
                    ComponentName: subgroup.selectedCF.component,
                    ContributionFactor: subgroup.selectedCF.cf,
                    NumberOfExpressedAs: subgroup.selectedCF.numberOfExpressedAs,
                    SubComponentMolWeight: subgroup.selectedCF.componentMolWeight,
                    // tslint:disable-next-line: triple-equals
                    IsExempted: subgroup.selectedExemptionValue == 'true' && this.exemptedRuleChecked ? 'Y' : 'N',
                });
            });
        }
        savedData = [{
            RuleId: this.ruleIdOnEdit ? this.ruleIdOnEdit : 0,
            RuleVersion: this.ruleVersion ? this.ruleVersion : 0,
            RPCs: rpcData,
            MarketID: Number(pureRulesDetailsModel.Market.marketId),
            Market: pureRulesDetailsModel.Market.marketName,
            Markets: mktList,
            RuleTypeID: ruleTypeId,
            RuleType: ruleType,
            CascadeMarket: 'N',
            CascadeRpc: 'Y',
            // passing NA uom when uom is not there
            UoMID: uomValue ? uomValue.uoMid ? uomValue.uoMid : 2 : 2,
            UoM: uomValue ? uomValue.uoMname ? uomValue.uoMname : 'N/A' : 'N/A',
            TexualMinLimitText: '',
            TexualMaxLimitText: '',
            RuleDescription: '',
            RuleSource: this.ruleSource ? this.ruleSource : '',
            RuleSourceType: '',
            AdditionalStatement: otherText ? otherText : '', // other requirements and restrictions
            PositiveStatement: '',
            MaxLimit: maxValue ? maxValue : '',
            MinLimit: minValue ? minValue : '',
            RuleClaim: '',
            RuleSpecificCondions: '',
            Claims: claimsText ? [{ ClaimText: claimsText ? claimsText : '' }] : null,
            SpeficicConditions: spConditionText ? [{ SpecificConditionText: spConditionText ? spConditionText : '' }] : null,
            EqualityCondition: equalityCondition,
            GroupFunction: fnValue ? fnValue : null,
            PreparationMethod: recipeModel.preparationMethod,
            IntendedUse: recipeModel.intendedUse,
            FunctionList: null, // used only for allowed function array of objects
            NegativeStatement: warningText ? warningText : '',
            IsMandatorySubstance: null, // Y for substance mandatory rule
            WarningText: null, // used for warning rule, adulterated rule , phased out rule, other requirements and restrictions, Product Warnings
            SubstanceGroup: this.groupDetails,
            SubstanceSubgroupsToSirList: subgroupsubstancelist,
            yearAdopted: this.yearAdopted ? this.datePipe.transform(this.yearAdopted, 'yyyy-MM-dd') : null,
            ValidityStartDate: this.validityStartDate ? this.datePipe.transform(this.validityStartDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityFromDate, 'yyyy-MM-dd'),
            ValidityDate: this.validityEndDate ? this.datePipe.transform(this.validityEndDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityEndDate, 'yyyy-MM-dd'),
            IsDosageApplicable: this.groupDosageFlag ? 'Y' : 'N',
            IsServingSizeApplicable: this.groupServingSizeFlag ? 'Y' : 'N',
            Comments: this.ruleComment ? this.ruleComment : null,
            RuleFlow: this.parallelRuleWorkflow === true ? 'Parallel' : 'Request'
        }];
        this.loading = true;
        this.loadingMsg = 'Saving Rule';
        // combination rule data
        if (this.combinationRuleChecked) {
            let combMaxValue;
            let combMinValue;
            let combUomValue;
            let combFnValue;
            let combWarningText;
            let combOtherText;
            let combEqualityCondition;
            let combSpecialConditionText;
            let combClaimText;
            // rule details for combination rule
            let combRuleTypeId;
            let combRuleType;
            if (this.editRuleFlow) {
                combRuleTypeId = this.ruleTypeId;
                combRuleType = this.ruleDisplayName;
            }
            if (ruleTypeId === 21) {
                combRuleTypeId = 37;
                combRuleType = 'Combination Rules- group limit - max';
            } else
                if (ruleTypeId === 22) {
                    combRuleTypeId = 38;
                    combRuleType = 'Combination Rules- group limit - min';
                } else
                    if (ruleTypeId === 23) {
                        combRuleTypeId = 39;
                        combRuleType = 'Combination Rules- group limit - range';
                    }
            const combsubgroupsubstancelist = [];
            this.combRuleSpecificAttributes.forEach((combruleattr) => {
                if (combruleattr.attributeName === 'Group Max') {
                    combMaxValue = combruleattr.combAttributeValue;
                    combUomValue = combruleattr.combUomValue;
                    maxEqualityCombGroupValue = combruleattr.equalityCondition === undefined && combMaxValue ? { value: '<' } : combruleattr.equalityCondition;
                    if (maxEqualityCombGroupValue) {
                        combEqualityCondition = maxEqualityCombGroupValue.value !== undefined ? maxEqualityCombGroupValue.value : '';
                    }
                }
                if (combruleattr.attributeName === 'Group Min') {
                    combMinValue = combruleattr.combAttributeValue;
                    combUomValue = combruleattr.combUomValue;
                    minEqualityCombGroupValue = combruleattr.equalityCondition === undefined && combMinValue ? { value: '>' } : combruleattr.equalityCondition;
                    if (minEqualityCombGroupValue) {
                        combEqualityCondition = minEqualityCombGroupValue.value !== undefined ? minEqualityCombGroupValue.value : '';
                    }
                }
                if (combruleattr.attributeName === 'Function') {
                    combFnValue = combruleattr.combAttributeValue;
                }
                if (combruleattr.attributeName === 'Warning') {
                    combWarningText = combruleattr.combAttributeValue;
                }
                if (combruleattr.attributeName === 'Other Requirements & Restrictions') {
                    combOtherText = combruleattr.combAttributeValue;
                }
                if (combruleattr.attributeName === 'Conditional Requirements') {
                    combSpecialConditionText = combruleattr.combAttributeValue;
                }
                if (combruleattr.attributeName === 'Claims') {
                    combClaimText = combruleattr.combAttributeValue;
                }
            });
            if ((this.ruleTypeId === 23 || this.ruleTypeId === 39) && (combMinValue && combMaxValue)) {  // for Range Rule
                combEqualityCondition = minEqualityCombGroupValue.value + ';' + maxEqualityCombGroupValue.value;
            }
            if (combMinValue === undefined && combMaxValue === undefined) {
                combEqualityCondition = '';
            }
            this.combSubGroupDetails.forEach(combsubgroup => {
                combsubgroupsubstancelist.push({
                    SubstanceId: combsubgroup.sirId,
                    ComponentName: combsubgroup.selectedCF.component,
                    ContributionFactor: combsubgroup.selectedCF.cf,
                    NumberOfExpressedAs: combsubgroup.selectedCF.numberOfExpressedAs,
                    SubComponentMolWeight: combsubgroup.selectedCF.componentMolWeight,
                });
            });
            savedData.push({
                RuleId: this.combRuleTypeId ? this.combRuleTypeId : 0,
                RuleVersion: this.combRuleVersion ? this.combRuleVersion : 0,
                RPCs: rpcData,
                MarketID: Number(pureRulesDetailsModel.Market.marketId),
                Market: pureRulesDetailsModel.Market.marketName,
                Markets: mktList,
                RuleTypeID: combRuleTypeId,
                RuleType: combRuleType,
                CascadeMarket: 'N',
                CascadeRpc: groupRulesDetailsModel.CascadeRpc,
                UoMID: combUomValue ? combUomValue.uoMid ? combUomValue.uoMid : 2 : 2,
                UoM: combUomValue ? combUomValue.uoMname ? combUomValue.uoMname : 'N/A' : 'N/A',
                TexualMinLimitText: '',
                TexualMaxLimitText: '',
                RuleDescription: '',
                RuleSource: '',
                RuleSourceType: '',
                AdditionalStatement: combOtherText ? combOtherText : '', // other requirements and restrictions
                PositiveStatement: '',
                MaxLimit: combMaxValue ? combMaxValue : '',
                MinLimit: combMinValue ? combMinValue : '',
                EqualityCondition: combEqualityCondition,
                RuleClaim: '',
                RuleSpecificCondions: '',
                Claims: combClaimText ? [{ ClaimText: combClaimText ? combClaimText : '' }] : null,
                SpeficicConditions: combSpecialConditionText ? [{ SpecificConditionText: combSpecialConditionText ? combSpecialConditionText : '' }] : null,
                GroupFunction: combFnValue ? combFnValue : null,
                PreparationMethod: recipeModel.preparationMethod,
                IntendedUse: recipeModel.intendedUse,
                FunctionList: null, // used only for allowed function array of objects
                NegativeStatement: combWarningText ? combWarningText : '',
                IsMandatorySubstance: null, // Y for substance mandatory rule
                WarningText: null, // used for warning rule, adulterated rule , phased out rule, other requirements and restrictions, Product Warnings
                SubstanceGroup: this.groupDetails,
                SubstanceSubgroupsToSirList: combsubgroupsubstancelist,
                yearAdopted: this.yearAdopted ? this.yearAdopted : null,
                ValidityStartDate: this.validityStartDate ? this.datePipe.transform(this.validityStartDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityFromDate, 'yyyy-MM-dd'),
                ValidityDate: this.validityEndDate ? this.datePipe.transform(this.validityEndDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityEndDate, 'yyyy-MM-dd'),
                IsDosageApplicable: this.groupDosageFlag ? 'Y' : 'N',
                IsServingSizeApplicable: this.groupServingSizeFlag ? 'Y' : 'N',
                Comments: this.ruleComment ? this.ruleComment : null
            });
        }
        if (!this.editRuleFlow) {
            const saveRuleToDbUrl = environment.APIFAsTRule + '/rulesList';
            this.assessReqRAdataService.postData(saveRuleToDbUrl, savedData)
                .subscribe(response => {
                    this.loading = false;
                    if (response[0].ruleId !== 0) {
                        this.ruleAdded = response;
                        this.getRuleDetailsFromDb();
                        this.isSaveButtonEnable = false;
                        this.snackBarGroup.open('Group Rule added successfully with Rule Id:' + response[0].ruleId, 'close', {
                            duration: 5000,
                            panelClass: ['success']
                        });
                        if (this.saveAndAddNewBtnClicked) {
                            setTimeout(() => {
                                this.dataSvcObj.getApplicableRuleTypesObsv.subscribe((res) => {
                                    if (res.length !== 0) {
                                        pureRulesDetailsModel.applicableRuleTypeList = res;
                                        this.applicableGroupRuleTypesToDisplay = res;
                                    }
                                });
                                this.applicableGroupRuleTypesToDisplay = pureRulesDetailsModel.applicableRuleTypeList;
                                this.saveAndAddNewDialog = true;
                                this.saveAndAddNewBtnClicked = false;
                            }, 3000);
                        } else
                            if (this.saveAndCloseClicked) {
                                this.dataSvcObj.setExpandablePanel(2);
                                this.saveAndCloseClicked = false;
                            }
                        this.refreshTemplate();
                    } else {
                        this.snackBarGroup.open('Rule could not be created.', 'close', {
                            duration: 5000,
                            panelClass: ['error']
                        });
                    }
                }, (error) => {
                    this.loading = false;
                });
        } else {
            const url = environment.APIFAsTRule + '/rules';
            this.assessReqRAdataService.putData(url, savedData).subscribe((response) => {
                this.loading = false;
                if (response[0].ruleId !== 0) {
                    this.ruleAdded = response;
                    this.getRuleDetailsFromDb();
                    this.snackBarGroup.open('Rule updated successfully with Rule Id:' + response[0].ruleId + ' and Rule Version: ' + response[0].ruleVersion, 'close', {
                        duration: 5000,
                        panelClass: ['success']
                    });
                    this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
                        this.route.navigate(['/rule-management/view-rules']);
                    });
                } else {
                    this.snackBarGroup.open('Rule could not be updated.', 'close', {
                        duration: 5000,
                        panelClass: ['error']
                    });
                }
            }, (error) => {
                this.loading = false;
            });
        }
    }
    // get rule from DB
    getRuleDetailsFromDb() {
        this.rpc = [];
        if (pureRulesDetailsModel.selectedRpcs !== undefined) {
            if (pureRulesDetailsModel.selectedRpcs[0].text !== 'No Regulatory Product Class is Mapped') {
                pureRulesDetailsModel.selectedRpcs.forEach((rpcValue) => {
                    this.rpc.push({
                        rpcId: rpcValue.id,
                        rpcName: rpcValue.text,
                        rpcFullText: rpcValue.rpcFullText,
                    });
                });
            }
        }
        const fetchRuleBody = {
            MarketList: [pureRulesDetailsModel.Market],
            RPCList: this.rpc,
            ruleClassifications: ['Substance Group Rules']
        };

        this.loading = true;
        const getruleapiurl = environment.APIFAsTRule + '/rules/rulesFilter';
        return new Promise<any>((resolve) => {
            this.assessReqRAdataService.postData(getruleapiurl, fetchRuleBody)
                .toPromise()
                .then(response => {
                    this.loading = false;
                    if (response !== null || response !== []) {
                        resolve(response);
                        const result = response.filter(x => x.ruleClassification === 'Substance Group Rules');
                        for (const value of result) {
                            value.ruleVerionToDisplay = 'V' + value.ruleVersion;
                            if (value.cfDetails !== null && value.cfDetails !== undefined) {
                                const cfValue = value.cfDetails;
                                value.cf = cfValue.cf !== 0 ? cfValue.cf : '';
                                value.cfSubstanceExpressedAs = cfValue.component;
                            }
                            value.warningText = (value.warningText !== null) ? value.warningText : ((value.warningStatement !== null) ? value.warningStatement : '');
                            value.originalWarningText = value.warningText;
                            const warningLinkRuleData = this.assessReqRAdataService.hyperLinksToSheet(value.warningText);
                            value.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
                            value.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
                            value.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
                            value.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
                            value.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
                            value.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
                            value.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
                            if (value.warningText !== '') {
                                value.warningTextStripped = this.handleSpecialCharacters(value.originalWarningText);
                                value.warningText = this.changeComment(value.warningText);
                            } else {
                                value.warningTextStripped = '';
                            }
                            value.otherRestrictionsAndRequirements = (value.otherRestrictionsAndRequirements !== null) ? value.otherRestrictionsAndRequirements : '';
                            const orrLinkRuleData = this.assessReqRAdataService.hyperLinksToSheet(value.otherRestrictionsAndRequirements);
                            value.ORR1 = orrLinkRuleData[0] !== undefined ? orrLinkRuleData[0] : '';
                            value.ORR2 = orrLinkRuleData[1] !== undefined ? orrLinkRuleData[1] : '';
                            value.ORR3 = orrLinkRuleData[2] !== undefined ? orrLinkRuleData[2] : '';
                            value.ORR4 = orrLinkRuleData[3] !== undefined ? orrLinkRuleData[3] : '';
                            value.ORR5 = orrLinkRuleData[4] !== undefined ? orrLinkRuleData[4] : '';
                            value.ORR6 = orrLinkRuleData[5] !== undefined ? orrLinkRuleData[5] : '';
                            value.ORR7 = orrLinkRuleData[6] !== undefined ? orrLinkRuleData[6] : '';
                            if (value.otherRestrictionsAndRequirements !== null) {
                                value.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(value.otherRestrictionsAndRequirements);
                                value.originalOtherRestrictionsAndRequirements = value.otherRestrictionsAndRequirements;
                                value.otherRestrictionsAndRequirements = this.changeComment(value.otherRestrictionsAndRequirements);
                            } else {
                                value.otherRestrictionsAndRequirementsStripped = '';
                            }
                        }

                        this.dataSvcObj.setRulesListForGroup(result);
                        groupRulesDetailsModel.rulesListForGroups = result;
                        this.rulesListForGroup.push(response.filter((value) => value.groupID === this.groupDetails.groupId));
                    }
                }).catch(error => {
                    this.loading = false;
                });
        });
    }
    handleSpecialCharacters(text) {
        return text.replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, '').replace(/&amp;/g, '&');
    }
    // on group rule add attribute button click
    onAddAttributeBtnClicked() {
        this.attBtnClicked = !this.attBtnClicked;
    }
    // on combination rule add attribute button click
    onCombAddAttributeBtnClicked() {
        this.combAttBtnClicked = !this.combAttBtnClicked;
    }
    // select limit type - group level, individual level
    onSelectLevelClick() {
        this.ruleLevelClicked = !this.ruleLevelClicked;
    }
    // on rule level check
    onRuleLevelCheck(ruleLevel) {
        if (ruleLevel.isChecked) {
            this.isSaveButtonEnable = true;
            this.selectedRuleLevelArray.push(ruleLevel.name);
            // if both level selected, function attribute should be enables for group level rule only
            if (this.selectedRuleLevelArray.length === 2) {
                if (!this.editRuleFlow && !this.viewRuleFlow) {
                    this.subGroupDetails.forEach(subgroup => {
                        const functionattr = subgroup.ruleSpecificAttributesForIndividual.filter(x => x.attributeName === 'Function')[0];
                        functionattr.isValid = false;
                    });
                } else {
                    this.targetProducts.forEach(subgroup => {
                        subgroup.selectedCF = subgroup.cfDetails.filter(x => x.cfDisplayText === subgroup.ContributionFactor)[0];
                        if (subgroup.selectedCF) {
                            subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                        }
                        const functionattr = subgroup.ruleSpecificAttributesForIndividual.filter(x => x.attributeName === 'Function')[0];
                        functionattr.isValid = false;
                    });
                    this.subGroupDetails = this.targetProducts;
                }
            }
            // dosage and serving size are enable for group level rule only
            if (ruleLevel.name === 'Group Level') {
                // enable dosage
                if (!this.editRuleFlow) {
                    this.isGroupDosageDisabled = false;
                    this.isGroupServingSizeDisabled = false;
                }
                if ((this.editRuleFlow || this.viewRuleFlow) && this.groupDosageFlag) {
                    this.isGroupDosageDisabled = false;
                    this.isGroupServingSizeDisabled = true;
                } else if ((this.editRuleFlow || this.viewRuleFlow) && this.groupServingSizeFlag) {
                    this.isGroupDosageDisabled = true;
                    this.isGroupServingSizeDisabled = false;
                } else {
                    this.isGroupDosageDisabled = false;
                    this.isGroupServingSizeDisabled = false;
                }
            }
        } else {
            this.selectedRuleLevelArray.forEach((element, index) => {
                if (element === ruleLevel.name) {
                    this.selectedRuleLevelArray.splice(index, 1);
                }
            });
            // clearing the data on unchecking the level
            if (ruleLevel.name === 'Group Level') {
                this.ruleSpecificAttributes.forEach((ruleattr) => {
                    ruleattr.attributeValue = null;
                    ruleattr.Uom = null;
                    ruleattr.equalityCondition = null;
                    this.isGroupDosageDisabled = true;
                    this.isGroupServingSizeDisabled = true;
                    this.groupDosageFlag = false;
                    this.groupServingSizeFlag = false;
                });
            } else if (ruleLevel.name === 'Individual Level') {
                if (!this.editRuleFlow && !this.viewRuleFlow) {
                    this.subGroupDetails.forEach((subgroup) => {
                        subgroup.ruleSpecificAttributesForIndividual.forEach(subgroupattr => {
                            subgroupattr.attributeValue = null;
                            subgroupattr.Uom = null;
                            subgroupattr.equalityCondition = null;
                        });
                    });
                } else {
                    this.targetProducts.forEach((subgroup) => {
                        subgroup.selectedCF = subgroup.cfDetails.filter(x => x.cfDisplayText === subgroup.ContributionFactor)[0];
                        if (subgroup.selectedCF) {
                            subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                        }
                        subgroup.ruleSpecificAttributesForIndividual.filter(x => x.attributeTemplate === 'When').forEach(attr => {
                            if (attr.attributeName === 'Function') {
                                attr.valuelist = this.allFns;
                            }
                        });
                        subgroup.ruleSpecificAttributesForIndividual.forEach(subgroupattr => {
                            subgroupattr.attributeValue = null;
                            subgroupattr.Uom = null;
                            subgroupattr.equalityCondition = null;
                        });
                    });
                }
            }
            // for removing function attribute from the invidual attribute list
            if (this.selectedRuleLevelArray.length < 2) {
                if (!this.editRuleFlow && !this.viewRuleFlow) {
                    this.subGroupDetails.forEach(subgroup => {
                        const functionattr = subgroup.ruleSpecificAttributesForIndividual.filter(x => x.attributeName === 'Function')[0];
                        functionattr.isValid = true;
                    });
                } else {
                    this.targetProducts.forEach(subgroup => {
                        const functionattr = subgroup.ruleSpecificAttributesForIndividual.filter(x => x.attributeName === 'Function')[0];
                        functionattr.isValid = true;
                    });
                }
            }
            if (this.selectedRuleLevelArray.length === 0) {
                this.isSaveButtonEnable = false;
            }
        }
        this.selectedRuleLevel = this.selectedRuleLevelArray.toString();
        this.ruleLevelClicked = !this.ruleLevelClicked;
    }
    // rule specific attribute list
    getRuleSpecificAttributeList() {
        const ruleId = this.ruleTypeId;
        const ruledetails = RuletypesAttributeMapping.filter(obj => obj.ruleBucket === 'Group Based' && obj.ruleTypeId === ruleId)[0];
        this.ruleDetails = Object.assign({}, ruledetails);
        const rsListAttr = this.ruleDetails.attributeList;
        this.ruleSpecificAttributes = rsListAttr.map(x => Object.assign({}, x));
        this.ruleSpecificAttributes.filter(x => x.attributeTemplate === 'When').forEach(attr => {
            if (attr.attributeName === 'Function') {
                attr.valuelist = this.allFns;
            } else
                if (attr.attributeName === 'Claims') {
                    // assign all claims
                    // attr.valuelist = this.allFns;
                } else
                    if (attr.attributeName === 'Conditional Requirements') {
                        // assign all specific condition
                        // attr.valuelist = this.allFns;
                    }
        });
    }
    /* istanbul ignore next */
    onNextButtonClick(tabname) {
        if (!this.limitTabOpened) {
            this.subgroupAttributeDetails();
            this.limitTabOpened = true;
        }
        if (tabname === 'contribution-factor') {
            if (!this.editRuleFlow && !this.viewRuleFlow) {
                this.subGroupDetails.forEach((subgroup) => {
                    subgroup.selectedCF = subgroup.cfDetails.filter(x => x.cfDisplayText === subgroup.ContributionFactor)[0];
                    if (subgroup.selectedCF) {
                        subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                    } else {
                        subgroup.ContributionFactor = subgroup.cfDetails[0].cfDisplayText;
                        subgroup.selectedCF = subgroup.cfDetails[0];
                        subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                    }
                    if (!subgroup.ruleDeatilsIndividual) {
                        const invList = RuletypesAttributeMapping.filter(obj => obj.ruleBucket === 'Group Based' && obj.ruleTypeId === this.ruleDisplayIdForIndividual)[0];
                        subgroup.ruleDeatilsIndividual = Object.assign({}, invList);
                        const invListAttr = subgroup.ruleDeatilsIndividual.attributeList;
                        subgroup.ruleSpecificAttributesForIndividual = invListAttr.map(x => Object.assign({}, x));
                        subgroup.ruleSpecificAttributesForIndividual.filter(x => x.attributeTemplate === 'When').forEach(attr => {
                            if (attr.attributeName === 'Function') {
                                attr.valuelist = this.allFns;
                            }
                        });
                    }
                });
            } else {
                this.targetProducts.forEach((subgroup) => {
                    if (!subgroup.ContributionFactor) {
                        if (subgroup.cfDetails && subgroup.cfDetails.length > 0) {
                            subgroup.ContributionFactor = subgroup.cfDetails[0].cfDisplayText;
                        }
                        subgroup.selectedCF = subgroup.cfDetails.filter(x => x.cfDisplayText === subgroup.ContributionFactor)[0];
                        if (subgroup.selectedCF) {
                            subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                        }
                    }
                    if (!subgroup.ruleDeatilsIndividual) {
                        const invList = RuletypesAttributeMapping.filter(obj => obj.ruleBucket === 'Group Based' && obj.ruleTypeId === this.ruleDisplayIdForIndividual)[0];
                        subgroup.ruleDeatilsIndividual = Object.assign({}, invList);
                        const invListAttr = subgroup.ruleDeatilsIndividual.attributeList;
                        subgroup.ruleSpecificAttributesForIndividual = invListAttr.map(x => Object.assign({}, x));
                        subgroup.ruleSpecificAttributesForIndividual.filter(x => x.attributeTemplate === 'When').forEach(attr => {
                            if (attr.attributeName === 'Function') {
                                attr.valuelist = this.allFns;
                            }
                        });
                    }
                });
                this.subGroupDetails = this.targetProducts;
            }
            this.isAllCfSelected = true;
        }
        if (this.activeTab === 'contribution-factor') {
            if (this.editRuleFlow || this.viewRuleFlow) {
                this.targetProducts.forEach(subgroup => {
                    // subgroup.selectedExemptionValue = null;
                    subgroup.selectedCF = subgroup.cfDetails.filter(x => x.cfDisplayText === subgroup.ContributionFactor)[0];
                    if (subgroup.selectedCF) {
                        subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                    }
                });
            } else {
                this.subGroupDetails.forEach((subgroup) => {
                    subgroup.selectedCF = subgroup.cfDetails.filter(x => x.cfDisplayText === subgroup.ContributionFactor)[0];
                    if (subgroup.selectedCF) {
                        subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                    } else {
                        subgroup.ContributionFactor = subgroup.cfDetails[0].cfDisplayText;
                        subgroup.selectedCF = subgroup.cfDetails[0];
                        subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                    }
                });
            }
        }
        if (tabname === 'next-tab') {
            this.activeTab = (this.exemptedRuleChecked) ? 'exempt-rule' : 'combination-rule';
        } else {
            this.activeTab = tabname;
        }
        if (this.activeTab === 'combination-rule') {
            this.combinationTabOpened = true;
        }
        if (this.activeTab === 'set-limit') {
            this.isGroupLimitSet = true;
        }
    }
    // get substance specific attribute list
    subgroupAttributeDetails() {
        if (!this.editRuleFlow && !this.viewRuleFlow) {
            this.subGroupDetails.forEach(subgroup => {
                subgroup.selectedExemptionValue = null;
                subgroup.selectedCF = subgroup.cfDetails.filter(x => x.cfDisplayText === subgroup.ContributionFactor)[0];
                if (subgroup.selectedCF) {
                    subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                } else {
                    subgroup.ContributionFactor = subgroup.cfDetails[0].cfDisplayText;
                    subgroup.selectedCF = subgroup.cfDetails[0];
                    subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                }
                const invList = RuletypesAttributeMapping.filter(obj => obj.ruleBucket === 'Group Based' && obj.ruleTypeId === this.ruleDisplayIdForIndividual)[0];
                subgroup.ruleDeatilsIndividual = Object.assign({}, invList);
                const invListAttr = subgroup.ruleDeatilsIndividual.attributeList;
                subgroup.ruleSpecificAttributesForIndividual = invListAttr.map(x => Object.assign({}, x));
                subgroup.ruleSpecificAttributesForIndividual.filter(x => x.attributeTemplate === 'When').forEach(attr => {
                    if (attr.attributeName === 'Function') {
                        attr.valuelist = this.allFns;
                    }
                });
            });
        } else {
            this.targetProducts.forEach(subgroup => {
                // subgroup.selectedExemptionValue = null;
                subgroup.selectedCF = subgroup.cfDetails.filter(x => x.cfDisplayText === subgroup.ContributionFactor)[0];
                if (subgroup.selectedCF) {
                    subgroup.formattedCF = 'CF  ' + subgroup.selectedCF.cf;
                }
                const invList = RuletypesAttributeMapping.filter(obj => obj.ruleBucket === 'Group Based' && obj.ruleTypeId === this.ruleDisplayIdForIndividual)[0];
                subgroup.ruleDeatilsIndividual = Object.assign({}, invList);
                const invListAttr = subgroup.ruleDeatilsIndividual.attributeList;
                subgroup.ruleSpecificAttributesForIndividual = invListAttr.map(x => Object.assign({}, x));
                subgroup.ruleSpecificAttributesForIndividual.filter(x => x.attributeTemplate === 'When').forEach(attr => {
                    if (attr.attributeName === 'Function') {
                        attr.valuelist = this.allFns;
                    }
                });
            });
        }
    }
    // getting the attribute list for group rule
    combgroupAttributeDetails() {
        this.combSubGroupDetails = this.subGroupDetails.filter(x => x.combinationChecked);
        this.combRuleSpecificAttributes = this.ruleSpecificAttributes.map(x => Object.assign({}, x));
        this.combRuleSpecificAttributes.filter(x => x.attributeTemplate === 'When').forEach(combAttr => {
            if (combAttr.attributeName === 'Function') {
                combAttr.valuelist = this.allFns;
            }
        });
    }
    // add attribute button click for individual rule
    onIndividualAddAtrBtnClicked(index) {
        this.indAtrBtnClicked = !this.indAtrBtnClicked;
        this.substanceIndex = index;
    }
    // cancel rule creation
    onCancel() {
        if (!this.editRuleFlow) {
            this.refreshTemplate();
            this.dataSvcObj.setExpandablePanel(2);
        } else {
            this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
                this.route.navigate(['/rule-management/view-rules']);
            });
        }
    }
    // add new rule popup ruletype change
    ruleTypeRadioButtonChanged(event) {
        this.ruleTypeRadioSelected = true;
        this.rtRadioSelected = event.target.value;
    }
    // continue after changing ruletype in add new rule popup
    onContinue() {
        if (this.rtRadioSelected !== undefined) {
            const obj = _.filter(pureRulesDetailsModel.applicableRuleTypeList, (element) => element.ruleTypeDisplayName === this.rtRadioSelected);
            // tslint:disable-next-line: no-shadowed-variable
            const obj1 = obj.reduce((obj, newObj, i) => {
                newObj[i] = obj;
                return newObj;
            }, {});
            this.saveAndAddNewDialog = false;
            this.dataSvcObj.setSelectedGroupRuleType(obj1);
            this.dataSvcObj.setExpandablePanel(3);
        }
    }

    // exempt check
    OnExemptRadioClick(value, subGroup) {
        subGroup.selectedExemptionValue = value;
    }

    // get attribute list for combination rule
    onCombinationSelect(value) {
        this.combSubGroupDetails = this.subGroupDetails.filter(x => x.combinationChecked);
    }
    // combination limit type
    onSelectCombLimitTypeClick() {
        this.selectCombinationLimitClicked = !this.selectCombinationLimitClicked;
    }
    // check combination limit
    onCombRuleLimitCheck(limitType) {
        this.selectedLimitType = limitType;
    }
    // enable combination rule tab
    combRuleTab() {
        this.selectCombinationTab = !this.selectCombinationTab;
    }
    // click back button inn combination ruletype template
    onBackButtonClick() {
        this.selectCombinationTab = !this.selectCombinationTab;
    }
    // refresh component data
    refreshComponant() {
        this.subGroupDetails.forEach((element) => {
            this.groupDetails.substanceData.push(element);
        });
        this.subGroupDetails = [];
        this.ruleDetails = [];
        this.activeTab = 'substance-list';
        this.isGroupDosageDisabled = true;
        this.isGroupServingSizeDisabled = true;
        this.groupDosageFlag = false;
        this.groupServingSizeFlag = false;
    }
    // range validation check
    /* istanbul ignore next */
    checkRangeValidation(ruletype) {
        let minValue; let maxValue;
        if (this.ruleTypeId === 23) {
            if (ruletype === 'Group') {
                this.ruleSpecificAttributes.forEach((ruleattr) => {
                    if (ruleattr.attributeName === 'Group Max') {
                        maxValue = ruleattr.attributeValue;
                    }
                    if (ruleattr.attributeName === 'Group Min') {
                        minValue = ruleattr.attributeValue;
                    }
                });
                if (Number(minValue) > Number(maxValue)) {
                    this.rangeValidationFailedGroup = true;
                } else {
                    this.rangeValidationFailedGroup = false;
                }
            } else if (ruletype === 'Individual') {
                if (!this.editRuleFlow && !this.viewRuleFlow) {
                    this.subGroupDetails.forEach((subgroup) => {
                        subgroup.ruleSpecificAttributesForIndividual.forEach((ruleattr) => {
                            if (ruleattr.attributeName === 'Max') {
                                maxValue = ruleattr.attributeValue;
                            }
                            if (ruleattr.attributeName === 'Min') {
                                minValue = ruleattr.attributeValue;
                            }
                        });
                        if (Number(minValue) > Number(maxValue)) {
                            subgroup.rangeValidationFailedInd = true;
                        } else {
                            subgroup.rangeValidationFailedInd = false;
                        }
                    });
                } else {
                    this.targetProducts.forEach((subgroup) => {
                        subgroup.ruleSpecificAttributesForIndividual.forEach((ruleattr) => {
                            if (ruleattr.attributeName === 'Max') {
                                maxValue = ruleattr.attributeValue;
                            }
                            if (ruleattr.attributeName === 'Min') {
                                minValue = ruleattr.attributeValue;
                            }
                        });
                        if (Number(minValue) > Number(maxValue)) {
                            subgroup.rangeValidationFailedInd = true;
                        } else {
                            subgroup.rangeValidationFailedInd = false;
                        }
                    });
                }
            } else if (ruletype === 'Combination') {
                this.combRuleSpecificAttributes.forEach((ruleattr) => {
                    if (ruleattr.attributeName === 'Group Max') {
                        maxValue = ruleattr.combAttributeValue;
                    }
                    if (ruleattr.attributeName === 'Group Min') {
                        minValue = ruleattr.combAttributeValue;
                    }
                });
                if (Number(minValue) > Number(maxValue)) {
                    this.rangeValidationFailedComb = true;
                } else {
                    this.rangeValidationFailedComb = false;
                }
            }
        }
    }
    onGroupDosageClick() {
        this.isGroupServingSizeDisabled = !this.isGroupServingSizeDisabled;
    }
    onGroupServingSizeClick() {
        this.isGroupDosageDisabled = !this.isGroupDosageDisabled;
    }
    refreshTemplate() {
        this.subGroupDetails.forEach((element) => {
            element.ContributionFactor = '';
            element.combinationChecked = false;
            this.groupDetails.substanceData.push(element);
        });
        this.subGroupDetails = [];
        this.ruleDetails = [];
        this.activeTab = 'substance-list';
    }
    /* istanbul ignore next */
    handleChange(event: any) {
        if (!this.editRuleFlow && !this.viewRuleFlow) {
            if ((event.checked === false) && (this.activeTab === 'exempt-rule' || this.activeTab === 'combination-rule')) {
                if (this.activeTab === 'exempt-rule') {
                    this.subGroupDetails.forEach((element) => {
                        element.selectedExemptionValue = null;
                    });
                } else if (this.activeTab === 'combination-rule') {
                    this.subGroupDetails.forEach((element) => {
                        element.combinationChecked = false;
                    });
                }
                this.activeTab = 'set-limit';
            }
        } else {
            if (event.checked === false) {
                if (this.activeTab === 'exempt-rule') {
                    this.subGroupDetails.forEach((element) => {
                        element.selectedExemptionValue = null;
                    });
                } else if (this.activeTab === 'combination-rule') {
                    this.subGroupDetails.forEach((element) => {
                        element.combinationChecked = false;
                    });
                }
                if (this.activeTab === 'exempt-rule' || this.activeTab === 'combination-rule') {
                    this.activeTab = 'set-limit';
                }
            }
        }
    }
    /* istanbul ignore next */
    checkGroupUomValue(event) {
        const value = event.target.value;
        if (value.length > 0) {
            this.uomGroupRuleValidation = true;
        } else {
            this.uomGroupRuleValidation = false;
        }
    }
    /* istanbul ignore next */
    checkIndUomValue(event) {
        const value = event.target.value;
        if (value.length > 0) {
            this.uomIndRuleValidation = true;
        } else {
            this.uomIndRuleValidation = false;
        }
    }
    checkCombUomValue(event) {
        const value = event.target.value;
        if (value.length > 0) {
            this.uomCombRuleValidation = true;
        } else {
            this.uomCombRuleValidation = false;
        }
    }
    parseDate(dateString: string): Date {
        if (dateString) {
            return new Date(dateString);
        }
        return null;
    }
    onClickedOutsideGroup() {
        this.combAttBtnClicked = false;
    }
    onClickedOutsideind() {
        this.indAtrBtnClicked = false;
    }
    onClickedOutsideMain() {
        this.attBtnClicked = false;
    }
    /* istanbul ignore next */
    getEditGroupRuleDetails(grpRuleToEdit) {
        let indMaxVal; let indMinVal; let firstValue; let secondValue; let firstValueInd; let secondValueInd;
        this.subgroupAttributeDetails();
        this.limitTabOpened = true;
        if (grpRuleToEdit.isDosageApplicable === 'Y') {
            this.groupDosageFlag = true;
            this.isGroupDosageDisabled = false;
            this.isGroupServingSizeDisabled = true;
        }
        if (grpRuleToEdit.isServingSizeApplicable === 'Y') {
            this.groupServingSizeFlag = true;
            this.isGroupDosageDisabled = true;
            this.isGroupServingSizeDisabled = false;
        }
        if (grpRuleToEdit.ruleTypeID === 23) {
            firstValue = grpRuleToEdit.equalityCondition.split(';')[0];
            secondValue = grpRuleToEdit.equalityCondition.split(';')[1];
        }
        if (grpRuleToEdit.maxLimit || grpRuleToEdit.minLimit) {
            this.ruleLevelClicked = true;
            this.ruleLevels[1].isChecked = true;
            this.onRuleLevelCheck(this.ruleLevels[1]);
        } else {
            this.ruleLevels[1].isChecked = false;
        }

        this.ruleSpecificAttributes.forEach((ruleattr) => {
            if (ruleattr.attributeName === 'Group Max' && (grpRuleToEdit.maxLimit || grpRuleToEdit.maxLimit === 0)) {
                ruleattr.attributeValue = grpRuleToEdit.maxLimit;
                ruleattr.Uom = { uoMsymbol: grpRuleToEdit.uoM, uoMid: grpRuleToEdit.uoMID, uoMname: grpRuleToEdit.uoM };
                if (grpRuleToEdit.ruleTypeID === 23) {
                    ruleattr.equalityCondition = { value: secondValue };
                } else {
                    ruleattr.equalityCondition = { value: grpRuleToEdit.equalityCondition };
                }
            }
            if (ruleattr.attributeName === 'Group Min' && (grpRuleToEdit.minLimit || grpRuleToEdit.minLimit === 0)) {
                ruleattr.attributeValue = grpRuleToEdit.minLimit;
                ruleattr.Uom = { uoMsymbol: grpRuleToEdit.uoM, uoMid: grpRuleToEdit.uoMID, uoMname: grpRuleToEdit.uoM };
                if (grpRuleToEdit.ruleTypeID === 23) {
                    ruleattr.equalityCondition = { value: firstValue };
                } else {
                    ruleattr.equalityCondition = { value: grpRuleToEdit.equalityCondition };
                }
            }
            if (ruleattr.attributeName === 'Function' && grpRuleToEdit.groupFunction) {
                ruleattr.attributeValue = grpRuleToEdit.groupFunction;
                ruleattr.isRequired = true;
            }
            if (ruleattr.attributeName === 'Warning' && grpRuleToEdit.negativeStatement) {
                ruleattr.attributeValue = grpRuleToEdit.negativeStatement;
                ruleattr.isRequired = true;
            }
            if (ruleattr.attributeName === 'Other Requirements & Restrictions' && grpRuleToEdit.additionalStatement) {
                ruleattr.attributeValue = grpRuleToEdit.additionalStatement;
                ruleattr.isRequired = true;
            }
            if (ruleattr.attributeName === 'Conditional Requirements' && grpRuleToEdit.speficicConditions.length > 0) {
                ruleattr.attributeValue = grpRuleToEdit.speficicConditions[0].specificConditionText;
                ruleattr.isRequired = true;
            }
            if (ruleattr.attributeName === 'Claims' && grpRuleToEdit.claims.length > 0) {
                ruleattr.attributeValue = grpRuleToEdit.claims[0].claimText;
                ruleattr.isRequired = true;
            }
        });
        grpRuleToEdit.substanceSubgroupsToSirList.forEach((value) => {
            const index = _.findIndex(this.targetProducts, (value1) => value1.sirId === value.substanceId);
            if (index !== -1) {
                this.targetProducts[index] = { ...this.targetProducts[index], ...value };
            }
        });
        this.targetProducts.forEach((val) => {
            val.ruleSpecificAttributesForIndividual.filter(x => x.attributeTemplate === 'When').forEach(attr => {
                if (attr.attributeName === 'Function') {
                    attr.valuelist = this.allFns;
                }
            });
            if (grpRuleToEdit.ruleTypeID === 23) {
                firstValueInd = val.equalityCondition ? val.equalityCondition.split(';')[0] : '';
                secondValueInd = val.equalityCondition ? val.equalityCondition.split(';')[1] : '';
            }
            val.ruleSpecificAttributesForIndividual.forEach((subgroupattr) => {
                if (subgroupattr.attributeName === 'Max' && (val.maxLimit || val.maxLimit === 0)) {
                    subgroupattr.isRequired = true;
                    subgroupattr.attributeValue = val.maxLimit;
                    indMaxVal = val.maxLimit;
                    subgroupattr.Uom = { uoMsymbol: val.uoM, uoMid: val.uoMID };
                    if (grpRuleToEdit.ruleTypeID === 23) {
                        subgroupattr.equalityCondition = { value: secondValueInd };
                    } else {
                        subgroupattr.equalityCondition = { value: val.equalityCondition };
                    }
                }
                if (subgroupattr.attributeName === 'Min' && (val.minLimit || val.minLimit === 0)) {
                    subgroupattr.isRequired = true;
                    subgroupattr.attributeValue = val.minLimit;
                    indMinVal = val.minLimit;
                    subgroupattr.Uom = { uoMsymbol: val.uoM, uoMid: val.uoMID };
                    if (grpRuleToEdit.ruleTypeID === 23) {
                        subgroupattr.equalityCondition = { value: firstValueInd };
                    } else {
                        subgroupattr.equalityCondition = { value: val.equalityCondition };
                    }
                }
                if (subgroupattr.attributeName === 'Function' && val.function) {
                    subgroupattr.isRequired = true;
                    subgroupattr.attributeValue = { functionName: val.function ? val.function.functionName : 'Any', functionId: val.function ? val.function.functionId : '' };
                }
                if (subgroupattr.attributeName === 'Warning' && val.negativeStatement) {
                    if (val.negativeStatement) {
                        subgroupattr.isRequired = true;
                        subgroupattr.attributeValue = val.negativeStatement;
                    }
                }
                if (subgroupattr.attributeName === 'Other Requirements & Restrictions' && val.additionalStatement) {
                    if (val.additionalStatement) {
                        subgroupattr.isRequired = true;
                        subgroupattr.attributeValue = val.additionalStatement;
                    }
                }
            });
        });
        if (grpRuleToEdit.combinationSubstanceSubgroupsToSirList && grpRuleToEdit.combinationSubstanceSubgroupsToSirList.length > 0) {
            const value = grpRuleToEdit.combinationSubstanceSubgroupsToSirList[0];
            this.combRuleSpecificAttributes.forEach((combruleattr) => {
                if (combruleattr.attributeName === 'Group Max' && (value.maxLimit || value.maxLimit === 0)) {
                    combruleattr.combAttributeValue = value.maxLimit;
                    combruleattr.combUomValue = { uoMsymbol: value.uoM, uoMid: value.uoMID, uoMname: value.uoM };
                    if (grpRuleToEdit.ruleTypeID === 23) {
                        combruleattr.equalityCondition = { value: value.equalityCondition.split(';')[1] };
                    } else {
                        combruleattr.equalityCondition = { value: value.equalityCondition };
                    }
                }
                if (combruleattr.attributeName === 'Group Min' && (value.minLimit || value.minLimit === 0)) {
                    combruleattr.combAttributeValue = value.minLimit;
                    combruleattr.combUomValue = { uoMsymbol: value.uoM, uoMid: value.uoMID, uoMname: value.uoM };
                    if (grpRuleToEdit.ruleTypeID === 23) {
                        combruleattr.equalityCondition = { value: value.equalityCondition.split(';')[0] };
                    } else {
                        combruleattr.equalityCondition = { value: value.equalityCondition };
                    }
                }
                if (combruleattr.attributeName === 'Function' && value.function) {
                    combruleattr.combAttributeValue = value.function;
                    combruleattr.isRequired = true;
                }
                if (combruleattr.attributeName === 'Warning' && value.negativeStatement) {
                    combruleattr.combAttributeValue = value.negativeStatement;
                    combruleattr.isRequired = true;

                }
                if (combruleattr.attributeName === 'Other Requirements & Restrictions' && value.additionalStatement) {
                    combruleattr.combAttributeValue = value.additionalStatement;
                    combruleattr.isRequired = true;
                }
                if (combruleattr.attributeName === 'Conditional Requirements' && grpRuleToEdit.combinationRuleSpecificConditions && grpRuleToEdit.combinationRuleSpecificConditions.length > 0) {
                    combruleattr.combAttributeValue = grpRuleToEdit.combinationRuleSpecificConditions[0].specificConditionText;
                    combruleattr.isRequired = true;
                }
                if (combruleattr.attributeName === 'Claims' && grpRuleToEdit.combinationRuleClaims && grpRuleToEdit.combinationRuleClaims.length > 0) {
                    combruleattr.combAttributeValue = grpRuleToEdit.combinationRuleClaims[0].claimText;
                    combruleattr.isRequired = true;
                }
            });
        }
        if (indMaxVal || indMinVal) {
            this.ruleLevelClicked = true;
            this.ruleLevels[0].isChecked = true;
            this.onRuleLevelCheck(this.ruleLevels[0]);
        } else {
            this.ruleLevels[0].isChecked = false;
        }
        this.subGroupDetails = this.targetProducts;
    }
    handleSwitch(event, sirvalue) {
        sirvalue.issueCategoryList = [
            { name: 'CAS#', value: 'CAS#' },
            { name: 'Parent CAS#', value: 'Parent CAS#' },
            { name: 'E#', value: 'E#' },
            { name: 'INS#', value: 'INS#' },
            { name: 'EINECS#', value: 'EINECS#' },
            { name: 'INCI', value: 'INCI' },
            { name: 'Synonym', value: 'Synonym' },
            { name: 'CF details', value: 'CF details' },
        ];
        sirvalue.selectedIssueCategory = [];

        if (event.checked === true) {
            this.switchedSirDetails.push(sirvalue);
            this.switchedPureCodes.push(sirvalue.purecode);
        } else if (event.checked === false) {
            const index = this.switchedPureCodes.findIndex(d => d === sirvalue.purecode);
            this.switchedPureCodes.splice(index, 1);
            this.switchedSirDetails.splice(index, 1);
        }
    }
    notifyGPCClicked(event, selectedSirDetails) {
        this.selectedSirDetails = selectedSirDetails;
        this.notifyGPCDialog = true;
    }
    /* istanbul ignore next */
    sendMailtoGPC() {
        let emailData = '';
        this.sentPureCodes = this.switchedPureCodes.toString();
        this.selectedSirDetails.forEach(element => {
            emailData = emailData + '\r\nPure Code:' + ' ' + element.purecode + '\r\n[SIR ID :' + ' ' + element.sirId + ']' +
                '\r\nBackbone Identity :' + ' ' + element.selectedIssueCategory.map((s) => s.value).toString() + '. ' + '\r\n' + 'Comments : ' + ' ' + element.commentsToUser + '\r\n'
                + 'Pure Description : ' + ' ' + element.pureDescription + '\r\nCF Details:' + ' ' + element.cfDetails.map((s) => s.value).toString() + '\r\n';
        });
        const data = {
            NotificationGroup: 'GPC',
            Subject: null,
            EmailBody: emailData,
            ToEmailList: null,
            FromEmail: null,
            CCEmailList: null,
            EmailType: 'html',
            UPD: recipeModel.division,
            PureCodes: this.sentPureCodes,
            RequestID: null
        };
        const apiurl = environment.APIFAsT + '/email';
        this.assessReqRAdataService.postData(apiurl, data).subscribe((response) => {
            if (response.message !== null) {
                if (!this.editRuleFlow && !this.viewRuleFlow) {
                    const requestStatus = 'In Progress – SIR';
                    const statusUpdateApiUrl = environment.APIFAsT + '/assessment/request/' + recipeModel.requestDisplayID + '/status/' + requestStatus + '/comment/SystemGenerated';
                    this.loading = true;
                    this.loadingMsg = 'Request Status Update';
                    this.assessReqRAdataService.putData(statusUpdateApiUrl, null).subscribe((statusresponse) => {
                        if (statusresponse.message !== null) {
                            this.loading = false;
                            const val: any = {
                                requestID: recipeModel.requestDisplayID,
                                requestStatus: requestStatus
                            };
                            this.dataSvcObj.setRecipeData(val);
                            this.snackBarGroup.open('Email message sent to GPC team successfully', 'close', {
                                duration: 5000,
                                panelClass: ['success']
                            });
                        }
                    }, (error) => {
                        this.loading = false;
                    });
                } else {
                    this.loading = false;
                    this.snackBarGroup.open('Email message sent to GPC team successfully', 'close', {
                        duration: 5000,
                        panelClass: ['success']
                    });
                }

            }
        }, (error) => {
        });
    }
    /* istanbul ignore next */
    checkRulesAvail() {
        this.viewRelbtn = true;
        this.prepareRuleTableData = [];
        this.selectedRows = null;
        this.loadingMsg = 'Fetching data from FAsT Rule-Engine';
        this.loading = true;
        const apiurl = environment.APIFAsTRule + '/rules/queryrelatedrequests';
        const body = [];
        this.showRelatedRulesPopup = true;
        body.push({
            RuleId: this.ruleIdOnEdit,
            RuleVersion: this.ruleVersion
        });
        this.assessReqRAdataService.postData(apiurl, body).subscribe((response) => {
            if (response) {
                this.loading = false;
                this.prepareRuleTableData = response.map(maxReq => {
                    return {
                        requestDisplayID: maxReq.requestDisplayID,
                        cucCode: maxReq.cucCode,
                        cucDescription: maxReq.cucDescription,
                        recipeType: maxReq.recipeType,
                        recipeStatus: maxReq.recipeStatus,
                        marketId: maxReq.market.marketId,
                        marketName: maxReq.market.marketName,
                        division: (maxReq.tfc.tfcLevel === 5) ? maxReq.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName : maxReq.tfc.parentTFC.parentTFC.parentTFC.tfcName,
                        rDFormulationCategory: (maxReq.tfc.tfcLevel === 5) ? maxReq.tfc.parentTFC.parentTFC.parentTFC.tfcName : maxReq.tfc.parentTFC.parentTFC.tfcName,
                        formulationType: (maxReq.tfc.tfcLevel === 5) ? maxReq.tfc.parentTFC.parentTFC.tfcName : maxReq.tfc.parentTFC.tfcName,
                        formulationClass: (maxReq.tfc.tfcLevel === 5) ? maxReq.tfc.parentTFC.tfcName : maxReq.tfc.tfcName,
                        formulationSubClass: (maxReq.tfc.tfcLevel === 5) ? maxReq.tfc.tfcName : '',
                        preparationMethod: maxReq.preparationMethod,
                        intendedUse: maxReq.intendedUse,
                        multicomponent: maxReq.isMultiComponent,
                        requestStatus: maxReq.requestStatus,
                        ra: maxReq.ra,
                        allRa: maxReq.allRA,
                        submittedDate: maxReq.submittedDate,
                        submittedByUserId: maxReq.submittedByUserId,
                        submittedByUserName: maxReq.submittedByUserName,
                        comments: maxReq.comments,
                        assignedDate: maxReq.assignedDate,
                        inProgressRADate: maxReq.inProgressRADate,
                        inProgressRDDate: maxReq.inProgressRNDDate,
                        inProgressSIRDate: maxReq.inProgressSIRDate,
                        rejectedDate: maxReq.rejectedDate,
                        deletionDate: maxReq.deletionDate,
                        completedDate: maxReq.completedDate,
                        rdComment: maxReq.comments,
                        raComment: maxReq.raComment,
                        topTfcId: maxReq.tfcid,
                        allRAIDs: maxReq.allRAIDs,
                        raUserID: maxReq.raUserIDred,
                        divisionid: (maxReq.tfc.tfcLevel === 5) ? maxReq.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId : maxReq.tfc.parentTFC.parentTFC.parentTFC.tfcId
                    };
                });
            }
            const ele = document.getElementById('echeck') as HTMLInputElement;
            ele.checked = false;
            this.selectedRowsRelated = [];
        }, (error) => {
            this.loading = false;
        });
    }
    getWorklistColumns() {
        const apiurl = environment.APIFAsT + '/getworklistcolumns/relatedrequest';
        this.worklistservice.getWorklistApiData(apiurl).subscribe((response) => {
            this.myWorklistTableColumns = response;
        });
    }
    sendEmail() {
        this.showEmailConfirmationDialog = true;
        let reqId = '';
        let userDetails = '';
        const uDetail = [];
        const reDet = [];
        this.selectedRowsRelated.forEach(element => {
            reDet.push(element.requestDisplayID);
        });
        const distinctReqs = reDet.filter((n, i) => reDet.indexOf(n) === i);
        reqId = distinctReqs.join(' ');
        this.selectedRowsRelated.forEach(element => {
            uDetail.push(element.ra);
        });
        const distinctNames = uDetail.filter((n, i) => uDetail.indexOf(n) === i);
        userDetails = distinctNames.join(' ');
        this.commentsToUser = 'Regulatory rule/s for the request no. ' + reqId + ' will be updated/deleted by RA user ' + userDetails + ' ..To get the most updated FAsT report, please submit a new request for the formulation. Ignore if the formulation is no longer valid.';
    }
    sendEmailMessage() {
        const payLoad = {
            NotificationGroup: 'RDCOMPLETED',
            Subject: 'FAsT | Rules updated for these requests :',
            EmailBody: null,
            ToEmailList: '',
            FromEmail: null,
            CCEmailList: null,
            EmailType: 'html',
            UPD: this.ruleIdOnEdit.toString(),
            PureCodes: null,
            UserRequests: [],
            CUCDescription: null,
            Comments: this.commentsToUser
        };

        if (this.selectedRowsRelated.length > 0) {
            this.selectedRowsRelated.forEach(row => {
                const reqIds = this.selectedRowsRelated.filter(item => item.submittedByUserId === row.submittedByUserId).map(i => i.requestDisplayID);
                payLoad.UserRequests.push({
                    userId: +row.submittedByUserId,
                    requestIds: reqIds
                });
            });
        }

        if (payLoad.UserRequests.length > 0) {
            this.assessReqRAdataService.sendRulesEmailNotification(payLoad);
        }
        this.showEmailConfirmationDialog = false;
        this.showRelatedRulesPopup = false;
    }
    onGroupSelect(checkValue) {
        if (checkValue) {
            this.selectedRowsRelated = this.prepareRuleTableData;
        } else {
            this.selectedRowsRelated = [];
        }
    }
    maxlenghtCheck1(data, count, attributeName, index) {
        if (data !== null) {
        const dataWithoutHtmlCount = data.replace(/<(.|\n)*?>/g, '').length + 1;
        const datawithhtmlCount = data.length;
        // this.ankerCount = (data.match(/<a/g) || []).length;
        this.subGroupDetails.forEach((ruleattr, i) => {
            if (i === index) {
                ruleattr.ruleSpecificAttributesForIndividual.forEach((e) => {
                    if (e.attributeName === attributeName) {
                        e.ankerCount = (data.match(/<a/g) || []).length;
                    }
                });
            }

        });
        this.checkmax71(attributeName);
        const diff = datawithhtmlCount - dataWithoutHtmlCount;
        if (count <= dataWithoutHtmlCount) {
            this.subGroupDetails.forEach((ruleattr) => {
                ruleattr.ruleSpecificAttributesForIndividual.forEach((e) => {
                    if (e.attributeName === attributeName) {
                    e.attributeValue = e.attributeValue.substring(0, count + diff);
                    }
                });
            });
            return false;
        }
    }
    }
    checkmax71(attributeName) {
        for (let index = 0; index < this.subGroupDetails.length; index++) {
            for (let j = 0; j < this.subGroupDetails[index].ruleSpecificAttributesForIndividual.length; j++) {
                // if (this.subGroupDetails[index].ruleSpecificAttributesForIndividual[j].attributeName === attributeName) {
                if (this.subGroupDetails[index].ruleSpecificAttributesForIndividual[j].ankerCount > 7) {
                    this.ankerCount = this.subGroupDetails[index].ruleSpecificAttributesForIndividual[j].ankerCount;
                    break;
                } else {
                    this.ankerCount = this.subGroupDetails[index].ruleSpecificAttributesForIndividual[j].ankerCount;
                }
                // }
            }
            if (this.ankerCount > 7) {
                break;
            }
        }
    }
    maxlenghtCheck(data, count, atributeName) {
        if (data !== null && data !== undefined) {
        const dataWithoutHtmlCount = data.replace(/<(.|\n)*?>/g, '').length + 1;
        const datawithhtmlCount = data.length;
        // this.ankerCount = (data.match(/<a/g) || []).length;
        this.ruleSpecificAttributes.forEach((ruleattr) => {
            if (ruleattr.attributeName === atributeName) {
                ruleattr.ankerCount = (data.match(/<a/g) || []).length;
            }
            this.checkmax7();
        });
        const diff = datawithhtmlCount - dataWithoutHtmlCount;
        if (count <= dataWithoutHtmlCount) {
            this.ruleSpecificAttributes.forEach((ruleattr) => {
                if (ruleattr.attributeName === atributeName) {
                    ruleattr.attributeValue = ruleattr.attributeValue.substring(0, count + diff);
                }
            });
            return false;
        }
    }
}
    checkmax7() {
        for (let index = 0; index < this.ruleSpecificAttributes.length; index++) {
            if (this.ruleSpecificAttributes[index].ankerCount > 7) {
                this.ankerCount = this.ruleSpecificAttributes[index].ankerCount;
                break;
            } else {
                this.ankerCount = this.ruleSpecificAttributes[index].ankerCount;
            }
        }
    }
    maxlenghtCheck3(data, count, atributeName) {
        if (data !== null) {
        const dataWithoutHtmlCount = data.replace(/<(.|\n)*?>/g, '').length + 1;
        const datawithhtmlCount = data.length;
        // this.ankerCount = (data.match(/<a/g) || []).length;
        this.combRuleSpecificAttributes.forEach((ruleattr) => {
            if (ruleattr.attributeName === atributeName) {
                ruleattr.ankerCount = (data.match(/<a/g) || []).length;
            }
            this.checkmax();
        });
        const diff = datawithhtmlCount - dataWithoutHtmlCount;
        if (count <= dataWithoutHtmlCount) {
            this.combRuleSpecificAttributes.forEach((ruleattr) => {
                if (ruleattr.attributeName === atributeName) {
                    ruleattr.combAttributeValue = ruleattr.combAttributeValue.substring(0, count + diff);
                }
            });
            return false;
        }
    }
    }
    checkmax() {
        for (let index = 0; index < this.combRuleSpecificAttributes.length; index++) {
            if (this.combRuleSpecificAttributes[index].ankerCount > 7) {
                this.ankerCount = this.combRuleSpecificAttributes[index].ankerCount;
                break;
            } else {
                this.ankerCount = this.combRuleSpecificAttributes[index].ankerCount;
            }
        }
    }
    /* istanbul ignore next */
    openComment(data) {
        this.warningTextOpen = true;
        const mydata = data.originalWarningText;
        this.tablewarningComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
    }
    /* istanbul ignore next */
    openCommentORR(data) {
        this.orrTextOpen = true;
        const mydata = data.originalOtherRestrictionsAndRequirements;
        this.tableORRComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
    }
    closePopup() {
        this.warningTextOpen = false;
    }
    closePopupORR() {
        this.orrTextOpen = false;
    }
    /* istanbul ignore next */
    changeComment(input) {
        const strValue = input;
        const ar = strValue.match(/<a.*?<\/a>/g);
        let commentwarning = '';
        if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
            commentwarning = 'Click Here';
        } else {
            commentwarning = input;
        }
        return commentwarning;
    }
}
