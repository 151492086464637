export class ProductRuleDetailsModel {
    Market: MarketDetails;
    RpcDetails: RPCDetails[];
    RpcFullDetails;
    CascadeRpc;
    applicableRuleTypeList = [];
    selectedRpcsPdtBased;
    selectedParentRpcsPdtBased;
    rulesListForProduct;
}
class MarketDetails {
    marketId;
    marketName;
}

class RPCDetails {
    RpcId;
    RpcName;
}

export let prdtRuleDetailsModel = new ProductRuleDetailsModel();
