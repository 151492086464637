import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

    @Output() selectedRowsforAction: EventEmitter<any> = new EventEmitter();
    loading = false;
    loadingMsg = 'Fetching data from FAsT Rule-Engine';
    myWorklistTableColumns = [];
    selectedRowEmail = [];
    // tslint:disable-next-line: no-input-rename
    @Input('request') request;
    // @Input('showRelatedRequest') showRelatedRequest;
    isClickHere = false;
    tableComment = '';
    constructor(
        public matSnackBar: MatSnackBar,
        private worklistservice: WorklistService,
    ) { }

    ngOnInit() {
        this.getWorklistColumns();
    }

    getWorklistColumns() {
        const apiurl = environment.APIFAsT + '/getworklistcolumns/relatedrequest';
        this.worklistservice.getWorklistApiData(apiurl).subscribe((response) => {
            this.myWorklistTableColumns = response;
        });
    }
    selectRowforAction(event, id) {
        this.selectedRowsforAction.emit({ data: event.data, selected: 'singleTrue', ruleId: id, mode: 'single' });
    }

    onTableHeaderCheckboxToggle(event, id) {
        this.selectedRowsforAction.emit({ data: 'this.selectedRowEmail', selected: event.checked, ruleId: id, mode: 'selectAll' });
    }

    onRowUnselectforAction(event, id) {
        this.selectedRowsforAction.emit({ data: event.data, selected: false, ruleId: id, mode: 'single' });
    }
    openRdComment(data) {
        this.isClickHere = true;
        const mydata = data.rdCommentoriginal;
        this.tableComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
    }
    closePopup() {
        this.isClickHere = false;
    }
}
