import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { pureRulesDetailsModel } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { sirDetailObj } from 'src/app/common/models/SirModel';
import { DataService } from 'src/app/common/services/data.service';
import { environment } from 'src/environments/environment';

export class RuleTypesApplicable {
  id;
  pureCode;
  marketId;
  rpcid;
  applicabilityStatus;
  isRuleApplicable: boolean;
  isRuleNotApplicable: boolean;
  ruleClassification;
  sirid;
  ruleTypeId;
  ruleTypeDisplayName;
  ruleCreationStatus;
  isAddRuleEnable: boolean;
}

@Component({
  selector: 'app-rule-selection',
  templateUrl: './rule-selection.component.html',
  styleUrls: ['./rule-selection.component.scss'],
  providers: [DatePipe]
})
export class RuleSelectionComponent implements OnInit , OnDestroy {
  @Output() ruleTypeEvent = new EventEmitter<any>();
  ruleTypesApplicable: RuleTypesApplicable[] = [];
  readOnlyValue = true;
  i = 0;
  isRuleAdded = false; isRuleChecked = false; isRuleUnChecked = false;  nonRestrictedOrBannedPopUpFlag = false;
  loading = false;
  selectedRuleCategory = ''; switchedRuleCategory = ''; disableRuleCategory = false;
  rulesAppliedList = []; ruleTypes = []; propertyRuleTypes = [];  substanceBasedRuleTypes = []; substanceGroupRuleTypes = []; subBasedRuleData = [];
  ruleBucket; rpc;
  validityStartDate: any = ''; validityEndDate: any = ''; yearAdopted: any; isValidityDateVisible = false;
  pureDetails = {
    PureCode: pureRulesDetailsModel.PureCode,
    Market: { MarketId: 10, MarketName: 'Brazil' },
    RpcDetails: {
      RpcId: 1690,
      RpcName: 'Oral Care'
    },
    Sirid: pureRulesDetailsModel.SirId,
  };
  preparationMethodToDisplay; intendedUseToDisplay; loadingMsg; purecodeToDisplay;
  positiveSubstance = []; bannedSubstance = [];
  nonRestrictedOrBannedComment = '';
  sirDetailsData ;
  subscription: Subscription;
  isparallelrule;
  constructor(
    private assessRequestRa: AssessRequestRaService, public matSnackBar: MatSnackBar, public dataSvcObject: DataService, private datePipe: DatePipe,
  ) { }
  defaultValidityFromDate; defaultValidityEndDate; todayDate;
  /* istanbul ignore next */
  ngOnInit() {
    this.subscription = this.assessRequestRa.sirData.subscribe(res => {
      this.sirDetailsData = res;
    });
    this.isparallelrule = sessionStorage.getItem('parallelRuleWorkflow');
    this.todayDate = new Date();
    this.defaultValidityFromDate = this.datePipe.transform(this.todayDate, 'longDate');
    this.defaultValidityEndDate = this.datePipe.transform(new Date('9999-12-31'), 'longDate');
    // this.purecodeToDisplay = pureRulesDetailsModel.PureCode ? pureRulesDetailsModel.PureCode : sirDetailObj.pureCode;
    this.purecodeToDisplay = pureRulesDetailsModel.PureCode ? pureRulesDetailsModel.PureCode : this.sirDetailsData.pureCode;
    this.preparationMethodToDisplay = recipeModel.preparationMethod;
    this.intendedUseToDisplay = recipeModel.intendedUse;
    this.ruleBucket = localStorage.getItem('selectedRuleBucket');
    this.dataSvcObject.getSelectedRuleCategoryForSubBased.subscribe((ruleCategory) => {
      if (ruleCategory === 'regulated') {
        this.selectedRuleCategory = 'regulated';
      } else if (ruleCategory === 'non-restricted') {
        this.selectedRuleCategory = 'non-restricted';
      } else if (ruleCategory === 'banned') {
        this.selectedRuleCategory = 'banned';
      }
    });
    this.dataSvcObject.getRulesListForEachPure.subscribe((resultSet) => { //  on adding rules update the rule display table
      if (resultSet.length !== 0) {
        pureRulesDetailsModel.rulesListForSpecificPure = resultSet;
        const positiveSubstance = _.filter(resultSet, (ruleData) => {
          return ruleData.ruleTypeID === 17;
        });
        this.positiveSubstance = _.uniqBy(positiveSubstance, (val) => {
          return val.ruleTypeID;
        });
        const bannedSubstance = _.filter(resultSet, (ruleData) => {
          return ruleData.ruleTypeID === 18;
        });
        this.bannedSubstance = _.uniqBy(bannedSubstance, (val) => {
          return val.ruleTypeID;
        });
        if (this.positiveSubstance.length !== 0) {
          pureRulesDetailsModel.ruleCategory = 'non-restricted';
          this.dataSvcObject.setRuleCategory('non-restricted');
          this.switchedRuleCategory = '';
        } else if (this.bannedSubstance.length !== 0) {
          pureRulesDetailsModel.ruleCategory = 'banned';
          this.dataSvcObject.setRuleCategory('banned');
          this.switchedRuleCategory = '';
        } else if (this.positiveSubstance.length === 0 && this.bannedSubstance.length === 0) {
          pureRulesDetailsModel.ruleCategory = 'regulated';
          this.dataSvcObject.setRuleCategory('regulated');
          this.switchedRuleCategory = '';
        }
      } else if (resultSet.length === 0) {
        pureRulesDetailsModel.ruleCategory = 'regulated';
        this.dataSvcObject.setRuleCategory('regulated');
        this.switchedRuleCategory = '';
      }
    });
    this.getRuleApplicability();
  }
  radioButtonChanged(event) {
    this.selectedRuleCategory = '';
    this.switchedRuleCategory = event.target.value;
    if (this.switchedRuleCategory === 'regulated') {
      this.isValidityDateVisible = false;
    } else {
      this.isValidityDateVisible = true;
    }
  }
  /* istanbul ignore next */
  onAddRule(ruleType) {
    if (ruleType.isRuleApplicable && ruleType.isAddRuleEnable) { // if rule is green and not red
      this.ruleTypeEvent.emit(ruleType);
    }
  }

  onCheckRule(ruleType) {
    if (ruleType.isRuleApplicable) {
      const index = _.findIndex(pureRulesDetailsModel.rulesListForSpecificPure, (ruleValue) => {
        return ruleValue.ruleTypeID === ruleType.ruleTypeId;
      });
      if (index > -1) {
        this.matSnackBar.open('Rule Exists for the Rule Type. Rule Applicability cannot be changed.', 'close', {
          duration: 5000,
          panelClass: ['error']
        });
        return;
      }
    }
    if (ruleType.isRuleNotApplicable) {
      return;
    }
    ruleType.isAddRuleEnable = false;
    const checkedruletype = this.ruleTypesApplicable.find(x => x.ruleTypeDisplayName === ruleType.ruleTypeDisplayName);
    checkedruletype.isRuleApplicable = !checkedruletype.isRuleApplicable;
  }
  onUnCheckRule(ruleType) {
    if (ruleType.isRuleApplicable) {
      return;
    }
    ruleType.isAddRuleEnable = false;
    const uncheckedruletype = this.ruleTypesApplicable.find(x => x.ruleTypeDisplayName === ruleType.ruleTypeDisplayName);
    uncheckedruletype.isRuleNotApplicable = !uncheckedruletype.isRuleNotApplicable;
  }
  saveRuleApplicability_nonrestricted() {
    this.dataSvcObject.getSirDetailForParallelRule().subscribe((value) => {
       if (this.isparallelrule === 'yes') {
        this.sirDetailsData = value[0];
      }
    });
    this.ruleTypesApplicable.forEach(element => {
      element.marketId = pureRulesDetailsModel.Market.marketId;
      element.pureCode = pureRulesDetailsModel.PureCode;
      element.sirid = this.sirDetailsData.sirId;
      // element.sirid = pureRulesDetailsModel.SirId;
      element.ruleCreationStatus = 'N';
      if (element.isRuleApplicable) {
        element.applicabilityStatus = 'Yes';
      } else
        if (element.isRuleNotApplicable) {
          element.applicabilityStatus = 'No';
        } else {
          element.applicabilityStatus = 'NA';
        }
    });
    const data = [];
    pureRulesDetailsModel.selectedRpcs.forEach(rpc => {
      this.ruleTypesApplicable.forEach(element => {
        const ruleappobject = Object.assign({}, element);
        ruleappobject.rpcid = Number(rpc.id);
        data.push(ruleappobject);
      });
    });
    this.loading = true;
    this.loadingMsg = 'Saving Rule Types';
    const apiurl = environment.APIFAsTRule + '/rules/ruleTypesMaps';
    this.assessRequestRa.postData(apiurl, data).subscribe((response) => {
      this.loading = false;
      if (response.message === 'Success') {
        this.nonRestrictedOrBannedPopUpFlag = true;
      }
    }, (error) => {
      this.loading = false;
    });
  }
  // save applicability data
  saveRuleApplicability() {
    this.ruleTypesApplicable.forEach(element => {
      element.marketId = pureRulesDetailsModel.Market.marketId;
      element.pureCode = pureRulesDetailsModel.PureCode;
      element.sirid = pureRulesDetailsModel.SirId;
      element.ruleCreationStatus = 'N';
      if (element.isRuleApplicable) {
        element.applicabilityStatus = 'Yes';
      } else
        if (element.isRuleNotApplicable) {
          element.applicabilityStatus = 'No';
        } else {
          element.applicabilityStatus = 'NA';
        }
    });
    const data = [];
    pureRulesDetailsModel.selectedRpcs.forEach(rpc => {
      this.ruleTypesApplicable.forEach(element => {
        const ruleappobject = Object.assign({}, element);
        ruleappobject.rpcid = Number(rpc.id);
        data.push(ruleappobject);
      });
    });
    let varrpcitem = 0;
    varrpcitem = pureRulesDetailsModel.selectedRpcs.length;
    if (varrpcitem > 1) {
      this.loading = false;
      this.matSnackBar.open('Invalid Input Error: More than one child RPC level under same parent is selected. Please select one child RPC level to save rule applicability.', 'close', {
        duration: 3000,
        panelClass: ['error']
      });
    } else {
      /* istanbul ignore next */
      this.loading = true;
      this.loadingMsg = 'Saving Rule Types';
      const apiurl = environment.APIFAsTRule + '/rules/ruleTypesMaps';
      this.assessRequestRa.postData(apiurl, data).subscribe((response) => {
      this.loading = false;
      if (response.message === 'Success') {
        this.matSnackBar.open('Saved Successfully', 'close', {
          duration: 3000,
          panelClass: ['success']
        });
        this.ruleTypesApplicable.forEach(element => {
          if (element.isRuleApplicable) {
            element.isAddRuleEnable = true;
          }
        });  // why the same panel added here && value.ruleClassification === 'Substance Based Rules'
        const filteredTypes = _.filter(this.ruleTypesApplicable, (value) => value.applicabilityStatus === 'Yes' && value.ruleClassification === 'Substance Based Rules');
        this.dataSvcObject.setApplicableRuleTypesObsv(filteredTypes);
      }
    }, (error) => {
      this.loading = false;
    });
    }
  }
  // get applicability data
  getRuleApplicability() {
    this.i++;
    const applicabilityInputList = [];
    const purecode = pureRulesDetailsModel.PureCode;
    const marketId = pureRulesDetailsModel.Market.marketId;
    pureRulesDetailsModel.selectedRpcs.forEach((value) => {
      const data = {
        PureCode: purecode,
        MarketId: marketId,
        Rpcid: value.id
      };
      applicabilityInputList.push(data);
    });
    this.loading = true;
    this.loadingMsg = 'Fetching All Rule Types';
    const apiurl = environment.APIFAsTRule + '/rules/ruleTypesMapForMarkets';
    this.assessRequestRa.postData(apiurl, applicabilityInputList).subscribe((response) => {
      this.ruleTypesApplicable = [];
      this.propertyRuleTypes = [];
      this.substanceBasedRuleTypes = [];
      this.substanceGroupRuleTypes = [];
      if (response.length !== 0) {
        this.ruleTypesApplicable = response;
        this.ruleTypesApplicable.forEach(element => {
          if (element.ruleTypeId !== 17 && element.ruleTypeId !== 18) {
            if (element.applicabilityStatus === 'Yes') {
              element.isRuleApplicable = true;
              element.isAddRuleEnable = true;
            } else if (element.applicabilityStatus === 'No') {
              element.isRuleNotApplicable = true;
            } else {
              element.isRuleNotApplicable = false;
              element.isRuleApplicable = false;
            }
            if (element.ruleClassification === 'Product Based Rules' || element.ruleClassification === 'Property Based Rules') {
              this.propertyRuleTypes.push(element);
            } else if (element.ruleClassification === 'Substance Based Rules') {
              this.substanceBasedRuleTypes.push(element);
            } else if (element.ruleClassification === 'Substance Group Rules') {
              if (element.ruleTypeId === 34 || element.ruleTypeId === 35 || element.ruleTypeId === 36) {
              } else {
                this.substanceGroupRuleTypes.push(element);
              }
            }
          }
        });
        const filtered = _.filter(this.ruleTypesApplicable, (value) => value.applicabilityStatus === 'Yes' && value.ruleClassification === 'Substance Based Rules');
        this.dataSvcObject.setApplicableRuleTypesObsv(filtered);
        this.loading = false;
      } else if (response.length === 0) {
        this.loading = false;
        this.matSnackBar.open('Request Failed', 'close', {
          duration: 3000,
          panelClass: ['error']
        });
        this.dataSvcObject.setExpandablePanel(1);
      }
    }, (error) => {
      this.loading = false;
      this.dataSvcObject.setExpandablePanel(1);
    });
  }
  saveRuleTypeCategory(ruleCategory) { // for Positive and Banned Substances
    // tslint:disable-next-line: prefer-const
    let ruleTypeId; let ruleType; let rpcData = [];
    if (ruleCategory === 'non-restricted') {
      ruleTypeId = 17;
      ruleType = 'Positive Substance';
    } else if (ruleCategory === 'banned') {
      ruleTypeId = 18;
      ruleType = 'Banned Substance';
    }
    const substanceData = [{
      PureCode: this.sirDetailsData.pureCode,
      PureDescription: this.sirDetailsData.pureDescription,
      SirId: this.sirDetailsData.sirId,
      SubstanceName: this.sirDetailsData.substanceName,
      InciName: this.sirDetailsData.inciName.toString(),
      Einecs: this.sirDetailsData.einecs.toString(),
      Cas: this.sirDetailsData.cas.toString(),
      parentCAS: this.sirDetailsData.parentCAS.toString(),
      Ins: this.sirDetailsData.ins.toString(),
      ENumber: this.sirDetailsData.eNumber.toString(),
      SubstanceFunction: null,
      UoM: 'N/A',
      CFDetails: this.sirDetailsData.cfDetails,
      SubstanceMolWeight: this.sirDetailsData.substanceMolWeight,
      CombinationRatio: null
    }];
    pureRulesDetailsModel.selectedRpcs.forEach((value) => {
      const data = {
        rpcFullText: value.rpcFullText,
        rpcName: value.text,
        rpcId: value.id
      };
      rpcData.push(data);
    });
    const savedData = [{
      Markets: [pureRulesDetailsModel.Market],
      RPCs: rpcData, /// object list
      RuleId: 0,
      RuleTypeID: ruleTypeId,
      RuleType: ruleType,
      SubstanceList: substanceData,
      CascadeMarket: 'N',
      CascadeRpc: 'Y', // made to Y by default as the flag is always selected
      UoMID: 2,
      UoM: 'N/A',
      TexualMinLimitText: '',
      TexualMaxLimitText: '',
      RuleDescription: ruleType,
      RuleSource: '',
      RuleSourceType: 'Regulatory',
      AdditionalStatement: '', // other requirements and restrictions
      PositiveStatement: '',
      MaxLimit: '',
      MinLimit: '',
      EqualityCondition: '',
      Claims: null,
      SpeficicConditions: null,
      FunctionList: null, // used only for allowed function array of objects
      NegativeStatement: '',
      PreparationMethod: recipeModel.preparationMethod,
      IntendedUse: recipeModel.intendedUse,
      IsMandatorySubstance: null, // Y for substance mandatory rule
      PhasedOutDate: null,
      yearAdopted: this.yearAdopted ? this.datePipe.transform(this.yearAdopted, 'yyyy-MM-dd') : null,
      ValidityStartDate: this.validityStartDate ? this.datePipe.transform(this.validityStartDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityFromDate, 'yyyy-MM-dd'),
      ValidityDate: this.validityEndDate ? this.datePipe.transform(this.validityEndDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityEndDate, 'yyyy-MM-dd'),
      Comments: this.nonRestrictedOrBannedComment ? this.nonRestrictedOrBannedComment : null,
      WarningText: null // used for warning rule, adulterated rule , phased out rule, other requirements and restrictions, Product Warnings
    }];
    const saveRuleToDbUrl = environment.APIFAsTRule + '/rulesList';
    this.loading = true;
    this.loadingMsg = 'Saving Rule';
    this.assessRequestRa.postData(saveRuleToDbUrl, savedData).subscribe((response) => {
      console.log(response);
      this.loading = false;
      if (response[0].ruleId !== 0) {
        this.dataSvcObject.setRulesListForPure(response);
        this.getRuleDetailsFromDb();
        this.matSnackBar.open('Rule added successfully with Rule Id:' + response[0].ruleId, 'close', {
          duration: 5000,
          panelClass: ['success']
        });
      } else {
        this.matSnackBar.open('Rule could not be created.', 'close', {
          duration: 5000,
          panelClass: ['error']
        });
      }
    }, (error) => {
      this.loading = false;
    });
  }
  getRuleDetailsFromDb() {
    this.rpc = [];
    pureRulesDetailsModel.selectedRpcs.forEach((value) => {
      const data = {
        rpcFullText: value.rpcFullText,
        rpcName: value.text,
        rpcId: value.id
      };
      this.rpc.push(data);
    });
    const fetchRuleBody = {
      MarketList: [pureRulesDetailsModel.Market],
      RPCList: this.rpc,
      SIRIds: [this.sirDetailsData.sirId],
      ruleClassifications: ['Substance Based Rules']
    };

    // console.log('fetchRuleBody', fetchRuleBody);
    this.loading = true;
    const getruleapiurl = environment.APIFAsTRule + '/rules/rulesFilter';
    this.assessRequestRa.postData(getruleapiurl, fetchRuleBody).subscribe((response) => {
      this.loading = false;
      if (response !== null || response) {
        const subBasedRuleDataTemp = response;
        this.subBasedRuleData = _.filter(subBasedRuleDataTemp, (val) => val.ruleClassification === 'Substance Based Rules');
        for (const value of this.subBasedRuleData) {
          value.ruleVerionToDisplay = 'V' + value.ruleVersion;
          value.warningText = (value.warningText !== null) ? value.warningText : ((value.warningStatement !== null) ? value.warningStatement : null);
          if (value.warningText === null) {
            value.warningText = value.warningText;
            value.originalWarningText = value.warningText;
            value.warningTextStripped = this.handleSpecialCharacters(value.originalWarningText);
            value.warningText = this.changeComment(value.warningStatement);
          }
          value.otherRestrictionsAndRequirements = (value.otherRestrictionsAndRequirements !== null) ? value.otherRestrictionsAndRequirements : null;
          value.originalOtherRestrictionsAndRequirements = value.otherRestrictionsAndRequirements;
          value.otherRestrictionsAndRequirements = this.changeComment(value.otherRestrictionsAndRequirements);
          value.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(value.originalOtherRestrictionsAndRequirements);
          if (value.cfDetails !== null && value.cfDetails !== undefined) {
            const cfValue = value.cfDetails;
            value.cf = cfValue.cfDisplayText;
            value.cfSubstanceExpressedAs = cfValue.expressedAs;
          }
        }
        this.dataSvcObject.setRulesListForPure(this.subBasedRuleData);
      }
    }, (error) => {
      this.loading = false;
    });
  }

  handleSpecialCharacters(text) {
    if (text) {
      return text.replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, '').replace(/&amp;/g, '&');
    } else {
      return null;
    }
  }


  changeComment(input) {
    const strValue = input;
    const ar = strValue ? strValue.match(/<a.*?<\/a>/g) : null;
    let commentwarning = '';
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentwarning = 'Click Here';
    } else {
      commentwarning = input;
    }
    return commentwarning;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
