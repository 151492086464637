
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../common/services/api/api.service';
import { timeout } from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
  ]
})


@Injectable({
  providedIn: 'root'
})
export class AssessRequestRaService {
  sirIdData = new BehaviorSubject<any>(null);
  sirData = this.sirIdData.asObservable();

  constructor(private apiService: ApiService, private matSnackBar: MatSnackBar) { }
  INGREDIENT_PROPERTY_VALUES: any = null;
  COMMENTS: any = null;
  // GetData

  transposeSubstanceBaseData(reqId) {
    this.sirIdData.next(reqId);
  }
  /* istanbul ignore next */
  getData(url): Observable<any> {
    return this.apiService.get(url).pipe(timeout(300000));
  }
  /* istanbul ignore next */
  putData(url, body): Observable<any> {
    return this.apiService.put(url, body).pipe(timeout(300000));
  }
  /* istanbul ignore next */
  postData(url, body): Observable<any> {
    return this.apiService.post(url, body).pipe(timeout(300000));
  }
  /* istanbul ignore next */
  deleteData(url): Observable<any> {
    return this.apiService.delete(url).pipe(timeout(300000));
  }
  /* istanbul ignore next */
  getConflictingDataSearchResults(value) {
    const url = environment.APIFAsTRule + '/rules/substance/' + value + '/PURECODE/cfrequired/n';
    return this.apiService.get(url).pipe(timeout(300000));
  }
  /* istanbul ignore next */
  getFallBackMktData(marketName) {
    const url = environment.APIFAsT + '/markets/' + marketName + '/assessmentOrder';
    return this.apiService.get(url).pipe(timeout(300000));
  }
/* istanbul ignore next */
  getHazardClassification(url): Observable<any> {
    return this.apiService.get(url).pipe(timeout(300000));
  }
/* istanbul ignore next */
  getAndPostComments(body): Observable<any> {
    const url = `${environment.APIFAsT}/reportcomments`;
    return this.apiService.post(url, body).pipe(timeout(300000));
  }
  /* istanbul ignore next */
  getCucParentProperties() {
    const url = environment.APIFAsTRule + '/Properties/parentProperties/0';
    return this.apiService.get(url).pipe(timeout(300000));
  }
  /* istanbul ignore next */
  getCucChildProperties(parentPropertyId) {
    const url = environment.APIFAsTRule + '/Properties/childProperties/' + parentPropertyId;
    return this.apiService.get(url).pipe(timeout(300000));
  }
/* istanbul ignore next */
  completeReport(body): Observable<any> {
    const url = `${environment.APIFAsT}/updateassessmentcompletedstatus`;
    return this.apiService.put(url, body).pipe(timeout(300000));
  }
/* istanbul ignore next */
  sendEmailNotification(body): Observable<any> {
    const url = `${environment.APIFAsT}/responseemail`;
    return this.apiService.post(url, body).pipe(timeout(300000));
  }
  /* istanbul ignore next */
  downloadFile(url) {
    return this.apiService.getFileData(url).pipe(timeout(300000));
  }
  /* istanbul ignore next */
  modifyObjectForTableMapping(prepareRuleTableData) {
    prepareRuleTableData.forEach(data => {
      data.isAllergen = data.isAllergen === 'Y' ? 'Y' : '';
      data.allowedFuncFlag = data.ruleTypeID === 14 ? 'Y' : 'N';
      data.warningText = (data.warningText !== null) ? data.warningText : data.warningStatement;
      data.functionName = data.functionName ? data.functionName : 'Any';
      if (data.cfDetails !== null) {
        data.cfSubstanceExpressedAs = data.cfDetails.component;
        data.cf = data.cfDetails.cf;

      }
      if (data.ruleClassification === 'Substance Based Rules') {
        if (data.ruleTypeID === 30) {
          data.propertySource = data.propertyName;
          data.propertyName = '';
        }
      }
      if (data.ruleClassification === 'Property Based Rules') {
        if (data.ruleTypeID === 7 || data.ruleTypeID === 8 || data.ruleTypeID === 9) {
          data.propertySource = 'CUC';
        }
      }
      if (data.ruleClassification === 'Substance Group Rules') {
        data.substanceGroup = data.groupName;
      }

    });
    return prepareRuleTableData;
  }
/* istanbul ignore next */
  sendRulesEmailNotification(payLoad: any) {
    payLoad.UserRequests = payLoad.UserRequests.reduce(
      (accumulator, current) => {
        if (!accumulator.some(x => x.userId === current.userId)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []
    );
    this.sendEmailNotification(payLoad).subscribe((data) => {
      if (data.message === 'Success') {
        this.matSnackBar.open(`Email notification sent successfully`, 'close', {
          duration: 5000,
          panelClass: ['success']
        });
      }
    }, (error) => {
      if (error.status !== 200) {
        console.log('error');
      }
    });
  }
   /* istanbul ignore next */
   handleSpecialCharactersForHyperlinks(text) {
    if (text !== null && text !== '') {
      return text.replace(/&nbsp;.*?/gi, ' ').replace(/&amp;/g, '&').replace(/<strong>/gi, '').replace(/<\/strong>/gi, '').replace(/<i>/gi, '').replace(/<\/i>/gi, '');
    } else {
      return '';
    }
  }
   /* istanbul ignore next */
  handleSpecialCharacters(text) {
    if (text !== null && text !== '' && text !== undefined) {
      return text.replace(/<.*?>/g, ' ').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, ' ').replace(/&amp;/g, '&');
    } else {
      return '';
    }
  }
  /* istanbul ignore next */
  hyperLinksToSheet(comment) {

    const regex = /<a[^>]*href=["']([^"']*)["']/gm;
    const aregText = /<a(.*?)<\/a>/gm;
    const hyperLinksData = [];
    let m;
    let getraAncharText;
    const totalAnchor = [1, 2, 3, 4, 5, 6, 7];
    const textbetweenTags = [];
    if (comment !== null || comment !== '') {
      for (const val of totalAnchor) {
        getraAncharText = aregText.exec(comment);
        if (getraAncharText == null) {
          break;
        }
        if (getraAncharText.index === aregText.lastIndex) {
          aregText.lastIndex++;
        }
        getraAncharText.forEach((amatch, agroupIndex) => {
          if (agroupIndex === 1) {
            const aindex = amatch.indexOf('>');
            const matchLength = amatch.length;
            textbetweenTags.push(amatch.substring(aindex + 1, matchLength));
          }
        });
      }
      let index = 0;
      for (const val of totalAnchor) {
        m = regex.exec(comment);
        if (m == null) {
          break;
        }
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match, groupIndex) => {
          if (groupIndex === 1) {
            hyperLinksData.push(
              {
                text: this.handleSpecialCharactersForHyperlinks(textbetweenTags[index]),
                hyperlink: match,
                tooltip: match,
                color: 'red'
              });
            index = index + 1;
          }
        });
      }
    }
    return hyperLinksData;
  }
  // setRolesData(val) {
  //   this.sirData.next(val);
  // }
}
