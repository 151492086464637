import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdministrationComponent } from './administration.component';
import { FunctionMasterComponent } from './function-master/function-master.component';
import { ComponentMasterComponent } from './component-master/component-master.component';
import { RegulatoryGroupMasterComponent } from './regulatory-group-master/regulatory-group-master.component';
import { PropertyMasterComponent } from './property-master/property-master.component';

const routes: Routes = [
  {
    path: '',
    component: AdministrationComponent,
    children: [
      {
        path: 'component-master',
        component: ComponentMasterComponent
      },
      {
        path: 'function-master',
        component: FunctionMasterComponent
      },
      {
        path: 'regulatory-group-master',
        component: RegulatoryGroupMasterComponent
      },
      {
        path: 'property-master',
        component: PropertyMasterComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule { }
