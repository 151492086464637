import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-create-insert-rule',
  templateUrl: './create-insert-rule.component.html',
  styleUrls: ['./create-insert-rule.component.scss']
})
export class CreateInsertRuleComponent implements OnInit {
  componentNameTab = 'CreateInsertRule';
  public steps: string[];
  public selectedStep = 0;
  loading = false;
  constructor(private routersvc: Router, private route: ActivatedRoute) { }
  /* istanbul ignore next */
  ngOnInit() {
    this.steps = this.route.snapshot.routeConfig.children.map(child => child.path);
  }
  /* istanbul ignore next */
  selectionChanged(event: any) {
    this.selectedStep = event.selectedIndex;
    this.routersvc.navigate([this.steps[this.selectedStep]], { relativeTo: this.route });
  }
}
