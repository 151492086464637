import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { AssessmentRequestService } from 'src/app/assess-requests/assess-requests.service';
import { groupDataModel } from 'src/app/common/models/GroupDataModel';
import { groupRulesDetailsModel } from 'src/app/common/models/GroupRulesDetailsModel';
import { pureRulesDetailsModel, ruleFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { DataService } from 'src/app/common/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-group-rules',
  templateUrl: './edit-group-rules.component.html',
  styleUrls: ['./edit-group-rules.component.scss']
})
export class EditGroupRulesComponent implements OnInit {
  loading = false;
  loadingMsg = 'Fetching SIR details..';
  step = 0;
  marketsToSelect = [];
  editRuleFlow = false;
  viewRuleFlow = false;
  selectedMarket;
  parentRpcParallelRule;
  rpcsForEditRule = [];
  cascadeRPC = true;
  ruleTypeIdVal;
  rpcsForEditRulestatus = [];
  groupData = {
    substanceData: []
  };
  confGroupData = {
    groupId: '',
    groupName: '',
    groupType: '',
    substanceData: []
  };
  constructor(public dataServiceObj: DataService, private assessRequestRa: AssessmentRequestService) { }
  /* istanbul ignore next */
  ngOnInit() {
    this.editRuleFlow = false;
    this.viewRuleFlow = false;
    this.dataServiceObj.getruleToEdit().subscribe((value) => {
      if (value !== null) {
        this.editRuleFlow = true;
        this.ruleTypeIdVal = value.ruleTypeId;
        if (value.markets) {
          this.selectedMarket = value.markets[0].marketName;
          recipeModel.marketDetails = value.markets[0];
          this.marketsToSelect.push(recipeModel.marketDetails);
          pureRulesDetailsModel.CascadeRpc = 'Y';
        }
        this.parentRpcParallelRule = value.rpcid;
        for (const val of value.rpcs) {
          this.rpcsForEditRule.push(val.rpcFullText);
          this.rpcsForEditRulestatus.push(val.mrpcStatus);
        }
        this.getSirDetailsBasedOnId(value);
        groupRulesDetailsModel.CascadeRpc = 'Y';
      } else {
        this.editRuleFlow = false;
      }
    });
    this.dataServiceObj.expandedPanel.subscribe((result) => { // setting the expanded panel
      if (result) {
        this.setStep(Number(result));
      }
    });
    if (ruleFlags.viewRule) {
      this.editRuleFlow = false;
      this.viewRuleFlow = true;
    } else if (ruleFlags.editRule) {
      this.editRuleFlow = true;
      this.viewRuleFlow = false;
    }
  }
  setStep(index: number) {
    this.step = index;
  }
  /* istanbul ignore next */
  getSirDetailsBasedOnId(value) {
    // tslint:disable-next-line: prefer-const
    let confSirIds = []; let sirIds = [];
    this.loading = true;
    sirIds = value.substanceGroup.substanceIds;
    if (value.ruleTypeID === 43) {
      this.confGroupData = {
        groupId: value.conflictingSubstanceGroup.groupId,
        groupName: value.conflictingSubstanceGroup.groupName,
        groupType: value.conflictingSubstanceGroup.groupType,
        substanceData: []
      };
      confSirIds = value.conflictingSubstanceGroup.substanceIds;
      sirIds = sirIds.concat(confSirIds);
    }
    let cfdata = null;
    this.loadingMsg = 'Fetching data from SIR';
    let distinctSirIds = sirIds.filter((n, i) => sirIds.indexOf(n) === i);
    distinctSirIds = _.without(distinctSirIds, null);
    // to get sir details for substances in group
    // tslint:disable-next-line: no-shadowed-variable
    const apiurlforsir = environment.APIFAsTRule + '/rules/substances/SIRID/cfRequired/y';
    let substanceGroupData: any;
    this.assessRequestRa.postData(apiurlforsir, distinctSirIds).subscribe((response) => {
      if (response) {
        this.loading = false;
        substanceGroupData = {
          groupId: value.substanceGroup.groupId,
          groupName: value.substanceGroup.groupName,
          groupType: value.substanceGroup.groupType,
          substanceData: [],
          conflictingGroupData: [],
        };
        response.forEach(groupsub => {
          if (groupsub.cfDetails != null && groupsub.cfDetails.length > 0) {
            cfdata = groupsub.cfDetails[0].cfDisplayText;
          }
          const subData = {
            sirId: groupsub.sirId,
            purecode: groupsub.pureCode,
            substanceName: this.changeToTitleCase(groupsub.substanceName),
            cas: groupsub.cas,
            parentCAS: groupsub.parentCAS,
            inciName: groupsub.inciName,
            ins: groupsub.ins,
            cfDetails: groupsub.cfDetails,
            cfdata: cfdata,
            einecs: groupsub.einecs,
            synonyms: groupsub.substanceName,
            pureDescription: groupsub.pureDescription
          };
          substanceGroupData.substanceData.push(subData);
        });
        this.groupData = substanceGroupData;
        Object.assign(groupDataModel, substanceGroupData);
        if (value.ruleTypeID === 43) {
          this.confGroupData.substanceData = this.groupData.substanceData.filter((value1) =>
            value.conflictingSubstanceGroup.substanceIds.find((val) => value1.sirId === val));
          substanceGroupData.substanceData = this.groupData.substanceData.filter(o => value.substanceGroup.substanceIds.find(o2 => o.sirId === o2));
          substanceGroupData.conflictingGroupData.push(this.confGroupData);
        }
        this.dataServiceObj.setSelectedGroup(substanceGroupData);
        recipeModel.division = value.substanceGroup.updFullText;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  changeToTitleCase(word: string) {
    if (!word) {
      return word;
    }
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

}
