export const COMPOSITION_COLUMNS = [
  { field: 'specID', header: 'Specification', width: '100px' },
  { field: 'specDescription', header: 'Spec. Description', width: '130px' },
  { field: 'specQuantity', header: 'Spec. Quantity', width: '100px' },
  { field: 'specUoM', header: 'UoM', width: '60px' },
  { field: 'ingredientPath', header: 'Ingredient Path', width: '180px' },
  { field: 'ingDescription', header: 'Ing Description', width: '150px' },
  { field: 'ingQuantity', header: 'Ing Quantity', width: '100px' },
  { field: 'pureCode', header: 'PURE Code', width: '90px' },
  { field: 'pureDescription', header: 'PURE Description', width: '130px' },
  { field: 'recipeQuantityToDisplay', header: 'PURE Quantity', width: '115px' },
  { field: 'recipeUoM', header: 'PURE UoM', width: '90px' },
  { field: 'originalRecipeQuantity', header: 'Qty Per Component', width: '140px' },
  { field: 'substanceFunctionInput', header: 'Function', width: '130px' },
  { field: 'originatorComment', header: 'R&D Comment', width: '130px' }
];
export const PROJECT_COLUMNS = [
  { field: 'MarketID', header: 'Market', width: '90px' },
  { field: 'ProjectName', header: 'Project Name', width: '100px' },
  { field: 'ProjectTypeDesc', header: 'Project Type', width: '100px' },
  { field: 'ProjectDescription', header: 'Project Description', width: '150px' },
  { field: 'BrandDesc', header: 'Brand', width: '70px' },
  { field: 'ManufactureCountry', header: 'Manufacturing Country', width: '140px' },
  { field: 'ManufactureSiteCd', header: 'Manufacturing Sites', width: '130px' },
  { field: 'LaunchTimeLine', header: 'Launch TimeLine', width: '100px' },
  { field: 'Comments', header: 'Comments', width: '100px' },
  { field: 'Actions', header: 'Actions', width: '60px' }
];
export const FRAGRANCE_COLUMNS = [
  { field: 'ingredientCode', header: 'Ingredient Code', width: '90px' },
  { field: 'ingredientDescription', header: 'Ingredient Desc', width: '120px' },
  { field: 'specType', header: 'Spec Type', width: '100px' },
  { field: 'IFRA', header: 'IFRA Category', width: '100px' },
  { field: 'Amendment', header: 'Amendment Number', width: '120px' },
  { field: 'IFRA Max', header: 'IFRA Max (%)', width: '90px' },
  { field: 'FRAG_R&DCategory', header: 'Formulation Category', width: '140px' },
  { field: 'FRAG_Formulation Type', header: 'Formulation Type', width: '110px' },
  { field: 'FRAG_Formulation Class', header: 'Formulation Class', width: '120px' },
  { field: 'FRAG_Formulation Sub-Class', header: 'Formulation Sub-Class', width: '120px' }
];
export const FRAGRANCE_REGIONAL_COLUMNS2 = [
  { field: 'ingredientCode', header: 'Ingredient Code', width: '120px' },
  { field: 'ingredientDescription', header: 'Ingredient Desc', width: '150px' },
  { field: 'specType', header: 'Spec Type', width: '130px' },
  { field: 'MARKET', header: 'Market', width: '100px' },
  { field: 'Product Type', header: 'Product Type', width: '80px' },
  { field: 'Max use (%)', header: 'Max use (%)', width: '70px' }
];
export const FRAGRANCE_REGIONAL_COLUMNS3 = [
  { field: 'ingredientCode', header: 'Ingredient Code', width: '120px' },
  { field: 'ingredientDescription', header: 'Ingredient Desc', width: '150px' },
  { field: 'specType', header: 'Spec Type', width: '130px' },
  { field: 'MARKET', header: 'Market', width: '100px' },
  { field: 'ingredient CAS Number', header: 'Ingredient CAS Number', width: '80px' },
  { field: 'Present', header: '% Present', width: '70px' }
];
export const COMPOSITION_COLUMNS_NO = [
  { field: 'specID', header: 'Specification', width: '100px' },
  { field: 'specDescription', header: 'Spec. Description', width: '130px' },
  { field: 'specQuantity', header: 'Spec. Quantity', width: '100px' },
  { field: 'specUoM', header: 'UoM', width: '60px' },
  { field: 'ingredientPath', header: 'Ingredient Path', width: '180px' },
  { field: 'ingDescription', header: 'Ing Description', width: '150px' },
  { field: 'ingQuantity', header: 'Ing Quantity', width: '100px' },
  { field: 'pureCode', header: 'PURE Code', width: '90px' },
  { field: 'pureDescription', header: 'PURE Description', width: '130px' },
  { field: 'recipeQuantityToDisplay', header: 'PURE Quantity', width: '115px' },
  { field: 'recipeUoM', header: 'PURE UoM', width: '90px' },
  { field: 'substanceFunctionInput', header: 'Function', width: '130px' },
  { field: 'originatorComment', header: 'R&D Comment', width: '130px' }
];

export const COMPOSITION_COLUMNS_EDIT = [
  { field: 'specID', header: 'Specification', width: '100px' },
  { field: 'specDescription', header: 'Spec. Description', width: '130px' },
  { field: 'specQuantity', header: 'Spec. Quantity', width: '100px' },
  { field: 'specUoM', header: 'UoM', width: '60px' },
  { field: 'ingredientPath', header: 'Ingredient Path', width: '180px' },
  { field: 'ingDescription', header: 'Ing Description', width: '150px' },
  { field: 'ingQuantity', header: 'Ing Quantity', width: '100px' },
  { field: 'pureCode', header: 'PURE Code', width: '90px' },
  { field: 'pureDescription', header: 'PURE Description', width: '130px' },
  { field: 'recipeQuantityToDisplay', header: 'PURE Quantity', width: '115px' },
  { field: 'recipeUoM', header: 'PURE UoM', width: '90px' },
  { field: 'originalRecipeQuantity', header: 'Qty Per Component', width: '140px' },
  { field: 'substanceFunctionInput', header: 'Function', width: '130px' },
  { field: 'raComment', header: 'RA Comment', width: '130px' },
  { field: 'originatorComment', header: 'R&D Comment', width: '130px' },
];

export const COMPOSITION_COLUMNS_EDIT_NO = [
  { field: 'specID', header: 'Specification', width: '100px' },
  { field: 'specDescription', header: 'Spec. Description', width: '130px' },
  { field: 'specQuantity', header: 'Spec. Quantity', width: '100px' },
  { field: 'specUoM', header: 'UoM', width: '60px' },
  { field: 'ingredientPath', header: 'Ingredient Path', width: '180px' },
  { field: 'ingDescription', header: 'Ing Description', width: '150px' },
  { field: 'ingQuantity', header: 'Ing Quantity', width: '100px' },
  { field: 'pureCode', header: 'PURE Code', width: '90px' },
  { field: 'pureDescription', header: 'PURE Description', width: '130px' },
  { field: 'recipeQuantityToDisplay', header: 'PURE Quantity', width: '115px' },
  { field: 'recipeUoM', header: 'PURE UoM', width: '90px' },
  { field: 'substanceFunctionInput', header: 'Function', width: '130px' },
  { field: 'raComment', header: 'RA Comment', width: '130px' },
  { field: 'originatorComment', header: 'R&D Comment', width: '130px' },
];

export const VERIFY_COMPOSITION_COLUMNS = [
  { field: 'specID', header: 'Specification', width: '100px' },
  { field: 'specDescription', header: 'Specification Des', width: '165px' },
  // { field: 'component', header: 'Component', width: '120px' },
  { field: 'specQuantity', header: 'Specification Qty', width: '150px' }, // 4
  { field: 'specUoM', header: 'UoM', width: '60px' },
  { field: 'ingredientPath', header: 'Ingredient Path', width: '180px' },
  { field: 'ingDescription', header: 'Ing Description', width: '150px' },
  { field: 'ingQuantity', header: 'Ing Quantity', width: '100px' }, // 8
  { field: 'pureCode', header: 'PURE Code', width: '90px' },
  { field: 'pureDescription', header: 'PURE Des', width: '130px' },
  { field: 'originalRecipeQuantity', header: 'PURE Qty', width: '115px' }, // 11
  { field: 'recipeUoM', header: 'PURE UoM', width: '90px' },
  {
    field: 'recipeQuantityToDisplay',
    header: 'Qty Per Component',
    width: '140px',
  },
  { field: 'substanceFunctionInput', header: 'Function', width: '130px' },
  { field: 'raComment', header: 'RA Comment', width: '130px' },
  { field: 'originatorComment', header: 'R&D Comment', width: '130px' },
];
export const RELATED_RULE_TABLE_COLUMNS = [
  { field: 'ruleId', header: 'Rule ID', width: '100px' },
  { field: 'ruleVerionToDisplay', header: 'Rule Version', width: '60px' },
  { field: 'ruleStatus', header: 'Rule Status', width: '100px' },
  { field: 'pureCode', header: 'PURE Code', width: '100px' },
  { field: 'pureDescription', header: 'PURE Description', width: '125px' },
  { field: 'substanceID', header: 'SIR ID', width: '100px' },
  { field: 'substanceName', header: 'Substance Name', width: '120px' },
  { field: 'inciName', header: 'INCI', width: '75px' },
  {
    field: 'cfSubstanceExpressedAs',
    header: 'CF Substance Expressed as',
    width: '100px',
  },
  { field: 'cf', header: 'CF', width: '100px' },
  { field: 'isAllergen', header: 'Allergen (Y/N)', width: '100px' },
  { field: 'hazardClass', header: 'Hazard Classification', width: '100px' },

  { field: 'market', header: 'Market	', width: '100px' },
  { field: 'rpc', header: 'RPC Path', width: '100px' },
  { field: 'ruleClassification', header: 'Rule Category', width: '125px' },
  { field: 'ruleType', header: 'Rule Type', width: '100px' },
  {
    field: 'allowedFuncFlag',
    header: 'Allowed Function (Y/N)',
    width: '100px',
  },
  { field: 'functionName', header: 'Function', width: '100px' },
  { field: 'equalityCondition', header: 'Equality condition', width: '150px' },
  { field: 'minLimit', header: 'Minimum value', width: '80px' },
  // { field: 'equalityConditionForMax', header: 'Equality condition for maximum', width: '150px' },
  { field: 'maxLimit', header: 'Maximum value', width: '80px' },
  { field: 'uoM', header: 'Unit of Measure', width: '80px' },
  { field: 'substanceGroup', header: 'Substance Group', width: '80px' },

  // { field: 'equalityConditionForGroupMinx', header: 'Equality condition for Group Min', width: '150px' },
  // { field: 'groupMinValue', header: 'Group Minimum value', width: '100px' },
  // { field: 'equalityConditionForGroupMaxx', header: 'Equality condition for Group Max', width: '150px' },
  // { field: 'groupMaxValue', header: 'Group Maximum value', width: '100px' },
  // { field: 'groupUnitOfMeasure', header: 'Unit of Measure', width: '80px' },
  // { field: 'conflictingRegulatoryGroup', header: 'Conflicting Regulatory Group', width: '150px' },
  { field: 'isExempted', header: 'Exempted Y/N', width: '100px' },
  {
    field: 'isDosageApplicable',
    header: 'Dosage Applicable (Y/N)',
    width: '120px',
  },
  {
    field: 'isServingSizeApplicable',
    header: 'Serving Size Applicable (Y/N)',
    width: '140px',
  },
  {
    field: 'conflictingSubstance',
    header: 'Conflicting Substance',
    width: '100px',
  },
  {
    field: 'combinationSubstance',
    header: 'Combination Substance',
    width: '100px',
  },
  {
    field: 'combinationRatioToDisplay',
    header: 'Combination Ratio',
    width: '100px',
  },
  {
    field: 'specificConditionText',
    header: 'Conditional Requirements',
    width: '100px',
  },

  { field: 'claimText', header: 'Claims', width: '100px' },
  { field: 'phasedOutDate', header: 'Phasing out Date', width: '80px' },
  { field: 'warningText', header: 'Warnings', width: '100px' },
  {
    field: 'otherRestrictionsAndRequirements',
    header: 'Other Requirements and Restrictions',
    width: '150px',
  },
  { field: 'propertySource', header: 'Property Source', width: '80px' },
  { field: 'propertyClass', header: 'Property', width: '100px' },
  { field: 'propertyName', header: 'Sub property', width: '100px' },
  // ing property
  { field: 'ingPropertyType', header: 'ING Rule Type', width: '100px' },
  { field: 'ingPropertyName', header: 'ING Property Name', width: '100px' },
  { field: 'ruleSource', header: 'Rule Source', width: '100px' },
  { field: 'validityStartDate', header: 'Valid From', width: '100px' },
  { field: 'validityDate', header: 'Valid To', width: '100px' },
  { field: 'yearAdopted', header: 'Year Adopted', width: '100px' },
  { field: 'cas', header: 'CAS #', width: '100px' },
  { field: 'parentCAS', header: 'Parent CAS #', width: '100px' },
  { field: 'einecs', header: 'EINECS#', width: '100px' },

  { field: 'eNumber', header: 'E #', width: '100px' },
  { field: 'ins', header: 'INS #', width: '100px' },
  { field: 'preparationMethod', header: 'Preparation Method	', width: '100px' },
  { field: 'intendedUse', header: 'Intended Use', width: '100px' },
  { field: 'createdByUserDisplayName', header: 'Created By', width: '100px' },
  { field: 'createdDate', header: 'Created Date', width: '100px' },
  { field: 'modifiedByUserDisplayName', header: 'Modified By', width: '100px' },
  { field: 'modifiedDate', header: 'Modified Date', width: '100px' },
];
export const EXECUTE_RULE_TABLE_COLUMNS = [
  { field: 'ruleId', header: 'Rule ID', width: '100px' },
  { field: 'ruleVerionToDisplay', header: 'Rule Version', width: '60px' },
  { field: 'pureCode', header: 'PURE Code', width: '100px' },
  { field: 'pureDescription', header: 'PURE Description', width: '125px' },
  { field: 'substanceID', header: 'SIR ID', width: '100px' },
  { field: 'substanceName', header: 'Substance Name', width: '120px' },
  { field: 'inciName', header: 'INCI', width: '75px' },
  {
    field: 'cfSubstanceExpressedAs',
    header: 'CF Substance Expressed as',
    width: '100px',
  },
  { field: 'cf', header: 'CF', width: '100px' },
  { field: 'isAllergen', header: 'Allergen (Y/N)', width: '100px' },
  { field: 'hazardClass', header: 'Hazard Classification', width: '100px' },

  { field: 'market', header: 'Market	', width: '100px' },
  { field: 'rpc', header: 'RPC Path', width: '100px' },
  { field: 'ruleClassification', header: 'Rule Category', width: '125px' },
  { field: 'ruleType', header: 'Rule Type', width: '100px' },
  { field: 'ruleStatus', header: 'Rule Status', width: '100px' },
  {
    field: 'allowedFuncFlag',
    header: 'Allowed Function (Y/N)',
    width: '100px',
  },
  { field: 'functionName', header: 'Function', width: '100px' },
  { field: 'equalityCondition', header: 'Equality condition', width: '150px' },
  { field: 'minLimit', header: 'Minimum value', width: '80px' },
  // { field: 'equalityConditionForMax', header: 'Equality condition for maximum', width: '150px' },
  { field: 'maxLimit', header: 'Maximum value', width: '80px' },
  { field: 'uoM', header: 'Unit of Measure', width: '80px' },
  { field: 'substanceGroup', header: 'Substance Group', width: '80px' },

  // { field: 'equalityConditionForGroupMinx', header: 'Equality condition for Group Min', width: '150px' },
  // { field: 'groupMinValue', header: 'Group Minimum value', width: '100px' },
  // { field: 'equalityConditionForGroupMaxx', header: 'Equality condition for Group Max', width: '150px' },
  // { field: 'groupMaxValue', header: 'Group Maximum value', width: '100px' },
  // { field: 'groupUnitOfMeasure', header: 'Unit of Measure', width: '80px' },
  // { field: 'conflictingRegulatoryGroup', header: 'Conflicting Regulatory Group', width: '150px' },
  { field: 'isExempted', header: 'Exempted Y/N', width: '100px' },
  {
    field: 'isDosageApplicable',
    header: 'Dosage Applicable (Y/N)',
    width: '120px',
  },
  {
    field: 'isServingSizeApplicable',
    header: 'Serving Size Applicable (Y/N)',
    width: '140px',
  },
  {
    field: 'conflictingSubstance',
    header: 'Conflicting Substance',
    width: '100px',
  },
  {
    field: 'combinationSubstance',
    header: 'Combination Substance',
    width: '100px',
  },
  {
    field: 'combinationRatioToDisplay',
    header: 'Combination Ratio',
    width: '100px',
  },
  {
    field: 'specificConditionText',
    header: 'Conditional Requirements',
    width: '100px',
  },

  { field: 'claimText', header: 'Claims', width: '100px' },
  { field: 'phasedOutDate', header: 'Phasing out Date', width: '80px' },
  { field: 'warningText', header: 'Warnings', width: '100px' },
  {
    field: 'otherRestrictionsAndRequirements',
    header: 'Other Requirements and Restrictions',
    width: '150px',
  },
  { field: 'propertySource', header: 'Property Source', width: '80px' },
  { field: 'propertyClass', header: 'Property', width: '100px' },
  { field: 'propertyName', header: 'Sub property', width: '100px' },
  // ing property
  { field: 'ingPropertyType', header: 'ING Rule Type', width: '100px' },
  { field: 'ingPropertyName', header: 'ING Property Name', width: '100px' },
  { field: 'ruleSource', header: 'Rule Source', width: '100px' },
  { field: 'validityStartDate', header: 'Valid From', width: '100px' },
  { field: 'validityDate', header: 'Valid To', width: '100px' },
  { field: 'yearAdopted', header: 'Year Adopted', width: '100px' },
  { field: 'cas', header: 'CAS #', width: '100px' },
  { field: 'parentCAS', header: 'ParentCAS #', width: '100px' },
  { field: 'einecs', header: 'EINECS#', width: '100px' },

  { field: 'eNumber', header: 'E #', width: '100px' },
  { field: 'ins', header: 'INS #', width: '100px' },
  { field: 'preparationMethod', header: 'Preparation Method	', width: '100px' },
  { field: 'intendedUse', header: 'Intended Use', width: '100px' },
  { field: 'createdByUserDisplayName', header: 'Created By', width: '100px' },
  { field: 'createdDate', header: 'Created Date', width: '100px' },
  { field: 'modifiedByUserDisplayName', header: 'Modified By', width: '100px' },
  { field: 'modifiedDate', header: 'Modified Date', width: '100px' },
];
export const EXECUTE_RULE_TABLE_COLUMNS_EXCEL = [
  { field: 'ruleId', header: 'Rule ID', width: '100px' },
  { field: 'ruleVerionToDisplay', header: 'Rule Version', width: '60px' },
  { field: 'pureCode', header: 'PURE Code', width: '100px' },
  { field: 'pureDescription', header: 'PURE Description', width: '125px' },
  { field: 'substanceID', header: 'SIR ID', width: '100px' },
  { field: 'substanceName', header: 'Substance Name', width: '120px' },
  { field: 'inciName', header: 'INCI', width: '75px' },
  {
    field: 'cfSubstanceExpressedAs',
    header: 'CF Substance Expressed as',
    width: '100px',
  },
  { field: 'cf', header: 'CF', width: '100px' },
  { field: 'isAllergen', header: 'Allergen (Y/N)', width: '100px' },
  { field: 'hazardClass', header: 'Hazard Classification', width: '100px' },

  { field: 'market', header: 'Market	', width: '100px' },
  { field: 'rpc', header: 'RPC Path', width: '100px' },
  { field: 'ruleClassification', header: 'Rule Category', width: '125px' },
  { field: 'ruleType', header: 'Rule Type', width: '100px' },
  { field: 'ruleStatus', header: 'Rule Status', width: '100px' },
  {
    field: 'allowedFuncFlag',
    header: 'Allowed Function (Y/N)',
    width: '100px',
  },
  { field: 'functionName', header: 'Function', width: '100px' },
  { field: 'equalityCondition', header: 'Equality condition', width: '150px' },
  { field: 'minLimit', header: 'Minimum value', width: '80px' },
  // { field: 'equalityConditionForMax', header: 'Equality condition for maximum', width: '150px' },
  { field: 'maxLimit', header: 'Maximum value', width: '80px' },
  { field: 'uoM', header: 'Unit of Measure', width: '80px' },
  { field: 'substanceGroup', header: 'Substance Group', width: '80px' },

  // { field: 'equalityConditionForGroupMinx', header: 'Equality condition for Group Min', width: '150px' },
  // { field: 'groupMinValue', header: 'Group Minimum value', width: '100px' },
  // { field: 'equalityConditionForGroupMaxx', header: 'Equality condition for Group Max', width: '150px' },
  // { field: 'groupMaxValue', header: 'Group Maximum value', width: '100px' },
  // { field: 'groupUnitOfMeasure', header: 'Unit of Measure', width: '80px' },
  // { field: 'conflictingRegulatoryGroup', header: 'Conflicting Regulatory Group', width: '150px' },
  { field: 'isExempted', header: 'Exempted Y/N', width: '100px' },
  {
    field: 'isDosageApplicable',
    header: 'Dosage Applicable (Y/N)',
    width: '120px',
  },
  {
    field: 'isServingSizeApplicable',
    header: 'Serving Size Applicable (Y/N)',
    width: '140px',
  },
  {
    field: 'conflictingSubstance',
    header: 'Conflicting Substance',
    width: '100px',
  },
  {
    field: 'combinationSubstance',
    header: 'Combination Substance',
    width: '100px',
  },
  {
    field: 'combinationRatioToDisplay',
    header: 'Combination Ratio',
    width: '100px',
  },
  {
    field: 'specificConditionText',
    header: 'Conditional Requirements',
    width: '100px',
  },

  { field: 'claimText', header: 'Claims', width: '100px' },
  { field: 'phasedOutDate', header: 'Phasing out Date', width: '80px' },
  { field: 'warningTextStrippedExcel', header: 'Warnings', width: '100px' },
  {
    field: 'otherRestrictionsAndRequirementsStrippedExcel',
    header: 'Other Requirements and Restrictions',
    width: '150px',
  },
  { field: 'propertySource', header: 'Property Source', width: '80px' },
  { field: 'propertyClass', header: 'Property', width: '100px' },
  { field: 'propertyName', header: 'Sub property', width: '100px' },
  // ing property
  { field: 'ingPropertyType', header: 'ING Rule Type', width: '100px' },
  { field: 'ingPropertyName', header: 'ING Property Name', width: '100px' },
  { field: 'ruleSource', header: 'Rule Source', width: '100px' },
  { field: 'validityStartDate', header: 'Valid From', width: '100px' },
  { field: 'validityDate', header: 'Valid To', width: '100px' },
  { field: 'yearAdopted', header: 'Year Adopted', width: '100px' },
  { field: 'cas', header: 'CAS #', width: '100px' },
  { field: 'parentCAS', header: 'Parent CAS #', width: '100px' },
  { field: 'einecs', header: 'EINECS#', width: '100px' },

  { field: 'eNumber', header: 'E #', width: '100px' },
  { field: 'ins', header: 'INS #', width: '100px' },
  { field: 'preparationMethod', header: 'Preparation Method	', width: '100px' },
  { field: 'intendedUse', header: 'Intended Use', width: '100px' },
  { field: 'createdByUserDisplayName', header: 'Created By', width: '100px' },
  { field: 'createdDate', header: 'Created Date', width: '100px' },
  { field: 'modifiedByUserDisplayName', header: 'Modified By', width: '100px' },
  { field: 'modifiedDate', header: 'Modified Date', width: '100px' },
  { field: 'warningText1', header: 'Warnings Link 1', width: 25 },
  { field: 'warningText2', header: 'Warnings Link 2', width: 25 },
  { field: 'warningText3', header: 'Warnings Link 3', width: 25 },
  { field: 'warningText4', header: 'Warnings Link 4', width: 25 },
  { field: 'warningText5', header: 'Warnings Link 5', width: 25 },
  { field: 'warningText6', header: 'Warnings Link 6', width: 25 },
  { field: 'warningText7', header: 'Warnings Link 7', width: 25 },
  { field: 'ORR1', header: 'Other Requirements and Restrictions Link 1', width: 45 },
  { field: 'ORR2', header: 'Other Requirements and Restrictions Link 2', width: 45 },
  { field: 'ORR3', header: 'Other Requirements and Restrictions Link 3', width: 45 },
  { field: 'ORR4', header: 'Other Requirements and Restrictions Link 4', width: 45 },
  { field: 'ORR5', header: 'Other Requirements and Restrictions Link 5', width: 45 },
  { field: 'ORR6', header: 'Other Requirements and Restrictions Link 6', width: 45 },
  { field: 'ORR7', header: 'Other Requirements and Restrictions Link 7', width: 45 },
];

export const RULE_TABLE_COLUMNS = [
  { field: 'ruleId', header: 'Rule ID', width: '100px' },
  { field: 'pureCode', header: 'PURE Code', width: '100px' },
  { field: 'pureDescription', header: 'PURE Description', width: '125px' },
  { field: 'inci', header: 'INCI', width: '75px' },
  {
    field: 'cfSubstanceExpressedAs',
    header: 'CF Substance Expressed as',
    width: '100px',
  },
  { field: 'cf', header: 'CF', width: '100px' },
  { field: 'allergenFlag', header: 'Allergen (Y/N)', width: '100px' },
  {
    field: 'hazardClassification',
    header: 'Hazard Classification',
    width: '100px',
  },

  { field: 'market', header: 'Market	', width: '100px' },
  { field: 'rpcPath', header: 'RPC Path', width: '100px' },
  { field: 'ruleType', header: 'Rule Type', width: '100px' },
  {
    field: 'allowedFuncFlag',
    header: 'Allowed Function (Y/N)',
    width: '100px',
  },
  { field: 'ruleFunctionName', header: 'Function', width: '100px' },
  {
    field: 'equalityConditionForMin',
    header: 'Equality condition for minimum',
    width: '150px',
  },
  { field: 'minLimit', header: 'Minimum value', width: '80px' },
  {
    field: 'equalityConditionForMax',
    header: 'Equality condition for maximum',
    width: '150px',
  },
  { field: 'maxLimit', header: 'Maximum value', width: '80px' },
  { field: 'unitOfMeasure', header: 'Unit of Measure', width: '80px' },
  { field: 'groupName', header: 'Substance Group', width: '80px' },

  {
    field: 'equalityConditionForGroupMinx',
    header: 'Equality condition for Group Minx',
    width: '150px',
  },
  { field: 'groupMinValue', header: 'Group Minimum value', width: '100px' },
  {
    field: 'equalityConditionForGroupMaxx',
    header: 'Equality condition for Group Minx',
    width: '150px',
  },
  { field: 'groupMaxValue', header: 'Group Maximum value', width: '100px' },
  { field: 'groupUnitOfMeasure', header: 'Unit of Measure', width: '80px' },
  {
    field: 'conflictingRegulatoryGroup',
    header: 'Conflicting Regulatory Group',
    width: '150px',
  },
  { field: 'isExempted', header: 'Exempted Y/N', width: '100px' },
  {
    field: 'conflictingSubstance',
    header: 'Conflicting Substance',
    width: '100px',
  },
  {
    field: 'combinationSubstance',
    header: 'Combination Substance',
    width: '100px',
  },
  {
    field: 'combinationRatioToDisplay',
    header: 'Combination Ratio',
    width: '100px',
  },
  {
    field: 'ruleSpecificConditionName',
    header: 'Conditional Requirements',
    width: '100px',
  },

  { field: 'ruleClaimName', header: 'Claims', width: '100px' },
  { field: 'phasingOutDate', header: 'Phasing out Date', width: '80px' },
  { field: 'warnings', header: 'Warnings', width: '100px' },
  {
    field: 'otherRestrictionsAndRequirements',
    header: 'Other Requirements and Restrictions',
    width: '150px',
  },
  { field: 'propertySource', header: 'Property Source', width: '80px' },
  { field: 'propertyClass', header: 'Property', width: '100px' },
  { field: 'subProperty', header: 'Sub property', width: '100px' },
  { field: 'source', header: 'Source', width: '100px' },
  { field: 'validityDate', header: 'Validity', width: '100px' },
  { field: 'substanceID', header: 'SIR ID', width: '100px' },
  { field: 'casNumber', header: 'CAS #', width: '100px' },
  { field: 'parentCASNumber', header: 'Parent CAS #', width: '100px' },
  { field: 'einecsNumber', header: 'EINECS#', width: '100px' },

  { field: 'eNumber', header: 'E #', width: '100px' },
  { field: 'insNumber', header: 'INS #', width: '100px' },
  { field: 'componentName', header: 'Component type	', width: '80px' },
  { field: 'preparationMethod', header: 'Preparation Method	', width: '100px' },
  { field: 'intendedUse', header: 'Intended Use', width: '100px' },
  {
    field: 'ruleCreatedByUserDisplayName',
    header: 'Created By',
    width: '100px',
  },
  { field: 'ruleCreatedDate', header: 'Created Date', width: '100px' },
  {
    field: 'ruleModifiedByUserDisplayName',
    header: 'Modified By',
    width: '100px',
  },
  { field: 'ruleModifiedDate', header: 'Modified Date', width: '100px' },
];
export const VIEW_PREPARE_RULE_TABLE_COLUMNS = [
  { field: 'ruleID', header: 'Rule ID', width: '100px' },
  { field: 'ruleVerionToDisplay', header: 'Rule Version', width: '60px' },
  { field: 'pureCode', header: 'PURE Code', width: '100px' },
  { field: 'pureDescription', header: 'PURE Description', width: '125px' },
  { field: 'substanceID', header: 'SIR ID', width: '100px' },
  { field: 'substanceName', header: 'Substance Name', width: '130px' },
  { field: 'inciNumber', header: 'INCI', width: '75px' },
  {
    field: 'cfSubstanceExpressedAs',
    header: 'CF Substance Expressed as',
    width: '100px',
  },
  { field: 'cf', header: 'CF', width: '100px' },
  { field: 'isAllergen', header: 'Allergen (Y/N)', width: '100px' },
  { field: 'hazardClass', header: 'Hazard Classification', width: '100px' },

  { field: 'marketNameForDisplay', header: 'Market	', width: '100px' },
  { field: 'rpcListForDisplay', header: 'RPC Path', width: '100px' },
  { field: 'ruleRPC', header: 'Rule RPC Path', width: '100px' },
  { field: 'ruleClassification', header: 'Rule Category', width: '125px' },
  { field: 'ruleTypeDisplayName', header: 'Rule Type', width: '100px' },
  {
    field: 'allowedFuncFlag',
    header: 'Allowed Function (Y/N)',
    width: '100px',
  },
  { field: 'ruleFunctionName', header: 'Rule Function', width: '100px' },
  {
    field: 'substanceFunctionInput',
    header: 'Rule Evaluated Function',
    width: '150px',
  },
  { field: 'rndInputFunction', header: 'Request Function', width: '120px' },
  { field: 'equalityCondition', header: 'Equality condition', width: '150px' },
  { field: 'minLimit', header: 'Minimum value', width: '80px' },
  // { field: 'equalityConditionForMaxSub', header: 'Equality condition for maximum', width: '150px' },
  { field: 'maxLimit', header: 'Maximum value', width: '80px' },
  { field: 'uoM', header: 'Unit of Measure', width: '80px' },
  { field: 'groupName', header: 'Substance Group', width: '80px' },

  // { field: 'equalityConditionForGroupMinx', header: 'Equality condition for Group Min', width: '150px' },
  // { field: 'groupMinValue', header: 'Group Minimum value', width: '100px' },
  // { field: 'equalityConditionForGroupMaxx', header: 'Equality condition for Group Max', width: '150px' },
  // { field: 'groupMaxValue', header: 'Group Maximum value', width: '100px' },
  // { field: 'groupUnitOfMeasure', header: 'Unit of Measure', width: '80px' },
  // { field: 'conflictingRegulatoryGroup', header: 'Conflicting Regulatory Group', width: '150px' },
  { field: 'isExempted', header: 'Exempted Y/N', width: '100px' },
  {
    field: 'isDosageApplicable',
    header: 'Dosage Applicable (Y/N)',
    width: '120px',
  },
  {
    field: 'isServingSizeApplicable',
    header: 'Serving Size Applicable (Y/N)',
    width: '140px',
  },
  { field: 'conflictingGroup', header: 'Conflicting Group ', width: '100px' },
  {
    field: 'conflictingSubstance',
    header: 'Conflicting Substance',
    width: '100px',
  },
  {
    field: 'combinationSubstance',
    header: 'Combination Substance',
    width: '100px',
  },
  {
    field: 'combinationRatioToDisplay',
    header: 'Combination Ratio',
    width: '100px',
  },
  {
    field: 'ruleSpecificConditionName',
    header: 'Conditional Requirements',
    width: '100px',
  },

  { field: 'ruleClaimName', header: 'Claims', width: '100px' },
  { field: 'phaseOutDate', header: 'Phasing out Date', width: '80px' },
  { field: 'warningText', header: 'Warnings', width: '100px' },
  {
    field: 'otherRestrictionsAndRequirements',
    header: 'Other Requirements and Restrictions',
    width: '150px',
  },
  { field: 'propertySource', header: 'Property Source', width: '80px' },
  { field: 'propertyClass', header: 'Property', width: '100px' },
  { field: 'propertyName', header: 'Sub property', width: '100px' },
  // ing property
  { field: 'ingPropertyType', header: 'ING Rule Type', width: '100px' },
  // { field: 'propertyName', header: 'Property Tree', width: '100px' },
  // { field: 'propertyClass', header: 'Property Type', width: '100px' },
  { field: 'ingPropertyName', header: 'ING Property Name', width: '100px' },
  { field: 'ruleSource', header: 'Rule Source', width: '100px' },
  { field: 'validityDate', header: 'Validity', width: '100px' },
  { field: 'yearAdopted', header: 'Year Adopted', width: '100px' },
  { field: 'casNumber', header: 'CAS #', width: '100px' },
  { field: 'parentCASNumber', header: 'Parent CAS #', width: '100px' },
  { field: 'einecsNumber', header: 'EINECS#', width: '100px' },

  { field: 'eNumber', header: 'E #', width: '100px' },
  { field: 'insNumber', header: 'INS #', width: '100px' },
  // { field: 'componentName', header: 'Component Type	', width: '80px' },
  { field: 'preparationMethod', header: 'Preparation Method	', width: '100px' },
  { field: 'intendedUse', header: 'Intended Use', width: '100px' },
  {
    field: 'ruleCreatedByUserDisplayName',
    header: 'Created By',
    width: '100px',
  },
  { field: 'ruleCreatedDate', header: 'Created Date', width: '100px' },
  {
    field: 'ruleModifiedByUserDisplayName',
    header: 'Modified By',
    width: '100px',
  },
  { field: 'ruleModifiedDate', header: 'Modified Date', width: '100px' },
];
export const VIEW_PREPARE_RULE_TABLE_COLUMNS_EXCEL = [
  { field: 'ruleID', header: 'Rule ID', width: '100px' },
  { field: 'ruleVerionToDisplay', header: 'Rule Version', width: '60px' },
  { field: 'pureCode', header: 'PURE Code', width: '100px' },
  { field: 'pureDescription', header: 'PURE Description', width: '125px' },
  { field: 'substanceID', header: 'SIR ID', width: '100px' },
  { field: 'substanceName', header: 'Substance Name', width: '130px' },
  { field: 'inciNumber', header: 'INCI', width: '75px' },
  {
    field: 'cfSubstanceExpressedAs',
    header: 'CF Substance Expressed as',
    width: '100px',
  },
  { field: 'cf', header: 'CF', width: '100px' },
  { field: 'isAllergen', header: 'Allergen (Y/N)', width: '100px' },
  { field: 'hazardClass', header: 'Hazard Classification', width: '100px' },

  { field: 'marketNameForDisplay', header: 'Market	', width: '100px' },
  { field: 'rpcListForDisplay', header: 'RPC Path', width: '100px' },
  { field: 'ruleRPC', header: 'Rule RPC Path', width: '100px' },
  { field: 'ruleTypeDisplayName', header: 'Rule Type', width: '100px' },
  {
    field: 'allowedFuncFlag',
    header: 'Allowed Function (Y/N)',
    width: '100px',
  },
  { field: 'ruleFunctionName', header: 'Rule Function', width: '100px' },
  {
    field: 'substanceFunctionInput',
    header: 'Rule Evaluated Function',
    width: '150px',
  },
  { field: 'rndInputFunction', header: 'Request Function', width: '120px' },
  { field: 'equalityCondition', header: 'Equality condition', width: '150px' },
  { field: 'minLimit', header: 'Minimum value', width: '80px' },
  { field: 'maxLimit', header: 'Maximum value', width: '80px' },
  { field: 'uoM', header: 'Unit of Measure', width: '80px' },
  { field: 'groupName', header: 'Substance Group', width: '80px' },
  { field: 'isExempted', header: 'Exempted Y/N', width: '100px' },
  {
    field: 'isDosageApplicable',
    header: 'Dosage Applicable (Y/N)',
    width: '120px',
  },
  {
    field: 'isServingSizeApplicable',
    header: 'Serving Size Applicable (Y/N)',
    width: '140px',
  },
  { field: 'conflictingGroup', header: 'Conflicting Group ', width: '100px' },
  {
    field: 'conflictingSubstance',
    header: 'Conflicting Substance',
    width: '100px',
  },
  {
    field: 'combinationSubstance',
    header: 'Combination Substance',
    width: '100px',
  },
  {
    field: 'combinationRatioToDisplay',
    header: 'Combination Ratio',
    width: '100px',
  },
  {
    field: 'ruleSpecificConditionName',
    header: 'Conditional Requirements',
    width: '100px',
  },

  { field: 'ruleClaimName', header: 'Claims', width: '100px' },
  { field: 'phaseOutDate', header: 'Phasing out Date', width: '80px' },
  { field: 'warningTextStripped', header: 'Warnings', width: '100px' },
  {
    field: 'otherRestrictionsAndRequirementsStripped',
    header: 'Other Requirements and Restrictions',
    width: '150px',
  },
  { field: 'propertySource', header: 'Property Source', width: '80px' },
  { field: 'propertyClass', header: 'Property', width: '100px' },
  { field: 'propertyName', header: 'Sub property', width: '100px' },
  // ing property
  { field: 'ingPropertyType', header: 'ING Rule Type', width: '100px' },
  // { field: 'propertyName', header: 'Property Tree', width: '100px' },
  // { field: 'propertyClass', header: 'Property Type', width: '100px' },
  { field: 'ingPropertyName', header: 'ING Property Name', width: '100px' },
  { field: 'ruleSource', header: 'Rule Source', width: '100px' },
  { field: 'validityDate', header: 'Validity', width: '100px' },
  { field: 'yearAdopted', header: 'Year Adopted', width: '100px' },
  { field: 'casNumber', header: 'CAS #', width: '100px' },
  { field: 'parentCASNumber', header: 'Parent CAS #', width: '100px' },
  { field: 'einecsNumber', header: 'EINECS#', width: '100px' },

  { field: 'eNumber', header: 'E #', width: '100px' },
  { field: 'insNumber', header: 'INS #', width: '100px' },
  // { field: 'componentName', header: 'Component Type	', width: '80px' },
  { field: 'preparationMethod', header: 'Preparation Method	', width: '100px' },
  { field: 'intendedUse', header: 'Intended Use', width: '100px' },
  {
    field: 'ruleCreatedByUserDisplayName',
    header: 'Created By',
    width: '100px',
  },
  { field: 'ruleCreatedDate', header: 'Created Date', width: '100px' },
  {
    field: 'ruleModifiedByUserDisplayName',
    header: 'Modified By',
    width: '100px',
  },
  { field: 'ruleModifiedDate', header: 'Modified Date', width: '100px' },
  { field: 'warningText1', header: 'Warnings Link 1', width: 25 },
  { field: 'warningText2', header: 'Warnings Link 2', width: 25 },
  { field: 'warningText3', header: 'Warnings Link 3', width: 25 },
  { field: 'warningText4', header: 'Warnings Link 4', width: 25 },
  { field: 'warningText5', header: 'Warnings Link 5', width: 25 },
  { field: 'warningText6', header: 'Warnings Link 6', width: 25 },
  { field: 'warningText7', header: 'Warnings Link 7', width: 25 },
  { field: 'ORR1', header: 'Other Requirements and Restrictions Link 1', width: 45 },
  { field: 'ORR2', header: 'Other Requirements and Restrictions Link 2', width: 45 },
  { field: 'ORR3', header: 'Other Requirements and Restrictions Link 3', width: 45 },
  { field: 'ORR4', header: 'Other Requirements and Restrictions Link 4', width: 45 },
  { field: 'ORR5', header: 'Other Requirements and Restrictions Link 5', width: 45 },
  { field: 'ORR6', header: 'Other Requirements and Restrictions Link 6', width: 45 },
  { field: 'ORR7', header: 'Other Requirements and Restrictions Link 7', width: 45 },
];
export const SUBSTANCE_RULE_COLUMNS = [
  { field: 'ruleId', header: 'Rule ID', width: '100px' },
  { field: 'ruleVerionToDisplay', header: 'Rule Version', width: '60px' },
  { field: 'pureCode', header: 'PURE Code', width: '100px' },
  { field: 'pureDescription', header: 'PURE Description', width: '125px' },
  { field: 'substanceID', header: 'SIR ID', width: '100px' },
  { field: 'substanceName', header: 'Substance Name', width: '120px' },
  // { field: 'substanceName', header: 'Synonyms', width: '130px' },

  { field: 'isAllergen', header: 'Allergen (Y/N)', width: '100px' },
  { field: 'isDosageApplicable', header: 'Dosage (Y/N)', width: '100px' },
  {
    field: 'isServingSizeApplicable',
    header: 'Serving Size (Y/N)',
    width: '130px',
  },
  { field: 'market', header: 'Market', width: '100px' },
  { field: 'updName', header: 'Division', width: '100px' },
  { field: 'rpc', header: 'RPC Path', width: '200px' },
  { field: 'ruleType', header: 'Rule Type', width: '100px' },
  { field: 'ruleSource', header: 'Rule Source', width: '100px' },
  { field: 'ruleStatus', header: 'Rule Status', width: '100px' },

  // { field: 'allowedFuncFlag', header: 'Allowed Function (Y/N)', width: '120px' },
  { field: 'functionName', header: 'Function', width: '100px' },
  { field: 'minLimit', header: 'Minimum value', width: '110px' },
  { field: 'equalityCondition', header: 'Equality condition', width: '130px' },
  { field: 'maxLimit', header: 'Maximum value', width: '110px' },
  // { field: 'equalityCondition', header: 'Equality condition for maximum', width: '150px' },
  { field: 'uoM', header: 'Unit of Measure', width: '110px' },
  // { field: 'substanceGroup', header: 'Substance Group', width: '80px' },

  // { field: 'equalityConditionForGroupMin', header: 'Equality condition for Group Min', width: '150px' },
  // { field: 'groupMinValue', header: 'Group Minimum value', width: '100px' },
  // { field: 'equalityConditionForGroupMax', header: 'Equality condition for Group Max', width: '150px' },
  // { field: 'groupMaxValue', header: 'Group Maximum value', width: '100px' },
  // { field: 'groupUnitOfMeasure', header: 'Unit of Measure', width: '80px' },
  // { field: 'conflictingRegulatoryGroup', header: 'Conflicting Regulatory Group', width: '150px' },
  // { field: 'isExempted', header: 'Exempted Y/N', width: '100px' },
  {
    field: 'conflictingSubstance',
    header: 'Conflicting Substance',
    width: '100px',
  },
  {
    field: 'combinationSubstance',
    header: 'Combination Substance',
    width: '100px',
  },
  {
    field: 'combinationRatioToDisplay',
    header: 'Combination Ratio',
    width: '100px',
  },
  // { field: 'conditionalRequirements', header: 'Conditional Requirements', width: '100px' },

  { field: 'claimText', header: 'Claims', width: '100px' },
  {
    field: 'specificConditionText',
    header: 'Conditional Requirements',
    width: '100px',
  },
  { field: 'phasedOutDate', header: 'Phasing out Date', width: '80px' },
  { field: 'warningText', header: 'Warnings', width: '100px' },
  {
    field: 'otherRestrictionsAndRequirements',
    header: 'Other Requirements and Restrictions',
    width: '150px',
  },
  // { field: 'propertySource', header: 'Property Source', width: '80px' },
  // { field: 'property', header: 'Property', width: '100px' },
  // { field: 'subProperty', header: 'Sub property', width: '100px' },
  { field: 'ruleSourceType', header: 'Source', width: '100px' },

  // Add Ingredient Rule Type columns
  { field: 'ingPropertyType', header: 'ING Rule Type', width: '100px' },
  { field: 'propertyClass', header: 'Property', width: '100px' },
  { field: 'ingPropertyName', header: 'Sub property', width: '100px' },

  { field: 'validityStartDate', header: 'Valid From', width: '100px' },
  { field: 'validityDate', header: 'Valid To', width: '100px' },
  { field: 'cas', header: 'CAS #', width: '100px' },
  { field: 'parentCAS', header: 'Parent CAS #', width: '100px' },
  { field: 'einecs', header: 'EINECS#', width: '100px' },
  { field: 'eNumber', header: 'E #', width: '100px' },
  { field: 'ins', header: 'INS #', width: '100px' },
  { field: 'inciName', header: 'INCI', width: '75px' },
  {
    field: 'cfSubstanceExpressedAs',
    header: 'CF Substance Expressed as',
    width: '130px',
  },
  { field: 'cf', header: 'CF', width: '100px' },
  // { field: 'hazardClass', header: 'Hazard Classification', width: '100px' },
  // { field: 'componentType', header: 'Component type	', width: '80px' },
  { field: 'preparationMethod', header: 'Preparation Method	', width: '100px' },
  { field: 'intendedUse', header: 'Intended Use', width: '100px' },
  { field: 'createdByUserDisplayName', header: 'Created By', width: '100px' },
  { field: 'createdDate', header: 'Created Date', width: '100px' },
  { field: 'modifiedByUserDisplayName', header: 'Modified By', width: '100px' },
  { field: 'modifiedDate', header: 'Modified Date', width: '100px' },
  { field: 'yearAdopted', header: 'Year Adopted', width: '100px' },
  { field: 'hazardClass', header: 'Hazard Class', width: '120px' },
  { field: 'hazardCategory', header: 'Hazard Category', width: '120px' },
  { field: 'routeOfExposure', header: 'Route of Exposure', width: '140px' },
  { field: 'hStatement', header: 'H Statement', width: '120px' },
  {
    field: 'cutOffConcentration', header: 'Cut off/Concentration',
    width: '150px',
  },
  { field: 'delete', header: '', width: '40px' },
];
export const SUBSTANCE_RULE_COLUMNS_EXCEL = [
  { field: 'ruleId', header: 'Rule ID', width: '100px' },
  { field: 'ruleVerionToDisplay', header: 'Rule Version', width: '60px' },
  { field: 'pureCode', header: 'PURE Code', width: '100px' },
  { field: 'pureDescription', header: 'PURE Description', width: '125px' },
  { field: 'substanceID', header: 'SIR ID', width: '100px' },
  { field: 'substanceName', header: 'Substance Name', width: '120px' },
  { field: 'isAllergen', header: 'Allergen (Y/N)', width: '100px' },
  { field: 'isDosageApplicable', header: 'Dosage (Y/N)', width: '100px' },
  { field: 'isServingSizeApplicable', header: 'Serving Size (Y/N)', width: '130px', },
  { field: 'market', header: 'Market', width: '100px' },
  { field: 'rpc', header: 'RPC Path', width: '200px' },
  { field: 'ruleType', header: 'Rule Type', width: '100px' },
  { field: 'ruleSource', header: 'Rule Source', width: '100px' },
  { field: 'ruleStatus', header: 'Rule Status', width: '100px' },
  { field: 'functionName', header: 'Function', width: '100px' },
  { field: 'minLimit', header: 'Minimum value', width: '110px' },
  { field: 'equalityCondition', header: 'Equality condition', width: '130px' },
  { field: 'maxLimit', header: 'Maximum value', width: '110px' },
  { field: 'uoM', header: 'Unit of Measure', width: '110px' },
  { field: 'conflictingSubstance', header: 'Conflicting Substance', width: '100px' },
  { field: 'combinationSubstance', header: 'Combination Substance', width: '100px' },
  { field: 'combinationRatioToDisplay', header: 'Combination Ratio', width: '100px' },
  { field: 'claimText', header: 'Claims', width: '100px' },
  { field: 'specificConditionText', header: 'Conditional Requirements', width: '100px' },
  { field: 'phasedOutDate', header: 'Phasing out Date', width: '80px' },
  { field: 'warningTextStripped', header: 'Warnings', width: '100px' },
  { field: 'otherRestrictionsAndRequirementsStripped', header: 'Other Requirements and Restrictions', width: '150px' },
  { field: 'ruleSourceType', header: 'Source', width: '100px' },
  { field: 'ingPropertyType', header: 'ING Rule Type', width: '100px' },
  { field: 'propertyClass', header: 'Property', width: '100px' },
  { field: 'ingPropertyName', header: 'Sub property', width: '100px' },
  { field: 'validityStartDate', header: 'Valid From', width: '100px' },
  { field: 'validityDate', header: 'Valid To', width: '100px' },
  { field: 'cas', header: 'CAS #', width: '100px' },
  { field: 'parentCAS', header: 'Parent CAS #', width: '100px' },
  { field: 'einecs', header: 'EINECS#', width: '100px' },
  { field: 'eNumber', header: 'E #', width: '100px' },
  { field: 'ins', header: 'INS #', width: '100px' },
  { field: 'inciName', header: 'INCI', width: '75px' },
  { field: 'cfSubstanceExpressedAs', header: 'CF Substance Expressed as', width: '130px' },
  { field: 'cf', header: 'CF', width: '100px' },
  { field: 'preparationMethod', header: 'Preparation Method	', width: '100px' },
  { field: 'intendedUse', header: 'Intended Use', width: '100px' },
  { field: 'createdByUserDisplayName', header: 'Created By', width: '100px' },
  { field: 'createdDate', header: 'Created Date', width: '100px' },
  { field: 'modifiedByUserDisplayName', header: 'Modified By', width: '100px' },
  { field: 'modifiedDate', header: 'Modified Date', width: '100px' },
  { field: 'yearAdopted', header: 'Year Adopted', width: '100px' },
  { field: 'hazardClass', header: 'Hazard Class', width: '120px' },
  { field: 'hazardCategory', header: 'Hazard Category', width: '120px' },
  { field: 'routeOfExposure', header: 'Route of Exposure', width: '140px' },
  { field: 'hStatement', header: 'H Statement', width: '120px' },
  { field: 'cutOffConcentration', header: 'Cut off/Concentration', width: '150px' },
  { field: 'warningText1', header: 'Warnings Link 1', width: 25 },
  { field: 'warningText2', header: 'Warnings Link 2', width: 25 },
  { field: 'warningText3', header: 'Warnings Link 3', width: 25 },
  { field: 'warningText4', header: 'Warnings Link 4', width: 25 },
  { field: 'warningText5', header: 'Warnings Link 5', width: 25 },
  { field: 'warningText6', header: 'Warnings Link 6', width: 25 },
  { field: 'warningText7', header: 'Warnings Link 7', width: 25 },
  { field: 'ORR1', header: 'Other Requirements and Restrictions Link 1', width: 45 },
  { field: 'ORR2', header: 'Other Requirements and Restrictions Link 2', width: 45 },
  { field: 'ORR3', header: 'Other Requirements and Restrictions Link 3', width: 45 },
  { field: 'ORR4', header: 'Other Requirements and Restrictions Link 4', width: 45 },
  { field: 'ORR5', header: 'Other Requirements and Restrictions Link 5', width: 45 },
  { field: 'ORR6', header: 'Other Requirements and Restrictions Link 6', width: 45 },
  { field: 'ORR7', header: 'Other Requirements and Restrictions Link 7', width: 45 },
];

export const GROUP_RULE_COLUMNS = [
  { field: 'ruleId', header: 'Rule Id', width: '80px' },
  { field: 'ruleVerionToDisplay', header: 'Rule Version', width: '60px' },
  { field: 'groupName', header: 'Group Name', width: '140px' },
  { field: 'pureCode', header: 'Pure Code', width: '100px' },
  { field: 'pureDescription', header: 'Pure Description', width: '120px' },
  { field: 'substanceID', header: 'SIR Id', width: '100px' },
  { field: 'substanceName', header: 'Substance Name', width: '120px' },
  { field: 'inciName', header: 'INCI', width: '100px' },
  {
    field: 'cfSubstanceExpressedAs',
    header: 'CF Substance Expressed As',
    width: '100px',
  },
  { field: 'cf', header: 'CF', width: '100px' },
  { field: 'isAllergen', header: 'Allergen (Y/N)', width: '100px' },
  { field: 'isDosageApplicable', header: 'Dosage (Y/N)', width: '100px' },
  { field: 'isServingSizeApplicable', header: 'Serving Size', width: '100px' },
  { field: 'market', header: 'Market', width: '100px' },
  { field: 'rpc', header: 'RPC Path', width: '140px' },
  { field: 'ruleType', header: 'Rule Type', width: '100px' },
  { field: 'ruleSource', header: 'Rule Source', width: '100px' },
  { field: 'ruleStatus', header: 'Rule Status', width: '100px' },
  { field: 'validityStartDate', header: 'Valid From', width: '100px' },
  { field: 'validityDate', header: 'Valid To', width: '100px' },
  { field: 'yearAdopted', header: 'Year Adopted', width: '100px' },
  { field: 'functionName', header: 'Function', width: '100px' },
  { field: 'minLimit', header: 'Minimum value', width: '80px' },
  { field: 'equalityCondition', header: 'Equality Condition', width: '80px' },
  { field: 'maxLimit', header: 'Maximum value', width: '80px' },
  { field: 'uoM', header: 'Unit of Measure', width: '80px' },
  {
    field: 'conflictingRegulatoryGroup',
    header: 'Conflicting Regulatory Group',
    width: '80px',
  },
  { field: 'isExempted', header: 'Exempted Y/N', width: '130px' },
  { field: 'exemptionWarning', header: 'Exemption Warning', width: '130px' },
  { field: 'claimText', header: 'Claims', width: '100px' },
  { field: 'warningText', header: 'Warnings', width: '100px' },
  {
    field: 'otherRestrictionsAndRequirements',
    header: 'Other Requirements and Restrictions',
    width: '150px',
  },
  {
    field: 'specificConditionText',
    header: 'Conditional Requirements',
    width: '100px',
  },
  { field: 'cas', header: 'CAS#', width: '100px' },
  { field: 'parentCAS', header: 'Parent CAS #', width: '100px' },
  { field: 'ins', header: 'INS#', width: '100px' },
  { field: 'einecs', header: 'EINECS', width: '100px' },
  { field: 'eNumber', header: 'E#', width: '100px' },
  { field: 'preparationMethod', header: 'Preparation Method', width: '130px' },
  { field: 'intendedUse', header: 'Intended End Use', width: '140px' },
  { field: 'createdByUserDisplayName', header: 'Created By', width: '100px' },
  { field: 'createdDate', header: 'Created Date', width: '100px' },
  { field: 'modifiedByUserDisplayName', header: 'Modified By', width: '100px' },
  { field: 'modifiedDate', header: 'Modified Date', width: '100px' },
  { field: 'delete', header: '', width: '40px' },
];
export const GROUP_RULE_COLUMNS_EXCEL = [
  { field: 'ruleId', header: 'Rule Id', width: '80px' },
  // { field: 'groupID', header: 'Group Id', width: '90px' },
  { field: 'ruleVerionToDisplay', header: 'Rule Version', width: '60px' },
  { field: 'groupName', header: 'Group Name', width: '140px' },
  //   ? { field: 'groupName', header: 'Division', width: '140px' },
  { field: 'pureCode', header: 'Pure Code', width: '100px' },
  { field: 'pureDescription', header: 'Pure Description', width: '120px' },
  { field: 'substanceID', header: 'SIR Id', width: '100px' },
  { field: 'substanceName', header: 'Substance Name', width: '120px' },
  // { field: 'substanceName', header: 'Synonyms', width: '100px' },
  { field: 'inciName', header: 'INCI', width: '100px' },
  {
    field: 'cfSubstanceExpressedAs',
    header: 'CF Substance Expressed As',
    width: '100px',
  },
  { field: 'cf', header: 'CF', width: '100px' },
  { field: 'isAllergen', header: 'Allergen (Y/N)', width: '100px' },
  { field: 'isDosageApplicable', header: 'Dosage (Y/N)', width: '100px' },
  { field: 'isServingSizeApplicable', header: 'Serving Size', width: '100px' },
  // { field: 'substanceName', header: 'Substance Name', width: '100px' },
  { field: 'market', header: 'Market', width: '100px' },
  { field: 'rpc', header: 'RPC Path', width: '140px' },
  { field: 'ruleType', header: 'Rule Type', width: '100px' },
  { field: 'ruleSource', header: 'Rule Source', width: '100px' },
  { field: 'ruleStatus', header: 'Rule Status', width: '100px' },
  { field: 'validityStartDate', header: 'Valid From', width: '100px' },
  { field: 'validityDate', header: 'Valid To', width: '100px' },
  { field: 'yearAdopted', header: 'Year Adopted', width: '100px' },
  { field: 'functionName', header: 'Function', width: '100px' },
  //  ?  { field: 'equalityCondition', header: 'Equality Condition Minimum value', width: '80px' },
  { field: 'minLimit', header: 'Minimum value', width: '80px' },
  { field: 'equalityCondition', header: 'Equality Condition', width: '80px' },
  { field: 'maxLimit', header: 'Maximum value', width: '80px' },
  { field: 'uoM', header: 'Unit of Measure', width: '80px' },
  {
    field: 'conflictingRegulatoryGroup',
    header: 'Conflicting Regulatory Group',
    width: '80px',
  },
  { field: 'isExempted', header: 'Exempted Y/N', width: '130px' },
  { field: 'exemptionWarning', header: 'Exemption Warning', width: '130px' },
  { field: 'claimText', header: 'Claims', width: '100px' },
  { field: 'warningTextStripped', header: 'Warnings', width: '100px' },
  {
    field: 'otherRestrictionsAndRequirementsStripped',
    header: 'Other Requirements and Restrictions',
    width: '150px',
  },
  {
    field: 'specificConditionText',
    header: 'Conditional Requirements',
    width: '100px',
  },
  // { field: 'isDosageApplicable', header: 'Dosage', width: '100px' },
  //  { field: 'phasingOutDate', header: 'Phasing out Date', width: '80px' },
  { field: 'cas', header: 'CAS#', width: '100px' },
  { field: 'parentCAS', header: 'Parent CAS #', width: '100px' },
  { field: 'ins', header: 'INS#', width: '100px' },
  { field: 'einecs', header: 'EINECS', width: '100px' },
  { field: 'eNumber', header: 'E#', width: '100px' },
  { field: 'preparationMethod', header: 'Preparation Method', width: '130px' },
  { field: 'intendedUse', header: 'Intended End Use', width: '140px' },
  { field: 'createdByUserDisplayName', header: 'Created By', width: '100px' },
  { field: 'createdDate', header: 'Created Date', width: '100px' },
  { field: 'modifiedByUserDisplayName', header: 'Modified By', width: '100px' },
  { field: 'modifiedDate', header: 'Modified Date', width: '100px' },
  { field: 'warningText1', header: 'Warnings Link 1', width: 25 },
  { field: 'warningText2', header: 'Warnings Link 2', width: 25 },
  { field: 'warningText3', header: 'Warnings Link 3', width: 25 },
  { field: 'warningText4', header: 'Warnings Link 4', width: 25 },
  { field: 'warningText5', header: 'Warnings Link 5', width: 25 },
  { field: 'warningText6', header: 'Warnings Link 6', width: 25 },
  { field: 'warningText7', header: 'Warnings Link 7', width: 25 },
  { field: 'ORR1', header: 'Other Requirements and Restrictions Link 1', width: 45 },
  { field: 'ORR2', header: 'Other Requirements and Restrictions Link 2', width: 45 },
  { field: 'ORR3', header: 'Other Requirements and Restrictions Link 3', width: 45 },
  { field: 'ORR4', header: 'Other Requirements and Restrictions Link 4', width: 45 },
  { field: 'ORR5', header: 'Other Requirements and Restrictions Link 5', width: 45 },
  { field: 'ORR6', header: 'Other Requirements and Restrictions Link 6', width: 45 },
  { field: 'ORR7', header: 'Other Requirements and Restrictions Link 7', width: 45 },
];

export const PRODUCT_RULE_TABLE_COLUMNS = [
  { field: 'ruleId', header: 'Rule ID', width: '100px' },
  { field: 'ruleVerionToDisplay', header: 'Rule Version', width: '60px' },
  { field: 'pureCode', header: 'PURE Code', width: '100px' },
  { field: 'pureDescription', header: 'PURE Description', width: '125px' },
  { field: 'substanceID', header: 'SIR ID', width: '100px' },
  { field: 'substanceName', header: 'Substance Name', width: '130px' },

  { field: 'market', header: 'Market', width: '100px' },
  { field: 'rpc', header: 'RPC Path', width: '200px' },
  { field: 'ruleType', header: 'Rule Type', width: '150px' },
  { field: 'ruleStatus', header: 'Rule Status', width: '100px' },
  { field: 'ruleSource', header: 'Rule Source', width: '100px' },
  { field: 'functionName', header: 'Function', width: '100px' },
  { field: 'minLimit', header: 'Minimum value', width: '110px' },
  { field: 'equalityCondition', header: 'Equality condition', width: '150px' },
  { field: 'maxLimit', header: 'Maximum value', width: '110px' },
  { field: 'uoM', header: 'Unit of Measure', width: '110px' },
  { field: 'propertySource', header: 'Property Source', width: '80px' },
  { field: 'propertyClass', header: 'Property', width: '100px' },
  { field: 'propertyName', header: 'Sub property', width: '100px' },

  { field: 'claimText', header: 'Claims', width: '100px' },
  {
    field: 'specificConditionText',
    header: 'Conditional Requirements',
    width: '100px',
  },
  { field: 'warningText', header: 'Warnings', width: '100px' },
  {
    field: 'otherRestrictionsAndRequirements',
    header: 'Other Requirements and Restrictions',
    width: '150px',
  },
  { field: 'ruleSourceType', header: 'Source', width: '100px' },
  { field: 'validityStartDate', header: 'Valid From', width: '100px' },
  { field: 'validityDate', header: 'Valid To', width: '100px' },
  { field: 'cas', header: 'CAS #', width: '100px' },
  { field: 'parentCAS', header: 'Parent CAS #', width: '100px' },
  { field: 'einecs', header: 'EINECS#', width: '100px' },
  { field: 'eNumber', header: 'E #', width: '100px' },
  { field: 'ins', header: 'INS #', width: '100px' },
  { field: 'inciName', header: 'INCI', width: '75px' },
  {
    field: 'cfSubstanceExpressedAs',
    header: 'CF Substance Expressed as',
    width: '100px',
  },
  { field: 'cf', header: 'CF', width: '100px' },

  // { field: 'componentType', header: 'Component type	', width: '80px' },
  { field: 'preparationMethod', header: 'Preparation Method	', width: '100px' },
  { field: 'intendedUse', header: 'Intended Use', width: '100px' },
  { field: 'createdByUserDisplayName', header: 'Created By', width: '100px' },
  { field: 'createdDate', header: 'Created Date', width: '100px' },
  { field: 'modifiedByUserDisplayName', header: 'Modified By', width: '100px' },
  { field: 'modifiedDate', header: 'Modified Date', width: '100px' },
  { field: 'yearAdopted', header: 'Year Adopted', width: '100px' },
  { field: 'delete', header: '', width: '40px' },
];
export const PRODUCT_RULE_TABLE_COLUMNS_EXCEL = [
  { field: 'ruleId', header: 'Rule ID', width: '100px' },
  { field: 'ruleVerionToDisplay', header: 'Rule Version', width: '60px' },
  { field: 'pureCode', header: 'PURE Code', width: '100px' },
  { field: 'pureDescription', header: 'PURE Description', width: '125px' },
  { field: 'substanceID', header: 'SIR ID', width: '100px' },
  { field: 'substanceName', header: 'Substance Name', width: '130px' },
  { field: 'market', header: 'Market', width: '100px' },
  { field: 'rpc', header: 'RPC Path', width: '200px' },
  { field: 'ruleType', header: 'Rule Type', width: '150px' },
  { field: 'ruleStatus', header: 'Rule Status', width: '100px' },
  { field: 'ruleSource', header: 'Rule Source', width: '100px' },
  { field: 'functionName', header: 'Function', width: '100px' },
  { field: 'minLimit', header: 'Minimum value', width: '110px' },
  { field: 'equalityCondition', header: 'Equality condition', width: '150px' },
  { field: 'maxLimit', header: 'Maximum value', width: '110px' },
  { field: 'uoM', header: 'Unit of Measure', width: '110px' },
  { field: 'propertySource', header: 'Property Source', width: '80px' },
  { field: 'propertyClass', header: 'Property', width: '100px' },
  { field: 'propertyName', header: 'Sub property', width: '100px' },
  { field: 'claimText', header: 'Claims', width: '100px' },
  { field: 'specificConditionText', header: 'Conditional Requirements', width: '100px' },
  { field: 'warningTextStripped', header: 'Warnings', width: '100px' },
  { field: 'otherRestrictionsAndRequirementsStripped', header: 'Other Requirements and Restrictions', width: '150px' },
  { field: 'ruleSourceType', header: 'Source', width: '100px' },
  { field: 'validityStartDate', header: 'Valid From', width: '100px' },
  { field: 'validityDate', header: 'Valid To', width: '100px' },
  { field: 'cas', header: 'CAS #', width: '100px' },
  { field: 'parentCAS', header: 'Parent CAS #', width: '100px' },
  { field: 'einecs', header: 'EINECS#', width: '100px' },
  { field: 'eNumber', header: 'E #', width: '100px' },
  { field: 'ins', header: 'INS #', width: '100px' },
  { field: 'inciName', header: 'INCI', width: '75px' },
  { field: 'cfSubstanceExpressedAs', header: 'CF Substance Expressed as', width: '100px' },
  { field: 'cf', header: 'CF', width: '100px' },
  { field: 'preparationMethod', header: 'Preparation Method	', width: '100px' },
  { field: 'intendedUse', header: 'Intended Use', width: '100px' },
  { field: 'createdByUserDisplayName', header: 'Created By', width: '100px' },
  { field: 'createdDate', header: 'Created Date', width: '100px' },
  { field: 'modifiedByUserDisplayName', header: 'Modified By', width: '100px' },
  { field: 'modifiedDate', header: 'Modified Date', width: '100px' },
  { field: 'yearAdopted', header: 'Year Adopted', width: '100px' },
  { field: 'warningText1', header: 'Warnings Link 1', width: 25 },
  { field: 'warningText2', header: 'Warnings Link 2', width: 25 },
  { field: 'warningText3', header: 'Warnings Link 3', width: 25 },
  { field: 'warningText4', header: 'Warnings Link 4', width: 25 },
  { field: 'warningText5', header: 'Warnings Link 5', width: 25 },
  { field: 'warningText6', header: 'Warnings Link 6', width: 25 },
  { field: 'warningText7', header: 'Warnings Link 7', width: 25 },
  { field: 'ORR1', header: 'Other Requirements and Restrictions Link 1', width: 45 },
  { field: 'ORR2', header: 'Other Requirements and Restrictions Link 2', width: 45 },
  { field: 'ORR3', header: 'Other Requirements and Restrictions Link 3', width: 45 },
  { field: 'ORR4', header: 'Other Requirements and Restrictions Link 4', width: 45 },
  { field: 'ORR5', header: 'Other Requirements and Restrictions Link 5', width: 45 },
  { field: 'ORR6', header: 'Other Requirements and Restrictions Link 6', width: 45 },
  { field: 'ORR7', header: 'Other Requirements and Restrictions Link 7', width: 45 },
];

export const GROUPTABLE_COLUMNS = [
  { field: 'groupId', header: 'Group Id', width: '70px' },
  { field: 'groupName', header: 'Group Name', width: '100px' },
  { field: 'groupType', header: 'Group Type', width: '100px' },
  { field: 'substanceId', header: 'SIR ID', width: '100px' },
  { field: 'pureCode', header: 'Pure Code', width: '100px' },
  { field: 'substanceName', header: 'Substance Name', width: '130px' },
  { field: 'inciName', header: 'INCI Name', width: '130px' },
  { field: 'cfData', header: 'CF Data', width: '130px' },
  { field: 'cas', header: 'CAS#', width: '100px' },
  { field: 'parentCAS', header: 'Parent CAS#', width: '100px' },
  { field: 'ins', header: 'INS#', width: '100px' },
];

export const REGULATORY_GROUPTABLE_COLUMNS = [
  { field: 'groupId', header: 'Group Id', width: '70px' },
  { field: 'groupName', header: 'Group Name', width: '100px' },
  { field: 'groupType', header: 'Group Type', width: '100px' },
  { field: 'substanceId', header: 'SIR ID', width: '100px' },
  { field: 'pureCode', header: 'Pure Code', width: '100px' },
  { field: 'substanceName', header: 'Substance Name', width: '130px' },
  { field: 'inciName', header: 'INCI Name', width: '130px' },
  { field: 'cfData', header: 'CF Data', width: '130px' },
  { field: 'cas', header: 'CAS#', width: '100px' },
  { field: 'parentCAS', header: 'Parent CAS#', width: '100px' },
  { field: 'ins', header: 'INS#', width: '100px' },
  { field: 'createdDate', header: 'Created Date ', width: '130px' },
  { field: 'createdByUserIdDisplayName', header: 'Created By', width: '130px' },
  { field: 'modifiedDate', header: 'Modified Date', width: '100px' },
  {
    field: 'modifiedByUserIdDisplayName',
    header: 'Modified By',
    width: '100px',
  },
];

export const SUBSTANCE_COLUMNS = [
  { field: 'purecode', header: 'PURE Code', width: '100px' },
  { field: 'sirId', header: 'SIR ID', width: '100px' },
  { field: 'inciName', header: 'INCI Name', width: '130px' },
  { field: 'cas', header: 'CAS#', width: '100px' },
  { field: 'parentCAS', header: 'Parent CAS#', width: '100px' },
  { field: 'ins', header: 'INS#', width: '100px' },
  { field: 'einecs', header: 'EINECS', width: '100px' },
  { field: 'synonyms', header: 'Synonyms', width: '140px' },
  { field: 'cfdata', header: 'CF Data', width: '140px' },
  { field: 'notifygpc', header: 'Notify GPC', width: '80px' },
  { field: 'notifysir', header: 'Notify SIR', width: '80px' },
];

export const WORKLIST_COLUMNS_TO_SHOW = [
  { field: 'check', header: '', width: '25px' },
  { field: 'requestDisplayID', header: 'Request ID' },
  { field: 'requestStatus', header: 'Request Status' },
];

export const MANDATORY_WORKLIST_COLUMNS_TO_SHOW = [
  { field: 'requestDisplayID', header: 'Request ID', width: '120px' },
  { field: 'requestStatus', header: 'Request Status', width: '110px' },
  // { field: 'cucCode', header: 'CUC Code/ALT/VER', width: '160px' },
  // { field: 'cucDescription', header: 'Description', width: '150px' },
  // { field: 'recipeType', header: 'Recipe Type', width: '120px' }
];

export const WORKLIST_COLUMNS_TO_ADD = [
  { field: 'check', header: '', width: '25px' },
  // { field: 'requestDisplayID', header: 'Request ID', width: '120px' },
  // { field: 'requestStatus', header: 'Request Status', width: '110px' },
  // { field: 'cucCode', header: 'CUC Code/ALT/VER', width: '160px' },
  // { field: 'cucDescription', header: 'Description', width: '150px' },
  // { field: 'recipeType', header: 'Recipe Type', width: '120px' },
  { field: 'recipeStatus', header: 'Recipe Status', width: '120px' },
  { field: 'marketName', header: 'Market', width: '125px' },
  { field: 'division', header: 'Division', width: '100px' },
  {
    field: 'rDFormulationCategory',
    header: 'R & D Formulation Owner Category',
    width: '240px',
  },
  { field: 'formulationType', header: 'Formulation Type', width: '150px' },
  { field: 'formulationClass', header: 'Formulation Class', width: '150px' },
  {
    field: 'formulationSubClass',
    header: 'Formulation Sub Class',
    width: '175px',
  },
  { field: 'preparationMethod', header: 'Preparation Method', width: '140px' },
  { field: 'intendedUse', header: 'Intended Use', width: '120px' },
  { field: 'multicomponent', header: 'Multi-component	', width: '140px' },
  { field: 'ra', header: 'RA', width: '135px' },
  { field: 'allRa', header: 'All R A', width: '135px' },
  { field: 'submittedByUserName', header: 'Submitted by', width: '130px' },
  { field: 'submittedDate', header: 'Submitted Date', width: '130px' },
  { field: 'assignedDate', header: 'Assigned Date', width: '120px' },
  { field: 'inProgressRADate', header: 'In Progress-RA Date', width: '165px' },
  {
    field: 'inProgressRDDate',
    header: 'In Progress – R&D Date',
    width: '165px',
  },
  {
    field: 'inProgressSIRDate',
    header: 'In Progress – SIR Date',
    width: '165px',
  },
  { field: 'rejectedDate', header: 'Rejected Date', width: '120px' },
  { field: 'deletionDate', header: 'Deletion Date', width: '120px' },
  { field: 'completedDate', header: 'Completed Date', width: '150px' },
  { field: 'rdComment', header: 'R&D Comment', width: '130px' },
  { field: 'raComment', header: 'RA Comment', width: '130px' },
  { field: 'reportComments', header: 'Report Comment', width: '130px' },
];

export const WORKLIST_COLUMNS = [
  { field: 'check', header: '', width: '25px' },
  { field: 'requestDisplayID', header: 'Request ID', width: '120px' },
  { field: 'requestStatus', header: 'Request Status', width: '110px' },
  { field: 'cucCode', header: 'CUC Code/ALT/VER', width: '160px' },
  { field: 'cucDescription', header: 'Description', width: '150px' },
  { field: 'recipeType', header: 'Recipe Type', width: '120px' },
  { field: 'recipeStatus', header: 'Recipe Status', width: '120px' },
  { field: 'marketName', header: 'Market', width: '125px' },
  { field: 'division', header: 'Division', width: '100px' },
  {
    field: 'rDFormulationCategory',
    header: 'R & D Formulation Owner Category',
    width: '240px',
  },
  { field: 'formulationType', header: 'Formulation Type', width: '150px' },
  { field: 'formulationClass', header: 'Formulation Class', width: '150px' },
  {
    field: 'formulationSubClass',
    header: 'Formulation Sub Class',
    width: '175px',
  },
  { field: 'preparationMethod', header: 'Preparation Method', width: '140px' },
  { field: 'intendedUse', header: 'Intended Use', width: '120px' },
  { field: 'multicomponent', header: 'Multi-component	', width: '140px' },
  { field: 'ra', header: 'RA', width: '135px' },
  { field: 'allRa', header: 'All R A', width: '135px' },
  { field: 'submittedByUserName', header: 'Submitted by', width: '130px' },
  { field: 'submittedDate', header: 'Submitted Date', width: '130px' },
  { field: 'assignedDate', header: 'Assigned Date', width: '120px' },
  { field: 'inProgressRADate', header: 'In Progress-RA Date', width: '165px' },
  {
    field: 'inProgressRDDate',
    header: 'In Progress – R&D Date',
    width: '165px',
  },
  {
    field: 'inProgressSIRDate',
    header: 'In Progress – SIR Date',
    width: '165px',
  },
  { field: 'rejectedDate', header: 'Rejected Date', width: '120px' },
  { field: 'deletionDate', header: 'Deletion Date', width: '120px' },
  { field: 'completedDate', header: 'Completed Date', width: '150px' },
  { field: 'rdComment', header: 'R&D Comment', width: '130px' },
  { field: 'raComment', header: 'RA Comment', width: '130px' },
  { field: 'reportComments', header: 'Report Comment', width: '130px' },
];

export const COMPONENT_MASTER_COLUMNS = [
  { field: 'multiComponentId', header: 'Component ID', width: '100px' },
  { field: 'multiComponentName', header: 'Component Name', width: '120px' },
  { field: 'isActive', header: 'Status', width: '80px' },
  { field: 'createdUserName', header: 'Created By', width: '160px' },
  { field: 'createdDate', header: 'Created Date', width: '150px' },
  { field: 'modifiedUserName', header: 'Modified By', width: '160px' },
  { field: 'modifiedDate', header: 'Modified Date', width: '150px' },
];

export const MULTI_COMPONENT = [
  { field: 'specID', header: 'Specification', width: '100px' },
  { field: 'specDescription', header: 'Spec. Description', width: '280px' },
  { field: 'specQuantity', header: 'Spec. Quantity', width: '100px' },
];

export const DOSAGE_COMPONENT = [
  { field: 'marketName', header: 'Market Name', width: '100px' },
  { field: 'dosage', header: 'Dosage', width: '280px' },
  { field: 'servingSize', header: 'Serving', width: '280px' },
  { field: 'dosageUnit', header: 'UoM', width: '100px' },
];
export const SUMMARY_MULTI_COMPONENT = [
  {
    field: 'multiComponentIngredientName',
    header: 'Specification',
    width: '100px',
  },
  {
    field: 'multispecDescription',
    header: 'Spec. Description',
    width: '280px',
  },
  {
    field: 'multiComponentIngredientQty',
    header: 'Spec. Quantity',
    width: '100px',
  },
  { field: 'multiComponentName', header: 'Component', width: '100px' },
];

export const FUNCTION_MASTER_COLUMNS = [
  { field: 'functionId', header: 'FAST Function ID', width: '100px' },
  { field: 'functionName', header: 'Function Name', width: '120px' },
  { field: 'isActive', header: 'Status', width: '80px' },
  { field: 'createdUserName', header: 'Created By', width: '160px' },
  { field: 'createdDate', header: 'Created Date', width: '150px' },
  { field: 'modifiedUserName', header: 'Modified By', width: '160px' },
  { field: 'modifiedDate', header: 'Modified Date', width: '150px' },
];
export const HELPTABLE_COLUMNS = [
  { field: 'faqId', header: 'ID', width: '50px' },
  { field: 'questionDesc', header: 'Document Name', width: '500px' },
  { field: 'division', header: 'Division', width: '120px' },
  { field: 'createdByUserName', header: 'Created By', width: '120px' },
  { field: 'createdDate', header: 'Created On', width: '120px' },
  { field: 'modifiedByUserName', header: 'Last Modified By', width: '120px' },
  { field: 'modifiedDate', header: 'Last Modified On', width: '120px' },
];

export const VIEW_RULE_TABLE_COLUMNS = [
  { field: 'check', header: '', width: '25px' },
  { field: 'ruleId', header: 'Rule ID', width: '100px' },
  { field: 'ruleVersion', header: 'Rule Version', width: '60px' },
  { field: 'pureCode', header: 'PURE Code', width: '100px' },
  { field: 'pureDescription', header: 'PURE Description', width: '125px' },
  { field: 'substanceID', header: 'SIR ID', width: '100px' },
  { field: 'substanceName', header: 'Substance Name', width: '120px' },
  { field: 'inciName', header: 'INCI', width: '75px' },
  {
    field: 'cfSubstanceExpressedAs',
    header: 'CF Substance Expressed as',
    width: '100px',
  },
  { field: 'cf', header: 'CF', width: '100px' },
  { field: 'isAllergen', header: 'Allergen (Y/N)', width: '100px' },
  { field: 'hazardClass', header: 'Hazard Classification', width: '100px' },

  { field: 'market', header: 'Market	', width: '100px' },
  { field: 'rpc', header: 'RPC Path', width: '100px' },
  { field: 'ruleClassification', header: 'Rule Category', width: '125px' },
  { field: 'ruleType', header: 'Rule Type', width: '100px' },
  {
    field: 'allowedFuncFlag',
    header: 'Allowed Function (Y/N)',
    width: '100px',
  },
  { field: 'functionName', header: 'Function', width: '100px' },
  { field: 'equalityCondition', header: 'Equality condition', width: '150px' },
  { field: 'minLimit', header: 'Minimum value', width: '80px' },
  // { field: 'equalityConditionForMax', header: 'Equality condition for maximum', width: '150px' },
  { field: 'maxLimit', header: 'Maximum value', width: '80px' },
  { field: 'uoM', header: 'Unit of Measure', width: '80px' },
  { field: 'substanceGroup', header: 'Substance Group', width: '80px' },

  // { field: 'equalityConditionForGroupMinx', header: 'Equality condition for Group Min', width: '150px' },
  // { field: 'groupMinValue', header: 'Group Minimum value', width: '100px' },
  // { field: 'equalityConditionForGroupMaxx', header: 'Equality condition for Group Max', width: '150px' },
  // { field: 'groupMaxValue', header: 'Group Maximum value', width: '100px' },
  // { field: 'groupUnitOfMeasure', header: 'Unit of Measure', width: '80px' },
  // { field: 'conflictingRegulatoryGroup', header: 'Conflicting Regulatory Group', width: '150px' },
  { field: 'isExempted', header: 'Exempted Y/N', width: '100px' },
  {
    field: 'isDosageApplicable',
    header: 'Dosage Applicable (Y/N)',
    width: '120px',
  },
  {
    field: 'isServingSizeApplicable',
    header: 'Serving Size Applicable (Y/N)',
    width: '140px',
  },
  {
    field: 'conflictingSubstance',
    header: 'Conflicting Substance',
    width: '100px',
  },
  {
    field: 'combinationSubstance',
    header: 'Combination Substance',
    width: '100px',
  },
  {
    field: 'combinationRatioToDisplay',
    header: 'Combination Ratio',
    width: '100px',
  },
  {
    field: 'specificConditionText',
    header: 'Conditional Requirements',
    width: '100px',
  },

  { field: 'claimText', header: 'Claims', width: '100px' },
  { field: 'phasedOutDate', header: 'Phasing out Date', width: '80px' },
  { field: 'warningText', header: 'Warnings', width: '100px' },
  {
    field: 'otherRestrictionsAndRequirements',
    header: 'Other Requirements and Restrictions',
    width: '150px',
  },
  { field: 'propertySource', header: 'Property Source', width: '80px' },
  { field: 'propertyClass', header: 'Property', width: '100px' },
  { field: 'propertyName', header: 'Sub property', width: '100px' },
  { field: 'ingPropertyType', header: 'ING Rule Type', width: '100px' },
  { field: 'ingPropertyName', header: 'ING Property Name', width: '100px' },
  { field: 'ruleSource', header: 'Rule Source', width: '100px' },
  { field: 'validityDate', header: 'Validity', width: '100px' },
  { field: 'yearAdopted', header: 'Year Adopted', width: '100px' },
  { field: 'cas', header: 'CAS #', width: '100px' },
  { field: 'parentCAS', header: 'Parent CAS #', width: '100px' },
  { field: 'einecs', header: 'EINECS#', width: '100px' },
  { field: 'eNumber', header: 'E #', width: '100px' },
  { field: 'ins', header: 'INS #', width: '100px' },
  { field: 'preparationMethod', header: 'Preparation Method	', width: '100px' },
  { field: 'intendedUse', header: 'Intended Use', width: '100px' },
  { field: 'createdByUserDisplayName', header: 'Created By', width: '100px' },
  { field: 'createdDate', header: 'Created Date', width: '100px' },
  { field: 'modifiedByUserDisplayName', header: 'Modified By', width: '100px' },
  { field: 'modifiedDate', header: 'Modified Date', width: '100px' },
];

export const SELECT_RECIPE_COLUMNS = [
  { field: 'cucCode', header: 'CUC', width: '100px' },
  { field: 'cucAlt', header: 'ALT', width: '120px' },
  { field: 'cucVer', header: 'VER', width: '80px' },
  { field: 'recipeDescription', header: 'Description', width: '160px' },
  { field: 'recipeType', header: 'Recipe Type', width: '150px' },
  { field: 'recipeStatus', header: 'Recipe Status', width: '160px' },
  { field: 'validFromDate', header: 'Valid From', width: '150px' },
  { field: 'validToDate', header: 'Valid To', width: '150px' },
];
export const SUBSTANCE_SEARCH_COLUMNS = [
  { field: 'pureCode', header: 'Pure Code', width: '150px' },
  { field: 'substanceName', header: 'Substance Name', width: '120px' },
  { field: 'marketName', header: 'Market	', width: '100px' },
  { field: 'sirId', header: 'SIR ID', width: '120px' },
  { field: 'hazardClassification', header: 'Hazard Classification', width: '150px' },
  { field: 'ruleCount', header: 'Count of rules', width: '100px' },
];
export const SUBSTANCE_SEARCH_REGULATION_COLUMNS = [
  { field: 'market', header: 'Market', width: '80px' },
  { field: 'rpc', header: 'RPC Path', width: '280px' },
  { field: 'functionName', header: 'Function', width: '120px' },
  { field: 'ruleType', header: 'Rule Type	', width: '120px' },
  { field: 'keyParameter', header: 'Rule Value', width: '140px' },
  { field: 'uoM', header: 'Unit of Measure', width: '50px' },
  { field: 'commentstoDisplay', header: 'Rule Description', width: '140px' },
  { field: 'ruleId', header: 'Rule Id', width: '50px' },
];

export const SUBSTANCE_SEARCH_REGULATION_COLUMNS_EXCEL = [
  [
    { field: 'pureCode', header: 'Pure Code', width: '150px' },
    { field: 'substanceName', header: 'Substance Name', width: '120px' },
    { field: 'marketName', header: 'Market	', width: '100px' },
    { field: 'sirId', header: 'SIR ID', width: '120px' },
    { field: 'hazardClassification', header: 'Hazard Classification', width: '150px' },
    { field: 'ruleCount', header: 'Count of rules', width: '100px' },
  ],
  [
    { field: 'market', header: 'Market', width: '80px' },
    { field: 'rpc', header: 'RPC Path', width: '280px' },
    { field: 'functionName', header: 'Function', width: '120px' },
    { field: 'ruleType', header: 'Rule Type	', width: '120px' },
    { field: 'keyParameterExcel', header: 'Rule Value', width: '140px' },
    { field: 'uoM', header: 'Unit of Measure', width: '50px' },
    { field: 'commentstoDisplayExcel', header: 'Rule Description', width: '140px' },
    { field: 'ruleId', header: 'Rule Id', width: '50px' },
    { field: 'rulevalueText1', header: 'Rule Value Link 1', width: 25, show: 'N' },
    { field: 'rulevalueText2', header: 'Rule Value Link 2', width: 25, show: 'N' },
    { field: 'rulevalueText3', header: 'Rule Value Link 3', width: 25, show: 'N' },
    { field: 'rulevalueText4', header: 'Rule Value Link 4', width: 25, show: 'N' },
    { field: 'rulevalueText5', header: 'Rule Value Link 5', width: 25, show: 'N' },
    { field: 'rulevalueText6', header: 'Rule Value Link 6', width: 25, show: 'N' },
    { field: 'rulevalueText7', header: 'Rule Value Link 7', width: 25, show: 'N' },
  ]
];

export const VIEW_MINIMUM_RULES_COLUMNS = [
  { field: 'ruleId', header: 'Rule ID', width: '70px' },
  { field: 'ruleVersion', header: 'Rule Version', width: '100px' },
  { field: 'substanceID', header: 'SIR ID', width: '100px' },
  { field: 'substanceName', header: 'Substance Name', width: '120px' },
  { field: 'cf', header: 'CF', width: '100px' },
  { field: 'market', header: 'Market	', width: '100px' },
  { field: 'rpc', header: 'RPC Path', width: '100px' },
  { field: 'ruleType', header: 'Rule Type', width: '100px' },
  { field: 'functionName', header: 'Function', width: '100px' },
  { field: 'minLimit', header: 'Minimum value', width: '120px' },
  { field: 'equalityCondition', header: 'Equality condition', width: '150px' },
  { field: 'maxLimit', header: 'Maximum value', width: '120px' },
  { field: 'uoM', header: 'Unit of Measure', width: '120px' },
  { field: 'warningText', header: 'Warnings', width: '100px' },
  { field: 'warningicon', header: '', width: '12px', filter: 'N' },
  { field: 'claimText', header: 'Claims', width: '100px' },
  { field: 'specificConditionText', header: 'Conditional Requirements', width: '180px', },
  { field: 'hazardClass', header: 'Hazard Classification', width: '150px' },
  { field: 'warningText1', header: 'Warnings Link 1', width: 25, show: 'N' },
  { field: 'warningText2', header: 'Warnings Link 2', width: 25, show: 'N' },
  { field: 'warningText3', header: 'Warnings Link 3', width: 25, show: 'N' },
  { field: 'warningText4', header: 'Warnings Link 4', width: 25, show: 'N' },
  { field: 'warningText5', header: 'Warnings Link 5', width: 25, show: 'N' },
  { field: 'warningText6', header: 'Warnings Link 6', width: 25, show: 'N' },
  { field: 'warningText7', header: 'Warnings Link 7', width: 25, show: 'N' },

];
export const VIEW_MINIMUM_RULES_COLUMNS_EXCEL = [
  { field: 'ruleId', header: 'Rule ID', width: '70px' },
  { field: 'ruleVersion', header: 'Rule Version', width: '100px' },
  { field: 'substanceID', header: 'SIR ID', width: '100px' },
  { field: 'substanceName', header: 'Substance Name', width: '120px' },
  { field: 'cf', header: 'CF', width: '100px' },
  { field: 'market', header: 'Market	', width: '100px' },
  { field: 'rpc', header: 'RPC Path', width: '100px' },
  { field: 'ruleType', header: 'Rule Type', width: '100px' },
  { field: 'functionName', header: 'Function', width: '100px' },
  { field: 'minLimit', header: 'Minimum value', width: '120px' },
  { field: 'equalityCondition', header: 'Equality condition', width: '150px' },
  { field: 'maxLimit', header: 'Maximum value', width: '120px' },
  { field: 'uoM', header: 'Unit of Measure', width: '120px' },
  { field: 'warningTextStripped', header: 'Warnings', width: '100px' },
  { field: 'claimText', header: 'Claims', width: '100px' },
  { field: 'specificConditionText', header: 'Conditional Requirements', width: '180px' },
  { field: 'hazardClass', header: 'Hazard Classification', width: '150px' },
  { field: 'warningText1', header: 'Warnings Link 1', width: 25 },
  { field: 'warningText2', header: 'Warnings Link 2', width: 25 },
  { field: 'warningText3', header: 'Warnings Link 3', width: 25 },
  { field: 'warningText4', header: 'Warnings Link 4', width: 25 },
  { field: 'warningText5', header: 'Warnings Link 5', width: 25 },
  { field: 'warningText6', header: 'Warnings Link 6', width: 25 },
  { field: 'warningText7', header: 'Warnings Link 7', width: 25 },
];

export const VIEW_PRODUCT_SUBSTANCE_RULE_COLUMNS = [
  { field: 'ruleId', header: 'Rule ID', width: '100px' },
  { field: 'ruleVersion', header: 'Rule Version', width: '150px' },
  { field: 'pureCode', header: 'Pure Code', width: '100px' },
  { field: 'pureDescription', header: 'Pure Description', width: '100px' },
  { field: 'sirId', header: 'SIR ID', width: '100px' },
  { field: 'substanceName', header: 'Substance Name', width: '120px' },
  { field: 'ruleType', header: 'Rule Type', width: '200px' },
  { field: 'functionName', header: 'Function', width: '100px' },
  { field: 'warningText', header: 'Warnings', width: '100px' },
  { field: 'warningicon', header: '', width: '14px', filter: 'N' },
  { field: 'warningText1', header: 'Warnings Link 1', width: 25, show: 'N' },
  { field: 'warningText2', header: 'Warnings Link 2', width: 25, show: 'N' },
  { field: 'warningText3', header: 'Warnings Link 3', width: 25, show: 'N' },
  { field: 'warningText4', header: 'Warnings Link 4', width: 25, show: 'N' },
  { field: 'warningText5', header: 'Warnings Link 5', width: 25, show: 'N' },
  { field: 'warningText6', header: 'Warnings Link 6', width: 25, show: 'N' },
  { field: 'warningText7', header: 'Warnings Link 7', width: 25, show: 'N' },
];

export const VERSION_HISTORY_COLUMNS = [
  // { field: 'ruleType', header: 'Rule Type', width: '80px' },
  { field: 'ruleVersion', header: 'Rule Version', width: '100px' },
  { field: 'ruleAction', header: 'Rule Action', width: '100px' },
  { field: 'userName', header: 'Modified By', width: '90px' },
  { field: 'updatedDate', header: 'Modified Date', width: '100px' },
  { field: 'comments', header: 'Comment', width: '120px' },
  { field: 'pureCode', header: 'PURE Code', width: '100px' },
  { field: 'sirID', header: 'SIR ID', width: '100px' },
  { field: 'validityStartDateNewValue', header: 'Validity Start Date', width: '120px' },
  { field: 'validityDateNewValue', header: 'Validity End Date', width: '120px' },
  { field: 'functionNewValue', header: 'Function', width: '100px' },
  { field: 'minNewValue', header: 'Minimum value', width: '120px' },
  {
    field: 'eqCondtitionNewValue',
    header: 'Equality condition',
    width: '90px',
  },
  { field: 'maxNewValue', header: 'Maximum value', width: '120px' },
  { field: 'uoMnewValue', header: 'Unit of Measure', width: '90px' },
  { field: 'contributionFactor', header: 'CF', width: '100px' },
  { field: 'numberOfExpressedAs', header: 'CF Expressed As', width: '120px' },
  { field: 'groupName', header: 'Substance Group Name', width: '70px' },
  { field: 'isExempted', header: 'Exempted', width: '100px' },
  {
    field: 'confCobSirID',
    header: 'Combination/Conflicting Substances',
    width: '140px',
  },
  {
    field: 'combinationRatioToDisplay',
    header: 'Combination Ratio',
    width: '120px',
  },
  { field: 'parentProperty', header: 'Property', width: '100px' },
  { field: 'propertyName', header: 'Sub Property', width: '100px' },
  { field: 'warningNewValue', header: 'Warnings', width: '90px' },
  { field: 'claimNewValue', header: 'Claims', width: '90px' },
  {
    field: 'specialConditionNewValue',
    header: 'Conditional Requirements',
    width: '120px',
  },
];
export const VERSION_HISTORY_EXPORT_COLUMNS = [
  { field: 'ruleVersion', header: 'Rule Version', width: '100px' },
  { field: 'ruleAction', header: 'Rule Action', width: '100px' },
  { field: 'userName', header: 'Modified By', width: '90px' },
  { field: 'updatedDate', header: 'Modified Date', width: '100px' },
  { field: 'comments', header: 'Comment', width: '120px' },
  { field: 'pureCode', header: 'PURE Code', width: '100px' },
  { field: 'sirID', header: 'SIR ID', width: '100px' },
  { field: 'validityStartDateNewValue', header: 'Validity Start Date', width: '120px' },
  { field: 'validityDateNewValue', header: 'Validity End Date', width: '120px' },
  { field: 'functionNewValue', header: 'Function', width: '100px' },
  { field: 'minNewValue', header: 'Minimum value', width: '120px' },
  { field: 'eqCondtitionNewValue', header: 'Equality condition', width: '90px' },
  { field: 'maxNewValue', header: 'Maximum value', width: '120px' },
  { field: 'uoMnewValue', header: 'Unit of Measure', width: '90px' },
  { field: 'contributionFactor', header: 'CF', width: '100px' },
  { field: 'numberOfExpressedAs', header: 'CF Expressed As', width: '120px' },
  { field: 'groupName', header: 'Substance Group Name', width: '70px' },
  { field: 'isExempted', header: 'Exempted', width: '100px' },
  { field: 'confCobSirID', header: 'Combination/Conflicting Substances', width: '140px' },
  { field: 'combinationRatioToDisplay', header: 'Combination Ratio', width: '120px' },
  { field: 'parentProperty', header: 'Property', width: '100px' },
  { field: 'propertyName', header: 'Sub Property', width: '100px' },
  { field: 'warningTextStrippedExcel', header: 'Warnings', width: '90px' },
  { field: 'claimNewValue', header: 'Claims', width: '90px' },
  { field: 'specialConditionNewValue', header: 'Conditional Requirements', width: '120px' },
  { field: 'warningText1', header: 'Warnings Link 1', width: 25, show: 'N' },
  { field: 'warningText2', header: 'Warnings Link 2', width: 25, show: 'N' },
  { field: 'warningText3', header: 'Warnings Link 3', width: 25, show: 'N' },
  { field: 'warningText4', header: 'Warnings Link 4', width: 25, show: 'N' },
  { field: 'warningText5', header: 'Warnings Link 5', width: 25, show: 'N' },
  { field: 'warningText6', header: 'Warnings Link 6', width: 25, show: 'N' },
  { field: 'warningText7', header: 'Warnings Link 7', width: 25, show: 'N' },
];

export const PROPERTY_LIST_COLUMNS = [
  { field: 'propertyId', header: 'FAST Property Id', width: '100px' },
  { field: 'propertyName', header: 'Property Name', width: '100px' },
  { field: 'createdUserName', header: 'Created By', width: '100px' },
  { field: 'createdDate', header: 'Created Date', width: '100px' },
  { field: 'isActive', header: 'Usage Status', width: '100px' },
  { field: 'modifiedUserName', header: 'Modified By', width: '100px' },
  { field: 'modifiedDate', header: 'Modified Date', width: '100px' },
];
export const TFC_IFRA_COLUMNS = [
  { field: 'id', header: 'ID', width: '50px' },
  { field: 'upd', header: 'UPD', width: '50px' },
  { field: 'formulationCategory', header: 'Formulation Category', width: '150px' },
  { field: 'formulationType', header: 'Formulation Type', width: '150px' },
  { field: 'formulationClass', header: 'Formulation Class', width: '150px' },
  { field: 'formulationSubClass', header: 'Formulation Sub Class', width: '150px' },
  { field: 'ifracategory', header: 'IFRA Category', width: '80px' },
  { field: 'isActive', header: 'Status', width: '80px' },
  { field: 'createdUserName', header: 'Created By', width: '100px' },
  { field: 'createdDate', header: 'Created Date', width: '100px' },
  { field: 'modifiedUserName', header: 'Modified By', width: '100px' },
  { field: 'modifiedDate', header: 'Modified Date', width: '100px' },
];
export const REGIONAL_COMPLAINS_COLUMNS = [
  { field: 'Id', header: 'ID', width: '50px' },
  { field: 'regionName', header: 'Regulation', width: '150px' },
  { field: 'productType', header: 'Product Type', width: '150px' },
  { field: 'upd', header: 'UPD', width: '60px' },
  { field: 'formulationCategory', header: 'Formulation Category', width: '150px' },
  { field: 'formulationType', header: 'Formulation Type', width: '150px' },
  { field: 'formulationClass', header: 'Formulation Class', width: '150px' },
  { field: 'formulationSubClass', header: 'Formulation Sub Class', width: '150px' },
  { field: 'isActive', header: 'Status', width: '60px' },
  { field: 'createdUserName', header: 'Created By', width: '100px' },
  { field: 'createdDate', header: 'Created Date', width: '100px' },
  { field: 'modifiedUserName', header: 'Modified By', width: '100px' },
  { field: 'modifiedDate', header: 'Modified Date', width: '100px' },
];
export const PLM_FAST_MARKET_COLUMNS = [
  { field: 'Id', header: 'ID', width: '60px' },
  { field: 'regionName', header: 'Regulation', width: '180px' },
  { field: 'marketName', header: 'FAsT Market', width: '100px' },
  { field: 'isActive', header: 'Status', width: '100px' },
  { field: 'createdUserName', header: 'Created By', width: '100px' },
  { field: 'createdDate', header: 'Created Date', width: '100px' },
  { field: 'modifiedUserName', header: 'Modified By', width: '100px' },
  { field: 'modifiedDate', header: 'Modified Date', width: '100px' },
];
export const FRAGRANCE_EXCEL_COLUMNS =
  [
    [
      { field: 'ingID', header: 'Ingredient Code', width: '90px' },
      { field: 'ingDescription', header: 'Ingredient Desc', width: '120px' },
      { field: 'specType', header: 'Spec Type', width: '100px' },
      { field: 'ifracategory', header: 'IFRA Category', width: '100px' },
      { field: 'amendmentNumber', header: 'Amendment Number', width: '120px' },
      { field: 'ifraMaxUseLimit', header: 'IFRA Max (%)', width: '90px' },
      { field: 'formulationCategory', header: 'Formulation Category', width: '140px' },
      { field: 'formulationType', header: 'Formulation Type', width: '110px' },
      { field: 'formulationClass', header: 'Formulation Class', width: '120px' },
      { field: 'formulationSubClass', header: 'Formulation Sub-Class', width: '120px' }
    ],
    [
      { field: 'ingID', header: 'Ingredient Code', width: '120px' },
      { field: 'ingDescription', header: 'Ingredient Desc', width: '150px' },
      { field: 'specType', header: 'Spec Type', width: '130px' },
      { field: 'marketName', header: 'Market', width: '100px' },
      { field: 'productType', header: 'Product Type', width: '80px' },
      { field: 'maxUseLimit', header: 'Max use (%)', width: '70px' }
    ],
    [
      { field: 'ingID', header: 'Ingredient Code', width: '120px' },
      { field: 'ingDescription', header: 'Ingredient Desc', width: '150px' },
      { field: 'specType', header: 'Spec Type', width: '130px' },
      { field: 'marketName', header: 'Market', width: '100px' },
      { field: 'casNumber', header: 'Ingredient CAS Number', width: '80px' },
      { field: 'percentPresent', header: '% Present', width: '70px' }
    ]
  ];
export const WORKLIST_EXCEL_ADDITIONAL_COLUMNS = [
  { fieldName: 'rdcommentLink1', fieldDescription: 'R&D Comment Link 1' },
  { fieldName: 'rdcommentLink2', fieldDescription: 'R&D Comment Link 2' },
  { fieldName: 'rdcommentLink3', fieldDescription: 'R&D Comment Link 3' },
  { fieldName: 'rdcommentLink4', fieldDescription: 'R&D Comment Link 4' },
  { fieldName: 'rdcommentLink5', fieldDescription: 'R&D Comment Link 5' },
  { fieldName: 'rdcommentLink6', fieldDescription: 'R&D Comment Link 6' },
  { fieldName: 'rdcommentLink7', fieldDescription: 'R&D Comment Link 7' }
];

export const WORKLIST_EXCEL_ADDITIONAL_COLUMNS_REPORT = [

  { fieldName: 'reportCommentsLink1', fieldDescription: 'RA Assessment Summary Link 1' },
  { fieldName: 'reportCommentsLink2', fieldDescription: 'RA Assessment Summary Link 2' },
  { fieldName: 'reportCommentsLink3', fieldDescription: 'RA Assessment Summary Link 3' },
  { fieldName: 'reportCommentsLink4', fieldDescription: 'RA Assessment Summary Link 4' },
  { fieldName: 'reportCommentsLink5', fieldDescription: 'RA Assessment Summary Link 5' },
  { fieldName: 'reportCommentsLink6', fieldDescription: 'RA Assessment Summary Link 6' },
  { fieldName: 'reportCommentsLink7', fieldDescription: 'RA Assessment Summary Link 7' }
];

export const VIEWRULE_EXCEL_ADDITIONAL_COLUMNS_WARNINGS = [
  { fieldName: 'warningText1', fieldDescription: 'Warnings Link 1', width: 25 },
  { fieldName: 'warningText2', fieldDescription: 'Warnings Link 2', width: 25 },
  { fieldName: 'warningText3', fieldDescription: 'Warnings Link 3', width: 25 },
  { fieldName: 'warningText4', fieldDescription: 'Warnings Link 4', width: 25 },
  { fieldName: 'warningText5', fieldDescription: 'Warnings Link 5', width: 25 },
  { fieldName: 'warningText6', fieldDescription: 'Warnings Link 6', width: 25 },
  { fieldName: 'warningText7', fieldDescription: 'Warnings Link 7', width: 25 },
];

export const VIEWRULE_EXCEL_ADDITIONAL_COLUMNS_ORR = [
  { fieldName: 'ORR1', fieldDescription: 'Other Requirements and Restrictions Link 1', width: 45 },
  { fieldName: 'ORR2', fieldDescription: 'Other Requirements and Restrictions Link 2', width: 45 },
  { fieldName: 'ORR3', fieldDescription: 'Other Requirements and Restrictions Link 3', width: 45 },
  { fieldName: 'ORR4', fieldDescription: 'Other Requirements and Restrictions Link 4', width: 45 },
  { fieldName: 'ORR5', fieldDescription: 'Other Requirements and Restrictions Link 5', width: 45 },
  { fieldName: 'ORR6', fieldDescription: 'Other Requirements and Restrictions Link 6', width: 45 },
  { fieldName: 'ORR7', fieldDescription: 'Other Requirements and Restrictions Link 7', width: 45 },
];
