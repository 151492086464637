import { InjectionToken } from '@angular/core';

export const CONFIG_INJECTOR = new InjectionToken<any>('ConfigInjector');

export class EnvironmentConfiguration {

  public configuration = {
    production: false,
    APIFAsT: '',
    tenant: '',
    clientId: '',
    endpoints: '',
    OAUTH_TOKEN_ENDPOINT: '',
    redirectUri: '',
    navigateToLoginRequestUrl: false,
    cacheLocation: 'sessionStorage'
  };

  static Get(): EnvironmentConfiguration {
    return new EnvironmentConfiguration();
  }
  constructor() {
    const urlVal = window.location.href;

    if (urlVal.includes('ara-fast-dev.unilever.com')) {
      this.configuration.production = false;
      this.configuration.tenant = 'f66fae02-5d36-495b-bfe0-78a6ff9f8e6e';
      this.configuration.endpoints = 'https://ara-fast-dev.unilever.com';
      this.configuration.OAUTH_TOKEN_ENDPOINT = 'https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e/oauth2/v2.0/token';
      this.configuration.APIFAsT = 'https://ara-fast-dev.unilever.com/fastapi/ara/fast/v1';
      this.configuration.clientId = '0cfe1f98-00f0-4087-9eb1-bc077b311332';
    } else if (urlVal.includes('ara-fast-qa.unilever.com')) {
      this.configuration.production = false;
      this.configuration.tenant = 'f66fae02-5d36-495b-bfe0-78a6ff9f8e6e';
      this.configuration.endpoints = 'https://ara-fast-qa.unilever.com';
      this.configuration.OAUTH_TOKEN_ENDPOINT = 'https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e/oauth2/v2.0/token';
      this.configuration.APIFAsT = 'https://ara-fast-qa.unilever.com/fastapi/ara/fast/v1';
      this.configuration.clientId = '035998cf-a903-4a09-8417-b2bc96f35796';
    } else if (urlVal.includes('ara-fast-test.unilever.com')) {
      this.configuration.production = false;
      this.configuration.tenant = 'f66fae02-5d36-495b-bfe0-78a6ff9f8e6e';
      this.configuration.endpoints = 'https://ara-fast-test.unilever.com';
      this.configuration.OAUTH_TOKEN_ENDPOINT = 'https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e/oauth2/v2.0/token';
      this.configuration.APIFAsT = 'https://ara-fast-test.unilever.com/fastapi/ara/fast/v1';
      this.configuration.clientId = 'f52e2cbc-01b9-4cf2-9c75-9448bbe6efc8';
    } else if (urlVal.includes('ara-fast-training.unilever.com')) {
      this.configuration.production = false;
      this.configuration.tenant = 'f66fae02-5d36-495b-bfe0-78a6ff9f8e6e';
      this.configuration.endpoints = 'https://ara-fast-training.unilever.com';
      this.configuration.OAUTH_TOKEN_ENDPOINT = 'https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e/oauth2/v2.0/token';
      this.configuration.APIFAsT = 'https://ara-fast-training.unilever.com/fastapi/ara/fast/v1';
      this.configuration.clientId = '48f92423-3cb5-4360-af5a-f462f23de1ce';

    } else if (urlVal.includes('ara-fast.unilever.com')) {
      this.configuration.production = true;
      this.configuration.tenant = 'f66fae02-5d36-495b-bfe0-78a6ff9f8e6e';
      this.configuration.endpoints = 'https://ara-fast.unilever.com';
      this.configuration.OAUTH_TOKEN_ENDPOINT = 'https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e/oauth2/v2.0/token';
      this.configuration.APIFAsT = 'https://ara-fast.unilever.com/fastapi/ara/fast/v1';
      this.configuration.clientId = '37eb5b50-d077-400e-8f61-6ba037b57337';
    } else if (urlVal.includes('ara-fast-regression.unilever.com')) {
      this.configuration.production = false;
      this.configuration.tenant = 'f66fae02-5d36-495b-bfe0-78a6ff9f8e6e';
      this.configuration.endpoints = 'https://ara-fast-regression.unilever.com';
      this.configuration.OAUTH_TOKEN_ENDPOINT = 'https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e/oauth2/v2.0/token';
      this.configuration.APIFAsT = 'https://ara-fast-regression.unilever.com/fastapi/ara/fast/v1';
      this.configuration.clientId = '0cb883f0-9b9b-4648-a5b5-b37653bba0af';
    } else if (urlVal.includes('localhost:4200/')) {
      this.configuration.production = false;
      this.configuration.tenant = 'f66fae02-5d36-495b-bfe0-78a6ff9f8e6e';
      this.configuration.endpoints = 'http://localhost:4200/';
      this.configuration.OAUTH_TOKEN_ENDPOINT = 'https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e/oauth2/v2.0/token';
      this.configuration.APIFAsT = 'https://ara-fast-dev.unilever.com/fastapi/ara/fast/v1';
      this.configuration.clientId = '0cfe1f98-00f0-4087-9eb1-bc077b311332';
    }
    this.configuration.redirectUri = this.configuration.endpoints;
  }
}
