import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthorizationService } from './authorization.service';
// import { AuthGroup } from '../models/authorization.types';

@Injectable({
    providedIn: 'root',
})
export class AuthorizationRouteGuard implements CanActivate {

    constructor(protected router: Router,
                protected authorizationService: AuthorizationService) { }
    /* istanbul ignore next */
    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
        return this.hasRequiredPermission(route.data['auth']);
    }
    /* istanbul ignore next */
    protected hasRequiredPermission(permissions: string): Promise<boolean> | boolean {
        // If user’s permissions already retrieved from the API
        if (this.authorizationService.permissions) {
            if (permissions) {
                return this.authorizationService.hasPermission(permissions);
            } else {
                return this.authorizationService.hasPermission(null);
            }
        }
        // else {
        //     // Otherwise, must request permissions from the API first
        //     const promise = new Promise<boolean>((resolve, reject) => {
        //         this.authorizationService.initializePermissions()
        //             .then(() => {
        //                 if (permissions) {
        //                     resolve(this.authorizationService.hasPermission(permissions));
        //                } else {
        //                     resolve(this.authorizationService.hasPermission(null));
        //                 }
        //             }).catch(() => {
        //                 resolve(false);
        //             });
        //     });
        //     return promise;
        // }
    }
}
