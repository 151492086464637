
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, FormBuilder, FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { AssessRequestRaService } from '../assess-request-ra/assess-request-ra.service';
import { VERSION_HISTORY_COLUMNS, VIEW_RULE_TABLE_COLUMNS, VERSION_HISTORY_EXPORT_COLUMNS, VIEWRULE_EXCEL_ADDITIONAL_COLUMNS_WARNINGS, VIEWRULE_EXCEL_ADDITIONAL_COLUMNS_ORR } from '../common/models/tableColumns';
import { DataService } from '../common/services/data.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { WorklistService } from '../worklist/worklist.service';
import { ruleFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-view-rules',
  templateUrl: './view-rules.component.html',
  styleUrls: ['./view-rules.component.scss'],
  providers: [DatePipe]
})
export class ViewRulesComponent implements OnInit {

  @ViewChild('rowHoverDiv') rowHoverDiv: ElementRef;
  @ViewChild('InputForm') InputForm: NgForm;
  loading = false;
  loadingMsg = 'Fetching data from FAsT Rule-Engine';
  overlayText = '';
  hideOverlay = true;
  maxRowsSelectCount;
  maxMarketRPCsCount;
  viewRuleTableData = [];
  selectedUPDs = [];
  emailUserId = [];
  viewRuleColumns = [];
  sameMRPCs: any = [];
  selectedRows: any = [];
  selectedRules: any = [];
  removeDuplicateRuleId: any = [];
  isCopyRulesDisable = true;
  ruleSelected = false;
  maximum10MRPcs = false;
  isSameMarketRPCAvailable = false;
  copyMultipleRules: any = [];
  filteredRows: any = [];
  flotingDivFlag = false;
  isCopyRuleDisable = true;
  isDeleteRulesDisable = true;
  isRPCIDThere = true;
  rowData;
  selectedRuleData: any;
  marketToDD = [];
  marketToDDForCopyRules = [];
  userDetails;
  selectedMarkets = [];
  isSuperAdminOrGlobalRA = false;
  viewRuleBtnDisableFlag = true;
  onEditRules = false;
  onViewRulesFlag = false;
  componentNameTab;
  public steps: string[];
  public selectedStep;
  deleteRulePopup = false;
  deleteGroupRulePopup = false;
  groupRuleFlag = false;
  viewRuleColumnsOptions: any[];
  userForm: FormGroup;
  columnsAdded: any[] = [];
  columnToBeAddedDetails: any[] = [];
  columnListToAdd: any;
  allViewRuleColumns: any;
  viewRuleColumnsCustomisedToShow: any[] = [];
  settings: any;
  selectedColumns: any[];
  columnsDialog = false;
  position: string;
  viewRuleslistTableColumns = [];
  viewRuleslistTableColumnsExcel = [];
  viewRuleslistColumnsOptions: any[];
  columizeLoading = false;
  columizeClear = [];
  deleteRuleComment = [];
  ruleCommentFlag = false;
  versionHistoryDisplay = false;
  selectMktRpcDialogForCopy = false;
  rpcLevels;
  rpcPathResults;
  RpcPathsForDD = [];
  ruleDetailsToCopy = 0;
  copyRuleResponse;
  copyRuleResponseDialog = false;
  selectedMarketForCopy;
  myWorklistTableColumns = [];
  versionHistoryColumns = [];
  versionHistoryExportColumns = [];
  prepareRuleTableData = [];
  selectedRowEmail = [];
  copyRuleSuccess = [];
  copyRuleFailure = [];
  shortVersionHistoryDetails = [];
  versionHistoryDetails = [];
  verHistoryDetailsDialog = false;
  columnLength;
  selectedrpc: '';
  star = 'fa fa-fw fa-search';
  filteredRpcData = [];
  viewRuleRpcPath = [];
  selectedRpcForViewRule = [];
  userbasedMarkets = [];
  emailComments = '';
  sendEmailToRnDPopUp = false;
  prefMsg = 'Create Market RPC/TFC-Market RPC mapping';
  exportDialog = false;
  childRpcCheckBox = false;
  gearActionDD = false;
  activeRules = false;
  viewRuleTableActionCount = 0;
  reActivatePopUp = false;
  validityStartDate: any = '';
  validityEndDate: any = '';
  validitynewdate: any = '';
  defaultValidityFromDate;
  defaultValidityEndDate;
  todayDate;
  reActivateComments;
  authorizedRuleforDelete = [];
  unauthorizedRuleforDelete = [];
  tempArray: any = [];
  showRelatedRequest: any = [];
  addColumnButton = false;
  warningTextOpen: boolean;
  tablewarningComment: any;
  commentRD = '';
  orrTextOpen: boolean;
  tableORRComment: any;
  isColumnsHasHyperLinks = 'No';
  HyperLinksStartColumnNumber = 0;
  issubstanceSearch = false;
  isVersionHistoryColumnsHasHyperLinks = 'Yes';
  versionHistoryCount = 0;
  // isClickHere = false;
  constructor(
    private apiService: AssessRequestRaService,
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    public matSnackBar: MatSnackBar,
    private worklistservice: WorklistService,
    private fb: FormBuilder, private datePipe: DatePipe,
  ) {
    this.userForm = this.fb.group({
      name: [],
      column: this.fb.array([
        this.fb.control(null)
      ])
    });
  }
  /* istanbul ignore next */
  ngOnInit() {
    this.todayDate = new Date();
    this.ruleCommentFlag = true;
    this.isVersionHistoryColumnsHasHyperLinks = 'Yes';
    this.defaultValidityFromDate = this.datePipe.transform(this.todayDate, 'longDate');
    this.defaultValidityEndDate = this.datePipe.transform(new Date('9999-12-31'), 'longDate');
    this.onEditRules = false;
    this.onViewRulesFlag = false;
    this.activeRules = true;
    this.viewRuleColumns = VIEW_RULE_TABLE_COLUMNS;
    this.versionHistoryColumns = VERSION_HISTORY_COLUMNS;
    this.versionHistoryExportColumns = VERSION_HISTORY_EXPORT_COLUMNS;
    this.versionHistoryCount = VERSION_HISTORY_EXPORT_COLUMNS.length - 7;
    this.columnLength = this.versionHistoryColumns.length;
    this.settings = {
      singleSelection: false,
      idField: 'fieldName',
      textField: 'fieldDescription',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Un Select All',
      allowSearchFilter: true,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Column to add',
      closeDropDownOnSelection: true,
      itemsShowLimit: 2
    };
    this.getUserBasedMarkets();
    this.getColumnCustomization();
    this.getWorklistColumns();
    this.getMRPCsCount();
    this.issubstanceSearch = this.dataService.isfromSubstancesearch;
    if (this.dataService.fromSubstanceData && this.issubstanceSearch) {
      this.onViewRulesFlag = true;
      this.dataService.fromSubstanceData.orginalRuleVersion = this.dataService.fromSubstanceData.ruleVersion;
      this.dataService.fromSubstanceData.ruleVersion = 'V' + this.dataService.fromSubstanceData.ruleVersion;
      this.dataService.fromSubstanceData.isAllergen = this.dataService.fromSubstanceData.isAllergen === 'Y' ? 'Y' : '';
      this.dataService.fromSubstanceData.allowedFuncFlag = this.dataService.fromSubstanceData.ruleTypeID === 14 ? 'Y' : 'N';
      this.editRule(this.dataService.fromSubstanceData);
    }
  }
  getMRPCsCount() {
    const apiurl = environment.APIFAsT + '/appinfo/maxrulecopytolimit';
    this.apiService.getData(apiurl).subscribe(response => {
      this.maxRowsSelectCount = (response !== null) ? response['MaxRuleCopyToLimit'] : 10;
      this.maxMarketRPCsCount = (response !== null) ? response['MaxRuleCopyToLimit'] : 10;
    });
  }
  getColumnCustomization() {
    this.loading = true;
    const apiurl = environment.APIFAsT + '/getcolumncustomization/viewrule';
    this.apiService.getData(apiurl).subscribe((response) => {
      this.viewRuleColumnsCustomisedToShow = response.filter(item => 'fieldName' in item);
      this.getviewRuleColumns();
    });

  }
  getviewRuleColumns() {
    this.allViewRuleColumns = [];
    const apiurl = environment.APIFAsT + '/getworklistcolumns/viewrule';
    this.apiService.getData(apiurl).subscribe((response) => {
      this.allViewRuleColumns = response.filter(item => 'fieldName' in item);
      this.getColumnDetailsOnload();
    });
  }
  initiateColumnOptions() {
    this.columnsAdded = [];
    this.columnListToAdd = this.allViewRuleColumns.filter(item => 'fieldName' in item);
    if (this.viewRuleColumnsCustomisedToShow.length) {
      this.columnsAdded = this.viewRuleColumnsCustomisedToShow;
      VIEWRULE_EXCEL_ADDITIONAL_COLUMNS_WARNINGS.forEach(col => {
        this.columnsAdded = this.columnsAdded.filter(x => x.fieldName !== col.fieldName);
      });
      VIEWRULE_EXCEL_ADDITIONAL_COLUMNS_ORR.forEach(col => {
        this.columnsAdded = this.columnsAdded.filter(x => x.fieldName !== col.fieldName);
      });
    } else {
      this.allViewRuleColumns.forEach(column => {
        if (column.fieldName === 'check' ||
          column.fieldName === 'ruleId' || column.fieldName === 'market' || column.fieldName === 'rpc') {
          this.columnsAdded.push(column);
        }
      });
    }
    this.columnsAdded.forEach(col => {
      this.columnListToAdd = this.columnListToAdd.filter(x => x.fieldName !== col.fieldName);
    });
    VIEWRULE_EXCEL_ADDITIONAL_COLUMNS_WARNINGS.forEach(col => {
      this.columnListToAdd = this.columnListToAdd.filter(x => x.fieldName !== col.fieldName);
    });
    VIEWRULE_EXCEL_ADDITIONAL_COLUMNS_ORR.forEach(col => {
      this.columnListToAdd = this.columnListToAdd.filter(x => x.fieldName !== col.fieldName);
    });
    if (this.columnListToAdd.length > 0) {
      this.addColumnButton = false;
    } else if (this.columnListToAdd.length < 1) {
      this.addColumnButton = true;
    }
  }
  getColumnDetailsOnload() {
    this.viewRuleslistTableColumns = [];
    this.viewRuleslistTableColumnsExcel = [];
    this.viewRuleslistColumnsOptions = [];
    this.columizeClear = [];
    if (this.viewRuleColumnsCustomisedToShow.length) {
      this.viewRuleslistTableColumns = this.viewRuleColumnsCustomisedToShow.filter(item => 'fieldName' in item);
      this.viewRuleslistTableColumnsExcel = this.viewRuleColumnsCustomisedToShow.filter(item => 'fieldName' in item);
    } else {
      this.viewRuleslistTableColumns = this.allViewRuleColumns.filter(  item => 'fieldName' in item);
      this.viewRuleslistTableColumnsExcel = this.allViewRuleColumns.filter(item => 'fieldName' in item);
    }
    this.viewRuleslistTableColumns.sort((a3, b3) => (a3.sortOrder > b3.sortOrder) ? 1 : -1);
    const sortedData = this.viewRuleslistTableColumns;
    this.viewRuleslistTableColumns = sortedData;
    this.viewRuleslistTableColumns = JSON.parse(JSON.stringify(this.viewRuleslistTableColumns));
    this.viewRuleslistTableColumns.forEach(element => {
      this.columizeClear.push({
        field: element.fieldName,
        header: element.fieldDescription,
        width: element.width
      });
    });
    // Below variable using for Export Excel
    // this.viewRuleslistTableColumnsExcel = [];
    this.viewRuleslistTableColumns.forEach(element => {
      let fieldName = '';
      if (element.fieldName === 'warningText') {
        fieldName = 'warningTextStrippedExcel';
      } else if (element.fieldName === 'otherRestrictionsAndRequirements') {
        fieldName = 'otherRestrictionsAndRequirementsStrippedExcel';
      } else {
        fieldName = element.fieldName;
      }
      if (fieldName !== 'check') {
        this.viewRuleslistTableColumnsExcel.push({
          field: fieldName,
          header: element.fieldDescription,
          width: element.width
        });
        this.viewRuleslistTableColumnsExcel.filter(item => 'fieldName' in item);

      }
    });
    this.viewRuleColumns = this.columizeClear.filter( item => 'field' in item);
    if (this.loading === true && this.columizeLoading === true) {
      this.loading = false;
    }
  }
  getUserBasedMarkets() {
    this.userDetails = JSON.parse(localStorage.getItem('userRolesPermissions'));
    this.userDetails.roles.forEach(c => {
      if (c.roleId === 150 || c.roleId === 140 || (c.roleId === 130 && c.market === null)) {
        this.isSuperAdminOrGlobalRA = true;
      }
    });
    if (!this.isSuperAdminOrGlobalRA) {
      this.userDetails.roles.forEach(t => {
        if (t.roleId === 130) {
          this.userbasedMarkets.push(t.market);
        }
      });
      this.userbasedMarkets.sort((a, b) => (a.marketName > b.marketName) ? 1 : -1);
      this.userbasedMarkets = _.uniqBy(this.userbasedMarkets, v => [v.marketId, v.marketName].join());
    }
    this.getMarketData();
  }
  getMarketData() {
    this.loading = true;
    this.loadingMsg = '';
    const apiurl = environment.APIFAsT + '/allmarkets';
    this.apiService.getData(apiurl).subscribe((response1) => {
      const market = JSON.parse(localStorage.getItem('marketDetailsEdit'));
      if (market && market.length > 0) {
        this.loading = true;
        this.selectedMarkets = market;
        this.viewRuleBtnDisableFlag = false;
        this.getAllRules();
      } else {
        this.loading = false;
        this.selectedMarkets = [];
      }
      this.marketToDD = response1;
      this.marketToDDForCopyRules = this.isSuperAdminOrGlobalRA ? this.marketToDD : this.userbasedMarkets;
    }, (error) => {
      this.loading = false;
    });
  }
  getAllRules() {
    this.selectedRules = [];
    localStorage.setItem('marketDetailsEdit', JSON.stringify(this.selectedMarkets));
    const fetchRuleBody = {
      MarketList: this.selectedMarkets,
      RPCList: this.selectedRpcForViewRule.length > 0 ? this.selectedRpcForViewRule : [],
      SIRIds: [],
      GetChildRpcRules: this.childRpcCheckBox ? 'Y' : 'N',
      GetCascadedParentRpcRules: 'Y',
      ActiveStatusList: (this.activeRules) ? ['Y', 'F'] : ['N', 'D']
    };
    this.isCopyRulesDisable = this.copyRuleButtonEnableDisable();
    this.viewRuleTableData = [];
    this.loading = true;
    this.loadingMsg = 'Fetching data from FAsT Rule-Engine';
    const apiurl = environment.APIFAsTRule + '/rules/rulesFilter';
    this.apiService.postData(apiurl, fetchRuleBody).subscribe((response) => {
      if (response.length !== 0) {
        this.loading = false;
        this.viewRuleTableData = response;
        this.viewRuleTableData.forEach(apiData => {
          apiData.editRuleFlag = false;
          apiData.deleteRuleFlag = false;
          const warningLinkRuleData = this.hyperLinksToSheet((apiData.warningText == null || apiData.warningText === '') ? ((apiData.warningStatement !== null) ? apiData.warningStatement : '') : apiData.warningText);
          const orrLinkRuleData = this.hyperLinksToSheet(apiData.otherRestrictionsAndRequirements);
          if (this.userbasedMarkets.length > 0) {
            this.userbasedMarkets.forEach(market => {
              if (market.marketId === apiData.marketID.toString() && market.marketName === apiData.market) {
                apiData.editRuleFlag = true;
                apiData.deleteRuleFlag = true;
              }
            });
          }
          apiData.orginalRuleVersion = apiData.ruleVersion;
          apiData.ruleVersion = 'V' + apiData.ruleVersion;
          apiData.isAllergen = apiData.isAllergen === 'Y' ? 'Y' : '';
          apiData.allowedFuncFlag = apiData.ruleTypeID === 14 ? 'Y' : 'N';
          apiData.warningText = (apiData.warningText == null || apiData.warningText === '') ? ((apiData.warningStatement !== null) ? apiData.warningStatement : '') : apiData.warningText;
          if (apiData.warningText !== '') {
            apiData.originalWarningText = apiData.warningText;
            apiData.warningText = this.changeComment(apiData.warningText);
            apiData.warningTextStripped = this.handleSpecialCharacters(apiData.originalWarningText).substr(0, 14) + '...';
            apiData.warningTextStrippedExcel = this.handleSpecialCharacters(apiData.originalWarningText);
            apiData.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
            apiData.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
            apiData.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
            apiData.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
            apiData.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
            apiData.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
            apiData.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
          }
          apiData.otherRestrictionsAndRequirements = (apiData.otherRestrictionsAndRequirements !== null) ? apiData.otherRestrictionsAndRequirements : '';
          if (apiData.otherRestrictionsAndRequirements !== '') {
            apiData.originalotherRestrictionsAndReq = apiData.otherRestrictionsAndRequirements;
            apiData.otherRestrictionsAndRequirements = this.changeComment(apiData.otherRestrictionsAndRequirements);
            apiData.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(apiData.originalotherRestrictionsAndReq).substr(0, 14) + '...';
            apiData.otherRestrictionsAndRequirementsStrippedExcel = this.handleSpecialCharacters(apiData.originalotherRestrictionsAndReq);
            apiData.ORR1 = orrLinkRuleData[0] !== undefined ? orrLinkRuleData[0] : '';
            apiData.ORR2 = orrLinkRuleData[1] !== undefined ? orrLinkRuleData[1] : '';
            apiData.ORR3 = orrLinkRuleData[2] !== undefined ? orrLinkRuleData[2] : '';
            apiData.ORR4 = orrLinkRuleData[3] !== undefined ? orrLinkRuleData[3] : '';
            apiData.ORR5 = orrLinkRuleData[4] !== undefined ? orrLinkRuleData[4] : '';
            apiData.ORR6 = orrLinkRuleData[5] !== undefined ? orrLinkRuleData[5] : '';
            apiData.ORR7 = orrLinkRuleData[6] !== undefined ? orrLinkRuleData[6] : '';
          }
          if (apiData.cfDetails !== null) {
            apiData.cfSubstanceExpressedAs = apiData.cfDetails.component;
            apiData.cf = apiData.cfDetails.cf !== 0 ? apiData.cfDetails.cf : '';

          }
          if (apiData.ruleClassification === 'Substance Based Rules') {
            if (apiData.ruleTypeID === 30) {
              apiData.propertySource = apiData.propertyName;
              apiData.propertyName = '';
            }
          }
          if (apiData.ruleClassification === 'Property Based Rules') {
            apiData.ruleClassification = 'Product Based Rules';
            if (apiData.ruleTypeID === 7 || apiData.ruleTypeID === 8 || apiData.ruleTypeID === 9 || apiData.ruleTypeID === 49) {
              apiData.propertySource = 'CUC';
            }
          }
          if (apiData.ruleClassification === 'Substance Group Rules') {
            apiData.substanceGroup = apiData.groupName;
          }
        });
        this.selectedRows = this.viewRuleTableData;
        this.columizeLoading = false;
      } else {
        this.loading = false;
        this.viewRuleTableData = [];
      }
    }, (error) => {
      this.loading = false;
    });
  }
  handleSpecialCharacters(text) {
    return text.replace(/<.*?>/g, ' ').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, ' ').replace(/&amp;/g, '&');
  }
  onFilter(event) {
    if (event.filters != null) {
      (Object.keys(event.filters).length === 0 ? this.filteredRows = [] : this.filteredRows = event.filteredValue);
    }
  }
  onMarketChange() {
    if (this.selectedMarkets.length > 0) {
      this.viewRuleBtnDisableFlag = false;
    } else {
      this.viewRuleBtnDisableFlag = true;
    }
  }
  /* istanbul ignore next */
  hideFloatingIcons() {
    this.rowHoverDiv.nativeElement.style.display = 'none';
  }
  /* istanbul ignore next */
  showFloatingIcons(event, rowData) {
    this.selectedRuleData = rowData;
    event.target.dataTypeElem = 'row';
    this.flotingDivFlag = true;
    this.rowData = rowData;
    setTimeout(() => {
      const tableRowElem = document.getElementById('rowHoverDiv');
      const adj = (window.screen.width === window.outerWidth) ? 0 : -15;
      const maxScreenWidth = window.innerWidth * (window.screen.width / (window.outerWidth + adj));
      tableRowElem.style.left = String(event.pageX + 15) + 'px';

      tableRowElem.style.display = 'block';
      this.viewRuleTableActionCount = tableRowElem.getElementsByTagName('input').length;
      if (this.viewRuleTableActionCount === 0) {
        tableRowElem.style.display = 'none';
      }
      if (event.pageY > 400) {
        if (this.viewRuleTableActionCount === 1) {
          tableRowElem.style.top = (event.pageY - 20) + 'px';
        } else if (this.viewRuleTableActionCount === 2) {
          tableRowElem.style.top = (event.pageY - 45) + 'px';
        } else if (this.viewRuleTableActionCount === 3) {
          tableRowElem.style.top = (event.pageY - 70) + 'px';
        } else if (this.viewRuleTableActionCount === 4) {
          tableRowElem.style.top = (event.pageY - 95) + 'px';
        } else if (this.viewRuleTableActionCount === 5) {
          tableRowElem.style.top = (event.pageY - 120) + 'px';
        }
      } else {
        tableRowElem.style.top = (event.pageY - 10) + 'px';
      }
    }, 100);
  }
  /* istanbul ignore next */
  mousehoverpop(event) {
    event.stopPropagation();
  }
  /* istanbul ignore next */
  editRule(rowDataToEdit) {
    if (this.onViewRulesFlag) {
      ruleFlags.viewRule = true;
      ruleFlags.editRule = false;
    } else {
      ruleFlags.viewRule = false;
      ruleFlags.editRule = true;
    }
    this.onEditRules = true;
    this.dataService.setComponentName('EditRule');
    this.selectedStep = 0;
    this.componentNameTab = 'EditRule';
    this.steps = this.route.snapshot.routeConfig.children.map(child => child.path);
    this.dataService.setSelectedStep(1);
    this.dataService.setViewruleToEdit(rowDataToEdit);
    this.router.navigate(['rule-management/view-rules/edit-rules']);
  }
  /* istanbul ignore next */
  removeDuplicates(array, ruleID) {
    const lookup = {};
    array.forEach(element => {
      lookup[element[ruleID]] = element;
    });
    return Object.keys(lookup).map(prop => lookup[prop]);
  }
  /* istanbul ignore next */
  deleteRule() {
    this.ruleCommentFlag = true;
    this.authorizedRuleforDelete = [];
    this.tempArray = [];
    this.unauthorizedRuleforDelete = [];
    this.removeDuplicateRuleId = this.removeDuplicates(this.selectedRules, 'ruleId');
    this.removeDuplicateRuleId.forEach(e => {
      let arraySIRid = [];
      this.selectedRules.forEach(el => {
        if (el.ruleId === e.ruleId) {
          arraySIRid.push(' ' + el.substanceID + '(' + el.substanceName + ') ');
        }
      });
      e.deleteRuleComment = '';
      arraySIRid = arraySIRid.filter((item, index, inputArray) => {
        return inputArray.indexOf(item) === index;
      });
      if (e.ruleClassification === 'Substance Based Rules') {
        e.tempSirId = 'SIR ID : ' + e.substanceID + ' | Substance Name : ' + e.substanceName;
      } else if (e.ruleType === 'Product Standard') {
        e.tempSirId = 'Function ID : ' + e.functionID + ' | Function Name : ' + e.functionName;
      } else if (e.ruleClassification === 'Property Based Rules') {
        e.tempSirId = 'Property Class : ' + e.propertyClass + ' |  Property Name : ' + e.propertyName;
      } else if (e.ruleClassification === 'Substance Group Rules') {
        e.tempSirId = 'Group ID : ' + e.groupID + ' |  Group Name : ' + e.groupName;
      } else if (e.ruleTypeID === 45) {
        e.tempSirId = arraySIRid;
      } else {
        e.tempSirId = 'SIR ID : ' + e.substanceID + ' | Substance Name : ' + e.substanceName;
      }
      if (this.isSuperAdminOrGlobalRA) {
        this.authorizedRuleforDelete.push(e);
      } else {
        this.userbasedMarkets.forEach(market => {
          if (e.market === market.marketName) {
            this.authorizedRuleforDelete.push(e);
            this.tempArray.push(e.ruleId);
          } else if (!this.tempArray.includes(e.ruleId)) {
            this.unauthorizedRuleforDelete.push(e);
          }
        });
      }
    });
    this.authorizedRuleforDelete = _.uniqBy(this.authorizedRuleforDelete, v => [v.ruleId, v.ruleTypeID, v.ruleVersion].join());
    this.unauthorizedRuleforDelete = _.uniqBy(this.unauthorizedRuleforDelete, v => [v.ruleId, v.ruleTypeID, v.ruleVersion].join());
    this.checkRulesAvail();
    if (this.removeDuplicateRuleId.length > this.maxRowsSelectCount) {
      this.matSnackBar.open('Max ' + this.maxRowsSelectCount + ' rules can be In-Activated at a time', 'close', {
        duration: 5000,
        panelClass: ['error']
      });
    } else {
      this.deleteRulePopup = true;
    }
  }
  /* istanbul ignore next */
  deleteGroupRule() {
    this.ruleCommentFlag = true;
    this.authorizedRuleforDelete = [];
    this.tempArray = [];
    this.unauthorizedRuleforDelete = [];
    this.removeDuplicateRuleId = this.removeDuplicates(this.selectedRules, 'ruleId');
    this.removeDuplicateRuleId.forEach(e => {
      let arraySIRid = [];
      this.selectedRules.forEach(el => {
        if (el.ruleId === e.ruleId) {
          arraySIRid.push(' ' + el.substanceID + '(' + el.substanceName + ') ');
        }
      });
      e.deleteRuleComment = '';
      arraySIRid = arraySIRid.filter((item, index, inputArray) => {
        return inputArray.indexOf(item) === index;
      });
      if (e.ruleClassification === 'Substance Based Rules') {
        e.tempSirId = 'SIR ID : ' + e.substanceID + ' | Substance Name : ' + e.substanceName;
      } else if (e.ruleType === 'Product Standard') {
        e.tempSirId = 'Function ID : ' + e.functionID + ' | Function Name : ' + e.functionName;
      } else if (e.ruleClassification === 'Property Based Rules') {
        e.tempSirId = 'Property Class : ' + e.propertyClass + ' |  Property Name : ' + e.propertyName;
      } else if (e.ruleClassification === 'Substance Group Rules') {
        e.tempSirId = 'Group ID : ' + e.groupID + ' |  Group Name : ' + e.groupName;
      } else if (e.ruleTypeID === 45) {
        e.tempSirId = arraySIRid;
      } else {
        e.tempSirId = 'SIR ID : ' + e.substanceID + ' | Substance Name : ' + e.substanceName;
      }
      if (this.isSuperAdminOrGlobalRA) {
        this.authorizedRuleforDelete.push(e);
      } else {
        this.userbasedMarkets.forEach(market => {
          if (e.market === market.marketName) {
            this.authorizedRuleforDelete.push(e);
            this.tempArray.push(e.ruleId);
          } else if (!this.tempArray.includes(e.ruleId)) {
            this.unauthorizedRuleforDelete.push(e);
          }
        });
      }
    });
    this.authorizedRuleforDelete = _.uniqBy(this.authorizedRuleforDelete, v => [v.ruleId, v.ruleTypeID, v.ruleVersion].join());
    this.unauthorizedRuleforDelete = _.uniqBy(this.unauthorizedRuleforDelete, v => [v.ruleId, v.ruleTypeID, v.ruleVersion].join());
    this.checkRulesAvail();
    if (this.removeDuplicateRuleId.length > this.maxRowsSelectCount) {
      this.matSnackBar.open('Max ' + this.maxRowsSelectCount + ' rules can be Deleted at a time', 'close', {
        duration: 5000,
        panelClass: ['error']
      });
    } else {
      this.deleteGroupRulePopup = true;
    }
  }
  selectRow(checkValue) {
    if (checkValue) {
      this.selectedRows = this.viewRuleTableData;
    } else {
      this.selectedRows = [];
    }
  }
  /* istanbul ignore next */
  clearFilters(viewRuleTable: any) {
    viewRuleTable.reset();
    this.filteredRows = [];
    for (const val of this.viewRuleColumns) {
      if (val.field !== 'check') {
        const colname = val.field + '_val';
        // tslint:disable-next-line: whitespace
        if ((<HTMLInputElement> document.getElementById(colname)) && (<HTMLInputElement> document.getElementById(colname)).value) {
          // tslint:disable-next-line: whitespace
          (<HTMLInputElement> document.getElementById(colname)).value = '';
        }
      }
    }
  }
  createRulesBtn() {
    this.dataService.setComponentName('CreateInsertRule');
    setTimeout(() => {
      this.dataService.setSelectedStep(0);
    }, 1000);
    this.router.navigate(['/create-insert/select-rpc']);
  }
  /* istanbul ignore next */
  onConfirmInactivate() {
    const deleteRuleurl = environment.APIFAsTRule + '/DeleteRules';
    const apiData = [];
    this.authorizedRuleforDelete.forEach(element => {
      element.ruleVersion = element.ruleVersion.replace('V', '');
      const tempData = {
        RuleTypeID: element.ruleTypeID,
        RuleType: element.ruleType,
        RuleId: element.ruleId,
        RuleVersion: element.orginalRuleVersion,
        Comments: element.deleteRuleComment
      };
      apiData.push(tempData);
    });
    this.loading = true;
    this.loadingMsg = 'Deleting...';
    this.apiService.putData(deleteRuleurl, apiData).subscribe((responseData) => {

      if (responseData !== null) {
        this.getAllRules();
        this.selectedRules = null;
        this.unauthorizedRuleforDelete = [];
        this.authorizedRuleforDelete = [];
        this.removeDuplicateRuleId = [];
        this.deleteRuleComment = null;
        let message = '';
        responseData.message.forEach(msg => {
          message = message + ' ' + msg;
        });
        this.matSnackBar.open(message, 'close', {
          duration: 6000,
          panelClass: ['success']
        });
      }
    }, (error) => {
      this.matSnackBar.open('Rule deletion Failed', 'close', {
        duration: 4000,
        panelClass: ['error']
      });
      this.loading = false;
    });
  }
  /* istanbul ignore next */
  onConfirmDelete() {
    const deleteRuleurl = environment.APIFAsTRule + '/rules/delete';
    const apiData = [];
    this.authorizedRuleforDelete.forEach(element => {
      element.ruleVersion = element.ruleVersion.replace('V', '');
      const tempData = {
        RuleTypeID: element.ruleTypeID,
        RuleType: element.ruleType,
        RuleId: element.ruleId,
        RuleVersion: element.orginalRuleVersion,
        Comments: element.deleteRuleComment
      };
      apiData.push(tempData);
    });
    this.loading = true;
    this.loadingMsg = 'Deleting...';
    this.apiService.postData(deleteRuleurl, apiData).subscribe((responseData) => {

      if (responseData !== null) {
        this.getAllRules();
        this.selectedRules = null;
        this.unauthorizedRuleforDelete = [];
        this.authorizedRuleforDelete = [];
        this.removeDuplicateRuleId = [];
        this.deleteRuleComment = null;
        let message = '';
        responseData.message.forEach(msg => {
          message = message + ' ' + msg;
        });
        this.matSnackBar.open(message, 'close', {
          duration: 6000,
          panelClass: ['success']
        });
      }
    }, (error) => {
      this.matSnackBar.open('Rule deletion Failed', 'close', {
        duration: 4000,
        panelClass: ['error']
      });
      this.loading = false;
    });
  }
  onKeyUp() {
    if (this.authorizedRuleforDelete.length > 0) {
      for (const val of this.authorizedRuleforDelete) {
        if (val.deleteRuleComment === null || val.deleteRuleComment === '') {
          this.ruleCommentFlag = true;
          break;
        } else {
          this.ruleCommentFlag = false;
        }
      }
    }
  }
  showColumnsDialog() {
    this.initiateColumnOptions();
    this.columnsDialog = true;
    this.position = 'right';
  }
  showExportDialog() {
    this.viewRuleslistTableColumnsExcel = this.viewRuleslistTableColumnsExcel.filter(  item => 'fieldName' in item);
    const warningHashyerlinks = this.viewRuleslistTableColumnsExcel.filter(x => x.fieldName === 'warningText').length > 0 ? 'Yes' : 'No';
    const orrHashyerlinks = this.viewRuleslistTableColumnsExcel.filter(x => x.fieldName === 'otherRestrictionsAndRequirements').length > 0 ? 'Yes' : 'No';
    if (warningHashyerlinks === 'Yes' || orrHashyerlinks === 'Yes') {
      this.isColumnsHasHyperLinks = 'Yes';
    } else {
      this.isColumnsHasHyperLinks = 'No';
    }
    if (this.isColumnsHasHyperLinks === 'Yes') {
      let totalHyperlinksCount = 0;
      if (this.viewRuleslistTableColumnsExcel.filter(x => x.fieldName === 'warningText1').length === 0 && warningHashyerlinks === 'Yes') {
        this.addWarningExtraColumns();
      }
      if (this.viewRuleslistTableColumnsExcel.filter(x => x.fieldName === 'ORR1').length === 0 && orrHashyerlinks === 'Yes') {
        this.addOrrExtraColumns();
      }
      if (warningHashyerlinks === 'Yes') {
        totalHyperlinksCount = this.viewRuleslistTableColumnsExcel.findIndex(x => x.fieldName === 'warningText1');
      } else if (orrHashyerlinks === 'Yes' && totalHyperlinksCount === 0) {
        totalHyperlinksCount = this.viewRuleslistTableColumnsExcel.findIndex(x => x.fieldName === 'ORR1');
      }
      this.HyperLinksStartColumnNumber = totalHyperlinksCount;
    }

    this.exportDialog = true;
  }
  cancel() {
    this.columnsDialog = false;
  }
  saveColumnChange(action) {
    this.filteredRows = [];
    this.columnsDialog = false;
    this.loading = true;
    const columnsAddedData = [];
    let activePayload;
    if (action === 'save') {
      activePayload = 1;
    } else if (action === 'reset') {
      activePayload = 0;
    }
    this.columnsAdded.forEach((column, i) => {
      const data = {
        pageName: 'viewrule',
        fieldName: column.fieldName,
        fieldDescription: column.fieldDescription,
        sortOrder: i + 1,
        status: column.status,
        active: activePayload,
        width: column.width
      };
      columnsAddedData.push(data);
      columnsAddedData.filter(item => 'fieldName' in item);
    });
    this.columizeLoading = true;
    const apiurl = environment.APIFAsT + '/SaveColumnCustomization';
    this.apiService.postData(apiurl, columnsAddedData).subscribe((response) => {
      this.getColumnCustomization();
    });
  }
  /* istanbul ignore next */
  drop(event: CdkDragDrop<string[]>) {
    if (event.currentIndex !== 1 && event.currentIndex !== 2 && event.currentIndex !== 3) {
      moveItemInArray(this.columnsAdded, event.previousIndex, event.currentIndex);
    }
  }
  addColumn(): void {
    (this.userForm.get('column') as FormArray).push(
      this.fb.control(null)
    );
    this.columnToBeAddedDetails.forEach(colDetail => {
      this.columnsAdded.push(colDetail);
      this.columnListToAdd = this.columnListToAdd.filter(x => x.fieldName !== colDetail.fieldName);
    });
    if (this.columnListToAdd.length < 1) {
      this.addColumnButton = true;
    }
    this.columnToBeAddedDetails = [];
    this.selectedColumns = [];
  }
  /* istanbul ignore next */
  onColumnChange(value) {
    if (value.fieldName !== 'check') {
      const column = this.allViewRuleColumns.filter(x => x.fieldName === value.fieldName);
      const columnData = column[0];
      columnData.sortOrder = this.columnsAdded.length;
      columnData.status = 1;
      this.columnToBeAddedDetails.push(columnData);
    }
  }
  onDeSelectAllColumn() {
    this.columnToBeAddedDetails = [];
  }
  /* istanbul ignore next */
  onSelectAllColumn(value) {
    value.forEach(col => {
      if (value.fieldName !== 'check') {
        const column = this.allViewRuleColumns.filter(x => x.fieldName === col.fieldName);
        const columnData = column[0];
        columnData.sortOrder = this.columnsAdded.length;
        columnData.status = 1;
        this.columnToBeAddedDetails.push(columnData);
      }
    });
  }
  removeColumn(col) {
    this.columnsAdded = this.columnsAdded.filter(x => x.fieldName !== col.fieldName);
    const removedCol = this.allViewRuleColumns.filter(x => x.fieldName === col.fieldName);
    this.columnListToAdd.push(removedCol[0]);
    this.columnsAdded.forEach(colRmove => {
      this.columnListToAdd = this.columnListToAdd.filter(x => x.fieldName !== colRmove.fieldName);
    });
    if (this.columnListToAdd.length > 0) {
      this.addColumnButton = false;
    }
  }
  getColumnDetails(): AbstractControl[] {
    // tslint:disable-next-line: whitespace
    return (<FormArray>this.userForm.get('column')).controls;
  }
  clearSelectedMarkets(markets) {
    if (markets && markets.length > 0) {
      this.selectedMarkets = [];
      this.viewRuleBtnDisableFlag = true;
      this.viewRuleTableData = [];
      this.viewRuleRpcPath = [];
      this.selectedRpcForViewRule = [];
    }
    this.isCopyRulesDisable = true;
  }
  onVersionHistoryClick() {
    this.loading = true;
    const getVerHistoryUrl = environment.APIFAsTRule + '/versionHistory/ruleID/' + this.selectedRuleData.ruleId + '/isDetailVersion/n';
    this.apiService.getData(getVerHistoryUrl).subscribe((responseData) => {
      if (responseData !== null) {
        this.loading = false;
        this.versionHistoryDisplay = true;
        this.shortVersionHistoryDetails = responseData;
        for (const val of this.shortVersionHistoryDetails) {
          val.updatedDate = new Date(val.updatedDate).toLocaleString();
        }
        this.shortVersionHistoryDetails = _.uniqBy(this.shortVersionHistoryDetails, 'ruleVersion');
      }
    }, (error) => {
      this.loading = false;
      this.versionHistoryDisplay = false;
    });
  }
  onCopyRulesClick() {
    this.copyMultipleRules = [];
    this.selectedRules.forEach(rule => {
      this.ruleSelected = false;
      if (this.copyMultipleRules.length > 0) {
        this.copyMultipleRules.forEach(element => {
          if (rule.ruleId === element.ruleId) {
            this.ruleSelected = true;
          }
        });
      }
      if (this.ruleSelected === false) {
        rule.ruleDetailsToCopy = [{
          selectedMarketForCopy: {
            marketId: '',
            marketName: ''
          },
          selectedRpcPath: {
            rpcDescription: '',
            rpcId: '',
            rpcSearchValue: ''
          },
          selectedRpc1: '',
          selectedRpc2: '',
          selectedRpc3: '',
          selectedRpc4: '',
          selectedRpc5: ''
        }];
        this.copyMultipleRules.push(rule);
      }
    });
    this.ruleDetailsToCopy = this.copyMultipleRules.length;
    if (this.copyMultipleRules.length > this.maxRowsSelectCount) {
      this.matSnackBar.open('Maximum ' + this.maxRowsSelectCount + ' rules only can be copied at a time', 'close', {
        duration: 5000,
        panelClass: ['error']
      });
    } else {
      this.selectMktRpcDialogForCopy = true;
    }
  }
  searchRPCPath(event, ruleDetail) {
    this.loading = true;
    const data = [ruleDetail.selectedMarketForCopy];
    const getRpcPathUrl = environment.APIFAsT + '/searchtext/' + event.query + '/rpcpaths';
    this.apiService.postData(getRpcPathUrl, data).subscribe((responseData) => {
      if (responseData !== null) {
        this.loading = false;
        this.rpcPathResults = responseData;
      }
    }, (error) => {
      this.loading = false;
    });

  }
  /* istanbul ignore next */
  onRPCPathSelected(selectedRpcPath, ruleDetail) {
    this.loading = true;
    this.isSameMarketRPCAvailable = false;
    this.onRPCPathUnselected(ruleDetail);
    const getRpcPathUrl = environment.APIFAsT + '/rpcid/' + selectedRpcPath.rpcId + '/parents';
    this.apiService.getData(getRpcPathUrl).subscribe((responseData) => {
      if (responseData !== null) {
        this.loading = false;
        this.rpcLevels = responseData;
        ruleDetail.selectedRpc1 = this.rpcLevels[0].rpcDescription;
        ruleDetail.selectedRpc2 = this.rpcLevels[1].rpcDescription;
        ruleDetail.selectedRpc3 = this.rpcLevels[2].rpcDescription;
        ruleDetail.selectedRpc4 = this.rpcLevels[3].rpcDescription;
        ruleDetail.selectedRpc5 = this.rpcLevels[4].rpcDescription;
      }
    }, (error) => {
      this.loading = false;
    });
    this.disableCopyRule();
  }
  onRPCPathUnselected(ruleDetail) {
    ruleDetail.selectedRpc1 = '';
    ruleDetail.selectedRpc2 = '';
    ruleDetail.selectedRpc3 = '';
    ruleDetail.selectedRpc4 = '';
    ruleDetail.selectedRpc5 = '';
    this.disableCopyRule();
  }
  /* istanbul ignore next */
  addAnotherMarket(rule) {
    this.ruleDetailsToCopy++;
    if (this.ruleDetailsToCopy > this.maxMarketRPCsCount) {
      this.ruleDetailsToCopy--;
      this.maximum10MRPcs = true;
    } else {
      rule.ruleDetailsToCopy.push({
        selectedMarketForCopy: {
          marketId: '',
          marketName: ''
        },
        selectedRpcPath: {
          rpcDescription: '',
          rpcId: '',
          rpcSearchValue: ''
        },
        selectedRpc1: '',
        selectedRpc2: '',
        selectedRpc3: '',
        selectedRpc4: '',
        selectedRpc5: ''
      });
    }
  }
  /* istanbul ignore next */
  addAnotherRPCs(value, rule, index) {
    this.ruleDetailsToCopy++;
    if (this.ruleDetailsToCopy > this.maxMarketRPCsCount) {
      this.ruleDetailsToCopy--;
      this.maximum10MRPcs = true;
    } else {
      rule.ruleDetailsToCopy.splice((index + 1), 0, {
        selectedMarketForCopy: {
          marketId: value.marketId,
          marketName: value.marketName
        },
        selectedRpcPath: {
          rpcDescription: '',
          rpcId: '',
          rpcSearchValue: ''
        },
        selectedRpc1: '',
        selectedRpc2: '',
        selectedRpc3: '',
        selectedRpc4: '',
        selectedRpc5: ''
      });
    }
  }
  /* istanbul ignore next */
  copyRule() {
    // tslint:disable-next-line: prefer-const
    let mRPCData = []; let reqData = []; let val;
    this.copyMultipleRules.forEach(rule => {
      // tslint:disable-next-line: prefer-const
      let matchCount = 0;
      rule.ruleDetailsToCopy.forEach(row => {
        const marketName = row.selectedMarketForCopy.marketName;
        const RPC = row.selectedRpcPath.rpcDescription;
        matchCount = 0;
        rule.ruleDetailsToCopy.forEach(row2 => {
          if (marketName === row2.selectedMarketForCopy.marketName && RPC === row2.selectedRpcPath.rpcDescription) {
            matchCount++;
            if (matchCount >= 2) {
              this.sameMRPCs.push(rule.ruleId);
            }
          }
        });
      });
      if (matchCount >= 2) {
        this.isSameMarketRPCAvailable = true;
      }
    });
    this.sameMRPCs = this.sameMRPCs.filter((id, index, self) => self.indexOf(id) === index).filter(id => id !== null);
    if (this.isSameMarketRPCAvailable === false) {
      this.loading = true;
      this.copyMultipleRules.forEach(rule => {
        mRPCData = [];
        rule.ruleDetailsToCopy.forEach((value) => {
          if (value.selectedMarketForCopy.marketId) {
            val = {
              marketId: value.selectedMarketForCopy.marketId,
              marketName: value.selectedMarketForCopy.marketName,
              RPCId: value.selectedRpcPath.rpcId,
              RPCName: value.selectedRpcPath.rpcDescription,
              RPCFullText: value.selectedRpcPath.rpcSearchValue
            };
            mRPCData.push(val);
          }
        });
        reqData.push({
          ruleId: rule.ruleId,
          ruleTypeID: rule.ruleTypeID,
          ruleVersion: rule.ruleVersion.substr(1),
          MRPCs: mRPCData
        });
      });
      this.copyRuleSuccess = [];
      this.copyRuleFailure = [];
      const validateMRPCUrl = environment.APIFAsTRule + '/copyMultipleRules';
      this.apiService.postData(validateMRPCUrl, reqData).subscribe((responseData) => {
        if (responseData !== null) {
          this.loading = false;
          this.selectMktRpcDialogForCopy = false;
          this.copyRuleResponseDialog = true;
          this.copyRuleResponse = responseData;
          responseData.forEach(rule => {
            const responses = rule.mrpCs;
            for (const value of responses) {
              if (value.messageText.includes('Copied Successfully to')) {
                value.msgSplit1 = 'Rule ID ' + rule.ruleId + ' - ' + value.messageText.split('>')[0];
                value.msgSplit2 = value.messageText.split('>')[1];
                this.copyRuleSuccess.push(value);
              }
              if (value.messageText.includes('Copy rule failed for')) {
                value.msgSplit1 = 'Rule ID ' + rule.ruleId + ' - ' + value.messageText.split('>')[0];
                value.msgSplit2 = (value.messageText.split('>')[1]).split('due to ')[0];
                value.msgSplit3 = (value.messageText.split('>')[1]).split('due to ')[1];
                this.copyRuleFailure.push(value);
              }
            }
          });
        }
      }, (error) => {
        this.loading = false;
      });
    }
  }
  getWorklistColumns() {
    const apiurl = environment.APIFAsT + '/getworklistcolumns/relatedrequest';
    this.worklistservice.getWorklistApiData(apiurl).subscribe((response) => {
      this.myWorklistTableColumns = response;
    });
  }
  /* istanbul ignore next */
  checkRulesAvail() {
    this.selectedRowEmail = [];
    this.prepareRuleTableData = [];
    this.showRelatedRequest = [];
    this.loadingMsg = 'Fetching data from FAsT Rule-Engine';
    this.loading = true;
    const apiurl = environment.APIFAsTRule + '/rules/queryrelatedrequests';
    if (this.authorizedRuleforDelete.length === 0) {
      this.loading = false;
    }
    this.authorizedRuleforDelete.forEach(rule => {
      const reqAuthData = [{
        RuleId: rule.ruleId,
        RuleVersion: rule.ruleVersion.replace('V', ''),
      }];
      this.apiService.postData(apiurl, reqAuthData).subscribe((response) => {
        if (response) {
          this.loading = false;
          this.prepareRuleTableData = response.map(reqRespElm => {
            return {
              requestDisplayID: reqRespElm.requestDisplayID,
              cucCode: reqRespElm.cucCode,
              cucDescription: reqRespElm.cucDescription,
              recipeType: reqRespElm.recipeType,
              recipeStatus: reqRespElm.recipeStatus,
              marketId: reqRespElm.market.marketId,
              marketName: reqRespElm.market.marketName,
              division: (reqRespElm.tfc.tfcLevel === 5) ? reqRespElm.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName : reqRespElm.tfc.parentTFC.parentTFC.parentTFC.tfcName,
              rDFormulationCategory: (reqRespElm.tfc.tfcLevel === 5) ? reqRespElm.tfc.parentTFC.parentTFC.parentTFC.tfcName : reqRespElm.tfc.parentTFC.parentTFC.tfcName,
              formulationType: (reqRespElm.tfc.tfcLevel === 5) ? reqRespElm.tfc.parentTFC.parentTFC.tfcName : reqRespElm.tfc.parentTFC.tfcName,
              formulationClass: (reqRespElm.tfc.tfcLevel === 5) ? reqRespElm.tfc.parentTFC.tfcName : reqRespElm.tfc.tfcName,
              formulationSubClass: (reqRespElm.tfc.tfcLevel === 5) ? reqRespElm.tfc.tfcName : '',
              preparationMethod: reqRespElm.preparationMethod,
              intendedUse: reqRespElm.intendedUse,
              multicomponent: reqRespElm.isMultiComponent,
              requestStatus: reqRespElm.requestStatus,
              ra: reqRespElm.ra,
              allRa: reqRespElm.allRA,
              submittedDate: reqRespElm.submittedDate,
              submittedByUserId: reqRespElm.submittedByUserId,
              submittedByUserName: reqRespElm.submittedByUserName,
              comments: reqRespElm.comments,
              assignedDate: reqRespElm.assignedDate,
              inProgressRADate: reqRespElm.inProgressRADate,
              inProgressRDDate: reqRespElm.inProgressRNDDate,
              inProgressSIRDate: reqRespElm.inProgressSIRDate,
              rejectedDate: reqRespElm.rejectedDate,
              deletionDate: reqRespElm.deletionDate,
              completedDate: reqRespElm.completedDate,
              rdComment: this.changeComment(reqRespElm.comments),
              rdCommentoriginal: reqRespElm.comments,
              // raComment: this.changeComment(reqRespElm.raComment),
              rdCommentWithoutHtml: this.handleSpecialCharacters(reqRespElm.comments),
              raComment: this.handleSpecialCharacters(reqRespElm.raComment),
              topTfcId: reqRespElm.tfcid,
              allRAIDs: reqRespElm.allRAIDs,
              raUserID: reqRespElm.raUserIDred,
              ruleId: rule.ruleId,
              divisionid: (reqRespElm.tfc.tfcLevel === 5) ? reqRespElm.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId : reqRespElm.tfc.parentTFC.parentTFC.parentTFC.tfcId
            };
          });
          this.showRelatedRequest.push([rule.ruleId, this.prepareRuleTableData]);
        }
      }, (error) => {
        this.loading = false;
      });

    });
  }
  /* istanbul ignore next */
  onCloseCopyRulesDialog() {
    location.reload();
  }
  selectedRowforAction(event) {
    if (event.selected || event.selected === 'singleTrue') {
      for (const value of this.showRelatedRequest) {
        if (value[0] === event.ruleId) {
          if (event.selected !== 'singleTrue') {
            this.selectedRowEmail = this.selectedRowEmail.concat(value[1]);
          } else if (event.selected === 'singleTrue') {
            this.selectedRowEmail = this.selectedRowEmail.concat(value[1].filter(x => x.requestDisplayID === event.data.requestDisplayID));
          }
        }
      }
    } else {
      if (event.mode === 'selectAll') {
        const length = this.selectedRowEmail.length;
        for (let i = 0; i < length; i++) {
          const index = this.selectedRowEmail.findIndex(obj => obj.ruleId === event.ruleId);
          if (index > -1) {
            this.selectedRowEmail.splice(index, 1);
          }
        }
      } else {
        const index = this.selectedRowEmail.findIndex(x => x.requestDisplayID === event.data.requestDisplayID && x.ruleId === event.data.ruleId);
        this.selectedRowEmail.splice(index, 1);
      }
    }
  }
  sendEmail() {
    const payLoad = {
      NotificationGroup: 'RDCOMPLETED',
      Subject: 'FAsT | Rules updated for these requests :',
      EmailBody: null,
      ToEmailList: '',
      FromEmail: null,
      CCEmailList: null,
      EmailType: 'html',
      UPD: '',
      PureCodes: null,
      UserRequests: [],
      CUCDescription: null,
      Comments: this.emailComments === '' ? null : this.emailComments
    };
    this.selectedUPDs = Array.from(new Set(this.selectedRowEmail.map(i => i.ruleId)));
    const unique = [];
    this.selectedRowEmail.forEach(x => unique.filter(a => a.requestDisplayID === x.requestDisplayID && a.ruleId === x.ruleId).length > 0 ? null : unique.push(x));
    this.selectedRowEmail = unique;
    if (this.selectedRowEmail.length > 0) {
      this.selectedUPDs.forEach(ruleId => {
        payLoad.UserRequests = [];
        this.emailUserId = [];
        this.selectedRowEmail.forEach(row => {
          if (ruleId === row.ruleId) {
            this.emailUserId.push(row.submittedByUserId);
          }
        });
        this.emailUserId = Array.from(new Set(this.emailUserId));
        this.emailUserId.forEach(userId => {
          const reqIds = this.selectedRowEmail.filter(item => (item.submittedByUserId === userId && item.ruleId === ruleId)).map(i => i.requestDisplayID);
          payLoad.UserRequests.push({
            userId: +userId,
            requestIds: reqIds
          });
          payLoad.UPD = ruleId;
        });
        if (payLoad.UserRequests.length > 0) {
          this.apiService.sendRulesEmailNotification(payLoad);
        }
      });
    }
  }
  onRemoveMRPC(rule, i) {
    rule.ruleDetailsToCopy.splice(i, 1);
    this.ruleDetailsToCopy--;
    this.disableCopyRule();
  }
  showVersionHistoryDetails() {
    this.versionHistoryDetails = [];
    this.verHistoryDetailsDialog = true;
    this.versionHistoryDisplay = false;
    this.loading = true;
    const getVerHistoryUrl = environment.APIFAsTRule + '/versionHistory/ruleID/' + this.selectedRuleData.ruleId + '/isDetailVersion/y';
    this.apiService.getData(getVerHistoryUrl).subscribe((responseData) => {
      if (responseData !== null) {
        this.loading = false;
        this.versionHistoryDetails = responseData;
        this.versionHistoryDetails.forEach((element) => {
          element.ruleVersion = 'V' + element.ruleVersion;
          element.functionNewValue = element.functionNewValue ? element.functionNewValue : 'Any';
          element.warningNewValue = (element.warningNewValue == null || element.warningNewValue === '') ? '' : element.warningNewValue;
          element.originalWarningText = element.warningNewValue;
          const warningLinkRuleData = this.hyperLinksToSheet(element.originalWarningText);
          element.warningNewValue = this.changeComment(element.warningNewValue);
          element.warningTextStripped = this.handleSpecialCharacters(element.originalWarningText);
          element.warningTextStrippedExcel = this.handleSpecialCharacters(element.originalWarningText);
          element.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
          element.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
          element.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
          element.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
          element.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
          element.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
          element.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
        });
      }
    }, (error) => {
      this.loading = false;
      this.verHistoryDetailsDialog = false;
    });
  }
  searchRPCsForViewRule(event) {
    this.loading = true;
    const getRpcPathApiUrl = environment.APIFAsT + '/searchtext/' + event.query + '/rpcpaths';
    this.apiService.postData(getRpcPathApiUrl, this.selectedMarkets).subscribe((ApiResponse) => {
      if (ApiResponse.length > 0) {
        this.loading = false;
        this.RpcPathsForDD = ApiResponse;
      } else {
        this.RpcPathsForDD = [];
        this.loading = false;
      }
    }, (error) => {
      this.loading = false;
    });

  }
  onRPCsForViewRuleSelected(event) {
    this.selectedRpcForViewRule = [event];
  }
  /* istanbul ignore next */
  goToPrefClicked() {
    const url = environment.PREFADMIN;
    window.open(url, '_blank');
  }
  rowselected(event) {
    const selectedRow = event.data;
    this.viewRuleTableData.forEach(rule => {
      if (rule.ruleId === selectedRow.ruleId && rule.ruleVersion === selectedRow.ruleVersion) {
        this.selectedRules.push(rule);
      }
    });
    this.isCopyRulesDisable = this.copyRuleButtonEnableDisable();
    this.isDeleteRulesDisable = this.deleteRuleButtonEnableDisable();
  }
  onRowUnselect(event) {
    const unselectedRow = event.data;
    this.removeRule(unselectedRow);
    this.isCopyRulesDisable = this.copyRuleButtonEnableDisable();
    this.isDeleteRulesDisable = this.deleteRuleButtonEnableDisable();
    this.selectedRules = this.selectedRules.filter((obj) => obj.ruleId !== unselectedRow.ruleId);
  }
  /* istanbul ignore next */
  private removeRule(unselectedRow) {
    this.selectedRules.forEach((rule, index) => {
      if (rule.ruleId === unselectedRow.ruleId && rule.ruleVersion === unselectedRow.ruleVersion) {
        this.selectedRules.splice(index, 1);
        this.removeRule(unselectedRow);
      }
    });
  }
  disableCopyRule() {
    this.isRPCIDThere = true;
    this.copyMultipleRules.forEach(rule => {
      rule.ruleDetailsToCopy.forEach(row => {
        if (row.selectedRpcPath.rpcId === '') {
          this.isRPCIDThere = false;
        }
      });
    });
    this.isCopyRuleDisable = !this.isRPCIDThere;
  }
  /* istanbul ignore next */
  reactivateBtnClick() {
    this.reActivateComments = '';
    this.reActivatePopUp = true;
    this.validityStartDate = '';
    this.validityEndDate = '';
    this.validitynewdate = this.datePipe.transform('9999-12-31', 'MMMM dd, yyyy');
    this.groupRuleFlag = this.selectedRuleData.ruleClassification === 'Substance Group Rules' ? true : false;
  }
  /* istanbul ignore next */
  reActivateRules() {
    this.loading = true;
    let ruleVer = this.selectedRuleData.ruleVersion;
    ruleVer = ruleVer.substring(1);
    const reActivateApiBody = {
      RuleTypeID: this.selectedRuleData.ruleTypeID.toString(),
      RuleType: this.selectedRuleData.ruleType,
      RuleId: this.selectedRuleData.ruleId,
      RuleVersion: +ruleVer,
      Comments: this.reActivateComments,
      ValidityStartDate: this.validityStartDate ? this.datePipe.transform(this.validityStartDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityFromDate, 'yyyy-MM-dd'),
      ValidityEndDate: this.validityEndDate ? this.datePipe.transform(this.validityEndDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityEndDate, 'yyyy-MM-dd'),
    };
    const getRpcPathApiUrl = environment.APIFAsTRule + '/rulesActivate';
    this.apiService.postData(getRpcPathApiUrl, reActivateApiBody).subscribe((response) => {
      if (response.length > 0) {
        this.activeRules = true;
        this.matSnackBar.open('Succesfully re-activated RuleID ' + response[0].ruleId + ' as V' + response[0].ruleVersion, 'close', {
          duration: 4000,
          panelClass: ['success']
        });
        this.getAllRules();
        this.validityStartDate = '';
        this.validityEndDate = '';
      } else if (response.status === 400) {
        this.matSnackBar.open(response.message, 'close', {
          duration: 4000,
          panelClass: ['error']
        });
        this.loading = false;
      } else {
        this.matSnackBar.open(response.message, 'close', {
          duration: 4000,
          panelClass: ['error']
        });
        this.loading = false;
      }
    }, (error) => {
      this.matSnackBar.open(error.error.message, 'close', {
        duration: 4000,
        panelClass: ['error']
      });
      this.loading = false;
    });
  }
  /* istanbul ignore next */
  copyRuleButtonEnableDisable() {
    if (this.activeRules) {
      return (this.selectedRules.length === 0);
    } else {
      return true;
    }
  }
  /* istanbul ignore next */
  deleteRuleButtonEnableDisable() {
    if (!this.activeRules) {
      return (this.selectedRules.length === 0);
    } else {
      return true;
    }
  }
  showOverlay(htmlContent) {
    this.overlayText = htmlContent;
    this.hideOverlay = false;
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
  hideOverlayPanel() {
    this.hideOverlay = true;
  }
  /* istanbul ignore next */
  openComment(data) {
    this.warningTextOpen = true;
    const mydata = data.originalWarningText;
    this.tablewarningComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  /* istanbul ignore next */
  openCommentORR(data) {
    this.orrTextOpen = true;
    const mydata = data.originalotherRestrictionsAndReq;
    this.tableORRComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  closePopupORR() {
    this.orrTextOpen = false;
  }
  closePopup() {
    this.warningTextOpen = false;
  }
  /* istanbul ignore next */
  changeComment(input) {
    const strValue = input;
    const ar = strValue.match(/<a.*?<\/a>/g);
    let commentwarning = '';
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentwarning = 'Click Here';
    } else {
      commentwarning = input;
    }
    return commentwarning;
  }

  /* istanbul ignore next */
  handleSpecialCharactersForHyperlinks(text) {
    if (text !== null && text !== '') {
      return text.replace(/&nbsp;.*?/gi, ' ').replace(/&amp;/g, '&').replace(/<strong>/gi, '').replace(/<\/strong>/gi, '').replace(/<i>/gi, '').replace(/<\/i>/gi, '');
    } else {
      return '';
    }
  }
  /* istanbul ignore next */
  hyperLinksToSheet(comment) {

    const regex = /<a[^>]*href=["']([^"']*)["']/gm;
    const aregText = /<a(.*?)<\/a>/gm;
    const hyperLinksData = [];
    let m;
    let getraAncharText;
    const totalAnchor = [1, 2, 3, 4, 5, 6, 7];
    const textbetweenTags = [];
    if (comment !== null || comment !== '') {
      for (const val of totalAnchor) {
        getraAncharText = aregText.exec(comment);
        if (getraAncharText == null) {
          break;
        }
        if (getraAncharText.index === aregText.lastIndex) {
          aregText.lastIndex++;
        }
        getraAncharText.forEach((amatch, agroupIndex) => {
          if (agroupIndex === 1) {
            const aindex = amatch.indexOf('>');
            const matchLength = amatch.length;
            textbetweenTags.push(amatch.substring(aindex + 1, matchLength));
          }
        });
      }
      let index = 0;
      for (const val of totalAnchor) {
        m = regex.exec(comment);
        if (m == null) {
          break;
        }
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match, groupIndex) => {
          if (groupIndex === 1) {
            hyperLinksData.push(
              {
                text: this.handleSpecialCharactersForHyperlinks(textbetweenTags[index]),
                hyperlink: match,
                tooltip: match,
                color: 'red'
              });
            index = index + 1;
          }
        });
      }
    }
    return hyperLinksData;
  }
  /* istanbul ignore next */
  addWarningExtraColumns() {
    this.isColumnsHasHyperLinks = this.viewRuleslistTableColumnsExcel.filter(x => x.fieldName === 'warningText').length > 0 ? 'Yes' : 'No';
    if (this.isColumnsHasHyperLinks === 'Yes') {
      VIEWRULE_EXCEL_ADDITIONAL_COLUMNS_WARNINGS.forEach(col => {
        this.viewRuleslistTableColumnsExcel.push(
          {
            fieldName: col.fieldName,
            fieldDescription: col.fieldDescription
          }
        );
      });
    }
  }
  /* istanbul ignore next */
  addOrrExtraColumns() {
    this.isColumnsHasHyperLinks = this.viewRuleslistTableColumnsExcel.filter(x => x.fieldName === 'otherRestrictionsAndRequirements').length > 0 ? 'Yes' : 'No';
    if (this.isColumnsHasHyperLinks === 'Yes') {
      VIEWRULE_EXCEL_ADDITIONAL_COLUMNS_ORR.forEach(col => {
        this.viewRuleslistTableColumnsExcel.push(
          {
            fieldName: col.fieldName,
            fieldDescription: col.fieldDescription
          }
        );
      });
    }
  }
  // openRdComment(data){
  //   this.isClickHere = true;
  //   const mydata = data.rdComment;
  //   this.tablewarningComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  // }
}
