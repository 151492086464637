import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { parallelRuleObj, requestFlags } from '../../models/PureRuleDetailsModel';
import { DataService } from '../../services/data.service';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  moduleSelected = '';
  navigationItems = [];
  selectedItem = 'Create Request';
  LoggedInUserName;
  LoggedInUserEmail;
  role;
  loading = false;
  links = [{ name: 'Assessment Requests', image: '../../../../assets/images/AssementRequest.svg', routerLink: '/assess-requests' },
  { name: 'Reports Archive', image: '../../../../assets/images/ReportsArchive.svg', routerLink: '../reportsarchive' }];
  activeLink = this.links[0].routerLink;
  @Output() stepperChange = new EventEmitter<any>();
  openMenuBar = false;
  givenName;
  adminDD = false;
  worklistDD = false;
  fragranceDD = false;
  firstLetter;
  showUserDetails = false;
  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    const index = _.findIndex(event.path, (val) => {
      return val.className === 'profile-icon header-icon';
    });
    if (index === -1) {
      this.showUserDetails = false;
    } else {
      this.showUserDetails = true;
    }
  }
  constructor(
    private dataService: DataService,
    private route: Router, private msalSvc: MsalService) { }
  /* istanbul ignore next */
  ngOnInit() {
    this.loginInfo();
    this.navigationItems = [
      {
        item: 'Substance Search',
        hasSubnav: false,
        routerLink: '/substance-search/0',
      },
      {
        item: 'Create Request',
        hasSubnav: false,
        routerLink: '/assess-requests/select-recipe',
      },
      {
        item: 'Rule Management',
        hasSubnav: true,
        routerLink: '',
        dropdownItems: [{
          itemName: 'Create Rules',
          routerLink: '/create-insert/select-rpc',
        }, {
          itemName: 'View Rules',
          routerLink: '/rule-management/view-rules'
        }, {
          itemName: 'Regulatory Group Master List',
          routerLink: '/administration/regulatory-group-master'
        }, {
          itemName: 'Search Related Requests or Rules',
          routerLink: '/rule-management/related-requests'
        }
        ]
      },
      {
        item: 'Worklist',
        hasSubnav: true,
        routerLink: '/worklist',
        dropdownItems: [{
          itemName: 'Master Worklist',
          routerLink: '/worklist/master-worklist',
        }, {
          itemName: 'My Worklist',
          routerLink: '/worklist/my-worklist'
        }
        ]
      },
      {
        item: 'Administration',
        hasSubnav: true,
        routerLink: '',
        dropdownItems: [{
          itemName: 'Function Master List',
          routerLink: '/administration/function-master',
        }, {
          itemName: 'Component Master List',
          routerLink: '/administration/component-master'
        }, {
          itemName: 'Property Master List',
          routerLink: '/administration/property-master'
        }

        ]
      },
      {
        item: 'Fragrance Master List',
        hasSubnav: localStorage.getItem('FeatureFlagFragrance') === 'true' ? true : false,
        routerLink: '',
        dropdownItems: [{
          itemName: 'TFC IFRA Category',
          routerLink: '/fragrancemasterlist/tfc-ifra-mapping',
        }, {
          itemName: 'Regional Compliance Level 2',
          routerLink: '/fragrancemasterlist/regional-complaince-level2'
        }, {
          itemName: 'PLM Regulation - FAsT Market',
          routerLink: '/fragrancemasterlist/plm-fast-market'
        }

        ]
      },
      {
        item: 'Help',
        hasSubnav: false,
        routerLink: '/help',
      },
    ];
    $('[data-booked="true"]').popover({
      html: true,
      placement: 'bottom',
      content: '<div class="pooverbox"><div class="row"><div class="col-md-3"> <img class="prof-pos" src="../../../../assets/images/header/profile.png" alt="person"></div>' + '<div class="col-md-9"><div class="userInfo1">' + this.givenName + '</div><div>'
        + '<div class="userInfo"> ' + this.role + '<div>'
        + '<div class="userInfo">' + this.LoggedInUserEmail + '<div></div><div>',
    });
  }

  listClick(event, newValue) {
    this.dataService.isfromSubstancesearch = false;
    this.selectedItem = newValue.itemName;
    localStorage.removeItem('marketDetailsEdit');
    localStorage.removeItem('FormObj');
    if (newValue.itemName === 'View Rules') {
      this.dataService.setComponentName('EditRule');
      if (this.route.url.endsWith('view-rules')) {
        this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
          this.route.navigate(['/rule-management/view-rules']);
        });
      }
    } else if (newValue.itemName === 'Create Rules') {
      this.dataService.setComponentName('CreateInsertRule');
      parallelRuleObj.selectedMarket = null;
      parallelRuleObj.selectedRPC = null;
      parallelRuleObj.selectedrpc1 = null;
      parallelRuleObj.selectedrpc2 = null;
      parallelRuleObj.selectedrpc3 = null;
      parallelRuleObj.selectedrpc4 = null;
      parallelRuleObj.selectedrpc5 = null;
      parallelRuleObj.selectedrpc6 = null;
      parallelRuleObj.intendedUseForParallelRule = null;
      parallelRuleObj.prepMethodForParallelRule = null;
      /* istanbul ignore if */
      if (this.route.url.endsWith('select-rpc')) {
        this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
          this.route.navigate(['/create-insert/select-rpc']);
        });
      }
      setTimeout(() => {
        this.dataService.setSelectedStep(0);
      }, 1000);
    } else if (newValue.itemName === 'Search Related Requests or Rules') {
      /* istanbul ignore if */
      if (this.route.url.endsWith('related-requests')) {
        this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
          this.route.navigate(['/rule-management/related-requests']);
        });
      }
    } else if (newValue.itemName === 'Regulatory Group Master List') {
      /* istanbul ignore if */
      if (this.route.url.endsWith('regulatory-group-master')) {
        this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
          this.route.navigate(['/administration/regulatory-group-master']);
        });
      }
    }
  }


  onAssesmentClick(tabValue) {
    if (!tabValue.hasSubnav) {
      this.dataService.isfromSubstancesearch = false;
    }
    localStorage.removeItem('marketDetailsEdit');
    if (tabValue.item === 'Create Request') {
      this.loading = true;
      requestFlags.viewRequest = false;
      requestFlags.EditRequest = false;
      requestFlags.createRequest = true;
      this.selectedItem = tabValue.item;
      localStorage.removeItem('FormObj');
      this.dataService.setComponentName('AssessRequestsRD');
      setTimeout(() => {
        this.loading = false;
        this.dataService.setSelectedStep(0);
      }, 1000);
      /* istanbul ignore if */
      if (this.route.url.endsWith('/assess-requests/select-recipe')) {
        this.route.navigateByUrl('/select', { skipLocationChange: true }).then(() => {
          this.route.navigate(['/assess-requests/select-recipe']);
        });
      }

    } else if (tabValue.item === 'Create Rules') {
      this.selectedItem = tabValue.item;
      this.dataService.setComponentName('CreateInsertRule');
      setTimeout(() => {
        this.dataService.setSelectedStep(0);
      }, 1000);
      if (this.route.url.endsWith('/create-insert/select-rpc')) {
        this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
          this.route.navigate(['/create-insert/select-rpc']);
        });
      }
    }
    localStorage.setItem('createRequest', 'createRequest');
  }
/* istanbul ignore next */
  loginInfo() {
    this.LoggedInUserName = localStorage.getItem('UserName');
    this.LoggedInUserEmail = localStorage.getItem('UserEmailAddress');
    const roleData = localStorage.getItem('roles');
    const data = roleData.split(',');
    let listRole = [];
    for (const val of data) {
      if (val.toLowerCase().startsWith('fast')) {
        listRole.push(val);
      }
    }
    listRole = _.uniqBy(listRole);
    this.role = listRole.join(',');
    // if (this.msalSvc.instance.hasOwnProperty('getAllAccounts()')) {
    this.givenName = this.msalSvc.instance.getAllAccounts()[0].name;
    this.firstLetter = this.msalSvc.instance.getAllAccounts()[0].username.charAt(0) + this.msalSvc.instance.getAllAccounts()[0].name.charAt(0);
    // }
  }
  // method for logout
  /* istanbul ignore next */
  logout() {
    this.msalSvc.logout();
    localStorage.clear();
  }
  toggleMenu() {
    this.openMenuBar = true;
  }
  navigate(val) {
    if (val === 'Create Request') {
      this.loading = true;
      requestFlags.viewRequest = false;
      requestFlags.EditRequest = false;
      requestFlags.createRequest = true;
      localStorage.removeItem('FormObj');
      this.dataService.setComponentName('AssessRequestsRD');
      setTimeout(() => {
        this.loading = false;
        this.dataService.setSelectedStep(0);
      }, 1000);
      this.route.navigate(['/assess-requests/select-recipe']);
    } else if (val === 'Create Rules') {
      this.dataService.setComponentName('CreateInsertRule');
      setTimeout(() => {
        this.dataService.setSelectedStep(0);
      }, 1000);
      this.route.navigate(['/create-insert/select-rpc']);
    } else if (val === 'Master') {
      this.route.navigate(['/worklist/master-worklist']);
    } else if (val === 'My') {
      this.route.navigate(['/worklist/my-worklist']);
    } else if (val === 'Function') {
      this.route.navigate(['/administration/function-master']);
    } else if (val === 'Component') {
      this.route.navigate(['/administration/component-master']);
    } else if (val === 'Property') {
      this.route.navigate(['/administration/property-master']);
    } else if (val === 'TFC') {
      this.route.navigate(['/fragrance-master-list/tfc-ifra-mapping']);
    } else if (val === 'Regional') {
      this.route.navigate(['/fragrance-master-list/regional-complaince-level2']);
    } else if (val === 'PLMFAsTMarket') {
      this.route.navigate(['/fragrance-master-list/plm-fast-market']);
    } else if (val === 'Help') {
      this.route.navigate(['/help']);

    }
  }
  closeMenu() {
    this.openMenuBar = false;
    this.worklistDD = false;
    this.adminDD = false;
    this.fragranceDD = false;
  }
  navigateParent(val) {
    if (val === 'Worklist') {
      this.worklistDD = !this.worklistDD;
      this.adminDD = false;
    } else if (val === 'Admin') {
      this.adminDD = !this.adminDD;
      this.worklistDD = false;
    } else if (val === 'Fragrance') {
      this.fragranceDD = !this.fragranceDD;
      // this.worklistDD = false;
    }
  }
  openuserDetails() {
    this.showUserDetails = true;
  }
  hideUserDetails() {
    this.showUserDetails = false;
  }
}
