import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { ApiService } from '../common/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {
  private profileObs$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private apiService: ApiService) { }
  getComponentMasterlistApiData(apiurl): Observable<any> {
  return this.apiService.get(apiurl).pipe(timeout(300000));
  }

  postComponentMasterData(url, body): Observable<any> {
    return this.apiService.post(url , body).pipe(timeout(300000));
  }

  getFunctionMasterlistApiData(apiurl): Observable<any> {
    return this.apiService.get(apiurl).pipe(timeout(300000));
  }

  postFunctionMasterData(url, body): Observable<any> {
    return this.apiService.post(url , body).pipe(timeout(300000));
  }

  selfAssignApi(apiurl, body): Observable<any> {
    return this.apiService.post(apiurl, body).pipe(timeout(300000));
  }
  putApiData(url, body?): Observable<any> {
    return this.apiService.put(url, body).pipe(timeout(300000));
  }
  getData(url): Observable<any> {
    return this.apiService.get(url).pipe(timeout(300000));
  }
  getDatawithCode(url, code): Observable<any> {
    return this.apiService.getwithcode(url, code);
  }

  getProfileObs(): Observable<any> {
    return this.profileObs$.asObservable();
}
setProfileObs(profile: any) {
    this.profileObs$.next(profile);
}
}
