import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MyMonitoringService } from '../services/logging.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(public snackBar: MatSnackBar, private myMonitoringService: MyMonitoringService) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(catchError((error: any) => {
        this.myMonitoringService.logException(error); // Manually log exception
        if (error.status === 401) {
          this.snackBar.open('Authentication Failed', 'close', {
            duration: 4000,
            panelClass: ['error']
          });
        } else if (error.error.message === undefined) {
          this.snackBar.open('System error occured. Please contact administrator.', 'close', {
            duration: 5000,
            panelClass: ['error']
          });
        } else {
          this.snackBar.open(error.error.message, 'close', {
            duration: 7600,
            panelClass: ['error']
          });
        }
        return throwError(error);
      }));
  }
}
