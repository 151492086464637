import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../common/services/api/api.service';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssessmentRequestService {

  constructor(private apiService: ApiService) { }
  // GetData
  getData(url): Observable<any> {
    return this.apiService.get(url).pipe(timeout(300000));
  }
  getDatawithCode(url, code): Observable<any> {
    return this.apiService.getwithcode(url, code);
  }
  postData(url, body): Observable<any> {
    return this.apiService.post(url, body).pipe(timeout(300000));
  }
  getFnList() {
    const vals = ['Acid', 'Acidity Regulator', 'Additive', 'Solvents', 'Any'];
    return vals;
  }
  putData(url, body): Observable<any> {
    return this.apiService.put(url, body).pipe(timeout(300000));
  }
  getRegionalComplianceData(url, body): Observable<any> {
    return this.apiService.post(url, body).pipe(timeout(300000));
  }
}
