import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { pureRulesDetailsModel, ruleFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { sirDetailObj } from 'src/app/common/models/SirModel';
import { DataService } from 'src/app/common/services/data.service';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from '../../../../../environments/environment';
import { AssessRequestRaService } from '../../../assess-request-ra.service';
export class CreateRuleSet {
  ruleBucket;
  ruleType;
  ruleTypeId;
  conditionTypeSet;
  setRuleTypeAttribute;
  setAttributeValue;
  checked: boolean;
}
export class CreateRuleWhen {
  ruleBucket;
  ruleType;
  ruleTypeId;
  conditionTypeWhen;
  whenRuleTypeAttribute;
  whenAttributeValue;
  checked: boolean;
}

@Component({
  selector: 'app-create-rule',
  templateUrl: './create-rule.component.html',
  styleUrls: ['./create-rule.component.scss']
})
export class CreateRuleComponent implements OnInit {
  inputSelectedRuleDetails;
  activeTabUR = 'rule-detail';
  loading = false; editRule = false; viewRule = false;
  loadingMsg; ruleDisplayName; ruleTypeId;
  ruleSource; nonRestrictedOrBannedComment; defaultnonRestrictedOrBannedComment;
  validityStartDate: any = ''; validityEndDate: any = ''; ruleIdOnEdit; ruleVersion; marketForModal = '';
  selectedRows = []; myWorklistTableColumns = []; myworkListData = [];
  showRelatedRulesPopup = false; ruleSaveConformationPopup = false; viewRelbtn = false; disablebtn = true; cancelClicked = false;
  showEmailConfirmationDialog = false;
  commentsToUser = '';
  defaultValidityFromDate; defaultValidityEndDate; todayDate;
  pureSirDetail;
  constructor(
    public formBuilder: FormBuilder, public dataObj: DataService, private datePipe: DatePipe, private snackBar: MatSnackBar,
    private assessReqRAService: AssessRequestRaService, private worklistservice: WorklistService, private route: Router) {
  }

   /* istanbul ignore next */
  ngOnInit() {
    this.assessReqRAService.sirData.subscribe(res => {
      console.log(res.pureCode);
      this.pureSirDetail = res;
    });
    this.todayDate = new Date();
    this.defaultValidityFromDate = this.datePipe.transform(this.todayDate, 'longDate');
    this.defaultValidityEndDate = this.datePipe.transform(new Date('9999-12-31'), 'longDate');
    this.dataObj.getruleToEdit().subscribe((value) => {
      if (value !== null) {
        this.defaultnonRestrictedOrBannedComment = value.comments;
        this.nonRestrictedOrBannedComment = this.defaultnonRestrictedOrBannedComment;
        if (ruleFlags.viewRule) {
          this.defaultValidityFromDate = value.validityStartDate ? (this.datePipe.transform(new Date(this.datePipe.transform(value.validityStartDate, 'yyyy-MM-dd')), 'longDate')) : '';
          this.defaultValidityEndDate = value.validityDate ? (this.datePipe.transform(new Date(this.datePipe.transform(value.validityDate, 'yyyy-MM-dd')), 'longDate')) : '';
        }
        this.ruleTypeId = value.ruleTypeID;
        this.ruleIdOnEdit = value.ruleId;
        this.ruleVersion = value.ruleVersion;
        this.marketForModal = recipeModel.marketDetails.marketName;
        Object.assign(sirDetailObj, value.substanceList[0]);
        if (ruleFlags.editRule && this.pureSirDetail == null) {
          this.pureSirDetail = value.substanceList[0];
        }
        const rpcVals = [];
        this.ruleSource = value.ruleSource;
        value.rpcs.forEach((val) => {
          const data = {
            id: val.rpcId,
            text: val.rpcName,
            rpcFullText: val.rpcFullText
          };
          rpcVals.push(data);
        });
        pureRulesDetailsModel.selectedParentRpcs = rpcVals;
        pureRulesDetailsModel.selectedRpcs = rpcVals;
        recipeModel.intendedUse = value.intendedUse;
        this.editRule = true;
      } else {
        this.editRule = false;
      }
    });
    this.dataObj.ruleType.subscribe((value) => {
      if (value.length !== 0) {
        this.inputSelectedRuleDetails = value;
        this.ruleTypeId = this.inputSelectedRuleDetails.ruleTypeId;
        this.ruleDisplayName = this.inputSelectedRuleDetails.ruleTypeDisplayName;
      }
    });
    if (ruleFlags.viewRule) {
      this.editRule = false;
      this.viewRule = true;
    } else if (ruleFlags.editRule) {
      this.editRule = true;
      this.viewRule = false;
    }
    if (this.editRule === true || this.viewRule === true) {
      this.getWorklistColumns();
    }
  }
  /* istanbul ignore next */
  onSaveAndClose() {
    this.onSave();
    if (!this.editRule && !this.viewRule) {
      setTimeout(() => this.dataObj.setExpandablePanel(2), 3500);
    }
  }
  /* istanbul ignore next */
  checkRulesAvail() {
    this.viewRelbtn = true;
    this.myworkListData = [];
    this.selectedRows = [];
    this.loadingMsg = 'Fetching data from FAsT Rule-Engine';
    this.loading = true;
    const apiurl = environment.APIFAsTRule + '/rules/queryrelatedrequests';
    const body = [];
    this.showRelatedRulesPopup = true;
    body.push({
      RuleId: this.ruleIdOnEdit,
      RuleVersion: this.ruleVersion
    });
    this.assessReqRAService.postData(apiurl, body).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.myworkListData = response.map(reqElem => {
          return {
            requestDisplayID: reqElem.requestDisplayID,
            cucCode: reqElem.cucCode,
            cucDescription: reqElem.cucDescription,
            recipeType: reqElem.recipeType,
            recipeStatus: reqElem.recipeStatus,
            marketId: reqElem.market.marketId,
            marketName: reqElem.market.marketName,
            division: (reqElem.tfc.tfcLevel === 5) ? reqElem.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName : reqElem.tfc.parentTFC.parentTFC.parentTFC.tfcName,
            rDFormulationCategory: (reqElem.tfc.tfcLevel === 5) ? reqElem.tfc.parentTFC.parentTFC.parentTFC.tfcName : reqElem.tfc.parentTFC.parentTFC.tfcName,
            formulationType: (reqElem.tfc.tfcLevel === 5) ? reqElem.tfc.parentTFC.parentTFC.tfcName : reqElem.tfc.parentTFC.tfcName,
            formulationClass: (reqElem.tfc.tfcLevel === 5) ? reqElem.tfc.parentTFC.tfcName : reqElem.tfc.tfcName,
            formulationSubClass: (reqElem.tfc.tfcLevel === 5) ? reqElem.tfc.tfcName : '',
            preparationMethod: reqElem.preparationMethod,
            intendedUse: reqElem.intendedUse,
            multicomponent: reqElem.isMultiComponent,
            requestStatus: reqElem.requestStatus,
            ra: reqElem.ra,
            allRa: reqElem.allRA,
            submittedDate: reqElem.submittedDate,
            submittedByUserId: reqElem.submittedByUserId,
            submittedByUserName: reqElem.submittedByUserName,
            comments: reqElem.comments,
            assignedDate: reqElem.assignedDate,
            inProgressRADate: reqElem.inProgressRADate,
            inProgressRDDate: reqElem.inProgressRNDDate,
            inProgressSIRDate: reqElem.inProgressSIRDate,
            rejectedDate: reqElem.rejectedDate,
            deletionDate: reqElem.deletionDate,
            completedDate: reqElem.completedDate,
            rdComment: reqElem.comments,
            raComment: reqElem.raComment,
            topTfcId: reqElem.tfcid,
            allRAIDs: reqElem.allRAIDs,
            raUserID: reqElem.raUserIDred,
            divisionid: (reqElem.tfc.tfcLevel === 5) ? reqElem.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId : reqElem.tfc.parentTFC.parentTFC.parentTFC.tfcId
          };
        });
      }
      const ele = document.getElementById('echeck') as HTMLInputElement;
      ele.checked = false;
      this.selectedRows = [];
    }, (error) => {
      this.loading = false;
    });
  }
  /* istanbul ignore next */
  onCancel() {
      if (!this.editRule) {
        this.disablebtn = true;
        this.dataObj.setExpandablePanel(2);
      } else {
        this.disablebtn = true;
        this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
          this.route.navigate(['/rule-management/view-rules']);
        });
      }
  }
  /* istanbul ignore next */
  getWorklistColumns() {
    const apiurl = environment.APIFAsT + '/getworklistcolumns/relatedrequest';
    this.worklistservice.getWorklistApiData(apiurl).subscribe((response) => {
      this.myWorklistTableColumns = response;
    });
  }
  /* istanbul ignore next */
  onSave() {
    let saveRuleToDbUrl = environment.APIFAsTRule + '/rulesList';
    let ruleType;
    const rpcData = [];
    ruleType = this.ruleDisplayName;
    if (pureRulesDetailsModel.CascadeRpc === 'Y') {
      pureRulesDetailsModel.selectedParentRpcs.forEach((value) => {
        const data = {
          rpcFullText: value.rpcFullText,
          rpcName: value.text,
          rpcId: value.id
        };
        rpcData.push(data);
      });
    } else {
      pureRulesDetailsModel.selectedRpcs.forEach((value) => {
        const data = {
          rpcFullText: value.rpcFullText,
          rpcName: value.text,
          rpcId: value.id
        };
        rpcData.push(data);
      });
    }
    const substanceData = [{
      PureCode: this.pureSirDetail.pureCode,
      PureDescription: this.pureSirDetail.pureDescription,
      SirId: this.pureSirDetail.sirId,
      SubstanceName: this.pureSirDetail.substanceName,
      InciName: this.pureSirDetail.inciName.toString(),
      Einecs: this.pureSirDetail.einecs.toString(),
      Cas: this.pureSirDetail.cas.toString(),
      parentCAS: this.pureSirDetail.parentCAS.toString(),
      Ins: this.pureSirDetail.ins.toString(),
      ENumber: this.pureSirDetail.eNumber.toString(),
      SubstanceFunction: null,
      UoM: '',
      CFDetails: '',
      SubstanceMolWeight: this.pureSirDetail.substanceMolWeight,
      CombinationRatio: null
    }];
    const savedData = [{
      Markets: [pureRulesDetailsModel.Market],
      RPCs: rpcData, /// object list
      RuleId: this.ruleIdOnEdit ? this.ruleIdOnEdit : 0,
      RuleVersion: this.ruleVersion ? this.ruleVersion : 0,
      RuleTypeID: this.ruleTypeId,
      SubstanceList: substanceData,
      RuleType: ruleType,
      ValidityStartDate: this.validityStartDate ? this.datePipe.transform(this.validityStartDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityFromDate, 'yyyy-MM-dd'),
      ValidityDate: this.validityEndDate ? this.datePipe.transform(this.validityEndDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityEndDate, 'yyyy-MM-dd'),
      Comments: this.nonRestrictedOrBannedComment ? this.nonRestrictedOrBannedComment : null,
      CascadeMarket: 'N',
      CascadeRpc: 'Y', // made to Y by default as the flag is always selected
      RuleDescription: ruleType,
      RuleSource: this.ruleSource ? this.ruleSource : '',
      RuleSourceType: 'Regulatory',
      PreparationMethod: recipeModel.preparationMethod,
      IntendedUse: recipeModel.intendedUse
    }];
    this.loading = true;
    this.loadingMsg = 'Saving Rule';
    if (!this.editRule) {
      this.assessReqRAService.postData(saveRuleToDbUrl, savedData).subscribe((response) => {
        this.loading = false;
        this.disablebtn = true;
        if (response[0].ruleId !== 0) {
          this.snackBar.open('Rule created successfully with Rule Id:' + response[0].ruleId, 'close', {
            duration: 5000,
            panelClass: ['success']
          });
        } else {
          this.snackBar.open('Rule could not be created.', 'close', {
            duration: 5000,
            panelClass: ['error']
          });
        }
      }
        , (error) => {
          this.loading = false;
        }
      );
    } else {
      saveRuleToDbUrl = environment.APIFAsTRule + '/rules';
      this.assessReqRAService.putData(saveRuleToDbUrl, savedData).subscribe((response) => {
        this.loading = false;
        this.disablebtn = true;
        if (response[0].ruleId !== 0) {
          this.snackBar.open('Rule updated successfully with Rule Id:' + response[0].ruleId + ' and Rule Version: ' + response[0].ruleVersion, 'close', {
            duration: 5000,
            panelClass: ['success']
          });
          this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
            this.route.navigate(['/rule-management/view-rules']);
          });
        } else {
          this.snackBar.open('Rule could not be created.', 'close', {
            duration: 5000,
            panelClass: ['error']
          });
        }
      }
        , (error) => {
          this.loading = false;
        }
      );
    }

  }
  /* istanbul ignore next */
  sendEmail() {
    this.showEmailConfirmationDialog = true;
    let reqId = '';
    let userDetails = '';
    const uDetail = [];
    const reDet = [];
    this.selectedRows.forEach(element => {
      reDet.push(element.requestDisplayID);
    });
    const distinctReqs = reDet.filter((n, i) => reDet.indexOf(n) === i);
    reqId = distinctReqs.join(' ');
    this.selectedRows.forEach(element => {
      uDetail.push(element.ra);
    });
    const distinctNames = uDetail.filter((n, i) => uDetail.indexOf(n) === i);
    userDetails = distinctNames.join(' ');
    this.commentsToUser = 'Regulatory rule/s for the request no. ' + reqId + ' will be updated/deleted by RA user ' + userDetails + ' .To get the most updated FAsT report, please submit a new request for the formulation. Ignore if the formulation is no longer valid.';
  }
  /* istanbul ignore next */
  sendEmailMessage() {
    this.showEmailConfirmationDialog = false;
    this.showRelatedRulesPopup = false;
    const payLoad = {
      NotificationGroup: 'RDCOMPLETED',
      Subject: 'FAsT | Rules updated for these requests :',
      EmailBody: null,
      ToEmailList: '',
      FromEmail: null,
      CCEmailList: null,
      EmailType: 'html',
      UPD: this.ruleIdOnEdit.toString(),
      PureCodes: null,
      UserRequests: [],
      CUCDescription: null,
      Comments: this.commentsToUser
    };

    if (this.selectedRows.length > 0) {
      this.selectedRows.forEach(row => {
        const reqIds = this.selectedRows.filter(item => item.submittedByUserId === row.submittedByUserId).map(i => i.requestDisplayID);
        payLoad.UserRequests.push({
          userId: +row.submittedByUserId,
          requestIds: reqIds
        });
      });
    }

    if (payLoad.UserRequests.length > 0) {
      this.assessReqRAService.sendRulesEmailNotification(payLoad);
    }
  }
  /* istanbul ignore next */
  onGroupSelect(checkValue) {
    if (checkValue) {
      this.selectedRows = this.myworkListData;
    } else {
      this.selectedRows = [];
    }
  }
}
