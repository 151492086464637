import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AssessmentRequestService } from 'src/app/assess-requests/assess-requests.service';
import { ruleFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { DataService } from 'src/app/common/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-rules',
  templateUrl: './edit-rules.component.html',
  styleUrls: ['./edit-rules.component.scss']
})
export class EditRulesComponent implements OnInit {
  selectedRuleBucket: string;
  ruleDetailsToEdit;
  activeTabUR = 'update-rule';
  goToWorklistConfirmation = false;
  expandedView = false;
  loadingMsg = 'Fetching data from FAST Rule-Engine';
  loading = false;
  getRuleData;
  editRuleFlag = false;
  viewRuleFlag = false;
  constructor(public dataSvc: DataService, private route: Router, private assessRequestRa: AssessmentRequestService) { }
  /* istanbul ignore next */
  ngOnInit() {
    this.loading = true;
    this.dataSvc.getViewruleToEdit().subscribe((val) => {
      this.ruleDetailsToEdit = val;
    });
    if (ruleFlags.viewRule) {
      this.editRuleFlag = false;
      this.viewRuleFlag = true;
    } else if (ruleFlags.editRule) {
      this.editRuleFlag = true;
      this.viewRuleFlag = false;
    }
    this.loading = true;
    this.loadingMsg = 'Fetching data from FAST Rule-Engine';
    let apiurl = environment.APIFAsTRule + '/rules/ruleID/' + this.ruleDetailsToEdit.ruleId + '/ruleType/' + this.ruleDetailsToEdit.ruleTypeID;
    if (this.viewRuleFlag) {
      let ruleVer = this.ruleDetailsToEdit.ruleVersion;
      ruleVer = ruleVer.substring(1);
      apiurl = environment.APIFAsTRule + '/rules/ruleID/' + this.ruleDetailsToEdit.ruleId + '/ruleType/' + this.ruleDetailsToEdit.ruleTypeID + '/ruleVersion/' + ruleVer;
    }
    this.assessRequestRa.getData(apiurl).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.getRuleData = response;
        this.getRuleData[0].ruleStatus = this.ruleDetailsToEdit.ruleStatus;
        this.getRuleData[0].isFutureActiveRule = this.ruleDetailsToEdit.ruleStatus === 'Future-Active' ? true : false;
        if (response[0].ruleClassification === 'Substance Based Rules' || response[0].ruleClassification === 'Substance Group Rules' || response[0].ruleClassification === 'Property Based Rules' || response[0].ruleClassification === 'Product Based Rules') {
          this.setRuleBucket(response[0].ruleClassification);
        }
        this.dataSvc.setruleToEdit(response[0]);
        response[0].ruleTypeId = response[0].ruleTypeID;
        response[0].ruleTypeDisplayName = response[0].ruleType;
        if (response[0].ruleClassification === 'Substance Based Rules') {
          this.dataSvc.setSelectedRuleType(response[0]);
          this.dataSvc.setExpandablePanel(3);
        } else if (response[0].ruleClassification === 'Substance Group Rules') {
          this.dataSvc.setSelectedGroupRuleType(response[0]);
          this.dataSvc.setParallelRuleFormValues(null);
          this.dataSvc.setExpandablePanel(1);
        } else if (response[0].ruleClassification === 'Property Based Rules' || response[0].ruleClassification === 'Product Based Rules') {
          this.dataSvc.setSelectedProductRuleType(response[0]);
          this.dataSvc.setExpandablePanel(2);
        }
      }
    }, (error) => {
      this.loading = false;
    });
  }
  setRuleBucket(ruleBucketValue) {
    this.selectedRuleBucket = ruleBucketValue;
    if (ruleBucketValue === 'Product Based Rules') {
      this.dataSvc.setExpandablePanel(2);
    } else if (ruleBucketValue === 'Substance Based Rules') {
      this.dataSvc.setExpandablePanel(3);
    } else if (ruleBucketValue === 'Substance Group Rules') {
      this.dataSvc.setExpandablePanel(3);
    }
  }
  /* istanbul ignore next */
  navigateToViewRules() {
    this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
      this.route.navigate(['/rule-management/view-rules']);
    });
  }
  /* istanbul ignore next */
  navigateToSubstanceSearch() {
    this.dataSvc.fromViewtoSubstance = true;
    this.route.navigate(['/substance-search/1']);
  }
}
