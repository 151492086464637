import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { AuthorizationService } from './authorization.service';
// import { AuthPermission } from './authorization.types';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[disableIfUnauthorized]'
})
export class DisableIfUnauthorizedDirective implements OnInit {
    @Input('disableIfUnauthorized') permissions: string; // Required permissions passed in
    // Use like below:
    // <div [disableIfUnauthorized]="'createRule,viewRule'"> <!-- a property set or passed into the component -->

    constructor(private el: ElementRef, private authorizationService: AuthorizationService) { }

    ngOnInit() {
        if (!this.authorizationService.hasPermission(this.permissions)) {
            this.el.nativeElement.disabled = true;
            this.el.nativeElement.style.pointerEvents = 'none';
            this.el.nativeElement.style.textDecoration = 'none';
        }
    }
}
