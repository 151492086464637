import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { COMPONENT_MASTER_COLUMNS } from 'src/app/common/models/tableColumns';
import { environment } from 'src/environments/environment';
import { AdministrationService } from '../administration.service';
@Component({
  selector: 'app-component-master',
  templateUrl: './component-master.component.html',
  styleUrls: ['./component-master.component.scss']
})
export class ComponentMasterComponent implements OnInit {
  loading = true;
  loadingComponentMasterListMessage;
  exportDialog = false;
  filteredRows: any = [];
  selectedRows: any = [];
  componentMasterListColumns;
  componentMasterListData = [];
  addComponentMaster = false;
  editComponentMaster = false;
  fastComponentId;
  fastComponentName;
  model: any = {};
  modelEdit: any = {};
  userId: any;
  userDetails: any;
  enterComponentName = false;
  isComponentEmpty = false;
  status = [];
  selectedStatus: any;
  prevName: any;
  prevStatus: any;
  constructor(public componentMasterservice: AdministrationService, public matSnackBar: MatSnackBar) { }
  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('userRolesPermissions'));
    this.userId = this.userDetails.userId;
    this.status = [
      { name: 'Active', code: 'Y' },
      { name: 'In-Active', code: 'N'},
    ];
    this.getComponentMasterList();
  }
  getComponentMasterList() {
    this.componentMasterListColumns = COMPONENT_MASTER_COLUMNS;
    this.loading = true;
    this.loadingComponentMasterListMessage = 'Getting Component Master Details';
    const apiurl = environment.APIFAsT + '/multicomponentlist';
    this.componentMasterservice.getComponentMasterlistApiData(apiurl).subscribe((response1) => {
      this.loading = false;
      if (response1.length !== 0) {
        this.componentMasterListData = response1.map(respElem => {
          return {
            multiComponentId: respElem.multiComponentId,
            multiComponentName: respElem.multiComponentName,
            isActive: respElem.isActive === 'Y' ? 'Active' : 'In-Active',
            createdByUserId: respElem.createdByUserId,
            createdDate: respElem.createdDate,
            createdUserName: respElem.createdUserName,
            modifiedUserName: respElem.modifiedUserName,
            modifiedDate: respElem.modifiedDate,
          };
        });
      } else {
        this.componentMasterListData = [];
      }
    }, (error) => {
      this.loading = false;
    });
  }
  addComponentMasterData() {
    this.model.fastComponentName = ' ';
    this.addComponentMaster = true;
  }
  saveComponentMasterData() {
    if (this.model.fastComponentName != null && this.model.fastComponentName.trim() !== '') {
      if (this.isDuplicateComponent(this.model.fastComponentName, 'No')) {
        const data = {
          MultiComponentName: this.model.fastComponentName,
          CreatedByUserId: this.userId,
          IsActive: 'Y',
          ModifiedByUserId: this.userId,
        };
        const apiurl = environment.APIFAsT + '/addmulticomponent';
        this.componentMasterservice.postComponentMasterData(apiurl, data).subscribe((response) => {
          this.loading = false;
          this.getComponentMasterList();
          this.matSnackBar.open('Component Name added successfully ', 'close', {
            duration: 3000,
            panelClass: ['success']
          });
        }, (error) => {
          this.addComponentMaster = false;
          this.getComponentMasterList();
        });
        this.addComponentMaster = false;
        this.getComponentMasterList();
      } else {
        this.enterComponentName = true;
        return false;
      }
    } else {
      this.isComponentEmpty = true;
      return false;
    }
  }
  editComponentMasterData(componentMasterList) {
    this.enterComponentName = false;
    this.editComponentMaster = true;
    this.modelEdit.fastComponentId = componentMasterList._selection.multiComponentId;
    this.prevName = this.modelEdit.fastComponentName = componentMasterList._selection.multiComponentName;
    this.prevStatus = componentMasterList._selection.isActive === 'Active' ? 'Y' : 'N';
    this.selectedStatus = this.status.filter(f => f.name === componentMasterList._selection.isActive)[0];
  }
  onSelectType(event) {
    if (event) {
      this.selectedStatus = event;
    }
  }
  updateComponentMasterData() {
    if (this.modelEdit.fastComponentName !== null && this.modelEdit.fastComponentName.trim() !== '') {
      if (this.isDuplicateComponent(this.modelEdit.fastComponentName, 'Yes')) {
        const data = {
          MultiComponentId: this.modelEdit.fastComponentId,
          MultiComponentName: this.modelEdit.fastComponentName,
          IsActive: this.selectedStatus.code,
          ModifiedByUserId: this.userId,
        };
        const apiurl = environment.APIFAsT + '/updatemulticomponent';
        this.componentMasterservice.postComponentMasterData(apiurl, data).subscribe((response) => {
          this.loading = false;
          this.getComponentMasterList();
          this.matSnackBar.open('Component details updated successfully ', 'close', {
            duration: 3000,
            panelClass: ['success']
          });
        }, (error) => {
          this.editComponentMaster = false;
          this.getComponentMasterList();
        });
        this.editComponentMaster = false;
        this.getComponentMasterList();
      } else {
        this.enterComponentName = true;
        return false;
      }
    } else {
      this.isComponentEmpty = true;
      return false;
    }
  }
  isDuplicateComponent(componentName, isEdit) {
    if (isEdit === 'No') {
      const checkDupilcate = this.componentMasterListData.find(x => x.multiComponentName.trim().toLowerCase() === componentName.trim().toLowerCase());
      if (checkDupilcate === null || checkDupilcate === undefined) {
        return true;
      } else {
        return false;
      }
    }
    if (isEdit === 'Yes') {
      const checkDupilcate = this.componentMasterListData.find(x => x.multiComponentName.trim().toLowerCase() === componentName.trim().toLowerCase());
      if (this.selectedStatus.code === this.prevStatus && componentName.trim().toLowerCase() === this.prevName.toLowerCase()) {
        return true;
      } else if (checkDupilcate === null || checkDupilcate === undefined) {
        return true;
      } else if (checkDupilcate !== null && componentName.trim().toLowerCase() === this.prevName.toLowerCase() && checkDupilcate.isActive !== this.selectedStatus.code) {
        return true;
      } else {
        return false;
      }
    }
  }

  showExportDialog() {
    this.exportDialog = true;
  }

  onFilter(event) {
    this.filteredRows = event.filteredValue;
  }
  /* istanbul ignore next */
  onClearFilters(componentMasterList: any) {
    componentMasterList.reset();
    this.filteredRows = [];
    for (const value of this.componentMasterListColumns) {
      if (value.field !== 'check') {
        const colname = value.field + '_val';
        if ((<HTMLInputElement> document.getElementById(colname)) && (<HTMLInputElement> document.getElementById(colname)).value) {
          (<HTMLInputElement> document.getElementById(colname)).value = '';
        }
      }
    }
  }
}

