import 'jstree';

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { pureRulesDetailsModel } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { EXECUTE_RULE_TABLE_COLUMNS, EXECUTE_RULE_TABLE_COLUMNS_EXCEL } from 'src/app/common/models/tableColumns';
import { DataService } from 'src/app/common/services/data.service';
import { environment } from 'src/environments/environment';

import { AssessRequestRaService } from '../assess-request-ra.service';

@Component({
  selector: 'app-execute-rules-ra',
  templateUrl: './execute-rules-ra.component.html',
  styleUrls: ['./execute-rules-ra.component.scss']
})
export class ExecuteRulesRaComponent implements OnInit {

  prepareRuleColumns = [];
  prepareRuleColumnsExcel = [];
  rpcdata = [];
  rpcUnileverMarket = [];
  rpcOtherMarket = [];
  overlayText = '';
  hideOverlay = true;
  prepareRulePageTableView = true;
  prepareRuleTabView = false;
  prepareRuleTableData = [];
  TFCName;
  selectedMarket;
  unileverMarketObj = {
    marketId: ''
  };
  marketsToSelect = [];
  loading = true;
  rpc;
  unileverRpc = [];
  sIRIds: any;
  sirIdList = [];
  tfc: any;
  execRulePageLoadingMessage;
  sirIDInvalidList = [];
  goToWorklistConfirmation = false;
  rpcdataForTree = [];
  rpcToSelect = [];
  tfcData = [];
  selectedRPC = '';
  rpcFullData = [];
  rpcData = [];
  market = '';
  prepareRuleBtnDissageFlag = true;
  warningTextOpen: boolean;
  tablewarningComment: any;
  orrTextOpen: boolean;
  tableORRComment: any;
  isColumnsHasHyperLinks = 'Yes';
  constructor(
    public assessraService: AssessRequestRaService,
    public matSnackBar: MatSnackBar,
    private dataService: DataService,
    private route: Router
  ) { }

  /* istanbul ignore next */
  ngOnInit() {
    this.prepareRuleColumns = EXECUTE_RULE_TABLE_COLUMNS;
    this.prepareRuleColumnsExcel = EXECUTE_RULE_TABLE_COLUMNS_EXCEL;
    this.selectedMarket = recipeModel.marketDetails.marketName;
    this.market = this.selectedMarket;
    this.isColumnsHasHyperLinks = 'Yes';
    pureRulesDetailsModel.selectedMarketForRulesDisplay = recipeModel.marketDetails;
    this.getFallbackMarkets();
    this.tfc = recipeModel.tfc;
    this.dataService.setSelectedStep(2);
    this.getTFCbyID();
  }

  /* istanbul ignore next */
  getFallbackMarkets() {
    const marketName = recipeModel.marketDetails.marketName;
    this.loading = true;
    const serviceCall = this.assessraService.getFallBackMktData(marketName);
    if (serviceCall !== undefined) {
      serviceCall.subscribe((data: any[]) => {
        if (data !== null) {
          const fallBackMarkets = data;
          for (const value of fallBackMarkets) {
            this.marketsToSelect.push(value);
          }
        } else {
          this.marketsToSelect.push(recipeModel.marketDetails);
        }
        this.unileverMarketObj = this.marketsToSelect.filter(x => x.marketName === 'Unilever')[0];
        this.getRPCDataBasedOnMarket(this.selectedMarket);
        setTimeout(() => {
          this.loading = true;
          this.getRPCbyMarketAndTfc(pureRulesDetailsModel.selectedMarketForRulesDisplay, 1);
        }, 1000);
      }, (error) => {
        this.loading = false;
      });
    }
  }
  onMarketChange(filterVal: any) {
    this.rpcToSelect = [];
    this.market = filterVal.value.marketName;
    pureRulesDetailsModel.selectedMarketForRulesDisplay = this.marketsToSelect.filter(x => x.marketName === filterVal.value.marketName)[0];
    $('#treeDiv').jstree('destroy').empty();
    this.getRPCDataBasedOnMarket(pureRulesDetailsModel.selectedMarketForRulesDisplay.marketName);
    setTimeout(() => {
      this.loading = true;
      this.getRPCbyMarketAndTfc(pureRulesDetailsModel.selectedMarketForRulesDisplay, 1);
    }, 1000);
  }
  /* istanbul ignore next */
  getRPCbyMarketAndTfc(selectedMarket, updateRpcList) {
    this.rpc = [];
    let index;
    this.tfc = recipeModel.tfc;
    this.loading = true;
    const encodedTfcName = encodeURIComponent(encodeURIComponent(encodeURIComponent(this.tfc.tfcId)));
    const apiurl = environment.APIFAsT + '/markets/' + selectedMarket.marketId + '/tfcs/' + encodedTfcName + '/rpcs';
    const url = environment.APIFAsT + '/markets/' + this.unileverMarketObj.marketId + '/tfcs/' + encodedTfcName + '/rpcs';
    const serviceCall = forkJoin([this.assessraService.getData(apiurl), this.assessraService.getData(url)]);
    if (serviceCall !== undefined) {
      serviceCall.subscribe((response) => {
        if (response[0] !== undefined && response[0].length !== 0) {
          this.rpc = response[0];
          this.rpc = response[0].map(respElem => {
            return {
              rpcId: respElem.rpcId,
              rpcName: respElem.rpcDescription,
              rpcFullText: respElem.rpcSearchValue
            };
          });
          this.loading = false;
        }
        if (response[1] !== undefined && response[1].length !== 0) {
          this.unileverRpc = response[1];
          this.unileverRpc = response[1].map(respElement => {
            return {
              rpcId: respElement.rpcId,
              rpcName: respElement.rpcDescription,
              rpcFullText: respElement.rpcSearchValue
            };
          });
          this.loading = false;
        }
        if (response[0] !== undefined && (response[0].length === 0 || response[0].text === 'No Regulatory Product Class is Mapped')) {
          this.loading = false;
          this.matSnackBar.open('RPC Not mapped for Market ' + selectedMarket.marketName + ' and TFC ' + this.tfc.tfcName, 'close', {
            duration: 3900,
            panelClass: ['error']
          });
        }
        if (this.unileverRpc.length !== 0) {
          this.unileverRpc.forEach((rpcValue) => {
            index = _.findIndex(this.rpc, (value) => value.rpcId === rpcValue.rpcId);
            if (index === -1) {
              this.rpc.push(rpcValue);
            }
          });
        }
        if (updateRpcList === 1) {
          recipeModel.rpcList = this.rpc;
        }
        this.getPrepareRuleTableData();
      }, (error) => {
        this.loading = false;
      });
    }
  }
   /* istanbul ignore next */
  getRpcTree(market) {
    const encodedTfcName = encodeURIComponent(encodeURIComponent(encodeURIComponent(recipeModel.tfcFullText)));
    const otherMarketRpcTreeApiurl = environment.APIFAsT + '/markets/' + market + '/tfcname/' + encodedTfcName + '/rpcs/fortree';
    const unileverRpctreeApiurl = environment.APIFAsT + '/markets/Unilever/tfcname/' + encodedTfcName + '/rpcs/fortree';
    const serviceCall = forkJoin([this.assessraService.getData(otherMarketRpcTreeApiurl), this.assessraService.getData(unileverRpctreeApiurl)]);
    serviceCall.subscribe((response) => {
      if (response[0] !== null) {
        this.rpcOtherMarket = response[0];
        this.createRpcTree(this.rpcOtherMarket);
      }
      if (response[1] !== null) {
        this.rpcUnileverMarket = response[1];
        this.createRpcTreeUnilever(this.rpcUnileverMarket);
      }
    }, (error) => {
      this.loading = false;
    });
  }
  /* istanbul ignore next */
  createRpcTree(rpcdata) {
    $('#treeDiv').on('changed.jstree', (evt, data) => {
      $('#treeDiv').jstree(true).set_icon(data.node, 'https://jstree.com/tree-icon.png');
    });

    $('#treeDiv').on('changed.jstree', (e, rpcdataChanged) => {
      /* tslint:disable:no-empty */
    }).jstree({
      plugins: ['changed'],
      checkbox: {
        three_state: false

      },
      core: {
        check_callback: true,
        data: rpcdata,
        themes: {
          icons: false
        }
      },
      types: {
        default: {
          icon: 'src/assets/images/tree.svg'
        }
      },
      icon: 'glyphicon glyphicon-file file-node',
      // tslint:disable-next-line: space-before-function-paren // tslint:disable-next-line: no-shadowed-variable
    }).bind('loaded.jstree', function (event, rpcdata123) {
      $(this).jstree('open_all');
    });
    $('#treeDiv').jstree(true).redraw(true);
  }
  /* istanbul ignore next */
  createRpcTreeUnilever(unileverRpcData) {

    $('#treeDivUni').on('changed.jstree', (evt, data) => {
      $('#treeDivUni').jstree(true).set_icon(data.node, 'https://jstree.com/tree-icon.png');
    });

    $('#treeDivUni').on('changed.jstree', (e, unileverRpcDataChanged) => {
      /* tslint:disable:no-empty */
    }).jstree({
      plugins: ['changed'],
      checkbox: {
        three_state: false

      },
      core: {
        check_callback: true,
        data: unileverRpcData,
        themes: {
          icons: false
        }
      },
      types: {
        default: {
          icon: 'src/assets/images/tree.svg'
        }
      },
      icon: 'glyphicon glyphicon-file file-node',
      // tslint:disable-next-line: space-before-function-paren // tslint:disable-next-line: no-shadowed-variable
    }).bind('loaded.jstree', function (event, unileverRpcData123) {
      $(this).jstree('open_all');
    });
    $('#treeDivUni').jstree(true).redraw(true);
  }
  /* istanbul ignore next */
  getPrepareRuleTableData() {
    let fetchRuleBody;
    this.loading = true;
    this.execRulePageLoadingMessage = 'Fetching data from FAST Rule-Engine';
    recipeModel.formulationRequestDetails.forEach(element => {
      this.sirIdList.push(element.substanceID);
    });
    this.sirIDInvalidList = this.sirIdList.filter(x => x === 'SIR ID Not found');
    if (this.sirIDInvalidList.length > 0) {
      this.matSnackBar.open('Some pures in the recipe is not mapped with SIR ID', 'close', {
        duration: 5000,
        panelClass: ['success']
      });
    }
    this.sirIdList = this.sirIdList.filter(x => x !== 'SIR ID Not found' && x !== '');
    if (pureRulesDetailsModel.selectedMarketForRulesDisplay.marketId !== this.unileverMarketObj.marketId) {
      fetchRuleBody = {
        MarketList: [pureRulesDetailsModel.selectedMarketForRulesDisplay, this.unileverMarketObj],
        RPCList: this.rpc,
        SIRIds: this.sirIdList.filter((a, b) => this.sirIdList.indexOf(a) === b),
        TFCName : this.TFCName
      };
    } else {
      fetchRuleBody = {
        MarketList: [pureRulesDetailsModel.selectedMarketForRulesDisplay],
        RPCList: this.unileverRpc,
        SIRIds: this.sirIdList.filter((a, b) => this.sirIdList.indexOf(a) === b),
        TFCName : this.TFCName
      };
    }
    const apiurl = environment.APIFAsTRule + '/rules/rulesFilterActiveMrpc';
    const serviceCall = this.assessraService.postData(apiurl, fetchRuleBody);
    serviceCall.subscribe((response) => {
      if (response !== undefined && response.length !== 0) {
        this.loading = false;
        this.prepareRuleBtnDissageFlag = false;
        this.prepareRuleTableData = response;
        this.prepareRuleTableData.forEach(data => {
          data.ruleVerionToDisplay = 'V' + data.ruleVersion;
          data.isAllergen = data.isAllergen === 'Y' ? 'Y' : '';
          data.allowedFuncFlag = data.ruleTypeID === 14 ? 'Y' : 'N';
          data.warningText = (data.warningText !== null) ? data.warningText : ((data.warningStatement !== null) ? data.warningStatement : '');
          if (data.warningText !== '') {
            const warningLinkRuleData = this.assessraService.hyperLinksToSheet(data.warningText);
            data.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
            data.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
            data.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
            data.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
            data.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
            data.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
            data.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
            data.originalWarningText = data.warningText;
            data.warningText = this.changeComment(data.warningText);
            data.warningTextStripped = this.handleSpecialCharacters(data.originalWarningText).substr(0, 14) + '...';
            data.warningTextStrippedExcel = this.handleSpecialCharacters(data.originalWarningText);
          }
          data.otherRestrictionsAndRequirements = (data.otherRestrictionsAndRequirements !== null) ? data.otherRestrictionsAndRequirements : '';
          if (data.otherRestrictionsAndRequirements !== '') {
            const orrLinkRuleData = this.assessraService.hyperLinksToSheet(data.otherRestrictionsAndRequirements);
            data.ORR1 = orrLinkRuleData[0] !== undefined ? orrLinkRuleData[0] : '';
            data.ORR2 = orrLinkRuleData[1] !== undefined ? orrLinkRuleData[1] : '';
            data.ORR3 = orrLinkRuleData[2] !== undefined ? orrLinkRuleData[2] : '';
            data.ORR4 = orrLinkRuleData[3] !== undefined ? orrLinkRuleData[3] : '';
            data.ORR5 = orrLinkRuleData[4] !== undefined ? orrLinkRuleData[4] : '';
            data.ORR6 = orrLinkRuleData[5] !== undefined ? orrLinkRuleData[5] : '';
            data.ORR7 = orrLinkRuleData[6] !== undefined ? orrLinkRuleData[6] : '';
            data.originalotherRestrictionsAndReq = data.otherRestrictionsAndRequirements;
            data.otherRestrictionsAndRequirements = this.changeComment(data.otherRestrictionsAndRequirements);
            data.otherRestrictionsAndRequirementsStripped =  this.handleSpecialCharacters(data.originalotherRestrictionsAndReq).substr(0, 14) + '...';
            data.otherRestrictionsAndRequirementsStrippedExcel = this.handleSpecialCharacters(data.originalotherRestrictionsAndReq);
          }
          if (data.cfDetails !== null) {
            data.cfSubstanceExpressedAs = data.cfDetails.component;
            data.cf = data.cfDetails.cf !== 0 ? data.cfDetails.cf : '';

          }
          if (data.ruleClassification === 'Substance Based Rules') {
            if (data.ruleTypeID === 30) {
              data.propertySource = data.propertyName;
              data.propertyName = '';
            }
          }
          if (data.ruleClassification === 'Property Based Rules') {
            if (data.ruleTypeID === 7 || data.ruleTypeID === 8 || data.ruleTypeID === 9 || data.ruleTypeID === 49) {
              data.propertySource = 'CUC';
            }
          }
          if (data.ruleClassification === 'Substance Group Rules') {
            data.substanceGroup = data.groupName;
          }

        });

      } else {
        this.prepareRuleTableData = [];
        this.prepareRuleBtnDissageFlag = true;
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.prepareRuleBtnDissageFlag = true;
    });

  }
  handleSpecialCharacters(text) {
    return text.replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, '').replace(/&amp;/g, '&');
  }

  prepareRulebtnclick() {
    this.prepareRulePageTableView = false;
    this.prepareRuleTabView = true;
  }
  goToWorklistBtnClick() {
    this.route.navigate(['/worklist/master-worklist']);
  }
  /*istanbul ignore next */
  getTFCbyID() {
    this.execRulePageLoadingMessage = 'Loading..';
    const apiurl = environment.APIFAsT + '/tfcid/' + recipeModel.tfc.tfcId;
    const serviceCall = this.assessraService.getData(apiurl);
    if (serviceCall !== undefined) {
      serviceCall.subscribe((response) => {
        if (response !== undefined && response.length !== 0) {
          this.tfcData = response;
          this.TFCName = this.tfcData[0].tfcFullText;
        } else {
          this.loading = false;
          this.matSnackBar.open('No TFC Found for TFC ID' + recipeModel.tfc.tfcId, 'close', {
            duration: 3000,
            panelClass: ['error']
          });
        }
      });
    }
  }
  /*istanbul ignore next */
  getRPCDataBasedOnMarket(marketSelected) {
    this.selectedRPC = '';
    this.rpcdataForTree = [];
    this.loading = true;
    this.rpc = [];
    this.rpcToSelect = [];
    this.execRulePageLoadingMessage = 'Loading..';
    const result = this.marketsToSelect.filter(x => x.marketName === marketSelected)[0];
    const apiurl = environment.APIFAsT + '/marketid/' + result.marketId + '/tfcid/' + recipeModel.tfc.tfcId + '/rpclist';
    const serviceCall = this.assessraService.getData(apiurl);
    if (serviceCall !== undefined) {
      serviceCall.subscribe((response) => {
        if (response !== undefined && response.length !== 0) {
          this.rpcToSelect = response;
          this.onRPCChangedLoad(response[0]);
        } else {
          this.loading = false;
          this.getTreeDataBasedOnMarket(response);
          this.matSnackBar.open('No RPCs are found for market ' + marketSelected, 'close', {
            duration: 3000,
            panelClass: ['error']
          });
        }
      });
    }
  }
  onRPCChanged(selectedRPC) {
    this.getTreeDataBasedOnMarket(selectedRPC.value);
  }
  onRPCChangedLoad(selectedRPCOnLoad) {
    this.getTreeDataBasedOnMarket(selectedRPCOnLoad);
  }

  /* istanbul ignore next */
  getTreeDataBasedOnMarket(rpcVal) {
    this.loading = true;
    $('#treeDiv').jstree('destroy').empty();
    const encodedTfcName = encodeURIComponent(encodeURIComponent(encodeURIComponent(this.TFCName)));
    let otherMarketRpcTreeApiurl;
    let unileverRpctreeApiurl;
    if (rpcVal.rpcId !== undefined) {
      otherMarketRpcTreeApiurl = environment.APIFAsT + '/markets/' + pureRulesDetailsModel.selectedMarketForRulesDisplay.marketName + '/tfcname/' + encodedTfcName + '/rpcs/fortree/rpc/' + rpcVal.rpcId;
      unileverRpctreeApiurl = environment.APIFAsT + '/markets/Unilever/tfcname/' + encodedTfcName + '/rpcs/fortree/rpc/' + rpcVal.rpcId;

    } else {
      unileverRpctreeApiurl = environment.APIFAsT + '/markets/Unilever/tfcname/' + encodedTfcName + '/rpcs/fortree/rpc/0';
      otherMarketRpcTreeApiurl = environment.APIFAsT + '/markets/' + pureRulesDetailsModel.selectedMarketForRulesDisplay.marketName + '/tfcname/' + encodedTfcName + '/rpcs/fortree/rpc/0';
    }
    const serviceCall = forkJoin([this.assessraService.getData(otherMarketRpcTreeApiurl), this.assessraService.getData(unileverRpctreeApiurl)]);
    if (serviceCall !== undefined) {
      serviceCall.subscribe((response) => {
        if (response[0] !== undefined && response[0].length > 0) {
          this.loading = false;
          this.rpcOtherMarket = response[0];
          this.createRpcTree(this.rpcOtherMarket);
        } else {
          this.rpcOtherMarket = [];
          this.createRpcTree(this.rpcOtherMarket);
        }
        if (response !== undefined && response[1].length > 0) {
          this.rpcUnileverMarket = response[1];
          this.createRpcTreeUnilever(this.rpcUnileverMarket);
        } else {
          this.rpcUnileverMarket = [];
          this.createRpcTree(this.rpcUnileverMarket);
        }
        this.loading = false;
      }, (error) => {
        this.loading = false;
      });
    }
  }

  showOverlay(htmlContent) {
    this.overlayText = htmlContent;
    this.hideOverlay = false;
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }

  /* istanbul ignore next */
  hideOverlayPanel() {
    this.hideOverlay = true;
  }
  /* istanbul ignore next */
  openComment(data) {
    this.warningTextOpen = true;
    const mydata = data.originalWarningText;
    this.tablewarningComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  closePopup() {
    this.warningTextOpen = false;
  }
  /* istanbul ignore next */
  openCommentORR(data) {
    this.orrTextOpen = true;
    const mydata = data.originalotherRestrictionsAndReq;
    this.tableORRComment = mydata.replace(/<a/gi, '<a target="_blank"');
  }
  closePopupORR() {
    this.orrTextOpen = false;
  }
  /* istanbul ignore next */
  changeComment(input) {
    const strValue = input;
    const ar = strValue.match(/<a.*?<\/a>/g);
    let commentwarning = '';
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentwarning = 'Click Here';
    } else {
      commentwarning = input;
    }
    return commentwarning;
  }
  /* istanbul ignore next */
  handleSpecialCharactersForHyperlinks(text) {
    if (text !== null && text !== '') {
      return text.replace(/&nbsp;.*?/gi, ' ').replace(/&amp;/g, '&').replace(/<strong>/gi, '').replace(/<\/strong>/gi, '').replace(/<i>/gi, '').replace(/<\/i>/gi, '');
    } else {
      return '';
    }
  }
  /* istanbul ignore next */
  hyperLinksToSheet(comment) {
    const regex = /<a[^>]*href=["']([^"']*)["']/gm;
    const aregText = /<a(.*?)<\/a>/gm;
    const hyperLinksData = [];
    let m;
    let getraAncharText;
    const totalAnchor = [1, 2, 3, 4, 5, 6, 7];
    const textbetweenTags = [];
    if (comment !== null || comment !== '') {
      for (const val of totalAnchor) {
        getraAncharText = aregText.exec(comment);
        if (getraAncharText == null) {
          break;
        }
        if (getraAncharText.index === aregText.lastIndex) {
          aregText.lastIndex++;
        }
        getraAncharText.forEach((amatch, agroupIndex) => {
          if (agroupIndex === 1) {
            const aindex = amatch.indexOf('>');
            const matchLength = amatch.length;
            textbetweenTags.push(amatch.substring(aindex + 1, matchLength));
          }
        });
      }
      let index = 0;
      for (const val of totalAnchor) {
        m = regex.exec(comment);
        if (m == null) {
          break;
        }
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match, groupIndex) => {
          if (groupIndex === 1) {
            hyperLinksData.push(
              {
                text: this.handleSpecialCharactersForHyperlinks(textbetweenTags[index]),
                hyperlink: match,
                tooltip: match,
                color: 'red'
              });
            index = index + 1;
          }
        });
      }
    }
    return hyperLinksData;
  }
}
