import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from 'src/environments/environment';
import { AssessRequestRaService } from '../../assess-request-ra.service';

@Component({
  selector: 'app-prepare-rule',
  templateUrl: './prepare-rule.component.html',
  styleUrls: ['./prepare-rule.component.scss']
})
export class PrepareRuleComponent implements OnInit {
  loading = false;
  ingLoading = false;
  ingLoadingMessage = 'Fetching ING details from PLM';
  activeTab = 'nav-mandatoryFunc';
  RDemailFlag = false;
  enterRnDInputFlag = true;
  previousBtnFlag = false;
  commentsTabNxtBtnFlag = false;
  radioGroupDisableFlag = false;
  recipeData: any;
  rpc;
  ruleAssessResponse: any;
  formulationRequestDetails;
  productPropertiesInputs = [];
  groupRuleInputs = [];
  groupClaimList = [];
  groupSCList = [];
  propertyClaimList = [];
  propertySCList = [];
  sirIdList = [];
  claimsList = [];
  scList = [];
  functionList;
  radioSelected;
  tabFooterBtnFlag = false;
  viewprepareRulePageFlag = false;
  prepareRuleTabPageFlag = true;
  mandFuncSelected: any;
  claimSelected: any;
  scSelected: any;
  additionalUserComments;
  uomval;
  uomList;
  input1;
  input2;
  input3;
  input4;
  prepareRulePageLoadingMessage;
  goToWorklistConfirmation = false;
  ingApiDetails;
  ingList = [];
  ingApiCallFlag = false;
  rmPropertyRules = [];
  ingIDListForPLMApi = [];
  plmUomList = [];
  fastUomList = [];
  uomMisMatch = [];
  plmFastUomMatchFlag = false;
  proceedToExecutrRuleBtnDisableFlag = true;
  linkchkflg: boolean;
  constructor(
    public assessraService: AssessRequestRaService,
    public matSnackBar: MatSnackBar,
    private route: Router,
    private worklistService: WorklistService
  ) { }

  ngOnInit() {
    this.recipeData = recipeModel;
    this.assesmentRuleEngineApi();
    if (this.recipeData.requestStatus === 'In Progress – R&D') {
      this.RDemailFlag = true;
      this.enterRnDInputFlag = false;
      this.radioGroupDisableFlag = true;
      this.commentsTabNxtBtnFlag = true;
      this.proceedToExecutrRuleBtnDisableFlag = true;
    }
    this.getUOMData();
  }
  onTabClick(activeTab) {
    this.activeTab = activeTab;
    this.previousBtnFlag = false;
    if (activeTab === 'nav-ingredientPropValue' && this.ingApiCallFlag === false
      && this.rmPropertyRules.length > 0) {
      this.getINGDetails();
    }
    this.validateExecuteRuleBtn();
  }
  assesmentRuleEngineApi() {
    this.rpc = recipeModel.rpcList;
    this.recipeData.formulationRequestDetails.forEach(x => {
      this.sirIdList.push(x.substanceID);
      if (x.userInputClaim === 'All') {
        x.userInputClaim = null;
      }
      if (x.userInputSpecificCondition === 'All') {
        x.userInputSpecificCondition = null;
      }
    });

    this.recipeData.productPropertiesInputs.forEach(x => {
      if (x.userInputClaim === 'All') {
        x.userInputClaim = null;
      }
      if (x.userInputSpecificCondition === 'All') {
        x.userInputSpecificCondition = null;
      }
    });
    this.prepareRulePageLoadingMessage = 'Checking Rule Input Requirement';
    this.loading = true;
    const apiurl = environment.APIFAsTRule + '/rules/assessmentRules';
    this.assessraService.postData(apiurl, this.recipeData).subscribe((response) => {

      this.loading = false;

      if (response.length !== 0) {
        this.ruleAssessResponse = response;
        this.formulationRequestDetails = this.ruleAssessResponse.formulationRequestDetails;
        this.productPropertiesInputs = this.ruleAssessResponse.productPropertiesInputs;
        this.groupRuleInputs = this.ruleAssessResponse.groupRuleInputs;
        // group Rule Claims Filtering out
        if (this.ruleAssessResponse.groupRuleInputs !== null && this.ruleAssessResponse.groupRuleInputs.length > 0) {
          this.groupClaimList = this.ruleAssessResponse.groupRuleInputs.filter(v => v.claimsListFromRules !== null
            && v.claimsListFromRules.length > 0)
            .map(respElem => {
              return {
                pureCode: respElem.pureCode,
                ingID: respElem.ingID,
                specID: respElem.specID,
                substanceID: respElem.substanceID,
                pureDescription: respElem.pureDescription,
                ingDescription: respElem.ingDescription,
                claimsListFromRules: respElem.claimsListFromRules,
                propertyName: respElem.propertyName,
                propertyClass: respElem.propertyClass,
                propertyId: respElem.propertyId,
                userInputClaim: respElem.userInputClaim,
                multiComponentID: respElem.multiComponentID,
                multiComponentName: respElem.multiComponentName,
                userInputClaimList: respElem.userInputClaimList,
                groupID: respElem.groupID,
                groupName: respElem.groupName

              };
            });
          this.groupClaimList = _.uniqBy(this.groupClaimList, v => [v.groupID, v.groupName].join());
        }

        // property based Rule Claims Filtering out
        if (this.ruleAssessResponse.productPropertiesInputs !== null && this.ruleAssessResponse.productPropertiesInputs.length > 0) {
          this.propertyClaimList = this.ruleAssessResponse.productPropertiesInputs.filter(v => v.claimsListFromRules !== null
            && v.claimsListFromRules.length > 0)
            .map(respElem => {
              return {
                pureCode: respElem.pureCode,
                ingID: respElem.ingID,
                specID: respElem.specID,
                substanceID: respElem.substanceID,
                pureDescription: respElem.pureDescription,
                ingDescription: respElem.ingDescription,
                claimsListFromRules: respElem.claimsListFromRules,
                propertyName: respElem.propertyName,
                propertyClass: respElem.propertyClass,
                propertyId: respElem.propertyId,
                userInputClaim: respElem.userInputClaim,
                multiComponentID: respElem.multiComponentID,
                multiComponentName: respElem.multiComponentName,
                userInputClaimList: respElem.userInputClaimList
              };
            });
          this.propertyClaimList = _.uniqBy(this.propertyClaimList, v => [v.propertyName, v.propertyClass].join());
        }
        if (this.groupClaimList.length > 0) {
          this.propertyClaimList = this.propertyClaimList.concat(this.groupClaimList);
        }

        // substance based Rule Claims Filtering out
        if (this.ruleAssessResponse.formulationRequestDetails !== null && this.ruleAssessResponse.formulationRequestDetails.length > 0) {
          this.claimsList = this.ruleAssessResponse.formulationRequestDetails.filter(v => v.claimsListFromRules !== null
            && v.claimsListFromRules.length > 0)
            .map(respElem => {
              return {
                pureCode: respElem.pureCode,
                ingID: respElem.ingID,
                specID: respElem.specID,
                substanceID: respElem.substanceID,
                pureDescription: respElem.pureDescription,
                ingDescription: respElem.ingDescription,
                claimsListFromRules: respElem.claimsListFromRules,
                propertyName: respElem.propertyName,
                propertyClass: respElem.propertyClass,
                propertyId: respElem.propertyId,
                userInputClaim: respElem.userInputClaim,
                multiComponentID: respElem.multiComponentID,
                multiComponentName: respElem.multiComponentName,
                userInputClaimList: respElem.userInputClaimList
              };

            });
          this.claimsList = _.uniqBy(this.claimsList, v => [v.substanceID, v.pureCode, v.ingID, v.multiComponentName].join());
        }
        if (this.propertyClaimList.length > 0) {
          this.claimsList = this.claimsList.concat(this.propertyClaimList);
        }
        this.claimsList.forEach(claim => {
          if (claim.userInputClaimList !== null && claim.userInputClaimList.length > 0 &&
            claim.claimsListFromRules !== null && claim.claimsListFromRules.length > 0) {
            claim.userInputClaimList.forEach((claimText, index) => {

              if (!claim.claimsListFromRules.includes(claimText)) {
                claim.userInputClaimList.splice(index, 1);
              }
            });
          }
        });

        this.claimSelected = this.claimsList;

        // substance based Rule Mandatory functions Filtering out
        if (this.ruleAssessResponse.formulationRequestDetails !== null && this.ruleAssessResponse.formulationRequestDetails.length > 0) {
          this.functionList = this.ruleAssessResponse.formulationRequestDetails.filter(v => v.functionLstFromRules !== null
            && v.functionLstFromRules.length > 0)
            .map(respElem => {
              return {
                pureCode: respElem.pureCode,
                ingID: respElem.ingID,
                specID: respElem.specID,
                substanceID: respElem.substanceID,
                pureDescription: respElem.pureDescription,
                ingDescription: respElem.ingDescription,
                functionLstFromRules: respElem.functionLstFromRules,
                propertyName: respElem.propertyName,
                propertyClass: respElem.propertyClass,
                propertyId: respElem.propertyId,
                rndInputFunction: respElem.rndInputFunction,
                multiComponentID: respElem.multiComponentID,
                multiComponentName: respElem.multiComponentName,
                substanceFunctionInput: respElem.substanceFunctionInput

              };
            });
          this.functionList.forEach(func => {
            let containsAdditionalFunction = false;
            for (const value of func.functionLstFromRules) {
              if (func.rndInputFunction != null && value.functionName === func.rndInputFunction) {
                containsAdditionalFunction = true;
              }
            }
            if (!containsAdditionalFunction && func.rndInputFunction != null) {
              func.functionLstFromRules.push({ functionId: '0', functionName: func.rndInputFunction, isActive: false });

            }
            let radioSelected = false;
            for (const value of func.functionLstFromRules) {
              if (func.substanceFunctionInput != null && func.substanceFunctionInput.length > 0 && func.substanceFunctionInput === value.functionName) {
                value.isActive = true;
                radioSelected = true;
                break;
              }
            }

            if (!radioSelected) {
              for (const value of func.functionLstFromRules) {
                if (func.rndInputFunction != null && func.rndInputFunction.length > 0 && func.rndInputFunction === value.functionName) {
                  value.isActive = true;
                  radioSelected = true;
                  break;
                }
              }
            }

            if (!radioSelected && func.functionLstFromRules.length > 0) {
              func.functionLstFromRules[0].isActive = true;
            }
          });

          this.functionList = _.uniqBy(this.functionList, f => [f.substanceID, f.pureCode, f.ingID, f.multiComponentName].join());
          this.mandFuncSelected = this.functionList.map(respElem => {
            return {
              pureCode: respElem.pureCode,
              ingID: respElem.ingID,
              specID: respElem.specID,
              substanceID: respElem.substanceID,
              pureDescription: respElem.pureDescription,
              ingDescription: respElem.ingDescription,
              propertyName: respElem.propertyName,
              propertyClass: respElem.propertyClass,
              propertyId: respElem.propertyId,
              substanceFunctionInput: respElem.substanceFunctionInput,
              multiComponentID: respElem.multiComponentID,
              multiComponentName: respElem.multiComponentName,
              functionLstFromRules: (respElem.substanceFunctionInput !== null) ? respElem.substanceFunctionInput : respElem.functionLstFromRules[0]
            };
          });
        }

        // Property based Rule Specific Conditions Filtering out
        if (this.ruleAssessResponse.productPropertiesInputs !== null && this.ruleAssessResponse.productPropertiesInputs.length > 0) {
          this.propertySCList = this.ruleAssessResponse.productPropertiesInputs.filter(v => v.specialConditionListFromRules !== null
            && v.specialConditionListFromRules.length > 0)
            .map(respElem => {
              return {
                pureCode: respElem.pureCode,
                ingID: respElem.ingID,
                specID: respElem.specID,
                substanceID: respElem.substanceID,
                pureDescription: respElem.pureDescription,
                ingDescription: respElem.ingDescription,
                propertyName: respElem.propertyName,
                propertyClass: respElem.propertyClass,
                propertyId: respElem.propertyId,
                specialConditionListFromRules: respElem.specialConditionListFromRules,
                multiComponentID: respElem.multiComponentID,
                multiComponentName: respElem.multiComponentName,
                userInputSpecificCondition: respElem.userInputSpecificCondition,
                userInputSpecificConditionList: respElem.userInputSpecificConditionList

              };
            });
          this.propertySCList = _.uniqBy(this.propertySCList, v => [v.propertyName, v.propertyClass].join());
        }

        // Group  based Rule Specific Conditions Filtering out
        if (this.ruleAssessResponse.groupRuleInputs !== null && this.ruleAssessResponse.groupRuleInputs.length > 0) {
          this.groupSCList = this.ruleAssessResponse.groupRuleInputs.filter(v => v.specialConditionListFromRules !== null
            && v.specialConditionListFromRules.length > 0).map(respElem => {
              return {
                pureCode: respElem.pureCode,
                ingID: respElem.ingID,
                specID: respElem.specID,
                substanceID: respElem.substanceID,
                pureDescription: respElem.pureDescription,
                ingDescription: respElem.ingDescription,
                propertyName: respElem.propertyName,
                propertyClass: respElem.propertyClass,
                propertyId: respElem.propertyId,
                specialConditionListFromRules: respElem.specialConditionListFromRules,
                userInputSpecificCondition: respElem.userInputSpecificCondition,
                multiComponentID: respElem.multiComponentID,
                multiComponentName: respElem.multiComponentName,
                userInputSpecificConditionList: respElem.userInputSpecificConditionList,
                groupID: respElem.groupID,
                groupName: respElem.groupName
              };
            });
          this.groupSCList = _.uniqBy(this.groupSCList, v => [v.groupID, v.groupName].join());
        }
        if (this.groupSCList.length > 0) {
          this.propertySCList = this.propertySCList.concat(this.groupSCList);
        }

        // substance based Rule Specific Conditions Filtering out
        if (this.ruleAssessResponse.formulationRequestDetails !== null && this.ruleAssessResponse.formulationRequestDetails.length > 0) {
          this.scList = this.ruleAssessResponse.formulationRequestDetails.filter(v => v.specialConditionListFromRules !== null
            && v.specialConditionListFromRules.length > 0)
            .map(respElem => {
              return {
                pureCode: respElem.pureCode,
                ingID: respElem.ingID,
                specID: respElem.specID,
                substanceID: respElem.substanceID,
                pureDescription: respElem.pureDescription,
                ingDescription: respElem.ingDescription,
                propertyName: respElem.propertyName,
                propertyClass: respElem.propertyClass,
                propertyId: respElem.propertyId,
                specialConditionListFromRules: respElem.specialConditionListFromRules,
                userInputSpecificCondition: respElem.userInputSpecificCondition,
                multiComponentID: respElem.multiComponentID,
                multiComponentName: respElem.multiComponentName,
                userInputSpecificConditionList: respElem.userInputSpecificConditionList
              };
            });
          this.scList = _.uniqBy(this.scList, f => [f.substanceID, f.pureCode, f.ingID, f.multiComponentName].join());

        }
        if (this.propertySCList.length > 0) {
          this.scList = this.scList.concat(this.propertySCList);
        }
        this.scList.forEach(claim => {
          if (claim.userInputSpecificConditionList !== null && claim.userInputSpecificConditionList.length > 0 &&
            claim.specialConditionListFromRules !== null && claim.specialConditionListFromRules.length > 0) {
            claim.userInputSpecificConditionList.forEach((claimText, index) => {

              if (!claim.specialConditionListFromRules.includes(claimText)) {
                claim.userInputSpecificConditionList.splice(index, 1);
              }
            });
          }
        });


        this.scSelected = this.scList;
        // RM rule will populate ruleAssessResponse.rulesResults Array
        // RMproperty ruleTypeID = 30 (Rule type name changed to Ingredient property)
        if (this.ruleAssessResponse.rulesResults !== null && this.ruleAssessResponse.rulesResults.length > 0) {
          this.rmPropertyRules = this.ruleAssessResponse.rulesResults.filter(q => q.ruleTypeID === 30)
            .map(rmRule => {
              return {
                ruleID: rmRule.ruleID,
                ruleTypeID: rmRule.ruleTypeID,
                ruleTypeDisplayName: rmRule.ruleTypeDisplayName,
                ruleUoM: rmRule.ruleUoM,
                substanceID: rmRule.substanceID,
                substanceName: rmRule.substanceName,
                pureCode: rmRule.pureCode,
                pureDescription: rmRule.pureDescription,
                recipeQuantity: rmRule.recipeQuantity,
                recipeUoM: rmRule.recipeUoM,
                ruleRPC: rmRule.ruleRPC,
                maxLimit: rmRule.maxLimit,
                minLimit: rmRule.minLimit,
                ingID: rmRule.ingID,
                ingDescription: rmRule.ingDescription,
                ingQuantity: rmRule.ingQuantity,
                ingPropertyType: rmRule.ingPropertyType,
                ingPropertyName: rmRule.ingPropertyName,
                amountOfLemonOrLimeJCInFormula: rmRule.amountOfLemonOrLimeJCInFormula,
                amountofVinegarInFormula: rmRule.amountofVinegarInFormula,
                acidityAsAceticAcid: rmRule.acidityAsAceticAcid,
                brixOfLemon: rmRule.brixOfLemon,
                multiComponentID: rmRule.multiComponentID,
                multiComponentName: rmRule.multiComponentName
              };
            });
          this.rmPropertyRules = _.uniqBy(this.rmPropertyRules, v => [v.substanceID, v.pureCode, v.ingID, v.multiComponentName].join());

        }

        // RM rule details find out from formulationRequestDetails by substance ID
        for (const value of this.ruleAssessResponse.formulationRequestDetails) {
          this.rmPropertyRules.forEach(rmPropRule => {
            if (rmPropRule.substanceID === value.substanceID) {
              rmPropRule.ingID = value.ingID;
              rmPropRule.ingDescription = value.ingDescription;
              rmPropRule.ingQuantity = value.ingQuantity;
              rmPropRule.pureCode = value.pureCode;
              rmPropRule.pureDescription = value.pureDescription;
              rmPropRule.substanceName = value.substanceName;
              rmPropRule.recipeQuantity = value.recipeQuantity;
              rmPropRule.recipeUoM = value.recipeUoM;
              rmPropRule.multiComponentID = value.multiComponentID;
              rmPropRule.multiComponentName = value.multiComponentName;
            }
          });
        }

        this.validateExecuteRuleBtn();
        this.tabFooterBtnFlag = true;
      }
    },
      (error) => {
        this.loading = false;
      });


  }

  funcSelectRadioBtn(event, formulationData, mandatoryFunc, index) {
    this.mandFuncSelected.forEach(x => {
      if (x.pureCode === formulationData.pureCode && x.substanceID === formulationData.substanceID
        && x.ingID === formulationData.ingID) {
        x.functionLstFromRules = mandatoryFunc;
      }
      this.validateExecuteRuleBtn();
    });


  }
  claimSelectRadioBtn() {
    this.validateExecuteRuleBtn();


  }
  SCSelectRadioBtn() {
    this.validateExecuteRuleBtn();

  }
  updateUserSelectedValuesToGlobalVariable() {

    if (this.ruleAssessResponse.formulationRequestDetails !== null) {

      for (const value of this.ruleAssessResponse.formulationRequestDetails) {

        // Updating mandatory function selected
        this.mandFuncSelected.forEach(p => {
          if (p.pureCode === value.pureCode
            && p.substanceID === value.substanceID
            && p.ingID === value.ingID) {
            value.substanceFunctionInput = p.functionLstFromRules === p.substanceFunctionInput ? p.functionLstFromRules : p.functionLstFromRules.functionName;
          }
        });
        // Updating claim selected by user
        this.claimsList.forEach(p => {
          if (p.pureCode === value.pureCode
            && p.substanceID === value.substanceID
            && p.ingID === value.ingID) {
            value.userInputClaimList = p.userInputClaimList;
          }
        });
        // Updating Specific conditions selected by user
        this.scList.forEach(r => {
          if (r.pureCode === value.pureCode
            && r.substanceID === value.substanceID
            && r.ingID === value.ingID) {
            value.userInputSpecificConditionList = r.userInputSpecificConditionList;
          }
        });

        // update RM rule deatils
        this.ingList.forEach(rm => {
          if (rm.pureCode === value.pureCode
            && rm.substanceID === value.substanceID
            && rm.ingID === value.ingID) {
            if (rm.propertyInputsListFromPlm.propertyName === 'Brix Value'
              && rm.propertyInputsListFromPlm.propertyClass === 'Chemical-Physical Analysis') {

              value.brixOfLemon = rm.propertyInputsListFromPlm.propertyTargetQuantity;
              value.brixOfLemonUoM = typeof (rm.propertyInputsListFromPlm.propertyUoM) === 'object' ? rm.propertyInputsListFromPlm.propertyUoM.uoMsymbol : rm.propertyInputsListFromPlm.propertyUoM;

            } else if (rm.propertyInputsListFromPlm.propertyName === 'Acidity as Acetic Acid'
              && rm.propertyInputsListFromPlm.propertyClass === 'Chemical-Physical Analysis') {

              value.acidityAsAceticAcid = rm.propertyInputsListFromPlm.propertyTargetQuantity;
              value.aceticAcidUoM = typeof (rm.propertyInputsListFromPlm.propertyUoM) === 'object' ? rm.propertyInputsListFromPlm.propertyUoM.uoMsymbol : rm.propertyInputsListFromPlm.propertyUoM;
            }
          }
        });
      }
    }

    if (this.ruleAssessResponse.groupRuleInputs !== null) {
      for (const value of this.ruleAssessResponse.groupRuleInputs) {

        // Updating claim selected by user for Group rules
        this.claimsList.forEach(cl => {
          if (cl.groupID === value.groupID
            && cl.groupName === value.groupName) {
            value.userInputClaimList = cl.userInputClaimList;
          }

        });
        // Updating Specific conditions selected by user for Group rules
        this.scList.forEach(sc => {
          if (sc.groupID === value.groupID
            && sc.groupName === value.groupName) {
            value.userInputSpecificConditionList = sc.userInputSpecificConditionList;
          }

        });
      }
    }
    if (this.ruleAssessResponse.productPropertiesInputs !== null) {
      for (const value of this.ruleAssessResponse.productPropertiesInputs) {

        // Updating claim selected by user for property rules
        this.claimsList.forEach(p => {
          if (p.propertyId === value.propertyId
            && p.propertyClass === value.propertyClass
            && p.propertyName === value.propertyName) {
            value.userInputClaimList = p.userInputClaimList;
          }

        });
        // Updating Specific conditions selected by user for property rules
        this.scList.forEach(r => {
          if (r.propertyId === value.propertyId
            && r.propertyClass === value.propertyClass
            && r.propertyName === value.propertyName) {
            value.userInputSpecificConditionList = r.userInputSpecificConditionList;
          }

        });
      }
    }
    this.ruleAssessResponse.raComments = this.additionalUserComments;
    this.ruleAssessResponse.ifraComplianceModelList = recipeModel.ifraComplianceModelList;
    this.ruleAssessResponse.regionalComplianceLevel2ModelList = recipeModel.regionalComplianceLevel2ModelList;
    this.ruleAssessResponse.regionalComplianceLevel3ModelList = recipeModel.regionalComplianceLevel3ModelList;
    Object.assign(recipeModel, this.ruleAssessResponse);
  }
  onProceedToExecuteRuleBtnClick() {
    this.updateUserSelectedValuesToGlobalVariable();
    this.assessraService.COMMENTS = this.additionalUserComments;
    this.prepareRulePageLoadingMessage = 'Updating Request';
    this.loading = true;
    const apiurl = environment.APIFAsT + '/assessmentrequestmarket';
    this.assessraService.putData(apiurl, recipeModel).subscribe((response) => {
      this.loading = false;
      this.viewprepareRulePageFlag = true;
      this.prepareRuleTabPageFlag = false;
      this.matSnackBar.open('Request updated successfully ', 'close', {
        duration: 3000,
        panelClass: ['success']
      });

    }, (error) => {
      this.loading = false;
      this.assesmentRuleEngineApi();
      this.matSnackBar.open('Request Updation Failed', 'close', {
        duration: 3000,
        panelClass: ['error']
      });
    });


  }
  funcTabNxtBtn() {
    this.activeTab = 'nav-claims';
  }
  onGetRnDEmailTriggerBtnClicked() {
    const requestStatus = 'In Progress – R&D';
    const statusUpdateApiUrl = environment.APIFAsT + '/assessment/request/' + recipeModel.requestDisplayID + '/status/' + requestStatus + '/comment/SystemGenerated';
    this.loading = true;
    this.prepareRulePageLoadingMessage = 'Updating Request';
    this.assessraService.putData(statusUpdateApiUrl, null).subscribe((statusresponse) => {
      if (statusresponse.message !== null) {
        const val: any = {
          requestID: recipeModel.requestDisplayID,
          requestStatus: requestStatus
        };
        this.worklistService.setRecipeData(val);
        this.loading = false;
        this.RDemailFlag = true;
        this.enterRnDInputFlag = false;
        this.radioGroupDisableFlag = true;
        this.commentsTabNxtBtnFlag = true;
        this.proceedToExecutrRuleBtnDisableFlag = true;
        recipeModel.requestStatus = requestStatus;
        this.matSnackBar.open(statusresponse.message, 'close', {
          duration: 3200,
          panelClass: ['success']
        });
        this.sendResponseEmail('ADDINPUTS', recipeModel.requestDisplayID, localStorage.getItem('submittedByUserId'), this.ruleAssessResponse.formulationRequestDetails, this.additionalUserComments, this.productPropertiesInputs, recipeModel.cucCode);
      }
    },
      (error) => {
        this.loading = false;
      });
  }
  goToWorklistBtnClick() {
    this.route.navigate(['/worklist/master-worklist']);
  }
  onEnterRnDIpnputClicked() {
    const requestStatus = 'In Progress – RA';
    this.prepareRulePageLoadingMessage = 'Updating Request';
    const statusUpdateApiUrl = environment.APIFAsT + '/assessment/request/' + recipeModel.requestDisplayID + '/status/' + requestStatus + '/comment/SystemGenerated';
    this.loading = true;
    this.assessraService.putData(statusUpdateApiUrl, null).subscribe((statusresponse) => {
      if (statusresponse.message !== null) {
        const val: any = {
          requestID: recipeModel.requestDisplayID,
          requestStatus: requestStatus
        };
        this.worklistService.setRecipeData(val);
        this.RDemailFlag = false;
        this.loading = false;
        this.enterRnDInputFlag = true;
        this.radioGroupDisableFlag = false;
        this.commentsTabNxtBtnFlag = false;
        this.proceedToExecutrRuleBtnDisableFlag = false;
        recipeModel.requestStatus = requestStatus;
        this.validateExecuteRuleBtn();
        this.matSnackBar.open(statusresponse.message, 'close', {
          duration: 3200,
          panelClass: ['success']
        });
      }
    }, (error) => {
      this.loading = false;
    });
  }
  // CG - Send response email to respective RA/R&D users.
  sendResponseEmail(notificationGroup, displayRequestId, toemailids, formulationrequestdetails, comments, productproperties, cuccode) {
    const data = {
      NotificationGroup: notificationGroup,
      Subject: null,
      EmailBody: null,
      ToEmailList: toemailids,
      FromEmail: null,
      CCEmailList: null,
      EmailType: 'html',
      UPD: null,
      PureCodes: null,
      RequestID: displayRequestId
      , Comments: comments
      , FormulationRequestDetails: formulationrequestdetails
      , ProductPropertiesInputs: productproperties
      , CUCCode: cuccode
      , CUCDescription:  recipeModel.cucDescription
    };
    const apiurl = environment.APIFAsT + '/additionalinputemail';
    this.assessraService.postData(apiurl, data).subscribe((response) => {
      this.loading = false;
      if (response.result === 'Success') {
        this.loading = false;
      }
    }, (error) => {
      this.loading = false;
    });
  }
  saveAndExitBtn() {
    this.updateUserSelectedValuesToGlobalVariable();
    this.prepareRulePageLoadingMessage = 'Updating Request';
    this.loading = true;
    const apiurl = environment.APIFAsT + '/assessmentrequestmarket';
    this.assessraService.putData(apiurl, recipeModel).subscribe((response) => {
      this.loading = false;
      this.goToWorklistBtnClick();
      this.matSnackBar.open('Request updated successfully ', 'close', {
        duration: 3000,
        panelClass: ['success']
      });

    }, (error) => {
      this.loading = false;
    });

  }
  getINGDetails() {
    this.rmPropertyRules.forEach(rm => {
      if (typeof rm.ingID !== 'undefined' && rm.ingID !== null) {
        this.ingIDListForPLMApi.push(rm.ingID);
      }

    });
    if (this.ingIDListForPLMApi.length > 0) {
      this.ingIDListForPLMApi = _.uniqBy(this.ingIDListForPLMApi);
      const ingCodes = this.ingIDListForPLMApi.join(','); // +',100000110142';
      this.prepareRulePageLoadingMessage = 'Fetching data from PLM';
      this.loading = true;
      const apiurl = environment.APIFAsT + '/formulation/ingProperties/' + ingCodes;
      this.assessraService.getData(apiurl).subscribe((response) => {
        if (response !== null) {
          this.ingApiDetails = response;

          this.ingList = this.ingApiDetails.map(ing => {
            return {
              propertyInputsListFromPlm: ing.propertyInputsListFromPlm,
              ingID: ing.ingID
            };
          });
          for (const value of this.rmPropertyRules) {
            this.ingList.forEach(h => {
              if (h.ingID === value.ingID) {
                h.ruleID = value.ruleID;
                h.ruleTypeID = value.ruleTypeID;
                h.ruleTypeDisplayName = value.ruleTypeDisplayName;
                h.substanceID = value.substanceID;
                h.substanceName = value.substanceName;
                h.pureCode = value.pureCode;
                h.pureDescription = value.pureDescription;
                h.recipeQuantity = value.recipeQuantity;
                h.recipeUoM = value.recipeUoM;
                h.ruleRPC = value.ruleRPC;
                h.maxLimit = value.maxLimit;
                h.minLimit = value.minLimit;
                h.ruleUoM = value.ruleUoM;
                h.ingID = value.ingID;
                h.ingDescription = value.ingDescription;
                h.ingQuantity = value.ingQuantity;
                h.ingPropertyType = value.ingPropertyType;
                h.ingPropertyName = value.ingPropertyName;
                h.amountOfLemonOrLimeJCInFormula = value.amountOfLemonOrLimeJCInFormula;
                h.amountofVinegarInFormula = value.amountofVinegarInFormula;
                h.acidityAsAceticAcid = value.acidityAsAceticAcid;
                h.brixOfLemon = value.brixOfLemon;
                h.multiComponentID = value.multiComponentID;
                h.multiComponentName = value.multiComponentName;

              }
            });
          }
          this.loading = false;
          this.assessraService.INGREDIENT_PROPERTY_VALUES = this.ingList;
        } else {
          this.matSnackBar.open('ING ID - ' + ingCodes + ' User input not required  ', 'close', {
            duration: 4000,
            panelClass: ['success']
          });
          this.loading = false;
          this.ingApiDetails = [];
        }
        this.ingApiCallFlag = true;
      }, (error) => {
        this.loading = false;

        this.ingList = this.rmPropertyRules.map(ing => {
          return {
            propertyInputsListFromPlm: {
              propertyId: null,
              parentPropertyId: null,
              propertyClass: ing.ingPropertyType,
              propertyName: ing.ingPropertyName,
              propertyRecipeQuantity: '',
              propertyTargetQuantity: '',
              propertyMaxQuantity: '',
              propertyMinQuantity: '',
              propertyUoM: '',
              rpcList: null,
              propertyValueType: '',
              claimsListFromRules: null,
              specialConditionListFromRules: null,
              userInputClaim: null,
              userInputSpecificCondition: null,
              userInputClaimList: null,
              userInputSpecificConditionList: null,
              propertyRuleStatus: null,
              ingRuleType: null,
              propertySource: null,
              ingPropertyName: null
            },
            ingID: ing.ingID,
            ruleTypeID: ing.ruleTypeID,
            ruleTypeDisplayName: ing.ruleTypeDisplayName,
            substanceID: ing.substanceID,
            substanceName: ing.substanceName,
            pureCode: ing.pureCode,
            pureDescription: ing.pureDescription,
            recipeQuantity: ing.recipeQuantity,
            recipeUoM: ing.recipeUoM,
            ruleRPC: ing.ruleRPC,
            maxLimit: ing.maxLimit,
            minLimit: ing.minLimit,
            ruleUoM: ing.ruleUoM,
            ingDescription: ing.ingDescription,
            ingQuantity: ing.ingQuantity,
            ingPropertyType: ing.ingPropertyType,
            ingPropertyName: ing.ingPropertyName,
            amountOfLemonOrLimeJCInFormula: ing.amountOfLemonOrLimeJCInFormula,
            amountofVinegarInFormula: ing.amountofVinegarInFormula,
            acidityAsAceticAcid: ing.acidityAsAceticAcid,
            brixOfLemon: ing.brixOfLemon,
            multiComponentID: ing.multiComponentID,
            multiComponentName: ing.multiComponentName
          };
        });
        this.assessraService.INGREDIENT_PROPERTY_VALUES = this.ingList;
      });
    } else {
      this.ingApiCallFlag = true;
    }

  }
  getUOMData() {
    const apiurl = environment.APIFAsT + '/uoms';
    this.assessraService.getData(apiurl).subscribe((response1) => {
      this.uomList = response1;
      this.uomList.forEach(element => {
        this.fastUomList.push(element.uoMsymbol);
      });
    },
      (error) => {
        this.loading = false;
      });
  }
  validateExecuteRuleBtn() {
    if (this.recipeData.requestStatus !== 'In Progress – R&D') {
      this.proceedToExecutrRuleBtnDisableFlag = false;
    }
    if (this.mandFuncSelected.length > 0 && this.mandFuncSelected !== null) {
      this.mandFuncSelected.forEach(mandFunc => {
        if (mandFunc.functionLstFromRules === null) {
          this.proceedToExecutrRuleBtnDisableFlag = true;
        }
      });
    }
    if (this.claimsList !== null && this.claimsList.length > 0) {
      this.claimsList.forEach(claims => {
        if (claims.userInputClaimList === null || claims.userInputClaimList.length === 0) {
          this.proceedToExecutrRuleBtnDisableFlag = true;
        }
      });
    }
    if (this.scList !== null && this.scList.length > 0) {
      this.scList.forEach(sc => {
        if (sc.userInputSpecificConditionList === null || sc.userInputSpecificConditionList.length === 0) {
          this.proceedToExecutrRuleBtnDisableFlag = true;
        }
      });
    }
    if ((this.rmPropertyRules !== null && this.rmPropertyRules.length > 0) ||
      (this.ingList !== null && this.ingList.length > 0)) {
      if (this.rmPropertyRules.length > 0 && this.ingList.length === 0 && this.ingApiCallFlag === false) {

        this.rmPropertyRules.forEach(rmRule => {
          if (rmRule.ingPropertyName === 'Acidity as Acetic Acid' || rmRule.ingPropertyName === 'Brix Value') {
            this.proceedToExecutrRuleBtnDisableFlag = true;
          }
        });

      } else {
        this.ingList.forEach(ingRule => {
          if (ingRule.propertyInputsListFromPlm.propertyTargetQuantity === null ||
            ingRule.propertyInputsListFromPlm.propertyTargetQuantity === '' ||
            isNaN(Number(ingRule.propertyInputsListFromPlm.propertyTargetQuantity))) {
            this.proceedToExecutrRuleBtnDisableFlag = true;
          }


        });
      }
    }
  }
  checkINGInput() {
    this.validateExecuteRuleBtn();
  }
}
