import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { groupDataModel } from 'src/app/common/models/GroupDataModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { REGULATORY_GROUPTABLE_COLUMNS, RELATED_RULE_TABLE_COLUMNS, SUBSTANCE_COLUMNS } from 'src/app/common/models/tableColumns';
import { DataService } from 'src/app/common/services/data.service';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-regulatory-group-master',
  templateUrl: './regulatory-group-master.component.html',
  styleUrls: ['./regulatory-group-master.component.scss']
})
export class RegulatoryGroupMasterComponent implements OnInit {
  overlayText = '';
  hideOverlay = true;
  star = 'fa fa-fw fa-search';
  loadingRegulatoryGroupMasterListMessage;
  createGroupTab;
  isEditbtnDisable = false;
  substanceData;
  checkedFlag;
  exportDialog = false;
  notifyGPCDialog = false;
  sentPureCodes;
  filteredRows: any = [];
  filteredDataDivision = [];
  filteredDataGroup = [];
  divisionData = [];
  groupsData = [];
  showRelatedRulesPopupForDelete = false;
  checkedPureCodes = [];
  selectedSirDetailsEdit = [];
  editNotifyGPC = false;
  notifyGPCDialogEdit = false;
  isButtonVisible = false;
  isSubstanceSelected;
  groupToEditData;
  isAllSubstanceChecked;
  groupToCreateData;
  sirIdsforSubstanceDetails: string[] = [];
  checkSelectedVal = '';
  disableLoader = false;
  deleteGroupResponseSuccess = false;
  division = '';
  group = '';
  isGroupSelected: boolean;
  deleteGroupConfPopup = false;
  groupColumns = REGULATORY_GROUPTABLE_COLUMNS;
  groupData = [];
  selectedRowData: any = [];
  editGroupNamePopup = false;
  substanceGroupData: {
    groupId: number;
    groupName;
    groupType;
    substanceData: any;
  };
  groupForm: FormGroup;
  isEditGroup = false;
  isCreateGroup = false;
  isUpdateGroupDetails = false;
  createdGroup = [];
  switchedPureCodes = [];
  switchedSirDetails = [];
  switchedSirDetailsEdit = [];
  switchedPureCodesEdit = [];
  selectedSirDetails = [];
  selectedDivision = '';
  selectedGroup = '';
  substanceColumns;
  addSubstanceDialogue = false;
  searchIdentifierValue;
  searchIdentifier;
  subtanceIdentifier = ['Pure Code', 'INCI Name', 'CAS #', 'SIR ID', 'Substance Name'];
  searchResultsGroup = [];
  disablePureCode = false;
  radioRowIndex = null;
  loading = false;
  loadingMessage = 'Getting Regulatory Group Master Details';
  searchedSubstanceData = [];
  searchedSubstanceDataTemp = [];
  selectedRows = [];
  showRelatedRulesPopup = false;
  prepareRuleTableData = [];
  prepareRuleColumns = [];
  myWorklistTableColumns = [];
  workListData = [];
  selectedRowWorklist = null;
  selectedRowsEmail = [];
  showRelatedRequests = false;
  enableDelete = false;
  viewRuleBtnDisableFlag = true;
  addItemBtnDisableFlag = true;
  deleteRulePopup = false;
  deleteRuleComment = '';
  deleteGroupPopup = false;
  editGroupPopup = false;
  deleteGroupComment = '';
  showEmailConfirmationDialog = false;
  emailComments = '';
  groupMasterData;
  removeBtn = false;
  userDetails;
  isSuperAdminOrGlobalRA = false;
  isUserAuthToEditGrpName = true;
  updId = '';
  deleteGroupResponse: any;
  isGroupName = true;
  popupOverlayText: any;
  popupHideOverlay = true;
  warningTextOpen: boolean;
  tablewarningComment: any;
  commentRD = '';
  orrTextOpen: boolean;
  tableORRComment: any;
  constructor(
    private assessRequestRa: AssessRequestRaService,
    private dataServiceObj: DataService,
    public matSnackBar: MatSnackBar,
    public formBuilder: FormBuilder,
    private worklistService: WorklistService,
  ) { }

  ngOnInit() {
    this.createGroupTab = false;
    this.createGroupFormStructure();
    this.groupColumns = REGULATORY_GROUPTABLE_COLUMNS;
    this.substanceColumns = SUBSTANCE_COLUMNS;
    this.isSubstanceSelected = false;
    this.getDivisonData();
    this.prepareRuleColumns = RELATED_RULE_TABLE_COLUMNS;
    this.getWorklistColumns();
    this.getUserBasedDivision();
    if (this.isSuperAdminOrGlobalRA) {
      this.isButtonVisible = true;
    } else {
      this.isButtonVisible = false;
    }
  }
  /* istanbul ignore next */
  getUserBasedDivision() {
    this.userDetails = JSON.parse(localStorage.getItem('userRolesPermissions'));
    this.userDetails.roles.forEach(c => {
      if (c.roleId === 150) {
        this.isSuperAdminOrGlobalRA = true;
        this.isUserAuthToEditGrpName = true;
      } else if ((c.roleId === 140 && c.tfc.tfcId === this.updId)) {
        this.isSuperAdminOrGlobalRA = true;
        this.isUserAuthToEditGrpName = true;
      } else {
        this.isSuperAdminOrGlobalRA = false;
        this.isUserAuthToEditGrpName = false;
      }
    });
  }
  createGroup() {
    this.selectedRows = [];
    this.isCreateGroup = true;
    this.createGroupTab = true;
    this.addSubstanceDialogue = false;
    this.isEditGroup = false;
    this.createdGroup = [];
    this.isEditbtnDisable = false;
    const ele = document.getElementById('echeck') as HTMLInputElement;
    if (ele) {
      ele.checked = false;
    }
  }
  addSubstanceMethod() {
    this.searchIdentifier = null;
    this.searchIdentifierValue = null;
    this.searchResultsGroup = [];
    this.disablePureCode = false;
    this.selectedRowData = [];
    this.searchedSubstanceData = [];
    this.searchedSubstanceDataTemp = [];
    this.switchedPureCodes = [];
    this.addSubstanceDialogue = true;
    this.isAllSubstanceChecked = false;
    this.isSubstanceSelected = false;
    this.switchedSirDetails = [];
    this.switchedPureCodes = [];
  }

  sendMailtoGPC(dataDetails, pureCodesDetails) {
    let emailData = '';
    this.sentPureCodes = pureCodesDetails.toString();
    dataDetails.forEach(element => {
      emailData = emailData + '\r\nPure Code:' + ' ' + element.purecode + '\r\n[SIR ID :' + ' ' + element.sirId + ']' +
        '\r\nBackbone Identity :' + ' ' + element.selectedIssueCategory.map((s) => s.value).toString() + '\r\n' + 'Comments : ' + ' ' + element.commentsToUser + '\r\n'
        + 'Pure Description : ' + ' ' + element.pureDescription + '\r\n';
    });
    const data = {
      NotificationGroup: 'GPC',
      Subject: null,
      EmailBody: emailData,
      ToEmailList: null,
      FromEmail: null,
      CCEmailList: null,
      EmailType: 'html',
      UPD: recipeModel.division,
      PureCodes: this.sentPureCodes,
      RequestID: 0
    };
    const apiurl = environment.APIFAsT + '/email';
    this.assessRequestRa.postData(apiurl, data).subscribe((response) => {
      if (response.message !== null) {
        this.matSnackBar.open('Email message sent to GPC team successfully', 'close', {
          duration: 5000,
          panelClass: ['success']
        });
      }
    }, (error) => {
      /* tslint:disable:no-empty */
    });
  }
  /* istanbul ignore next */
  notifySIRClicked(event) {
    this.sentPureCodes = this.checkedPureCodes.toString();
    if (this.checkedPureCodes.length !== 0) {
      const data = {
        NotificationGroup: 'SIR',
        Subject: null,
        EmailBody: null,
        ToEmailList: null,
        FromEmail: null,
        CCEmailList: null,
        EmailType: 'PlainText',
        UPD: recipeModel.division,
        PureCodes: this.sentPureCodes,
        RequestID: recipeModel.requestDisplayID
      };
      const apiurl = environment.APIFAsT + '/email';
      this.assessRequestRa.postData(apiurl, data).subscribe((response) => {
        if (response.message !== null) {
          this.matSnackBar.open('Email message sent to SIR team successfully', 'close', {
            duration: 3000,
            panelClass: ['success']
          });
        }
      }, (error) => {
        /* tslint:disable:no-empty */
      });
    } else {
      this.matSnackBar.open('Select the Pure Codes', 'close', {
        duration: 3900,
        panelClass: ['error']
      });
    }
  }
  /* istanbul ignore next */
  showGroup() {
    this.loadingMessage = 'Getting Regulatory Group Master Details';
    this.groupForm.controls.GroupName.setValue('');
    this.addSubstanceDialogue = false;
    this.createGroupTab = false;
    this.groupData = [];
    this.getGroups();
    this.searchIdentifier = null;
    this.searchIdentifierValue = null;
    this.searchResultsGroup = [];
    this.disablePureCode = false;
    this.isSuperAdminOrGlobalRA = false;
    this.radioRowIndex = null;
    this.selectedRowData = [];
    this.createdGroup = [];
    this.isUpdateGroupDetails = false;
    this.groupForm.value.selectedDivision = '';
    this.switchedPureCodesEdit = [];
  }
  /* istanbul ignore next */
  addSubstance() {
  }

  getDivisonData() {
    const apiurl = environment.APIFAsT + '/upds';
    this.assessRequestRa.getData(apiurl).subscribe((response1) => {
      this.loading = false;
      this.divisionData = response1;
    }, (error) => {
      this.loading = false;
    });
  }

  searchDivision(event) {
    const filteredSearchDivision = [];
    const query = event.query;
    for (const value of this.divisionData) {
      if (value.updName.toLowerCase().includes(query.toLowerCase())) {
        filteredSearchDivision.push(value);
      }
    }
    this.filteredDataDivision = filteredSearchDivision;
    if (this.division.toLowerCase() !== query.toLowerCase()) {
      this.viewRuleBtnDisableFlag = true;
    }
  }

  oncloseDialog() {
    this.switchedSirDetails = [];
    this.switchedSirDetailsEdit = [];
  }
  getGroupData() {
    const apiurl = environment.APIFAsTRule + '/rules/groups/upd/' + this.division;
    this.assessRequestRa.getData(apiurl).subscribe((response1) => {
      this.loading = false;
      this.groupsData = response1;
    }, (error) => {
      this.loading = false;
    });
  }

  searchGroup(event) {
    const filteredSearchGroup = [];
    const query = event.query;
    for (const value of this.groupsData) {
      if (value.groupName.toLowerCase().includes(query.toLowerCase())) {
        filteredSearchGroup.push(value);
      }
    }
    this.filteredDataGroup = filteredSearchGroup;
    if (this.group.toLowerCase() !== query.toLowerCase()) {
      this.viewRuleBtnDisableFlag = true;
    } else {
      this.viewRuleBtnDisableFlag = false;
    }
  }

  divisionSelected(val): void {
    this.division = val.updName;
    if (this.division !== '') {
      this.isGroupName = false;
      this.viewRuleBtnDisableFlag = false;
      this.getGroupData();
      this.group = '';
      this.selectedGroup = '';
      this.groupForm.value.UPDFullText = val.updName;
      recipeModel.division = val.updName;
    } else {
      this.viewRuleBtnDisableFlag = true;
    }
  }
  groupSelected(val) {
    this.group = val.groupName;
    val.groupNam = '';
    if (this.group !== '') {
      this.viewRuleBtnDisableFlag = false;
    } else {
      this.viewRuleBtnDisableFlag = true;
    }
    if (this.division === '') { this.viewRuleBtnDisableFlag = true; }
  }
  viewResult(dtGroupTable) {
    dtGroupTable.reset();
    this.showGroup();

  }
  clearDivision() {
    this.isGroupName = true;
    this.selectedGroup = '';
    this.groupMasterData = [];
    this.division = '';
    this.group = '';
    this.viewRuleBtnDisableFlag = true;
    this.isGroupSelected = false;
  }
  clearGroups() {
    this.group = '';
    this.viewRuleBtnDisableFlag = false;
    if (this.division === '') { this.viewRuleBtnDisableFlag = true; }
  }
  // get groups data for selected division
  /* istanbul ignore next */
  getGroups() {
    const groupData = [];
    this.loading = true;
    this.isGroupSelected = false;
    this.radioRowIndex = null;
    if (this.division != null) {
      const apiurl = environment.APIFAsTRule + '/rules/substancegroups/upd/' + this.division;
      this.assessRequestRa.getData(apiurl).subscribe((response) => {
        this.loading = true;
        if (response.length > 0) {
          this.updId = response[0].updid.toString();
          this.disableLoader = false;
          if (this.group !== '' && this.group !== 'All') {
            response = response.filter(items => items.groupName.toLowerCase() === this.group.toLowerCase());
          }
          this.loading = true;
          response.forEach(group => {
            group.substanceIds.forEach(sub => {
              const temp = [];
              group.substances.forEach(element => {
                if (element.sirId === sub) {
                  element.cfDetails.forEach(cf => {
                    temp.push(cf.cfDisplayText);
                  });
                  this.groupData.push({
                    groupId: group.groupId,
                    groupName: group.groupName,
                    groupType: group.groupType,
                    substanceId: sub,
                    pureCode: element.pureCode,
                    substanceName: element.substanceName,
                    cas: element.cas,
                    parentCAS: element.parentCAS,
                    cfData: temp.toString(),
                    cfDetails: element.cfDetails,
                    ins: element.ins,
                    inciName: element.inciName,
                    einecs: element.einecs,
                    createdDate: group.createdDate,
                    createdByUserIdDisplayName: group.createdByUserIdDisplayName,
                    modifiedDate: group.modifiedDate,
                    modifiedByUserIdDisplayName: group.modifiedByUserIdDisplayName
                  });
                  this.groupMasterData = this.groupData;
                }
              });
            });
          });
          this.loading = false;
        } else {
          this.loading = false;
          this.disableLoader = true;
        }
      }, (error) => {
        this.loading = false;
        if (error.status === 200) {
          this.matSnackBar.open('Group data recieved', 'close', {
            duration: 3000,
            panelClass: ['success']
          });
        } else {
          this.matSnackBar.open('Request Failed', 'close', {
            duration: 3000,
            panelClass: ['error']
          });
          return false;
        }
      });

    }
  }
  // To create group
  /* istanbul ignore next */
  saveGroupDetails() {
    let apiurl;
    let data;
    const selectedSubstances = this.searchedSubstanceData.filter(x => x.isSubstanceChecked && !x.sirId.includes('SIR ID Not found'));
    if (selectedSubstances.length === 0) {
      return;
    }
    this.loading = true;
    // if - Create group, Else - Edit group
    if (!this.isEditGroup && this.createdGroup.length === 0) {
      this.loadingMessage = 'Saving Group';
      apiurl = environment.APIFAsTRule + '/rules/substancegroup';
      this.groupToCreateData = this.groupForm.value;
      this.groupToCreateData.SubstanceIds = [];
      selectedSubstances.forEach(element => {
        if (!element.sirId.includes('SIR ID Not found')) {
          this.groupToCreateData.SubstanceIds.push(element.sirId);
        }
      });
      data = this.groupToCreateData;
      this.assessRequestRa.postData(apiurl, data).subscribe((response) => {
        this.matSnackBar.open('Group created successfully', 'close', {
          duration: 3000,
          panelClass: ['success']
        });
        this.isEditGroup = true;
        this.oncloseDialog();
        this.substanceGroupData = {
          groupId: response.groupId,
          groupName: response.groupName,
          groupType: response.groupType,
          substanceData: selectedSubstances
        };
        this.createdGroup = selectedSubstances;
        this.loading = false;
        this.loadingMessage = 'Loading...';
        this.substanceData = this.searchedSubstanceData;
        this.addSubstanceDialogue = false;
        this.searchedSubstanceData = [];
        this.searchedSubstanceDataTemp = [];
        this.searchIdentifierValue = '';
        this.searchResultsGroup = [];
        this.disablePureCode = false;
        this.searchIdentifier = '';
      }, (error) => {
        this.loading = false;
        this.loadingMessage = 'Loading...';
        this.oncloseDialog();
        this.substanceData = this.searchedSubstanceData;
        this.addSubstanceDialogue = false;
        this.searchedSubstanceData = [];
        this.searchedSubstanceDataTemp = [];
        this.searchIdentifierValue = '';
        this.searchResultsGroup = [];
        this.disablePureCode = false;
        this.searchIdentifier = '';
        return false;
      });
    } else {
      this.loadingMessage = 'Updating Group';
      apiurl = environment.APIFAsTRule + '/rules/editsubstancegroup';
      this.groupToEditData = Object.assign({}, this.substanceGroupData);
      this.groupToEditData.SubstanceIds = [];
      selectedSubstances.forEach(element => {
        if (!element.sirId.includes('SIR ID Not found')) {
          this.groupToEditData.SubstanceIds.push(element.sirId);
        }
      });
      data = this.groupToEditData;
      this.assessRequestRa.putData(apiurl, data).subscribe((response) => {
        this.matSnackBar.open('Group updated successfully', 'close', {
          duration: 3000,
          panelClass: ['success']
        });
        this.createdGroup.push.apply(this.createdGroup, selectedSubstances);
        this.loading = false;
        this.loadingMessage = 'Loading...';
        this.oncloseDialog();
        this.substanceData = this.searchedSubstanceData;
        this.addSubstanceDialogue = false;
        this.searchedSubstanceData = [];
        this.searchedSubstanceDataTemp = [];
        this.searchIdentifierValue = '';
        this.searchResultsGroup = [];
        this.disablePureCode = false;
        this.searchIdentifier = '';
      }, (error) => {
        this.loading = false;
        this.loadingMessage = 'Loading...';
        this.substanceData = this.searchedSubstanceData;
        this.addSubstanceDialogue = false;
        this.searchedSubstanceData = [];
        this.oncloseDialog();
        this.searchedSubstanceDataTemp = [];
        this.searchIdentifierValue = '';
        this.searchResultsGroup = [];
        this.disablePureCode = false;
        this.searchIdentifier = '';
        return false;
      });
    }
  }

  // to search the substance on the basis of pure code
  /* istanbul ignore next */
  searchSubstance() {
    let apiurl;
    this.searchResultsGroup = [];
    let val = '';
    if (this.searchIdentifier === 'CAS #') {
      val = 'CAS';
    } else if (this.searchIdentifier === 'Substance Name') {
      val = 'BACKBONEIDENTITY';
     }  else if (this.searchIdentifier === 'INCI Name') {
      val = 'INCI';
    } else if (this.searchIdentifier === 'SIR ID') {
      val = 'SIRID';
    } else if (this.searchIdentifier === 'Pure Code') {
      val = 'PURECODE';
    }
    apiurl = environment.APIFAsTRule + '/rules/substances/' + val + '/cfRequired/y';
    const data = [];
    data.push(this.searchIdentifierValue);
    this.loadingMessage = 'Loading...';
    this.loading = true;
    this.assessRequestRa.postData(apiurl, data).subscribe((response) => {
      if (response && response.length > 0) {
        this.searchResultsGroup = response;
        this.loading = false;
        this.disablePureCode = true;
      }
    }, (error) => {
      this.searchResultsGroup = [];
      this.loading = false;
    });
  }

  onSearchIdentifierChange() {
    this.searchIdentifierValue = null;
    this.searchResultsGroup = [];
    this.disablePureCode = false;
  }
  // save/edit group validation check flag - enable if substances are selected
  onSubstanceSelect() {
    let sirIDExists = false;
    for (const value of this.searchedSubstanceData) {
      if (!value.sirId.includes('SIR ID Not found')) {
        sirIDExists = true;
        break;
      }
    }
    const selectedSubs = this.searchedSubstanceData.filter(x => x.isSubstanceChecked);
    if (selectedSubs.length > 0 && sirIDExists) {
      this.isSubstanceSelected = true;
    } else {
      this.isSubstanceSelected = false;
    }
  }

  onAllSubstanceSelect() {
    this.searchedSubstanceData.forEach(x => x.isSubstanceChecked = this.isAllSubstanceChecked);
    this.onSubstanceSelect();
  }
  // assign groupdatamodel on group selection
  onGroupSelect(event, rowValue, index) {
    this.radioRowIndex = index;
    this.isGroupSelected = true;
    this.substanceGroupData = {
      groupId: rowValue.groupId,
      groupName: rowValue.groupName,
      groupType: rowValue.groupType,
      substanceData: []
    };
    this.groupData.forEach(groupsub => {
      if (groupsub.groupId === rowValue.groupId && groupsub.substanceName) {
        const subData = {
          sirId: groupsub.substanceId,
          purecode: groupsub.pureCode,
          substanceName: this.changeToTitleCase(groupsub.substanceName),
          cas: groupsub.cas,
          parentCAS: groupsub.parentCAS,
          inciName: groupsub.inciName,
          ins: groupsub.ins,
          cfDetails: groupsub.cfDetails,
          cfdata: groupsub.cfData,
          einecs: groupsub.einecs,
          synonyms: groupsub.substanceName,
          createdbyuser: groupsub.createdByUserIdDisplayName
        };
        this.substanceGroupData.substanceData.push(subData);
      }
    });
    Object.assign(groupDataModel, this.substanceGroupData);
    this.dataServiceObj.setSelectedGroup(this.substanceGroupData);
  }

  changeToTitleCase(word: string) {
    if (!word) {
      return word;
    }
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
  /* istanbul ignore next */
  editGroup() {
    this.getUserBasedDivision();
    this.isEditbtnDisable = true;
    this.selectedRows = [];
    this.switchedPureCodesEdit = [];
    this.switchedSirDetailsEdit = [];
    this.createGroupTab = true;
    this.addSubstanceDialogue = false;
    this.isEditGroup = true;
    this.isCreateGroup = false;
    this.groupForm = this.formBuilder.group({
      GroupName: this.substanceGroupData.groupName,
      GroupType: 'Regulatory Group',
      UPDFullText: this.division,
      SubstanceIds: [],
      selectedDivision: '',
    });
    this.createdGroup = this.substanceGroupData.substanceData;
    this.userDetails = JSON.parse(localStorage.getItem('userRolesPermissions'));
    if (this.substanceGroupData.substanceData[0].createdbyuser === this.userDetails.userDisplayName) {
      this.isUserAuthToEditGrpName = true;
    } else {
      // this.isSuperAdminOrGlobalRA = false;
      this.getUserBasedDivision();
    }
    const ele = document.getElementById('echeck') as HTMLInputElement;
    if (ele) {
      ele.checked = false;
    }
  }

  /* istanbul ignore next */
  deleteGroup() {
    this.loading = true;
    this.loadingMessage = 'Deleting Regulatory Group - ' + this.substanceGroupData.groupId;
    const apiUrl = environment.APIFAsTRule + '/rules/substancegroup/' + this.substanceGroupData.groupId + '/comments/' + this.deleteGroupComment;
    this.assessRequestRa.deleteData(apiUrl).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.deleteGroupResponse = response.message;
        if (response.message.startsWith('Deleted')) {
          this.deleteGroupResponseSuccess = true;
          this.deleteGroupResponse = 'No associated rules found. Group deletion successful.';
        }
      }
    }, (error) => {
      this.loading = false;
    });
    this.removeBtn = true;
  }
  closePopup() {
    this.deleteGroupPopup = false;
    this.enableDelete = false;
    this.showGroup();
  }
  closeGroupConfPopup() {
    this.deleteGroupConfPopup = false;
  }
  rowselected(data) {
    this.onGroupSelect(data, data.data, data.index);
  }
  editGrpDetails() {
    this.isEditGroup = false;
    this.isUpdateGroupDetails = true;
  }
  /* istanbul ignore next */
  editGroupDetails() {
    this.loading = true;
    this.loadingMessage = 'Updating Regulatory Group - ' + this.substanceGroupData.groupId;
    const apiUrl = environment.APIFAsTRule + '/rules/substancegroup/' + this.substanceGroupData.groupId + '/comments/' + this.deleteGroupComment;
    this.assessRequestRa.deleteData(apiUrl).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.deleteGroupResponse = response.message;
        if (response.message.startsWith('Deleted')) {
          this.deleteGroupResponseSuccess = true;
        }
      }
    }, (error) => {
      this.loading = false;
    });
    this.removeBtn = true;
  }
  onRowUnselect(event) {
    this.radioRowIndex = null;
    this.isGroupSelected = false;
  }
  /* istanbul ignore next */
  addItem() {
    this.searchedSubstanceData = this.searchedSubstanceData.concat(this.searchedSubstanceDataTemp);
    this.searchedSubstanceDataTemp = [];
    this.addItemBtnDisableFlag = true;
    this.disablePureCode = false;
  }
  /* istanbul ignore next */
  valueSelected(rowselected: any, event) {
    if (event.currentTarget.checked) {
      let purecode;
      let sirId;
      let cas;
      let parentCAS;
      let cfdata;
      let einecs;
      let synonyms;
      let ins;
      let inciName;
      let sirIdNotFound;
      let existingRecord;
      this.checkSelectedVal = rowselected.sirId + ' (' + rowselected.substanceName + ')';
      if (rowselected) {
        const found = this.searchedSubstanceData.some(item => item.sirId === rowselected.sirId);
        if (found) {
          this.matSnackBar.open('Substance already added', 'close', {
            duration: 6000,
            panelClass: ['error']
          });
          this.loading = false;
          return false;
        }
        this.loading = false;
        const sirvalues = rowselected;
        purecode = sirvalues.pureCode;
        sirId = sirvalues.sirId;
        if (!sirId || sirId === '' || sirId.includes('SIR ID Not found')) {
          sirIdNotFound = true;
        } else {
          sirIdNotFound = false;
        }
        cas = sirvalues.cas;
        parentCAS = sirvalues.parentCAS;
        einecs = sirvalues.einecs;
        synonyms = sirvalues.substanceName;
        if (sirvalues.cfDetails !== null) {
          cfdata = sirvalues.cfDetails[0].cfDisplayText;
        }
        ins = sirvalues.ins;
        inciName = sirvalues.inciName;
        existingRecord = this.searchedSubstanceDataTemp.filter(x => x.sirId === sirId);
        if (this.isEditGroup === true) {
          existingRecord = this.substanceGroupData.substanceData.filter(x => x.sirId === sirId);
        }
        if (existingRecord && existingRecord.length > 0) {
          this.matSnackBar.open('Substance already added.', 'close', {
            duration: 6000,
            panelClass: ['error']
          });
          this.loading = false;
          return false;
        }

        this.searchedSubstanceDataTemp.push({
          purecode,
          sirId,
          cas,
          parentCAS,
          einecs,
          synonyms,
          cfdata,
          ins,
          inciName,
          isSubstanceChecked: false,
          sirIdNotFound
        });
        this.loading = false;
      } else if (this.searchIdentifier === 'Pure Code') {
        this.loading = false;
        existingRecord = this.searchedSubstanceDataTemp.filter(x => x.purecode === this.searchIdentifierValue);
        if (existingRecord && existingRecord.length > 0) {
          this.matSnackBar.open('Duplicate Record', 'close', {
            duration: 6000,
            panelClass: ['error']
          });
          return false;
        }
        purecode = this.searchIdentifierValue;
        const foundPure = this.searchedSubstanceData.some(item => item.purecode === rowselected.purecode);
        if (!foundPure) {
          this.searchedSubstanceDataTemp.push({
            purecode,
            isSubstanceChecked: false,
            sirIdNotFound: true
          });
        }

      } else {
        this.loading = false;
        this.matSnackBar.open('Could not find a matching substance, Please provide the correct search value', 'close', {
          duration: 6000,
          panelClass: ['error']
        });
      }

    } else {
      this.searchedSubstanceDataTemp = this.searchedSubstanceDataTemp.filter((item) => item.sirId !== rowselected.sirId);
    }
    if (this.searchedSubstanceDataTemp.length > 0) {
      this.addItemBtnDisableFlag = false;
    } else {
      this.addItemBtnDisableFlag = true;
    }
  }
  checkval(event) {
    if (event.target.value === '') {
      this.searchResultsGroup = [];
      this.disablePureCode = false;
    } else {
      this.addItemBtnDisableFlag = true;
    }
    setTimeout(() => {
      if (event.target.value !== this.checkSelectedVal) {
        this.searchResultsGroup = [];
        this.disablePureCode = false;
      }
    }, 1000);
  }

  createGroupFormStructure() {
    this.groupForm = this.formBuilder.group({
      GroupName: [''],
      GroupType: 'Regulatory Group',
      UPDFullText: this.division,
      SubstanceIds: [],
    });
  }
    /* istanbul ignore next */
    removeDuplicates(array, ruleID) {
      const lookup = {};
      array.forEach(element => {
        lookup[element[ruleID]] = element;
      });
      return Object.keys(lookup).map(prop => lookup[prop]);
    }

  notifyGPCClicked(event, selectedSirDetails) {
    for (const value of selectedSirDetails) {
      value.selectedIssueCategory = [];
      value.commentsToUser = '';
    }
    selectedSirDetails = this.removeDuplicates(selectedSirDetails, 'sirId');
    this.selectedSirDetails = selectedSirDetails;
    this.notifyGPCDialog = true;
  }
  notifyGPCClickedEdit(event, selectedSirDetails) {
    for (const value of this.selectedSirDetails) {
      value.selectedIssueCategory = [];
      value.commentsToUser = '';
    }
    this.selectedSirDetails = this.removeDuplicates(selectedSirDetails, 'sirId');
    this.selectedSirDetailsEdit = selectedSirDetails;
    this.notifyGPCDialogEdit = true;
  }

  handleSwitchEdit(event, sirvalue) {
    this.editNotifyGPC = true;
    sirvalue.issueCategoryList = [
      { name: 'CAS#', value: 'CAS#' },
      { name: 'Parent CAS#', value: 'Parent CAS#' },
      { name: 'E#', value: 'E#' },
      { name: 'INS#', value: 'INS#' },
      { name: 'EINECS#', value: 'EINECS#' },
      { name: 'INCI', value: 'INCI' },
      { name: 'Synonym', value: 'Synonym' },
      { name: 'CF details', value: 'CF details' },
    ];
    sirvalue.selectedIssueCategory = [];
    if (event.checked === true) {
      this.switchedSirDetailsEdit.push(sirvalue);
      this.switchedPureCodesEdit.push(sirvalue.purecode);
    } else if (event.checked === false) {
      const index = this.switchedPureCodesEdit.findIndex(d => d === sirvalue.purecode);
      this.switchedPureCodesEdit.splice(index, 1);
      this.switchedSirDetailsEdit.splice(index, 1);
    }
  }
  handleSwitch(event, sirvalue) {
    sirvalue.issueCategoryList = [
      { name: 'CAS#', value: 'CAS#' },
      { name: 'Parent CAS#', value: 'Parent CAS#' },
      { name: 'E#', value: 'E#' },
      { name: 'INS#', value: 'INS#' },
      { name: 'EINECS#', value: 'EINECS#' },
      { name: 'INCI', value: 'INCI' },
      { name: 'Synonym', value: 'Synonym' },
      { name: 'CF details', value: 'CF details' },
    ];
    sirvalue.selectedIssueCategory = [];
    if (event.checked === true) {
      this.switchedSirDetails.push(sirvalue);
      this.switchedPureCodes.push(sirvalue.purecode);
    } else if (event.checked === false) {
      const index = this.switchedPureCodes.findIndex(d => d === sirvalue.purecode);
      this.switchedPureCodes.splice(index, 1);
      this.switchedSirDetails.splice(index, 1);
    }
  }

  onCheck(event, checkedPureCode) {
    if (event.checked === true) {
      this.checkedPureCodes.push(checkedPureCode);
    } else if (event.checked === false) {
      const index = this.checkedPureCodes.findIndex(d => d === checkedPureCode);
      this.checkedPureCodes.splice(index, 1);
    }
  }
  /* istanbul ignore next */
  checkRulesAvail() {
    this.loading = true;
    this.selectedRowsEmail = [];
    this.showRelatedRequests = false;
    this.getUserBasedDivision();
    this.selectedRowWorklist = null;
    this.loadingMessage = 'Fetching data from FAsT Rule-Engine';
    const sirIds = [];
    this.selectedRows.forEach(element => {
      sirIds.push(element.sirId);
    });
    const body = {
      GroupID: this.substanceGroupData.groupId,
      GroupSubstances: sirIds
    };
    const apiUrl = environment.APIFAsTRule + '/rules/substancegroups/relatedrules/View/rulestatus/' + 'activeandfutureactive';
    this.assessRequestRa.postData(apiUrl, body).subscribe((response) => {
      this.showRelatedRulesPopup = true;
      this.loading = false;
      this.deleteRulePopup = false;
      if (response.length > 0) {
        this.prepareRuleTableData = this.assessRequestRa.modifyObjectForTableMapping(response);
      } else {
        this.prepareRuleTableData = response;
      }
      for (const val of this.prepareRuleTableData) {
        val.warningText = (val.warningText !== null && val.warningText !== '') ? val.warningText : ((val.warningStatement !== null) ? val.warningStatement : '');
        val.originalWarningText = val.warningText;
        const warningLinkRuleData = this.assessRequestRa.hyperLinksToSheet(val.warningText);
        val.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
        val.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
        val.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
        val.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
        val.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
        val.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
        val.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
        val.warningTextStripped = this.handleSpecialCharacters(val.warningText);
        val.warningText = this.changeComment(val.warningText);
        val.otherRestrictionsAndRequirements = (val.otherRestrictionsAndRequirements !== null) ? val.otherRestrictionsAndRequirements : '';
        const orrLinkRuleData = this.assessRequestRa.hyperLinksToSheet(val.otherRestrictionsAndRequirements);
        val.ORR1 = orrLinkRuleData[0] !== undefined ? orrLinkRuleData[0] : '';
        val.ORR2 = orrLinkRuleData[1] !== undefined ? orrLinkRuleData[1] : '';
        val.ORR3 = orrLinkRuleData[2] !== undefined ? orrLinkRuleData[2] : '';
        val.ORR4 = orrLinkRuleData[3] !== undefined ? orrLinkRuleData[3] : '';
        val.ORR5 = orrLinkRuleData[4] !== undefined ? orrLinkRuleData[4] : '';
        val.ORR6 = orrLinkRuleData[5] !== undefined ? orrLinkRuleData[5] : '';
        val.ORR7 = orrLinkRuleData[6] !== undefined ? orrLinkRuleData[6] : '';
        val.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(val.otherRestrictionsAndRequirements);
        val.originalotherRestrictionsAndReq = val.otherRestrictionsAndRequirements;
        val.otherRestrictionsAndRequirements = this.changeComment(val.otherRestrictionsAndRequirements);
      }
      setTimeout(() => {
        const element = document.getElementById('echeckdel') as HTMLInputElement;
        if (element) {
          element.checked = false;
        }
        if (this.selectedRows.length === this.createdGroup.length) {
          this.matSnackBar.open('Atleast one Purecode must be available in the substance group', 'close', {
            duration: 3900,
            panelClass: ['error']
          });
        }
      }, 500);
    }, (error) => {
      this.loading = false;
    });
    if (this.substanceGroupData.substanceData[0].createdbyuser === this.userDetails.userDisplayName) {
      this.isUserAuthToEditGrpName = true;
    } else {
      // this.isSuperAdminOrGlobalRA = false;
      this.getUserBasedDivision();
    }
    this.enableDelete = false;
  }
  handleSpecialCharacters(text) {
    return text.replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, '').replace(/&amp;/g, '&');
  }
  /* istanbul ignore next */
  checkRulesAvailForDelete() {
    this.loading = true;
    this.showRelatedRequests = false;
    this.selectedRowWorklist = null;
    this.selectedRowsEmail = [];
    this.enableDelete = false;
    this.loadingMessage = 'Fetching data from FAsT Rule-Engine';
    const sirIds = [];
    this.substanceGroupData.substanceData.forEach(element => {
      sirIds.push(element.sirId);
    });
    const body = {
      GroupID: this.substanceGroupData.groupId,
      GroupSubstances: sirIds
    };
    const apiUrl = environment.APIFAsTRule + '/rules/substancegroups/relatedrules/View/rulestatus/' + 'all';
    this.assessRequestRa.postData(apiUrl, body).subscribe((response) => {
      this.showRelatedRulesPopupForDelete = true;
      this.loading = false;
      this.deleteRulePopup = false;
      if (response.length > 0) {
        this.prepareRuleTableData = this.assessRequestRa.modifyObjectForTableMapping(response);
      } else {
        this.prepareRuleTableData = response;
      }
      for (const val of this.prepareRuleTableData) {
        val.warningText = (val.warningText !== null && val.warningText !== '') ? val.warningText : ((val.warningStatement !== null) ? val.warningStatement : '');
        val.originalWarningText = val.warningText;
        const warningLinkRuleData = this.assessRequestRa.hyperLinksToSheet(val.warningText);
        val.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
        val.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
        val.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
        val.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
        val.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
        val.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
        val.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
        val.warningTextStripped = this.handleSpecialCharacters(val.warningText);
        val.warningText = this.changeComment(val.warningText);
        val.otherRestrictionsAndRequirements = (val.otherRestrictionsAndRequirements !== null) ? val.otherRestrictionsAndRequirements : '';
        val.originalotherRestrictionsAndReq = val.otherRestrictionsAndRequirements;
        val.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(val.otherRestrictionsAndRequirements);
        const orrLinkRuleData = this.assessRequestRa.hyperLinksToSheet(val.otherRestrictionsAndRequirements);
        val.ORR1 = orrLinkRuleData[0] !== undefined ? orrLinkRuleData[0] : '';
        val.ORR2 = orrLinkRuleData[1] !== undefined ? orrLinkRuleData[1] : '';
        val.ORR3 = orrLinkRuleData[2] !== undefined ? orrLinkRuleData[2] : '';
        val.ORR4 = orrLinkRuleData[3] !== undefined ? orrLinkRuleData[3] : '';
        val.ORR5 = orrLinkRuleData[4] !== undefined ? orrLinkRuleData[4] : '';
        val.ORR6 = orrLinkRuleData[5] !== undefined ? orrLinkRuleData[5] : '';
        val.ORR7 = orrLinkRuleData[6] !== undefined ? orrLinkRuleData[6] : '';
        val.otherRestrictionsAndRequirements = this.changeComment(val.otherRestrictionsAndRequirements);
      }
      setTimeout(() => {
        const element = document.getElementById('echeckdel') as HTMLInputElement;
        if (element) {
          element.checked = false;
        }
      }, 500);
    }, (error) => {
      this.loading = false;
    });
  }

  selectRow(checkValue) {
    if (checkValue) {
      this.selectedRows = this.createdGroup;
    } else {
      this.selectedRows = [];
    }
  }

  selectRowEmail(checkValue) {
    if (checkValue) {
      this.selectedRowsEmail = this.workListData;
    } else {
      this.selectedRowsEmail = [];
    }
  }

  deleteRequests() {
    this.loading = true;
    this.loadingMessage = 'Deleting...';
    const sirIds = [];
    this.selectedRows.forEach(element => {
      sirIds.push(element.sirId);
    });
    const body = {
      GroupID: this.substanceGroupData.groupId,
      GroupSubstances: sirIds
    };
    const apiUrl = environment.APIFAsTRule + '/rules/substancegroups/relatedrules/Delete/rulestatus/activeandfutureactive';
    this.assessRequestRa.postData(apiUrl, body).subscribe((response) => {
      if (response) {
        this.selectedRows.forEach(elements => {
          this.substanceGroupData.substanceData = this.substanceGroupData.substanceData.filter(x => x.sirId !== elements.sirId);
        });
        this.createdGroup = _.xor(this.createdGroup, this.selectedRows);
        this.showRelatedRulesPopup = false;
        this.deleteRulePopup = false;
        this.loading = false;
        this.selectedRows = [];
        this.matSnackBar.open('Deleted successfully', 'close', {
          duration: 3000,
          panelClass: ['success']
        });
        const ele = document.getElementById('echeck') as HTMLInputElement;
        if (ele) {
          ele.checked = false;
        }
        const element = document.getElementById('echeckdel') as HTMLInputElement;
        if (element) {
          element.checked = false;
        }
      }
    }, (error) => {
      this.loading = false;
    });
  }

  sendEmail() {
    this.showEmailConfirmationDialog = true;
    this.emailComments = '';
  }

  deleteGroupConfirmation() {
    this.getUserBasedDivision();
    if (this.isSuperAdminOrGlobalRA) {
    this.deleteGroupPopup = true;
    this.enableDelete = true;
    this.deleteGroupComment = '';
    this.deleteGroupResponse = '';
    this.removeBtn = false;
    this.deleteGroupResponseSuccess = false;
     } else {
    this.deleteGroupConfPopup = true;
    this.loading = false;
     }
  }
  /* istanbul ignore next */
  updateGroupConfirmation() {
    this.getUserBasedDivision();
    if (this.substanceGroupData.substanceData[0].createdbyuser === this.userDetails.userDisplayName) {
      this.isUserAuthToEditGrpName = true;
    } else {
      // this.isSuperAdminOrGlobalRA = false;
      this.getUserBasedDivision();
    }
    if (this.isUserAuthToEditGrpName) {
      this.editGroupPopup = true;
    } else {
      this.editGroupPopup = false;
      this.matSnackBar.open('User is not authorised to rename the group for [' + this.division + ']  division', 'close', {
        duration: 6000,
        panelClass: ['error']
      });
    }
  }
  /* istanbul ignore next */
  editGroupConfirmation() {
    this.loading = true;
    this.loadingMessage = 'Updating Group';
    const apiUrl = environment.APIFAsTRule + '/rules/updategroup';
    let data1;
    this.groupToEditData = Object.assign({}, this.substanceGroupData);
    this.groupToEditData.groupName = this.groupForm.controls.GroupName.value;
    this.groupToEditData.UPDFullText = this.division;
    data1 = this.groupToEditData;
    this.assessRequestRa.putData(apiUrl, data1).subscribe((response) => {
      if (response) {
        this.isUpdateGroupDetails = true;
        this.matSnackBar.open('Updated successfully', 'close', {
          duration: 3000,
          panelClass: ['success']
        });
        this.isEditGroup = true;
        this.loading = false;
        this.editGroupPopup = false;
        this.isUpdateGroupDetails = false;
      }
    }, (error) => {
      this.loading = false;
    });
  }
  /* istanbul ignore next */
  sendEmailMessage() {
    this.showEmailConfirmationDialog = false;
    const payLoad = {
      NotificationGroup: 'RDCOMPLETED',
      Subject: 'FAsT | Rules updated for these requests :',
      EmailBody: null,
      ToEmailList: '',
      FromEmail: null,
      CCEmailList: null,
      EmailType: 'html',
      UPD: this.selectedRowWorklist.ruleId.toString(),
      PureCodes: null,
      UserRequests: [],
      CUCDescription: null,
      Comments: this.emailComments
    };

    if (this.selectedRowsEmail.length > 0) {
      this.selectedRowsEmail.forEach(row => {
        const reqIds = this.selectedRowsEmail.filter(item => item.submittedByUserId === row.submittedByUserId).map(i => i.requestDisplayID);
        payLoad.UserRequests.push({
          userId: +row.submittedByUserId,
          requestIds: reqIds
        });
      });
    }

    if (payLoad.UserRequests.length > 0) {
      this.assessRequestRa.sendRulesEmailNotification(payLoad);
    }
  }

  getWorklistColumns() {
    const apiurl = environment.APIFAsT + '/getworklistcolumns/relatedrequest';
    this.worklistService.getWorklistApiData(apiurl).subscribe((response) => {
      this.myWorklistTableColumns = response;
    });
  }

  onRowSelectReq(value) {
    this.workListData = [];
    this.selectedRowWorklist = value;
    this.loadingMessage = 'Fetching data from FAsT Rule-Engine';
    this.loading = true;
    const apiurl = environment.APIFAsTRule + '/rules/queryrelatedrequests';
    const body = [];
    body.push({
      RuleId: value.ruleId,
      RuleVersion: value.ruleVersion
    });
    this.assessRequestRa.postData(apiurl, body).subscribe((response) => {
      if (response) {
        this.showRelatedRequests = true;
        this.loading = false;
        this.workListData = response.map(respElem => {
          return {
            requestDisplayID: respElem.requestDisplayID,
            cucCode: respElem.cucCode,
            cucDescription: respElem.cucDescription,
            recipeType: respElem.recipeType,
            recipeStatus: respElem.recipeStatus,
            marketId: respElem.market.marketId,
            marketName: respElem.market.marketName,
            division: (respElem.tfc.tfcLevel === 5) ? respElem.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName : respElem.tfc.parentTFC.parentTFC.parentTFC.tfcName,
            rDFormulationCategory: (respElem.tfc.tfcLevel === 5) ? respElem.tfc.parentTFC.parentTFC.parentTFC.tfcName : respElem.tfc.parentTFC.parentTFC.tfcName,
            formulationType: (respElem.tfc.tfcLevel === 5) ? respElem.tfc.parentTFC.parentTFC.tfcName : respElem.tfc.parentTFC.tfcName,
            formulationClass: (respElem.tfc.tfcLevel === 5) ? respElem.tfc.parentTFC.tfcName : respElem.tfc.tfcName,
            formulationSubClass: (respElem.tfc.tfcLevel === 5) ? respElem.tfc.tfcName : '',
            preparationMethod: respElem.preparationMethod,
            intendedUse: respElem.intendedUse,
            multicomponent: respElem.isMultiComponent,
            requestStatus: respElem.requestStatus,
            ra: respElem.ra,
            allRa: respElem.allRA,
            submittedDate: respElem.submittedDate,
            submittedByUserId: respElem.submittedByUserId,
            submittedByUserName: respElem.submittedByUserName,
            comments: respElem.comments,
            assignedDate: respElem.assignedDate,
            inProgressRADate: respElem.inProgressRADate,
            inProgressRDDate: respElem.inProgressRNDDate,
            inProgressSIRDate: respElem.inProgressSIRDate,
            rejectedDate: respElem.rejectedDate,
            deletionDate: respElem.deletionDate,
            completedDate: respElem.completedDate,
            rdComment: respElem.comments,
            raComment: respElem.raComment,
            topTfcId: respElem.tfcid,
            allRAIDs: respElem.allRAIDs,
            raUserID: respElem.raUserIDred,
            divisionid: (respElem.tfc.tfcLevel === 5) ? respElem.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId : respElem.tfc.parentTFC.parentTFC.parentTFC.tfcId
          };
        });
      }
    }, (error) => {
      this.loading = false;
    });
  }

  selectRowForDelete(checked) {
    if (checked) {
      this.enableDelete = true;
    } else {
      this.enableDelete = false;
    }
  }

  deleteRule() {
    this.deleteRulePopup = true;
    this.deleteRuleComment = '';
  }

  showExportDialog() {
    this.exportDialog = true;
  }

  onFilter(event) {
    this.filteredRows = event.filteredValue;
  }

  /* istanbul ignore next */
  onClearFilters(dtGroupTable: any) {
    dtGroupTable.reset();
    this.filteredRows = [];
    for (const val of this.groupColumns) {
      if (val.field !== 'check') {
        const colname = val.field + '_val';
        if ((<HTMLInputElement> document.getElementById(colname)) && (<HTMLInputElement> document.getElementById(colname)).value) {
          (<HTMLInputElement> document.getElementById(colname)).value = '';
        }
      }
    }
  }
  showOverlay(htmlContent) {
    this.overlayText = htmlContent;
    this.hideOverlay = false;
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
  hideOverlayPanel() {
    this.hideOverlay = true;
  }
  popupShowOverlay(htmlContent) {
    this.popupOverlayText = htmlContent;
    this.popupHideOverlay = false;
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
  popupHideOverlayPanel() {
    this.popupHideOverlay = true;
  }
    /* istanbul ignore next */
    openComment(data) {
      this.warningTextOpen = true;
      const mydata = data.originalWarningText;
      this.tablewarningComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
    }
      /* istanbul ignore next */
      openCommentORR(data) {
      this.orrTextOpen = true;
      const mydata = data.originalotherRestrictionsAndReq;
      this.tableORRComment = mydata.replace(/<a/gi, '<a target="_blank"');
    }
    closePopupORR() {
      this.orrTextOpen = false;
    }
    closePopupW() {
      this.warningTextOpen = false;
    }
    /* istanbul ignore next */
    changeComment(input) {
      const strValue = input;
      const ar = strValue.match(/<a.*?<\/a>/g);
      let commentwarning = '';
      if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
        commentwarning = 'Click Here';
      } else {
        commentwarning = input;
      }
      return commentwarning;
    }
}
function compareData(a, b) {
  return a.groupId - b.groupId;
}
