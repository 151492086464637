export class GroupRulesDetailsModel {
    Market: MarketDetails;
    RpcDetails: RPCDetails[];
    RpcFullDetails;
    CascadeRpc;
    applicableRuleTypeList = [];
    selectedRpcs;
    selectedParentRpcs;
    ruleCategory;
    GroupId;
    GroupType;
    GroupName;
    SubstanceData: SirDetails[];
    rulesListForGroups;
}

export class SirDetails {
    sirId;
    pureCode;
    substanceName;
    cas;
    parentCAS;
    inciName;
    ins;
    cfDetails;
}

class MarketDetails {
    marketId;
    marketName;
}

class RPCDetails {
    RpcId;
    RpcName;
}

// tslint:disable-next-line: no-var-keyword
export var groupRulesDetailsModel = new GroupRulesDetailsModel();
