import { Directionality } from '@angular/cdk/bidi';
import { CdkStepper } from '@angular/cdk/stepper';
import { ChangeDetectorRef, ElementRef, Input, Output, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AssessRequestsComponent } from '../assess-requests/assess-requests.component';
import { SubmitRequestComponent } from '../assess-requests/submit-request/submit-request.component';
import { requestFlags, ruleFlags } from '../common/models/PureRuleDetailsModel';
import { recipeModel } from '../common/models/recipeDetailsModel';
import { DataService } from '../common/services/data.service';
import { StandaloneComponent } from '../standalone/standalone.component';
import { WorklistService } from '../worklist/worklist.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }]
})
export class StepperComponent extends CdkStepper implements OnInit {
  @ViewChild(StandaloneComponent) stepper: StandaloneComponent;
  @ViewChild(AssessRequestsComponent) stepper1: AssessRequestsComponent;
  @ViewChild(SubmitRequestComponent) stepper2: SubmitRequestComponent;
  @ViewChild('stepTabs') stepTabs: ElementRef;
  @ViewChild('stepsTabsWrapper') stepsTabsWrapper: ElementRef;
  @Output() stepperChange = new EventEmitter<any>();
  @Input() componentName: any;
  // dataSvc = new DataService();
  stepLabels = [];
  navigationItems;
  currentTab;
  viewReqFlag;
  editReqFlag;
  createReqFlag;
  requestID;
  requestStatus;
  loadingMsg: string;
  loading = false;
  viewRuleFlag = false;
  editRuleFlag = false;
  constructor(
    dir: Directionality,
    changeDetectorRef: ChangeDetectorRef,
    private dataSvc: DataService,
    private router: Router,
    private workListService: WorklistService
  ) {
    super(dir, changeDetectorRef);
  }
  /* istanbul ignore next */
  ngOnInit() {
    // test sonar
    this.stepLabels = [
      'Verify Request',
      'Update Rules',
      'Execute Rules',
      'Submit Report'];

    this.workListService.getRecipeData().subscribe((val: any) => {
      if (val !== null) {
        // Object.assign(recipeModel, val);
        this.requestID = val.requestID;
        // this.requestStatus = val.requestStatus;
        this.workListService.setRequestStatus(val.requestStatus);
      }
    });

    this.workListService.getRequestStatus().subscribe(status => this.requestStatus = status);

    this.dataSvc.getComponentNameSvc.subscribe((val) => {
      if (val) {
        this.componentName = val;
      }
    });
    this.dataSvc.selectedStepValue.subscribe((value) => {
      this.selectedIndex = value;
      if (this.selectedIndex === 0) {
        this.createReqFlag = true;
        this.viewReqFlag = false;
        this.editReqFlag = false;
      }
    });
    if (requestFlags.createRequest === true && this.selectedIndex === 0) {
      this.stepperChange.next(0);
      this.createReqFlag = true;
      this.viewReqFlag = false;
      this.editReqFlag = false;

    } else if (requestFlags.viewRequest === true || requestFlags.EditRequest === true) {
      this.selectedIndex = 2;
      this.viewReqFlag = true;
      this.editReqFlag = true;
      this.createReqFlag = false;
      this.stepperChange.next(2);
    }
    if (ruleFlags.viewRule) {
      this.viewRuleFlag = true;
    } else if (ruleFlags.editRule) {
      this.editRuleFlag = true;
    }

  }
 /* istanbul ignore next */
  onClick(index: number): void {
    const oldSelectedIndex = this.selectedIndex;
    this.selectedIndex = index;
    if (index === 0) {
      localStorage.setItem('selectedIndex', '0');
    } else if (index === 1) {
      localStorage.setItem('selectedIndex', '1');
    } else {
      localStorage.setItem('selectedIndex', '2');
    }
    if (this.selectedIndex !== oldSelectedIndex) {
      localStorage.setItem('selectedStepprev', '1');
      this.stepperChange.next(oldSelectedIndex);
    }
  }
  /* istanbul ignore next */
  viewReportt() {
    localStorage.setItem('isViewReportClicked', 'true');
    localStorage.setItem('requestStatus', 'Completed');
    this.dataSvc.setComponentName('SubmitReportRA');
    this.loadingMsg = 'Getting FAsT Report';
    const apiurl = environment.APIFAsT + '/reportrequest/' + this.requestID;
    this.loading = true;
    this.workListService.getData(apiurl).subscribe((response1) => {
      if (response1 !== null) {
        const val: any = {
          requestID: response1.requestDisplayID,
          requestStatus: response1.requestStatus
        };
        this.workListService.setRecipeData(val);
        recipeModel.requestDisplayID = response1.requestDisplayID;
        localStorage.setItem('CompletedReport', JSON.stringify(response1));
        this.dataSvc.setSelectedStep(3);
        setTimeout(() => {
          this.router.navigate(['assess-request-ra/submit-report']);
        }, 500);
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }
}
