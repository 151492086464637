
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { PROPERTY_COLUMNS_VIEW, PROPERTY_COLUMNS } from 'src/app/common/models/PureFnModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import {
  COMPOSITION_COLUMNS_EDIT_NO,
  SUMMARY_MULTI_COMPONENT,
  VERIFY_COMPOSITION_COLUMNS, FRAGRANCE_COLUMNS, FRAGRANCE_REGIONAL_COLUMNS2, FRAGRANCE_REGIONAL_COLUMNS3, FRAGRANCE_EXCEL_COLUMNS, PROJECT_COLUMNS
} from 'src/app/common/models/tableColumns';
import { DataService } from 'src/app/common/services/data.service';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from 'src/environments/environment';
import { AssessmentRequestService } from 'src/app/assess-requests/assess-requests.service';
import { AssessRequestRaService } from '../assess-request-ra.service';

@Component({
  selector: 'app-verify-request-plm',
  templateUrl: './verify-request-plm.component.html',
  styleUrls: ['./verify-request-plm.component.scss']
})

export class VerifyRequestPlmComponent implements OnInit {
  divisionValue = '';
  loading = false;
  readOnlyValue = true;
  plmForm: FormGroup;
  formulationData = [];
  pureCodeList = [];
  requestId;
  star = 'fa fa-fw fa-search';
  loadingMessage;
  productDetailsColumns = [];
  compositionDetailsColumns = [];
  compositionData = [];
  productData = [];
  functionVal;
  functionListVals = [];
  filteredFunctionListVals = [];
  cucAltVer;
  tfcList: [];
  requestid;
  profileid;
  returnBtnClicked = false;
  showDialog;
  updtaeBtnFlag;
  userDetails;
  requestResponse;
  selfAssignFlag = false;
  rejectReqFlag = false;
  infoPopUp = false;
  cancelBtnFalg = false;
  isSuperAdmin = false;
  isCommentDialogOpen = false;
  isRACountryRAGlobal = false;
  isViewer = false;
  isRD = false;
  isMultiComp;
  multiComponent = [];
  dosageforMarkets = [];
  multicomponentDatacolumns = [];
  GoToWorklistConfirmation = false;
  createdByUserId;
  multiComponentData = [];
  selectedMultiComponents = [];
  isMultiComponent = 'N';
  sortedCompositionData = [];
  rejectionComments = '';
  hideDosageServicePanel = false;
  isDosage: boolean;
  calculatedRecipeQty: any;
  returnReqPopUp = false;
  reqReturnComments = '';
  fragranceDetailsColumns = [];
  regionalComplianceData = [];
  regionalComplianceLevel2Data = [];
  regionalComplianceLevel3Data = [];
  fragranceComplianceLevel2Column = [];
  fragranceComplianceLevel3Column = [];
  regionalComplianceLevel2ModelList = [];
  regionalComplianceLevel3ModelList = [];
  fragranceData = [];
  fragranceMultipleArrayExcelColumns = [];
  fragranceMultipleArrayExcelRowsData = [];
  projectDetailsColumns = [];
  reqStatus: string;
  showFragranceMenu: boolean;
  fragranceMessage = '';
  projectForm: FormGroup;
  ProjectData: any = {};
  tableDataProject: any = [];
  tableComment: any;
  isRAAndRD = false;
  updId = '';
  marketId = '0';
  constructor(
    public assessService: AssessmentRequestService,
    public assessraService: AssessRequestRaService,
    public matSnackBar: MatSnackBar,
    private routersvc: Router,
    private dataTrans: DataService,
    public formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private worklistservice: WorklistService) { }

  ngOnInit() {
    this.getfragranceFlag();
    this.dataTrans.setruleToEdit(null);
    this.dataTrans.setSelectedStep(0);
    this.worklistservice.getProfileObs().subscribe(profile => this.profileid = profile);
    this.requestId = this.route.snapshot.paramMap.get('requestId');
    if (this.requestId === null) {
      this.requestId = this.profileid;
    }
    if (this.profileid === null) {
      this.worklistservice.setProfileObs(this.requestId);
    }
    recipeModel.requestDisplayID = this.requestId;
    this.createPlmFormStructure();
    this.projectDetailsData();
    this.compositionDetailsColumns = COMPOSITION_COLUMNS_EDIT_NO;
    this.multicomponentDatacolumns = SUMMARY_MULTI_COMPONENT;
    this.fragranceDetailsColumns = FRAGRANCE_COLUMNS;
    this.fragranceComplianceLevel2Column = FRAGRANCE_REGIONAL_COLUMNS2;
    this.fragranceComplianceLevel3Column = FRAGRANCE_REGIONAL_COLUMNS3;
    this.fragranceMultipleArrayExcelColumns = FRAGRANCE_EXCEL_COLUMNS;
    this.projectDetailsColumns = PROJECT_COLUMNS;
    this.projectDetailsColumns = this.removeObjectWithId(this.projectDetailsColumns, 'Actions');
    this.getRecipeDetails();
    if (this.compositionDetailsColumns[this.compositionDetailsColumns.length - 2].header === 'RA Comment') {
      this.compositionDetailsColumns[this.compositionDetailsColumns.length - 2].editable = false;
    }
    // if (this.productDetailsColumns[this.productDetailsColumns.length - 2].header === 'RA Comment') {
    //   this.productDetailsColumns[this.productDetailsColumns.length - 2].editable = false;
    // }
    // const isloadedBefore = localStorage.getItem('IsLoadedBefore');
    // if (isloadedBefore === 'true') {
    //   return;
    // } else {
    //   localStorage.setItem('IsLoadedBefore', 'true');
    //   location.reload();
    // }
  }
  removeObjectWithId(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.field === id);
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
    return arr;
  }
  createPlmFormStructure() {
    this.plmForm = this.formBuilder.group({
      formulationCode: [''],
      alternate: [''],
      version: [''],
      cucAltVer: [''],
      description: [''],
      recipeType: [''],
      recipeStatus: [''],
      keydate: [''],
      validityarea: [''],
      division: [''],
      formulationCategory: [''],
      formulationType: [''],
      formulationClass: [''],
      formulationSubClass: [''],
      preparationMethod: [''],
      intendedUse: ['Any'],
      market: [],
      comments: [''],
      dosage: [''],
      servingSize: [''],
      dosageUom: [''],
      function: [],
      tfcList: [],
      isMultiComponent: []
    });
  }
  projectDetailsData() {
    this.projectForm = this.formBuilder.group({
      Market: '',
      ProjectName: '',
      ProjectDescription: '',
      ProjectType: '',
      Brand: '',
      OtherBrandDescription: '',
      OtherProjectDescription: '',
      LaunchTimeLine: '',
      ManufactureSite: '',
      ManufactureCountry: '',
      requestComments: ''
    });
  }

  /* istanbul ignore next */
  getRecipeDetails() {
    this.tableDataProject = [];
    this.updtaeBtnFlag = false;
    let formulationSubClass; let formulationClass; let formulationType; let formulationCategory; let division;
    const apiurl = environment.APIFAsT + '/executionrequest/' + this.requestId;
    this.loading = true;
    this.assessraService.getData(apiurl).subscribe((response) => {
      this.loading = false;
      if (response !== null) {
        Object.assign(recipeModel, response);
        this.requestResponse = response;
        const val: any = {
          requestID: response.requestDisplayID,
          requestStatus: response.requestStatus
        };
        this.reqStatus = response.requestStatus;
        this.worklistservice.setRecipeData(val);
        this.compositionData = response.formulationRequestDetails;
        this.productData = response.productPropertiesInputs;
        this.ProjectData = response.marketDetails;
        this.tableDataProject.push(response.marketDetails.projDetail);
        this.tableDataProject[0].marketName = response.marketDetails.marketName;
        this.regionalComplianceData = response.ifraComplianceModelList;
        this.regionalComplianceLevel2Data = response.regionalComplianceLevel2ModelList;
        this.regionalComplianceLevel3Data = response.regionalComplianceLevel3ModelList;
        this.fragranceMultipleArrayExcelRowsData.push(response.ifraComplianceModelList);
        this.fragranceMultipleArrayExcelRowsData.push(response.regionalComplianceLevel2ModelList);
        this.fragranceMultipleArrayExcelRowsData.push(response.regionalComplianceLevel3ModelList);
        this.showFragranceMessage(this.regionalComplianceData, this.regionalComplianceLevel2Data, this.regionalComplianceLevel3Data);
        if (response.isMultiComponent === 'Y') {
          this.calculatedRecipeQty = _.sumBy(this.compositionData, 'originalRecipeQuantity');
        } else {
          this.calculatedRecipeQty = _.sumBy(this.compositionData, 'recipeQuantity');
        }
        this.calculatedRecipeQty = _.round(this.calculatedRecipeQty, 12);
        this.formulationData = response.formulationRequestDetails;
        this.multiComponentData = response.multiComponent;
        this.isMultiComponent = response.isMultiComponent;
        if (this.multiComponentData !== null) {
          this.sortCompositionData();
        }
        if (this.ProjectData) {
          this.projectForm = this.formBuilder.group({
            Market: this.ProjectData.marketName,
            ProjectName: this.ProjectData.projDetail.projectName,
            ProjectDescription: this.ProjectData.projDetail.projectDescription,
            ProjectType: this.ProjectData.projDetail.projectTypeDesc,
            Brand: this.ProjectData.projDetail.brandDesc,
            LaunchTimeLine: this.ProjectData.projDetail.launchTimeLine,
            ManufactureSite: this.ProjectData.projDetail.manufactureSiteDesc,
            ManufactureCountry: this.ProjectData.projDetail.manufactureCountryDescription,
            requestComments: this.ProjectData.projDetail.requestComments
          });
        }
        /* istanbul ignore if */
        if (this.formulationData) {
          for (let i = 0; i < this.formulationData.length; i++) {
            this.pureCodeList[i] = this.formulationData[i].pureCode;
          }
          recipeModel.pureCodeList = this.pureCodeList;
          recipeModel.marketDetails = response.marketDetails;
          this.functionListVals = response.functionLstFromRules;
          this.tfcList = response.tfc;
          recipeModel.tfcFullText = response.tfc.tfcFullText;
          this.cucAltVer = response.cucCode + '/' + response.cucAlt + '/' + response.cucVer;
          if (response.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcLevel !== 0) {
            recipeModel.division = response.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName;
            this.divisionValue = response.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName;
            this.updId = response.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId;
            recipeModel.formulationCategory = response.tfc.parentTFC.parentTFC.parentTFC.tfcName;
            recipeModel.formulationType = response.tfc.parentTFC.parentTFC.tfcName;
            recipeModel.formulationClass = response.tfc.parentTFC.tfcName;
            recipeModel.formulationSubClass = response.tfc.tfcName;
            division = response.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName;
            formulationCategory = response.tfc.parentTFC.parentTFC.parentTFC.tfcName;
            formulationType = response.tfc.parentTFC.parentTFC.tfcName;
            formulationClass = response.tfc.parentTFC.tfcName;
            formulationSubClass = response.tfc.tfcName;
            this.marketId = response.marketDetails.marketId;
          } else {
            recipeModel.division = response.tfc.parentTFC.parentTFC.parentTFC.tfcName;
            this.divisionValue = response.tfc.parentTFC.parentTFC.parentTFC.tfcName;
            this.updId = response.tfc.parentTFC.parentTFC.parentTFC.tfcId;
            recipeModel.formulationCategory = response.tfc.parentTFC.parentTFC.tfcName;
            recipeModel.formulationType = response.tfc.parentTFC.tfcName;
            recipeModel.formulationClass = response.tfc.tfcName;
            recipeModel.formulationSubClass = [];
            division = response.tfc.parentTFC.parentTFC.parentTFC.tfcName;
            formulationCategory = response.tfc.parentTFC.parentTFC.tfcName;
            formulationType = response.tfc.parentTFC.tfcName;
            formulationClass = response.tfc.tfcName;
            formulationSubClass = localStorage.getItem('AsIsIngredientComponentname');
            this.marketId = response.marketDetails.marketId;
          }
          this.divisionValue === 'F&R' ? this.productDetailsColumns = PROPERTY_COLUMNS_VIEW : this.productDetailsColumns = PROPERTY_COLUMNS;
          this.multiComponent = response.multiComponent;
          this.dosageforMarkets = response.dosageForMarkets;
          this.isMultiComp = response.isMultiComponent === 'Y' ? 'Yes' : 'No';
          this.createdByUserId = response.createdByUserId;
          if (response.isMultiComponent === 'Y') {
            this.compositionDetailsColumns = VERIFY_COMPOSITION_COLUMNS;
          }
          this.plmForm = this.formBuilder.group({
            formulationCode: [recipeModel.cucCode],
            alternate: [recipeModel.cucAlt],
            version: [recipeModel.cucVer],
            cucAltVer: [this.cucAltVer],
            description: [recipeModel.cucDescription],
            recipeType: [recipeModel.recipeType],
            recipeStatus: [recipeModel.recipeStatus],
            division: [recipeModel.division],
            formulationCategory: [recipeModel.formulationCategory],
            formulationType: [recipeModel.formulationType],
            formulationClass: [recipeModel.formulationClass],
            formulationSubClass: [recipeModel.formulationSubClass],
            preparationMethod: [recipeModel.preparationMethod],
            keydate: this.ProjectData.projDetail.keyDate,
            validityarea: this.ProjectData.projDetail.validityArea,
            intendedUse: [recipeModel.intendedUse],
            market: recipeModel.marketDetails.marketName,
            comments: [recipeModel.requestComments],
            dosage: [recipeModel.dosage],
            servingSize: [recipeModel.servingSize],
            dosageUom: [recipeModel.dosageUnit],
            isMultiComponent: this.isMultiComp, // =="Y"?"Yes":"No",
            function: []
          });
          // tslint:disable-next-line: triple-equals
          if (this.plmForm.value.division == 'BPC') {
            this.hideDosageServicePanel = true;
            // tslint:disable-next-line: triple-equals
          } else if (this.plmForm.value.division == 'F&R') {
            this.hideDosageServicePanel = false;
            this.isDosage = false;
            // tslint:disable-next-line: triple-equals
          } else if (this.plmForm.value.division == 'HC') {
            this.hideDosageServicePanel = false;
            this.isDosage = true;
          }
        }
        if (recipeModel.requestStatus === 'Rejected' || recipeModel.requestStatus === 'Deleted' ||
          recipeModel.requestStatus === 'Completed' || recipeModel.requestStatus === 'SentBack' || recipeModel.requestStatus === 'Submitted' || recipeModel.requestStatus === 'Re-Submitted') {
          this.updtaeBtnFlag = true;
        }
        this.userDetails = JSON.parse(localStorage.getItem('userRolesPermissions'));
        this.userDetails.roles.forEach(c => {
          if (c.roleId === 150) {
            this.isSuperAdmin = true;
          }
          if (c.roleId === 120 || c.roleId === 160) {
            this.isViewer = true;
          }
          if (c.roleId === 130 || c.roleId === 140) {
            this.isRACountryRAGlobal = true;
          }
          if (c.roleId === 110) {
            this.isRD = true;
          }
          if (c.roleId === 170) {
            this.isRAAndRD = true;
          }
        });
        if (this.isSuperAdmin !== true && this.isRAAndRD !== true && recipeModel.raUserID !== this.userDetails.userId) {
          this.updtaeBtnFlag = true;
          this.selfAssignFlag = true;
        }
        if ((this.isViewer === true || this.isRD === true) && this.isRAAndRD !== true && recipeModel.raUserID === this.userDetails.userId) {
          this.updtaeBtnFlag = true;
          this.selfAssignFlag = true;
        }
        if (this.isRACountryRAGlobal === true || this.isSuperAdmin === true || this.isRAAndRD === true) {
          this.selfAssignFlag = false;
        }
        const reqDivisionId = (response.tfc.tfcLevel === 5) ? response.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId : response.tfc.parentTFC.parentTFC.parentTFC.tfcId;
        const reqMarketId = response.marketDetails.marketId;
        const isRARDUser = this.userDetails.roles.filter(f => f.roleId === 130 || f.roleId === 140 || f.roleId === 170);
        const isRARDRowMatched = (isRARDUser.length > 0 && isRARDUser.filter(m => ((m.market !== null && m.market.marketId === reqMarketId) || m.market === null) && ((m.tfc !== null && m.tfc.tfcId === reqDivisionId) || m.tfc === null)).length > 0);
        if (!isRARDRowMatched && this.isSuperAdmin !== true) {
          this.updtaeBtnFlag = true;
          this.selfAssignFlag = true;
        }
      }
      if (this.productDetailsColumns.length > 0 && this.productDetailsColumns[this.productDetailsColumns.length - 2].header === 'RA Comment') {
        this.productDetailsColumns[this.productDetailsColumns.length - 2].editable = false;
      }
    }
      , (error) => {
        this.loading = false;
        const url = '/worklist/master-worklist';
        this.routersvc.navigate([url]);
      });
  }
  sortCompositionData() {
    this.sortedCompositionData = [];
    this.selectedMultiComponents = [];
    this.multiComponentData.forEach(item => {
      this.compositionData.forEach(composition => {
        if (item.multiComponentIngredientName === composition.specID && item.multiComponentName === composition.multiComponentName) {
          composition.componentSet = item.multiComponentName;
        }
      });
    });
    this.multiComponentData.forEach(item => {
      const filterCom = this.selectedMultiComponents.filter(com => com.component === item.multiComponentName);
      if (filterCom.length === 0) {
        this.selectedMultiComponents.push({
          specID: item.multiComponentIngredientName,
          component: item.multiComponentName,
        });
      }
    });
    this.selectedMultiComponents.forEach(component => {
      this.sortedCompositionData.push({
        show: true,
        composition: true,
        specID: component.component,
        specDescription: '',
        specQuantity: '',
        specUoM: '',
        ingredientPath: '',
        ingDescription: '',
        ingQuantity: '',
        pureCode: '',
        pureDescription: '',
        recipeQuantityToDisplay: '',
        recipeUoM: '',
        substanceFunctionInput: ''
      });
      this.compositionData.forEach(composition => {
        if (component.component === composition.componentSet) {
          this.sortedCompositionData.push(composition);
        }
      });
    });
    this.compositionData = this.sortedCompositionData;
  }
  // searchFnList method
  searchFunctionList(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.functionListVals.length; i++) {
      this.functionVal = this.functionListVals[i].functionName;
      if (this.functionVal.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(this.functionVal);
      }
    }
    this.filteredFunctionListVals = filtered;
  }
  onSelfAssign() {
    if (recipeModel.requestStatus === 'Submitted' || recipeModel.requestStatus === 'Re-Submitted') {
      const selfAssignApiBody = {
        RequestId: recipeModel.requestDisplayID,
        UserId: 0,
        AssignmentType: 'Self-Assign'
      };
      this.loading = true;
      this.loadingMessage = 'Assigning Request';
      const apiurl = environment.APIFAsT + '/assignnassessmentrequest/self';
      this.worklistservice.selfAssignApi(apiurl, selfAssignApiBody).subscribe((response) => {
        this.loading = false;
        if (response.result === 'Success') {
          this.sendResponseEmail('Assign', recipeModel.requestDisplayID, this.createdByUserId, '', '', recipeModel.cucDescription);
          this.infoPopUp = true;
          this.getRecipeDetails();
        }
      }, (error) => {
        this.loading = false;
      });


    } else {
      this.matSnackBar.open(recipeModel.requestStatus + ' Request cannot be Self Assign', 'close', {
        duration: 3900,
        panelClass: ['error']
      });
    }
  }
  validateReject(val: any) {
    if (val === 'Rejected') {
      if (recipeModel.requestStatus === 'Assigned') {
        this.rejectionComments = '';
        this.rejectReqFlag = true;
      } else {
        this.matSnackBar.open(recipeModel.requestStatus + ' Request cannot be ' + val, 'close', {
          duration: 3900,
          panelClass: ['error']
        });
      }
    } else if (val === 'Returned') {
      if (recipeModel.requestStatus === 'Assigned' || recipeModel.requestStatus === 'In Progress – RA') {
        this.onReturnClicked();
      } else {
        this.matSnackBar.open(recipeModel.requestStatus + ' Request cannot be ' + val, 'close', {
          duration: 3900,
          panelClass: ['error']
        });
      }
    }
  }
  // reject assessment request
  onRejectClicked() {
    if (recipeModel.requestStatus === 'Assigned') {
      const requestStatus = 'Rejected';
      const statusUpdateApiUrl = environment.APIFAsT + '/assessment/request/' + this.requestId + '/status/' + requestStatus + '/comment/' + this.rejectionComments;
      this.loading = true;
      this.loadingMessage = 'Request Status Update';
      this.assessraService.putData(statusUpdateApiUrl, null).subscribe((statusresponse) => {
        if (statusresponse.message !== null) {
          this.sendResponseEmail('Reject', recipeModel.requestDisplayID, this.createdByUserId, this.rejectionComments, '', recipeModel.cucDescription);
          this.loading = false;
          const url = '/worklist/master-worklist';
          this.routersvc.navigate([url]);
        }
      }, (error) => {
        this.loading = false;
      });
    } else {
      this.matSnackBar.open(recipeModel.requestStatus + ' Request cannot be Rejected', 'close', {
        duration: 3900,
        panelClass: ['error']
      });
    }
  }
  onUpdateRuleClicked() {
    const requestStatus = 'In Progress – RA';
    const statusUpdateApiUrl = environment.APIFAsT + '/assessment/request/' + this.requestId + '/status/' + requestStatus + '/comment/SystemGenerated';
    this.loading = true;
    this.loadingMessage = 'Request Status Update';
    this.assessraService.putData(statusUpdateApiUrl, null).subscribe((statusresponse) => {
      if (statusresponse.message !== null) {
        this.loading = false;
        const val: any = {
          requestID: this.requestId,
          requestStatus: requestStatus
        };
        this.worklistservice.setRecipeData(val);
      }
    }, (error) => {
      this.loading = false;
    });
    this.dataTrans.setSelectedStep(1);
    this.routersvc.navigate(['assess-request-ra/update-rules']);
  }
  goToWorklist() {
    this.returnBtnClicked = false;
    this.routersvc.navigate(['/worklist/master-worklist']);
  }
  onReturnClicked() {
    this.returnBtnClicked = true;
    if (this.compositionDetailsColumns[this.compositionDetailsColumns.length - 2].header === 'RA Comment') {
      this.compositionDetailsColumns[this.compositionDetailsColumns.length - 2] = Object.assign(this.compositionDetailsColumns[this.compositionDetailsColumns.length - 2], {
        editable: true,
      });
    }
    if (this.productDetailsColumns[this.productDetailsColumns.length - 2].header === 'RA Comment') {
      this.productDetailsColumns[this.productDetailsColumns.length - 2] = Object.assign(this.productDetailsColumns[this.productDetailsColumns.length - 2], {
        editable: true,
      });
    }
  }
  onReturnRequestClicked() {
    if (recipeModel.requestStatus === 'Assigned' || recipeModel.requestStatus === 'In Progress – RA') {
      this.loading = true;
      this.loading = true;
      this.loadingMessage = 'RETURN request loading...';
      const url = environment.APIFAsT + '/assessmentrequestmarket';
      this.requestResponse.formulationRequestDetails = this.formulationData;
      this.requestResponse.productPropertiesInputs = this.productData;
      this.assessraService.putData(url, this.requestResponse).subscribe((response12) => {
        const requestStatus = '130'; // sentback
        const statusUpdateApiUrl = environment.APIFAsT + '/assessment/request/' + this.requestId + '/status/' + requestStatus + '/comment/' + this.reqReturnComments;
        this.assessraService.putData(statusUpdateApiUrl, null).subscribe((statusresponse) => {
          if (statusresponse.message !== null) {
            this.reqReturnComments = '';
            this.sendResponseEmail('RETURN', recipeModel.requestDisplayID, this.createdByUserId, this.reqReturnComments, '', recipeModel.cucDescription);
            this.goToWorklist();
            const val: any = {
              requestID: recipeModel.requestDisplayID,
              requestStatus: requestStatus
            };
            this.worklistservice.setRecipeData(val);
            this.returnBtnClicked = false;
            this.loading = false;
          }
        }, (error) => {
          this.loading = false;
        });

        this.matSnackBar.open('Request is returned successfully', 'close', {
          duration: 5000,
          panelClass: ['success']
        });
      }, (error) => {
        this.loading = false;
      });
    } else {
      this.matSnackBar.open(recipeModel.requestStatus + ' Request cannot be Returned', 'close', {
        duration: 3900,
        panelClass: ['error']
      });
    }
  }
  onCancel() {
    this.showDialog = true;
    this.cancelBtnFalg = true;
  }
  addComments(val, index) {
    setTimeout(() => {
      if (val) {
        this.formulationData[index].raComment = val.target.value;
      }
    }, 500);
  }

  addProdComments(val, index) {
    setTimeout(() => {
      if (val) {
        this.productData[index].raComment = val.target.value;
      }
    }, 500);
  }
  // CG - Send response email to respective RA/R&D users.
  sendResponseEmail(notificationGroup, displayRequestId, toemailids, bodyData, ccemaillist, cucDescription) {
    const data = {
      NotificationGroup: notificationGroup,
      Subject: null,
      EmailBody: bodyData,
      ToEmailList: toemailids,
      FromEmail: null,
      CCEmailList: ccemaillist,
      EmailType: 'html',
      UPD: this.updId,
      PureCodes: null,
      RequestID: displayRequestId,
      CUCDescription: cucDescription,
      MarketId: this.marketId
    };
    const apiurl = environment.APIFAsT + '/responseemail';
    this.assessraService.postData(apiurl, data).subscribe((response) => {
      this.loading = false;
      if (response.result === 'Success') {
      }
    }, (error) => {
      this.loading = false;
    });
  }
  getfragranceFlag() {
    const apiurl = environment.APIFAsT + '/appinfo/featureflagfragrance';
    this.assessService.getData(apiurl).subscribe((res) => {
      this.showFragranceMenu = res.FeatureFlagFragrance.toLocaleLowerCase().replace('"', '').replace('"', '') === 'true' ? true : false;
    });
  }
  /* istanbul ignore next */
  showFragranceMessage(regionalComplianceData, regionalComplianceLevel2Data, regionalComplianceLevel3Data) {
    if (regionalComplianceData !== undefined && regionalComplianceData !== null && regionalComplianceLevel2Data !== undefined && regionalComplianceLevel3Data !== undefined) {
      if (regionalComplianceData.length === 0 && regionalComplianceLevel2Data.length === 0 && regionalComplianceLevel3Data.length === 0) {
        this.fragranceMessage = localStorage.getItem('FragranceMessage');
      } else {
        this.fragranceMessage = '';
      }
    }
  }
  openComment(data) {
    this.isCommentDialogOpen = true;
    this.tableComment = data.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  changeComment(comment) {
    let commentRD = '';
    const strValue = comment;
    const ar = strValue.match(/<a.*?<\/a>/g);
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentRD = 'Click Here';
    } else {
      const hComment = this.handleSpecialCharacters(comment);
      commentRD = hComment.length > 25 ? hComment.substring(0, 25) + '...' : hComment;
    }
    return commentRD;
  }
  changedateFormat(launchdate) {
    let newdate = '';
    if (launchdate !== null) {
      const datearray = launchdate.split('/');
      newdate = datearray[0] + '-' + datearray[1] + '-' + datearray[2];
    }
    return newdate;
  }
  /* istanbul ignore next */
  closePopup() {
    this.isCommentDialogOpen = false;
  }
  handleSpecialCharacters(text) {
    return text.replace(/<.*?>/g, ' ').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, ' ').replace(/&amp;/g, '&');
  }
}

