import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../common/shared.module';
import { CreateInsertRuleRoutingModule } from './create-insert-rule-routing.module';
import { CreateInsertRuleComponent } from './create-insert-rule.component';
import { CreateParallelRuleComponent } from './create-parallel-rule/create-parallel-rule.component';
import { SelectRpcComponent } from './select-rpc/select-rpc.component';

@NgModule({
  declarations: [
    CreateInsertRuleComponent,
    SelectRpcComponent,
    CreateParallelRuleComponent,
    // ProductBasedComponent
  ],
  imports: [
    CommonModule,
    CreateInsertRuleRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    // AssessRequestRaModule
  ]
})
export class CreateInsertRuleModule { }
