import {NgModule} from '@angular/core';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {RadioButtonModule} from 'primeng/radiobutton';
import {CheckboxModule} from 'primeng/checkbox';
import { BreadcrumbModule} from 'primeng';
import {AccordionModule} from 'primeng/accordion';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ButtonModule} from 'primeng/button';
import {MultiSelectModule} from 'primeng/multiselect';
import {DialogModule} from 'primeng/dialog';
import {InputSwitchModule} from 'primeng/inputswitch';
import {PickListModule} from 'primeng/picklist';
import {SidebarModule} from 'primeng/sidebar';
import {FileUploadModule} from 'primeng/fileupload';
import {PaginatorModule} from 'primeng/paginator';
import {InputNumberModule} from 'primeng/inputnumber';



@NgModule({
    exports: [
        TableModule,
        DropdownModule,
        AutoCompleteModule,
        AccordionModule,
        ProgressSpinnerModule,
        BreadcrumbModule,
        TooltipModule,
        RadioButtonModule,
        CheckboxModule,
        InputTextareaModule,
        ButtonModule,
        MultiSelectModule,
        DialogModule,
        InputSwitchModule,
        PickListModule,
        SidebarModule,
        FileUploadModule,
        PaginatorModule,
        InputNumberModule,
    ]
})

export class PrimeNGModule { }
