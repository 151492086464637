import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SIR_SHOW_MORE_COLUMNS } from 'src/app/common/models/PureFnModel';
import { pureRulesDetailsModel } from 'src/app/common/models/PureRuleDetailsModel';
import { CFDetails, recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { sirDetailObj } from 'src/app/common/models/SirModel';
import { DataService } from 'src/app/common/services/data.service';
import { environment } from '../../../../../environments/environment';
import { AssessRequestRaService } from '../../../assess-request-ra.service';

@Component({
  selector: 'app-substance-data',
  templateUrl: './substance-data.component.html',
  styleUrls: ['./substance-data.component.scss']
})
export class SubstanceDataComponent implements OnInit {
  pureSirDetail; currentRoute: string;
  readOnlyValue = true; showMoreDialog = false; editRuleFlow = false;
  showMoreColumns = []; showMoreData = [];
  loadingMessage = ''; loading = false; parallelRuleWorkflow = false;
  requestId; componentGlobal; parentRpcParallelRule;
  messageParent = 'CAS no. fetched from Parent substance in SIR';
  constructor(
    private dataService: DataService, private assessRequestRa: AssessRequestRaService, private route: Router
  ) { }
  /* istanbul ignore next */
  ngOnInit() {
    this.assessRequestRa.sirData.subscribe(res => {
      console.log(res);
      this.pureSirDetail = res;
    });
    this.dataService.getComponentNameSvc.subscribe((val) => {
      this.componentGlobal = val;
    });
    this.dataService.getruleToEdit().subscribe((value) => {
      if (value !== null) {
        this.editRuleFlow = true;
        Object.assign(sirDetailObj, value.substanceList[0]);
        this.pureSirDetail = sirDetailObj;
        if (value.substanceList[0].cfDetails[0] !== null) {
          value.substanceList[0].cfDetails[0].cfDisplayText = '[ Expressed As ' + value.substanceList[0].cfDetails[0].component + ' ] [ CF = ' + value.substanceList[0].cfDetails[0].cf + ' ]';
        }
        this.pureSirDetail.inciName = (value.substanceList[0].inciName) ? [value.substanceList[0].inciName] : [];
        this.pureSirDetail.cas = value.substanceList[0].cas ? [value.substanceList[0].cas] : [];
        this.pureSirDetail.parentCAS = value.substanceList[0].parentCAS ? [value.substanceList[0].parentCAS] : [];
        this.pureSirDetail.einecs = value.substanceList[0].einecs ? [value.substanceList[0].einecs] : [];
        this.pureSirDetail.ins = value.substanceList[0].ins ? [value.substanceList[0].ins] : [];
        this.pureSirDetail.eNumber = value.substanceList[0].eNumber ? [value.substanceList[0].eNumber] : [];
        this.pureSirDetail.synonyms = value.substanceList[0].synonymList ? [value.substanceList[0].synonymList] : [];
        pureRulesDetailsModel.selectedCF = value.substanceList[0].cfDetails[0];
      } else {
        this.editRuleFlow = false;
      }
    });

    this.dataService.getParallelRuleFormValues().subscribe((value) => {
      if (value !== null && this.componentGlobal === 'CreateInsertRule') {
        this.parallelRuleWorkflow = true;
      } else {
        this.parallelRuleWorkflow = false;
        // this.pureSirDetail = res;
        // this.pureSirDetail = sirDetailObj;
        // this.pureSirDetail = JSON.parse(JSON.stringify(sirDetailObj));
      }
    });
    this.dataService.getSirDetailForParallelRule().subscribe((result) => {
      if (result !== null && this.parallelRuleWorkflow) {
        this.parallelRuleWorkflow = true;
        this.pureSirDetail = result[0];
        this.pureSirDetail.selectedCF = result[0].selectedCF;
        pureRulesDetailsModel.selectedCF = result[0].cfDetails[0];
      } else {
        this.parallelRuleWorkflow = false;
        // this.pureSirDetail = sirDetailObj;
        // this.pureSirDetail = JSON.parse(JSON.stringify(sirDetailObj));/
      }
    });
    this.showMoreColumns = SIR_SHOW_MORE_COLUMNS;
    this.dataService.datarecipe.subscribe((values) => {
      this.requestId = values.requestDisplayID;
    });
    if (!this.parallelRuleWorkflow && !this.editRuleFlow) {
      recipeModel.formulationRequestDetails.forEach(p => {
        if (p.pureCode === sirDetailObj.pureCode) {
          this.pureSirDetail.selectedCF = p.userSelectedCFDetails.cfDisplayText;
          pureRulesDetailsModel.selectedCF = p.userSelectedCFDetails;
        }
      });
    }
  }

  closeShowMore() {
    this.showMoreDialog = false;
  }

  displayShowMoreDialog(pureCode) {
    this.currentRoute = this.route.url;
    this.showMoreDialog = true;
    let apiurl;
    apiurl = environment.APIFAsT + '/getPureLevelSpecDetails_WithoutReqId/' + recipeModel.marketDetails.marketName + '/' + pureCode;
    this.loadingMessage = 'Getting Pure Level Specification';
    this.loading = true;
    this.showMoreData = [];
    this.assessRequestRa.getData(apiurl).subscribe((response) => {
      this.loading = false;
      this.showMoreData = response;
    });
  }


  /* istanbul ignore next */
  onChangeCfDetails(event, sirDetail) {
    const selectedCFDropdown = sirDetail.cfDetails[event.target.selectedIndex];
    if (!this.parallelRuleWorkflow) {
      recipeModel.formulationRequestDetails.forEach(r => {
        if (r.pureCode === sirDetail.pureCode) {
          r.userSelectedCFDetails = new CFDetails();
          r.userSelectedCFDetails.cf = selectedCFDropdown.cf;
          r.userSelectedCFDetails.cfDisplayText = selectedCFDropdown.cfDisplayText;
          r.userSelectedCFDetails.component = selectedCFDropdown.component;
          r.userSelectedCFDetails.componentMolWeight = selectedCFDropdown.componentMolWeight;
          r.userSelectedCFDetails.expressedAs = selectedCFDropdown.expressedAs;
          r.userSelectedCFDetails.numberOfExpressedAs = selectedCFDropdown.numberOfExpressedAs;
          pureRulesDetailsModel.selectedCF = r.userSelectedCFDetails;
        }
      });
    } else {
      pureRulesDetailsModel.selectedCF = selectedCFDropdown;
    }
  }

}
