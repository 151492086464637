import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { ApiService } from '../common/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class WorklistService {
  private profileObs$: BehaviorSubject<any> = new BehaviorSubject(null);
  private recipeDataObs$: BehaviorSubject<any> = new BehaviorSubject(null);

  private requestStatus: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private apiService: ApiService) { }
  getWorklistApiData(apiurl): Observable<any> {
    return this.apiService.get(apiurl).pipe(timeout(300000));
  }
  selfAssignApi(apiurl, body): Observable<any> {
    return this.apiService.post(apiurl, body).pipe(timeout(300000));
  }
  putApiData(url, body?): Observable<any> {
    return this.apiService.put(url, body).pipe(timeout(300000));
  }
  getData(url): Observable<any> {
    return this.apiService.get(url).pipe(timeout(300000));
  }
  getDatawithCode(url, code): Observable<any> {
    return this.apiService.getwithcode(url, code);
  }
  postData(url, body): Observable<any> {
    return this.apiService.post(url, body).pipe(timeout(300000));
  }
  getProfileObs(): Observable<any> {
    return this.profileObs$.asObservable();
  }
  setProfileObs(profile: any) {
    this.profileObs$.next(profile);
  }
  setRecipeData(val: any) {
    this.recipeDataObs$.next(val);
  }
  getRecipeData(): Observable<any> {
    return this.recipeDataObs$.asObservable();
  }

  getRequestStatus(): Observable<any> {
    return this.requestStatus.asObservable();
  }

  setRequestStatus(status: string) {
    this.requestStatus.next(status);
  }

}
