import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { groupRulesDetailsModel } from 'src/app/common/models/GroupRulesDetailsModel';
import { pureRulesDetailsModel, ruleFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { GROUPTABLE_COLUMNS } from 'src/app/common/models/tableColumns';
import { DataService } from 'src/app/common/services/data.service';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-conflicting-rule-template',
  templateUrl: './conflicting-rule-template.component.html',
  styleUrls: ['./conflicting-rule-template.component.scss']
})
export class ConflictingRuleTemplateComponent implements OnInit, AfterViewInit {
  loading = false;
  ruleDisplayName;
  activeTabGR = 'rule-detail'; activeTab = 'substance-list';
  activeTabCR = '';
  selectedRuleDetails = []; selectedRuleTypeDetails = []; sirIdsforSubstanceDetails: string[] = []; uomList = [];
  groupDetails; conflictingGroupDetails; groupDetailsInitial; selectedFunctionValue;
  ruleTypeId; ruleType; selectedRows;
  saveAndCloseClicked: boolean; saveAndAddNewBtnClicked: boolean; isAllCfSelected: boolean;
  allFns = []; subGroupDetails = []; conflictingSubGroupDetails = []; groupColumns = []; groupData = [];
  attBtnClicked = false; showGroupDetails = false; showLimit = false; showGroup = true; parallelRuleWorkflow = false; readOnlyValue = true; isAllConflictingCfSelected = false;
  selectedMarket; division; tfcPath;
  loadingMsg = 'loading...'; maxval = 'Maximum';
  maxEqualityCondition = [{ value: '<' }, { value: '<=' }];
  equalityCondition = [{ value: 'isEqual' }];
  uomVal = 0;
  uomval;
  substanceGroupData: {
    groupId;
    groupName;
    groupType;
    substanceData: any;
  };
  isContinue = true; isGroupSelected = false; cancelClicked = false; hideGroupA = false; editRuleFlow = false; viewRuleFlow = false; disableAnyForAllUsers = true;
  ruleAdded; applicableGroupRuleTypesToDisplay; saveAndAddNewDialog;
  rpc; rulesListForGroup; ruleTypeRadioSelected; rtRadioSelected;
  ruleSource = ''; validityStartDate: any = ''; validityEndDate: any = ''; ruleDescription = '';
  yearAdopted: any;
  editRuleData;
  targetProducts = []; sourceProducts = []; confTargetProducts = []; confSourceProducts = [];
  ruleIdOnEdit; ruleVersion; selectedRowsRelated = [];
  showRelatedRulesPopup = false; viewRelbtn = false; ruleSaveConformationPopup = false;
  prepareRuleTableData = []; myWorklistTableColumns = [];
  showEmailConfirmationDialog = false;
  commentsToUser = ''; marketForModal = '';
  ruleComment: string;
  todayDate; defaultValidityFromDate; defaultValidityEndDate; maxequalityConditionSelected;
  constructor(
    private assessReqRAdataService: AssessRequestRaService, public snackBarGroup: MatSnackBar, public formBuilder: FormBuilder,
    public dataSvcObj: DataService, private datePipe: DatePipe, private worklistservice: WorklistService, private route: Router) { }
  ngAfterViewInit(): void {
    this.disableCheckbox(0);
  }
  disableCheckbox(i) {
    if (this.dataSvcObj.isfromSubstancesearch) {
      const picklistSource = document.getElementsByClassName('ui-picklist-source-wrapper').item(i) as HTMLElement;
      if (picklistSource) {
        picklistSource.style.display = 'none';
        const picklistButton = document.getElementsByClassName('ui-picklist-buttons').item(i) as HTMLElement;
        picklistButton.style.display = 'none';
        const picklistTarget = document.getElementsByClassName('ui-picklist-target-wrapper').item(i) as HTMLElement;
        picklistTarget.style.cssFloat = 'left';
      }
    }
  }
  /* istanbul ignore next */
  ngOnInit() {
    this.todayDate = new Date();
    this.defaultValidityFromDate = this.datePipe.transform(this.todayDate, 'longDate');
    this.defaultValidityEndDate = this.datePipe.transform(new Date('9999-12-31'), 'longDate');
    this.editRuleFlow = false;
    this.viewRuleFlow = false;
    if (this.activeTabGR === 'rule-detail') {
      document.getElementById('description').style.display = 'none';
    }
    this.dataSvcObj.getruleToEdit().subscribe((value) => {
      if (value !== null) {
        this.editRuleFlow = true;
        this.viewRelbtn = false;
        this.marketForModal = recipeModel.marketDetails.marketName;
        this.editRuleData = value;
        this.ruleDisplayName = value.ruleType;
        this.ruleTypeId = value.ruleTypeID;
        this.ruleSource = value.ruleSource;
        if (ruleFlags.viewRule) {
          this.defaultValidityFromDate = value.validityStartDate ? (this.datePipe.transform(new Date(this.datePipe.transform(value.validityStartDate, 'yyyy-MM-dd')), 'longDate')) : '';
          this.defaultValidityEndDate = value.validityDate ? (this.datePipe.transform(new Date(this.datePipe.transform(value.validityDate, 'yyyy-MM-dd')), 'longDate')) : '';
        }
        this.yearAdopted = value.yearAdopted ? (new Date(value.yearAdopted)).toUTCString() : '';
        this.ruleIdOnEdit = value.ruleId;
        this.ruleVersion = value.ruleVersion;
        this.selectedFunctionValue = value.groupFunction;
        pureRulesDetailsModel.Market = value.markets[0];
        const rpcVals = [];
        value.rpcs.forEach((val) => {
          const data = {
            id: val.rpcId,
            text: val.rpcName,
            rpcFullText: val.rpcFullText
          };
          rpcVals.push(data);
        });
        pureRulesDetailsModel.selectedParentRpcs = rpcVals;
        pureRulesDetailsModel.selectedRpcs = rpcVals;
        recipeModel.preparationMethod = value.preparationMethod;
        recipeModel.intendedUse = value.intendedUse;
        this.getWorklistColumns();
      } else {
        this.editRuleFlow = false;
      }
    });
    this.groupColumns = GROUPTABLE_COLUMNS;
    this.division = recipeModel.division;
    this.dataSvcObj.getGroupData.subscribe((groupData: any) => {
      this.groupDetailsInitial = groupData;
      if (this.editRuleFlow || this.viewRuleFlow) {
        this.groupDetails = Object.assign({}, this.groupDetailsInitial);
        const data = groupData.substanceData;
        this.sourceProducts = [];
        this.targetProducts = [];
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < this.editRuleData.substanceSubgroupsToSirList.length; j++) {
            if (data[i].sirId === this.editRuleData.substanceSubgroupsToSirList[j].substanceId) {
              const val = data[i].cfDetails.filter(x => x.cf === this.editRuleData.substanceSubgroupsToSirList[j].contributionFactor)[0];
              data[i].ContributionFactor = val.cfDisplayText;
              data[i].selectedCF = val;
              data[i].formattedCF = 'CF  ' + data[i].selectedCF.cf;
              this.targetProducts.push(data[i]);
            }
          }
        }
        this.sourceProducts = _.xor(data, this.targetProducts);
        this.subGroupDetails = this.targetProducts;

        this.conflictingGroupDetails = Object.assign({}, groupData.conflictingGroupData[0]);
        this.getFnList();
        const data2 = groupData.conflictingGroupData[0].substanceData;
        this.confSourceProducts = [];
        this.confTargetProducts = [];
        for (let i = 0; i < data2.length; i++) {
          for (let j = 0; j < this.editRuleData.conflictingSubstanceSubgroupsToSirList.length; j++) {
            if (data2[i].sirId === this.editRuleData.conflictingSubstanceSubgroupsToSirList[j].substanceId) {
              const val = data2[i].cfDetails.filter(x => x.cf === this.editRuleData.conflictingSubstanceSubgroupsToSirList[j].contributionFactor)[0];
              data2[i].ContributionFactor = val.cfDisplayText;
              data2[i].selectedCF = val;
              data2[i].formattedCF = 'CF  ' + data2[i].selectedCF.cf;
              this.confTargetProducts.push(data2[i]);
            }
          }
        }
        this.confSourceProducts = _.xor(data2, this.confTargetProducts);
        this.conflictingSubGroupDetails = this.confTargetProducts;
      } else {
        this.refreshTemplate();
        this.groupDetailsInitial = groupData;
        this.groupDetails = Object.assign({}, this.groupDetailsInitial);
      }
    });
    this.dataSvcObj.getSelectedGroupRuleType.subscribe((value) => {
      if (value.length !== 0) {
        this.selectedRuleTypeDetails[0] = value;
        this.ruleTypeId = this.selectedRuleTypeDetails[0].ruleTypeId;
        this.ruleDisplayName = this.selectedRuleTypeDetails[0].ruleTypeDisplayName;
        this.activeTabGR = 'rule-detail';
        document.getElementById('rule-detail').style.display = 'block';
        document.getElementById('description').style.display = 'none';
        this.ruleDescription = this.selectedRuleTypeDetails[0].ruleTypeDescription ? this.selectedRuleTypeDetails[0].ruleTypeDescription : null;
        this.getGroupDetail();
        this.getFnList();
        if (!this.editRuleFlow && !this.viewRuleFlow) {
          this.activeTabCR = '';
          this.groupDetails = this.groupDetailsInitial;
          this.refreshTemplate();
          this.ruleSource = '';
          this.validityStartDate = '';
          this.validityEndDate = '';
          this.yearAdopted = '';
          this.groupData = [];
        }
      }
    });
    if (ruleFlags.viewRule) {
      this.editRuleFlow = false;
      this.viewRuleFlow = true;
    } else if (ruleFlags.editRule) {
      this.editRuleFlow = true;
      this.viewRuleFlow = false;
    }
  }
  // get group details
  getGroupDetail() {
    this.dataSvcObj.getGroupData.subscribe((groupData) => {
      this.groupDetailsInitial = groupData;
      this.groupDetails = Object.assign({}, this.groupDetailsInitial);
    });
  }
  // get function list
  /* istanbul ignore next */
  getFnList() {
    const functionApiUrl = environment.APIFAsT + '/functions';
    this.assessReqRAdataService.getData(functionApiUrl).subscribe((response) => {
      this.allFns = response;
    });
  }
  /* istanbul ignore next */
  onSaveAndClose() {
    this.saveAndCloseClicked = true;
    this.onSave();
  }
  /* istanbul ignore next */
  onSaveandAddNew() {
    this.saveAndAddNewBtnClicked = true;
    this.onSave();
  }
  /* istanbul ignore next */
  onSave() {
    let ruleTypeId;
    let ruleType;
    const rpcData = [];
    const subgroupsubstancelist = [];
    const conflictingsubgroupsubstancelist = [];
    const mktList = [{ marketId: pureRulesDetailsModel.Market.marketId, marketName: pureRulesDetailsModel.Market.marketName }];
    ruleTypeId = this.ruleTypeId;
    ruleType = this.ruleDisplayName;

    if (groupRulesDetailsModel.CascadeRpc === 'Y') {
      pureRulesDetailsModel.selectedParentRpcs.forEach((value) => {
        const data = {
          rpcFullText: value.rpcFullText,
          rpcName: value.text,
          rpcId: value.id
        };
        rpcData.push(data);
      });
    } else {
      pureRulesDetailsModel.selectedRpcs.forEach((value) => {
        const data = {
          rpcFullText: value.rpcFullText,
          rpcName: value.text,
          rpcId: value.id
        };
        rpcData.push(data);
      });
    }
    // subgroup details for group
    this.subGroupDetails.forEach(subgroup => {
      subgroup.selectedCF = subgroup.cfDetails.filter(x => x.cfDisplayText === subgroup.ContributionFactor)[0];
      subgroupsubstancelist.push({
        SubstanceId: subgroup.sirId,
        ComponentName: subgroup.selectedCF.component,
        ContributionFactor: subgroup.selectedCF.cf,
        NumberOfExpressedAs: subgroup.selectedCF.numberOfExpressedAs,
        SubComponentMolWeight: subgroup.selectedCF.componentMolWeight,
      });
    });
    // conflicting subgroup details
    this.conflictingSubGroupDetails.forEach(conflictingsubgroup => {
      conflictingsubgroup.selectedCF = conflictingsubgroup.cfDetails.filter(x => x.cfDisplayText === conflictingsubgroup.ContributionFactor)[0];
      conflictingsubgroupsubstancelist.push({
        SubstanceId: conflictingsubgroup.sirId,
        ComponentName: conflictingsubgroup.selectedCF.component,
        ContributionFactor: conflictingsubgroup.selectedCF.cf,
        NumberOfExpressedAs: conflictingsubgroup.selectedCF.numberOfExpressedAs,
        SubComponentMolWeight: conflictingsubgroup.selectedCF.componentMolWeight,
      });
    });
    if (this.editRuleFlow || this.viewRuleFlow) {
      delete this.groupDetails.conflictingGroupData;
    }

    const savedData = [{
      RuleId: this.ruleIdOnEdit ? this.ruleIdOnEdit : 0,
      RuleVersion: this.ruleVersion ? this.ruleVersion : 0,
      RPCs: rpcData,
      MarketID: Number(pureRulesDetailsModel.Market.marketId),
      Market: pureRulesDetailsModel.Market.marketName,
      Markets: mktList,
      RuleTypeID: ruleTypeId,
      RuleType: ruleType,
      CascadeMarket: 'N',
      CascadeRpc: 'Y',
      UoMID: 2,
      UoM: 'N/A',
      TexualMinLimitText: '',
      TexualMaxLimitText: '',
      RuleDescription: '',
      RuleSource: this.ruleSource ? this.ruleSource : '',
      RuleSourceType: '',
      AdditionalStatement: '', // other requirements and restrictions
      PositiveStatement: '',
      MaxLimit: 0,
      MinLimit: '',
      RuleClaim: '',
      RuleSpecificCondions: '',
      GroupFunction: this.selectedFunctionValue,
      PreparationMethod: recipeModel.preparationMethod,
      IntendedUse: recipeModel.intendedUse,
      FunctionList: null, // used only for allowed function array of objects
      NegativeStatement: '',
      IsMandatorySubstance: null, // Y for substance mandatory rule
      WarningText: null, // used for warning rule, adulterated rule , phased out rule, other requirements and restrictions, Product Warnings
      SubstanceGroup: this.groupDetails,
      ConflictingSubstanceGroup: this.conflictingGroupDetails,
      SubstanceSubgroupsToSirList: subgroupsubstancelist,
      ConflictingSubstanceSubgroupsToSirList: conflictingsubgroupsubstancelist,
      yearAdopted: this.yearAdopted ? this.datePipe.transform(this.yearAdopted, 'yyyy-MM-dd') : null,
      ValidityStartDate: this.validityStartDate ? this.datePipe.transform(this.validityStartDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityFromDate, 'yyyy-MM-dd'),
      ValidityDate: this.validityEndDate ? this.datePipe.transform(this.validityEndDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityEndDate, 'yyyy-MM-dd'),
      Comments: this.ruleComment ? this.ruleComment : null,
      RuleFlow: this.parallelRuleWorkflow === true ? 'Parallel' : 'Request'
    }];
    if (!this.editRuleFlow) {
      const saveRuleToDbUrl = environment.APIFAsTRule + '/rulesList';
      this.loading = true;
      this.assessReqRAdataService.postData(saveRuleToDbUrl, savedData).subscribe((response) => {
        this.loading = false;
        if (response[0].ruleId !== 0) {
          this.ruleAdded = response;
          this.getGroupRuleDetailsFromDb();
          this.snackBarGroup.open('Group Rule added successfully with Rule Id:' + response[0].ruleId, 'close', {
            duration: 5000,
            panelClass: ['success']
          });
          if (this.saveAndAddNewBtnClicked) {
            setTimeout(() => {
              this.dataSvcObj.getApplicableRuleTypesObsv.subscribe((res) => {
                if (res.length !== 0) {
                  pureRulesDetailsModel.applicableRuleTypeList = res;
                  this.applicableGroupRuleTypesToDisplay = res;
                }
              });
              this.applicableGroupRuleTypesToDisplay = pureRulesDetailsModel.applicableRuleTypeList;
              this.saveAndAddNewDialog = true;
              this.saveAndAddNewBtnClicked = false;
            }, 3000);
          } else
            if (this.saveAndCloseClicked) {
              this.dataSvcObj.setExpandablePanel(2);
              this.saveAndCloseClicked = false;
            }
          this.refreshTemplate();
        } else {
          this.snackBarGroup.open('Rule could not be created.', 'close', {
            duration: 5000,
            panelClass: ['error']
          });
        }
      }, (error) => {
        this.loading = false;
        // this.handleErrors(error);
      });
    } else {
      const url = environment.APIFAsTRule + '/rules';
      this.loading = true;
      this.assessReqRAdataService.putData(url, savedData).subscribe((response) => {
        this.loading = false;
        if (response[0].ruleId !== 0) {
          this.ruleAdded = response;
          this.snackBarGroup.open('Rule updated successfully with Rule Id:' + response[0].ruleId + ' and Rule Version: ' + response[0].ruleVersion, 'close', {
            duration: 5000,
            panelClass: ['success']
          });
          this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
            this.route.navigate(['/rule-management/view-rules']);
          });
        } else {
          this.snackBarGroup.open('Rule could not be updated.', 'close', {
            duration: 5000,
            panelClass: ['error']
          });
        }
      }, (error) => {
        this.loading = false;
      });
    }
  }
  // get group rule details from DB
  /* istanbul ignore next */
  getGroupRuleDetailsFromDb() {
    this.rpc = [];
    if (pureRulesDetailsModel.selectedRpcs !== undefined) {
      if (pureRulesDetailsModel.selectedRpcs[0].text !== 'No Regulatory Product Class is Mapped') {
        pureRulesDetailsModel.selectedRpcs.forEach((rpcValue) => {
          this.rpc.push({
            rpcId: rpcValue.id,
            rpcName: rpcValue.text,
            rpcFullText: rpcValue.rpcFullText,
          });
        });
      }
    }
    const fetchRuleBody = {
      MarketList: [pureRulesDetailsModel.Market],
      RPCList: this.rpc,
      ruleClassifications: ['Substance Group Rules']
    };
    this.loading = true;
    this.loadingMsg = 'Fetching data from FAST Rule-Engine';
    const getruleapiurl = environment.APIFAsTRule + '/rules/rulesFilter';
    return new Promise<any>((resolve) => {
      this.assessReqRAdataService.postData(getruleapiurl, fetchRuleBody)
        .toPromise()
        .then(response => {
          this.loading = false;
          if (response !== null || response !== []) {
            resolve(response);
            const result = response.filter(x => x.ruleClassification === 'Substance Group Rules');
            for (const value of result) {
              value.ruleVerionToDisplay = 'V' + value.ruleVersion;
              if (value.cfDetails !== null && value.cfDetails !== undefined) {
                const cfValue = value.cfDetails;
                value.cf = cfValue.cf;
                value.cfSubstanceExpressedAs = cfValue.component;
              }
              value.warningText = (value.warningText !== null) ? value.warningText : ((value.warningStatement !== null) ? value.warningStatement : '');
              if (value.warningText !== null) {
                value.warningTextStripped = this.handleSpecialCharacters(value.warningText);
              } else {
                value.warningTextStripped = ' ';
              }
              value.otherRestrictionsAndRequirements = (value.otherRestrictionsAndRequirements !== null) ? value.otherRestrictionsAndRequirements : '';
              if (value.otherRestrictionsAndRequirements !== null) {
                value.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(value.otherRestrictionsAndRequirements);
              } else {
                value.otherRestrictionsAndRequirementsStripped = ' ';
              }
            }
            this.dataSvcObj.setRulesListForGroup(result);
            groupRulesDetailsModel.rulesListForGroups = result;
            this.rulesListForGroup.push(response.filter((value) => value.groupID === this.groupDetails.groupId));
          }
        }).catch(error => {
          this.loading = false;
        });
    });
  }
  handleSpecialCharacters(text) {
    return text.replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, '').replace(/&amp;/g, '&');
  }
  /* istanbul ignore next */
  onAddAttributeBtnClicked() {
    this.attBtnClicked = !this.attBtnClicked;
  }
  /* istanbul ignore next */
  onCancel() {
    if (!this.editRuleFlow) {
      this.refreshTemplate();
      this.dataSvcObj.setExpandablePanel(2);
    } else {
      this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
        this.route.navigate(['/rule-management/view-rules']);
      });
    }
  }
  /* istanbul ignore next */
  onNextButtonClick(tabname) {
    this.activeTab = tabname;
    if (tabname === 'contribution-factor') {
      if (this.editRuleFlow || this.viewRuleFlow) {
        this.showGroup = true;
        this.subGroupDetails = this.targetProducts;
      }
      this.subGroupDetails.forEach((subgroup) => {
        if (!subgroup.ContributionFactor) {
          if (subgroup.cfDetails && subgroup.cfDetails.length > 0) {
            subgroup.ContributionFactor = subgroup.cfDetails[0].cfDisplayText;
          }
        }
      });
    }
    if (tabname === 'contribution-factor-B') {
      if (this.editRuleFlow || this.viewRuleFlow) {
        this.conflictingSubGroupDetails = this.confTargetProducts;
      }
      this.conflictingSubGroupDetails.forEach((subgroup) => {
        if (!subgroup.ContributionFactor) {
          if (subgroup.cfDetails && subgroup.cfDetails.length > 0) {
            subgroup.ContributionFactor = subgroup.cfDetails[0].cfDisplayText;
          }
        }
      });
      this.isAllConflictingCfSelected = true;
    }
  }
  /* istanbul ignore next */
  onTabChange(activeTab) {
    this.activeTabGR = activeTab;
    if (activeTab === 'rule-detail') {
      this.hideGroupA = false;
      this.activeTabGR = 'rule-detail';
      if (this.conflictingSubGroupDetails.length === 0 && !this.showLimit) {
        this.activeTab = 'substance-list';
        document.getElementById('rule-detail').style.display = 'block';
        document.getElementById('rule-detail-B').style.display = 'none';
      } else {
        this.activeTab = 'substance-list-B';
        this.activeTabGR = 'rule-detail-B';
        this.showLimit = true;
        this.activeTabCR = 'rule-detail-B';
        document.getElementById('rule-detail').style.display = 'none';
        if (document.getElementById('rule-detail-B') !== null) {
          document.getElementById('rule-detail-B').style.display = 'block';
        }
      }
      document.getElementById('description').style.display = 'none';
      document.getElementById('ruleValidity').style.display = 'none';
    } else if (activeTab === 'description') {
      this.hideGroupA = true;
      document.getElementById('rule-detail').style.display = 'none';
      document.getElementById('description').style.display = 'block';
      document.getElementById('ruleValidity').style.display = 'none';
      if (document.getElementById('rule-detail-B') !== null) {
        document.getElementById('rule-detail-B').style.display = 'none';
      }
    } else if (activeTab === 'ruleValidity') {
      this.hideGroupA = true;
      document.getElementById('rule-detail').style.display = 'none';
      if (document.getElementById('rule-detail-B') !== null) {
        document.getElementById('rule-detail-B').style.display = 'none';
      }
      document.getElementById('description').style.display = 'none';
      document.getElementById('ruleValidity').style.display = 'block';
    }
    setTimeout(() => {
      this.disableCheckbox(0);
    }, 10);

  }
  /* istanbul ignore next */
  parseDate(dateString) {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }
  // tab change for group
  /* istanbul ignore next */
  onInnerTabChange(activeTab) {
    this.activeTab = activeTab;
    // setting default contribution factor
    if (activeTab === 'contribution-factor') {
      if (this.editRuleFlow || this.viewRuleFlow) {
        this.showGroup = true;
        this.subGroupDetails = this.targetProducts;
      }
      this.subGroupDetails.forEach((subgroup) => {
        if (!subgroup.ContributionFactor) {
          if (subgroup.cfDetails && subgroup.cfDetails.length > 0) {
            subgroup.ContributionFactor = subgroup.cfDetails[0].cfDisplayText;
          }
        }
      });
    }
  }
  // tab change for conflicting group
  /* istanbul ignore next */
  onInnerTabChangeB(activeTab) {
    this.activeTab = activeTab;
    if (activeTab === 'contribution-factor-B') {
      if (this.editRuleFlow || this.viewRuleFlow) {
        this.conflictingSubGroupDetails = this.confTargetProducts;
      }
      this.conflictingSubGroupDetails.forEach((consubgroup) => {
        if (!consubgroup.ContributionFactor) {
          if (consubgroup.cfDetails && consubgroup.cfDetails.length > 0) {
            consubgroup.ContributionFactor = consubgroup.cfDetails[0].cfDisplayText;
          }
        }
      });
      this.isAllConflictingCfSelected = true;
    }
  }
  // group list for selecting conflicting group
  /* istanbul ignore next */
  openGroupDetails() {
    this.isContinue = true;
    this.showGroupDetails = true;
    this.conflictingSubGroupDetails = [];
    if (!this.groupData || this.groupData.length === 0) {
      this.getGroups();
    }
  }
  // get groups
  /* istanbul ignore next */
  getGroups() {
    this.loading = true;

    const apiurl = environment.APIFAsTRule + '/rules/substancegroups/upd/' + this.division;
    this.assessReqRAdataService.getData(apiurl).subscribe((response) => {
      if (response) {
        response.forEach(group => {
          group.substanceIds.forEach(sub => {
            const temp = [];
            group.substances.forEach(element => {
              if (element.sirId === sub) {
                element.cfDetails.forEach(cf => {
                  temp.push(cf.cfDisplayText);
                });
                this.groupData.push({
                  groupId: group.groupId,
                  groupName: group.groupName,
                  groupType: group.groupType,
                  substanceId: sub,
                  pureCode: element.pureCode,
                  pureDescription: element.pureDescription,
                  substanceName: element.substanceName,
                  cas: element.cas,
                  parentCAS: element.parentCAS,
                  cfDetails: element.cfDetails,
                  cfData: temp.toString(),
                  ins: element.ins,
                  inciName: element.inciName,
                  einecs: element.einecs,
                });
              }
            });
          });
        });
        this.groupData[0].show = true;
        for (let i = 0; i < this.groupData.length - 1; i++) {
          for (let j = i + 1; j < this.groupData.length; j++) {
            const firstValue = this.groupData[i].groupId;
            const nextValue = this.groupData[j].groupId;
            if (firstValue === nextValue) {
              this.groupData[j].show = false;
            } else {
              this.groupData[j].show = true;
            }
          }
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    }, (error) => {
      this.loading = false;
    });
  }
  /* istanbul ignore next */
  continue() {
    this.showGroupDetails = false;
    this.showLimit = true;
    this.activeTabGR = '';
    this.activeTab = 'substance-list-B';
    this.activeTabCR = 'rule-detail-B';
    if (document.getElementById('rule-detail') !== null) {
      document.getElementById('rule-detail').style.display = 'none';
    }
    if (document.getElementById('rule-detail-B') !== null) {
      document.getElementById('rule-detail-B').style.display = 'block';
    }
    this.getUomList();
    setTimeout(() => {
      this.disableCheckbox(1);
    }, 10);

  }
  // remove conflicting group
  /* istanbul ignore next */
  removeGroupB() {
    this.showLimit = false;
    this.activeTab = 'substance-list';
    this.activeTabGR = 'rule-detail';
    this.activeTabCR = '';
    this.isAllConflictingCfSelected = false;
    this.hideGroupA = false;
    this.conflictingSubGroupDetails = [];
    document.getElementById('rule-detail').style.display = 'block';
    document.getElementById('description').style.display = 'none';
  }
  // select CF for group
  /* istanbul ignore next */
  onCfSelect() {
    let isSelected;
    this.subGroupDetails.forEach(subgroup => {
      if (!subgroup.ContributionFactor) {
        isSelected = true;
      }
    });
    if (!isSelected) {
      this.isAllCfSelected = true;
    }
  }
  // select cf for conflicting group
  /* istanbul ignore next */
  onConflictingCfSelect() {
    let isSelected;
    this.conflictingSubGroupDetails.forEach(subgroup => {
      if (!subgroup.ContributionFactor) {
        isSelected = true;
      }
    });
    if (!isSelected) {
      this.isAllConflictingCfSelected = true;
    }
  }
  /* istanbul ignore next */
  getUomList() {
    const url = environment.APIFAsT + '/uoms';
    this.assessReqRAdataService.getData(url).subscribe((response) => {
      if (response !== null) {
        this.uomList = response;
      }
    });
  }
  /* istanbul ignore next */
  onGroupSelect(rowValue) {
    this.isContinue = false;
    this.substanceGroupData = {
      groupId: rowValue.groupId,
      groupName: rowValue.groupName,
      groupType: rowValue.groupType,
      substanceData: []
    };
    this.groupData.forEach(groupsub => {
      if (groupsub.groupId === rowValue.groupId) {
        const subData = {
          sirId: groupsub.substanceId,
          pureCode: groupsub.pureCode,
          substanceName: this.changeToTitleCase(groupsub.substanceName),
          cas: groupsub.cas,
          parentCAS: groupsub.parentCAS,
          inciName: groupsub.inciName,
          ins: groupsub.ins,
          cfDetails: groupsub.cfDetails
        };
        this.substanceGroupData.substanceData.push(subData);
      }
    });
    this.conflictingGroupDetails = Object.assign({}, this.substanceGroupData);

    this.isGroupSelected = true;
  }
  /* istanbul ignore next */
  changeToTitleCase(word: string) {
    if (!word) {
      return word;
    }
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
  /* istanbul ignore next */
  ruleTypeRadioButtonChanged(event) {
    this.ruleTypeRadioSelected = true;
    this.rtRadioSelected = event.target.value;
  }
  /* istanbul ignore next */
  onContinue() {
    if (this.rtRadioSelected !== undefined) {
      const obj = _.filter(pureRulesDetailsModel.applicableRuleTypeList, (element) => element.ruleTypeDisplayName === this.rtRadioSelected);
      // tslint:disable-next-line: no-shadowed-variable
      const obj1 = obj.reduce((obj, newObj, i) => {
        newObj[i] = obj;
        return newObj;
      }, {});
      this.saveAndAddNewDialog = false;
      this.dataSvcObj.setSelectedGroupRuleType(obj1);
      this.dataSvcObj.setExpandablePanel(3);
    }
  }
  // refresh template
  /* istanbul ignore next */
  refreshTemplate() {
    this.subGroupDetails.forEach((element) => {
      this.groupDetails.substanceData.push(element);
    });
    this.conflictingSubGroupDetails.forEach((element) => {
      this.conflictingGroupDetails.substanceData.push(element);
    });
    this.subGroupDetails = [];
    this.conflictingSubGroupDetails = [];
    this.showLimit = false;
    this.activeTab = 'substance-list';
    this.activeTabGR = 'rule-detail';
    this.activeTabCR = '';
    this.groupData = [];
  }
  /* istanbul ignore next */
  dateValidity(val: any) {
    const data = val.target.value;
    const d = new Date(data);
    if (!!d.valueOf()) {
      const year = d.getFullYear();
      const len = year.toString().length;
      if (len > 3) {
        val.preventDefault();
      }
    }
  }
  /* istanbul ignore next */
  onClickedOutside() {
    this.attBtnClicked = false;
  }
  /* istanbul ignore next */
  checkRulesAvail() {
    this.viewRelbtn = true;
    this.prepareRuleTableData = [];
    this.selectedRows = null;
    this.loadingMsg = 'Fetching data from FAsT Rule-Engine';
    this.loading = true;
    const apiurl = environment.APIFAsTRule + '/rules/queryrelatedrequests';
    const body = [];
    this.showRelatedRulesPopup = true;
    body.push({
      RuleId: this.ruleIdOnEdit,
      RuleVersion: this.ruleVersion
    });
    this.assessReqRAdataService.postData(apiurl, body).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.prepareRuleTableData = response.map(ruleReqElem => {
          return {
            requestDisplayID: ruleReqElem.requestDisplayID,
            cucCode: ruleReqElem.cucCode,
            cucDescription: ruleReqElem.cucDescription,
            recipeType: ruleReqElem.recipeType,
            recipeStatus: ruleReqElem.recipeStatus,
            marketId: ruleReqElem.market.marketId,
            marketName: ruleReqElem.market.marketName,
            division: (ruleReqElem.tfc.tfcLevel === 5) ? ruleReqElem.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName : ruleReqElem.tfc.parentTFC.parentTFC.parentTFC.tfcName,
            rDFormulationCategory: (ruleReqElem.tfc.tfcLevel === 5) ? ruleReqElem.tfc.parentTFC.parentTFC.parentTFC.tfcName : ruleReqElem.tfc.parentTFC.parentTFC.tfcName,
            formulationType: (ruleReqElem.tfc.tfcLevel === 5) ? ruleReqElem.tfc.parentTFC.parentTFC.tfcName : ruleReqElem.tfc.parentTFC.tfcName,
            formulationClass: (ruleReqElem.tfc.tfcLevel === 5) ? ruleReqElem.tfc.parentTFC.tfcName : ruleReqElem.tfc.tfcName,
            formulationSubClass: (ruleReqElem.tfc.tfcLevel === 5) ? ruleReqElem.tfc.tfcName : '',
            preparationMethod: ruleReqElem.preparationMethod,
            intendedUse: ruleReqElem.intendedUse,
            multicomponent: ruleReqElem.isMultiComponent,
            requestStatus: ruleReqElem.requestStatus,
            ra: ruleReqElem.ra,
            allRa: ruleReqElem.allRA,
            submittedDate: ruleReqElem.submittedDate,
            submittedByUserId: ruleReqElem.submittedByUserId,
            submittedByUserName: ruleReqElem.submittedByUserName,
            comments: ruleReqElem.comments,
            assignedDate: ruleReqElem.assignedDate,
            inProgressRADate: ruleReqElem.inProgressRADate,
            inProgressRDDate: ruleReqElem.inProgressRNDDate,
            inProgressSIRDate: ruleReqElem.inProgressSIRDate,
            rejectedDate: ruleReqElem.rejectedDate,
            deletionDate: ruleReqElem.deletionDate,
            completedDate: ruleReqElem.completedDate,
            rdComment: ruleReqElem.comments,
            raComment: ruleReqElem.raComment,
            topTfcId: ruleReqElem.tfcid,
            allRAIDs: ruleReqElem.allRAIDs,
            raUserID: ruleReqElem.raUserIDred,
            divisionid: (ruleReqElem.tfc.tfcLevel === 5) ? ruleReqElem.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId : ruleReqElem.tfc.parentTFC.parentTFC.parentTFC.tfcId
          };
        });
      }
      const ele = document.getElementById('echeck') as HTMLInputElement;
      ele.checked = false;
      this.selectedRowsRelated = [];
    }, (error) => {
      this.loading = false;
    });
  }
  /* istanbul ignore next */
  getWorklistColumns() {
    const apiurl = environment.APIFAsT + '/getworklistcolumns/relatedrequest';
    this.worklistservice.getWorklistApiData(apiurl).subscribe((response) => {
      this.myWorklistTableColumns = response;
    });
  }
  /* istanbul ignore next */
  sendEmail() {
    this.showEmailConfirmationDialog = true;
    let reqId = '';
    let userDetails = '';
    const uDetail = [];
    const reDet = [];
    this.selectedRowsRelated.forEach(element => {
      reDet.push(element.requestDisplayID);
    });
    const distinctReqs = reDet.filter((n, i) => reDet.indexOf(n) === i);
    reqId = distinctReqs.join(' ');
    this.selectedRowsRelated.forEach(element => {
      uDetail.push(element.ra);
    });
    const distinctNames = uDetail.filter((n, i) => uDetail.indexOf(n) === i);
    userDetails = distinctNames.join(' ');
    this.commentsToUser = 'Regulatory rule/s for the request no. ' + reqId + ' will be updated/deleted by RA user ' + userDetails + ' .To get the most updated FAsT report, please submit a new request for the formulation. Ignore if the formulation is no longer valid.';
  }
  /* istanbul ignore next */
  sendEmailMessage() {
    const payLoad = {
      NotificationGroup: 'RDCOMPLETED',
      Subject: 'FAsT | Rules updated for these requests :',
      EmailBody: null,
      ToEmailList: '',
      FromEmail: null,
      CCEmailList: null,
      EmailType: 'html',
      UPD: this.ruleIdOnEdit.toString(),
      PureCodes: null,
      UserRequests: [],
      CUCDescription: null,
      Comments: this.commentsToUser
    };

    if (this.selectedRowsRelated.length > 0) {
      this.selectedRowsRelated.forEach(row => {
        const reqIds = this.selectedRowsRelated.filter(item => item.submittedByUserId === row.submittedByUserId).map(i => i.requestDisplayID);
        payLoad.UserRequests.push({
          userId: +row.submittedByUserId,
          requestIds: reqIds
        });
      });
    }

    if (payLoad.UserRequests.length > 0) {
      this.assessReqRAdataService.sendRulesEmailNotification(payLoad);
    }
    this.showEmailConfirmationDialog = false;
    this.showRelatedRulesPopup = false;
  }
  /* istanbul ignore next */
  onGroupSelectPopup(checkValue) {
    if (checkValue) {
      this.selectedRowsRelated = this.prepareRuleTableData;
    } else {
      this.selectedRowsRelated = [];
    }
  }
}
