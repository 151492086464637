import 'jstree';

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { pureRulesDetailsModel, ruleFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { sirDetailObj } from 'src/app/common/models/SirModel';
import { SUBSTANCE_RULE_COLUMNS, SUBSTANCE_RULE_COLUMNS_EXCEL } from 'src/app/common/models/tableColumns';
import { DataService } from 'src/app/common/services/data.service';
import { environment } from 'src/environments/environment';
import { AssessRequestRaService } from '../../assess-request-ra.service';

@Component({
  selector: 'app-substance-based',
  templateUrl: './substance-based.component.html',
  styleUrls: ['./substance-based.component.scss']
})
export class SubstanceBasedComponent implements OnInit {
  @ViewChild('onRuleHoverDiv') onRuleHoverDiv;
  @Output() sendRulesEvent = new EventEmitter<any>();
  @Input() updateRuleForm: FormGroup;
  @Input() isFileNameAmendDate = 'No';
  cascadeRPC = false;
  loading = false;
  unileverMarketObj = {
    clusterId: null,
    clusterName: null,
    marketFullText: null,
    marketId: '',
    marketName: 'Unilever',
    parentMarket: ''
  };
  rpcData; step = 0; rpcFullData; displayedTFCPath; overlayText = ''; selectedRuleBucket; selectedMarket;
  hideOverlay = true;
  selectedPureSirDetail; displayRuleType; counter = 0; loadingMsg;
  selectedRpcList = []; marketsToSelect = []; selectedRuleDetails = []; ruleColumns = []; ruleColumnsExcel = []; ruleDisplayData = [];
  rpc = []; sirIdList = []; positiveSubstance = []; bannedSubstance = []; componentDataforRPC = [];
  step1ValidationChecked = false; deletePopup = false; isMultiComponentAvail = false; btnDisable = true;
  dataSelectedDelete; multiComponentSelected; componentGlobal;
  parallelRuleWorkflow = false; editRuleFlow = false; viewRuleFlow = false; refreshGrid = false;
  deleteSubRuleComment: string;
  warningTextOpen: boolean;
  orrTextOpen: boolean;
  tablewarningComment: any;
  commentRD = '';
  tableORRComment: any;
  isColumnsHasHyperLinks: string;
  // pureSirDetail ;
  // nonparalelsir = [];
  // isInsertPrallelRule = true;
  constructor(
    private assessRequestRa: AssessRequestRaService, public matSnackBar: MatSnackBar, public dataServiceObj: DataService) { }

  ngOnInit() {
    // this.assessRequestRa.sirData.subscribe(res => {
    //   this.pureSirDetail = res;
    //   this.isInsertPrallelRule = false;
    // });
    this.editRuleFlow = false;
    this.viewRuleFlow = false;
    this.ruleColumns = SUBSTANCE_RULE_COLUMNS;
    this.ruleColumnsExcel = SUBSTANCE_RULE_COLUMNS_EXCEL;
    this.isColumnsHasHyperLinks = 'Yes';
    this.dataServiceObj.getComponentNameSvc.subscribe((val) => {
      this.componentGlobal = val;
    });
    this.dataServiceObj.getruleToEdit().subscribe((value) => {
      if (value !== null) {
        this.editRuleFlow = true;
      } else {
        this.editRuleFlow = false;
      }
    });
    if (ruleFlags.viewRule) {
      this.editRuleFlow = false;
      this.viewRuleFlow = true;
    } else if (ruleFlags.editRule) {
      this.editRuleFlow = true;
      this.viewRuleFlow = false;
    }
    this.dataServiceObj.getParallelRuleFormValues().subscribe((value) => {
      if (value !== null && this.componentGlobal === 'CreateInsertRule') {
        this.parallelRuleWorkflow = true;
        sessionStorage.setItem('parallelRuleWorkflow', 'yes');
      } else {
        this.parallelRuleWorkflow = false;
        sessionStorage.setItem('parallelRuleWorkflow', 'no');
      }
    });
    this.selectedRuleBucket = localStorage.getItem('selectedRuleBucket');
    if (recipeModel.isMultiComponent === 'Y') {
      this.isMultiComponentAvail = true;
    } else {
      this.isMultiComponentAvail = false;
    }
    this.displayedTFCPath = recipeModel.tfcFullText;
    this.selectedPureSirDetail = sirDetailObj;
    pureRulesDetailsModel.SirId = this.selectedPureSirDetail.sirId;
    pureRulesDetailsModel.PureCode = this.selectedPureSirDetail.pureCode;
    this.dataServiceObj.setRulesListForPure([]);
    this.dataServiceObj.getRulesListForEachPure.subscribe((resultSet) => { //  on adding rules update the rule display table
      if (resultSet.length !== 0) {
        this.ruleDisplayData = resultSet;
      }
    });
    this.dataServiceObj.expandedPanel.subscribe((result) => { // setting the expanded panel
      if (result !== '') {
        const step = result;
        this.setStep(Number(step));
      }
    });

    this.dataServiceObj.getComponentObs().subscribe((val) => {
      if (val === null) {
        this.btnDisable = true;
      } else {
        this.componentDataforRPC = val;
        this.btnDisable = false;
      }
    });
    this.dataServiceObj.getSelectedMultiComponent().subscribe((val) => {
      this.multiComponentSelected = val;
    });
    if (!this.editRuleFlow || !this.viewRuleFlow) {
      if (this.ruleColumns[this.ruleColumns.length - 1].field === 'delete') {
        this.ruleColumns.pop();
      }
    } else {
      if (this.ruleColumns[this.ruleColumns.length - 1].field === 'cutOffConcentration') {
        const obj = { field: 'delete', header: '', width: '40px' };
        this.ruleColumns.push(obj);
      }
    }
  }
  setStep(index: number) {
    this.step = index;
    if (this.step === 1) {
      this.checkValidation(0);
    }
    if (this.step === 2) {
      let varrpcitem = 0;
      varrpcitem = pureRulesDetailsModel.selectedRpcs.length;
      if (varrpcitem > 1) {
        this.loading = false;
        this.matSnackBar.open('Invalid Input Error: More than one child RPC level under same parent is selected. Please select one child RPC level to save rule applicability.', 'close', {
        duration: 3000,
        panelClass: ['error']
      });
        this.setStep(1);
    } else {
      if (this.checkValidation(1) === true) {
        this.getRuleDetailsFromDb();
      }
    }
    }
    if (this.step === 3) {
      this.checkValidation(2);
    }
  }

  nextStep() {
    let varrpcitem = 0;
    varrpcitem = pureRulesDetailsModel.selectedRpcs.length;
    if (varrpcitem > 1) {
      pureRulesDetailsModel.selectedRpcs = [];
      this.loading = false;
      this.matSnackBar.open('Invalid Input Error: More than one child RPC level under same parent is selected. Please select one child RPC level to save rule applicability.', 'close', {
        duration: 3000,
        panelClass: ['error']
      });
    } else {
      this.btnDisable = true;
      if (this.step === 0) {
        this.checkValidation(0);
      }
      if (this.step === 1) {
        if (this.checkValidation(1) === true) {
          this.getRuleDetailsFromDb();
        }
      }
      if (this.step === 2) {
        this.checkValidation(2);
      }
      this.step++;
    }
  }
  selectedRuleType(value) {
    this.selectedRuleDetails = value;
    this.displayRuleType = value.ruleTypeDisplayName;
    this.dataServiceObj.setSelectedRuleType(this.selectedRuleDetails);
    this.step = 3;
    this.setStep(this.step);
  }

  getRuleDetailsFromDb() {
    this.rpc = [];
    if (pureRulesDetailsModel.selectedRpcs !== undefined && pureRulesDetailsModel.selectedRpcs.length !== 0) {
      if (pureRulesDetailsModel.selectedRpcs[0].text !== 'No Regulatory Product Class is Mapped') {
        pureRulesDetailsModel.selectedRpcs.forEach((rpcValue) => {
          this.rpc.push({
            rpcId: rpcValue.id,
            rpcName: rpcValue.text,
            rpcFullText: rpcValue.rpcFullText,
          });
        });
      }
    }
    const fetchRuleBody = {
      MarketList: [pureRulesDetailsModel.Market],
      RPCList: this.rpc,
      SIRIds: [pureRulesDetailsModel.SirId],
    //   SIRIds: this.parallelRuleWorkflow === true ? [sirDetailObj.sirId] : this.nonparalelsir,
    // //  SIRIds: this.parallelRuleWorkflow === true && this.isInsertPrallelRule === true ? [sirDetailObj.sirId] : this.nonparalelsir,
      ruleClassifications: ['Substance Based Rules']
    };

    this.loading = true;
    const getruleapiurl = environment.APIFAsTRule + '/rules/rulesFilter';
    this.assessRequestRa.postData(getruleapiurl, fetchRuleBody).subscribe((response) => {
      if (response !== null || response !== []) {
        this.ruleDisplayData = response;
        this.ruleDisplayData.forEach(data => {
          data.isAllergen = data.isAllergen === 'Y' ? 'Y' : '';
        });
        this.ruleDisplayData = _.filter(this.ruleDisplayData, (val) => val.ruleClassification === 'Substance Based Rules');
        for (const value of this.ruleDisplayData) {
          value.ruleVerionToDisplay = 'V' + value.ruleVersion;
          value.warningText = (value.warningText !== null) ? value.warningText : ((value.warningStatement !== null) ? value.warningStatement : '');
          value.originalWarningText = value.warningText;
          value.warningTextStripped = this.handleSpecialCharacters(value.originalWarningText);
          const warningLinkRuleData = this.assessRequestRa.hyperLinksToSheet(value.warningText);
          value.warningText1 = warningLinkRuleData[0] !== undefined ? warningLinkRuleData[0] : '';
          value.warningText2 = warningLinkRuleData[1] !== undefined ? warningLinkRuleData[1] : '';
          value.warningText3 = warningLinkRuleData[2] !== undefined ? warningLinkRuleData[2] : '';
          value.warningText4 = warningLinkRuleData[3] !== undefined ? warningLinkRuleData[3] : '';
          value.warningText5 = warningLinkRuleData[4] !== undefined ? warningLinkRuleData[4] : '';
          value.warningText6 = warningLinkRuleData[5] !== undefined ? warningLinkRuleData[5] : '';
          value.warningText7 = warningLinkRuleData[6] !== undefined ? warningLinkRuleData[6] : '';
          value.warningText = this.changeComment(value.warningText);
          value.otherRestrictionsAndRequirements = (value.otherRestrictionsAndRequirements !== null) ? value.otherRestrictionsAndRequirements : '';
          value.originalOtherRestrictionsAndRequirements = value.otherRestrictionsAndRequirements;
          const orrLinkRuleData = this.assessRequestRa.hyperLinksToSheet(value.otherRestrictionsAndRequirements);
          value.ORR1 = orrLinkRuleData[0] !== undefined ? orrLinkRuleData[0] : '';
          value.ORR2 = orrLinkRuleData[1] !== undefined ? orrLinkRuleData[1] : '';
          value.ORR3 = orrLinkRuleData[2] !== undefined ? orrLinkRuleData[2] : '';
          value.ORR4 = orrLinkRuleData[3] !== undefined ? orrLinkRuleData[3] : '';
          value.ORR5 = orrLinkRuleData[4] !== undefined ? orrLinkRuleData[4] : '';
          value.ORR6 = orrLinkRuleData[5] !== undefined ? orrLinkRuleData[5] : '';
          value.ORR7 = orrLinkRuleData[6] !== undefined ? orrLinkRuleData[6] : '';
          value.otherRestrictionsAndRequirements = this.changeComment(value.otherRestrictionsAndRequirements);
          value.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(value.originalOtherRestrictionsAndRequirements);

          if (value.cfDetails !== null && value.cfDetails !== undefined) {
            const cfValue = value.cfDetails;
            value.cf = cfValue.cf;
            value.cfSubstanceExpressedAs = cfValue.component;
          }
        }
        this.dataServiceObj.setRulesListForPure(this.ruleDisplayData);
        this.sendRulesEvent.emit(this.ruleDisplayData);
      }
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }
  handleSpecialCharacters(text) {
    if (text) {
      return text.replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, '').replace(/&amp;/g, '&');
    }
  }
  /* istanbul ignore next */
  deleteRule(val) {
    this.deletePopup = true;
    this.dataSelectedDelete = val;
  }
  checkValidation(step) {
    if (step === 0) {
      if (pureRulesDetailsModel.selectedCF !== undefined && pureRulesDetailsModel.selectedCF !== null) {
        return true;
      } else {
        return false;
      }
    }
    if (step === 1) {
      if ((pureRulesDetailsModel.Market !== undefined) && (pureRulesDetailsModel.selectedRpcs !== undefined && pureRulesDetailsModel.selectedRpcs.length !== 0 && this.checkValidation(0) === true)) {
        if (pureRulesDetailsModel.selectedRpcs[0].text !== 'No Regulatory Product Class is Mapped') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    if (step === 2) {
      if (pureRulesDetailsModel.applicableRuleTypeList !== undefined && pureRulesDetailsModel.applicableRuleTypeList.length !== 0 && this.checkValidation(0) === true && this.checkValidation(1) === true) {
        return true;
      } else {
        return false;
      }
    }

  }
  /* istanbul ignore next */
  confirmDelete() {
    const urlForDelete = environment.APIFAsTRule + '/DeleteRules';
    const data = [
      {
        RuleTypeID: this.dataSelectedDelete.ruleTypeID,
        RuleType: this.dataSelectedDelete.ruleType,
        RuleId: this.dataSelectedDelete.ruleId,
        RuleVersion: this.dataSelectedDelete.ruleVersion,
        Comments: this.deleteSubRuleComment
      }];
    const fetchRuleBody = {
      MarketList: [pureRulesDetailsModel.Market],
      RPCList: this.rpc,
      SIRIds: [sirDetailObj.sirId],
      ruleClassifications: ['Substance Based Rules']
    };
    this.loading = true;
    this.loadingMsg = 'Deleting...';
    this.assessRequestRa.putData(urlForDelete, data).subscribe((responseData) => {
      if (responseData !== null) {
        this.deleteSubRuleComment = null;
        this.matSnackBar.open('Rule is deleted successfully ', 'close', {
          duration: 3000,
          panelClass: ['success']
        });
        this.loadingMsg = 'Fetching data from FAST Rule-Engine';
        const getruleapiurl = environment.APIFAsTRule + '/rules/rulesFilter';
        this.assessRequestRa.postData(getruleapiurl, fetchRuleBody).subscribe((response) => {
          if (response.length > 0) {
            this.ruleDisplayData = response;
            this.ruleDisplayData = _.filter(this.ruleDisplayData, (val) => val.ruleClassification === 'Substance Based Rules');
            for (const value of this.ruleDisplayData) {
              value.ruleVerionToDisplay = 'V' + value.ruleVersion;
              if (value.warningText === null) {
                value.warningText = value.warningStatement;
              }
              if (value.cfDetails !== null && value.cfDetails !== undefined) {
                const cfValue = value.cfDetails;
                value.cf = cfValue.cfDisplayText;
                value.cfSubstanceExpressedAs = cfValue.expressedAs;
              }
            }
            this.dataServiceObj.setRulesListForPure(this.ruleDisplayData);
            pureRulesDetailsModel.rulesListForSpecificPure = this.ruleDisplayData;
            this.loading = false;
          } else {
            this.ruleDisplayData = response;
            this.dataServiceObj.setRulesListForPure(response);
            pureRulesDetailsModel.rulesListForSpecificPure = response;
            this.loading = false;
          }
        }, (error) => {
          this.loading = false;
        });
      }
    }, (error) => {
      this.matSnackBar.open('Rule deletion Failed', 'close', {
        duration: 3000,
        panelClass: ['error']
      });
      this.loading = false;
    });

  }
  /* istanbul ignore next */
  mapRPCTOComponent() {
    this.loading = true;
    const URL = environment.APIFAsTRule + '/multicomponentMaps';
    const data = pureRulesDetailsModel.selectedLeafRpcs;
    const responseData = [];
    if (data.length > 0) {
      for (const value of data) {
        const obj = {
          marketId: pureRulesDetailsModel.Market.marketId,
          marketName: pureRulesDetailsModel.Market.marketName,
          multiComponentId: this.multiComponentSelected.multiComponentID,
          multiComponentName: this.multiComponentSelected.multiComponentName,
          rpcName: value.text,
          rpcid: value.id
        };
        responseData.push(obj);
      }
      this.assessRequestRa.postData(URL, responseData).subscribe((response) => {
        if (response !== null) {
          this.loading = false;
          this.dataServiceObj.setRefreshGridVal(true);
          this.matSnackBar.open('RPC mapping is done successfully', 'close', {
            duration: 3000,
            panelClass: ['success']
          });
        }
      }, (error) => {
        this.loading = false;
        this.dataServiceObj.setRefreshGridVal(false);
        this.matSnackBar.open('RPC Mapping already exists', 'close', {
          duration: 3000,
          panelClass: ['error']
        });
      });
    } else {
      this.loading = false;
      this.dataServiceObj.setRefreshGridVal(false);
      this.matSnackBar.open('No leaf node is selected', 'close', {
        duration: 3000,
        panelClass: ['success']
      });
    }

  }
  showOverlay(htmlContent) {
    this.overlayText = htmlContent;
    this.hideOverlay = false;
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
  hideOverlayPanel() {
    this.hideOverlay = true;
  }
  /* istanbul ignore next */
  openComment(data) {
    this.warningTextOpen = true;
    const mydata = data.originalWarningText;
    this.tablewarningComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  /* istanbul ignore next */
  openCommentORR(data) {
    this.orrTextOpen = true;
    const mydata = data.originalOtherRestrictionsAndRequirements;
    this.tableORRComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  closePopup() {
    this.warningTextOpen = false;
  }
  closePopupORR() {
    this.orrTextOpen = false;
  }
  /* istanbul ignore next */
  changeComment(input) {
    const strValue = input;
    const ar = strValue ? strValue.match(/<a.*?<\/a>/g) : null;
    let commentwarning = '';
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentwarning = 'Click Here';
    } else {
      commentwarning = input;
    }
    return commentwarning;
  }
}
