import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FUNCTION_MASTER_COLUMNS } from 'src/app/common/models/tableColumns';
import { environment } from 'src/environments/environment';
import { AdministrationService } from '../administration.service';
@Component({
  selector: 'app-function-master',
  templateUrl: './function-master.component.html',
  styleUrls: ['./function-master.component.scss']
})
export class FunctionMasterComponent implements OnInit {
  loading = true;
  loadingFunctionMasterListMessage;
  exportDialog = false;
  filteredRows: any = [];
  selectedRows: any = [];
  functionMasterListColumns;
  functionMasterListData = [];
  addFunctionMaster = false;
  editFunctionMaster = false;
  fastFunctionId;
  fastFunctionName;
  model: any = {};
  modelEdit: any = {};
  userId: any;
  userDetails: any;
  enterFunctionName = false;
  isFunctionEmpty = false;
  status = [];
  selectedStatus: any;
  prevName: any;
  prevStatus: any;
  constructor(public functionMasterservice: AdministrationService, public matSnackBar: MatSnackBar) { }
  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('userRolesPermissions'));
    this.userId = this.userDetails.userId;
    this.status = [
      { name: 'Active', code: 'Y' },
      { name: 'In-Active', code: 'N'},
    ];
    this.getFunctionMasterList();
  }
  getFunctionMasterList() {
    this.functionMasterListColumns = FUNCTION_MASTER_COLUMNS;
    this.loading = true;
    this.loadingFunctionMasterListMessage = 'Getting Function Master Details';
    const apiurl = environment.APIFAsT + '/getFunctions';
    this.functionMasterservice.getFunctionMasterlistApiData(apiurl).subscribe((response1) => {
      this.loading = false;
      if (response1.length !== 0) {
        this.functionMasterListData = response1.map(respElem => {
          return {
            functionId: respElem.functionId,
            functionName: respElem.functionName,
            isActive: respElem.isActive === 'Y' ? 'Active' : 'In-Active',
            createdByUserId: respElem.createdByUserId,
            createdDate: respElem.createdDate,
            createdUserName: respElem.createdUserName,
            modifiedByUserId: respElem.modifiedByUserId,
            modifiedDate: respElem.modifiedDate,
            modifiedUserName: respElem.modifiedUserName,
          };
        });
      } else {
        this.functionMasterListData = [];
      }
    }, (error) => {
      this.loading = false;
    });
  }
  addFunctionMasterData() {
    this.model.fastFunctionName = ' ';
    this.addFunctionMaster = true;
  }
  saveFunctionMasterData() {
    if (this.model.fastFunctionName != null && this.model.fastFunctionName.trim() !== '') {
      if (this.isDuplicateFunction(this.model.fastFunctionName, 'No')) {
        const data = {
          FunctionName: this.model.fastFunctionName,
          CreatedByUserId: this.userId,
          IsActive: 'Y',
          ModifiedByUserId: this.userId,
        };
        const apiurl = environment.APIFAsT + '/addfunction';
        this.functionMasterservice.postFunctionMasterData(apiurl, data).subscribe((response) => {
          this.loading = false;
          this.getFunctionMasterList();
          this.matSnackBar.open('Function Name added successfully ', 'close', {
            duration: 3000,
            panelClass: ['success']
          });
        }, (error) => {
          this.addFunctionMaster = false;
          this.getFunctionMasterList();
        });
        this.addFunctionMaster = false;
        this.getFunctionMasterList();
      } else {
        this.enterFunctionName = true;
        return false;
      }
    } else {
      this.isFunctionEmpty = true;
      return false;
    }
  }
  onSelectType(event) {
    if (event) {
      this.selectedStatus = event;
    }
  }
  editFunctionMasterData(functionMasterList) {
    this.enterFunctionName = false;
    this.editFunctionMaster = true;
    this.modelEdit.fastFunctionId = functionMasterList._selection.functionId;
    this.prevName = this.modelEdit.fastFunctionName = functionMasterList._selection.functionName;
    this.prevStatus = functionMasterList._selection.isActive === 'Active' ? 'Y' : 'N';
    this.selectedStatus = this.status.filter(f => f.name === functionMasterList._selection.isActive)[0];
  }
  updateFunctionMasterData() {
    if (this.modelEdit.fastFunctionName !== null && this.modelEdit.fastFunctionName.trim() !== '') {
      if (this.isDuplicateFunction(this.modelEdit.fastFunctionName, 'Yes')) {
        const data = {
          FunctionId: this.modelEdit.fastFunctionId,
          FunctionName: this.modelEdit.fastFunctionName,
          IsActive: this.selectedStatus.code,
          ModifiedByUserId: this.userId,
        };
        const apiurl = environment.APIFAsT + '/updatefunction';
        this.functionMasterservice.postFunctionMasterData(apiurl, data).subscribe((response) => {
          this.loading = false;
          this.getFunctionMasterList();
          this.matSnackBar.open('Function details updated successfully ', 'close', {
            duration: 3000,
            panelClass: ['success']
          });
        }, (error) => {
          this.editFunctionMaster = false;
          this.getFunctionMasterList();
        });
        this.editFunctionMaster = false;
        this.getFunctionMasterList();
      } else {
        this.enterFunctionName = true;
        return false;
      }
    } else {
      this.isFunctionEmpty = true;
      return false;
    }
  }
  isDuplicateFunction(functionName, isEdit) {
    if (isEdit === 'No') {
      const checkDupilcate = this.functionMasterListData.find(x => x.functionName.trim().toLowerCase() === functionName.trim().toLowerCase());
      if (checkDupilcate === null || checkDupilcate === undefined) {
        return true;
      } else {
        return false;
      }
    }
    if (isEdit === 'Yes') {
      const checkDupilcate = this.functionMasterListData.find(x => x.functionName.trim().toLowerCase() === functionName.trim().toLowerCase());
      if (this.selectedStatus.code === this.prevStatus && functionName.trim().toLowerCase() === this.prevName.toLowerCase()) {
        return true;
      } else if (checkDupilcate === null || checkDupilcate === undefined) {
        return true;
      } else if (checkDupilcate !== null && functionName.trim().toLowerCase() === this.prevName.toLowerCase() && checkDupilcate.isActive !== this.selectedStatus.code) {
        return true;
      } else {
        return false;
      }
    }
  }

  showExportDialog() {
    this.exportDialog = true;
  }

  onFilter(event) {
    this.filteredRows = event.filteredValue;
  }
 /* istanbul ignore next */
  onClearFilters(functionMasterList: any) {
    functionMasterList.reset();
    this.filteredRows = [];
    for (const value of this.functionMasterListColumns) {
      if (value.field !== 'check') {
        const colname = value.field + '_val';
        if ((<HTMLInputElement> document.getElementById(colname)) && (<HTMLInputElement> document.getElementById(colname)).value) {
          (<HTMLInputElement> document.getElementById(colname)).value = '';
        }
      }
    }
  }
}

