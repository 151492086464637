import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { PROPERTY_COLUMNS, SERVING_DETAILS_COLUMNS, DOSAGE_DETAILS_COLUMNS, PROPERTY_COLUMNS_VIEW } from 'src/app/common/models/PureFnModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { COMPOSITION_COLUMNS, COMPOSITION_COLUMNS_NO, MULTI_COMPONENT, FRAGRANCE_COLUMNS, FRAGRANCE_REGIONAL_COLUMNS2, FRAGRANCE_REGIONAL_COLUMNS3, FRAGRANCE_EXCEL_COLUMNS, PROJECT_COLUMNS } from 'src/app/common/models/tableColumns';
import { DataService } from 'src/app/common/services/data.service';
import { environment } from 'src/environments/environment';
import { requestFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { AssessmentRequestService } from '../assess-requests.service';
import { AuthorizationDataService } from '../../common/Authorization/authorization-data.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-create-request',
  templateUrl: './create-request.component.html',
  styleUrls: ['./create-request.component.scss']
})
export class CreateRequestComponent implements OnInit {
  divisionData = '';
  validityStartDate: any = '';
  @ViewChild('InputForm2') InputForm2: NgForm;
  @ViewChild('ProjectForm') ProjectForm: NgForm;
  @ViewChild(MatExpansionPanel) multiExpansionpanel: MatExpansionPanel;
  name = 'Angular ';
  editor = ClassicEditor;
  isNextBtnEnabled = false;
  isSaveBtnEnabled = false;
  isConfirmBtnDisabled = true;
  isClearBtnDisabled = true;
  isPopupyesClicked = false;
  isAddMarketBtnDisabled = true;
  isSaveBtnClicked = true;
  tempkeydate: Date;
  isSaveaddBtclick = true;
  iseditrequestflag = false;
  commentRD: any;
  deleteIconPopup = false;
  keydatetodisplay = '';
  validityareatodisplay = '';
  loadingMessage = '';
  plmvalidityarea = {
    validityAreaCode: '',
    validityDescription: '',
    validity: '',
  };
  formAssess = {
    isMultiComponent: 'Y',
    selectedMultiComponents: [],
    regionalComplianceLevel2ModelList: [],
    regionalComplianceLevel3ModelList: [],
    ifraComplianceModelList: [],
    uom: null,
    recipeStatus: '',
    plmkeydate: '',
    plmvalidityarea: this.plmvalidityarea,
    description: '',
    recipeType: [],
    servingSize: [],
    prepMethod: [],
    tfcList: null,
    formulationSubClass: [],
    division: '',
    formulationClass: [],
    formulationType: [],
    firstRequest: [],
    dosageUom: '',
    formulationCategory: '',
    formulationRequestDetails: [],
    intendedUse: '',
    fc: '',
    keyDate: [],
    market: null,
    alternate: '',
    version: '',
    selectedMarkets: [],
    dosageForMarkets: [],
    multiComponent: [],
    compositionData: [],
    projectdetailData: [],
    comments: '',
    multiDataPrevNext: []
  };
  ProjDetail: any = {
    RequestId: '',
    Market: null,
    ProjectName: '',
    ProjectDescription: '',
    ProjectType: [],
    Brand: [],
    OtherBrandDescription: '',
    OtherProjectDescription: '',
    otherManufacturingSiteDescription: '',
    LaunchTimeLine: '',
    ManufactureSite: [],
    ManufactureCountry: {},
    comments: ''
  };
  tempManuSite = [];
  ProjDetailList = [];
  deleterowdata = [];
  activeTab = 'nav-formulation-details';
  star = 'fa fa-fw fa-search';
  loading = false;
  fetchingdata = false;
  readOnlyValue = true;
  cancelClicked = false;
  isDialogOpen = false;
  projtypeothoptionflg = false;
  brandothoptionflg = false;
  mansiteothoptionflg = false;
  isManufacturesiteDialogOpen = false;
  isprojectTypeDialogOpen = false;
  isCommentDialogOpen = false;
  cucaltver = '';
  columnLength = 800;
  responsedataMarket; formulationCode;
  tfc1Id; tfc2Id; tfc3Id; tfc4Id; tfc5Id; keyDate;
  ingredientData = [];
  cols = [];
  servingDetailsColumns = [];
  compositionDetailsColumns = [];
  projectDetailsColumns = [];
  fragranceDetailsColumns = [];
  fragranceComplianceLevel2Column = [];
  fragranceComplianceLevel3Column = [];
  functionListVals = [];
  compositionData = [];
  multicompositionData = [];
  projectDetailsData = [];
  segregatecompositionData = [];
  compositionDataTemp = [];
  reqData = [];
  productData = [];
  fragranceData = [];
  projectData = [];
  regionalComplianceLevel2Data = [];
  regionalComplianceLevel3Data = [];
  filteredFunctionListVals = [];
  functionVal: any;
  isFormulationOpen = true;
  isMultiOpen = false;
  isCompositionOpen = false;
  isPropertyOpen = false;
  isFragrancetyOpen = false;
  multiPanelDisable = true;
  responsedataUOM = [];
  selectedValue = '1';
  type = 0;
  rdoNo;
  options;
  responsedataMultiComp = [];
  filteredDataMultiComp = [];
  multicomponentDatacolumns = [];
  responseModel = [];
  dosageMarkets = [];
  complianceMarket = [];
  complianceMarketData = [];
  submitMultiComponent = [];
  multicomponentValid = false;
  disableNext = true;
  selectedMultiComponents = [];
  segregateOrderMultiComponent = [];
  filteredSearchMulti: any[] = [];
  sortAsPerOrder = [];
  specificationIds = [];
  segregateOrder = [];
  sortedCompositionData = [];
  ingDetails = [];
  hideDosageServicePanel = false;
  isDosage: boolean;
  dosageServingTitle = 'Dosage/Serving Details';
  calculatedRecipeQty;
  isOnCompositionClicked = false;
  invalidCalc = false;
  gpcContacts = [];
  regionalComplianceData = [{ item1: '', item2: '', item3: '' }];
  fragranceDetailArray = [];
  loadingData: any;
  editRequestFlag;
  infraData = [];
  regionalData2 = [];
  regionalData3 = [];
  fragranceMultipleArrayExcelRowsData = [];
  fragranceMultipleArrayExcelColumns = [];
  showFragranceMenu: boolean;
  fragranceMessage = '';
  date: any;
  brandType = [];
  manufatureCoutryData = [];
  flagmancountryselected = true;
  manufatureSiteData = [];
  projectType = [];
  editMarkteId: any;
  editflg = false;
  selectedDate;
  todayDate;
  defaultValidityFromDate;
  defaultValidityEndDate;
  ankerCount: any;
  userId: any;
  userDetails: any;
  config = {
    toolbar: [
      'link'
    ],
    placeholder: 'Please enter Comments',
    link: {
      addTargetToExternalLinks: true,
      decorators: [
        {
          mode: '',
          label: '',
          attributes: {
            target: '_blank',
          }
        }
      ]
    }
  };
  config1 = {
    toolbar: [
    ],
  };
  tableComment: any;
  addColumn = false;
  fromPrev = false;
  isCompositionPanel = false;
  constructor(
    public assessService: AssessmentRequestService,
    public matSnackBar: MatSnackBar,
    private routersvc: Router,
    private dataTrans: DataService,
    private AuthDataService: AuthorizationDataService,
    private datePipe: DatePipe
  ) {
  }
  /* istanbul ignore next */
  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('userRolesPermissions'));
    if (this.userDetails) {
      this.userId = this.userDetails.userId;
    }
    this.todayDate = new Date();
    this.defaultValidityFromDate = this.datePipe.transform(this.todayDate, 'longDate');
    this.defaultValidityEndDate = this.datePipe.transform(new Date('9999-12-31'), 'longDate');
    this.getfragranceFlag();
    this.infraData = [];
    this.tempManuSite = [];
    this.projtypeothoptionflg = false;
    this.brandothoptionflg = false;
    this.mansiteothoptionflg = false;
    this.regionalData2 = [];
    this.regionalData3 = [];
    this.isAddMarketBtnDisabled = false;
    this.isConfirmBtnDisabled = true;
    this.isClearBtnDisabled = true;
    this.compositionDetailsColumns = COMPOSITION_COLUMNS_NO;
    this.fragranceDetailsColumns = FRAGRANCE_COLUMNS;
    this.fragranceComplianceLevel2Column = FRAGRANCE_REGIONAL_COLUMNS2;
    this.fragranceComplianceLevel3Column = FRAGRANCE_REGIONAL_COLUMNS3;
    this.fragranceMultipleArrayExcelColumns = FRAGRANCE_EXCEL_COLUMNS;
    if (PROJECT_COLUMNS.length < 10) {
      PROJECT_COLUMNS.push({ field: 'Actions', header: 'Actions', width: '115px' });
    }
    this.projectDetailsColumns = PROJECT_COLUMNS;
    this.formAssess = JSON.parse(localStorage.getItem('FormObj'));
    if (this.formAssess.projectdetailData) {
      this.ProjDetailList = this.formAssess.projectdetailData;
    }
    if (this.formAssess.isMultiComponent === 'Y') {
      this.selectedMultiComponents = this.formAssess.selectedMultiComponents;
    }
    this.type = (this.formAssess.isMultiComponent === 'Y') ? 1 : 0;
    this.dataTrans.compositiondatapreview.subscribe((values) => {
      this.compositionData = values;
    });
    this.dataTrans.propertydatapreview.subscribe((values) => {
      this.productData = values;
    });

    this.dataTrans.fragrancedatapreview.subscribe((values) => {
      this.fragranceData = values;
    });
    this.dataTrans.projectdatapreview.subscribe((values) => {
      this.projectData = values;
    });
    this.editRequestFlag = requestFlags.EditRequest;
    if (this.editRequestFlag) {
      this.getFormulationData();
      this.setCompositionDataBack();
      this.regionalData2 = this.formAssess.regionalComplianceLevel2ModelList;
      this.regionalData3 = this.formAssess.regionalComplianceLevel3ModelList;
      this.fragranceMultipleArrayExcelRowsData = [];
      this.fragranceMultipleArrayExcelRowsData.push(this.formAssess.ifraComplianceModelList);
      this.fragranceMultipleArrayExcelRowsData.push(this.regionalData2);
      this.fragranceMultipleArrayExcelRowsData.push(this.regionalData3);
      requestFlags.EditRequest = false;
    }
    if (localStorage.getItem('selectedStepprev') && localStorage.getItem('selectedIndex') !== '0') {
      this.fromPrev = true;
      this.getFormulationData();
      this.setCompositionDataBack();
      this.regionalData2 = this.formAssess.regionalComplianceLevel2ModelList;
      this.regionalData3 = this.formAssess.regionalComplianceLevel3ModelList;
      this.fragranceMultipleArrayExcelRowsData = [];
      this.fragranceMultipleArrayExcelRowsData.push(this.formAssess.ifraComplianceModelList);
      this.fragranceMultipleArrayExcelRowsData.push(this.regionalData2);
      this.fragranceMultipleArrayExcelRowsData.push(this.regionalData3);

    } else {
      this.clearProjectFields();
      if (this.editRequestFlag !== true) {
        this.ProjDetailList = [];
      }
    }
    localStorage.setItem('selectedStepprev', '');
    localStorage.setItem('selectedIndex', '');
    this.formAssess.uom = [];
    this.getMarketData();
    this.getUOMData();
    this.getGpcContacts();
    this.multicomponentDatacolumns = MULTI_COMPONENT;
    this.getMultiComponentData();
    if (recipeModel.formulationRequestDetails === undefined || recipeModel.functionList === undefined) {
      this.getFormulationData();
    } else if (recipeModel.formulationRequestDetails !== undefined || recipeModel.functionList !== undefined) {
      this.assignValuesFromModel();
    }
    if (this.formAssess.isMultiComponent === undefined) {
      this.formAssess.isMultiComponent = 'N';
    }
    this.countSelectedComponents();
    this.enableOrDisableNextButton();
  }

  assignValuesFromModel() {
    this.cucaltver = recipeModel.cucCode + '/' + recipeModel.cucAlt + '/' + recipeModel.cucVer;
    this.formAssess.recipeStatus = (recipeModel.recipeStatus == null) ? '' : recipeModel.recipeStatus;
    this.formAssess.description = recipeModel.cucDescription;
    this.formAssess.recipeType = recipeModel.recipeType;
    this.keyDate = recipeModel.keyDate;
    this.formAssess.servingSize = recipeModel.servingSize !== null ? recipeModel.servingSize : this.formAssess.servingSize;
    this.formAssess.prepMethod = recipeModel.preparationMethod;
    this.formAssess.tfcList = recipeModel.tfc;
    this.addColumn = true;
    if (recipeModel.tfc.tfcLevel === 5) {
      this.formAssess.formulationSubClass = recipeModel.tfc.tfcName;
      this.tfc5Id = recipeModel.tfc.tfcId;
      const tfc5parent = recipeModel.tfc.parentTFC;
      if (tfc5parent.tfcLevel === 4) {
        this.formAssess.formulationClass = tfc5parent.tfcName;
        this.tfc4Id = tfc5parent.tfcId;
        const tfc4parent = tfc5parent.parentTFC;
        if (tfc4parent.tfcLevel === 3) {
          this.formAssess.formulationType = tfc4parent.tfcName;
          this.tfc3Id = tfc4parent.tfcId;
          const tfc3parent = tfc4parent.parentTFC;
          if (tfc3parent.tfcLevel === 2) {
            this.formAssess.formulationCategory = tfc3parent.tfcName;
            this.tfc2Id = tfc3parent.tfcId;
            const tfc2parent = tfc3parent.parentTFC;
            if (tfc2parent.tfcLevel === 1) {
              this.divisionData = tfc2parent.tfcName;
              this.formAssess.division = tfc2parent.tfcName;
              this.tfc1Id = tfc2parent.tfcId;
              // tslint:disable-next-line: triple-equals
              if (this.formAssess.division == 'BPC') {
                this.hideDosageServicePanel = true;
                // tslint:disable-next-line: triple-equals
              } else if (this.formAssess.division == 'F&R') {
                this.hideDosageServicePanel = false;
                this.addColumn = true;
                this.isDosage = false;
                this.dosageServingTitle = 'Serving Details';
                // tslint:disable-next-line: triple-equals
              } else if (this.formAssess.division == 'HC') {
                this.hideDosageServicePanel = false;
                this.isDosage = true;
                this.dosageServingTitle = 'Dosage Details';
              }
            }
          }
        }
      }
    }
    /* istanbul ignore next */
    this.formAssess.firstRequest = recipeModel.firstRequest;
    this.formAssess.intendedUse = 'Any';
    this.formAssess.keyDate = recipeModel.keyDate;
    this.compositionData = recipeModel.formulationRequestDetails;
    this.compositionData[0].show = true;
    this.compositionData[0].composition = false;
    this.compositionData[0].componentSet = false;
    for (let i = 0; i < this.compositionData.length - 1; i++) {
      for (let j = i + 1; j < this.compositionData.length; j++) {
        const firstValue = this.compositionData[i].specID;
        const nextValue = this.compositionData[j].specID;
        if (firstValue === nextValue) {
          this.compositionData[j].show = false;
        } else {
          this.compositionData[j].show = true;
        }
        this.compositionData[j].composition = false;
        this.compositionData[j].componentSet = false;
      }
    }
    this.calculatedRecipeQty = _.sumBy(this.compositionData, 'recipeQuantity');
    this.calculatedRecipeQty = _.round(this.calculatedRecipeQty, 12);
    if (parseFloat(this.calculatedRecipeQty) <= 0 || (parseFloat(this.calculatedRecipeQty) < 99.99) || (parseFloat(this.calculatedRecipeQty) > 100.01)) {
      this.invalidCalc = true;
      // this.matSnackBar.open('The sum total composition is not in valid range 99.99% - 100.002%. Request cannot be submitted. RMIX in PLM has to be replaced with reaction loss ingredients. Kindly correct the same in PLM and then raise a request.', 'close', {
      this.matSnackBar.open('The sum total composition is not in valid range 99.99% - 100.01%. Request cannot be submitted. Please adjust the composition in PLM and then raise a request.', 'close', {
        duration: 20000,
        panelClass: ['error']
      });
      this.editflg = true;
      this.disableNext = true;
      this.isAddMarketBtnDisabled = true;
      this.step = -1;
    } else {
      this.invalidCalc = false;
    }
    this.functionListVals = recipeModel.functionList;
    const fnValue = { functionName: 'Any' };
    const index = this.functionListVals.findIndex(d => d.functionName === 'Any');
    if (index === -1) {
      this.functionListVals.push(fnValue);
    }
    this.multicompositionData = this.compositionData;
    this.segregatecompositionData = this.compositionData;
    this.projectDetailsData = this.projectData;
    this.setrowdata();
  }

  searchMultiComponent(event) {
    this.filteredSearchMulti = [];
    const query = event.query;
    for (const value of this.responsedataMultiComp) {
      if (value.multiComponentName.toLowerCase().includes(query.toLowerCase())) {
        this.filteredSearchMulti.push(value.multiComponentName);
      }
    }
    this.filteredDataMultiComp = this.filteredSearchMulti;
  }

  getMultiComponentData() {
    const apiurl = environment.APIFAsT + '/multicomponentlist';
    this.assessService.getData(apiurl).subscribe((response1) => {
      // tslint:disable-next-line: prefer-const
      let multcomponentList = response1.filter(item => item.isActive === 'Y');
      this.responsedataMultiComp = multcomponentList.sort((a, b) => (a.multiComponentName.toLowerCase() > b.multiComponentName.toLowerCase()) ? 1 : -1);
    }, (error) => {
    });
  }
  getfragranceFlag() {
    const apiurl = environment.APIFAsT + '/appinfo/featureflagfragrance';
    this.assessService.getData(apiurl).subscribe((res) => {
      if (res.FeatureFlagFragrance) {
        this.showFragranceMenu = res.FeatureFlagFragrance.toLocaleLowerCase().replace('"', '').replace('"', '') === 'true' ? true : false;
      }
    });
  }
  // tslint:disable-next-line: member-ordering
  step = 1;
  setStep(index: number) {
    this.step = index;
  }

  openMulti() {
    this.multiExpansionpanel.disabled = false;
    this.multiExpansionpanel.toggle();
  }
  getMarketData() {
    const apiurl = environment.APIFAsT + '/markets';
    this.assessService.getData(apiurl).subscribe((response1) => {
      this.responsedataMarket = response1;
    }, (error) => {
    });
  }
  getUOMData() {
    const apiurl = environment.APIFAsT + '/uoms';
    this.assessService.getData(apiurl).subscribe((response1) => {
      this.responsedataUOM = response1;
    }, (error) => {
    });
  }
  getGpcContacts() {
    const apiurl = environment.APIFAsT + '/appinfo/gpccontacts';
    this.assessService.getData(apiurl).subscribe((response) => {
      this.gpcContacts = response;
    });
  }
  onUoMChange() {
    if (this.formAssess.uom) {
      this.formAssess.dosageUom = this.formAssess.uom['uoMsymbol'];
    } else {
      this.formAssess.dosageUom = '';
    }
  }

  /* istanbul ignore next */
  getFormulationData() {
    // this.tempkeydate = this.datePipe.transform(this.formAssess.plmkeydate,'dd-MM-yyyy');
    // this.setCompositionDataBack();
    const varmonth = this.formAssess.plmkeydate.substring(3, 5);
    const vardate = this.formAssess.plmkeydate.substring(0, 2);
    const varyear = this.formAssess.plmkeydate.substring(6, 10);
    const datestring = varyear + varmonth + vardate;
    if (this.formAssess.fc !== null) {
      this.formulationCode = this.formAssess.fc.toString();
    }
    if (this.formAssess.plmvalidityarea === null) {
      this.validityareatodisplay = 'Default Validity Area';
      this.formAssess.plmvalidityarea.validityDescription = 'Default Validity Area';
    } else {
      if (this.formAssess.plmvalidityarea.validityDescription === undefined) {
        this.validityareatodisplay = 'Default Validity Area';
      } else {
        this.validityareatodisplay = this.formAssess.plmvalidityarea.validityDescription;
      }
    }
    this.keydatetodisplay = datestring.substring(6, 8) + '/' + datestring.substring(4, 6) + '/' + datestring.substring(0, 4);
    // Get Recipe Data URL
    if (this.formulationCode !== null && this.formulationCode !== '') {
      this.loading = true;
      const apiurl = environment.APIFAsT + '/formulations/' + this.formulationCode + '/' + this.formAssess.alternate + '/' + this.formAssess.version + '/recipieType/' + this.formAssess.recipeType + '/plmvalidityarea/' + this.formAssess.plmvalidityarea.validityDescription + '/plmkeydate/' + datestring;
      this.assessService.getData(apiurl).subscribe((response) => {
        if (response && response.recipeType === this.formAssess.recipeType) {
          this.ingDetails = response.formulationRequestDetails.filter((el) => el.ingType === 'ZING_FRAGR');
          this.loading = false;
          this.cucaltver = response.cucCode + '/' + response.cucAlt + '/' + response.cucVer;
          this.formAssess.recipeStatus = (response.recipeStatus == null) ? '' : response.recipeStatus;
          this.ingredientData = response;
          this.formAssess.description = response.cucDescription;
          this.formAssess.recipeType = response.recipeType;
          this.keyDate = response.keyDate;
          this.formAssess.servingSize = response.servingSize;
          this.formAssess.prepMethod = response.preparationMethod;
          this.formAssess.tfcList = response.tfc;
          this.formAssess.ifraComplianceModelList = response.ifraComplianceModelList;
          this.infraData = response.ifraComplianceModelList;
          this.fragranceMultipleArrayExcelRowsData.push(this.formAssess.ifraComplianceModelList);
          if (response.tfc.tfcLevel === 5) {
            this.formAssess.formulationSubClass = response.tfc.tfcName;
            this.tfc5Id = response.tfc.tfcId;
            const tfc5parent = response.tfc.parentTFC;
            if (tfc5parent.tfcLevel === 4) {
              this.formAssess.formulationClass = tfc5parent.tfcName;
              this.tfc4Id = tfc5parent.tfcId;
              const tfc4parent = tfc5parent.parentTFC;
              if (tfc4parent.tfcLevel === 3) {
                this.formAssess.formulationType = tfc4parent.tfcName;
                this.tfc3Id = tfc4parent.tfcId;
                const tfc3parent = tfc4parent.parentTFC;
                if (tfc3parent.tfcLevel === 2) {
                  this.formAssess.formulationCategory = tfc3parent.tfcName;
                  this.tfc2Id = tfc3parent.tfcId;
                  const tfc2parent = tfc3parent.parentTFC;
                  if (tfc2parent.tfcLevel === 1) {
                    this.divisionData = tfc2parent.tfcName;
                    this.formAssess.division = tfc2parent.tfcName;
                    this.tfc1Id = tfc2parent.tfcId;
                    // tslint:disable-next-line: triple-equals
                    if (this.formAssess.division == 'BPC') {
                      this.hideDosageServicePanel = true;
                      // tslint:disable-next-line: triple-equals
                    } else if (this.formAssess.division == 'F&R') {
                      this.hideDosageServicePanel = false;
                      this.isDosage = false;
                      this.dosageServingTitle = 'Serving Details';
                      // tslint:disable-next-line: triple-equals
                    } else if (this.formAssess.division == 'HC') {
                      this.hideDosageServicePanel = false;
                      this.isDosage = true;
                      this.dosageServingTitle = 'Dosage Details';
                    }
                  }
                }
              }
            }
          }
          this.formAssess.firstRequest = response.firstRequest;
          this.formAssess.intendedUse = 'Any';
          this.formAssess.keyDate = response.keyDate;
          this.formAssess.servingSize = response.servingSize;
          if (!this.editRequestFlag) {
            if (!this.fromPrev) {
              this.compositionData = response.formulationRequestDetails;

              if (this.compositionData.length <= 0) {
                this.invalidCalc = true;
                // this.matSnackBar.open('The sum total composition is not in valid range 99.99% - 100.002%. Request cannot be submitted. RMIX in PLM has to be replaced with reaction loss ingredients. Kindly correct the same in PLM and then raise a request.', 'close', {
                this.matSnackBar.open('No data in PLM', 'close', {
                  duration: 20000,
                  panelClass: ['error']
                });
                this.disableNext = true;
                this.isAddMarketBtnDisabled = true;
                this.editflg = true;
                this.step = -1;
              }
              this.compositionData[0].show = true;
              this.compositionData[0].composition = false;
              this.compositionData[0].componentSet = false;
              for (let i = 0; i < this.compositionData.length - 1; i++) {
                for (let j = i + 1; j < this.compositionData.length; j++) {
                  const firstValue = this.compositionData[i].specID;
                  const nextValue = this.compositionData[j].specID;
                  if (firstValue === nextValue) {
                    this.compositionData[j].show = false;
                  } else {
                    this.compositionData[j].show = true;
                  }
                  this.compositionData[j].composition = false;
                  this.compositionData[j].componentSet = false;
                }
              }
              this.calculatedRecipeQty = _.sumBy(this.compositionData, 'recipeQuantity');
              this.calculatedRecipeQty = _.round(this.calculatedRecipeQty, 12);
              if (parseFloat(this.calculatedRecipeQty) <= 0 || ((parseFloat(this.calculatedRecipeQty) < 99.99) || (parseFloat(this.calculatedRecipeQty) > 100.01))) {
                this.invalidCalc = true;
                // this.matSnackBar.open('The sum total composition is not in valid range 99.99% - 100.002%. Request cannot be submitted. RMIX in PLM has to be replaced with reaction loss ingredients. Kindly correct the same in PLM and then raise a request.', 'close', {
                this.matSnackBar.open('The sum total composition is not in valid range 99.99% - 100.01%. Request cannot be submitted. Please adjust the composition in PLM and then raise a request.', 'close', {
                  duration: 20000,
                  panelClass: ['error']
                });
                this.disableNext = true;
                this.isAddMarketBtnDisabled = true;
                this.editflg = true;
                this.step = -1;
              } else {
                this.invalidCalc = false;
              }
              this.productData = response.productPropertiesInputs;
              this.fragranceData = response.ifraComplianceModelList;
              this.functionListVals = response.functionList;
              const fnValue = { functionName: 'Any' };
              const index = this.functionListVals.findIndex(d => d.functionName === 'Any');
              if (index === -1) {
                this.functionListVals.push(fnValue);
              }
              for (const value of this.compositionData) {
                if (value.lastUsedFunction !== null && value.lastUsedFunction.functionName !== 'Any') {
                  value.substanceFunctionInput = value.lastUsedFunction.functionName; // included to display recommended function
                } else if (value.lastUsedFunction === null) {
                  value.substanceFunctionInput = 'Any';
                }
              }
              this.dataTrans.setCompositionData(this.compositionData);
              this.dataTrans.setPropertyData(this.productData);
            }
          }
          localStorage.setItem('FormObj', JSON.stringify(this.formAssess));
          this.divisionData = this.formAssess.division;
          this.formAssess.division === 'F&R' ? this.cols = PROPERTY_COLUMNS_VIEW : this.cols = PROPERTY_COLUMNS;
          this.formAssess.division === 'HC' ? this.servingDetailsColumns = DOSAGE_DETAILS_COLUMNS : this.servingDetailsColumns = SERVING_DETAILS_COLUMNS;
          Object.assign(recipeModel, response);
          recipeModel.formulationRequestDetails = this.compositionData;
          if (this.multicompositionData.length === 0) {
            this.multicompositionData = this.compositionData;
          } else if (this.formAssess.multiDataPrevNext) {
            this.multicompositionData = this.formAssess.multiDataPrevNext;

          }
          if (this.formAssess.isMultiComponent === 'Y') {
            this.submitMultiComponent = this.submitMultiComponent === null || this.submitMultiComponent.length === 0 ? this.formAssess.multiComponent : this.submitMultiComponent;
          }
          this.segregatecompositionData = this.compositionData;
          this.fetchingdata = false;
          this.showFragranceMessage(this.infraData, this.regionalData2, this.regionalData3);
          this.getBrandData();
          this.getProjectTypeData();
          this.getManufatureCountry();
          this.setrowdata();
        } else {
          this.loading = false;
          this.matSnackBar.open('Selected RECIPETYPE [' + this.formAssess.recipeType + '] does not    match with the actual value in PLM [' + response.recipeType + '].           Please select ' + response.recipeType + ' to proceed.', 'close', {
            duration: 20000,
            panelClass: ['error']
          });
          localStorage.setItem('selectedStep', '0');
          this.dataTrans.setSelectedStep(0);
          this.routersvc.navigate(['/assess-requests/select-recipe']);
        }
      }, /* istanbul ignore next */
        (error) => {
          this.fetchingdata = false;
          this.loading = false;
          if (error.status === 401) {
            this.matSnackBar.open('Authentication Failed', 'close', {
              duration: 4000,
              panelClass: ['error']
            });
          } else if (error.error.message === undefined) {
            this.matSnackBar.open('System error occured. Please contact administrator.', 'close', {
              duration: 4100,
              panelClass: ['error']
            });
            localStorage.setItem('selectedStep', '0');
            this.dataTrans.setSelectedStep(0);
            this.routersvc.navigate(['/assess-requests/select-recipe']);
          } else {
            this.matSnackBar.open(error.error.message, 'close', {
              duration: 15000,
              panelClass: ['error']
            });
            // adding if statement to restrict navigation if conversion failed.
            if (error.error.message.includes('PureCode:')) {
              localStorage.setItem('selectedStep', '1');
              this.dataTrans.setSelectedStep(1);
              this.routersvc.navigate(['/assess-requests/create-request']);
            } else {
              localStorage.setItem('selectedStep', '0');
              this.dataTrans.setSelectedStep(0);
              this.routersvc.navigate(['/assess-requests/select-recipe']);
            }
          }
        });
    } else {
      this.fetchingdata = false;
    }
  }
  onTabClick(activeTab) {
    this.activeTab = activeTab;
  }

  /* istanbul ignore next */
  removeDuplicates(array, ruleID) {
    const lookup = {};
    array.forEach(element => {
      lookup[element[ruleID]] = element;
    });
    return Object.keys(lookup).map(prop => lookup[prop]);
  }
  /* istanbul ignore next */
  onMarketChange() {
    if (this.ProjDetail.Market !== null || this.ProjDetail.Market !== undefined) {
      this.isConfirmBtnDisabled = true;
      this.isClearBtnDisabled = true;
      this.disableNext = false;
    } else {
      this.isConfirmBtnDisabled = false;
      this.isClearBtnDisabled = false;
    }
    this.dosageMarkets = [];
    this.complianceMarketData = [];
    // replacing market selection on change event with Save button click after Project Detail fields incorporation
    // this.formAssess.selectedMarkets.forEach(market => {
    this.formAssess.projectdetailData.forEach(market => {
      this.dosageMarkets.push({
        marketId: market.marketId,
        marketName: market.marketName,
        dosage: '',
        dosageUnit: '',
        servingSize: ''
      });
      this.complianceMarket.push({
        marketId: market.marketId,
        marketName: market.marketName
      });
    });
    const today = new Date();
    let month = today.getMonth() + 1;
    month = ((month < 10 ? '0' : '') + month) as any;
    const day: string = ((today.getDate() < 10 ? '0' : '') + today.getDate()) as any;
    const year = today.getFullYear();
    /* istanbul ignore if */
    if ((this.complianceMarket.length > 0) && (this.ingDetails.length > 0) && this.showFragranceMenu && (this.formAssess.division === 'BPC' || this.formAssess.division === 'HC')) {
      this.ingDetails = this.removeDuplicates(this.ingDetails, 'ingID');
      this.ingDetails.forEach(ingdetail => {
        this.complianceMarketData.push({
          ingID: ingdetail.ingID,
          ingDescription: ingdetail.ingDescription,
          ingQuantity: ingdetail.ingQuantity,
          ingUoM: ingdetail.ingUoM,
          ingType: ingdetail.ingType,
          RequestMarkets: this.complianceMarket,
          userEmailid: localStorage.getItem('UserEmailAddress'),
          keyDate: year + '' + month + '' + day,
          upd: this.formAssess.division,
          formulationCategory: this.formAssess.formulationCategory,
          formulationType: this.formAssess.formulationType,
          formulationClass: this.formAssess.formulationClass,
          formulationSubClass: this.formAssess.formulationSubClass
        });
      });
      const apiurl = environment.APIFAsT + '/fragrance/regionalcompliancedata';
      this.loadingData = 'Fetching data from PLM';
      this.loading = true;
      this.assessService.getRegionalComplianceData(apiurl, this.complianceMarketData).subscribe((response) => {
        this.regionalComplianceData = response;
        this.loading = false;
        this.formAssess.regionalComplianceLevel2ModelList = response.item2;
        this.formAssess.regionalComplianceLevel3ModelList = response.item3;
        this.regionalData2 = response.item2;
        this.regionalData3 = response.item3;
        this.fragranceMultipleArrayExcelRowsData = [];
        this.fragranceMultipleArrayExcelRowsData.push(this.formAssess.ifraComplianceModelList);
        this.fragranceMultipleArrayExcelRowsData.push(response.item2);
        this.fragranceMultipleArrayExcelRowsData.push(response.item3);
        this.showFragranceMessage(this.formAssess.ifraComplianceModelList, this.regionalData2, this.regionalData3);
        localStorage.setItem('FormObj', JSON.stringify(this.formAssess));
        this.complianceMarket = [];
      });
    } else {
      this.formAssess.regionalComplianceLevel2ModelList = [];
      this.formAssess.regionalComplianceLevel3ModelList = [];
      this.regionalData2 = this.formAssess.regionalComplianceLevel2ModelList;
      this.regionalData3 = this.formAssess.regionalComplianceLevel3ModelList;
      localStorage.setItem('FormObj', JSON.stringify(this.formAssess));
      this.showFragranceMessage(this.formAssess.ifraComplianceModelList, this.regionalData2, this.regionalData3);
    }
    if (this.formAssess.selectedMarkets === null) {
      this.formAssess.dosageForMarkets = this.dosageMarkets;
    } else {
      this.formAssess.dosageForMarkets = this.dosageMarkets;
      this.formAssess.market.marketName = this.formAssess.selectedMarkets.map(market => market.marketName);
      this.formAssess.market.marketId = this.formAssess.selectedMarkets.map(market => market.marketId);
      this.formAssess.market = this.formAssess.selectedMarkets.map(market => market);
    }
    localStorage.setItem('FormObj', JSON.stringify(this.formAssess));
    this.enableOrDisableNextButton();
  }
  /* istanbul ignore next */
  updateDosage(field, event, index) {
    if (this.dosageMarkets.length === 0 && this.formAssess.dosageForMarkets !== null) {
      this.dosageMarkets = this.formAssess.dosageForMarkets;
    } else if (this.dosageMarkets.length === 0 && this.formAssess.dosageForMarkets === null) {
      this.formAssess.selectedMarkets.forEach(marketData => {
        this.dosageMarkets.push({
          marketId: marketData.marketId,
          marketName: marketData.marketName,
          dosage: '',
          dosageUnit: '',
          servingSize: ''
        });
      });
    }
    if (field === 'dosage') {
      event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      this.dosageMarkets[index].dosage = event.target.value;
      this.dosageMarkets[index].servingSize = '';
    } else if (field === 'servingSize') {
      event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      this.dosageMarkets[index].servingSize = event.target.value;
      this.dosageMarkets[index].dosage = '';
    } else {
      this.dosageMarkets[index].dosageUnit = event.value.uoMsymbol;
      this.dosageMarkets[index].selectedUoM = event.value;
    }
    this.formAssess.dosageForMarkets = this.dosageMarkets;
    localStorage.setItem('FormObj', JSON.stringify(this.formAssess));
  }

  updateMultiComponent(selected, data, multiIndex) {
    // creating  components based on selection
    if (this.selectedMultiComponents.length >= 1) {
      this.selectedMultiComponents.forEach(item => {
        if (item.specID === data.specID && item.specQuantity === data.specQuantity && item.rowValue === data.rowValue) {
          item.component = selected;
        }
      });

      const filterSelected = this.selectedMultiComponents.filter(item => item.specID === data.specID && item.specQuantity === data.specQuantity && item.rowValue === data.rowValue);
      if (filterSelected.length === 0) {
        this.selectedMultiComponents.push({
          component: selected,
          specID: data.specID,
          specQuantity: data.specQuantity,
          rowValue: data.rowValue
        });
      }
    } else {
      this.selectedMultiComponents.push({
        component: selected,
        specID: data.specID,
        specQuantity: data.specQuantity,
        rowValue: data.rowValue
      });
    }
    // creating  components based on selection

    // sort as per the multi component order
    this.sortAsPerOrder = [];
    this.specificationIds = [];
    this.segregatecompositionData.forEach(specification => {
      if (this.specificationIds.length >= 1) {
        const filterSpecId = this.specificationIds.filter(item => item === specification.specID);
        if (filterSpecId.length === 0) {
          this.specificationIds.push(specification.specID);
        }
      } else {
        this.specificationIds.push(specification.specID);
      }
      this.selectedMultiComponents.forEach(component => {
        if (specification.show === true && component.specID === specification.specID) {
          this.sortAsPerOrder.push(component);
        }
      });
    });
    this.segregateOrder = [];
    this.selectedMultiComponents.forEach(item => {
      if (this.segregateOrder.length >= 1) {
        this.segregateOrder.forEach(item2 => {
          if (item.component === item2.component) {
            if (item2.specifications.filter(s => s.specID === item.specID).length === 0) {
              item2.specifications.push(item.specID);
            }
            if (item2.specQuantities.filter(s => s.specID === item.specID && s.rowValue === item.rowValue).length === 0) {
              item2.specQuantities.push(
                {
                  specId: item.specID,
                  specQuantity: item.specQuantity,
                  rowValue: item.rowValue
                });
            }

          }
        });

        const filteredData = this.segregateOrder.filter(item2 => item.component === item2.component);
        if (filteredData.length === 0) {
          this.segregateOrder.push({
            component: item.component,
            specifications: [item.specID],
            specQuantities: [
              {
                specId: item.specID,
                specQuantity: item.specQuantity,
                rowValue: item.rowValue
              }]
          });
        }
      } else {
        this.segregateOrder.push({
          component: item.component,
          specifications: [item.specID],
          specQuantities: [{
            specId: item.specID,
            specQuantity: item.specQuantity,
            rowValue: item.rowValue
          }]
        });
      }
    });

    this.segregateOrderMultiComponent = this.segregateOrder;
    // segregate specification if single component is selected for multile specification

    const selectedMulticomponent = this.responsedataMultiComp.filter(item => item.multiComponentName === selected);

    if (this.submitMultiComponent.length >= 1) {
      const filterMulti = this.submitMultiComponent.filter(item => item.multiComponentIngredientName === data.specID && item.multiRowValue === data.rowValue);
      if (filterMulti.length === 0) {
        this.submitMultiComponent.push({
          multiComponentIngredientName: data.specID,
          multiComponentId: selectedMulticomponent[0].multiComponentId,
          multiComponentIngredientQty: data.specQuantity,
          multiComponentName: selected,
          multispecDescription: data.specDescription,
          multiRowValue: data.rowValue
        });
      } else {
        this.submitMultiComponent.forEach(component => {
          if (component.multiComponentIngredientName === data.specID && component.multiRowValue === data.rowValue) {
            component.multiComponentId = selectedMulticomponent[0].multiComponentId;
            component.multiComponentName = selected;
          }
        });
      }
    } else {
      this.submitMultiComponent.push({
        multiComponentIngredientName: data.specID,
        multiComponentId: selectedMulticomponent[0].multiComponentId,
        multiComponentIngredientQty: data.specQuantity,
        multiComponentName: selected,
        multispecDescription: data.specDescription,
        multiRowValue: data.rowValue
      });
    }
    this.formAssess.multiComponent = this.submitMultiComponent;
    this.formAssess.isMultiComponent = this.type ? 'Y' : 'N';
    this.countSelectedComponents();
    this.formAssess.selectedMultiComponents = this.selectedMultiComponents;
    this.enableOrDisableNextButton();
    if (this.multicomponentValid === true) {
      this.isCompositionOpen = false;
    }

  }
  public countSelectedComponents() {
    let count = 0;
    this.multicompositionData.forEach(item => {
      if (item.show === true && item.composition === false) {
        count++;
      }
    });
    if (count === this.selectedMultiComponents.length) {
      this.multicomponentValid = true;
    } else {
      this.multicomponentValid = false;
    }
  }

  setCompositionDataBack() {
    this.formAssess.isMultiComponent = this.type ? 'Y' : 'N';
    this.setQtyPerComponent(this.type);
    this.enableOrDisableNextButton();
    if (this.formAssess.isMultiComponent === 'Y') {
      this.setStep(2);
      this.compositionDetailsColumns = COMPOSITION_COLUMNS;
      if (this.multicomponentValid === true) {
        this.isCompositionOpen = false;
      } else {
        this.isCompositionOpen = true;
      }
    } else {
      this.compositionDetailsColumns = COMPOSITION_COLUMNS_NO;
      this.isCompositionOpen = false;
    }
    this.compositionData.forEach((composition, index) => {
      if (isNaN(composition.specID)) {
        this.compositionData.splice(index, 1);
      }
    });
    this.segregatecompositionData = this.compositionData;
    this.multicompositionData = this.compositionData;
    this.formAssess.multiComponent = this.submitMultiComponent;
    this.countSelectedComponents();
  }

  sortCompositionData() {
    this.sortedCompositionData = [];
    this.segregateOrderMultiComponent.forEach(selectedComponent => {
      this.sortedCompositionData.push({
        show: true,
        composition: true,
        specID: selectedComponent.component,
        specDescription: '',
        specQuantity: '',
        specUoM: '',
        ingredientPath: '',
        ingDescription: '',
        ingQuantity: '',
        pureCode: '',
        pureDescription: '',
        recipeQuantityToDisplay: '',
        recipeUoM: '',
        substanceFunctionInput: '',
        rowValue: 0
      });
      const specQuantities = selectedComponent.specQuantities;
      let i = 0;
      selectedComponent.specifications.forEach(specification => {
        this.segregatecompositionData.forEach(item => {
          if (specification === item.specID && specQuantities[i].specId === specification && specQuantities[i].rowValue === item.rowValue) {
            this.sortedCompositionData.push(item);
          }
        });
        i = i + 1;
      });
    });

    if (this.formAssess.compositionData !== undefined) {
      const formassessMultiComponentList = this.formAssess.compositionData.filter(f => f.composition === true);
      const sortedCompMultiComponentList = this.sortedCompositionData.filter(f => f.composition === true);
      if ((JSON.stringify(formassessMultiComponentList) === JSON.stringify(sortedCompMultiComponentList)) || sortedCompMultiComponentList.length === 0) {
        this.segregatecompositionData = this.formAssess.compositionData;
      } else {
        this.segregatecompositionData = this.sortedCompositionData;
      }
    } else {
      this.segregatecompositionData = this.sortedCompositionData;
    }
    this.CalculateOriginalRecipeQty();
  }

  openCompositionDetails(index) {
    this.setStep(index);
    this.isOnCompositionClicked = true;
    if (this.formAssess.isMultiComponent === 'Y') {
      this.sortCompositionData();
    }
    this.CalculateOriginalRecipeQty();
  }
  /* istanbul ignore next */
  searchFnList(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (const value of this.functionListVals) {
      this.functionVal = value.functionName;
      if (this.functionVal.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(this.functionVal);
      }
    }
    this.filteredFunctionListVals = filtered;
  }
  /* istanbul ignore next */
  onPreviousClicked() {
    this.dataTrans.setSelectedStep(0);
    this.routersvc.navigate(['assess-requests/select-recipe']);
    this.formAssess.regionalComplianceLevel2ModelList = [];
    this.formAssess.regionalComplianceLevel3ModelList = [];
  }
  /* istanbul ignore next */
  onNextClicked() {
    if (this.formAssess.isMultiComponent === 'Y' && this.isOnCompositionClicked === false) {
      this.sortCompositionData();
    } else {
      this.isOnCompositionClicked = false;
    }
    if (this.formAssess.isMultiComponent === 'N') {
      this.selectedMultiComponents = [];
    }
    this.formAssess.compositionData = this.segregatecompositionData;
    this.formAssess.multiDataPrevNext = this.multicompositionData;
    localStorage.setItem('FormObj', JSON.stringify(this.formAssess));
    this.dataTrans.setSelectedStep(2);
    this.routersvc.navigate(['assess-requests/submit-request']);
  }
  /* istanbul ignore next */
  onCancelClicked() {
    localStorage.removeItem('FormObj');
    this.setQtyPerComponent(this.type);
    Object.assign({}, this.formAssess);
    this.dataTrans.setSelectedStep(0);
    this.routersvc.navigate(['assess-requests/select-recipe']);
  }
  /* istanbul ignore next */
  setQtyPerComponent(multiComponent) {
    if (multiComponent === 1) {
      for (const value of this.compositionData) {
        value.originalRecipeQuantity = ((value.recipeQuantity / value.specQuantity) * 100).toFixed(10);
      }
    } else {
      for (const val of this.compositionData) {
        val.originalRecipeQuantity = val.recipeQuantity;
      }
    }
  }
  /* istanbul ignore next */
  addComments(val, index) {
    setTimeout(() => {
      if (val) {
      }
    }, 500);
  }
  /* istanbul ignore next */
  enableOrDisableNextButton() {
    this.enableordisableCompositionDetailsTab();
    if (this.isConfirmBtnDisabled === true) {
      if (this.formAssess.isMultiComponent === 'N' && this.ProjDetailList.length > 0) {
        if (this.calculatedRecipeQty && ((parseFloat(this.calculatedRecipeQty) < 99.99) || (parseFloat(this.calculatedRecipeQty) > 102.00))) {
          this.matSnackBar.open('Not completed fully. Please re-initate the request', 'close', {
            duration: 20000,
            panelClass: ['error']
          });
          this.disableNext = true;
          this.step = -1;
          this.multicomponentValid = false;
        } else {
          this.disableNext = false;
          //        this.selectedMultiComponents = [];
          this.multicomponentValid = false;
        }
      } else if (this.formAssess.isMultiComponent === 'Y' && this.multicomponentValid === true && (this.ProjDetailList.length > 0)) {
        if (this.calculatedRecipeQty && ((parseFloat(this.calculatedRecipeQty) < 99.99) || (parseFloat(this.calculatedRecipeQty) > 102.00))) {
          this.matSnackBar.open('Not completed fully. Please re-initate the request', 'close', {
            duration: 20000,
            panelClass: ['error']
          });
          this.disableNext = true;
          this.step = -1;
        } else {
          this.disableNext = false;
        }
      } else {
        if (this.calculatedRecipeQty && ((parseFloat(this.calculatedRecipeQty) < 99.99) || (parseFloat(this.calculatedRecipeQty) > 102.00))) {
          this.matSnackBar.open('Not completed fully. Please re-initate the request', 'close', {
            duration: 20000,
            panelClass: ['error']
          });
          this.step = -1;
        }
        this.disableNext = true;
      }
    } else {
      this.disableNext = true;
    }
  }
  /* istanbul ignore next */
  CalculateOriginalRecipeQty() {
    for (let i = 0; i < this.segregateOrderMultiComponent.length; i++) {
      let sumSpecQty = 0.0;
      if (this.segregateOrderMultiComponent[i].specifications !== undefined) {
        for (let j = 0; j < this.segregateOrderMultiComponent[i].specifications.length; j++) {
          const specid = this.segregateOrderMultiComponent[i].specifications[j];
          const data = this.segregatecompositionData.filter(s => s.specID === specid);
          sumSpecQty = sumSpecQty + data[0].specQuantity;
        }
        for (let k = 0; k < this.segregateOrderMultiComponent[i].specifications.length; k++) {
          for (let l = 0; l < this.segregatecompositionData.length; l++) {
            if (this.segregatecompositionData[l].specID === this.segregateOrderMultiComponent[i].specifications[k]) {
              if (this.segregateOrderMultiComponent[i].component.toLowerCase() !== localStorage.getItem('AsIsIngredientComponentname').toLowerCase()) {
                this.segregatecompositionData[l].originalRecipeQuantity = _.round(((this.segregatecompositionData[l].recipeQuantity * 100) / sumSpecQty), 10);
              } else {
                this.segregatecompositionData[l].originalRecipeQuantity = this.segregatecompositionData[l].recipeQuantity;
              }
            }
          }
        }
      }
    }
    // *commented in r6 to resolve pure quantity issue *//
    // this.calculatedRecipeQty = _.sumBy(this.segregatecompositionData, 'originalRecipeQuantity');
    // this.calculatedRecipeQty = this.calculatedRecipeQty / this.segregateOrderMultiComponent.length;
    // this.calculatedRecipeQty = _.round(this.calculatedRecipeQty, 12);
    this.calculatedRecipeQty = _.sumBy(this.segregatecompositionData, 'recipeQuantity');
    // this.calculatedRecipeQty = this.calculatedRecipeQty / this.segregateOrderMultiComponent.length;
    this.calculatedRecipeQty = _.round(this.calculatedRecipeQty, 12);
  }
  dropdownOnFocus(value) {
  }
  /* istanbul ignore next */
  showFragranceMessage(infraData, regionalData2, regionalData3) {
    if (infraData !== undefined && infraData != null && regionalData2 !== undefined && regionalData3 !== undefined) {
      if (infraData.length === 0 && regionalData2.length === 0 && regionalData3.length === 0) {
        this.fragranceMessage = localStorage.getItem('FragranceMessage');
      } else {
        this.fragranceMessage = '';
      }
    }
  }
  /* istanbul ignore next */
  getBrandData() {
    const apiurl = environment.APIFAsT + '/getbrand/division/' + this.formAssess.division;
    this.assessService.getData(apiurl).subscribe((response) => {
      this.brandType = response;
    });
  }
  /* istanbul ignore next */
  getProjectTypeData() {
    const apiurl = environment.APIFAsT + '/getprojecttypes/division/' + this.formAssess.division;
    this.assessService.getData(apiurl).subscribe((res) => {
      this.projectType = res;
    });
  }
  clearProjectFields() {
    this.iseditrequestflag = false;
    this.ProjDetail = {
      RequestId: '',
      Market: null,
      ProjectName: '',
      ProjectDescription: '',
      ProjectType: [],
      Brand: [],
      OtherBrandDescription: '',
      OtherProjectDescription: '',
      otherManufacturingSiteDescription: '',
      LaunchTimeLine: '',
      ManufactureSite: [],
      ManufactureCountry: [],
      comments: ''
    };
    this.flagmancountryselected = true;
    this.manufatureSiteData = [];
    this.projtypeothoptionflg = false;
    this.brandothoptionflg = false;
    this.mansiteothoptionflg = false;
    this.isClearBtnDisabled = true;
    this.editflg = false;
    if (this.ProjDetailList.length <= 0) {
      this.isConfirmBtnDisabled = true;
    }
  }
  /* istanbul ignore next */
  addprojectRequest(data) {
    const strsitecode = [];
    const strsitedesc = [];
    const today = new Date();
    data.ManufactureSite.forEach(e => {
      strsitecode.push(e.plmplantCode);
      if (e.plmplantCode === '99') {
        strsitedesc.push(data.otherManufacturingSiteDescription);
      } else {
        strsitedesc.push(e.plmplantDesc);
      }
    });
    data.ManufactureSite.forEach((value, index) => {
      if (value.plmplantCode === '99') {
        data.ManufactureSite[index].othersitedesc = data.otherManufacturingSiteDescription;
      } else {
        data.ManufactureSite[index].othersitedesc = value.plmplantDesc;
      }
    });
    const req = {
      marketId: data.Market.marketId,
      marketName: data.Market.marketName,
      clusterId: null,
      clusterName: null,
      marketFullText: null,
      parentMarket: null,
      marketLevel: null,
      isCluster: false,
      ProjDetail: {
        RequestId: data.Market.marketId,
        MarketId: data.Market.marketId,
        ProjectName: data.ProjectName,
        ProjectDescription: data.ProjectDescription,
        ProjectTypeCd: data.ProjectType.projTypeCd,
        ProjectTypeDesc: data.ProjectType.projTypeCd !== '99' ? data.ProjectType.projTypeDesc : data.OtherProjectDescription,
        BrandCd: data.Brand !== undefined ? data.Brand.brandCd : '',
        BrandDesc: data.Brand.brandCd !== '99' ? data.Brand.brandDesc : data.OtherBrandDescription,
        LaunchTimeLine: data.LaunchTimeLine !== null && data.LaunchTimeLine !== undefined && data.LaunchTimeLine !== '' ? this.convertToYYYMMDD(data.LaunchTimeLine) : '',
        ManufactureSiteCd: strsitecode.join(','),
        ManufactureSiteDesc: strsitedesc.join(','),
        ManufactureCountryDescription: data.ManufactureCountry !== undefined ? data.ManufactureCountry.countryDesc : '',
        ManufactureCountryCode: data.ManufactureCountry !== undefined ? data.ManufactureCountry.countryCode : '',
        CreatedDate: today,
        ModifiedDate: today,
        CreatedBy: this.userId,
        ModifiedBy: this.userId,
        manuSite: data.ManufactureSite,
        RequestComments: data.comments,
        otherManufacturingSiteDescription: data.otherManufacturingSiteDescription
      }
    };
    return req;
  }
  onconfirmbuttonclick(type) {
    this.iseditrequestflag = false;
    if (this.ProjDetail.Market && this.editflg === true) {
      this.ProjDetail.RequestId = this.ProjDetail.Market.marketId;
      // edit details
      this.ProjDetailList = this.removeObjectWithId(this.ProjDetailList, this.editMarkteId);
      this.ProjDetailList.push(JSON.parse(JSON.stringify(this.addprojectRequest(this.ProjDetail))));
      this.editMarkteId = '';
      this.matSnackBar.open('Project Details Updated Successfully', 'close', {
        duration: 1000,
        panelClass: ['success']
      });
      this.editflg = false;
    }
    if (this.ProjDetailList.length <= 5) {
      this.isAddMarketBtnDisabled = false;
    } else {
      this.isAddMarketBtnDisabled = true;
    }
    this.projtypeothoptionflg = false;
    this.brandothoptionflg = false;
    this.mansiteothoptionflg = false;
    this.clearProjectFields();
    // this.isNextBtnEnabled = true;
    this.isSaveBtnClicked = true;
    this.isSaveaddBtclick = false;
    this.formAssess.projectdetailData = this.ProjDetailList;
    localStorage.setItem('FormObj', JSON.stringify(this.formAssess));
    if (type === 'save') {
      this.onMarketChange();
      this.enableOrDisableNextButton();
    }
  }
  saveProjectDetails(type) {
    if (this.ProjDetail.Market) {
      this.ProjDetail.RequestId = this.ProjDetail.Market.marketId;
      if (!this.editMarkteId) { // add details
        if (!this.ProjDetailList.some(e => e.marketId === this.ProjDetail.RequestId)) {
          this.ProjDetailList.push(JSON.parse(JSON.stringify(this.addprojectRequest(this.ProjDetail))));
        } else {
          this.matSnackBar.open('Market Already Added', 'close', {
            duration: 3000,
            panelClass: ['error']
          });
        }
      } else { // edit details
        this.ProjDetailList = this.removeObjectWithId(this.ProjDetailList, this.editMarkteId);
        this.ProjDetailList.push(JSON.parse(JSON.stringify(this.addprojectRequest(this.ProjDetail))));
        this.editMarkteId = '';
        this.matSnackBar.open('Project Details Updated Successfully', 'close', {
          duration: 1000,
          panelClass: ['success']
        });
        this.editflg = false;
      }
      /* istanbul ignore if */
      if (this.ProjDetailList.length > 0) {
        this.isConfirmBtnDisabled = false;
      }
    } else {
      this.matSnackBar.open('Please select a Market.', 'close', {
        duration: 3000,
        panelClass: ['error']
      });
    }
    if (type === 'addnew') {
      this.ProjDetail.Market = null;
      this.isSaveBtnClicked = false;
      this.isSaveaddBtclick = true;
    } else {
      this.projtypeothoptionflg = false;
      this.brandothoptionflg = false;
      this.mansiteothoptionflg = false;
      this.clearProjectFields();
      // this.isNextBtnEnabled = true;
      this.isSaveBtnClicked = true;
      this.isSaveaddBtclick = false;
    }
    this.formAssess.projectdetailData = this.ProjDetailList;
    localStorage.setItem('FormObj', JSON.stringify(this.formAssess));
    if (type === 'save') {
      this.onMarketChange();
      this.enableOrDisableNextButton();
    }
    // this.editflg = false;
  }

  /* istanbul ignore next */
  editProjectDeails(row) {
    this.iseditrequestflag = true;
    let othesitedetail = '';
    row.ProjDetail.manuSite.forEach((e, i) => {
      if (e.plmplantCode === '99') {
        this.mansiteothoptionflg = true;
        othesitedetail = row.ProjDetail.ManufactureSiteDesc.split(',')[i];
      }
    });
    this.editflg = true;
    row.ProjDetail.manuSite.forEach((value, index) => {
      if (value.plmplantCode === '99') {
        row.ProjDetail.manuSite[index].othersitedesc = 'Other';
      }
    });
    const setdetail = {
      RequestId: '',
      Market: this.responsedataMarket.find(x => x.marketId === row.marketId),
      ProjectName: row.ProjDetail.ProjectName,
      ProjectDescription: row.ProjDetail.ProjectDescription,
      OtherProjectDescription: row.ProjDetail.ProjectTypeCd === '99' ? row.ProjDetail.ProjectTypeDesc : '',
      OtherBrandDescription: row.ProjDetail.BrandCd === '99' ? row.ProjDetail.BrandDesc : '',

      ProjectType: row.ProjDetail.ProjectTypeCd ? this.projectType.find(x => x.projTypeCd === row.ProjDetail.ProjectTypeCd) : [],
      Brand: row.ProjDetail.BrandCd ? this.brandType.find(x => x.brandCd === row.ProjDetail.BrandCd) : [],
      LaunchTimeLine: row.ProjDetail.LaunchTimeLine !== '' ? new Date(row.ProjDetail.LaunchTimeLine) : '',
      ManufactureCountry: row.ProjDetail.ManufactureCountryCode ? this.manufatureCoutryData.find(x => x.countryCode === row.ProjDetail.ManufactureCountryCode) : [],
      comments: row.ProjDetail.RequestComments,
      ManufactureSite: row.ProjDetail.manuSite,
      otherManufacturingSiteDescription: othesitedetail
    };

    if (row.ProjDetail.ProjectTypeCd === '99') {
      this.projtypeothoptionflg = true;
    } else {
      this.projtypeothoptionflg = false;
    }
    if (row.ProjDetail.BrandCd === '99') {
      this.brandothoptionflg = true;
    } else {
      this.brandothoptionflg = false;
    }
    this.editMarkteId = row.marketId;
    this.ProjDetail = JSON.parse(JSON.stringify(setdetail));
    this.ProjDetail.LaunchTimeLine = row.ProjDetail.LaunchTimeLine !== '' ? new Date(row.ProjDetail.LaunchTimeLine) : '';
    if (row.ProjDetail.ManufactureCountryCode) {
      this.getManufatureSite();
    }
    this.isAddMarketBtnDisabled = true;
    this.isConfirmBtnDisabled = false;
    this.isClearBtnDisabled = true;
    this.isNextBtnEnabled = false;
    this.isSaveBtnClicked = false;
    this.isSaveaddBtclick = false;
    this.enableOrDisableNextButton();
    this.disableNext = true;
  }

  onDropdownClick(event) {
    if (this.ProjDetail.Brand.brandCd === '99') {
      this.isDialogOpen = true;
      this.brandothoptionflg = true;
    } else {
      this.brandothoptionflg = false;
    }
  }
  onBrandNoClick() {
    this.isDialogOpen = false;
    if (this.ProjDetail.OtherBrandDescription === '') {
      this.ProjDetail.Brand = '';
      this.brandothoptionflg = false;
    }
  }
  onBrandYesClick() {
    this.isDialogOpen = false;
    if (this.ProjDetail.OtherBrandDescription === '') {
      this.ProjDetail.OtherBrandDescription = '';
      this.ProjDetail.Brand = '';
      this.brandothoptionflg = false;
    }
  }
  onProjTypeNoClick() {
    this.isprojectTypeDialogOpen = false;
    if (this.ProjDetail.OtherProjectDescription === '') {
      this.ProjDetail.ProjectType = '';
      this.projtypeothoptionflg = false;
    }
  }
  onProjTypeYesClick() {
    this.isprojectTypeDialogOpen = false;
    if (this.ProjDetail.OtherProjectDescription === '') {
      this.ProjDetail.OtherProjectDescription = '';
      this.ProjDetail.ProjectType = '';
      this.projtypeothoptionflg = false;
    }
  }
  onManSiteNoClick() {
    this.isManufacturesiteDialogOpen = false;
    this.resetManuSite();
  }
  resetManuSite() {
    if (this.ProjDetail.otherManufacturingSiteDescription === '') {
      this.tempManuSite = [];
      this.ProjDetail.otherManufacturingSiteDescription = '';
      this.ProjDetail.ManufactureSite.forEach(element => {
        if (element.plmplantCode === '99') {
          const objWithIdIndex = this.ProjDetail.ManufactureSite.findIndex((obj) => obj.plmplantCode === '99');
          this.ProjDetail.ManufactureSite.splice(objWithIdIndex, 1);
          this.mansiteothoptionflg = false;
        } else {
          this.tempManuSite.push({ id: 0, plmplantCode: element.plmplantCode, plmplantDesc: element.plmplantDesc, countryCode: element.countryCode, othersitedesc: element.othersitedesc });
        }
      });
      this.ProjDetail.ManufactureSite = [];
      this.ProjDetail.ManufactureSite = this.tempManuSite;
      this.mansiteothoptionflg = false;
    }
  }
  onManuSiteYesClick() {
    this.isManufacturesiteDialogOpen = false;
    if (this.ProjDetail.otherManufacturingSiteDescription === '') {
      this.resetManuSite();
    }
  }
  onProjTypeDropdownClick(event) {
    if (this.ProjDetail.ProjectType.projTypeCd === '99') {
      this.isprojectTypeDialogOpen = true;
      this.projtypeothoptionflg = true;
    } else {
      this.projtypeothoptionflg = false;
    }
  }
  onManufacturesiteChange(event) {
    if (event.itemValue.plmplantCode === '99') {
      if (event.value.some(e => e.plmplantCode === '99')) {
        this.isManufacturesiteDialogOpen = true;
        this.mansiteothoptionflg = true;
      } else {
        this.mansiteothoptionflg = false;
      }
    }
  }
  onManufacturesiteOthClick() {
    this.isManufacturesiteDialogOpen = true;
    this.isPopupyesClicked = false;
  }
  saveEnable() {
    const flag = false;
    if (this.isSaveBtnClicked) {
      return true;
    }
    return flag;
  }
  saveAddEnable() {
    const flag = false;
    if (this.isSaveaddBtclick) {
      return true;
    }
    return flag;
  }
  removeObjectWithId(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.marketId === id);
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
    return arr;
  }

  removeRegCompDataWithName(arr, marketName) {
    const newArr: string[] = arr.filter((element) => {
      return element.marketName !== marketName;
    });
    return newArr;
  }
  /* istanbul ignore next */
  deleteProjectTypedata(row) {
    this.editMarkteId = row.marketId;
    this.ProjDetailList = this.removeObjectWithId(this.ProjDetailList, this.editMarkteId);
    this.regionalData2 = this.removeRegCompDataWithName(this.regionalData2, row.marketName);
    this.regionalData3 = this.removeRegCompDataWithName(this.regionalData3, row.marketName);
    this.editMarkteId = '';
    this.matSnackBar.open('Project Details Deleted Successfully', 'close', {
      duration: 1000,
      panelClass: ['error']
    });
    if (this.ProjDetailList.length <= 0) {
      this.isNextBtnEnabled = false;
      this.isAddMarketBtnDisabled = false;
      this.isConfirmBtnDisabled = true;
      this.isClearBtnDisabled = true;
      // this.clearProjectFields();
    } else {
      // this.clearProjectFields();
      if (this.ProjDetailList.length <= 5) {
        this.isAddMarketBtnDisabled = false;
      } else {
        this.isAddMarketBtnDisabled = true;
      }
    }
    if (this.iseditrequestflag === true) {
      this.clearProjectFields();
    } else {
      this.ProjDetail.Market = null;
    }
    this.editflg = false;
    this.isSaveBtnClicked = false;
    this.isSaveaddBtclick = false;
    this.editMarkteId = '';
    this.enableOrDisableNextButton();
  }
  /* istanbul ignore next */
  getManufatureCountry() {
    const apiurl = environment.APIFAsT + '/getManufacturingCountry';
    this.assessService.getData(apiurl).subscribe((respData) => {
      this.manufatureCoutryData = respData;
    });
  }
  onManufactureDropdownClick() {
    this.ProjDetail.ManufactureSite = [];
    this.getManufatureSite();
  }
  /* istanbul ignore next */
  getManufatureSite() {
    if (this.ProjDetail.ManufactureCountry.countryCode !== undefined || this.ProjDetail.ManufactureCountry.countryCode !== null) {
      this.flagmancountryselected = false;
    } else {
      this.flagmancountryselected = true;
    }
    const apiurl = environment.APIFAsT + '/getmanufacturingsites/countrycode/' + this.ProjDetail.ManufactureCountry.countryCode;
    this.assessService.getData(apiurl).subscribe((resData) => {
      this.manufatureSiteData = resData;
    });
  }
  onmarketChange(event) {
    if (this.ProjDetailList.length >= 5) {
      this.isAddMarketBtnDisabled = true;
      this.matSnackBar.open('Limit Exceeded. Maximum 5 markets can be added in a request.', 'close', {
        duration: 3000,
        panelClass: ['error']
      });
      this.ProjDetail.Market = [];
      // return false;
    } else {
      this.isAddMarketBtnDisabled = false;
      this.isAddMarketBtnDisabled = false;
      // this.isConfirmBtnDisabled = false;
      this.isClearBtnDisabled = false;
      this.disableNext = true;
    }
  }
  test(event, mc) {
    this.ProjDetail.LaunchTimeLine = mc.inputFieldValue;
  }
  openComment(data) {
    this.isCommentDialogOpen = true;
    this.tableComment = data.toString().replace('<a', '<a target="_blank"');
  }
  changeComment(comment) {
    let commentRD = '';
    const strValue = comment;
    const ar = strValue.match(/<a.*?<\/a>/g);
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentRD = 'Click Here';
    } else {
      const hComment = this.handleSpecialCharacters(comment);
      commentRD = hComment.length > 25 ? hComment.substring(0, 25) + '...' : hComment;
    }
    return commentRD;
  }
  // /* istanbul ignore next */
  maxlenghtCheck(data, count) {
    data = data.replace('&nbsp;', '');
    data = data.replace(/<strong>/gi, '');
    data = data.replace(/<\/strong>/gi, '');
    data = data.replace(/<i>/gi, '');
    data = data.replace(/<\/i>/gi, '');
    this.ProjDetail.comments = data;
    this.ankerCount = (data.match(/<a/g) || []).length;
    const dataWithoutHtmlCount = data.replace(/<(.|\n)*?>/g, '').length + 1;
    const datawithhtmlCount = data.length;
    const diff = datawithhtmlCount - dataWithoutHtmlCount;
    if (count <= dataWithoutHtmlCount) {
      this.ProjDetail.comments = this.ProjDetail.comments.substring(0, count + diff);
      return false;
    }
  }
  closePopup() {
    this.isCommentDialogOpen = false;
  }
  convertToYYYMMDD(str) {
    const date = new Date(str);
    const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }
  handleSpecialCharacters(text) {
    return text.replace(/<.*?>/g, ' ').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, ' ').replace(/&amp;/g, '&');
  }
  deleteIcon(rowData) {
    this.deleteIconPopup = true;
    this.deleterowdata = rowData;
  }
  deleteRecords() {
    this.deleteProjectTypedata(this.deleterowdata);
  }
  enableordisableCompositionDetailsTab() {
    if (this.formAssess.isMultiComponent === 'Y') {
      // this.isCompositionOpen = true;
      this.countSelectedComponents();
      if (this.multicomponentValid === true) {
        this.isCompositionPanel = false;
      } else {
        this.isCompositionPanel = true;
      }
    } else {
      this.isCompositionPanel = false;
    }
  }
  setrowdata() {
    let count = 0;
    this.multicompositionData.forEach(item => {
      if (item.show === true && item.composition === false) {
        count++;
        item.rowValue = count;
      } else {
        item.rowValue = count;
      }
    });
    let compCount = 0;
    this.compositionData.forEach(item => {
      if (item.show === true && item.composition === false) {
        compCount++;
        item.rowValue = compCount;
      } else {
        item.rowValue = compCount;
      }
    });
  }
}
