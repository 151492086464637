import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CreateInsertRuleComponent } from './create-insert-rule.component';
import { CreateParallelRuleComponent } from './create-parallel-rule/create-parallel-rule.component';
import { SelectRpcComponent } from './select-rpc/select-rpc.component';


const routes: Routes = [
  {
      path: '',
      component: CreateInsertRuleComponent,
      children: [
          {
              path: 'select-rpc',
              component: SelectRpcComponent
          },
          {
              path: 'create-parallel-rule',
              component: CreateParallelRuleComponent
          }
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreateInsertRuleRoutingModule { }
