import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { groupRulesDetailsModel } from 'src/app/common/models/GroupRulesDetailsModel';
import { pureRulesDetailsModel } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { DataService } from 'src/app/common/services/data.service';
import { environment } from 'src/environments/environment';

export class RuleTypesApplicable {
  id; pureCode; marketId;  rpcid;  applicabilityStatus;
  isRuleNotApplicable: boolean;   isAddRuleEnable: boolean;  greyBackground: boolean; isRuleApplicable: boolean;
  ruleClassification; sirid; ruleTypeId; ruleTypeDisplayName; ruleCreationStatus; groupId;
}

@Component({
  selector: 'app-group-rule-selection',
  templateUrl: './group-rule-selection.component.html',
  styleUrls: ['./group-rule-selection.component.scss']
})
export class GroupRuleSelectionComponent implements OnInit {
  @Output() ruleTypeEvent = new EventEmitter<any>();
  ruleTypesApplicable: RuleTypesApplicable[] = [];
  readOnlyValue = true; isRuleAdded = false; isRuleChecked = false; isRuleUnChecked = false; loading = false;
  selectedRuleCategory = 'regulated';  ruleBucket;
  rulesAppliedList = []; ruleTypes = []; propertyRuleTypes = [];  substanceRuleTypes = [];
  substanceBasedRuleTypes = []; substanceGroupRuleTypes = []; groupRuleTypes = [];
  pureDetails = {
    Market: { MarketId: 10, MarketName: 'Brazil' },
    RpcDetails: {
      RpcId: 16,
      RpcName: 'Foods'
    }
  };
  ruleTypesWithApplicability; preparationMethodToDisplay; intendedUseToDisplay; groupDetails;
  constructor(
    private assessRequestRa: AssessRequestRaService, public matSnackBar: MatSnackBar, public dataObj: DataService
  ) { }

  ngOnInit() {
    this.preparationMethodToDisplay = recipeModel.preparationMethod;
    this.intendedUseToDisplay = recipeModel.intendedUse;
    this.ruleBucket = localStorage.getItem('selectedRuleBucket');
    this.dataObj.getGroupData.subscribe((groupData) => {
      this.groupDetails = groupData;
      if (this.groupDetails.groupId) {
        this.getRuleApplicability();
      }
    });
  }
  onAddRule(ruleType) {
    if (ruleType.isRuleApplicable && ruleType.isAddRuleEnable) { // if rule is green and not red
      this.ruleTypeEvent.emit(ruleType);
    }
  }
  onCheckRule(ruleType) {
    // disabled for exempt, combination ruletypes
    if (ruleType.ruleTypeId === 37 || ruleType.ruleTypeId === 38 || ruleType.ruleTypeId === 39 || ruleType.ruleTypeId === 40) {
      return;
    }
    // rule exist check for disabling rule applicability change
    if (ruleType.isRuleApplicable) {
      const index = _.findIndex(groupRulesDetailsModel.rulesListForGroups, (ruleValue) => {
        return ruleValue.ruleTypeID === ruleType.ruleTypeId;
      });
      if (index > -1) {
        this.matSnackBar.open('Rule Exists for the Rule Type. Rule Applicability cannot be changed.', 'close', {
          duration: 5000,
          panelClass: ['error']
        });
        return;
      }
    }
    // if rule is not applicable, disable applicability click
    if (ruleType.isRuleNotApplicable) {
      return;
    }
    ruleType.isAddRuleEnable = false;
    const checkedruletype = this.ruleTypesApplicable.find(x => x.ruleTypeDisplayName === ruleType.ruleTypeDisplayName);
    if (checkedruletype) {
      checkedruletype.isRuleApplicable = !checkedruletype.isRuleApplicable;
    }
  }
  onUnCheckRule(ruleType) {
    // disabled for exempt, combination ruletypes
    if (ruleType.ruleTypeId === 37 || ruleType.ruleTypeId === 38 || ruleType.ruleTypeId === 39 || ruleType.ruleTypeId === 40) {
      return;
    }
    if (ruleType.isRuleApplicable) {
      return;
    }
    ruleType.isAddRuleEnable = false;
    const uncheckedruletype = this.ruleTypesApplicable.find(x => x.ruleTypeDisplayName === ruleType.ruleTypeDisplayName);
    if (uncheckedruletype) {
      uncheckedruletype.isRuleNotApplicable = !uncheckedruletype.isRuleNotApplicable;
    }
  }
  // save applicability data
  saveRuleApplicability() {
    this.ruleTypesApplicable.forEach(element => {
      element.marketId = pureRulesDetailsModel.Market.marketId;
      element.groupId = this.groupDetails.groupId;
      element.ruleCreationStatus = 'N';
      if (element.isRuleApplicable) {
        element.applicabilityStatus = 'Yes';
      } else if (element.isRuleNotApplicable) {
        element.applicabilityStatus = 'No';
      } else {
        element.applicabilityStatus = 'NA';
      }
    });
    const data = [];
    pureRulesDetailsModel.selectedRpcs.forEach(rpc => {
      this.ruleTypesApplicable.forEach(element => {
        const ruleappobject = Object.assign({}, element);
        ruleappobject.rpcid = Number(rpc.id);
        data.push(ruleappobject);
      });
    });
    let varrpcitem = 0;
    varrpcitem = pureRulesDetailsModel.selectedRpcs.length;
    if (varrpcitem > 1) {
      this.loading = false;
      this.matSnackBar.open('Invalid Input Error: More than one child RPC level under same parent is selected. Please select one child RPC level to save rule applicability.', 'close', {
        duration: 3000,
        panelClass: ['error']
      });
    } else {
      this.loading = true;
      const apiurl = environment.APIFAsTRule + '/rules/ruleTypesMaps';
      this.assessRequestRa.postData(apiurl, data).subscribe((response) => {
      this.loading = false;
      if (response.message === 'Success') {
        this.matSnackBar.open('Saved Successfully', 'close', {
          duration: 3000,
          panelClass: ['success']
        });
        this.ruleTypesApplicable.forEach(element => {
          if (element.isRuleApplicable) {
            element.isAddRuleEnable = true;
          }
        });  // why the same panel added here
        const filteredTypes = _.filter(this.ruleTypesApplicable, (value) => value.applicabilityStatus === 'Yes' && value.ruleClassification === 'Substance Group Rules');
        this.dataObj.setApplicableRuleTypesObsv(filteredTypes);
      }
    }, (error) => {
      this.loading = false;
      this.matSnackBar.open('Request Failed', 'close', {
        duration: 3000,
        panelClass: ['error']
      });
      return false;
    });
    }
  }
  // get applicability data
  getRuleApplicability() {
    let groupid;
    groupid = this.groupDetails.groupId;
    const marketId = pureRulesDetailsModel.Market.marketId;
    const applicabilityInputList = [];
    pureRulesDetailsModel.selectedRpcs.forEach((value) => {
      const data = {
        GroupId: groupid,
        MarketId: marketId,
        Rpcid: value.id
      };
      applicabilityInputList.push(data);
    });
    this.loading = true;
    const apiurl = environment.APIFAsTRule + '/rules/ruleTypesMapForMarkets';
    this.assessRequestRa.postData(apiurl, applicabilityInputList).subscribe((response) => {
      this.ruleTypesApplicable = [];
      this.propertyRuleTypes = [];
      this.substanceBasedRuleTypes = [];
      this.substanceGroupRuleTypes = [];
      if (response.length !== 0) {
        this.ruleTypesApplicable = response;
        this.ruleTypesApplicable.forEach(element => {
          if (element.applicabilityStatus === 'Yes') {
            element.isRuleApplicable = true;
            element.isAddRuleEnable = true;
          } else if (element.applicabilityStatus === 'No') {
            element.isRuleNotApplicable = true;
          } else {
            element.isRuleNotApplicable = false;
            element.isRuleApplicable = false;
          }
          if (element.ruleClassification === 'Product Based Rules' || element.ruleClassification === 'Property Based Rules') {
            this.propertyRuleTypes.push(element);
          } else if (element.ruleClassification === 'Substance Based Rules') {
            this.substanceBasedRuleTypes.push(element);
          } else if (element.ruleClassification === 'Substance Group Rules') {
            if (element.ruleTypeId === 37 || element.ruleTypeId === 38 || element.ruleTypeId === 39 || element.ruleTypeId === 40) {
              element.greyBackground = true;
            } else {
              element.greyBackground = false;
            }
            if (element.ruleTypeId === 34 || element.ruleTypeId === 35 || element.ruleTypeId === 36) {
            } else {
              this.substanceGroupRuleTypes.push(element);
            }
          }
        });
        const filteredTypes = _.filter(this.ruleTypesApplicable, (value) => value.applicabilityStatus === 'Yes' && value.ruleClassification === 'Substance Group Rules');
        this.dataObj.setApplicableRuleTypesObsv(filteredTypes);
      } else if (response.length === 0) {
        this.matSnackBar.open('Request Failed', 'close', {
          duration: 3000,
          panelClass: ['error']
        });
      }
    }, (error) => {
      this.loading = false;
    });
    this.loading = false;
  }
}
