import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as _ from 'lodash';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { pureRulesDetailsModel, ruleFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { RuletypesAttributeMapping } from 'src/app/common/models/RuletypesAttributeMapping';
import { sirDetailObj } from 'src/app/common/models/SirModel';
import { EXECUTE_RULE_TABLE_COLUMNS } from 'src/app/common/models/tableColumns';
import { DataService } from 'src/app/common/services/data.service';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-allowed-function-template',
    templateUrl: './allowed-function-template.component.html',
    styleUrls: ['./allowed-function-template.component.scss']
})
export class AllowedFunctionTemplateComponent implements OnInit, AfterViewInit {
    @ViewChild('subBasedCommonForm') subBasedCommonForm: NgForm;
    activeTabUR = 'rule-detail';
    WarningText: null;
    allergenFlag = false;
    attBtnClicked = false;
    ruleTypeId; ruleDisplayName; applicableRuleTypesToDisplay;
    selectedRuleDetails = []; ruleAdded = [];
    ruleSpecificAttributes = [];
    ruleDetails;
    showSetVar = false;
    ankerCount: any;
    name = 'Angular ';
    editor = ClassicEditor;
    parallelRuleWorkflow = false;
    ckeditorData = '';
    setIndex;
    showWhenVar = false;
    whenIndex;
    allFns = [];
    allowedFns: any;
    rpc = []; subBasedRuleData = [];
    cancelClicked = false;
    ruleSource = '';
    validityStartDate: any = '';
    validityEndDate: any = '';
    yearAdopted: any;
    ruleDescription = '';
    editRule = false;
    viewRule = false;
    functionListData: any;
    loadingMsg;
    ruleIdOnEdit = '';
    ruleVersion = '';
    actualFnsList: any;
    ruleSaveConformationPopup = false;
    ruleComment: string;
    prepareRuleTableData = [];
    showRelatedRulesPopup = false;
    prepareRuleColumns = [];
    viewRelbtn = false;
    marketForModal = '';
    selectedRows;
    myWorklistTableColumns = [];
    showEmailConfirmationDialog = false;
    commentsToUser = '';
    selectedRowsRelated = [];
    defaultValidityFromDate;
    defaultValidityEndDate;
    todayDate;
    config = {
        toolbar: [
            'bold',
            'italic',
            'underline',
            '|',
            'bulletedList',
            'numberedList',
            // '|',
            'link'
        ],
        image: {
            toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side']
        },
        link: {
            addTargetToExternalLinks: true,
            decorators: [
              {
                mode: '',
                label: '',
                attributes: {
                  target: '_blank',
                }
              }
            ]
          }
    };
    loading = false; saveAndAddNewDialog = false; rtRadioSelected = false; ruleTypeRadioSelected = false; saveAndAddNewBtnClicked = false;
    warningTextOpen: boolean;
    tablewarningComment: any;
    orrTextOpen: boolean;
    tableORRComment: any;
    constructor(public dataSvcObj: DataService, private datePipe: DatePipe, private worklistservice: WorklistService, private reqSvc: AssessRequestRaService, public matSnackBarFn: MatSnackBar, private route: Router) { }
    ngAfterViewInit(): void {
        this.disableCheckbox();
    }
    disableCheckbox() {
        if (this.dataSvcObj.isfromSubstancesearch) {
            const picklistSource = document.getElementsByClassName('ui-picklist-source-wrapper').item(0) as HTMLElement;
            picklistSource.style.display = 'none';
            const picklistButton = document.getElementsByClassName('ui-picklist-buttons').item(0) as HTMLElement;
            picklistButton.style.display = 'none';
            const picklistTarget = document.getElementsByClassName('ui-picklist-target-wrapper').item(0) as HTMLElement;
            picklistTarget.style.cssFloat = 'left';
            if (!this.allergenFlag) {
                const alergenclass = document.getElementsByClassName('alergenclass').item(0) as HTMLElement;
                alergenclass.style.display = 'none';
            }
        }
    }
    /* istanbul ignore next */
    ngOnInit() {
        this.todayDate = new Date();
        this.defaultValidityFromDate = this.datePipe.transform(this.todayDate, 'longDate');
        this.defaultValidityEndDate = this.datePipe.transform(new Date('9999-12-31'), 'longDate');
        this.prepareRuleColumns = EXECUTE_RULE_TABLE_COLUMNS;
        if (this.activeTabUR === 'rule-detail') {
            document.getElementById('description').style.display = 'none';
            document.getElementById('ruleValidity').style.display = 'none';
        }
        this.dataSvcObj.getSirDetailForParallelRule().subscribe((result) => {
            if (result !== null) {
                this.parallelRuleWorkflow = true;
                Object.assign(sirDetailObj, result[0]);
                pureRulesDetailsModel.selectedCF = result[0].cfDetails[0];
            } else {
                this.parallelRuleWorkflow = false;
            }
        });
        this.dataSvcObj.ruleType.subscribe((value: any) => {
            if (value.length !== 0) {
                if (!this.editRule && !this.viewRule && !value.ruleId) {
                    this.ruleSource = '';
                    this.validityStartDate = '';
                    this.validityEndDate = '';
                    this.yearAdopted = '';
                    this.clearValues();
                    this.loading = true;
                    this.getFnList();
                }
                this.selectedRuleDetails[0] = value;
                this.ruleTypeId = this.selectedRuleDetails[0].ruleTypeId;
                this.ruleDisplayName = this.selectedRuleDetails[0].ruleTypeDisplayName;
                this.activeTabUR = 'rule-detail';
                document.getElementById('rule-detail').style.display = 'block';
                document.getElementById('description').style.display = 'none';
                document.getElementById('ruleValidity').style.display = 'none';
                this.ruleDescription = this.selectedRuleDetails[0].ruleTypeDescription ? this.selectedRuleDetails[0].ruleTypeDescription : null;
            }

        });
        if (this.ruleTypeId === 14) {
            this.getFnList();
            this.getRuleSpecificAttributeList(this.ruleTypeId);
        }
        if (ruleFlags.viewRule) {
            this.editRule = false;
            this.viewRule = true;
        } else if (ruleFlags.editRule) {
            this.editRule = true;
            this.viewRule = false;
        }
        this.dataSvcObj.getruleToEdit().subscribe((value) => {
            if (value !== null) {
                this.editRule = true;
                this.ruleIdOnEdit = value.ruleId;
                this.ruleVersion = value.ruleVersion;
                this.functionListData = value.functionList;
                Object.assign(sirDetailObj, value.substanceList[0]);
                this.ruleSpecificAttributes.forEach((ruleattr) => {
                    if (ruleattr.attributeName === 'Warning' && value.negativeStatement) {
                        ruleattr.isRequired = true;
                        ruleattr.attributeValue = value.negativeStatement;
                    }
                });
                if (ruleFlags.viewRule) {
                    this.defaultValidityFromDate = value.validityStartDate ? (this.datePipe.transform(new Date(this.datePipe.transform(value.validityStartDate, 'yyyy-MM-dd')), 'longDate')) : '';
                    this.defaultValidityEndDate = value.validityDate ? (this.datePipe.transform(new Date(this.datePipe.transform(value.validityDate, 'yyyy-MM-dd')), 'longDate')) : '';
                }
                this.yearAdopted = value.yearAdopted ? (new Date(value.yearAdopted)).toUTCString() : '';
                this.ruleSource = value.ruleSource;
                pureRulesDetailsModel.allergenFlag = value.isAllergenApplicable === 'Y' ? true : false;
                this.allergenFlag = value.isAllergenApplicable === 'Y' ? true : false;
                this.loading = true;
                const rpcVals = [];
                this.marketForModal = recipeModel.marketDetails.marketName;
                value.rpcs.forEach((val) => {
                    const data = {
                        id: val.rpcId,
                        text: val.rpcName,
                        rpcFullText: val.rpcFullText
                    };
                    rpcVals.push(data);
                });
                pureRulesDetailsModel.selectedParentRpcs = rpcVals;
                pureRulesDetailsModel.selectedRpcs = rpcVals;
                recipeModel.preparationMethod = value.preparationMethod;
                recipeModel.intendedUse = value.intendedUse;
                this.getFnList();
                this.getWorklistColumns();
            } else {
                this.editRule = false;
            }
        });
    }
    getFnList() {
        this.loadingMsg = 'Fetching data from FAST Rule-Engine';
        const functionApiUrl = environment.APIFAsT + '/functions';
        this.reqSvc.getData(functionApiUrl).subscribe((response) => {
            if (this.editRule || this.viewRule) {
                this.loading = false;
                this.allowedFns = [];
                for (const value of response) {
                    for (let j = 0; j < this.functionListData.length; j++) {
                        if (value.functionId === this.functionListData[j].functionId) {
                            this.allowedFns.push(value);
                        }
                    }
                }
                this.allFns = _.xor(response, this.allowedFns);
                this.actualFnsList = response;
            } else {
                this.loading = false;
                this.allFns = response;
            }
        }, (error) => {
            this.loading = false;
        });
    }
    onTabChange(activeTab) {
        this.activeTabUR = activeTab;
        if (activeTab === 'rule-detail') {
            document.getElementById('rule-detail').style.display = 'block';
            document.getElementById('description').style.display = 'none';
            document.getElementById('ruleValidity').style.display = 'none';
        } else if (activeTab === 'description') {
            document.getElementById('rule-detail').style.display = 'none';
            document.getElementById('description').style.display = 'block';
            document.getElementById('ruleValidity').style.display = 'none';
        } else if (activeTab === 'ruleValidity') {
            document.getElementById('rule-detail').style.display = 'none';
            document.getElementById('description').style.display = 'none';
            document.getElementById('ruleValidity').style.display = 'block';
        }
        setTimeout(() => {
            this.disableCheckbox();
          }, 10);
    }
    /* istanbul ignore next */
    onCancel() {
        if (!this.editRule) {
            this.allowedFns = [];
            this.clearValues();
            this.dataSvcObj.setExpandablePanel(2);
        } else {
            this.clearValues();
            this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
                this.route.navigate(['/rule-management/view-rules']);
            });
        }
    }
    /* istanbul ignore next */
    onSaveAndClose() {
        this.onSave();
    }
    onAllergenClick() {
        pureRulesDetailsModel.allergenFlag = this.allergenFlag;
    }
    /* istanbul ignore next */
    onSaveandAddNew() {
        this.onSave();
    }
    /* istanbul ignore next */
    onSave() {
        const rpcData = [];
        const savedFnData = [];
        const saveRuleToDbUrl = environment.APIFAsTRule + '/rulesList';
        this.ruleSpecificAttributes.forEach((ruleattr) => {
            if (ruleattr.attributeName === 'Warning') {
                this.WarningText = ruleattr.attributeValue;
            }
        });
        let pureSirDetails;
        if (localStorage.getItem('pureSirDetails')) {
          pureSirDetails = JSON.parse(localStorage.getItem('pureSirDetails'));
        }
        let substanceData = [];
        if (pureSirDetails && (pureRulesDetailsModel.SirId !== sirDetailObj.sirId)) {
              substanceData = [{
                PureCode: pureSirDetails.pureCode,
                PureDescription: pureSirDetails.pureDescription,
                SirId: pureSirDetails.sirId,
                SubstanceName: pureSirDetails.substanceName,
                InciName: pureSirDetails.inciName.toString(),
                Einecs: pureSirDetails.einecs.toString(),
                Cas: pureSirDetails.cas.toString(),
                parentCAS: pureSirDetails.parentCAS.toString(),
                Ins: pureSirDetails.ins.toString(),
                ENumber: pureSirDetails.eNumber.toString(),
                SubstanceFunction: null,
                UoM: '',
                CFDetails: [pureRulesDetailsModel.selectedCF],
                SubstanceMolWeight: pureSirDetails.substanceMolWeight,
                CombinationRatio: null
            }];
        } else {
            substanceData = [{
                PureCode: sirDetailObj.pureCode,
                PureDescription: sirDetailObj.pureDescription,
                SirId: sirDetailObj.sirId,
                SubstanceName: sirDetailObj.substanceName,
                InciName: sirDetailObj.inciName.toString(),
                Einecs: sirDetailObj.einecs.toString(),
                Cas: sirDetailObj.cas.toString(),
                parentCAS: sirDetailObj.parentCAS.toString(),
                Ins: sirDetailObj.ins.toString(),
                ENumber: sirDetailObj.eNumber.toString(),
                SubstanceFunction: null,
                UoM: '',
                CFDetails: [pureRulesDetailsModel.selectedCF],
                SubstanceMolWeight: sirDetailObj.substanceMolWeight,
                CombinationRatio: null
            }];
            localStorage.setItem('pureSirDetails', '');
    }
        if (pureRulesDetailsModel.CascadeRpc === 'Y') {
            pureRulesDetailsModel.selectedParentRpcs.forEach((value) => {
                const data = {
                    rpcFullText: value.rpcFullText,
                    rpcName: value.text,
                    rpcId: value.id
                };
                rpcData.push(data);
            });
        } else {
            pureRulesDetailsModel.selectedRpcs.forEach((value) => {
                const data = {
                    rpcFullText: value.rpcFullText,
                    rpcName: value.text,
                    rpcId: value.id
                };
                rpcData.push(data);
            });
        }
        this.allowedFns.forEach((functionValue) => {
            savedFnData.push({
                functionId: functionValue.functionId,
                functionName: functionValue.functionName
            });
        });
        const savedData = [{
            Markets: [pureRulesDetailsModel.Market],
            RPCs: rpcData, /// object list
            RuleId: this.ruleIdOnEdit ? this.ruleIdOnEdit : 0,
            RuleVersion: this.ruleVersion ? this.ruleVersion : 0,
            RuleTypeID: this.ruleTypeId,
            RuleType: 'Allowed Function',
            SubstanceList: substanceData,
            CascadeMarket: 'N',
            CascadeRpc: 'Y', // made to Y by default as the flag is always selected
            UoMID: 0,
            UoM: null,
            TexualMinLimitText: '',
            TexualMaxLimitText: '',
            RuleDescription: '',
            RuleSource: this.ruleSource ? this.ruleSource : '',
            RuleSourceType: 'Regulatory',
            AdditionalStatement: '', // other requirements and restrictions
            PositiveStatement: '',
            MaxLimit: '',
            MinLimit: '',
            EqualityCondition: null,
            Claims: '',
            SpeficicConditions: '',
            FunctionList: savedFnData, // used only for allowed function array of objects
            NegativeStatement: this.WarningText,
            PreparationMethod: recipeModel.preparationMethod,
            IntendedUse: recipeModel.intendedUse,
            IsMandatorySubstance: null, // Y for substance mandatory rule
            IsAllergenApplicable: pureRulesDetailsModel.allergenFlag ? 'Y' : 'N',
            IsDosageApplicable: null,
            IsServingSizeApplicable: null,
            WarningText: null, // used for warning rule, adulterated rule , phased out rule, other requirements and restrictions, Product Warnings
            yearAdopted: this.yearAdopted ? this.datePipe.transform(this.yearAdopted, 'yyyy-MM-dd') : null,
            ValidityStartDate: this.validityStartDate ? this.datePipe.transform(this.validityStartDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityFromDate, 'yyyy-MM-dd'),
            ValidityDate: this.validityEndDate ? this.datePipe.transform(this.validityEndDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityEndDate, 'yyyy-MM-dd'),
            Comments: this.ruleComment ? this.ruleComment : null,
            RuleFlow: this.parallelRuleWorkflow === true ? 'Parallel' : 'Request'
        }];
        this.loadingMsg = 'Saving Rule';
        this.loading = true;
        if (!this.editRule) {

            this.reqSvc.postData(saveRuleToDbUrl, savedData).subscribe((response) => {
                this.loading = false;
                this.clearValues();
                if (response[0].ruleId !== 0) {
                    this.ruleAdded = response;
                    pureRulesDetailsModel.allowedFunctions = this.allowedFns;
                    this.getRuleDetailsFromDb();
                    this.matSnackBarFn.open('Rule added successfully with Rule Id:' + response[0].ruleId, 'close', {
                        duration: 5000,
                        panelClass: ['success']
                    });
                    this.removefunctionattr();
                    if (this.saveAndAddNewBtnClicked === true) {
                        setTimeout(() => {
                            this.applicableRuleTypesToDisplay = pureRulesDetailsModel.applicableRuleTypeList;
                            this.saveAndAddNewDialog = true;
                        }, 3000);
                    }
                } else {
                    this.matSnackBarFn.open('Rule could not be created.', 'close', {
                        duration: 5000,
                        panelClass: ['error']
                    });
                }
            }, (error) => {
                this.loading = false;
            });
        } else {
            const url = environment.APIFAsTRule + '/rules';
            this.reqSvc.putData(url, savedData).subscribe((response) => {
                this.loading = false;
                this.clearValues();
                if (response[0].ruleId !== 0) {
                    this.ruleAdded = response;
                    this.matSnackBarFn.open('Rule updated successfully with Rule Id:' + response[0].ruleId + ' and Rule Version: ' + response[0].ruleVersion, 'close', {
                        duration: 5000,
                        panelClass: ['success']
                    });
                    this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
                        this.route.navigate(['/rule-management/view-rules']);
                    });
                    this.removefunctionattr();
                } else {
                    this.matSnackBarFn.open('Rule could not be created.', 'close', {
                        duration: 5000,
                        panelClass: ['error']
                    });
                }
            }
                , (error) => {
                    this.loading = false;
                }
            );
        }
    }
    removefunctionattr() {
        this.ruleSpecificAttributes.forEach((element) => {
            element.funcAttributeValue = null;
            element.isRequired = false;
        });
    }
    onContinue() {
        this.clearValues();
        this.saveAndAddNewDialog = false;
        this.saveAndAddNewBtnClicked = false;
        if (this.rtRadioSelected !== undefined) {
            const obj = _.filter(pureRulesDetailsModel.applicableRuleTypeList, (element) => element.ruleTypeDisplayName === this.rtRadioSelected);
            // tslint:disable-next-line: no-shadowed-variable
            const obj1 = obj.reduce((obj, newObj, i) => {
                newObj[i] = obj;
                return newObj;
            }, {});
            this.dataSvcObj.setSelectedRuleType(obj1);
            this.dataSvcObj.setExpandablePanel(3);
        }
    }
    ruleTypeRadioButtonChanged(event) {
        this.ruleTypeRadioSelected = true;
        this.rtRadioSelected = event.target.value;
    }
    onClickedOutside() {
        this.attBtnClicked = false;
    }
    onAddAttributeBtnClicked() {
        this.attBtnClicked = !this.attBtnClicked;
    }
    /* istanbul ignore next */
    getRuleSpecificAttributeList(ruleTypeId) {
        const ruledetails = RuletypesAttributeMapping.filter(obj => obj.ruleBucket === 'Substance Based' && obj.ruleTypeId === ruleTypeId)[0];
        this.ruleDetails = Object.assign({}, ruledetails);
        const rsListAttr = this.ruleDetails.attributeList;
        this.ruleSpecificAttributes = rsListAttr.map(x => Object.assign({}, x));
        for (let i = 0; i < this.ruleSpecificAttributes.length; i++) {
            if (this.ruleSpecificAttributes[i].attributeTemplate === 'SetLimit' || this.ruleSpecificAttributes[i].attributeTemplate === 'SetText' || this.ruleSpecificAttributes[i].attributeTemplate === 'SetDate') {
                this.showSetVar = true;
                this.setIndex = i;
                break;
            } else {
                this.showSetVar = false;
            }
        }
        for (let i = 0; i < this.ruleSpecificAttributes.length; i++) {
            if (this.ruleSpecificAttributes[i].attributeTemplate === 'When' || this.ruleSpecificAttributes[i].attributeTemplate === 'WhenText' || this.ruleSpecificAttributes[i].attributeTemplate === 'WhenSelect') {
                this.showWhenVar = true;
                this.whenIndex = i;
                break;
            } else {
                this.showWhenVar = false;
            }
        }
        this.ruleSpecificAttributes.filter(x => x.attributeTemplate === 'When').forEach(attr => {
            if (attr.attributeName === 'Function') {
                attr.valuelist = this.allFns;
            }
        });
    }
    /* istanbul ignore next */
    removeRTAttribute(value, index: number) {
        value.attributeValue = null;
        value.Uom = [];
        this.ruleSpecificAttributes[index].attributeValue = null;
        this.ruleSpecificAttributes[index].Uom = [];
        this.ruleSpecificAttributes.filter(x => x.attributeName === value.attributeName)[0].isRequired = false;
        for (let i = 0; i < this.ruleSpecificAttributes.length; i++) {
            if ((this.ruleSpecificAttributes[i].attributeTemplate === 'SetLimit' && this.ruleSpecificAttributes[i].isRequired) ||
                (this.ruleSpecificAttributes[i].attributeTemplate === 'SetText' && this.ruleSpecificAttributes[i].isRequired) ||
                (this.ruleSpecificAttributes[i].attributeTemplate === 'SetDate' && this.ruleSpecificAttributes[i].isRequired)) {
                this.showSetVar = true;
                this.setIndex = i;
                break;
            } else {
                this.showSetVar = false;
            }
        }
        for (let i = 0; i < this.ruleSpecificAttributes.length; i++) {
            if ((this.ruleSpecificAttributes[i].attributeTemplate === 'When' && this.ruleSpecificAttributes[i].isRequired) ||
                (this.ruleSpecificAttributes[i].attributeTemplate === 'WhenText' && this.ruleSpecificAttributes[i].isRequired) ||
                (this.ruleSpecificAttributes[i].attributeTemplate === 'WhenSelect' && this.ruleSpecificAttributes[i].isRequired)) {
                this.showWhenVar = true;
                this.whenIndex = i;
                break;
            } else {
                this.showWhenVar = false;
            }
        }
    }
    /* istanbul ignore next */
    removeRTAttributeHide(value, index: number) {
        value.attributeValue = null;
        value.Uom = [];
        this.ruleSpecificAttributes[index].attributeValue = null;
        this.ruleSpecificAttributes[index].Uom = [];
        if (value.isRequired === false) {
            this.ruleSpecificAttributes.filter(x => x.attributeName === value.attributeName)[0].isRequired = false;
        }
        for (let i = 0; i < this.ruleSpecificAttributes.length; i++) {
            if ((this.ruleSpecificAttributes[i].attributeTemplate === 'SetLimit' && this.ruleSpecificAttributes[i].isRequired) ||
                (this.ruleSpecificAttributes[i].attributeTemplate === 'SetText' && this.ruleSpecificAttributes[i].isRequired) || (this.ruleSpecificAttributes[i].attributeTemplate === 'SetDate' && this.ruleSpecificAttributes[i].isRequired)) {
                this.showSetVar = true;
                this.setIndex = i;
                break;
            } else {
                this.showSetVar = false;
            }
        }
        for (let i = 0; i < this.ruleSpecificAttributes.length; i++) {
            if ((this.ruleSpecificAttributes[i].attributeTemplate === 'When' && this.ruleSpecificAttributes[i].isRequired) ||
                (this.ruleSpecificAttributes[i].attributeTemplate === 'WhenText' && this.ruleSpecificAttributes[i].isRequired) ||
                (this.ruleSpecificAttributes[i].attributeTemplate === 'WhenSelect' && this.ruleSpecificAttributes[i].isRequired)) {
                this.showWhenVar = true;
                this.whenIndex = i;
                break;
            } else {
                this.showWhenVar = false;
            }
        }
    }
    getRuleDetailsFromDb() {
        this.rpc = [];
        pureRulesDetailsModel.selectedRpcs.forEach((value) => {
            const data = {
                rpcFullText: value.rpcFullText,
                rpcName: value.text,
                rpcId: value.id
            };
            this.rpc.push(data);
        });
        let pureSirDetails;
        if (localStorage.getItem('pureSirDetails')) {
          pureSirDetails = JSON.parse(localStorage.getItem('pureSirDetails'));
        }
        let  fetchRuleBody;
        if (pureSirDetails && (pureRulesDetailsModel.SirId !== sirDetailObj.sirId)) {
         fetchRuleBody = {
            MarketList: [pureRulesDetailsModel.Market],
            RPCList: this.rpc,
            SIRIds: [pureSirDetails.sirId],
            ruleClassifications: ['Substance Based Rules']
        };
    } else {
        fetchRuleBody = {
            MarketList: [pureRulesDetailsModel.Market],
            RPCList: this.rpc,
            SIRIds: [sirDetailObj.sirId],
            ruleClassifications: ['Substance Based Rules']
        };
    }
        this.loading = true;
        const getruleapiurl = environment.APIFAsTRule + '/rules/rulesFilter';
        return new Promise<any>((resolve) => {
            this.reqSvc.postData(getruleapiurl, fetchRuleBody)
                .toPromise()
                .then(response => {
                    this.loading = false;
                    if (response !== null || response !== []) {
                        resolve(response);
                        this.subBasedRuleData = response;
                        this.subBasedRuleData = _.filter(this.subBasedRuleData, (val) => val.ruleClassification === 'Substance Based Rules');
                        for (const value of this.subBasedRuleData) {
                            value.ruleVerionToDisplay = 'V' + value.ruleVersion;
                            // if (value.warningText === null) {
                            //     value.warningText = value.warningStatement;
                            // }
                            value.warningText = (value.warningText === null || value.warningText === '') ? (value.warningStatement === null || value.warningStatement === '') ? '' : value.warningStatement : value.warningText;
                            if (value.warningText !== '') {
                                value.originalWarningText = value.warningText;
                                value.warningTextStripped = this.handleSpecialCharacters(value.warningText);
                                value.warningText = this.changeComment(value.warningText);
                            } else {
                                value.warningTextStripped = '';
                            }
                            value.otherRestrictionsAndRequirements = (value.otherRestrictionsAndRequirements === null || value.otherRestrictionsAndRequirements === '') ? '' : value.otherRestrictionsAndRequirements;
                            if (value.otherRestrictionsAndRequirements !== '') {
                                value.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(value.otherRestrictionsAndRequirements);
                            } else {
                                value.otherRestrictionsAndRequirementsStripped = '';
                            }
                            if (value.cfDetails !== null && value.cfDetails !== undefined) {
                                const cfValue = value.cfDetails;
                                value.cf = cfValue.cf;
                                value.cfSubstanceExpressedAs = cfValue.component;
                            }
                        }
                        this.dataSvcObj.setRulesListForPure(this.subBasedRuleData);
                    }
                }).catch(error => {
                    this.loading = false;
                });
        });
    }
    handleSpecialCharacters(text) {
        return text.replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, '').replace(/&amp;/g, '&');
      }
    clearValues() {
        this.allergenFlag = false;
        this.saveAndAddNewDialog = false;
        this.allowedFns = [];
        pureRulesDetailsModel.servingSizeFlag = null;
    }
    parseDate(dateString: string): Date {
        if (dateString) {
            return new Date(dateString);
        }
        return null;
    }
    moveLeftMessage(event) {
        event.items.forEach(element => {
            this.functionListData.forEach(element1 => {
                if (element1.functionId === element.functionId) {
                    this.allowedFns = this.allowedFns.concat(element);
                }
            });
        });
        this.allowedFns = this.allowedFns.filter((n, i) => this.allowedFns.indexOf(n) === i);
        this.allFns = this.actualFnsList;
        this.allFns = this.allFns.filter(val => !this.allowedFns.includes(val));
    }
    /* istanbul ignore next */
    checkRulesAvail() {
        this.prepareRuleTableData = [];
        this.selectedRows = null;
        this.viewRelbtn = true;
        this.loadingMsg = 'Fetching data from FAsT Rule-Engine';
        this.loading = true;
        const apiurl = environment.APIFAsTRule + '/rules/queryrelatedrequests';
        const body = [];
        this.showRelatedRulesPopup = true;
        body.push({
            RuleId: this.ruleIdOnEdit,
            RuleVersion: this.ruleVersion
        });
        this.reqSvc.postData(apiurl, body).subscribe((response) => {
            if (response) {
                this.loading = false;
                this.prepareRuleTableData = response.map(relReq => {
                    return {
                        requestDisplayID: relReq.requestDisplayID,
                        cucCode: relReq.cucCode,
                        cucDescription: relReq.cucDescription,
                        recipeType: relReq.recipeType,
                        recipeStatus: relReq.recipeStatus,
                        marketId: relReq.market.marketId,
                        marketName: relReq.market.marketName,
                        division: (relReq.tfc.tfcLevel === 5) ? relReq.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName : relReq.tfc.parentTFC.parentTFC.parentTFC.tfcName,
                        rDFormulationCategory: (relReq.tfc.tfcLevel === 5) ? relReq.tfc.parentTFC.parentTFC.parentTFC.tfcName : relReq.tfc.parentTFC.parentTFC.tfcName,
                        formulationType: (relReq.tfc.tfcLevel === 5) ? relReq.tfc.parentTFC.parentTFC.tfcName : relReq.tfc.parentTFC.tfcName,
                        formulationClass: (relReq.tfc.tfcLevel === 5) ? relReq.tfc.parentTFC.tfcName : relReq.tfc.tfcName,
                        formulationSubClass: (relReq.tfc.tfcLevel === 5) ? relReq.tfc.tfcName : '',
                        preparationMethod: relReq.preparationMethod,
                        intendedUse: relReq.intendedUse,
                        multicomponent: relReq.isMultiComponent,
                        requestStatus: relReq.requestStatus,
                        ra: relReq.ra,
                        allRa: relReq.allRA,
                        submittedDate: relReq.submittedDate,
                        submittedByUserId: relReq.submittedByUserId,
                        submittedByUserName: relReq.submittedByUserName,
                        comments: relReq.comments,
                        assignedDate: relReq.assignedDate,
                        inProgressRADate: relReq.inProgressRADate,
                        inProgressRDDate: relReq.inProgressRNDDate,
                        inProgressSIRDate: relReq.inProgressSIRDate,
                        rejectedDate: relReq.rejectedDate,
                        deletionDate: relReq.deletionDate,
                        completedDate: relReq.completedDate,
                        rdComment: relReq.comments,
                        raComment: relReq.raComment,
                        topTfcId: relReq.tfcid,
                        allRAIDs: relReq.allRAIDs,
                        raUserID: relReq.raUserIDred,
                        divisionid: (relReq.tfc.tfcLevel === 5) ? relReq.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId : relReq.tfc.parentTFC.parentTFC.parentTFC.tfcId
                    };
                });
            }

            const ele = document.getElementById('echeck') as HTMLInputElement;
            ele.checked = false;
            this.selectedRowsRelated = [];
        }, (error) => {
            this.loading = false;
        });
    }
    getWorklistColumns() {
        const apiurl = environment.APIFAsT + '/getworklistcolumns/relatedrequest';
        this.worklistservice.getWorklistApiData(apiurl).subscribe((response) => {
            this.myWorklistTableColumns = response;
        });
    }
    sendEmail() {
        this.showEmailConfirmationDialog = true;
        let reqId = '';
        let userDetails = '';
        const uDetail = [];
        const reDet = [];
        this.selectedRowsRelated.forEach(element => {
            reDet.push(element.requestDisplayID);
        });
        const distinctReqs = reDet.filter((n, i) => reDet.indexOf(n) === i);
        reqId = distinctReqs.join(' ');
        this.selectedRowsRelated.forEach(element => {
            uDetail.push(element.ra);
        });
        const distinctNames = uDetail.filter((n, i) => uDetail.indexOf(n) === i);
        userDetails = distinctNames.join(' ');
        this.commentsToUser = 'Regulatory rule/s for the request no. ' + reqId + ' will be updated/deleted by RA user ' + userDetails + ' .To get the most updated FAsT report, please submit a new request for the formulation. Ignore if the formulation is no longer valid.';
    }
    sendEmailMessage() {
        this.showEmailConfirmationDialog = false;
        this.showRelatedRulesPopup = false;
        const payLoad = {
            NotificationGroup: 'RDCOMPLETED',
            Subject: 'FAsT | Rules updated for these requests :',
            EmailBody: null,
            ToEmailList: '',
            FromEmail: null,
            CCEmailList: null,
            EmailType: 'html',
            UPD: this.ruleIdOnEdit.toString(),
            PureCodes: null,
            UserRequests: [],
            CUCDescription: null,
            Comments: this.commentsToUser
        };

        if (this.selectedRowsRelated.length > 0) {
            this.selectedRowsRelated.forEach(row => {
                const reqIds = this.selectedRowsRelated.filter(item => item.submittedByUserId === row.submittedByUserId).map(i => i.requestDisplayID);
                payLoad.UserRequests.push({
                    userId: +row.submittedByUserId,
                    requestIds: reqIds
                });
            });
        }

        if (payLoad.UserRequests.length > 0) {
            this.reqSvc.sendRulesEmailNotification(payLoad);
        }
    }
    onGroupSelect(checkValue) {
        if (checkValue) {
            this.selectedRowsRelated = this.prepareRuleTableData;
        } else {
            this.selectedRowsRelated = [];
        }
    }
      /* istanbul ignore next */
      maxlenghtCheck(data, count, atributeName) {
        if (data !== null) {
        const dataWithoutHtmlCount = data.replace(/<(.|\n)*?>/g, '').length + 1;
        const datawithhtmlCount = data.length;
        // this.ankerCount = (data.match(/<a/g) || []).length;
        this.ruleSpecificAttributes.forEach((ruleattr) => {
            if (ruleattr.attributeName === atributeName) {
                ruleattr.ankerCount = (data.match(/<a/g) || []).length;
            }
            this.checkmax7();
        });
        const diff = datawithhtmlCount - dataWithoutHtmlCount;
        if (count <= dataWithoutHtmlCount) {
            this.ruleSpecificAttributes.forEach((ruleattr) => {
                if (ruleattr.attributeName === atributeName) {
                    ruleattr.attributeValue = ruleattr.attributeValue.substring(0, count + diff);
                }
            });
            return false;
        }
    }
    }

    checkmax7() {
        for (let index = 0; index < this.ruleSpecificAttributes.length; index++) {
            if (this.ruleSpecificAttributes[index].ankerCount > 7) {
                this.ankerCount = this.ruleSpecificAttributes[index].ankerCount;
                break;
            } else {
                this.ankerCount = this.ruleSpecificAttributes[index].ankerCount;
            }
        }
    }
      /* istanbul ignore next */
  openComment(data) {
    this.warningTextOpen = true;
    const mydata = data.originalWarningText;
    this.tablewarningComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  /* istanbul ignore next */
  openCommentORR(data) {
    this.orrTextOpen = true;
    const mydata = data.originalOtherRestrictionsAndRequirements;
    this.tableORRComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  closePopup() {
    this.warningTextOpen = false;
  }
  closePopupORR() {
    this.orrTextOpen = false;
  }
  /* istanbul ignore next */
  changeComment(input) {
    const strValue = input;
    const ar = strValue.match(/<a.*?<\/a>/g);
    let commentwarning = '';
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentwarning = 'Click Here';
    } else {
      commentwarning = input;
    }
    return commentwarning;
  }
}
