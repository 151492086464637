import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
// import { AuthenticationGuard, MsAdalAngular6Module, MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor, MSAL_INSTANCE, MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MSAL_INTERCEPTOR_CONFIG, MsalInterceptorConfiguration, ProtectedResourceScopes } from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CONFIG_INJECTOR, EnvironmentConfiguration } from '../app/common/constants/environment-configuration';
// import { ApiInterceptor } from '../app/common/interceptors/api.interceptor';
import { ErrorInterceptor } from '../app/common/interceptors/error.interceptor';
import { environment } from '../environments/environment';
import { AdministrationModule } from './administration/administration.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AssessRequestRaModule } from './assess-request-ra/assess-request-ra.module';
import {
  EditGroupRulesComponent
} from './assess-request-ra/update-rules-ra/edit-substance-group-ra/edit-group-rules/edit-group-rules.component';
import { AssessRequestsModule } from './assess-requests/assess-requests.module';
import { AuthorizationRouteGuard } from './common/Authorization/authorizationRouteGuard.service';
import { ErrorPageComponent } from './common/components/error-page/error-page.component';
import { ProvideMatFormFieldReadonlyDirective } from './common/components/readonly-directive/readonly-directive';
import { SideNavComponent } from './common/components/side-nav/side-nav.component';
import { ErrorHandlerService } from './common/services/errorhandler.service';
import { MyMonitoringService } from './common/services/logging.service';
import { QuillSettingsService } from './common/services/quill-html-editor/quill-settings.service';
import { SharedModule } from './common/shared.module';
import { CreateInsertRuleModule } from './create-insert-rule/create-insert-rule.module';
import { FeaturesComponent } from './features/features.component';
import { HelpPageComponent } from './help-page/help-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { PlmReqComponent } from './plm-req/plm-req.component';
import { RelatedRequestsComponent } from './related-requests/related-requests.component';
import { StandaloneComponent } from './standalone/standalone.component';
import { TwoDigitDecimaNumberDirective } from './two-digit-decima-number.directive';
import { EditRulesComponent } from './view-rules/edit-rules/edit-rules.component';
import { TableComponent } from './view-rules/table/table.component';
import { ViewRulesComponent } from './view-rules/view-rules.component';
import { WorklistModule } from './worklist/worklist.module';
const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
import { SubstanceSearchComponent } from './substance-search/substance-search/substance-search.component';
// import { GlobalErrorHandler } from './GlobalErrorHandler';
function MsalInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
       clientId: environment.clientId,
       authority: 'https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e',
       redirectUri: window.location.origin
    },
    cache: {
      cacheLocation: 'sessionStorage'
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          // console.log(message);
        },
        logLevel: LogLevel.Verbose
      }
    }
  });
}
function MsalGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read', 'openid', 'profile'],
    }
  };
}

function MsalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();
  // myProtectedResourcesMap.set('http://localhost', ['openid', 'profile', environment.clientId]);
  // myProtectedResourcesMap.set('http://localhost', ['User.Read']);
  // myProtectedResourcesMap.set('https://ara-fast-test-internalapi.unilever.com', ['https://Unilever.onmicrosoft.com/0cfe1f98-00f0-4087-9eb1-bc077b311332/user_impersonation']);
  protectedResourceMap.set(environment.MSALFAsTAPIConfig, [environment.ImpersonationConfig]);
  protectedResourceMap.set(environment.MSALFAsTRuleEngineConfig, [environment.ImpersonationConfig]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap

  };
}
@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    TwoDigitDecimaNumberDirective,
    SideNavComponent,
    FeaturesComponent,
    ProvideMatFormFieldReadonlyDirective,
    PlmReqComponent,
    StandaloneComponent,
    HomePageComponent,
    HelpPageComponent,
    ViewRulesComponent,
    EditRulesComponent,
    EditGroupRulesComponent,
    RelatedRequestsComponent,
    TableComponent,
    SubstanceSearchComponent
  ],
  entryComponents: [
    // PureDetailsPopUpComponent,
    // UserInputPopupComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AssessRequestsModule,
    AssessRequestRaModule,
    CreateInsertRuleModule,
    WorklistModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule, MatFormFieldModule,
    MatInputModule, MatSelectModule, MatTableModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatRadioModule,
    HttpClientModule,
    NgSelectModule,
    SharedModule,
    FormsModule,
    AdministrationModule,
    MsalModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MsalInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MsalGuardConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MsalInterceptorConfigFactory
    },
    MsalService,
    MsalBroadcastService,
    MsalGuard,
    MyMonitoringService,
    { provide: CONFIG_INJECTOR, useValue: EnvironmentConfiguration.Get(), multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    // {
    //   provide: ErrorHandler,
    //   useClass: GlobalErrorHandler
    // },
    // AuthenticationGuard,
    // MsAdalAngular6Service,
    AuthorizationRouteGuard,
    QuillSettingsService,
    DatePipe
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
