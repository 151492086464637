import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { parallelRuleObj } from 'src/app/common/models/PureRuleDetailsModel';
import { DataService } from 'src/app/common/services/data.service';

@Component({
  selector: 'app-create-parallel-rule',
  templateUrl: './create-parallel-rule.component.html',
  styleUrls: ['./create-parallel-rule.component.scss']
})
export class CreateParallelRuleComponent implements OnInit {
  selectedRuleBucket: string;
  activeTabUR = 'update-rule';
  rpcsForParallelRule = [];
  goToWorklistConfirmation = false;
  expandedView = false;
  loading = false;
  pureSirDetail = null;
  expandedPureCode = '';
  expandedPureSummary = '';
  loadingMessage;
  constructor(private dataSvc: DataService, private route: Router) { }

  ngOnInit() {
    this.dataSvc.setSelectedStep(1);
    this.dataSvc.getComponentNameSvc.subscribe((val) => {
      if (val === 'CreateInsertRule') {
        this.activeTabUR = 'update-rule';
      }
    });
    this.dataSvc.getParallelRuleFormValues().subscribe((value) => {
      if (value !== null) {
        if (value.selectedrpc1 !== '' && value.selectedrpc1 !== undefined) {
          this.rpcsForParallelRule.push(value.selectedrpc1);
          if (value.selectedrpc2 !== '' && value.selectedrpc2 !== undefined) {
            this.rpcsForParallelRule.push(value.selectedrpc2);
            if (value.selectedrpc3 !== '' && value.selectedrpc3 !== undefined) {
              this.rpcsForParallelRule.push(value.selectedrpc3);
              if (value.selectedrpc4 !== '' && value.selectedrpc4 !== undefined) {
                this.rpcsForParallelRule.push(value.selectedrpc4);
                if (value.selectedrpc5 !== '' && value.selectedrpc5 !== undefined) {
                  this.rpcsForParallelRule.push(value.selectedrpc5);
                  if (value.selectedrpc6 !== '' && value.selectedrpc6 !== undefined) {
                    this.rpcsForParallelRule.push(value.selectedrpc6);
                  }
                }
              }
            }
          }
        }
        this.dataSvc.getLastSelectedNodeTreeData().subscribe((resp) => {
          parallelRuleObj.rpcsForParallelRuleData = resp;
        });
      }
    });
    this.dataSvc.getRuleTypeVal().subscribe((val) => {
      if (val === 'Substance Based Rules') {
        this.setRuleBucket(val);
      } else if (val === 'Substance Group Rules') {
        this.setRuleBucket(val);
      } else if (val === 'Product Based Rules') {
        this.setRuleBucket(val);
      }
    });
    this.dataSvc.getSirDetailForParallelRule().subscribe((result) => {
      this.pureSirDetail = result[0];
      this.expandedPureCode = this.pureSirDetail.sirId;
      this.expandedPureSummary = this.pureSirDetail.substanceName;
    });
  }
  setRuleBucket(ruleBucketValue) {
    this.selectedRuleBucket = ruleBucketValue;
    this.dataSvc.setExpandablePanel(0);
  }
  navigateToWorklist() {
    this.route.navigate(['/worklist/master-worklist']);
  }
  closeTab() {
    parallelRuleObj.selectedRPC = null;
    parallelRuleObj.selectedMarket = null;
    parallelRuleObj.selectedrpc1 = null;
    parallelRuleObj.selectedrpc2 = null;
    parallelRuleObj.selectedrpc3 = null;
    parallelRuleObj.selectedrpc4 = null;
    parallelRuleObj.selectedrpc5 = null;
    parallelRuleObj.selectedrpc6 = null;
    this.route.navigate(['/create-insert/select-rpc']);

  }
  navigateToMRPCBtn() {
    this.route.navigate(['/create-insert/select-rpc']);
  }
}
