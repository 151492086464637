import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-plm-req',
  templateUrl: './plm-req.component.html',
  styleUrls: ['./plm-req.component.scss']
})
export class PlmReqComponent implements OnInit {
  formRequest = {
    fc: '',
    version: '',
    alternate: '',
    fromLanding: true
  };
  constructor(
    public route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    const cucCodeFromRoute = this.route.snapshot.paramMap.get('cucCode');
    const altFromRoute = this.route.snapshot.paramMap.get('alt');
    const verFromRoute = this.route.snapshot.paramMap.get('ver');
    this.formRequest.fc = cucCodeFromRoute;
    this.formRequest.version = verFromRoute;
    this.formRequest.alternate = altFromRoute;

    localStorage.setItem('cucCode', cucCodeFromRoute);
    localStorage.setItem('alternate', altFromRoute);
    localStorage.setItem('version', verFromRoute);
    this.router.navigate(['/']);
  }

}
